export enum SizeType {
  sm = "sm",
  md = "md",
  lg = "lg"
}

export enum PositionType {
  bottom = "bottom",
  left = "left",
  right = "right",
  top = "top"
}

export type CommonStoryArguments = {
  locale: string;
};
