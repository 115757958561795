import { useMutation } from "react-query";
import { ChangeTokenBodyType } from "../../../services/base/homepage/jek";
import {
  ChangeTokenService,
  ChangeTokenUrl
} from "./../../../services/base/homepage/jek/change-token";

export const useChangeTokenService = (body: ChangeTokenBodyType) => {
  const { isLoading, mutateAsync } = useMutation([ChangeTokenUrl, body], () =>
    ChangeTokenService(body)
  );

  return { isLoading, mutateAsync };
};
