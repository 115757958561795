import { AxiosResponse } from "axios";
import { AxiosNoAuth } from "../../../../utils";
import { AirportsParametersType, AirportsResponseType } from "./airports.types";

export const AirportsUrl = `${process.env.NEXT_PUBLIC_BASE_URL_FLIGHT}/flights/api/v3/airports`;

export const AirportsService = (
  parameters: AirportsParametersType
): Promise<AirportsResponseType> =>
  AxiosNoAuth.get(AirportsUrl, {
    params: parameters
  }).then(({ data }: AxiosResponse<AirportsResponseType>) => data);
