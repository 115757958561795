import { ChevronLeft, ChevronRight } from "@website/icons";
import { MonthActionsPropertiesType } from "./month-actions.types";

export const useMonthActions = (properties: MonthActionsPropertiesType) => {
  const {
    calendarLocale,
    showPreviousMonth,
    showNextMonth,
    nextMonthDisabled,
    previousMonthDisabled
  } = properties;

  return {
    showPreviousMonthButtonProperties: {
      onClick: showPreviousMonth,
      EndIcon: <ChevronLeft />,
      color: "neutral" as const,
      size: "small" as const,
      variant: "text" as const,
      "aria-label":
        calendarLocale === "persian" ? "نمایش ماه قبل" : "show-previous-month",
      equalDimension: true,
      disabled: previousMonthDisabled
    },
    showNextMonthButtonProperties: {
      onClick: showNextMonth,
      EndIcon: <ChevronRight />,
      color: "neutral" as const,
      size: "small" as const,
      variant: "text" as const,
      "aria-label":
        calendarLocale === "persian" ? "نمایش ماه بعد" : "show-next-month",
      equalDimension: true,
      disabled: nextMonthDisabled
    }
  };
};
