import { pick } from "lodash";
import { useEffect, useRef } from "react";
import { CheckboxPropertiesType } from "./checkbox.types";

export const useCheckbox = (properties: CheckboxPropertiesType) => {
  const { indeterminate } = properties;
  const inputReference = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (indeterminate && inputReference.current) {
      inputReference.current.indeterminate = true;
    }
  }, [indeterminate]);

  return {
    inputProps: {
      ...pick(
        properties,
        "autoFocus",
        "disabled",
        "checked",
        "name",
        "onChange",
        "readOnly",
        "value"
      ),
      type: "checkbox",
      ref: inputReference
    }
  };
};
