export const BUS_INTERNATIONAL_SEARCH_HISTORY_LOCAL_STORAGE_KEY =
  "bus-international-search-history";
export const BUS_INTERNATIONAL_ROUTE_HISTORY_LOCAL_STORAGE_KEY =
  "bus-international-route-history";

export const BUS_INTERNATIONAL_ORIGIN_SUGGESTIONS = [
  {
    code: "114008",
    name: "نجف",
    city: "نجف",
    cityEn: "Najaf",
    province: "عراق",
    isAbroad: true,
    childs: []
  },
  {
    code: "11320000",
    province: "تهران",
    name: "تهران",
    city: "تهران",
    cityEn: "Tehran",
    isAbroad: false,
    childs: []
  },
  {
    code: "31310000",
    province: "خراسان رضوی",
    name: "مشهد",
    city: "مشهد",
    cityEn: "Mashhad",
    isAbroad: false,
    childs: []
  },
  {
    code: "26310000",
    province: "آذربایجان شرقی",
    name: "تبریز",
    city: "تبریز",
    cityEn: "Tabriz",
    isAbroad: false,
    childs: []
  },
  {
    code: "57310000",
    name: "ارومیه",
    city: "ارومیه",
    cityEn: "Urmia",
    province: "آذربایجان غربی",
    isAbroad: false,
    childs: []
  },
  {
    code: "57350000",
    name: "خوی",
    city: "خوی",
    cityEn: "Khoy",
    province: "آذربایجان غربی",
    isAbroad: false,
    childs: []
  }
];

export const BUS_INTERNATIONAL_DESTINATION_SUGGESTIONS = [
  {
    code: "114008",
    name: "نجف",
    city: "نجف",
    cityEn: "Najaf",
    province: "عراق",
    isAbroad: true,
    childs: []
  },
  {
    code: "117002",
    name: "استانبول",
    city: "استانبول",
    cityEn: "Istanbul",
    province: "ترکیه",
    isAbroad: true,
    childs: []
  },
  {
    code: "117004",
    name: "وان",
    city: "وان",
    cityEn: "Van",
    province: "ترکیه",
    isAbroad: true,
    childs: []
  },
  {
    code: "117001",
    name: "آنکارا",
    city: "آنکارا",
    cityEn: "Ankara",
    province: "ترکیه",
    isAbroad: true,
    childs: []
  },
  {
    code: "156001",
    name: "ایروان",
    city: "ایروان",
    cityEn: "Yerevan",
    province: "ارمنستان",
    isAbroad: true,
    childs: []
  },
  {
    code: "114002",
    name: "اربیل",
    city: "اربیل",
    cityEn: "Arbil",
    province: "عراق",
    isAbroad: true,
    childs: []
  }
];
