import { memo, SVGProps } from "react";

const SvgBallon = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    height="1em"
    viewBox="0 0 48 48"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <path
      clip-rule="evenodd"
      d="M26.8113 29.9347C26.9242 29.8266 27.0464 29.7076 27.1767 29.5783C28.0261 28.7354 29.1458 27.5282 30.2522 26.1142C32.6323 23.0723 34.2777 19.9187 34.2777 17.5832C34.2777 12.6033 30.9933 8.39052 26.4726 6.99277L20.6601 6.79108C15.7603 7.93726 12.1113 12.3341 12.1113 17.5832C12.1113 18.6325 12.4726 19.9708 13.2565 21.5485C14.0265 23.0981 15.0944 24.6598 16.2318 26.0915C17.3608 27.5125 18.4998 28.7324 19.36 29.5989C19.4783 29.7181 19.5909 29.8302 19.6969 29.9347H20.9792C24.6229 29.9347 23.5811 29.9347 25.8135 29.9347H26.8113ZM25.8135 29.9347H20.9792C20.2514 28.8735 19.5335 27.7208 18.8851 26.5204C17.3879 23.749 16.1312 20.4879 16.1977 17.4175C16.2935 12.9986 18.0337 9.58219 20.6601 6.79108L26.4726 6.99277C25.7484 7.43513 25.0683 7.94802 24.4419 8.52284C21.7705 10.974 20.1667 13.7636 20.0856 17.5053C20.0409 19.5681 20.9209 22.11 22.3062 24.6744C23.3699 26.6436 24.6559 28.4879 25.8135 29.9347ZM28.2375 33.8236H18.1515C18.1515 33.8236 8.22241 25.0692 8.22241 17.5832C8.22241 9.31431 14.9256 2.61108 23.1945 2.61108C31.4633 2.61108 38.1666 9.31431 38.1666 17.5832C38.1666 25.0692 28.5386 33.8236 28.2375 33.8236Z"
      fill-rule="evenodd"
    />
    <path d="M25.8135 29.9347H20.9792C24.6229 29.9347 23.5811 29.9347 25.8135 29.9347Z" />
    <path d="M16.7779 37.8056C16.7779 37.2687 17.2132 36.8334 17.7501 36.8334H28.6389C29.1758 36.8334 29.6111 37.2687 29.6111 37.8056V42.4722C29.6111 44.083 28.3053 45.3889 26.6944 45.3889H19.6946C18.0837 45.3889 16.7779 44.083 16.7779 42.4722V37.8056Z" />
  </svg>
);

const Memo = memo(SvgBallon);
export default Memo;
