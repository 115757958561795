import { deepCompare } from "@website/utils";
import { FC, memo, useCallback } from "react";
import styles from "./menu-item.module.scss";
import { MenuItemPropertiesType } from "./menu.types";

const MenuItem: FC<MenuItemPropertiesType> = (properties) => {
  const {
    title,
    description,
    StartIcon,
    EndIcon,
    selected,
    disabled,
    onClick,
    value,
    size = "default"
  } = properties;

  const onClickItem = useCallback(() => {
    if (onClick && !disabled) {
      onClick(value);
    }
  }, [value, disabled, onClick]);

  return (
    <div
      className={`${styles["menu-item"]} ${
        selected ? styles["selected"] : ""
      } ${disabled ? styles["disabled"] : ""} ${styles[size]}`}
      onClick={onClickItem}
    >
      {StartIcon ? <div className={styles["start"]}>{StartIcon}</div> : null}
      <div className={styles["middle"]}>
        <div className={styles["title"]}>{title}</div>
        {description ? (
          <div className={styles["description"]}>{description}</div>
        ) : null}
      </div>
      {EndIcon ? <div className={styles["end"]}>{EndIcon}</div> : null}
    </div>
  );
};

const MenuItemMemoized = memo(MenuItem, deepCompare);
export { MenuItemMemoized as MenuItem };
