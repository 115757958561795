import { deepCompare } from "@website/utils";
import { omit } from "lodash";
import { FC, memo } from "react";
import styles from "./material.module.scss";
import { MaterialIconPropertiesType } from "./material.types";

const MaterialIconComponent: FC<MaterialIconPropertiesType> = (properties) => {
  const { className, name, variant, datatestid } = properties;
  return (
    <span
      className={`${styles["icon"]} ${styles[variant || "fill"]} ${
        className ?? ""
      }`}
      data-testid={datatestid}
      {...omit(properties, "name", "className", "variant")}
    >
      {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        typeof jest === "object" && typeof test === "function" ? "" : name
      }
    </span>
  );
};

export const MaterialIcon = memo(MaterialIconComponent, deepCompare);
