import { memo, SVGProps } from "react";

const SvgLinkedin = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    height="1em"
    viewBox="0 0 32 32"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <path d="M26.667 2.667H5.334a2.675 2.675 0 0 0-2.667 2.667v21.333c0 1.467 1.2 2.667 2.667 2.667h21.333c1.467 0 2.667-1.2 2.667-2.667V5.334c0-1.467-1.2-2.667-2.667-2.667zM10.8 24H6.667V12.533H10.8V24zM8.667 11.2c-1.2 0-2-.8-2-1.867s.8-1.867 2.133-1.867c1.2 0 2 .8 2 1.867S10 11.2 8.667 11.2zM25.333 24H21.2v-6.267c0-1.733-1.067-2.133-1.467-2.133S18 15.867 18 17.733V24h-4.133V12.533H18v1.6c.533-.933 1.6-1.6 3.6-1.6s3.6 1.6 3.6 5.2L25.333 24z" />
  </svg>
);

const Memo = memo(SvgLinkedin);
export default Memo;
