import { useViewPort } from "@website/hooks";
import { Close } from "@website/icons";
import { FC, memo, useRef } from "react";
import { Transition } from "react-transition-group";
import { usePosition, useShow } from "./hooks";
import styles from "./mini-popover.module.scss";
import { MiniPopoverPropertiesType } from "./mini-popover.types";

const MiniPopover: FC<MiniPopoverPropertiesType> = (properties) => {
  const { animate, children, title, fullWidth, gap, datatestid } = properties;
  const popoverReference = useRef<HTMLDivElement>(null);
  const {
    popoverProperties,
    transitionProps,
    closeButtonProps,
    popoverOpen,
    animateOpen,
    backdrop
  } = useShow(properties, popoverReference);
  const { horizontalPosition } = usePosition(properties, popoverReference);
  const { isMobile } = useViewPort();
  // const { sheetReference, sheetBottom } = useSheet(popoverOpen);
  // const { containerHeight } = useHeight();

  if (popoverOpen) {
    return (
      <Transition {...transitionProps} in={animateOpen}>
        {(state) => (
          <div
            className={`${styles["container"]} ${
              animate === "normal"
                ? ""
                : `${styles["animate-bottom"]} ${styles[state]}`
            } ${fullWidth ? styles["full"] : ""} ${
              styles[horizontalPosition]
            } `}
            data-testid={datatestid}
            onClick={
              isMobile && backdrop?.onClick ? backdrop?.onClick : undefined
            }
            style={{ marginTop: isMobile ? "0px" : gap || "0px" }}
            // ref={sheetReference}
            // style={isMobile ? { bottom: `${sheetBottom}px` } : {}}
            // style={{ height: isMobile ? `${containerHeight}px` : "auto" }}
          >
            {!isMobile && <div className={styles["backdrop"]} {...backdrop} />}
            <div
              ref={popoverReference}
              {...popoverProperties}
              className={`${styles["mini-popover"]} `}
            >
              {title ? (
                <div className={styles["header"]}>
                  <div className={styles["title"]}>{title}</div>
                  <div className={styles["close-button"]}>
                    <Close
                      className={styles["close-button-icon"]}
                      {...closeButtonProps}
                    />
                  </div>
                </div>
              ) : null}
              <div className={styles["main"]}>{children}</div>
            </div>
          </div>
        )}
      </Transition>
    );
  }
  return null;
};

const MiniPopoverMemoized = memo(MiniPopover);
export { MiniPopoverMemoized as MiniPopover };
