import { useRouter } from "next/router";
import { FC } from "react";
import { HistoryItem } from "./history-item";
import styles from "./history.module.scss";
import { HistoryPropertiesType } from "./history.types";

export const History: FC<HistoryPropertiesType> = (properties) => {
  const { list, onClick } = properties;
  const { pathname } = useRouter();
  const onClickHistory = (value: string) => () => {
    onClick && onClick(value);
  };
  return (
    <section className={styles["container"]}>
      <article className={styles["list"]}>
        {list.map((item) => (
          <HistoryItem
            key={item.url}
            onClick={onClickHistory?.(
              pathname && pathname === "/home-visa"
                ? item?.country || ""
                : item?.url || ""
            )}
            {...item}
          />
        ))}
      </article>
    </section>
  );
};
