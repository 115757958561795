import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { Button, FALLBACK_IMAGE_MD, LazyImage } from "@website/ui";
import { deepCompare, numberConvert } from "@website/utils";
import { useRouter } from "next/router";
import { memo } from "react";
import { ReserveGallerySingle } from "../reserve-gallery-detail/reserve-gallery-single";
import { ReserveGalleryDetailContainer } from "./reserve-gallery-detail-container";
import { useReserveGallery } from "./reserve-gallery.hook";
import { ReserveGalleryPropertiesTypes } from "./reserve-gallery.types";

const Component = (properties: ReserveGalleryPropertiesTypes) => {
  const router = useRouter();
  const { t } = useTranslation();
  const { gallery } = properties;
  const { imagesProps, detailProps, singleProps, moreProps } =
    useReserveGallery(properties);

  if (!gallery?.length) return null;

  return (
    <>
      <div
        className="relative grid grid-cols-1 grid-rows-1 gap-2 overflow-hidden md:h-[360px] md:w-full md:grid-cols-4 md:grid-rows-2 md:rounded-xl xl:h-[400px] xl:max-w-[1200px]"
        id="hotel_gallery"
      >
        {imagesProps?.map(({ imageProps, containerProps }, index) => (
          <div
            itemScope
            {...containerProps}
            className={`${containerProps.className} hidden first:block md:block`}
            key={containerProps?.key}
          >
            <LazyImage
              {...imageProps}
              className="bg-outline-thin h-[200px] w-full object-cover md:h-full"
              fallback={FALLBACK_IMAGE_MD.src}
              loading={index === 0 ? "eager" : "lazy"}
            />
          </div>
        ))}
        {gallery?.length > 5 && (
          <Button
            StartIcon={<MaterialIcon name="apps" />}
            className="absolute bottom-4 end-4 flex"
            {...moreProps}
          >
            {t("gallery.image_count", {
              count: numberConvert(gallery.length, {
                locale: router.locale
              })
            })}
          </Button>
        )}
      </div>
      <ReserveGalleryDetailContainer {...detailProps} />
      <ReserveGallerySingle {...singleProps} />
    </>
  );
};
export const ReserveGallery = memo(Component, deepCompare);
