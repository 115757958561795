import { useViewPort } from "@website/hooks";
import { CtaPropertiesType } from "./cta.types";

export const useCta = (properties: CtaPropertiesType) => {
  const { accept, reject, value, mode } = properties;
  const { isMobile } = useViewPort();

  const disabled =
    (mode === "single" && value.length === 0) ||
    (mode !== "single" && value.length <= 1);

  return {
    acceptButtonProperties: {
      onClick: accept,
      color: "secondary" as const,
      size: "large" as const,
      variant: "contained" as const,
      fullWidth: isMobile,
      disabled
    },
    rejectButtonProperties: {
      onClick: reject,
      color: "secondary" as const,
      size: "large" as const,
      variant: "text" as const,
      fullWidth: isMobile,
      disabled
    }
  };
};
