import { CollapsibleText } from "@website/ui-old";
import { FC } from "react";
import styles from "./seo-content.module.scss";
import { SeoContentPropertiesType } from "./seo-content.types";

export const SeoContent: FC<SeoContentPropertiesType> = (properties) => {
  const { list } = properties;

  if (list && list.length > 0) {
    return (
      <article className={styles["container"]}>
        {list.map((item) => (
          <CollapsibleText
            content={
              <div dangerouslySetInnerHTML={{ __html: item.description }} />
            }
            key={item.title}
            title={item.title}
          />
        ))}
      </article>
    );
  }

  return null;
};
