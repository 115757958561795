import { useCallback, useState } from "react";

export const useBottomSheet = (
  onDayChange: (value: string) => void,
  onMonthChange: (value: string) => void,
  onYearChange: (value: string) => void
) => {
  const [isOpenRollPicker, setIsOpenRollPicker] = useState<boolean>(false);

  const onDayChangeRoll = onDayChange;
  const onMonthChangeRoll = onMonthChange;
  const onYearChangeRoll = onYearChange;

  const onOpenRollPicker = useCallback(() => {
    setIsOpenRollPicker(true);
  }, []);

  const onCloseRollPicker = useCallback(() => {
    setIsOpenRollPicker(false);
  }, []);

  return {
    onOpenRollPicker,
    isOpenRollPicker,
    onDayChangeRoll,
    onCloseRollPicker,
    onMonthChangeRoll,
    onYearChangeRoll
  };
};
