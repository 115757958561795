import { AxiosResponse } from "axios";
import { Axios } from "../../utils";
import { BASE_URL_PROFILE } from "../urls";
import {
  ResetPasswordVerifyOtpBodyType,
  ResetPasswordVerifyOtpResponseType
} from "./reset-password-verify-otp.types";

export const ResetpasswordVerifyOtpUrl = `${BASE_URL_PROFILE}/reset-password/verify-otp`;

export const ResetPasswordVerifyOtpService = (
  body: ResetPasswordVerifyOtpBodyType
): Promise<ResetPasswordVerifyOtpResponseType> =>
  Axios.post(ResetpasswordVerifyOtpUrl, body).then(
    ({ data }: AxiosResponse<ResetPasswordVerifyOtpResponseType>) => data
  );
