import { Origin } from "@website/icons";
import { useTranslation } from "@website/locale";
import { useCallback, useMemo, useState } from "react";
import { NoResult } from "../../../..";
import { useCountryService } from "../../../../../services/visa/country/use-country.service";

export const useDestination = () => {
  const { t } = useTranslation();
  const { data: countries } = useCountryService();
  const [destination, setDestination] = useState({
    query: "",
    selected: null,
    id: ""
  });

  const onChangeDestinationQuery = useCallback((value: string) => {
    setDestination((destination) => ({
      ...destination,
      query: value
    }));
  }, []);

  //TODO: improvement
  const destinationList = useMemo(
    () =>
      destination.query.length >= 2
        ? countries.filter(
            (item) => item?.faName?.includes?.(destination.query)
          )
        : countries || [],
    [destination.query, countries]
  );

  const destinationListNormalized = useMemo(
    () =>
      destinationList.map((item) => ({
        id: `${item.name}`,
        title: item.faName,
        StartIcon: <Origin />
      })),
    [destinationList]
  );

  const onSelectDestination = useCallback(
    (id: string) => {
      const destinationItem = destinationList.find(
        (destination: any) => `${destination.name}` === `${id}`
      );
      setDestination({
        query: "",
        id,
        selected: destinationItem
      });
    },
    [destinationList]
  );

  const onChangeDestination = useCallback((item: any) => {
    setDestination({
      query: "",
      id: item?.id || "",
      selected: item
    });
  }, []);

  return {
    destination,
    onChangeDestination,
    onSelectDestination,
    onChangeDestinationQuery,
    destinationList,
    destinationListNormalized,
    DestinationNoResult:
      destination.query?.length >= 2 &&
      countries &&
      destinationList?.length === 0 ? (
        <NoResult query={destination.query} />
      ) : undefined,
    destinationLabel: t("homepage.visa.main_search.destination_label")
  };
};
