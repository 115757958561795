import {
  LocalStorageProvider,
  SessionStorageProvider,
  useAuth
} from "@website/hooks";
import "@website/ui/styles";
import type { AppProps } from "next/app";
import Head from "next/head";
import { Layout } from "../components";
// import { useRouter } from "next/router";
import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react";
import { LocaleType, TranslationProvider } from "@website/locale";
import { startUserTracking } from "@website/tracking";
import Script from "next/script";
import { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { SettingsProvider } from "../providers";
// import { TemporaryToggleTheme } from "../components";

const channel = process.env.NEXT_PUBLIC_CHANNEL_TYPE;

startUserTracking({
  product: () =>
    (
      ({
        "/": "dom-hotel",
        "/launcher": "dom-hotel",
        "/international-hotel": "int-hotel",
        "/home-visa": "int-hotel",
        "/flights": "dom-flight",
        "/flights-home": "dom-flight",
        "/interflights": "int-flight",
        "/inter-flights": "int-flight",
        "/bus": "dom-bus",
        "/bus-international": "int-bus",
        "/villa": "villa",
        "/tour": "tour",
        "/train": "dom-train",
        "/international-train": "int-train"
      }) as const
    )[new URL(window.location.href).pathname]
});

export const growthbook = new GrowthBook({
  apiHost:
    process.env["NEXT_PUBLIC_CHANNEL_TYPE"] === "ads"
      ? "https://gpi.snapptrip.info"
      : "https://gpi.snapptrip.com",
  clientKey: "sdk-Z2itvy5yPKT5xu",
  enableDevMode: true,
  subscribeToChanges: true
  // trackingCallback: (experiment, result) => {
  //   // TODO: Use your real analytics tracking system
  //   // console.log("Viewed Experiment", {
  //   //   experimentId: experiment.key,
  //   //   variationId: result.key
  //   // });
  // }
});
growthbook.init({
  // Optional, enable streaming updates
  streaming: true
});

const MyApp = ({ Component, pageProps }: AppProps) => {
  useAuth();
  const queryClient = new QueryClient();

  useEffect(() => {
    navigator.serviceWorker?.register("/sw.js");
  }, []);

  return (
    <>
      {channel === "jek" && (
        <Script id="google-tag-manager" strategy="lazyOnload">
          {`(function (w, d, s, l, i) {
              w[l] = w[l] || [];
              w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
              var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l != 'dataLayer' ? '&l=' + l : '';
              j.async = true;
              j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
              f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', 'GTM-P8WGVRK')`}
        </Script>
      )}
      {channel === "ads" && (
        <Script id="google-tag-manager" strategy="lazyOnload">
          {`(function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', 'GTM-KJPQFH4')`}
        </Script>
      )}
      {channel !== "jek" && channel !== "ads" && (
        <Script id="google-tag-manager" strategy="lazyOnload">
          {`(function (w, d, s, l, i) {
             w[l] = w[l] || [];
             w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
             var f = d.getElementsByTagName(s)[0],
               j = d.createElement(s),
               dl = l != 'dataLayer' ? '&l=' + l : '';
             j.async = true;
             j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
             f.parentNode.insertBefore(j, f);
           })(window, document, 'script', 'dataLayer', 'GTM-MT54VMN')`}
        </Script>
      )}

      {channel === "jek" ? (
        <Script id="_webengage_script_tag" strategy="lazyOnload">
          {`var webengage;
        ! function(w, e, b, n, g) {
            function o(e, t) {
                e[t[t.length - 1]] = function() {
                    r.__queue.push([t.join("."), arguments])
                }
            }
            var i, s, r = w[b],
                z = " ",
                l = "init options track screen onReady".split(z),
                a = "feedback survey notification".split(z),
                c = "options render clear abort".split(z),
                p = "Open Close Submit Complete View Click".split(z),
                u = "identify login logout setAttribute".split(z);
            if (!r || !r.__v) {
                for (w[b] = r = {
                        __queue: [],
                        is_spa: 1, //Change this to 0 if you do not wish to use default SPA behaviour of WebEngage SDK
                        __v: "6.0",
                        user: {}
                    }, i = 0; i < l.length; i++) o(r, [l[i]]);
                for (i = 0; i < a.length; i++) {
                    for (r[a[i]] = {}, s = 0; s < c.length; s++) o(r[a[i]], [a[i], c[s]]);
                    for (s = 0; s < p.length; s++) o(r[a[i]], [a[i], "on" + p[s]])
                }
                for (i = 0; i < u.length; i++) o(r.user, ["user", u[i]]);
                setTimeout(function() {
                    var f = e.createElement("script"),
                        d = e.getElementById("_webengage_script_tag");
                    f.type = "text/javascript", f.async = !0, f.src = ("https:" == e.location.protocol ? "https://ssl.widgets.webengage.com" : "http://cdn.widgets.webengage.com") + "/js/webengage-min-v-6.0.js", d.parentNode.insertBefore(f, d)
                })
            }
        }(window, document, "webengage");
        webengage.init('~47b6574d'); //replace the YOUR_WEBENGAGE_LICENSE_CODE with your WebEngage Account License Code`}
        </Script>
      ) : (
        <Script id="_webengage_script_tag" strategy="lazyOnload">
          {`var webengage;
        ! function(w, e, b, n, g) {
            function o(e, t) {
                e[t[t.length - 1]] = function() {
                    r.__queue.push([t.join("."), arguments])
                }
            }
            var i, s, r = w[b],
                z = " ",
                l = "init options track screen onReady".split(z),
                a = "feedback survey notification".split(z),
                c = "options render clear abort".split(z),
                p = "Open Close Submit Complete View Click".split(z),
                u = "identify login logout setAttribute".split(z);
            if (!r || !r.__v) {
                for (w[b] = r = {
                        __queue: [],
                        is_spa: 1, //Change this to 0 if you do not wish to use default SPA behaviour of WebEngage SDK
                        __v: "6.0",
                        user: {}
                    }, i = 0; i < l.length; i++) o(r, [l[i]]);
                for (i = 0; i < a.length; i++) {
                    for (r[a[i]] = {}, s = 0; s < c.length; s++) o(r[a[i]], [a[i], c[s]]);
                    for (s = 0; s < p.length; s++) o(r[a[i]], [a[i], "on" + p[s]])
                }
                for (i = 0; i < u.length; i++) o(r.user, ["user", u[i]]);
                setTimeout(function() {
                    var f = e.createElement("script"),
                        d = e.getElementById("_webengage_script_tag");
                    f.type = "text/javascript", f.async = !0, f.src = ("https:" == e.location.protocol ? "https://ssl.widgets.webengage.com" : "http://cdn.widgets.webengage.com") + "/js/webengage-min-v-6.0.js", d.parentNode.insertBefore(f, d)
                })
            }
        }(window, document, "webengage");
        webengage.init('11b5650c0'); //replace the YOUR_WEBENGAGE_LICENSE_CODE with your WebEngage Account License Code`}
        </Script>
      )}
      <Head>
        <title>SnappTrip</title>
        {process.env["NEXT_PUBLIC_CHANNEL_TYPE"] !== "web" && (
          <meta content="noindex,nofollow" name="robots" />
        )}
        <meta
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover"
          name="viewport"
        />
        <meta content="SnappTrip" name="application-name" />
        <meta content="no" name="apple-mobile-web-app-capable" />
        <meta content="SnappTrip" name="apple-mobile-web-app-title" />
        <meta content="SnappTrip dashboard" name="description" />
        <meta content="yes" name="mobile-web-app-capable" />
        <meta content="/icons/browserconfig.xml" name="msapplication-config" />
        <meta content="#ffffff" name="msapplication-TileColor" />
        <meta content="no" name="msapplication-tap-highlight" />
        <meta content="#ffffff" name="theme-color" />
        <meta content="black" name="mobile-web-app-status-bar-style" />
        <meta content="black" name="apple-mobile-web-app-status-bar-style" />
        <link
          crossOrigin="anonymous"
          href="https://storage.googleapis.com"
          rel="preconnect"
        />
      </Head>
      <GrowthBookProvider growthbook={growthbook}>
        <QueryClientProvider client={queryClient}>
          <LocalStorageProvider prefix="homepage" version={15}>
            <SessionStorageProvider prefix="homepage" version={14}>
              <SettingsProvider>
                <TranslationProvider locale={"fa" as LocaleType}>
                  <Layout>
                    <Component {...pageProps} />
                  </Layout>
                  {/* <TemporaryToggleTheme /> */}
                </TranslationProvider>
              </SettingsProvider>
            </SessionStorageProvider>
          </LocalStorageProvider>
        </QueryClientProvider>
      </GrowthBookProvider>
    </>
  );
};

export default MyApp;
