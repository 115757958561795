import { FALLBACK_IMAGE_SM } from "@website/ui";
import { getImage } from "@website/utils";
import { range, toString } from "lodash";
import { useRouter } from "next/router";
import { useCallback } from "react";
import { ReserveGalleryPropertiesTypes } from "./reserve-gallery.types";

export const useReserveGallery = ({
  title,
  gallery
}: ReserveGalleryPropertiesTypes) => {
  const router = useRouter();

  const map = {
    1: [
      {
        image: {
          ...gallery?.[0],
          media: getImage({
            width: 1584,
            height: 870,
            src: gallery?.[0]?.media
          })
        },
        className: "relative col-span-4 row-span-2"
      }
    ],
    2: [
      {
        image: {
          ...gallery?.[0],
          media: getImage({
            width: 1192,
            height: 800,
            src: gallery?.[0]?.media
          })
        },
        className: "relative col-span-2 row-span-2"
      },
      {
        image: {
          ...gallery?.[1],
          media: getImage({
            width: 1192,
            height: 700,
            src: gallery?.[1]?.media
          })
        },
        className: "relative col-span-2 row-span-2"
      }
    ],
    3: [
      {
        image: {
          ...gallery?.[0],
          media: getImage({
            width: 1192,
            height: 800,
            src: gallery?.[0]?.media
          })
        },
        className: "relative col-span-2 row-span-2"
      },
      ...range(1, gallery?.length).map((index) => ({
        image: {
          ...gallery?.[index],
          media: getImage({
            width: 590,
            height: 800,
            src: gallery?.[index]?.media
          })
        },
        className: "row-span-2"
      }))
    ],
    4: [
      {
        image: {
          ...gallery?.[0],
          media: getImage({
            width: 1192,
            height: 800,
            src: gallery?.[0]?.media
          })
        },
        className: "relative col-span-2 row-span-2"
      },
      {
        image: {
          ...gallery?.[1],
          media: getImage({
            width: 590,
            height: 392,
            src: gallery?.[1]?.media
          })
        },
        className: ""
      },
      {
        image: {
          ...gallery?.[2],
          media: getImage({
            width: 590,
            height: 800,
            src: gallery?.[2]?.media
          })
        },
        className: "row-span-2"
      },
      {
        image: {
          ...gallery?.[3],
          media: getImage({
            width: 590,
            height: 392,
            src: gallery?.[3]?.media
          })
        },
        className: ""
      }
    ],
    default: [
      {
        image: {
          ...gallery?.[0],
          media: getImage({
            width: 800,
            height: 460,
            src: gallery?.[0]?.media
          })
        },
        className: "relative col-span-2 row-span-2"
      },
      ...range(1, 5).map((index) => ({
        image: {
          ...gallery?.[index],
          media: getImage({
            width: 590,
            height: 392,
            src: gallery?.[index]?.media
          })
        },
        className: "bg-outline-thin h-[200px] w-[100%] object-cover md:h-[100%]"
      }))
    ]
  };

  const isModalOpen = toString(router.query?.modal) === "gallery";
  const setIsModalOpen = useCallback(
    (status: boolean, item?: string) =>
      status
        ? router.push(
            {
              pathname: router.pathname,
              query: { ...router.query, modal: "gallery", item }
            },
            undefined,
            { shallow: true, scroll: false }
          )
        : router.back(),
    [router]
  );

  return {
    imagesProps: map[
      (gallery?.length < 5 ? gallery?.length : "default") as keyof typeof map
    ]?.map(({ className, image }) => ({
      containerProps: {
        className,
        key: image?.id,
        onClick: setIsModalOpen.bind(null, true, toString(image?.id))
      },
      imageProps: {
        alt: image?.title,
        fallback: FALLBACK_IMAGE_SM.src,
        loading: "eager" as const,
        src: image?.media,
        title: image?.title
      }
    })),
    moreProps: {
      color: "neutral" as const,
      size: "small" as const,
      onClick: setIsModalOpen.bind(null, true)
    },
    detailProps: {
      title,
      gallery: gallery,
      isModalOpen: isModalOpen,
      onClose: setIsModalOpen.bind(null, false)
    },
    singleProps: {
      gallery: gallery
    }
  };
};
