import { useCallback, useEffect, useState } from "react";

export const useValidation = ({
  code,
  phoneNumber
}: {
  code: string;
  phoneNumber: string;
}) => {
  const [codeError, setCodeError] = useState<string>("");
  const [phoneNumberError, setPhoneNumberError] = useState<string>("");

  useEffect(() => {
    setCodeError("");
  }, [code]);

  useEffect(() => {
    if (phoneNumber.length === 0) {
      setPhoneNumberError("");
    } else {
      const regexp = /^(\+98|0098|98|0)?9\d{9}$/g;
      if (regexp.test(phoneNumber)) {
        setPhoneNumberError("");
      } else {
        setPhoneNumberError("شماره موبایل صحیح نمیباشد.");
      }
    }
  }, [phoneNumber]);

  const submitValidationCheck = useCallback(() => {
    if (
      code.length > 0 &&
      codeError.length === 0 &&
      phoneNumber.length > 0 &&
      phoneNumberError.length === 0
    ) {
      return true;
    }

    if (code.length === 0) {
      setCodeError("لطفا کد خود را وارد کنید.");
    }
    if (phoneNumber.length === 0) {
      setPhoneNumberError("لطفا شماره موبایل خود را وارد کنید.");
    }
    return false;
  }, [code, codeError, phoneNumber, phoneNumberError]);

  return {
    codeError,
    phoneNumberError,
    submitValidationCheck
  };
};
