import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { useSearchField } from "./search-field.hook";
import styles from "./search-field.module.scss";
import { SearchFieldPropertiesType } from "./search-field.types";

const SearchField: FC<SearchFieldPropertiesType> = (properties) => {
  const {
    className,
    disabled,
    fullWidth,
    size = "default",
    variant = "normal",
    color = "white",
    StartIcon,
    EndIcon
  } = properties;
  const { rootProps, inputProps, startIconProperties, endIconProperties } =
    useSearchField(properties);

  return (
    <div
      {...rootProps}
      className={`${styles["search-field"]} ${
        disabled ? styles["disabled"] : ""
      } ${styles[size]} ${styles[variant]} ${styles[color]} ${
        fullWidth ? styles["full-width"] : ""
      } ${className ?? ""}`}
    >
      <div className={styles["input-wrapper"]}>
        {StartIcon ? (
          <div {...startIconProperties} className={styles["start-icon"]}>
            {StartIcon}
          </div>
        ) : null}
        <input {...inputProps} className={styles["input"]} />
        {EndIcon ? (
          <div {...endIconProperties} className={styles["end-icon"]}>
            {EndIcon}
          </div>
        ) : null}
      </div>
    </div>
  );
};

const SearchFieldMemoized = memo(SearchField, deepCompare);
export { SearchFieldMemoized as SearchField };
