import { toNumber } from "lodash";

export const fetchTimeDiff = () =>
  fetch(`${process.env.NEXT_PUBLIC_BASE_URL_TIME}/hotel/api/v2/now`, {
    method: "GET"
  })
    .then((response) =>
      response
        .json()
        .then(({ now }) => toNumber(new Date(now)) - Date.now() || 0)
    )
    .catch(() => 0);
