import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import { StepperProperties } from "./stepper.types";

export const useStepper = (properties: StepperProperties) => {
  const router = useRouter();
  const { steps, activeStep } = properties;
  const [active, setActive] = useState<number>(0);
  useEffect(() => {
    const currentStep =
      steps[Number(activeStep)] === undefined
        ? activeStep <= 0
          ? 0
          : steps.length
        : activeStep;

    setActive(currentStep || 0);
  }, [steps, activeStep]);

  const onGoBack = useCallback(
    () =>
      window.history.length > 1
        ? router.back()
        : router.replace(`${process.env.NEXT_PUBLIC_BASE_URL_B2C}`),
    [router]
  );

  return {
    onGoBack,
    stepperProperties: {
      steps,
      active
    }
  };
};
