import { memo, SVGProps } from "react";

const SvgLuggage = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <g clipPath="url(#luggage_svg__a)">
      <path d="M9.5 18H8V9h1.5v9Zm3.25 0h-1.5V9h1.5v9ZM16 18h-1.5V9H16v9Zm1-12h-2V3c0-.55-.45-1-1-1h-4c-.55 0-1 .45-1 1v3H7c-1.1 0-2 .9-2 2v11c0 1.1.9 2 2 2 0 .55.45 1 1 1s1-.45 1-1h6c0 .55.45 1 1 1s1-.45 1-1c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2Zm-6.5-2.5h3V6h-3V3.5ZM17 19H7V8h10v11Z" />
    </g>
    <defs>
      <clipPath id="luggage_svg__a">
        <path d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(SvgLuggage);
export default Memo;
