import { useRouter } from "next/router";

export const useLayout = () => {
  const router = useRouter();

  return {
    headerProperties: {
      showAuth:
        router.pathname === "/" ||
        router.pathname === "/bus" ||
        router.pathname === "/bus-international" ||
        router.pathname === "/inter-flights" ||
        router.pathname === "/flights" ||
        router.pathname === "/tour" ||
        router.pathname === "/villa" ||
        router.pathname === "/international-hotel"
    },
    footerProperties: {}
  };
};
