import { MaterialIcon } from "@website/icons";
import { Button } from "@website/ui-old";
import { deepCompare } from "@website/utils";
import { FC, memo, PropsWithChildren } from "react";
import styles from "./header.module.scss";
import { HeaderPropertiesType } from "./header.types";

const Header: FC<PropsWithChildren<HeaderPropertiesType>> = (properties) => {
  const { className, id, title, onBack, onClose } = properties;

  return (
    <div className={`${styles["container"]} ${className ?? ""}`} id={id ?? ""}>
      {onBack && (
        <Button
          EndIcon={<MaterialIcon name="arrow_forward" />}
          color="neutral"
          equalDimension
          onClick={onBack}
          variant="text"
        />
      )}
      <h4 className={styles["title"]}>{title}</h4>
      {onClose && (
        <Button
          EndIcon={<MaterialIcon name="close" />}
          color="neutral"
          equalDimension
          onClick={onClose}
          variant="text"
        />
      )}
    </div>
  );
};

const HeaderMemoized = memo(Header, deepCompare);
export { HeaderMemoized as Header };
