import { FC, memo } from "react";
import { useLoading } from "./loading.hook";
import styles from "./loading.module.scss";
import { LoadingPropertiesType } from "./loading.types";

const Loading: FC<LoadingPropertiesType> = (properties) => {
  const { rootProps } = useLoading();
  const { color = "primary", size = "lg", children } = properties;

  return (
    <>
      {children ? <span>{children}</span> : null}
      <span
        {...rootProps}
        className={`${styles["container"]} ${styles[color]} ${styles[size]}`}
      >
        <span className={`${styles["dot"]} ${styles["odd"]}`} />
        <span className={`${styles["dot"]} ${styles["even"]}`} />
        <span className={`${styles["dot"]} ${styles["odd"]}`} />
      </span>
    </>
  );
};

const LoadingMemoized = memo(Loading);
export { LoadingMemoized as Loading };
