import { useEffect, useRef } from "react";
import { RouteButtonPropertiesType } from "./route-button.types";

export const useRouteButton = (properties: RouteButtonPropertiesType) => {
  const { onClick, selected, href, title } = properties;
  const rootReference = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (selected && rootReference.current) {
      rootReference.current.scrollIntoView?.({
        block: "center",
        inline: "center"
      });
    }
  }, [selected]);

  return {
    rootProperties: {
      ref: rootReference,
      href: href || "",
      onClick,
      "aria-label": `${title || ""}`
    }
  };
};
