import { isSSR } from "@website/utils";
import { FC, createContext, useContext, useMemo } from "react";
import {
  SessionStorageContextType,
  SessionStorageProviderPropertiesType
} from "./session-storage.types";

export const SessionStorageContext = createContext<SessionStorageContextType>({
  addToSessionStorage: (key: string, value: string) => null,
  removeFromSessionStorage: (key: string) => null,
  getFromSessionStorage: (key: string) => ""
});

export const SessionStorageProvider: FC<
  SessionStorageProviderPropertiesType
> = ({ children, version, prefix }) => {
  const value = useMemo(
    () => ({
      addToSessionStorage: (key: string, value: string) => {
        if (isSSR()) return;
        sessionStorage.setItem(`${prefix}-${version}-${key}`, value);
      },
      removeFromSessionStorage: (key: string) => {
        if (isSSR()) return;
        sessionStorage.removeItem(`${prefix}-${version}-${key}`);
      },
      getFromSessionStorage: (key: string) => {
        if (isSSR()) return null;
        return sessionStorage.getItem(`${prefix}-${version}-${key}`);
      }
    }),
    [version, prefix]
  );

  return (
    <SessionStorageContext.Provider value={value}>
      {children}
    </SessionStorageContext.Provider>
  );
};

export const useSessionStorage = () => {
  const context = useContext(SessionStorageContext);
  if (context === undefined) {
    throw new Error(
      "useSessionStorage must be used within a SessionStorageProvider"
    );
  }
  return context;
};
