import { memo, SVGProps } from "react";

const SvgYouTube = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <g clipPath="url(#clip0)">
      <path
        d="M21.5821 7.2003C21.3521 6.33421 20.6744 5.65211 19.8139 5.42063C18.2542 5 12 5 12 5C12 5 5.74586 5 4.18613 5.42063C3.32565 5.65215 2.64794 6.33421 2.41792 7.2003C2 8.77014 2 12.0455 2 12.0455C2 12.0455 2 15.3208 2.41792 16.8906C2.64794 17.7567 3.32565 18.4104 4.18613 18.6419C5.74586 19.0625 12 19.0625 12 19.0625C12 19.0625 18.2541 19.0625 19.8139 18.6419C20.6744 18.4104 21.3521 17.7567 21.5821 16.8906C22 15.3208 22 12.0455 22 12.0455C22 12.0455 22 8.77014 21.5821 7.2003ZM9.95453 15.0192V9.07171L15.1818 12.0455L9.95453 15.0192Z"
        fill="#040A1F"
        fillOpacity="0.6"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect fill="white" height="24" width="24" />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(SvgYouTube);
export default Memo;
