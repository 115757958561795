import { memo, SVGProps } from "react";

const SvgArrowLeft = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    height="1em"
    viewBox="0 0 6 8"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <path d="M6 .934a.5.5 0 0 0-.777-.416L.624 3.584a.5.5 0 0 0 0 .832l4.599 3.066A.5.5 0 0 0 6 7.066V.934Z" />
  </svg>
);

const Memo = memo(SvgArrowLeft);
export default Memo;
