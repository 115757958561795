import { History } from "@website/icons";
import { FC } from "react";
import styles from "./history-item.module.scss";
import { HistoryItemPropertiesType } from "./history.types";

export const HistoryItem: FC<HistoryItemPropertiesType> = (properties) => {
  const { title, description, url, onClick } = properties;

  return (
    <a className={styles["item"]} href={url} onClick={onClick}>
      <History className={styles["icon"]} />
      <div className={styles["detail"]}>
        <span className={styles["title"]}>{title}</span>
        <span className={styles["description"]}>{description}</span>
      </div>
    </a>
  );
};
