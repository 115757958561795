export const iconTypeConfig = {
  default: "info",
  warning: "warning_amber",
  error: "error_outline",
  success: "check_circle"
};
export const iconStyleConfig = {
  white: "text-on-color-high-emphasis",
  default: "text-on-surface-high-emphasis",
  warning: "text-inverse-warning",
  success: "text-success",
  error: "text-error"
};

export const alertConfig = {
  contained: {
    style: "text-on-color-high-emphasis",
    severity: {
      default: "bg-inverse-surface",
      warning: "bg-warning",
      error: "bg-error",
      success: "bg-success"
    }
  },
  outline: {
    style: "text-on-surface-high-emphasis border-2",
    severity: {
      default: "border-inverse-surface",
      warning: "border-warning",
      error: "border-error",
      success: "border-success"
    }
  },
  accent: {
    style: "text-on-surface-high-emphasis",
    severity: {
      default: "bg-transparent-secondary",
      warning: "bg-transparent-warning",
      error: "bg-transparent-error",
      success: "bg-transparent-success"
    }
  }
};
