import { omit } from "lodash";
import { FC, memo } from "react";
import { Button, Loading } from "..";
import styles from "./toggle-button.module.scss";
import { ToggleButtonPropertiesType } from "./toggle-button.types";

const ToggleButton: FC<ToggleButtonPropertiesType> = (properties) => {
  const {
    className,
    color = "primary",
    disabled,
    loading,
    size = "medium",
    variant = "contained",
    list,
    value,
    onChange,
    lineDirection = "horizontal"
  } = properties;

  return (
    <div
      className={`${styles["container"]} ${styles[lineDirection]} ${
        className ?? ""
      }`}
    >
      {loading ? (
        <Loading color="white" size="sm" />
      ) : (
        <>
          {list.map((item) => (
            <Button
              {...omit(
                item,
                "id",
                "disabled",
                "color",
                "loading",
                "size",
                "variant",
                "fullWidth"
              )}
              className={styles["btn-class-name"]}
              color={item.value === value ? color : "neutral"}
              disabled={disabled}
              key={item.href}
              // eslint-disable-next-line react/jsx-no-bind
              onClick={() => onChange?.(item?.value || "")}
              replace={item?.replace}
              size={size}
              variant={item.value === value ? variant : "text"}
            />
          ))}
        </>
      )}
    </div>
  );
};

const ToggleButtonMemoized = memo(ToggleButton);
export { ToggleButtonMemoized as ToggleButton };
