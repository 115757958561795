import { pick } from "lodash";
import { ListItemPropertiesType } from "./list-item.types";

export const useListItem = (properties: ListItemPropertiesType) => {
  const { href, target, onClick, role } = properties;

  return {
    rootProps: {
      default: {
        ...pick(properties, "aria-label")
      },
      button: {
        ...pick(properties, "aria-label"),
        role,
        onClick,
        tabIndex: 0
      },
      link: {
        ...pick(properties, "aria-label"),
        role,
        href,
        target,
        tabIndex: 0
      }
    }
  };
};
