import { gregorianToJalali, jalaliToGregorian } from "./date-converter.types";
/* tslint:disable */

export const gregorianToShamsi: gregorianToJalali = (gy, gm, gd) => {
  let jy, jm, jd, days;
  const gdm = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334];
  const gy2 = gm > 2 ? gy + 1 : gy;
  days =
    355_666 +
    365 * gy +
    Math.trunc((gy2 + 3) / 4) -
    Math.trunc((gy2 + 99) / 100) +
    Math.trunc((gy2 + 399) / 400) +
    gd +
    gdm[gm - 1];
  jy = -1595 + 33 * Math.trunc(days / 12_053);
  days %= 12_053;
  jy += 4 * Math.trunc(days / 1461);
  days %= 1461;
  if (days > 365) {
    jy += Math.trunc((days - 1) / 365);
    days = (days - 1) % 365;
  }
  if (days < 186) {
    jm = 1 + Math.trunc(days / 31);
    jd = 1 + (days % 31);
  } else {
    jm = 7 + Math.trunc((days - 186) / 30);
    jd = 1 + ((days - 186) % 30);
  }
  return [jy, jm, jd];
};

export const shamsiToGregorian: jalaliToGregorian = (jy, jm, jd) => {
  let gy, gm, gd, days;
  jy += 1595;
  days =
    -355_668 +
    365 * jy +
    Math.trunc(jy / 33) * 8 +
    Math.trunc(((jy % 33) + 3) / 4) +
    jd +
    (jm < 7 ? (jm - 1) * 31 : (jm - 7) * 30 + 186);
  gy = 400 * Math.trunc(days / 146_097);
  days %= 146_097;
  if (days > 36_524) {
    gy += 100 * Math.trunc(--days / 36_524);
    days %= 36_524;
    if (days >= 365) {
      days++;
    }
  }
  gy += 4 * Math.trunc(days / 1461);
  days %= 1461;
  if (days > 365) {
    gy += Math.trunc((days - 1) / 365);
    days = (days - 1) % 365;
  }
  gd = days + 1;
  const salA = [
    0,
    31,
    (gy % 4 === 0 && gy % 100 !== 0) || gy % 400 === 0 ? 29 : 28,
    31,
    30,
    31,
    30,
    31,
    31,
    30,
    31,
    30,
    31
  ];
  for (gm = 0; gm < 13 && gd > salA[Number.parseInt(gm.toString(), 10)]; gm++) {
    gd -= salA[Number.parseInt(gm.toString(), 10)];
  }
  return [gy, gm, gd];
};
