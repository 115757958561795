import { parseDateTime } from "@internationalized/date";
import { useTranslation } from "@website/locale";
import { DatepickerSelectedDateType } from "@website/ui-old";
import { useRouter } from "next/router";
import { useCallback } from "react";
import { SubmitSearchFlightsPropertiesType } from "../search-flights.types";

export const useSubmit = (
  originItem: any,
  destinationItem: any,
  saleType: string,
  date: DatepickerSelectedDateType,
  calendarLocale: string,
  passengerNumber: { adult: number; child: number; infant: number },
  addToHistory: (
    origin: any,
    destination: any,
    startDate: any,
    endDate: any,
    calendarLocale: string,
    saleType: any,
    passengerNumber: any
  ) => void,
  submitValidationCheck: () => boolean,
  properties: SubmitSearchFlightsPropertiesType
) => {
  const { t } = useTranslation();
  const router = useRouter();
  const channel = process.env.NEXT_PUBLIC_CHANNEL_TYPE;
  const { snappTripToken } = properties;

  const onSubmitClick = useCallback(() => {
    if (submitValidationCheck()) {
      let startDateTime = "";
      let endDateTime = "";
      let startDateObject = null;
      let endDateObject = null;
      let startDate = null;
      let endDate = null;
      if (saleType === "round-trip" && date && date[0] && date[1]) {
        startDateTime = date[0].dateTime;
        endDateTime = date[1].dateTime;

        startDateObject = parseDateTime(startDateTime);
        endDateObject = parseDateTime(endDateTime);

        startDate = `${startDateObject.year}-${startDateObject.month
          .toString()
          .padStart(2, "0")}-${startDateObject.day
          .toString()
          .padStart(2, "0")}`;
        endDate = `${endDateObject.year}-${endDateObject.month
          .toString()
          .padStart(2, "0")}-${endDateObject.day.toString().padStart(2, "0")}`;
      } else if (saleType === "one-way" && date && date[0]) {
        startDateTime = date[0].dateTime;

        startDateObject = parseDateTime(startDateTime);

        startDate = `${startDateObject.year}-${startDateObject.month
          .toString()
          .padStart(2, "0")}-${startDateObject.day
          .toString()
          .padStart(2, "0")}`;
      }

      if (
        originItem &&
        destinationItem &&
        ((startDate && saleType === "one-way") ||
          (endDate && saleType === "round-trip"))
      ) {
        addToHistory(
          originItem,
          destinationItem,
          startDateTime,
          endDateTime,
          calendarLocale,
          saleType,
          passengerNumber
        );

        if (channel === "jek") {
          const parameters = {
            ...router?.query,
            origin: originItem.iataCode,
            origin_city: originItem.city,
            destination: destinationItem.iataCode,
            destination_city: destinationItem.city,
            departDate: startDate || "",
            adults: `${passengerNumber.adult}`,
            children: `${passengerNumber.child}`,
            infants: `${passengerNumber.infant}`,
            ...(endDate && { returnDate: endDate }),
            authToken: snappTripToken,
            source: "searchBox"
          };
          window?.webengage?.track("flight-search-clicked", {
            ...parameters,
            channel,
            source: "searchBox"
          });
          window?.dataLayer?.push({
            event: "flights-search",
            ecommerce: {
              items: [
                {
                  ...router?.query,
                  origin_city: originItem.city,
                  destination_city: destinationItem.city,
                  departDate: startDate || "",
                  adults: `${passengerNumber.adult}`,
                  children: `${passengerNumber.child}`,
                  infants: `${passengerNumber.infant}`,
                  ...(endDate && { returnDate: endDate }),
                  authToken: snappTripToken,
                  source: "searchBox"
                }
              ]
            }
          });

          const urlParameters = new URLSearchParams(parameters);
          window.location.href = `${
            process.env.NEXT_PUBLIC_BASE_URL_B2C
          }/flights/result-outbound?${urlParameters.toString()}`;
        } else {
          const parameters = {
            date: startDate || "",
            roundTrip: saleType === "round-trip" ? "true" : "false",
            adultCount: `${passengerNumber.adult}`,
            childCount: `${passengerNumber.child}`,
            inLapCount: `${passengerNumber.infant}`,
            cabinType: "E",
            source: "searchBox",
            ...(endDate && { returnDate: endDate })
          };
          window?.webengage?.track("flight-search-clicked", {
            ...parameters,
            channel,
            source: "searchBox"
          });
          window?.dataLayer?.push({
            event: "flights-search",
            ecommerce: {
              items: [
                {
                  ...router?.query,
                  origin_city: originItem.city,
                  destination_city: destinationItem.city,
                  departDate: startDate || "",
                  adults: `${passengerNumber.adult}`,
                  children: `${passengerNumber.child}`,
                  infants: `${passengerNumber.infant}`,
                  ...(endDate && { returnDate: endDate }),
                  authToken: snappTripToken,
                  source: "searchBox"
                }
              ]
            }
          });

          const urlParameters = new URLSearchParams(parameters);
          window.location.href = `${
            process.env.NEXT_PUBLIC_BASE_URL_B2C
          }/flights/${originItem.iataCode}-${originItem.enCity}/${
            destinationItem.iataCode
          }-${destinationItem.enCity}?${urlParameters.toString()}`;
        }
      }
    }
  }, [
    submitValidationCheck,
    saleType,
    date,
    originItem,
    destinationItem,
    addToHistory,
    calendarLocale,
    passengerNumber,
    channel,
    router?.query,
    snappTripToken
  ]);

  return {
    onSubmitClick,
    submitText: t("action.search")
  };
};
