import { useBack } from "@website/hooks";
import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { Button, ListItem, Popover } from "@website/ui";
import { numberConvert } from "@website/utils";
import { FC, memo } from "react";
import { PurchaseMenu } from "./components";
import styles from "./header-jek.module.scss";
import { HeaderJekPropertiesType } from "./header-jek.types";
import { useLogin, usePurchaseMenu, useSupportMenu } from "./hooks";

const HeaderJek: FC<HeaderJekPropertiesType> = (properties) => {
  const {
    showShareButton = false,
    showSupportButton = false,
    showPurchaseMenu = false,
    showBackButton = true,
    redirectUrl,
    onBackButtonClick,
    snappTripToken,
    title = "سفر"
  } = properties;
  const { showAuth, onProfileClick } = useLogin();
  const {
    purchaseList,
    purchaseMenuIsOpen,
    onPurchaseMenuClose,
    onPurchaseMenuOpen
  } = usePurchaseMenu(snappTripToken);
  const { onSupportMenuOpen, onSupportMenuClose, supportMenuIsOpen } =
    useSupportMenu();
  const { t, locale } = useTranslation();
  const { goBack } = useBack(redirectUrl);

  return (
    <div className="relative z-30 flex h-16 w-full items-center justify-between bg-white px-4">
      <div className="flex flex-row items-center gap-2">
        {showBackButton && (
          <Button
            EndIcon={
              <MaterialIcon
                className="text-on-surface-medium-emphasis"
                name="arrow_forward"
                variant="outlined"
              />
            }
            aria-label="close"
            color="neutral"
            equalDimension
            onClick={onBackButtonClick || goBack}
            size="small"
            variant="text"
          />
        )}
        <h6 className="text-headline-6 font-normal">{title}</h6>
      </div>
      <div className="flex flex-row items-center justify-end gap-4">
        {showShareButton && (
          <Button
            EndIcon={
              <MaterialIcon
                className="text-on-surface-medium-emphasis"
                name="share"
                variant="outlined"
              />
            }
            aria-label="share"
            color="neutral"
            equalDimension
            size="small"
            variant="text"
          />
        )}

        {showSupportButton && (
          <div>
            <Button
              EndIcon={
                <MaterialIcon
                  className="text-on-surface-medium-emphasis"
                  name="support_agent"
                />
              }
              aria-label={t("header.show_support_links")}
              color="neutral"
              equalDimension
              onClick={onSupportMenuOpen}
              role="button"
              size="medium"
              variant="text"
            />

            <Popover
              gap="16px"
              horizontalPosition="end"
              onClose={onSupportMenuClose}
              open={supportMenuIsOpen}
            >
              <div>
                {["website", "web"].includes(
                  process.env.NEXT_PUBLIC_CHANNEL_TYPE
                ) && (
                  <ListItem
                    StartIcon={<MaterialIcon name="help_outline" />}
                    aria-label={t("header.faq")}
                    href={process.env.NEXT_PUBLIC_BASE_URL_B2C}
                    role="link"
                    target="_blank"
                    title={t("header.faq")}
                  />
                )}

                <ListItem
                  StartIcon={<MaterialIcon name="support_agent" />}
                  aria-label={t("header.call_support")}
                  href="tel:+982192000123"
                  role="link"
                  subtitle={
                    <div className="text-end" dir="ltr">
                      {numberConvert("+98 21 92000123", {
                        locale
                      })}
                    </div>
                  }
                  title={t("header.call_support")}
                />
              </div>
            </Popover>
          </div>
        )}
        {showPurchaseMenu && (
          <div className={styles["purchase-wrapper"]}>
            <Button
              EndIcon={<MaterialIcon name="luggage" variant="outlined" />}
              aria-label="Show Purchase List"
              color="neutral"
              equalDimension
              onClick={onPurchaseMenuOpen}
              variant="text"
            />
            <PurchaseMenu
              isOpen={purchaseMenuIsOpen}
              list={purchaseList}
              onClose={onPurchaseMenuClose}
            />
          </div>
        )}
        {showAuth && (
          <Button
            EndIcon={<MaterialIcon name="person_outline" variant="outlined" />}
            aria-label="share"
            color="neutral"
            equalDimension
            onClick={onProfileClick}
            role="button"
            size="small"
            target="_blank"
            variant="text"
          />
        )}
      </div>
    </div>
  );
};

const HeaderJekMemoized = memo(HeaderJek);
export { HeaderJekMemoized as HeaderJek };
