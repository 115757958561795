import { deepCompare } from "@website/utils";
import { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { UseDebounceType } from "./debounce.types";

export const useDebounce: UseDebounceType = (value, timeout) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const onChange = useMemo(
    () =>
      debounce(
        (value) =>
          setDebouncedValue((state) =>
            deepCompare(value, state) ? state : value
          ),
        timeout ?? 500
      ),
    [timeout]
  );
  useEffect(() => onChange(value), [onChange, value]);
  return debouncedValue;
};
