import { createRef, useCallback, useEffect, useMemo, useRef } from "react";
import { CarouselPropertiesType } from "./carousel.types";

export const useCarousel = (properties: CarouselPropertiesType) => {
  const { list } = properties;

  const carouselList = useMemo(
    () =>
      list.map((component, index) => ({
        component,
        ref: createRef<HTMLDivElement>(),
        itemIndex: index
      })),
    [list]
  );

  const containerReference = useRef<HTMLDivElement | null>(null);

  const currentIndex = useRef(0);
  const disabled = useRef(false);
  const disableOnClick = useRef(false);
  const swipeIsActive = useRef(false);
  const hasSwiped = useRef(false);
  const position = useRef({ startX: 0, scrollLeft: 0 });
  const direction = useRef("");

  const getItemWidth = useCallback(() => {
    if (carouselList[0]?.ref.current) {
      return carouselList[0].ref.current.offsetWidth + 16;
    }
    return 0;
  }, [carouselList]);

  const onClick = (event: any) => {
    if (disableOnClick.current) {
      event.preventDefault();
      disableOnClick.current = false;
    }
  };

  const pointerdownHandler = (event: MouseEvent) => {
    event.preventDefault();
    if (disabled.current || !containerReference.current) {
      return;
    }
    swipeIsActive.current = true;

    position.current.startX = event.clientX;
    position.current.scrollLeft = containerReference.current.scrollLeft;
  };

  const pointermoveHandler = (event: MouseEvent) => {
    event.preventDefault();
    if (!swipeIsActive.current || !containerReference.current) {
      return;
    }
    hasSwiped.current = true;

    const currentX = event.clientX;
    const walk = (currentX - position.current.startX) * 1.5;
    containerReference.current.scrollLeft = position.current.scrollLeft - walk;

    if (Math.abs(currentX - position.current.startX) > 5) {
      disableOnClick.current = true;
    }
  };

  const animateToIndex = useCallback(
    (index: number) => {
      const containerElement = containerReference.current;
      const nextItemElement = carouselList[index]?.ref.current;
      if (containerElement && nextItemElement) {
        let left = 0;
        if (direction.current === "rtl") {
          left =
            nextItemElement.offsetLeft +
            nextItemElement.offsetWidth -
            containerElement.offsetWidth;
        } else if (direction.current === "ltr") {
          left = nextItemElement.offsetLeft;
        }
        currentIndex.current = index;
        containerReference.current?.scrollTo({ left, behavior: "smooth" });
      }
    },
    [carouselList]
  );

  const pointerupHandler = useCallback(
    (event: MouseEvent) => {
      swipeIsActive.current = false;

      if (!containerReference.current || !hasSwiped.current) {
        return;
      }

      hasSwiped.current = false;
      const currentX = event.clientX;
      const itemWidth = getItemWidth();
      const scrolled = containerReference.current.scrollLeft;
      const walkedLength = Math.abs(scrolled) / itemWidth;
      const index =
        (currentX > position.current.startX && direction.current === "rtl") ||
        (currentX < position.current.startX && direction.current === "ltr")
          ? Math.ceil(walkedLength)
          : Math.floor(walkedLength);

      animateToIndex(index);
    },
    [animateToIndex, getItemWidth]
  );

  const animateToPrevious = useCallback(() => {
    if (!containerReference.current) {
      return;
    }
    const itemWidth = getItemWidth();
    const scrolled = containerReference.current.scrollLeft;
    const walkedLength = (Math.abs(scrolled) - itemWidth) / itemWidth;
    const index = Math.round(walkedLength);

    animateToIndex(index);
  }, [animateToIndex, getItemWidth]);

  const animateToNext = useCallback(() => {
    if (!containerReference.current) {
      return;
    }
    const itemWidth = getItemWidth();
    const scrolled = containerReference.current.scrollLeft;
    const walkedLength = (Math.abs(scrolled) + itemWidth) / itemWidth;
    const index = Math.round(walkedLength);

    animateToIndex(index);
  }, [animateToIndex, getItemWidth]);

  const onKeydown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "ArrowLeft") {
        animateToNext();
      } else if (event.key === "ArrowRight") {
        animateToPrevious();
      }
    },
    [animateToNext, animateToPrevious]
  );

  useEffect(() => {
    const containerElement = containerReference?.current;
    if (containerElement) {
      containerElement.addEventListener("keydown", onKeydown);
      containerElement.addEventListener("pointerdown", pointerdownHandler);
      containerElement.addEventListener("pointerup", pointerupHandler);
      containerElement.addEventListener("pointerleave", pointerupHandler);
      containerElement.addEventListener("pointermove", pointermoveHandler);
      containerElement.scrollTo?.({ left: 0 });
      direction.current = getComputedStyle(containerElement).direction;
    }

    return () => {
      if (containerElement) {
        containerElement.removeEventListener("keydown", onKeydown);
        containerElement.removeEventListener("pointerdown", pointerdownHandler);
        containerElement.removeEventListener("pointerup", pointerupHandler);
        containerElement.removeEventListener("pointerleave", pointerupHandler);
        containerElement.removeEventListener("pointermove", pointermoveHandler);
      }
    };
  }, [onKeydown, pointerupHandler]);

  return {
    containerProperties: {
      ref: containerReference,
      tabIndex: 0,
      onClick: onClick
    },
    previousButtonProperties: {
      color: "neutral" as const,
      onClick: animateToPrevious,
      variant: "round" as const,
      "aria-label": "previous slide",
      equalDimension: true
    },
    nextButtonProperties: {
      color: "neutral" as const,
      onClick: animateToNext,
      variant: "round" as const,
      "aria-label": "previous slide",
      equalDimension: true
    },
    carouselList
  };
};
