import { useQuery } from "react-query";
import {
  StationsService,
  StationsUrl
} from "../../../../../services/train/stations";

export const useStationsService = () => {
  const {
    isLoading,
    data
    // error
  } = useQuery([StationsUrl], () => StationsService());
  return { isLoading, data };
};
