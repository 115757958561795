import { memo, SVGProps } from "react";

const SvgTwitter = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    height="1em"
    viewBox="0 0 32 32"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <path d="M27.2 10.184v.84c0 7.56-5.88 16.38-16.38 16.38-3.22.14-6.3-.98-8.82-2.52h1.4c2.66 0 5.18-.98 7.28-2.38-2.66 0-4.62-1.82-5.46-4.06.42 0 .84.14.98.14.56 0 .98 0 1.54-.14-2.66-.56-4.62-2.8-4.62-5.6.84.42 1.68.56 2.66.84-1.54-1.4-2.66-3.08-2.66-5.04 0-.98.14-1.96.84-2.8 2.8 3.36 7 5.88 11.76 6.02 0-.42-.14-.84-.14-1.4 0-3.22 2.66-5.88 5.88-5.88 1.54 0 3.22.56 4.2 1.82 1.4-.14 2.66-.84 3.64-1.4-.42 1.4-1.4 2.38-2.66 3.22 1.12-.14 2.24-.42 3.36-.98-.7 1.26-1.82 2.24-2.8 2.94z" />
  </svg>
);

const Memo = memo(SvgTwitter);
export default Memo;
