import { AngleLeftRegular, AngleRightRegular } from "@website/icons";
import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { TabButton } from "../tab-button";
import { useTabBar } from "./tab-bar.hook";
import styles from "./tab-bar.module.scss";
import { TabBarPropertiesType } from "./tab-bar.types";

const TabBar: FC<TabBarPropertiesType> = (properties) => {
  const {
    "aria-label": ariaLabel,
    className,
    color = "primary",
    variant = "contained",
    iconPosition = "top",
    orientation = "horizontal",
    behavior = "button",
    size = "content",
    tabs,
    value,
    full = false
  } = properties;

  const { buttonsContainerProps, nextScrollProps, previousScrollProps } =
    useTabBar(properties);

  return (
    <div
      className={`${styles["tab-bar"]} ${styles[orientation]} ${
        styles[color]
      } ${styles[variant]} ${className ?? ""}`}
    >
      <div {...nextScrollProps} className={styles["nav-button"]}>
        <AngleLeftRegular className={styles["nav-button-icon"]} />
      </div>
      <div
        {...buttonsContainerProps}
        aria-label={ariaLabel}
        className={styles["buttons-container"]}
        data-testid="tab-list"
        role="tablist"
      >
        {tabs.map((item, index) => (
          <TabButton
            Icon={item.Icon}
            Wrapper={item.Wrapper}
            badge={item.badge}
            behavior={behavior}
            color={color}
            datatestid={item.datatestid}
            description={item.description}
            disabled={item.disabled}
            full={full}
            href={item.href}
            iconPosition={iconPosition}
            index={index}
            key={item.title}
            onClick={item.onClick}
            selected={value === index}
            size={size}
            title={item.title}
            variant={variant}
          />
        ))}
      </div>
      <div {...previousScrollProps} className={styles["nav-button"]}>
        <AngleRightRegular className={styles["nav-button-icon"]} />
      </div>
    </div>
  );
};

const TabBarMemoized = memo(TabBar, deepCompare);
export { TabBarMemoized as TabBar };
