import { useCallback, useState } from "react";

export const useSheet = (
  onDayChange: (value: string) => void,
  onMonthChange: (value: string) => void,
  onYearChange: (value: string) => void
) => {
  const [isOpenDayPicker, setIsOpenDayPicker] = useState<boolean>(false);
  const [isOpenMonthPicker, setIsOpenMonthPicker] = useState<boolean>(false);
  const [isOpenYearPicker, setIsOpenYearPicker] = useState<boolean>(false);

  const onOpenDayPicker = useCallback(() => {
    setIsOpenDayPicker(true);
  }, []);

  const onCloseDayPicker = useCallback(() => {
    setIsOpenDayPicker(false);
  }, []);

  const onDayChangePicker = useCallback(
    (value: string) => {
      onDayChange(value);
      setIsOpenDayPicker(false);
    },
    [onDayChange]
  );

  const onOpenMonthPicker = useCallback(() => {
    setIsOpenMonthPicker(true);
  }, []);

  const onCloseMonthPicker = useCallback(() => {
    setIsOpenMonthPicker(false);
  }, []);

  const onMonthChangePicker = useCallback(
    (value: string) => {
      onMonthChange(value);
      setIsOpenMonthPicker(false);
    },
    [onMonthChange]
  );

  const onOpenYearPicker = useCallback(() => {
    setIsOpenYearPicker(true);
  }, []);

  const onCloseYearPicker = useCallback(() => {
    setIsOpenYearPicker(false);
  }, []);

  const onYearChangePicker = useCallback(
    (value: string) => {
      onYearChange(value);
      setIsOpenYearPicker(false);
    },
    [onYearChange]
  );

  return {
    onCloseDayPicker,
    isOpenDayPicker,
    onDayChangePicker,
    onCloseMonthPicker,
    isOpenMonthPicker,
    onMonthChangePicker,
    onCloseYearPicker,
    isOpenYearPicker,
    onYearChangePicker,
    onOpenYearPicker,
    onOpenMonthPicker,
    onOpenDayPicker
  };
};
