import { MaterialIcon } from "@website/icons";
import { MouseEvent, useCallback, useEffect, useRef, useState } from "react";
import { AccordionPropertiesType } from "./accordion.types";

export const useAccordionItem = (properties: AccordionPropertiesType) => {
  const contentReference = useRef(null);
  const {
    disabled,
    initialExpanded,
    EndIcon,
    EndIconExpanded,
    endText,
    endTextExpanded
  } = properties;
  const [expanded, setExpanded] = useState<boolean>(initialExpanded ?? false);
  const [height, setHeight] = useState<"auto" | number>("auto");

  const onDetailsClick = useCallback(
    (event: MouseEvent<HTMLDetailsElement>) => {
      event.preventDefault();

      if (!disabled) {
        setExpanded((state) => !state);
      }
    },
    [disabled]
  );

  useEffect(() => {
    if (expanded && contentReference.current) {
      setHeight(contentReference.current["scrollHeight"]);
    } else {
      setHeight(0);
    }
  }, [expanded]);

  return {
    detailsProperties: {
      open: expanded
    },
    summeryProperties: {
      onClick: onDetailsClick
    },
    buttonProperties: {
      "aria-label": expanded ? "collapse" : "expand",
      disabled: disabled,
      color: "secondary" as const,
      variant: "text" as const,
      equalDimension: !endText,
      EndIcon: expanded
        ? endText
          ? undefined
          : EndIconExpanded || (
              <MaterialIcon name="keyboard_arrow_up" variant="outlined" />
            )
        : endTextExpanded
          ? undefined
          : EndIcon || (
              <MaterialIcon name="keyboard_arrow_down" variant="outlined" />
            )
    },
    buttonText: expanded ? endTextExpanded || "" : endText || "",
    contentWrapperProperties: {
      ref: contentReference,
      style: {
        height
      }
    }
  };
};
