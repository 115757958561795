import { CounterButton } from "@website/ui";
import { toNumber } from "lodash";
import { useRouter } from "next/router";
import { useEffect, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { CounterTypes } from "../filter.types";
import styles from "../index.module.scss";

interface CounterFilterProperties extends CounterTypes {
  name: string;
}

export const CounterFilter = (properties: CounterFilterProperties) => {
  const { title, name, description, defaultValue, type, min, max } = properties;
  const { control, setValue } = useFormContext();
  const router = useRouter();

  const parsedInitialValues = useMemo(() => {
    const query = router.query[name];

    return toNumber(query ?? defaultValue ?? 1);
  }, [defaultValue, name, router.query]);

  useEffect(() => {
    setValue(name, { type, value: parsedInitialValues });
  }, [name, parsedInitialValues, setValue, type]);

  return (
    <div className={styles["filter-counter-container"]}>
      <div className={styles["filter-counter-info"]}>
        <span className={styles["filter-counter-title"]}>{title}</span>
        <span className={styles["filter-counter-description"]}>
          {description}
        </span>
      </div>
      <Controller
        control={control}
        name={`${name}.value`}
        render={({ field: { value, onChange } }) => (
          <CounterButton
            color="secondary"
            decreaseDisabled={min ? value <= min : false}
            increaseDisabled={max ? value >= max : false}
            locale={router.locale}
            onChange={onChange}
            value={value ?? min}
            variant="outline"
          />
        )}
      />
    </div>
  );
};
