import { isArray, min } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { CollapsibleTextPropertiesType } from "./collapsible-text.types";

export const useCollapsibleTextItem = (
  properties: CollapsibleTextPropertiesType
) => {
  const {
    content,
    ariaLabel,
    disabled,
    itemsToShow = 0,
    height = 84
  } = properties;
  const [collapsed, setCollapsed] = useState<boolean>(disabled ?? true);
  const [collapsible, setCollapsible] = useState<boolean>(true);
  const reference = useRef<HTMLDivElement>(null);
  const [elementHeight, setElementHeight] = useState<number>(height);

  const buttonId = `button-${ariaLabel.replaceAll(" ", "_")}`;
  const regionId = `region-${ariaLabel.replaceAll(" ", "_")}`;

  const onClick = useCallback(
    () => setCollapsed((state) => !disabled && !state),
    [disabled]
  );

  const output =
    collapsed && isArray(content) && itemsToShow
      ? content?.slice(0, itemsToShow)
      : content;

  useEffect(() => {
    if (reference.current) {
      if (itemsToShow && collapsible)
        setElementHeight(reference.current?.clientHeight);
      else
        setElementHeight(
          min([height, reference.current?.scrollHeight]) as number
        );
    }
  }, [collapsed, itemsToShow, height, collapsible]);

  useEffect(
    () =>
      setCollapsible(
        !disabled &&
          (isArray(content) && itemsToShow
            ? content?.length > itemsToShow
            : (reference.current?.scrollHeight ?? 0) > (elementHeight ?? 0))
      ),
    [content, itemsToShow, elementHeight, disabled]
  );

  return {
    regionProperties: {
      ...(collapsible && { "aria-labelledby": buttonId }),
      id: regionId,
      role: "region",
      style: {
        height:
          collapsed && !itemsToShow
            ? elementHeight
            : collapsed && itemsToShow
              ? reference?.current?.clientHeight
              : reference?.current?.scrollHeight
      }
    },
    descriptionProperties: {
      ref: reference,
      children: output
    },
    buttonProperties: {
      "aria-controls": regionId,
      "aria-expanded": disabled || collapsed,
      "aria-label": disabled || collapsed ? "Show More" : "Show Less",
      "aria-disabled": disabled,
      disabled,
      id: buttonId,
      onClick
    },
    collapsed,
    collapsible
  };
};
