import {
  MarketingBannerItemType,
  MarketingBannerResponseType
} from "../services/fp/banners/banner/active";

export const findSortBanner = (
  banners: MarketingBannerResponseType,
  type: "slider" | "popup" | "medium"
): MarketingBannerItemType | null => {
  const banner = banners?.find?.((item) => item?.type === type);
  if (!banner) return null;

  return {
    ...banner,
    data: banner?.data?.sort?.((a, b) => a?.order - b?.order) || null
  };
};
