import { useTranslation } from "@website/locale";
import { useCallback, useState } from "react";

export const usePassengerCount = () => {
  const { t } = useTranslation();
  const [temporaryPassengerCount, setTemporaryPassengerCount] =
    useState<number>(1);
  const [passengerCount, setPassengerCount] = useState<number>(
    temporaryPassengerCount
  );

  const onChange = useCallback((value: number) => {
    setPassengerCount(value);
  }, []);

  const onSubmitPassengerCount = useCallback(() => {
    setPassengerCount((state) => {
      setTemporaryPassengerCount(state);
      return state;
    });
  }, []);

  const onClosePassengerButton = useCallback(() => {
    setTemporaryPassengerCount((state) => {
      setPassengerCount(state);
      return state;
    });
  }, []);

  const onClickBackDropHandler = useCallback(() => {
    setPassengerCount((state) => {
      setTemporaryPassengerCount(state);
      return state;
    });
  }, []);

  const maxCount = 9;
  const minCount = 1;

  const list = [
    {
      increaseDisabled: passengerCount >= maxCount,
      decreaseDisabled: passengerCount <= minCount,
      title: t("homepage.bus.main_search.number_of_person"),
      subTitle: "",
      value: passengerCount,
      onChange: onChange
    }
  ];

  return {
    passengerCountTitle: t("homepage.bus.main_search.number_of_passenger"),
    passengerCountResult: t("homepage.bus.main_search.x_passenger", {
      passenger_count: passengerCount
    }),
    passengerCountList: list,
    passengerCount,
    onSubmitPassengerCount,
    onClosePassengerButton,
    onClickBackDropHandler
  };
};
