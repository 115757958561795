import { useViewPort } from "@website/hooks";
import { ArrowDropDown, MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import {
  Button,
  Chips,
  Dropdown,
  ListItem,
  MiniChips,
  MiniPopover
} from "@website/ui-old";
import { numberConvert } from "@website/utils";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { usePassengerRoomCountTour } from "./passenger-room-count-tour.hook";
import styles from "./passenger-room-count-tour.module.scss";
import { PassengerRoomCountTourPropertiesType } from "./passenger-room-count-tour.types";

export const PassengerRoomCountTour: FC<
  PassengerRoomCountTourPropertiesType
> = (properties) => {
  const [adultWarningIsShow, setAdultWarningIsShow] = useState<boolean>(false);
  const {
    list,
    chipsProps,
    popoverProps,
    submitButtonProps,
    submitText,
    onChange
  } = usePassengerRoomCountTour(properties);
  const { t, locale } = useTranslation();
  const {
    onDelete,
    onAdd,
    onChangeChildAge,
    ageList = [
      {
        title: t("homepage.main_search.to_n_age", { age: 1 }),
        value: "1"
      },
      {
        title: t("homepage.main_search.m_to_n_age", { age_1: 1, age_2: 2 }),
        value: "2"
      },
      {
        title: t("homepage.main_search.m_to_n_age", { age_1: 2, age_2: 3 }),
        value: "3"
      },
      {
        title: t("homepage.main_search.m_to_n_age", { age_1: 3, age_2: 4 }),
        value: "4"
      },
      {
        title: t("homepage.main_search.m_to_n_age", { age_1: 4, age_2: 5 }),
        value: "5"
      },
      {
        title: t("homepage.main_search.m_to_n_age", { age_1: 5, age_2: 6 }),
        value: "6"
      },
      {
        title: t("homepage.main_search.m_to_n_age", { age_1: 6, age_2: 7 }),
        value: "7"
      },
      {
        title: t("homepage.main_search.m_to_n_age", { age_1: 7, age_2: 8 }),
        value: "8"
      },
      {
        title: t("homepage.main_search.m_to_n_age", { age_1: 8, age_2: 9 }),
        value: "9"
      },
      {
        title: t("homepage.main_search.m_to_n_age", { age_1: 9, age_2: 10 }),
        value: "10"
      },
      {
        title: t("homepage.main_search.m_to_n_age", { age_1: 10, age_2: 11 }),
        value: "11"
      },
      {
        title: t("homepage.main_search.m_to_n_age", { age_1: 11, age_2: 12 }),
        value: "12"
      }
    ],
    list: listItems,
    totalPassenger
  } = properties;

  const { isMobile } = useViewPort();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [_, item] of listItems.entries()) {
      if (
        item?.children?.[0]?.value?.length +
          item?.children?.[1]?.value?.length >
        5
      ) {
        setAdultWarningIsShow(true);
        break;
      } else {
        setAdultWarningIsShow(false);
      }
    }
  }, [list, listItems]);

  return (
    <div className={styles["passenger-count"]}>
      <Chips {...chipsProps} />
      <MiniPopover {...popoverProps}>
        <div className={styles["mini-popover"]}>
          <div className={styles["main"]}>
            <div className={styles["list"]}>
              {list?.map((items, itemsIndex) => (
                <div key={items[itemsIndex]?.id}>
                  <div className={styles["header"]}>
                    <span className={styles["title"]}>
                      {`${items?.title} ${numberConvert(itemsIndex + 1, {
                        locale
                      })}`}
                    </span>
                    {/* DELETE ROOM BUTTON */}
                    {list?.length > 1 && (
                      <Button
                        EndIcon={<MaterialIcon name="delete_outline" />}
                        className={styles["delete"]}
                        color="neutral"
                        // eslint-disable-next-line react/jsx-no-bind
                        onClick={() => onDelete(items?.id)}
                        text={t("homepage.main_search.delete_title")}
                        variant="text"
                      />
                    )}
                  </div>
                  <div>
                    {items?.children?.map((item) => (
                      <div key={item.title}>
                        <ListItem
                          key={item.title}
                          {...item}
                          onChange={onChange}
                        />
                      </div>
                    ))}
                  </div>
                  <div className={styles["dropwdown-box"]}>
                    {listItems[itemsIndex]?.children?.[1]?.value?.map(
                      (item: any, index: number) => (
                        <div
                          className={styles["dropwdown-item"]}
                          key={item?.title}
                        >
                          {isMobile ? (
                            <Dropdown
                              error={!item}
                              fullWidth
                              label={t("homepage.main_search.child_age", {
                                num: index + 1
                              })}
                              list={ageList}
                              name="gender"
                              // eslint-disable-next-line react/jsx-no-bind
                              onChange={(value: string) =>
                                onChangeChildAge(value, itemsIndex, index)
                              }
                              readOnly
                              small
                              value={`${item}` || ""}
                            />
                          ) : (
                            <div
                              className={`${styles["select-box"]} ${
                                item === undefined ? styles["error"] : ""
                              }`}
                            >
                              <div
                                className={`${styles["end-icon"]} ${
                                  item === undefined ? styles["error"] : ""
                                }`}
                              >
                                <ArrowDropDown />
                              </div>
                              <select
                                className={styles["select"]}
                                name="gender"
                                // eslint-disable-next-line react/jsx-no-bind
                                onChange={(
                                  event: ChangeEvent<HTMLSelectElement>
                                ) => {
                                  onChangeChildAge(
                                    event.target.value,
                                    itemsIndex,
                                    index
                                  );
                                }}
                                value={item}
                              >
                                <option
                                  className={styles["none-option"]}
                                  key="0"
                                  value="0"
                                />
                                {ageList?.map((item) => (
                                  <option key={item?.title} value={item?.value}>
                                    {item?.title}
                                  </option>
                                ))}
                              </select>
                              <label
                                className={`${
                                  item
                                    ? styles["label-selected-value"]
                                    : item === undefined
                                      ? `${styles["label"]} ${styles["error"]}`
                                      : styles["label"]
                                }`}
                              >
                                {t("homepage.main_search.child_age", {
                                  num: index + 1
                                })}
                              </label>
                            </div>
                          )}
                          {item === undefined && (
                            <div className={styles["error"]}>
                              {t("homepage.main_search.child_age_is_mandatory")}
                            </div>
                          )}
                        </div>
                      )
                    )}
                  </div>
                </div>
              ))}
              {/* ADD ROOM BUTTON */}

              <Button
                StartIcon={<MaterialIcon name="add" />}
                className={styles["add-room"]}
                color="secondary"
                disabled={list.length > 5 || totalPassenger > 8}
                // eslint-disable-next-line react/jsx-no-bind
                onClick={onAdd}
                text={t("homepage.main_search.add_room")}
                variant="text"
              />
            </div>
          </div>
          <div className={styles["footer"]}>
            <div
              className={
                adultWarningIsShow || list.length === 6 || totalPassenger > 8
                  ? "pb-4"
                  : ""
              }
            >
              {totalPassenger > 8 && (
                <MiniChips
                  StartIcon={
                    <MaterialIcon
                      className="text-base"
                      name="info"
                      variant="outlined"
                    />
                  }
                  color="default"
                  text={t(
                    "homepage.main_search.warning.valid_passenger_count",
                    {
                      count: 9
                    }
                  )}
                  variant="text"
                />
              )}
              {adultWarningIsShow && (
                <MiniChips
                  StartIcon={
                    <MaterialIcon
                      className="text-base"
                      name="info"
                      variant="outlined"
                    />
                  }
                  color="default"
                  text={t(
                    "homepage.main_search.warning.valid_max_adult_count",
                    {
                      count: 6
                    }
                  )}
                  variant="text"
                />
              )}
              {list.length === 6 && (
                <MiniChips
                  StartIcon={
                    <MaterialIcon
                      className="text-base"
                      name="info"
                      variant="outlined"
                    />
                  }
                  color="default"
                  text={t("homepage.main_search.warning.valid_max_room_count", {
                    count: 6
                  })}
                  variant="text"
                />
              )}
            </div>
            <Button {...submitButtonProps}>{submitText}</Button>
          </div>
        </div>
      </MiniPopover>
    </div>
  );
};
