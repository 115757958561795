import { MaterialIcon } from "@website/icons";
import { Button, Loading, TextField } from "@website/ui-old";
import { FC } from "react";
import { Header } from "..";
import { useAccountInfoCard } from "./account-info-card.hook";
import styles from "./account-info-card.module.scss";
import { AccountInfoCardPropertiesType } from "./account-info-card.types";

export const AccountInfoCard: FC<AccountInfoCardPropertiesType> = (
  properties
) => {
  const {
    password,
    onChangePassword,
    fullName,
    onBackClick,
    onCloseClick,
    onChangeFullName,
    onSubmitAccountInfo,
    onLaterClick,
    isLoading,
    showPassword,
    toggleShowPassword,
    fullNameError,
    passwordError,
    passwordConditionErrors
  } = useAccountInfoCard(properties);

  return (
    <div className={styles["container"]}>
      <Header
        onBack={onBackClick}
        onClose={onCloseClick}
        title="اطلاعات کاربری"
      />
      <div className={styles["title"]}>
        لطفا نام و نام خانوادگی خود را وارد کنید و برای استفاده آسانتر از سرویس
        ها، یک رمز عبور انتخاب کنید.
      </div>
      <div className={styles["input"]}>
        <TextField
          StartIcon={<MaterialIcon name="person" variant="outlined" />}
          error={Boolean(fullNameError)}
          fullWidth
          helperText={fullNameError}
          onChange={onChangeFullName}
          placeholder="نام و نام خانوادگی"
          value={fullName}
        />
        <TextField
          EndIcon={<MaterialIcon name="remove_red_eye" />}
          StartIcon={<MaterialIcon name="lock" variant="outlined" />}
          error={Boolean(passwordError)}
          fullWidth
          helperText={passwordError}
          onChange={onChangePassword}
          onEndIconClick={toggleShowPassword}
          placeholder="رمز عبور"
          type={showPassword ? "text" : "password"}
          value={password}
        />
      </div>
      <div className={styles["conditions"]}>
        <div
          className={`${styles["condition-item"]} ${
            passwordConditionErrors.useEnglishCharacter ? "" : styles["pass"]
          }`}
        >
          {passwordConditionErrors.useEnglishCharacter ? (
            <MaterialIcon name="remove" />
          ) : (
            <MaterialIcon name="check_circle" />
          )}
          استفاده از حروف انگلیسی
        </div>
        <div
          className={`${styles["condition-item"]} ${
            passwordConditionErrors.useNumberCharacter ? "" : styles["pass"]
          }`}
        >
          {passwordConditionErrors.useNumberCharacter ? (
            <MaterialIcon name="remove" />
          ) : (
            <MaterialIcon name="check_circle" />
          )}
          استفاده از عدد
        </div>
        <div
          className={`${styles["condition-item"]} ${
            passwordConditionErrors.useSpecialCharacter ? "" : styles["pass"]
          }`}
        >
          {passwordConditionErrors.useSpecialCharacter ? (
            <MaterialIcon name="remove" />
          ) : (
            <MaterialIcon name="check_circle" />
          )}
          استفاده از کاراکتر های خاص (@#$&*! و ...)
        </div>
        <div
          className={`${styles["condition-item"]} ${
            passwordConditionErrors.reachMinimumCharactersLength
              ? ""
              : styles["pass"]
          }`}
        >
          {passwordConditionErrors.reachMinimumCharactersLength ? (
            <MaterialIcon name="remove" />
          ) : (
            <MaterialIcon name="check_circle" />
          )}
          حداقل ۸ کاراکتر
        </div>
      </div>
      <div className={styles["footer"]}>
        <Button
          color="secondary"
          fullWidth
          onClick={isLoading ? undefined : onSubmitAccountInfo}
          size="large"
          variant="contained"
        >
          {isLoading ? (
            <Loading color="white" size="lg" />
          ) : (
            "تایید و ورود به اسنپ‌تریپ!"
          )}
        </Button>
        <Button
          color="secondary"
          disabled={isLoading}
          fullWidth
          onClick={isLoading ? undefined : onLaterClick}
          size="large"
          variant="text"
        >
          بعدا انجام میدهم
        </Button>
      </div>
    </div>
  );
};
