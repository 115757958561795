import { useViewPort } from "@website/hooks";
import { FC, memo, useCallback } from "react";
import { Transition } from "react-transition-group";
import { usePopoverFull } from "./popover-full.hook";
import styles from "./popover-full.module.scss";
import { PopoverFullPropertiesType } from "./popover-full.types";

const PopoverFull: FC<PopoverFullPropertiesType> = (properties) => {
  const {
    animate,
    children,
    title,
    position,
    StartAction,
    EndAction,
    gapHorizontal,
    gapVertical,
    onBlurInputs,
    className
  } = properties;
  const { rootProps, transitionProps, popoverOpen, animateOpen } =
    usePopoverFull(properties);
  const { isMobile } = useViewPort();

  const onCloseBackDrop = useCallback(() => {
    const { onClose } = properties;
    onClose();
    onBlurInputs && onBlurInputs();
  }, [onBlurInputs, properties]);

  if (popoverOpen) {
    return (
      <Transition {...transitionProps} in={animateOpen}>
        {(state: any) => (
          <div
            {...rootProps}
            className={`${styles["popover-full"]} ${className} ${
              styles[position || "start"]
            } ${
              animate === "normal"
                ? ""
                : `${styles["animate"]} ${
                    state === "entering" || state === "entered"
                      ? styles["animate-enter"]
                      : styles["animate-exit"]
                  }`
            } `}
            style={{
              marginBlockStart: isMobile ? "0px" : gapVertical || "0px",
              ...(position === "start"
                ? {
                    marginInlineStart: isMobile ? "0px" : gapHorizontal || "0px"
                  }
                : {
                    marginInlineEnd: isMobile ? "0px" : gapHorizontal || "0px"
                  })
            }}
          >
            {!isMobile && (
              <div className={styles["backdrop"]} onClick={onCloseBackDrop} />
            )}

            {StartAction || title || EndAction ? (
              <div className={styles["header"]}>
                {StartAction}
                <div className={styles["title"]}>{title}</div>
                {EndAction}
              </div>
            ) : null}
            <div className={`${styles["main"]}`}>{children}</div>
          </div>
        )}
      </Transition>
    );
  }
  return null;
};

const PopoverFullMemoized = memo(PopoverFull);
export { PopoverFullMemoized as PopoverFull };
