import { memo, SVGProps } from "react";

const SvgAparat = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    height="1em"
    viewBox="0 0 32 32"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <path d="M5.655 8.087c.387-2.357.997-4.628 2.735-5.596 1.737-.969 3.327-.357 5.173.563-3.753.7-5.509 2.257-7.911 5.033h.003zM2.736 18.436c.689 3.207 2.601 6.036 5.351 7.913-2.235-.172-4.06-.459-5.309-2.091-1.251-1.629-.784-3.772-.045-5.823h.004zm23.609 5.477c-.304 2.285-.851 4.532-2.592 5.533s-3.389.489-5.32-.327c3.824-.837 5.567-2.411 7.912-5.207zm2.75-10.348c-.596-3.199-2.457-6.041-5.183-7.913 2.313.263 4.183.617 5.393 2.241s.64 3.695-.211 5.672zm-18.259 3.044a3.347 3.347 0 1 0 3.337 3.359v-.003a3.35 3.35 0 0 0-3.336-3.356h-.001zm1.796-9.132A3.348 3.348 0 1 0 16 10.825a3.34 3.34 0 0 0-3.335-3.348h-.033zm8.549 1.219a3.347 3.347 0 1 0-.016 6.694 3.347 3.347 0 0 0 .016-6.694zm-1.852 9.129A3.346 3.346 0 0 0 16 21.192a3.346 3.346 0 0 0 3.367 3.329 3.35 3.35 0 0 0 3.329-3.348 3.342 3.342 0 0 0-3.337-3.348h-.029zm-3.652-3.042a1.521 1.521 0 1 0 .007.001h-.007zM16 3.825c6.724 0 12.173 5.452 12.173 12.176-.001 6.724-5.452 12.173-12.176 12.173-6.724-.001-12.173-5.451-12.173-12.173C3.828 9.278 9.277 3.828 16 3.825z" />
  </svg>
);

const Memo = memo(SvgAparat);
export default Memo;
