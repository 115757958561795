import { useTranslation } from "@website/locale";
import { numberConvert } from "@website/utils";
import { FC, Fragment, memo } from "react";
import { Button } from "../../..";
import { stringOfNumbers } from "../../datepicker.utils";
import { useMonthActions } from "./month-actions.hook";
import styles from "./month-actions.module.scss";
import { MonthActionsPropertiesType } from "./month-actions.types";

const MonthActions: FC<MonthActionsPropertiesType> = (properties) => {
  const { calendarLocale = "gregorian", showDates } = properties;

  const { t, locale, direction } = useTranslation();

  const { showPreviousMonthButtonProperties, showNextMonthButtonProperties } =
    useMonthActions(properties);

  return (
    <div className={`${styles["container"]} ${styles[direction || "ltr"]}`}>
      <Button
        {...showPreviousMonthButtonProperties}
        className={styles["month-button"]}
      />
      {showDates
        ? showDates.map((item, index) => (
            <Fragment key={item.month}>
              {index > 0 ? (
                <div className={styles["month-name-divider"]} />
              ) : null}
              <div
                className={styles["month-name"]}
                key={`${item?.year}-${item.month}`}
              >
                {t<string>(
                  `calendar.${calendarLocale}.monthNames.${
                    stringOfNumbers[item.month]
                  }`
                )}{" "}
                {numberConvert(item.year, {
                  numberOnly: true,
                  locale
                })}
                {(calendarLocale === "persian" && locale !== "fa") ||
                (calendarLocale === "gregorian" && locale !== "en") ? (
                  <span className={styles["month-name-native"]}>
                    (
                    {t<string>(
                      `calendar.${calendarLocale}.monthNames.${
                        stringOfNumbers[item.month]
                      }`
                    )}
                    )
                  </span>
                ) : null}
              </div>
            </Fragment>
          ))
        : null}
      <Button
        {...showNextMonthButtonProperties}
        className={styles["month-button"]}
      />
    </div>
  );
};

const MonthActionsMemoized = memo(MonthActions);
export { MonthActionsMemoized as MonthActions };
