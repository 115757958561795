import { memo, SVGProps } from "react";

const SvgCloseRounded = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <path
      clipRule="evenodd"
      d="M2 12C2 6.47 6.47 2 12 2s10 4.47 10 10-4.47 10-10 10S2 17.53 2 12Zm13.59 5L17 15.59 13.41 12 17 8.41 15.59 7 12 10.59 8.41 7 7 8.41 10.59 12 7 15.59 8.41 17 12 13.41 15.59 17Z"
      fillRule="evenodd"
    />
  </svg>
);

const Memo = memo(SvgCloseRounded);
export default Memo;
