import { MaterialIcon } from "@website/icons";
import { ActionsPropertiesType } from "./actions.types";

export const useActions = (properties: ActionsPropertiesType) => {
  const { showToday, changeCalendarLocale } = properties;

  return {
    showTodayButtonProperties: {
      onClick: showToday,
      StartIcon: <MaterialIcon name="sync" variant="outlined" />,
      color: "secondary" as const,
      size: "small" as const,
      variant: "text" as const
    },
    changeCalendarLocaleButtonProperties: {
      onClick: changeCalendarLocale,
      StartIcon: <MaterialIcon name="swap_horiz" variant="outlined" />,
      color: "secondary" as const,
      size: "small" as const,
      variant: "text" as const
    }
  };
};
