import { MaterialIcon } from "@website/icons";
import { MouseEvent, useCallback, useEffect, useState } from "react";
import { AccordionPropertiesType } from "./accordion.types";

export const useAccordionItem = (properties: AccordionPropertiesType) => {
  const {
    disabled,
    initialExpanded,
    EndIcon,
    EndIconExpanded,
    endText,
    endTextExpanded
  } = properties;
  const [expanded, setExpanded] = useState<boolean>(initialExpanded || false);

  useEffect(() => {
    if (initialExpanded) {
      setExpanded(true);
    }
  }, [initialExpanded]);

  const onDetailsClick = useCallback(
    (event: MouseEvent<HTMLDetailsElement>) => {
      event.preventDefault();
      setExpanded((value) => !value);
    },
    []
  );

  return {
    detailsProperties: {
      open: expanded
    },
    summeryProperties: {
      onClick: disabled ? undefined : onDetailsClick
    },
    buttonProperties: {
      "aria-label": expanded ? "collapse" : "expand",
      disabled: disabled,
      color: "secondary" as const,
      variant: "text" as const,
      equalDimension: !endText,
      EndIcon: expanded
        ? endTextExpanded
          ? undefined
          : EndIconExpanded || (
              <MaterialIcon name="keyboard_arrow_up" variant="outlined" />
            )
        : endText
          ? undefined
          : EndIcon || (
              <MaterialIcon name="keyboard_arrow_down" variant="outlined" />
            )
    },
    buttonText: expanded ? endTextExpanded || "" : endText || "",
    expanded
  };
};
