import { useViewPort } from "@website/hooks";
import { useTranslation } from "@website/locale";
import { isSSR, numberConvert } from "@website/utils";
import { FC, memo, useEffect } from "react";
import { stringOfNumbers } from "../../datepicker.utils";
import { useTable } from "./table.hook";
import styles from "./table.module.scss";
import { TablePropertiesType } from "./table.types";

const Table: FC<TablePropertiesType> = (properties) => {
  const { calendarLocale, year, month, prices } = properties;
  const { t, locale, direction } = useTranslation();
  const { days } = useTable(properties);
  const { isMobile } = useViewPort();

  useEffect(() => {
    if (isMobile && !isSSR()) {
      const element =
        document?.querySelector("#datepicker_selected") ||
        document?.querySelector("#datepicker_start") ||
        document?.querySelector("#datepicker_multi-selected");
      if (element)
        element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [isMobile]);

  return (
    <div className={`${styles["container"]} ${styles[direction || "ltr"]}`}>
      <div className={styles["week-names-container-desktop"]}>
        {[1, 2, 3, 4, 5, 6, 7].map((week, weekIndex) => (
          <div className={styles["week-name"]} key={weekIndex++}>
            {t<string>(
              `calendar.${calendarLocale}.weekNamesAbbr.${stringOfNumbers[week]}`
            )}
          </div>
        ))}
      </div>
      <div className={styles["month-name"]}>
        {t<string>(
          `calendar.${calendarLocale}.monthNames.${stringOfNumbers[month]}`
        )}{" "}
        {numberConvert(year, {
          numberOnly: true,
          locale
        })}
        {(calendarLocale === "persian" && locale !== "fa") ||
        (calendarLocale === "gregorian" && locale !== "en") ? (
          <span className={styles["month-name-native"]}>
            (
            {t<string>(
              `calendar.${calendarLocale}.monthNames.${stringOfNumbers[month]}`
            )}
            )
          </span>
        ) : null}
      </div>
      {[0, 1, 2, 3, 4, 5].map((row, rowIndex) => {
        if (
          days[rowIndex * 7]?.value !== -1 || // first cell of row
          days[rowIndex * 7 + 6]?.value !== -1 // last cell of row
        ) {
          return (
            <div className={styles["row"]} key={rowIndex++}>
              {[0, 1, 2, 3, 4, 5, 6].map((cell, cellIndex) => {
                const index = rowIndex * 7 + cellIndex;
                const cellText =
                  days[index]?.value === -1 ? "" : days[index]?.value;
                const cellContent = days[index]?.content;
                const state = days[index]?.state;
                const isToday = days[index]?.isToday;
                const cellType = days[index]?.type;

                return (
                  <div
                    className={`${styles["cell"]} ${
                      isToday ? styles["today"] : ""
                    } ${state ? styles[state] : ""}`}
                    id={`datepicker_${state}`}
                    onClick={days[index]?.onClick}
                  >
                    <div
                      className={`${styles["cell-inside"]} ${
                        cellType === "lowest"
                          ? `bg-transparent-success rounded`
                          : ""
                      }`}
                    >
                      {numberConvert(cellText, {
                        numberOnly: true,
                        locale
                      })}
                      {cellContent ? (
                        <span className={styles["cell-inside-data"]}>
                          {Number.isNaN(Number(cellContent))
                            ? cellContent
                            : prices
                              ? Number(cellContent) > 0 &&
                                numberConvert(
                                  (Number(cellContent) / 10_000_000).toFixed(1),
                                  {
                                    locale: "fa"
                                  }
                                )
                              : numberConvert(
                                  Number(cellContent).toLocaleString("en-US"),
                                  {
                                    numberOnly: false,
                                    locale
                                  }
                                )}
                        </span>
                      ) : null}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

const TableMemoized = memo(Table);
export { TableMemoized as Table };
