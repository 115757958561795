import { useViewPort } from "@website/hooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  DatepickerCalendarLocaleType,
  DatepickerShowDateType
} from "../datepicker.types";
import {
  addMonth,
  createShowDatesArray,
  getToday,
  subtractMonth
} from "../datepicker.utils";

const INITIAL_SHOW_DATES_NUMBER = 2;
const INITIAL_SHOW_DATES_NUMBER_MOBILE = 12;

export const useShowDate = (
  calendarLocale: DatepickerCalendarLocaleType,
  scrollToTop: () => void
) => {
  const { isMobile } = useViewPort();

  const [showDates, setShowDates] = useState<DatepickerShowDateType>(
    createShowDatesArray(
      calendarLocale,
      isMobile ? INITIAL_SHOW_DATES_NUMBER_MOBILE : INITIAL_SHOW_DATES_NUMBER
    )
  );

  useEffect(() => {
    if (isMobile) {
      setShowDates(
        createShowDatesArray(calendarLocale, INITIAL_SHOW_DATES_NUMBER_MOBILE)
      );
    } else {
      setShowDates(
        createShowDatesArray(calendarLocale, INITIAL_SHOW_DATES_NUMBER)
      );
    }
  }, [calendarLocale, isMobile]);

  const increaseShowDatesNumber = useCallback(() => {
    setShowDates(createShowDatesArray(calendarLocale, showDates?.length + 1));
  }, [calendarLocale, showDates?.length]);

  useEffect(() => {
    setShowDates(createShowDatesArray(calendarLocale, showDates?.length));
  }, [calendarLocale, showDates?.length]);

  const today = getToday(calendarLocale);

  const nextMonthDisabled = useMemo(
    () =>
      (Number(showDates?.at(-1)?.year) - today.year) * 12 +
        (Number(showDates?.at(-1)?.month) - today.month) >=
      12,
    [showDates, today]
  );

  const previousMonthDisabled = useMemo(
    () =>
      (showDates[0]?.year - today.year) * 12 +
        (showDates[0]?.month - today.month) <=
      0,
    [showDates, today]
  );

  const onNextMonthClick = useCallback(() => {
    if (!nextMonthDisabled) {
      const newShowDates = showDates?.map((item) => {
        const newDate = addMonth(item.year, item.month, 1);
        return { year: newDate.year, month: newDate.month };
      });
      setShowDates(newShowDates);
    }
  }, [showDates, nextMonthDisabled]);

  const onPreviousMonthClick = useCallback(() => {
    if (!previousMonthDisabled) {
      const newShowDates = showDates?.map((item) => {
        const newDate = subtractMonth(item.year, item.month, 1);
        return { year: newDate.year, month: newDate.month };
      });
      setShowDates(newShowDates);
    }
  }, [showDates, previousMonthDisabled]);

  const onShowTodayClick = useCallback(() => {
    if (isMobile) {
      scrollToTop();
    } else {
      setShowDates(
        createShowDatesArray(calendarLocale, INITIAL_SHOW_DATES_NUMBER)
      );
    }
  }, [scrollToTop, calendarLocale, isMobile]);

  return {
    onNextMonthClick,
    onPreviousMonthClick,
    onShowTodayClick,
    showDates,
    increaseShowDatesNumber,
    nextMonthDisabled,
    previousMonthDisabled
  };
};
