import { useQuery } from "react-query";
import {
  AirportsCitiesService,
  AirportsCitiesUrl
} from "../../../../../services/international-flights/airports-cities";

export const useAirportsCitiesService = (query: string) => {
  const {
    isLoading,
    data
    // error
  } = useQuery(
    [AirportsCitiesUrl, query],
    () => AirportsCitiesService({ query }),
    {
      enabled: query.length > 2
    }
  );

  return { isLoading, data: data?.cities };
};
