import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { Menu, MiniPopover, TextField } from "..";
import { useDropdown } from "./dropdown.hook";
import styles from "./dropdown.module.scss";
import { DropdownPropertiesType } from "./dropdown.types";

const Dropdown: FC<DropdownPropertiesType> = (properties) => {
  const {
    className,
    disabled,
    error,
    fullWidth,
    inputMode,
    otherPopoverProperties = {}
  } = properties;
  const { textfieldProps, popoverProps, menuProps } = useDropdown(properties);

  return (
    <div
      className={`${styles["dropdown"]} ${error ? styles["error"] : ""} ${
        disabled ? styles["disabled"] : ""
      } ${fullWidth ? styles["fullWidth"] : ""} ${className ?? ""}`}
    >
      <TextField
        className={`${className} ${styles["text-field"]}`}
        inputMode={inputMode}
        {...textfieldProps}
      />
      <MiniPopover {...popoverProps} {...otherPopoverProperties}>
        <div className={styles["popover"]}>
          <Menu {...menuProps} />
        </div>
      </MiniPopover>
    </div>
  );
};

const DropdownMemoized = memo(Dropdown, deepCompare);
export { DropdownMemoized as Dropdown };
