import { MaterialIcon } from "@website/icons";
import { FC, memo } from "react";
import { Button } from "../button";
import { usePagination } from "./pagination.hook";
import styles from "./pagination.module.scss";
import { PaginationPropertiesType } from "./pagination.types";

const Pagination: FC<PaginationPropertiesType> = (properties) => {
  const { className, color } = properties;
  const { paginationItems, previousButtonProperties, nextButtonProperties } =
    usePagination(properties);

  return (
    <div className={` ${styles["pagination"]} ${className || ""} `}>
      <Button
        StartIcon={<MaterialIcon name="arrow_back_ios" variant="outlined" />}
        className={styles["angle-button"]}
        color={color}
        equalDimension
        variant="text"
        {...previousButtonProperties}
      />
      {paginationItems?.map((item, index) =>
        item ? (
          <Button
            {...item.props}
            equalDimension
            key={`Pagination-${index++}`}
            variant={item.selected ? "contained" : "text"}
          >
            {item.item}
          </Button>
        ) : (
          <div>...</div>
        )
      )}
      <Button
        StartIcon={<MaterialIcon name="arrow_forward_ios" variant="outlined" />}
        className={styles["angle-button"]}
        color={color}
        equalDimension
        variant="text"
        {...nextButtonProperties}
      />
    </div>
  );
};

const PaginationMemoized = memo(Pagination);
export { PaginationMemoized as Pagination };
