import { useCallback, useState } from "react";
import { LimitTextPropertiesType } from "./limit-text.types";

export const useLimitTextItem = (properties: LimitTextPropertiesType) => {
  const { title, content, disabled, charLimit } = properties;
  const [expanded, setExpanded] = useState<boolean>(false);

  const onButtonClick = useCallback(() => {
    setExpanded((state) => !state);
  }, []);

  const contentCharLimit = charLimit || 250;
  const contentIsLimited = content.length > contentCharLimit;

  const buttonId = `button-${title}`;
  const regionId = `region-${title}`;

  return {
    regionProperties: {
      "aria-labelledby": buttonId,
      id: regionId,
      role: "region"
    },
    buttonProperties: {
      "aria-controls": regionId,
      "aria-expanded": disabled ? true : expanded,
      "aria-disabled": disabled,
      disabled,
      id: buttonId,
      onClick: onButtonClick
    },
    expanded: disabled ? true : expanded,
    limitedContent: contentIsLimited
      ? content.slice(0, contentCharLimit)
      : content,
    contentIsLimited
  };
};
