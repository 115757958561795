import { numberConvert } from "@website/utils";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  DatepickerRollDateType,
  DatepickerRollPropertiesType
} from "../datepicker-roll.types";
import { getDaysInMonth, getToday } from "../datepicker-roll.utils";

const yearsNumber = 100;
const yearsAgo = 2;

const monthNames = [
  "فروردین",
  "اردیبهشت",
  "خرداد",
  "تیر",
  "مرداد",
  "شهریور",
  "مهر",
  "آبان",
  "آذر",
  "دی",
  "بهمن",
  "اسفند"
];

export const useSelectDate = (properties: DatepickerRollPropertiesType) => {
  const { value } = properties;
  const [selectedDate, setSelectedDate] =
    useState<DatepickerRollDateType>(null);

  useEffect(() => {
    setSelectedDate(value);
  }, [value]);

  const today = getToday("persian");
  const startYear = useMemo(
    () => today.year - yearsAgo - yearsNumber + 1,
    [today.year]
  );
  const daysInMonth = getDaysInMonth(
    "persian",
    selectedDate?.year || 1370,
    selectedDate?.month || 1
  );

  const yearsList = useMemo(
    () =>
      [...Array.from({ length: 100 }).keys()].map(
        (item, index) => startYear + index
      ),
    [startYear]
  );

  const daysList = useMemo(
    () =>
      [...Array.from({ length: daysInMonth }).keys()].map(
        (item, index) => index + 1
      ),
    [daysInMonth]
  );

  const onYearChange = useCallback(
    (index: number) => {
      setSelectedDate((date) => ({
        year: startYear + index,
        month: date?.month || 1,
        day: date?.day || 1
      }));
    },
    [startYear]
  );

  const onMonthChange = useCallback(
    (index: number) => {
      setSelectedDate((date) => ({
        year: date?.year || startYear,
        month: index + 1,
        day: date?.day || 1
      }));
    },
    [startYear]
  );

  const onDayChange = useCallback(
    (index: number) => {
      setSelectedDate((date) => ({
        year: date?.year || startYear,
        month: date?.month || 1,
        day: index + 1
      }));
    },
    [startYear]
  );

  const valueText = useMemo(() => {
    const valueTextEn =
      (value ? value.year : "") +
      (value ? "/" : "") +
      (value ? value.month.toString().padStart(2, "0") : "") +
      (value ? "/" : "") +
      (value ? value.day.toString().padStart(2, "0") : "");

    return numberConvert(valueTextEn, {
      numberOnly: false,
      locale: "fa"
    });
  }, [value]);

  return {
    selectedDate,
    setSelectedDate,
    rollYearProperties: {
      list: yearsList,
      value: selectedDate?.year ? selectedDate.year - startYear : 0,
      onChange: onYearChange
    },
    rollMonthProperties: {
      list: monthNames,
      value: selectedDate?.month ? selectedDate.month - 1 : 0,
      onChange: onMonthChange
    },
    rollDayProperties: {
      list: daysList,
      value: selectedDate?.day ? selectedDate.day - 1 : 0,
      onChange: onDayChange
    },
    valueText
  };
};
