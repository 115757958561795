import { MaterialIcon } from "@website/icons";
import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { useCheckbox } from "./checkbox.hook";
import styles from "./checkbox.module.scss";
import { CheckboxPropertiesType } from "./checkbox.types";

const Checkbox: FC<CheckboxPropertiesType> = (properties) => {
  const { inputProps } = useCheckbox(properties);
  const {
    className,
    wrapperClassName,
    color,
    disabled,
    label,
    error,
    helperText
  } = properties;

  return (
    <div className={wrapperClassName}>
      <label
        className={`${styles["checkbox"]} ${
          disabled ? styles["disabled"] : ""
        } ${className ?? ""} ${error ? styles["error"] : ""}`}
        data-error={error}
      >
        <input {...inputProps} className={styles["checkbox-input"]} />
        <span className={styles["checkbox-box"]}>
          <MaterialIcon
            className={`${styles["icon"]} ${styles["checkbox-outline-icon"]}`}
            name="check_box_outline_blank"
            variant="fill"
          />
          <MaterialIcon
            className={`${styles["icon"]} ${styles["checkbox-icon"]} ${
              styles[color || "primary"]
            }`}
            name="check_box"
            variant="fill"
          />
          <MaterialIcon
            className={`${styles["icon"]} ${
              styles["checkbox-indeterminate-icon"]
            } ${styles[color || "primary"]}`}
            name="indeterminate_check_box"
            variant="fill"
          />
          <span
            className={`${styles["checkbox-ripple"]} ${
              styles[color || "primary"]
            }`}
          />
        </span>
        <span
          className={`${styles["checkbox-label"]} ${
            disabled ? styles["disabled"] : ""
          } `}
          hidden={!label}
        >
          {label}
        </span>
      </label>
      {helperText ? (
        <div className={styles["helper-text"]}>{helperText}</div>
      ) : null}
    </div>
  );
};

const CheckboxMemoized = memo(Checkbox, deepCompare);
export { CheckboxMemoized as Checkbox };
