import { useAddRemoveQuery } from "@website/hooks";
import { ArrowDropDown } from "@website/icons";
import { Radio } from "@website/ui-old";
import { useRouter } from "next/router";
import { useCallback, useMemo } from "react";
import { SaleTypePropertiesType } from "./sale-type.types";

export const useSaleType = (properties: SaleTypePropertiesType) => {
  const { list, title, name, onChange, value } = properties;
  const router = useRouter();
  const key = "SEARCH_SALE_TYPE";
  const { [key]: isKey } = router.query;
  const { addQuery, removeQuery } = useAddRemoveQuery(key);

  const onClickChips = useCallback(() => {
    addQuery("open");
  }, [addQuery]);

  const onClosePopover = useCallback(() => {
    removeQuery();
  }, [removeQuery]);

  const chipsText = useMemo(
    () => list.find((item) => item.value === value)?.title || "",
    [list, value]
  );

  return {
    chipsProps: {
      color: "secondary" as const,
      EndIcon: <ArrowDropDown />,
      text: chipsText,
      onClick: onClickChips,
      variant: "outline" as const,
      selected: true
    },
    popoverProps: {
      onClose: onClosePopover,
      open: Boolean(isKey),
      title
    },
    list: list.map((item) => ({
      title: item.title,
      role: "button",
      size: "small" as const,
      StartAction: (
        <Radio
          checked={item.value === value}
          color="secondary"
          name={name || ""}
          value={item.value}
        />
      ),
      onClick: () => {
        onChange(item.value);
        onClosePopover();
      }
    }))
  };
};
