import { useTranslation } from "@website/locale";
import { DatepickerSelectedDateType } from "@website/ui-old";
import { useCallback, useState } from "react";

export const useDate = () => {
  const { t } = useTranslation();
  const [date, setDate] = useState<DatepickerSelectedDateType>([]);
  const [calendarLocale, setCalendarLocale] = useState<string>("");

  const onChangeDate = useCallback(
    (value: DatepickerSelectedDateType, calendarLocale: string) => {
      setDate(value);
      setCalendarLocale(calendarLocale);
    },
    []
  );

  const onEmptyDate = useCallback(() => {
    setDate([]);
  }, []);

  return {
    date,
    calendarLocale,
    onChangeDate,
    onEmptyDate,
    fromTitle: t("homepage.inter_flights.main_search.departure_date"),
    toTitle: t("homepage.inter_flights.main_search.return_date")
  };
};
