import { memo, SVGProps } from "react";

const SvgBlog = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    height="1em"
    viewBox="0 0 32 32"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <path d="M3.839 9.449c.224-.005.447.012.665.051a1.445 1.445 0 0 1 1.184 1.039c.131.427.205.869.22 1.313.031 1.433.017 2.869.021 4.304.007.077.007.157.007.225.592-.148 1.161-.317 1.737-.427a6.818 6.818 0 0 1 7.94 5.473c.013.075.027.149.037.225a6.82 6.82 0 0 1-5.599 7.753 6.748 6.748 0 0 1-7.888-5.369l-.043-.253c-.101-.856-.14-1.72-.117-2.583-.011-2.997-.008-5.996.007-8.995 0-.477.047-.953.144-1.42a1.589 1.589 0 0 1 1.684-1.337zM8.86 19.78a2.95 2.95 0 0 0-2.901 2.939 2.948 2.948 0 0 0 2.916 2.853v-.005a2.905 2.905 0 0 0 2.908-2.9l-.001-.037a2.911 2.911 0 0 0-2.921-2.849zm3.737-10.221a13.256 13.256 0 0 1 5.784 2.469 10.646 10.646 0 0 1 4.27 7.328c.029.293.053.592.091 1.021-.012.281-.04.563-.084.841a1.737 1.737 0 0 1-1.353 1.425 3.224 3.224 0 0 1-1.439.057 1.81 1.81 0 0 1-1.529-1.689 10.983 10.983 0 0 0-.401-2.268 7.05 7.05 0 0 0-5.789-5.009c-.529-.097-1.073-.117-1.607-.193a3.262 3.262 0 0 1-.709-.193 1.699 1.699 0 0 1-1.127-1.736c0-.241.027-.483.077-.72a1.864 1.864 0 0 1 1.807-1.521 7.612 7.612 0 0 1 2.011.188zm-.95-7.086a19.066 19.066 0 0 1 16.884 11.181 16.397 16.397 0 0 1 1.468 6.921c0 .199-.017.397-.053.592a1.776 1.776 0 0 1-1.657 1.539 4.217 4.217 0 0 1-1.111-.009 1.815 1.815 0 0 1-1.575-1.807 18.696 18.696 0 0 0-.407-3.097A14.373 14.373 0 0 0 14 6.965a17.807 17.807 0 0 0-2.533-.277 8.333 8.333 0 0 1-1.379-.181 1.627 1.627 0 0 1-1.349-1.513 3.07 3.07 0 0 1 .1-1.292 1.57 1.57 0 0 1 1.384-1.139c.468-.069.948-.064 1.424-.089z" />
  </svg>
);

const Memo = memo(SvgBlog);
export default Memo;
