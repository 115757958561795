import { useDebounce } from "@website/hooks";
import { Airport, Origin } from "@website/icons";
import { useTranslation } from "@website/locale";
import { useCallback, useMemo, useState } from "react";
import { NoResult } from "../../../..";
import styles from "../search-inter-flights.module.scss";
import {
  useAirportsCitiesService,
  useSearchSuggestionsService
} from "../services";
import {
  createOriginDestinationInputText,
  createOriginDestinationItemSubtitle
} from "../utils";

export const useDestination = () => {
  const { t } = useTranslation();
  const [destinationQuery, setDestinationQuery] = useState<string>("");
  const [selectedDestinationItem, setSelectedDestinationItem] = useState(null);
  const query = useDebounce(destinationQuery, 250);

  const {
    // suggestionsLoading,
    suggestions
  } = useSearchSuggestionsService();
  const { isLoading, data } = useAirportsCitiesService(query);

  const onChangeDestinationQuery = useCallback((value: string) => {
    setDestinationQuery(value);
  }, []);

  // const destinationList = useMemo(
  //   () =>
  //     data && data.length > 0
  //       ? data
  //       : suggestions && !isLoading
  //       ? suggestions
  //       : [],
  //   [data, suggestions, isLoading]
  // );

  const destinationList = useMemo(
    () => (destinationQuery.length >= 3 ? data || [] : suggestions || []),
    [data, suggestions, destinationQuery]
  );

  const destinationListNormalized = useMemo(() => {
    const list = [];
    for (const city of destinationList) {
      if (city.includeAllAirports) {
        list.push({
          id: `${city.iataCode}-${city.cityName}`,
          title: `${city.cityFaName}، ${city.countryFaName}`,
          subtitle: createOriginDestinationItemSubtitle(city),
          StartIcon: (
            <div className={styles["origin-icon"]}>
              <Origin />
            </div>
          ),
          indent: 0
        });
      }
      if (
        (city.airports?.length > 1 && !city?.isSuggested) ||
        city.includeAllAirports === false
      ) {
        for (const airport of city.airports) {
          list.push({
            id: `${airport.iataCode}-${airport.name}-${city.cityName}`,
            title: airport.faName || `فرودگاه  ${airport.name}`,
            subtitle: `${city.cityFaName} - ${airport.iataCode}`,
            indent: city.includeAllAirports ? 1 : 0,
            StartIcon: <Airport />
          });
        }
      }
    }
    return list;
  }, [destinationList]);

  const selectedDestinationText = useMemo(
    () => createOriginDestinationInputText(selectedDestinationItem),
    [selectedDestinationItem]
  );

  const onSelectDestination = useCallback(
    (id: string) => {
      let destinationItem;
      destinationItem = destinationList.find(
        (destination: any) =>
          `${destination.iataCode}-${destination.cityName}` === `${id}`
      );
      if (!destinationItem) {
        for (const city of destinationList) {
          for (const airport of city.airports) {
            if (
              `${airport.iataCode}-${airport.name}-${city.cityName}` === `${id}`
            ) {
              destinationItem = {
                airportName: airport.name,
                airportFaName: airport.faName,
                airportIataCode: airport.iataCode,
                ...city
              };
            }
          }
        }
      }
      setDestinationQuery("");
      setSelectedDestinationItem(destinationItem);
    },
    [destinationList]
  );

  const onChangeDestination = useCallback((item: any) => {
    setDestinationQuery("");
    setSelectedDestinationItem(item);
  }, []);

  return {
    selectedDestinationItem,
    // selectedDestinationText: selectedDestinationItem?.isCity
    //   ? "تمام فرودگاه های " + selectedDestinationItem.cityName
    //   : selectedDestinationItem?.cityName
    //   ? selectedDestinationItem.cityName +
    //     "," +
    //     selectedDestinationItem.countryName
    //   : "",
    selectedDestinationText,
    destinationQuery,
    onChangeDestination,
    onSelectDestination,
    onChangeDestinationQuery,
    destinationList,
    destinationLoading: isLoading,
    destinationListNormalized,
    DestinationNoResult:
      destinationQuery?.length >= 2 &&
      !isLoading &&
      data &&
      data.length === 0 ? (
        <NoResult count="14,000" query={destinationQuery} />
      ) : undefined,
    destinationLabel: t("homepage.inter_flights.main_search.destination")
  };
};
