import { getLocalTimeZone } from "@internationalized/date";
import { fetcher } from "@website/fetcher";
// eslint-disable-next-line @nx/enforce-module-boundaries
import { decodedToken } from "@website/hooks";
import { applicationStore, userTrackingStore } from "@website/store";
import { getDeviceInfo, isFilled, uuid } from "@website/utils";
import {
  clamp,
  debounce,
  get,
  isEmpty,
  isFunction,
  map,
  pickBy,
  toString
} from "lodash";
import { getAllExperiments } from "./tracking.experiment";
import { USER_TRACKING_EVENTS } from "./tracking.types";

type ProductType =
  | "dom-hotel"
  | "int-hotel"
  | "dom-flight"
  | "int-flight"
  | "villa"
  | "tour"
  | "dom-bus"
  | "int-bus"
  | "dom-train"
  | "int-train"
  | "core";

type UseUserTrackingParameters = {
  [property: string]: unknown;
  product: ProductType | (() => ProductType);
};

const isUserTrackingEnabled =
  typeof window !== "undefined" &&
  process.env.NEXT_PUBLIC_BUILD_ENV === "production";

const generateSession = (data: Partial<UseUserTrackingParameters>) => {
  const query = new URLSearchParams(window.location.search);
  userTrackingStore.sid.set((state) => state || uuid());
  userTrackingStore.aid.set((state) => state || uuid());
  applicationStore.isNative.set(
    (state) =>
      (query.get("token") &&
        query.get("app_version") &&
        query.get("ispwa") !== "true") ||
      state
  );
  userTrackingStore.sid.set((state) => {
    if (
      userTrackingStore.sessionExpire.get() < Date.now() ||
      !userTrackingStore.sessionExpire.get()
    ) {
      addEventToTracking(
        USER_TRACKING_EVENTS.USER_SESSION_STARTED,
        data as UseUserTrackingParameters
      );
      return uuid();
    }
    return state;
  });
  return userTrackingStore.sid.get();
};

const sendEvents = (
  data: Partial<UseUserTrackingParameters>,
  exponent = 1000,
  delay = 1000
) => {
  const product = isFunction(data.product) ? data.product() : data.product;
  const _trackingQueue = userTrackingStore.queue.get();
  const hasEvent = _trackingQueue?.length > 0 && userTrackingStore.sid.get();

  const _userTrackingQueue = _trackingQueue.filter(
    (item: { aid?: string }) => "aid" in item && !isEmpty(item)
  );
  const _oldUserTrackingQueue = _trackingQueue.filter(
    (item: { aid?: string }) => !("aid" in item) && !isEmpty(item)
  );

  hasEvent && userTrackingStore.queue.set([]);

  return new Promise((resolve) => {
    if (!hasEvent) return resolve({});
    _userTrackingQueue?.length &&
      fetcher({
        url: `${process.env.NEXT_PUBLIC_BASE_URL_USER_TRACKING}/ut/v3/public`,
        method: "POST",
        data: _userTrackingQueue,
        params: { token: process.env.NEXT_PUBLIC_CHANNEL_TYPE },
        headers: {
          product: String(product)
        }
      }).finally(() => resolve({}));
    _oldUserTrackingQueue?.length &&
      fetcher({
        url: `${process.env.NEXT_PUBLIC_BASE_URL_USER_TRACKING}/ut/v2/public`, // deprecated
        method: "POST",
        data: _oldUserTrackingQueue,
        params: { token: process.env.NEXT_PUBLIC_CHANNEL_TYPE },
        headers: {
          product: String(product)
        }
      }).finally(() => resolve({}));
  }).finally(() => {
    setTimeout(
      () =>
        hasEvent
          ? sendEvents(data, delay + exponent, exponent)
          : sendEvents(data, exponent, delay),
      clamp(delay, 10_000)
    );
  });
};

const collectUrlChanges = (data: Partial<UseUserTrackingParameters>) => {
  const addEvent = debounce(() => {
    const urlPath = window.location.origin + window.location.pathname;

    if (userTrackingStore.previousUrl.get() !== urlPath) {
      addEventToTracking(
        USER_TRACKING_EVENTS.PAGE_VIEWED,
        data as UseUserTrackingParameters
      );
      userTrackingStore.previousUrl.set(urlPath);
    }
  }, 100);

  const { pushState } = window.history;
  const { replaceState } = window.history;
  window.history.pushState = (state, title, url) => {
    addEvent();
    return pushState.apply(window.history, [state, title, url]);
  };
  window.history.replaceState = (state, title, url) => {
    addEvent();
    return replaceState.apply(window.history, [state, title, url]);
  };
  window.addEventListener("popstate", addEvent);
  window.addEventListener("hashchange", addEvent);
  addEvent();
};

export const addEventToTracking = (
  event: USER_TRACKING_EVENTS,
  data: UseUserTrackingParameters
) => {
  if (!isUserTrackingEnabled) return;

  const query = new URLSearchParams(window.location.search);
  const product = isFunction(data.product) ? data.product() : data.product;
  const device = getDeviceInfo();
  userTrackingStore.sessionExpire.set(Date.now() + 30 * 60 * 1000);
  userTrackingStore.queue.set((previous) => [
    ...previous,
    {
      event,
      product,
      channel: process.env.NEXT_PUBLIC_CHANNEL_TYPE,
      uid: decodedToken()?.sub, // userid from auth snapp user profile
      sid: generateSession(data), // session id: if no new message sent in 30 minutes then we must renew this is, otherwise extend the time +30 minutes
      aid: userTrackingStore.aid.get(), // persist one time generation
      mid: uuid(), // every time generate a new id
      timestamp: new Date().toISOString(),
      sent_at: new Date().toISOString(),
      timezone: getLocalTimeZone(),
      properties: {
        experiments: map(
          getAllExperiments(),
          (value: { experiment: { name: string } }) => ({
            experiment_name: value.experiment?.name
          })
        ),
        filters: map(
          pickBy(
            {
              date_from: query.get("date_from"),
              date_to: query.get("date_to"),
              accommodations: query.get("accommodations"),
              category_ids: query.get("categories"),
              stars: toString(get(query, "stars")).replaceAll("-", ","),
              price_from: query.get("price_from"),
              price_to: query.get("price_to"),
              availability: query.get("availability"),
              no_rooms: query.get("no_rooms"),
              adults: query.get("adults"),
              children: query.get("children")
            },
            isFilled
          ),
          (value, key) => ({ key, value })
        ),
        sorts: [{ key: query.get("order_by") || "selling", value: "asc" }],
        ...(product ? { [product]: data } : {})
      },
      context: {
        app: {
          name: process.env.NEXT_PUBLIC_APP_NAME,
          version: process.env.NEXT_PUBLIC_IMAGE_VERSION
        },
        utm: {
          source: "utm_internal_source",
          name: new URLSearchParams(window.location.search).get(
            "utm_internal_source"
          )
        },
        device: device && {
          id: undefined, // todo
          advertising_id: undefined, // todo
          adtracking_enabled: true,
          manufacturer: device.device.vendor,
          model: device.device.model,
          name: device.device.model,
          type: device.device.type,
          width: window.innerWidth,
          height: window.innerHeight,
          locale: "", // todo
          user_agent: window.navigator?.userAgent,
          os_name: device.os.name,
          os_version: device.os.version,
          browser_name: device.browser.name,
          browser_version: device.browser.version
        },
        network: {
          ip: "" // todo
        },
        page: {
          path: decodeURIComponent(window.location.pathname),
          referrer: decodeURIComponent(document.referrer),
          previous_page: userTrackingStore.previousUrl.get(),
          title: document.title,
          url: decodeURIComponent(window.location.href)
        }
      }
    }
  ]);
};

/**
 * @deprecated please use addEventToTracking
 */
export const addEventToOldTracking = (
  event: USER_TRACKING_EVENTS,
  data: object
) => {
  userTrackingStore.queue.set((previous) => [
    ...previous,
    {
      ...data,
      utm_internal_source:
        new URLSearchParams(window.location.search).get(
          "utm_internal_source"
        ) || undefined,
      eventType: event,
      time: new Date().toJSON()
    }
  ]);
};

export const startUserTracking = (data: Partial<UseUserTrackingParameters>) => {
  if (isUserTrackingEnabled) {
    sendEvents(data);
    generateSession(data);
    collectUrlChanges(data);
  }
};
