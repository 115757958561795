import { useCallback, useState } from "react";
import {
  DatepickerRollDateType,
  DatepickerRollPropertiesType
} from "../datepicker-roll.types";

export const usePopover = (
  properties: DatepickerRollPropertiesType,
  selectedDate: DatepickerRollDateType,
  setSelectedDate: (value: DatepickerRollDateType) => void
) => {
  const { value, onChange } = properties;
  const [open, setOpen] = useState<boolean>(false);

  const onClosePopover = useCallback(() => {
    setSelectedDate(value);
    setOpen(false);
  }, [setSelectedDate, value]);

  const onOpenPopover = useCallback(() => {
    setOpen(true);
  }, []);

  const onAccept = useCallback(() => {
    onChange(selectedDate);
    setOpen(false);
  }, [onChange, selectedDate]);

  return {
    onAccept,
    onOpenPopover,
    popoverProperties: {
      animate: "bottom" as const,
      open,
      onClose: onClosePopover,
      position: "end" as const,
      title: "تاریخ تولد"
    }
  };
};
