import {
  CheckBox,
  CheckBoxOutlineBlank,
  IndeterminateCheckBox
} from "@website/icons";
import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { useCheckbox } from "./checkbox.hook";
import styles from "./checkbox.module.scss";
import { CheckboxPropertiesType } from "./checkbox.types";

const Checkbox: FC<CheckboxPropertiesType> = (properties) => {
  const { inputProps } = useCheckbox(properties);
  const { className, color, disabled, label } = properties;

  return (
    <label
      className={`${styles["checkbox"]} ${disabled ? styles["disabled"] : ""} ${
        className ?? ""
      }`}
    >
      <input {...inputProps} className={styles["checkbox-input"]} />
      <div className={styles["checkbox-box"]}>
        <CheckBoxOutlineBlank className={styles["checkbox-outline-icon"]} />
        <CheckBox
          className={`${styles["checkbox-icon"]} ${styles[color || "primary"]}`}
        />
        <IndeterminateCheckBox
          className={`${styles["checkbox-indeterminate-icon"]} ${
            styles[color || "primary"]
          } `}
        />
        <div
          className={`${styles["checkbox-ripple"]} ${
            styles[color || "primary"]
          }`}
        />
      </div>
      <span
        className={`${styles["checkbox-label"]} ${
          disabled ? styles["disabled"] : ""
        } `}
        hidden={!label}
      >
        {label}
      </span>
    </label>
  );
};

const CheckboxMemoized = memo(Checkbox, deepCompare);
export { CheckboxMemoized as Checkbox };
