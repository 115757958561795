import { useTranslation } from "@website/locale";
import { useCallback, useMemo, useState } from "react";

export const useCoupeType = () => {
  const { t } = useTranslation();
  const [coupeType, setCoupeType] = useState<string>("regular-coupe");

  const coupeTypeList = useMemo(
    () => [
      {
        title: t("homepage.train.main_search.regular_coupe"),
        value: "regular-coupe"
      },
      {
        title: t("homepage.train.main_search.exclusive_coupe"),
        value: "exclusive-coupe"
      }
    ],
    [t]
  );

  const onChangeCoupeType = useCallback((value: string) => {
    setCoupeType(value);
  }, []);

  return { coupeType, coupeTypeList, onChangeCoupeType };
};
