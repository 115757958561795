import { memo, SVGProps } from "react";

const SvgVentureFlight = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    {/* Directly apply clip-rule and fill-rule attributes to the path */}
    <path
      clipRule="evenodd"
      d="M18.326 21.122c.294-.294.408-.72.327-1.128l-2.306-9.976 4.497-4.497a1.732 1.732 0 0 0 0-2.453 1.732 1.732 0 0 0-2.453 0l-4.497 4.497L3.918 5.26a1.213 1.213 0 0 0-.834 2.257l7.13 3.729-4.497 4.497-2.575-.368a.554.554 0 0 0-.491.163l-.483.483a.582.582 0 0 0 .131.916l3.01 1.668 1.667 3.009c.188.335.646.4.916.13l.483-.482c.13-.13.196-.31.163-.49l-.368-2.576 4.497-4.497 3.729 7.13a1.213 1.213 0 0 0 1.93.294Z"
      fillRule="evenodd"
    />
  </svg>
);

const Memo = memo(SvgVentureFlight);

export default Memo;
