import { useDebounce } from "@website/hooks";
import { LodgingPin, Origin } from "@website/icons";
import { useCallback, useMemo, useState } from "react";
import { NoResult } from "../../../..";
import {
  SearchPartoInternationalHotelCityType,
  SearchPartoInternationalHotelHotelType
} from "../../../../../services/base/international-hotel/search-text";
import styles from "../search-international-hotel.module.scss";
import { useSearchDestinationServiceHotelian } from "../services";

const SUGGESTIONS_PARTO_NEW = [
  {
    id: 1283,
    name: "Istanbul",
    state: "Istanbul (province)",
    country_iso_code: "TR",
    country_name: "Turkey",
    city_slug: "istanbul-tr",
    type: "city",
    city_name_fa: "استانبول"
  },
  {
    id: 1321,
    name: "Dubai",
    state: "Dubai",
    country_iso_code: "AE",
    country_name: "United Arab Emirates",
    city_slug: "dubai-dubai-ae",
    type: "city",
    city_name_fa: "دبی"
  },
  {
    id: 26_243,
    name: "Van",
    state: "inland",
    country_iso_code: "TR",
    country_name: "Turkey",
    city_slug: "van-inland-tr",
    type: "city",
    city_name_fa: "وان"
  },
  {
    id: 20_865,
    name: "Yerevan",
    state: "Armenia",
    country_iso_code: "AM",
    country_name: "Armenia",
    city_slug: "yerevan-armenia-am",
    type: "city",
    city_name_fa: "ایروان"
  },
  {
    id: 1490,
    name: "Muscat",
    state: "Oman",
    country_iso_code: "OM",
    country_name: "Oman",
    city_slug: "muscat-oman-om",
    type: "city",
    city_name_fa: "مسقط"
  },
  {
    id: 2005,
    name: "Ankara",
    state: "inland",
    country_iso_code: "TR",
    country_name: "Turkey",
    city_slug: "ankara-inland-tr",
    type: "city",
    city_name_fa: "آنکارا"
  },
  {
    id: 15_752,
    name: "Tiflis",
    state: "Tbilisi and surrounding area",
    country_iso_code: "GE",
    country_name: "Georgia",
    city_slug: "tiflis-tbilisi-and-surrounding-area-ge",
    type: "city",
    city_name_fa: "تفلیس"
  },
  {
    id: 1322,
    name: "Sharjah",
    state: "Sharjah & Ajman",
    country_iso_code: "AE",
    country_name: "United Arab Emirates",
    city_slug: "sharjah-sharjah-and-ajman-ae",
    type: "city",
    city_name_fa: "شارجه"
  },
  {
    id: 1289,
    name: "Antalya",
    state: "Turkish Riviera",
    country_iso_code: "TR",
    country_name: "Turkey",
    city_slug: "antalya-turkish-riviera-tr",
    type: "city",
    city_name_fa: "آنتالیا"
  },
  {
    id: 1232,
    name: "Bangkok",
    state: "Bangkok & surrounding area",
    country_iso_code: "TH",
    country_name: "Thailand",
    city_slug: "bangkok-bangkok-and-surrounding-area-th",
    type: "city",
    city_name_fa: "بانکوک"
  },
  {
    id: 1946,
    hotelian_city_id: null,
    name: "Izmir",
    state: "Turkish Aegean Sea",
    country_iso_code: "TR",
    country_name: "Turkey",
    city_slug: "izmir-turkish-aegean-sea-tr",
    type: "city",
    city_name_fa: "ازمیر"
  }
];

export const useDestination = () => {
  const [destinationQuery, setDestinationQuery] = useState<string>("");
  const [selectedDestination, setSelectedDestination] = useState<string>("");
  const [selectedDestinationItem, setSelectedDestinationItem] = useState(null);

  const query = useDebounce(destinationQuery, 250);

  const { isLoading: isLoadingPartow, data: dataPartow } =
    useSearchDestinationServiceHotelian(query);

  const dataList = dataPartow;
  const loading = isLoadingPartow;

  const onChangeDestinationQuery = useCallback((value: string) => {
    setDestinationQuery(value);
  }, []);

  const destinationList = useMemo(
    () => (destinationQuery?.length >= 2 ? dataPartow : SUGGESTIONS_PARTO_NEW),
    [dataPartow, destinationQuery]
  );
  const destinationListNormalized = useMemo(
    () =>
      destinationList?.map((item) => {
        if ("id" in item) {
          const hotelItem = item as SearchPartoInternationalHotelHotelType;
          return {
            id: `${hotelItem?.id}`,
            title: `${hotelItem?.name} ${
              hotelItem?.city_name_fa ? `(${hotelItem?.city_name_fa})` : ""
            }`,
            subtitle: `${hotelItem?.country_name} - ${
              hotelItem?.city_name || hotelItem?.name
            }`,
            StartIcon: (
              <LodgingPin className={styles["autocomplete-hotel-icon"]} />
            ),
            Meta: (
              <div className="text-on-surface-high-emphasis text-caption">
                {hotelItem?.rating > 0 && (
                  <span className="row-reverse flex">
                    {hotelItem.rating === 1 ? "Star-" : "Stars-"}
                    <span>{hotelItem?.rating}</span>
                  </span>
                )}
              </div>
            )
          };
        }
        const cityItem = item as SearchPartoInternationalHotelCityType;
        return {
          id: `${cityItem?.city_id}`,
          title: cityItem?.city_name,
          subtitle: `${cityItem?.country_name}`,
          StartIcon: <Origin className={styles["autocomplete-city-icon"]} />
        };
      }),
    [destinationList]
  );

  const onSelectDestination = useCallback(
    (id: string) => {
      setSelectedDestination(id);
      const destinationItem = destinationList?.find((destination: any) =>
        destination.id
          ? `${destination?.id}` === `${id}`
          : `${destination?.city_id}` === `${id}`
      );
      setDestinationQuery("");
      setSelectedDestinationItem(destinationItem);
    },
    [destinationList]
  );

  const onChangeDestination = useCallback((item: any) => {
    setSelectedDestination(item?.id);
    setDestinationQuery("");
    setSelectedDestinationItem(item);
  }, []);

  return {
    selectedDestinationItem,
    selectedDestinationText:
      selectedDestinationItem?.name || selectedDestinationItem?.city_name || "",
    destinationQuery,
    selectedDestination,
    onChangeDestination,
    onSelectDestination,
    onChangeDestinationQuery,
    destinationList,
    destinationLoading: loading,
    destinationListNormalized,
    DestinationNoResult:
      (destinationQuery?.length >= 2 && !loading && !dataList) ||
      (destinationQuery?.length >= 2 &&
        !loading &&
        dataList &&
        dataList?.length === 0) ? (
        <NoResult query={destinationQuery} />
      ) : undefined
  };
};
