import { useCallback, useMemo } from "react";
import { usePaginationRange } from "./pagination-range.hook";
import { PaginationPropertiesType } from "./pagination.types";

export const usePagination = (properties: PaginationPropertiesType) => {
  const { count, page, onChange, color, getHref, role = "button" } = properties;
  const pageItems = usePaginationRange(count, page);

  const changePage = useCallback(
    (page: number) => {
      onChange(page);
    },
    [onChange]
  );

  const paginationItems = useMemo(
    () =>
      pageItems.map((item) => ({
        item: item || "...",
        selected: item === page,
        props: {
          color,
          ...(role === "button"
            ? {
                role: "button" as const,
                onClick: item ? () => changePage(item) : () => null
              }
            : getHref && item
              ? { href: getHref(item), role }
              : { role: "button" as const, disabled: true })
        }
      })),
    [pageItems, page, color, role, getHref, changePage]
  );

  const previousPage = useCallback(() => {
    if (page > 1) {
      onChange(page - 1);
    }
  }, [onChange, page]);

  const nextPage = useCallback(() => {
    if (page < count) {
      onChange(page + 1);
    }
  }, [count, onChange, page]);

  return {
    paginationItems,
    previousButtonProperties: {
      ...(role === "button"
        ? {
            role: "button" as const,
            onClick: previousPage,
            disabled: page <= 1
          }
        : getHref && page > 1
          ? { href: getHref(page - 1), role }
          : { role: "button" as const, disabled: true })
    },
    nextButtonProperties: {
      ...(role === "button"
        ? {
            role: "button" as const,
            onClick: nextPage,
            disabled: page >= count
          }
        : getHref && page < count
          ? { href: getHref(page + 1), role }
          : { role: "button" as const, disabled: true })
    }
  };
};
