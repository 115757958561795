import { MouseEvent, useCallback } from "react";
import { AlertPropertiesType } from "./alert.types";

export const useAlert = (properties: AlertPropertiesType) => {
  const { onClose } = properties;
  const onCloseButtonClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      onClose?.();
    },
    [onClose]
  );
  return {
    rootProps: { role: "alert" },
    closeProps: { onClick: onCloseButtonClick, role: "button", tabIndex: 0 }
  };
};
