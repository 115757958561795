import { deepCompare } from "@website/utils";
import { forwardRef, memo } from "react";
import { useLink } from "./link.hook";
import styles from "./link.module.scss";
import { LinkPropertiesType } from "./link.types";

const Link = forwardRef<HTMLAnchorElement, LinkPropertiesType>(
  (properties, reference) => {
    const { children, className, color, disabled, StartIcon } = properties;
    const { rootProperties } = useLink(properties);

    return (
      <a
        {...rootProperties}
        className={`${styles["link"]} ${styles[color ?? "primary"]} ${
          disabled ? styles["disabled"] : ""
        } ${className ?? ""}`}
        ref={reference}
      >
        {StartIcon ? (
          <div className={styles["start-icon"]}>{StartIcon}</div>
        ) : null}
        {children}
      </a>
    );
  }
);

const LinkMemoized = memo(Link, deepCompare);
export { LinkMemoized as Link };
