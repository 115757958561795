import { DatepickerSelectedDateType } from "@website/ui-old";
import { useCallback, useEffect, useState } from "react";

export const useValidation = ({
  originItem,
  destinationItem,
  saleType,
  date
}: {
  originItem: any;
  destinationItem: any;
  saleType: string;
  date: DatepickerSelectedDateType;
}) => {
  const [originError, setOriginError] = useState<string>("");
  const [destinationError, setDestinationError] = useState<string>("");
  const [dateError, setDateError] = useState<string>("");

  useEffect(() => {
    setOriginError("");
    setDestinationError("");
    setDateError("");
  }, [originItem, destinationItem, date]);

  const submitValidationCheck = useCallback(() => {
    let hasError = false;
    if (!originItem) {
      setOriginError("لطفا مبدأ خود را انتخاب نمایید");
      hasError = true;
    } else if (!destinationItem) {
      setDestinationError("لطفا مقصد خود را انتخاب نمایید");
      hasError = true;
    } else if (originItem.city === destinationItem.city) {
      setDestinationError("مبدا و مقصد نمیتواند یکسان باشد");
      hasError = true;
    } else if (saleType === "round-trip") {
      if (!date || !date[0] || !date[1]) {
        setDateError("لطفا تاریخ پرواز رفت و برگشت خود را مشخص کنید");
        hasError = true;
      }
    } else if (!date || !date[0]) {
      setDateError("لطفا تاریخ پرواز رفت خود را مشخص کنید");
      hasError = true;
    }

    if (hasError) {
      return false;
    }
    return true;
  }, [originItem, destinationItem, saleType, date]);

  return { originError, destinationError, dateError, submitValidationCheck };
};
