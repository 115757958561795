import { Chips, ListItem, MiniPopover } from "@website/ui-old";
import { FC } from "react";
import { useSalonType } from "./salon-type.hook";
import styles from "./salon-type.module.scss";
import { SalonTypePropertiesType } from "./salon-type.types";

export const SalonType: FC<SalonTypePropertiesType> = (properties) => {
  const { chipsProps, list, popoverProps } = useSalonType(properties);

  return (
    <div className={styles["container"]}>
      <Chips {...chipsProps} />
      <MiniPopover {...popoverProps}>
        <div className={styles["mini-popover"]}>
          <div className={styles["main"]}>
            {list.map((item) => (
              <ListItem key={item.title} {...item} />
            ))}
          </div>
        </div>
      </MiniPopover>
    </div>
  );
};
