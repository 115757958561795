import { useTranslation } from "@website/locale";
import { ChangeEvent, useCallback, useState } from "react";
import { AuthRequestOtpService } from "../../services/auth-request-otp";
import { SendOtpService } from "../../services/send-otp";
import { isPhoneNumberPartiallyValid, isPhoneNumberValid } from "../../utils";
import { PhoneNumberCardPropertiesType } from "./phone-number-card.types";

export const usePhoneNumberCard = (
  properties: PhoneNumberCardPropertiesType
) => {
  const { setStep, setUserData, userData } = properties;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const { t } = useTranslation();

  const onChangePhoneNumber = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      if (isPhoneNumberPartiallyValid(value)) {
        setPhoneNumber(value);
      }
    },
    []
  );

  const onSubmitPhoneNumber = useCallback(async () => {
    if (isPhoneNumberValid(phoneNumber)) {
      setIsLoading(true);
      setError("");
      SendOtpService({ phoneNumber })
        .then((data) => {
          if (data?.availableAuthMethods.includes("password")) {
            setIsLoading(false);
            setStep("password");
            setUserData({ ...userData, phoneNumber });
            // eslint-disable-next-line no-dupe-else-if
          } else if (data?.availableAuthMethods.includes("password")) {
            setError("نمیتوانید وارد شوید. لطفا با پشتیبانی تماس بگیرید.");
          } else {
            setStep("otp");
            AuthRequestOtpService({ phoneNumber })
              .then((data) => {
                setUserData({ ...userData, ...data, phoneNumber });
              })
              .catch((error) => {
                setError(
                  error?.response?.data?.message ||
                    t("login.general_error_message")
                );
              })
              .finally(() => setIsLoading(false));
          }
        })
        .catch((error) => {
          setError(
            error?.response?.data?.message || t("login.general_error_message")
          );
        })
        .finally(() => setIsLoading(false));
    } else {
      setError("شماره تلفن معتبر نیست.");
    }
  }, [phoneNumber, setStep, setUserData, t, userData]);

  return {
    error,
    isLoading,
    phoneNumber,
    onChangePhoneNumber,
    onSubmitPhoneNumber
  };
};
