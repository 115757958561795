import { useTranslation } from "@website/locale";
import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { LazyImage } from "../lazy-image";
import { Loading } from "../loading";
import styles from "./message-wrapper.module.scss";
import { MessageWrapperPropertiesType } from "./message-wrapper.type";

const MessageWrapper: FC<MessageWrapperPropertiesType> = (properties) => {
  const {
    className,
    img,
    imgAlt,
    title,
    action,
    description,
    isLoading = false
  } = properties;

  const { t } = useTranslation();

  return (
    <div className={`${styles["wrapper"]} ${className || ""}`}>
      <LazyImage
        alt={imgAlt ?? t("global.image_message")}
        className={styles.img}
        src={img}
        title={imgAlt ?? t("global.image_message")}
      />
      <h3 className={styles["header"]}>{title}</h3>
      <p className={styles["description"]}>{description}</p>
      {isLoading && <Loading />}
      {!isLoading && action && action}
    </div>
  );
};

const MessageWrapperMemoized = memo(MessageWrapper, deepCompare);
export { MessageWrapperMemoized as MessageWrapper };
