import { AxiosResponse } from "axios";
import { Axios } from "../../utils";
import { BASE_URL_PROFILE } from "../urls";
import {
  PartialUpdateProfileBodyType,
  PartialUpdateProfileResponseType
} from "./partial-update-profile.types";

export const PartialUpdateProfileUrl =
  process.env["NEXT_PUBLIC_CHANNEL_TYPE"] === "ads"
    ? ""
    : `${BASE_URL_PROFILE}/profile`;

export const PartialUpdateProfileService = (
  body: PartialUpdateProfileBodyType,
  accessToken: string
): Promise<PartialUpdateProfileResponseType> =>
  Axios.patch(PartialUpdateProfileUrl, body, {
    headers: { Authorization: `Bearer ${accessToken}` }
  }).then(({ data }: AxiosResponse<PartialUpdateProfileResponseType>) => data);
