import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { Button, Loading } from "@website/ui-old";
import { numberConvert } from "@website/utils";
import { FC } from "react";
import { Header } from "..";
import { PasswordField } from "../../..";
import { usePasswordCard } from "./password-card.hook";
import styles from "./password-card.module.scss";
import { PasswordCardPropertiesType } from "./password-card.types";

export const PasswordCard: FC<PasswordCardPropertiesType> = (properties) => {
  const { onClose } = properties;
  const {
    error,
    password,
    onChangePassword,
    onSubmitPassword,
    onOtpClick,
    isLoadingPassword,
    isLoadingOtp,
    onClickForgotPassword,
    onBackClick
  } = usePasswordCard(properties);
  const { locale } = useTranslation();

  return (
    <div className={styles["container"]}>
      <Header
        onBack={onBackClick}
        onClose={onClose}
        title="وارد کردن رمز عبور"
      />
      <div className={styles["title"]}>لطفا رمز عبور خود را وارد کنید.</div>
      <div className={styles["input"]}>
        <PasswordField
          error={Boolean(error)}
          fullWidth
          helperText={error}
          hideConditions
          initialValue={password}
          onChange={onChangePassword}
          placeholder="رمز عبور"
        />
      </div>
      <div className={styles["info"]}>
        رمز عبور خود را فراموش کرده ام.
        <Button
          color="secondary"
          onClick={onClickForgotPassword}
          size="small"
          variant="text"
        >
          بازیابی رمز عبور
        </Button>
      </div>
      <div className={styles["footer"]}>
        <Button
          color="secondary"
          disabled={isLoadingOtp}
          fullWidth
          onClick={
            isLoadingPassword || isLoadingOtp ? undefined : onSubmitPassword
          }
          size="large"
          variant="contained"
        >
          {isLoadingPassword ? (
            <Loading color="white" size="lg" />
          ) : (
            "تایید و ورود به اسنپ‌تریپ!"
          )}
        </Button>
        <Button
          color="secondary"
          disabled={isLoadingPassword}
          fullWidth
          onClick={isLoadingOtp || isLoadingPassword ? undefined : onOtpClick}
          size="large"
          variant="outline"
        >
          {isLoadingOtp ? (
            <Loading color="secondary" size="lg" />
          ) : (
            "ورود با کد یکبار مصرف"
          )}
        </Button>
        <Button
          StartIcon={<MaterialIcon name="support_agent" />}
          color="secondary"
          fullWidth
          href="tel:+982192000123"
          role="link"
          size="large"
          variant="text"
        >
          تماس با پشتیبانی ({numberConvert("02192000123", { locale })})
        </Button>
      </div>
    </div>
  );
};
