import { MaterialIcon, TripCollection } from "@website/icons";
import { Accordion } from "@website/ui-old";
import { FC } from "react";
import { InboundLinkContent } from "./inbound-link-content";
import { useInboundLinks } from "./inbound-links.hook";
import styles from "./inbound-links.module.scss";
import { InboundLinksPropertiesType } from "./inbound-links.types";

export const InboundLinks: FC<InboundLinksPropertiesType> = (properties) => {
  const { title, list } = properties;
  const { masonryList } = useInboundLinks(properties);

  if (list && list.length > 0) {
    return (
      <article className={styles["container"]}>
        <header className={styles["title-wrapper"]}>
          <TripCollection className={styles["icon"]} />
          <h2 className={styles["title"]}>{title}</h2>
        </header>
        <section className={styles["list"]}>
          {masonryList.map((columns, index) => (
            <div
              className={styles["list-items"]}
              key={`InboundLinks-${index++}`}
            >
              {columns.map((item) => (
                <Accordion
                  EndIcon={<MaterialIcon name="keyboard_arrow_down" />}
                  EndIconExpanded={<MaterialIcon name="keyboard_arrow_up" />}
                  content={<InboundLinkContent list={item.list} />}
                  initialExpanded
                  key={item.groupTitle}
                  scroll="long"
                  title={item.groupTitle}
                />
              ))}
            </div>
          ))}
        </section>
      </article>
    );
  }

  return null;
};
