import { useTranslation } from "@website/locale";
import { useRouter } from "next/router";
import { useMemo } from "react";

export const useToggle = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const query = new URLSearchParams(router.query as any).toString();
  const { pathname } = router;
  const toggleList = useMemo(
    () => [
      {
        text: t("homepage.services.domestic_hotels"),
        value: `/${query ? `?${query}` : ""}`,
        role: "next-link" as const,
        href: `/${query ? `?${query}` : ""}`
      },
      {
        text: t("homepage.services.inter_hotels"),
        value: `/international-hotel${query ? `?${query}` : ""}`,
        role: "next-link" as const,
        href: `/international-hotel${query ? `?${query}` : ""}`
      }
    ],
    [t, query]
  );

  const toggleValue = `${pathname}${query ? `?${query}` : ""}`;

  return {
    toggleList,
    toggleValue
  };
};
