import { useCallback, useEffect, useState } from "react";

export const useValidation = ({
  destinationItem
}: {
  destinationItem: any;
}) => {
  const [destinationError, setDestinationError] = useState<string>("");

  useEffect(() => {
    setDestinationError("");
  }, [destinationItem]);

  const submitValidationCheck = useCallback(() => {
    let hasError = false;
    if (!destinationItem) {
      setDestinationError("لطفا کشور مقصد خود را انتخاب نمایید");
      hasError = true;
    }
    if (hasError) {
      return false;
    }
    return true;
  }, [destinationItem]);

  return { destinationError, submitValidationCheck };
};
