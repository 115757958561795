import { useForm } from "@website/form";
import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import {
  Button,
  ButtonColorType,
  ButtonSizeType,
  ButtonVariantType
} from "@website/ui";
import { deepCompare, numberConvert } from "@website/utils";
import { useRouter } from "next/router";
import { Children, Fragment, memo, useEffect } from "react";
import { FormProvider, Mode } from "react-hook-form";
import { ObjectSchema } from "yup";
import {
  CheckboxFilter,
  CounterFilter,
  Input,
  RadioFilter,
  RangeFilter,
  SwitchFilter,
  TimeFilter
} from "./filter-components";
import {
  useCityFilterState,
  useFilterLayoutEffect,
  useFilters
} from "./filter.hook";
import type { FilterResultType, FiltersSchemaType } from "./filter.types";
import { getQuery } from "./helpers";
import styles from "./index.module.scss";

interface ResetButtonProperties {
  size: ButtonSizeType;
  color: ButtonColorType;
  variant: ButtonVariantType;
}

interface FilterProperties<T extends Record<string, unknown>> {
  onChange?(value: FilterResultType): void;
  validations?: ObjectSchema<T>;
  mode?: Mode;
  schema: FiltersSchemaType;
  title: string;
  loading?: boolean;
  handleFiltersAction(): void;
  filtersActionTitle: string;
  resetButtonProps: ResetButtonProperties;
  onResetFilters?(): void;
}

const Filters = <T extends Record<string, unknown>>(
  properties: FilterProperties<T>
) => {
  const {
    schema,
    loading,
    title,
    onChange,
    validations,
    mode,
    handleFiltersAction,
    filtersActionTitle,
    resetButtonProps,
    onResetFilters
  } = properties;

  const filtersSchemaEntries = Object.entries(schema);
  const { t } = useTranslation();
  const router = useRouter();

  const methods = useForm<T>({
    mode,
    validations
  });

  const { isMobile, formRef } = useFilterLayoutEffect();

  const { setFilterQueries, selectedFiltersCount } = useFilters({ schema });

  const { onCloseFilters, visible } = useCityFilterState();

  useEffect(() => {
    const watcher = methods.watch((values, object) => {
      const [name] = object.name?.split(".") ?? [];
      const schema = (values as T)[name] as {
        type: string;
        value: unknown;
        withDebounce?: boolean;
      };

      const parameters = {
        name,
        asQuery: getQuery(schema),
        withDebounce: schema?.withDebounce
      };
      setFilterQueries(parameters)?.then(() => onChange?.(parameters));
    });

    return watcher.unsubscribe;
  }, [methods, onChange, setFilterQueries]);

  const children = filtersSchemaEntries
    .map(([name, schema]) => {
      switch (schema.type) {
        case "range": {
          return <RangeFilter key={name} {...schema} />;
        }
        case "time-range": {
          return <TimeFilter key={name} {...schema} />;
        }
        case "input": {
          return <Input key={name} {...schema} />;
        }
        case "counter": {
          return <CounterFilter key={name} name={name} {...schema} />;
        }
        case "switch": {
          return <SwitchFilter key={name} name={name} {...schema} />;
        }
        case "radio": {
          return <RadioFilter key={name} name={name} {...schema} />;
        }
        case "checkbox": {
          return <CheckboxFilter key={name} name={name} {...schema} />;
        }

        default: {
          return null;
        }
      }
    })
    .filter(Boolean);

  return (
    <aside
      className={`${styles["panel-container"]} ${
        visible ? styles["visible"] : ""
      }`}
      role={visible && isMobile ? "dialog" : undefined}
    >
      <div className={styles["filters"]}>
        <div className={styles["filters-header-box"]}>
          <div className={styles["filters-title-container"]}>
            <Button
              className={styles["close-button"]}
              color="neutral"
              onClick={onCloseFilters}
              variant="text"
            >
              <MaterialIcon className="text-xl" name="arrow_forward" />
            </Button>
            <span className={styles["filters-header-title"]}>{title}</span>
          </div>
          <Button
            {...resetButtonProps}
            hidden={!selectedFiltersCount}
            onClick={onResetFilters}
            startIcon={
              Number(selectedFiltersCount) ? (
                <MaterialIcon className="text-sm" name="delete_outline" />
              ) : null
            }
          >
            {t("global.remove_filters_with_count", {
              count: selectedFiltersCount
                ? numberConvert(selectedFiltersCount, {
                    locale: router.locale
                  })
                : ""
            })}
          </Button>
        </div>
        <form className={styles["filters-form-container"]} ref={formRef}>
          <FormProvider {...methods}>
            {Children.map(children, (child, index) => (
              <Fragment key={child?.key ?? index}>
                {child}
                {Children.count(children) - 1 !== index && (
                  <div className={styles["divider"]} />
                )}
              </Fragment>
            ))}
          </FormProvider>
        </form>
        <div className={styles["filters-footer-container"]}>
          <Button
            color="secondary"
            fullWidth
            loading={loading}
            onClick={handleFiltersAction}
            size="large"
          >
            {filtersActionTitle}
          </Button>
        </div>
      </div>
    </aside>
  );
};

const MemoizedFilters = memo(Filters, deepCompare);

export { MemoizedFilters as Filters };
