import { useTranslation } from "@website/locale";
import { Range } from "@website/ui";
import { numberConvert, numberSeparator } from "@website/utils";
import { isArray, toNumber } from "lodash";
import { useRouter } from "next/router";
import { useEffect, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { RangeTypes } from "../filter.types";
import styles from "../index.module.scss";

interface RangeFilterProperties extends RangeTypes {}

export const RangeFilter = (properties: RangeFilterProperties) => {
  const {
    title,
    description,
    min = 0,
    max,
    step,
    min_key,
    max_key,
    type
  } = properties;

  const router = useRouter();

  const { t } = useTranslation();

  const { control, setValue } = useFormContext();

  const value = useWatch({ control });

  const bounds = useMemo(() => {
    const maybeMin = router.query[min_key];
    const maybeMax = router.query[max_key];

    const minAsNumber = maybeMin ? toNumber(maybeMin) : undefined;
    const maxAsNumber = maybeMax ? toNumber(maybeMax) : undefined;

    return { minAsNumber, maxAsNumber };
  }, [max_key, min_key, router.query]);

  useEffect(() => {
    setValue(min_key, {
      type,
      withDebounce: false,
      value: bounds.minAsNumber
    });
    setValue(max_key, {
      type,
      withDebounce: false,
      value: bounds.maxAsNumber
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bounds, max_key, min_key, type]);

  return (
    <div className={styles["filter-range-container"]}>
      <div className={styles["filter-range-title"]}>{title}</div>
      <label
        className={styles["filter-range-description"]}
        htmlFor="range_total_price"
      >
        {description}
      </label>

      <div className={styles["filter-range-price"]}>
        <span>
          {t("global.price_with_tooman_currency", {
            price: numberConvert(
              numberSeparator(String(value[min_key]?.value || min) || "0"),
              {
                locale: router.locale
              }
            )
          })}
        </span>
        <span>
          {t("global.price_with_tooman_currency", {
            price: numberConvert(
              numberSeparator(String(value[max_key]?.value || max)),
              {
                locale: router.locale
              }
            )
          })}
        </span>
      </div>

      <Range
        color="secondary"
        id="range_total_price"
        max={max}
        min={min}
        onChange={(event, eventType) => {
          if (eventType === "minOnChange") {
            setValue(min_key, {
              type,
              withDebounce: true,
              value: isArray(event)
                ? event[0] === min
                  ? undefined
                  : event[0]
                : event
            });
          } else {
            setValue(max_key, {
              type,
              withDebounce: true,
              value: isArray(event)
                ? event[1] === max
                  ? undefined
                  : event[1]
                : event
            });
          }
        }}
        step={step}
        value={[value[min_key]?.value || min, value[max_key]?.value || max]}
      />
    </div>
  );
};
