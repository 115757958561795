import { useViewPort } from "@website/hooks";
import { MouseEvent, useCallback, useEffect, useState } from "react";
import { DialogPropertiesType } from "./dialog.types";

export const useDialog = (properties: DialogPropertiesType) => {
  const {
    "aria-describedby": ariaDescribedby,
    "aria-labelledby": ariaLabelledby,
    id,
    animate,
    open,
    onClose
  } = properties;

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [animateOpen, setAnimateOpen] = useState<boolean>(false);
  const { isMobile, isTablet } = useViewPort();

  useEffect(() => {
    if (isMobile || isTablet) {
      if (open) {
        document.body.classList.add("prevent-scroll");
      } else {
        document.body.classList.remove("prevent-scroll");
      }
    }

    return () => {
      document.body.classList.remove("prevent-scroll");
    };
  }, [open, isMobile, isTablet]);

  useEffect(() => {
    const checkIfPressEsc = (event: KeyboardEvent) => {
      if (open && event.key === "Escape" && onClose) {
        onClose();
      }
    };

    if (document) {
      if (open) {
        if (onClose) {
          document.addEventListener("keydown", checkIfPressEsc);
        }
        setModalOpen(true);
        setTimeout(() => {
          setAnimateOpen(true);
        }, 0);
      } else {
        if (onClose) {
          document.removeEventListener("keydown", checkIfPressEsc);
        }
        setAnimateOpen(false);
        setTimeout(
          () => {
            setModalOpen(false);
          },
          animate === "none" ? 0 : 150
        );
      }
    }

    return () => {
      if (document && onClose) {
        document.removeEventListener("keydown", checkIfPressEsc);
      }
    };
  }, [animate, onClose, open]);

  const stopPropagationOnModal = useCallback((event: MouseEvent) => {
    event.stopPropagation();
  }, []);

  return {
    transitionProperties: {
      timeout: animate === "none" ? 0 : 150,
      in: animateOpen
    },
    dialogContainerProperties: {
      "data-testid": "dialog-container",
      onClick: onClose
    },
    dialogProperties: {
      role: "dialog",
      "aria-labelledby": ariaLabelledby,
      "aria-describedby": ariaDescribedby,
      "aria-modal": true,
      onClick: stopPropagationOnModal,
      id
    },
    modalOpen
  };
};
