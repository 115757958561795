export const profile = {
  hotel: {
    main_search: {
      check_in: "Check In",
      check_out: "Check Out",
      destination: "Destination City/Hotel",
      number_of_adult: "Number of adult",
      number_of_child: "Number of child",
      number_of_room: "Number of room",
      greather_than_n_years: "{age} years and above",
      between_m_and_n_years: "{age_one} to {age_two} years",
      number_of_passenger_and_room: "Number of passanger and room",
      x_passenger_and_y_room: "{passenger_count} passenger, {room_count} room"
    },
    suggestions: {
      title: "Suggested hotels",
      subtitle:
        "To see the offers of each city, please select your destination",
      zero: "Zero",
      one: "One",
      two: "Two",
      three: "Three",
      four: "Four",
      five: "Five",
      count_stars: "{star} star",
      price_per_night: "Price per night",
      toman: "Toman"
    },
    popular_cities: {
      title: "Most popular cities in Iran"
    },
    features: {
      title:
        "SnappTrip! Booking hotels, residences and villas, buying plane tickets, trains, buses and all kinds of tours",
      easy_shop: {
        title: "Easy and fast shopping",
        description:
          "The easiest process of searching, buying and refunding plane tickets"
      },
      best_price: {
        title: "Best price guarantee",
        description: "The lowest price for buying a plane ticket"
      },
      support: {
        title: "24 hour support",
        description: "Customer support service 7 days a week and holidays"
      },
      safe_choice: {
        title: "Safe choice",
        description: "Buy system and charter plane tickets safely"
      }
    },
    special_contents: {
      title: "Special Contents"
    },
    faq: {
      title: "Frequently asked questions"
    },
    inbound_links: {
      title: "Plan your next Trip"
    }
  },
  general: {
    title: "Profile Account",
    gender: "gender",
    male: "male",
    female: "female",
    navbar: {
      username: "Username",
      wallet: "Wallet",
      support: "Support",
      logout: "Logout"
    }
  },
  information: {
    title: "User Information",
    submit: "Save Data",
    basic_information_section: {
      title: "Main Information",
      first_and_last_name: "First and Last Name",
      phone_number: "Phone Number",
      email: "Email"
    },
    password_section: {
      title: "Password",
      description: "",
      add: "Add Password",
      edit: "Edit Password"
    },
    secondary_information_section: {
      title: "Secondary Information",
      national_code: "National Code",
      birth_date: "Birth Date",
      gender: "Gender"
    },
    agency_section: {
      title: "Agency Information",
      code: "Agency Code",
      name: "Agency Name"
    },
    bank_information_section: {
      title: "Bank Information",
      account_number: "Account Number",
      card_number: "Card Number",
      iban: "iban"
    },
    delete_account_section: {
      title: "Delete Account",
      description: "",
      caution: "",
      delete: "Delete Account"
    },
    change_password_flow: {
      add_card: {
        title: "",
        description: "",
        new_password: "",
        old_password: "",
        accept: "",
        cancel: ""
      },
      edit_card: {
        title: "",
        description: "",
        new_password: "",
        old_password: "",
        info: "",
        accept: "",
        cancel: ""
      },
      otp_card: {
        title: "Enter OTP",
        description: "",
        accept: "Accept and Continue",
        cancel: "Cancel"
      }
    },
    password_card: {
      title: "Add Password",
      description: "",
      name: "Password",
      accept: "Accept and Save",
      cancel: "Cancel"
    },
    submit_card: {
      title: "Save Changes",
      description: "",
      accept: "Save and Exit",
      cancel: "Exit"
    },
    delete_account_card: {
      title: "Delete Account",
      description: "",
      sure: "",
      accept: "Delete Account",
      cancel: "Cancel"
    },
    delete_account_otp_card: {
      title: "Enter OTP",
      description: "",
      wrong_mobile_number: "Is the mobile number wrong ?",
      edit: "Edit",
      did_not_receive_otp: "Did not receive OTP ?",
      send_again: "Send Again",
      accept: "Accept and Delete Account",
      cancel: "Cancel"
    }
  },
  passengers_list: {
    title: "Passengers List"
  }
};
