import { memo, SVGProps } from "react";

const SvgAirlineSeatReclineNormal = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <g clipPath="url(#airline-seat-recline-normal_svg__a)">
      <path d="M15 20H8c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 6 18V7h2v11h7v2ZM11.5 6c-.55 0-1.02-.196-1.412-.588A1.923 1.923 0 0 1 9.5 4c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 11.5 2c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413s-.196 1.02-.587 1.412A1.927 1.927 0 0 1 11.5 6ZM16 22v-5h-5c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 9 15V9.5c0-.7.242-1.292.725-1.775C10.208 7.242 10.8 7 11.5 7s1.292.242 1.775.725C13.758 8.208 14 8.8 14 9.5V14h2c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v6h-2Z" />
    </g>
    <defs>
      <clipPath id="airline-seat-recline-normal_svg__a">
        <path d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(SvgAirlineSeatReclineNormal);
export default Memo;
