import { useQuery } from "react-query";
import {
  SearchTextService,
  SearchTextUrl
} from "../../../../../services/base/hotel/search-text";

export const useSearchTextService = (text: string) => {
  const {
    isLoading,
    data
    // error
  } = useQuery(
    [SearchTextUrl, text],
    () => SearchTextService({ text, token: "Jek", provider: "flightio" }),
    {
      enabled: text.length > 1
    }
  );

  return { isLoading, data: data?.data };
};
