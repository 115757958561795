import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { Button } from "../button";
import { useAccordionItem } from "./accordion.hook";
import styles from "./accordion.module.scss";
import { AccordionPropertiesType } from "./accordion.types";

const Accordion: FC<AccordionPropertiesType> = (properties) => {
  const {
    buttonText,
    buttonProperties,
    detailsProperties,
    summeryProperties,
    expanded
  } = useAccordionItem(properties);
  const {
    content,
    disabled,
    title,
    subtitle,
    variant = "outline",
    scroll,
    StartIcon,
    noOverflowHidden
  } = properties;

  return (
    <details
      {...detailsProperties}
      className={`${styles["details"]} ${styles[variant]} ${
        disabled ? styles["disabled"] : ""
      }`}
    >
      <summary {...summeryProperties} className={styles["summary"]}>
        <h3 className={styles["title"]}>
          {StartIcon ? <div className={styles["icon"]}>{StartIcon}</div> : null}
          {title}
        </h3>
        {subtitle ? (
          <span className={styles["subtitle"]}>{subtitle}</span>
        ) : null}
        <Button className={styles["button"]} {...buttonProperties}>
          {buttonText}
        </Button>
      </summary>
      <div
        className={`${styles["content-wrapper"]} ${
          expanded ? "" : styles["collapsed"]
        } ${noOverflowHidden ? styles["no-overflow"] : ""}`}
      >
        <div className={`${styles["content"]} ${scroll ? styles[scroll] : ""}`}>
          {content}
        </div>
      </div>
    </details>
  );
};

const AccordionMemoized = memo(Accordion, deepCompare);
export { AccordionMemoized as Accordion };
