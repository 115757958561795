import { memo, SVGProps } from "react";

const SvgOrigin = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    height="1em"
    viewBox="0 0 32 32"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <path d="M16 2.667c7.364 0 13.333 5.969 13.333 13.333S23.364 29.333 16 29.333 2.667 23.364 2.667 16 8.636 2.667 16 2.667zm0 8a5.333 5.333 0 1 0 0 10.667 5.333 5.333 0 0 0 0-10.667z" />
  </svg>
);

const Memo = memo(SvgOrigin);
export default Memo;
