import { useRouter } from "next/router";
import { useCallback } from "react";

export const useAddRemoveQuery = (key: string) => {
  const router = useRouter();

  const removeQuery = useCallback(() => {
    const { [key]: _, ...remainingQuery } = router.query;
    return router?.replace(
      {
        pathname: router?.pathname,
        query: remainingQuery
      },
      undefined,
      { shallow: true, scroll: false }
    );
  }, [key, router]);

  const addQuery = useCallback(
    (query: string) => {
      const { [key]: q } = router.query;
      if (q) {
        removeQuery()?.finally(
          () =>
            router?.replace(
              {
                pathname: router?.pathname,
                query: { ...router?.query, [key]: query }
              },
              undefined,
              { shallow: true, scroll: false }
            )
        );
      }
      return router?.push(
        {
          pathname: router?.pathname,
          query: { ...router?.query, [key]: query }
        },
        undefined,
        { shallow: true, scroll: false }
      );
    },
    [key, removeQuery, router]
  );

  return {
    addQuery,
    removeQuery
  };
};
