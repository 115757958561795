import { useViewPort } from "@website/hooks";
import { TripOriginSmall } from "@website/icons";
import { useTranslation } from "@website/locale";
import { AutoComplete, Button, ToggleButton } from "@website/ui-old";
import { FC } from "react";
import { useDestination, useHistory, useSubmit, useValidation } from "./hooks";
import { useToggle } from "./hooks/use-toggle";
import styles from "./search-visa.module.scss";
import { SearchVisaPropertiesType } from "./search-visa.types";

const SearchVisa: FC<SearchVisaPropertiesType> = (properties) => {
  const {
    destination,
    onChangeDestination,
    onChangeDestinationQuery,
    onSelectDestination,
    destinationListNormalized,
    destinationLabel,
    DestinationNoResult
  } = useDestination();

  const { destinationHistoryList, addToHistory, onSelectDestinationHistory } =
    useHistory(onChangeDestination);
  const { destinationError, submitValidationCheck } = useValidation({
    destinationItem: destination.selected
  });
  const { onSubmitClick, submitLoading, submitText } = useSubmit(
    destination.selected,
    addToHistory,
    submitValidationCheck
  );
  const { toggleList, toggleValue } = useToggle();

  const { isMobile } = useViewPort();
  const channel = process.env.NEXT_PUBLIC_CHANNEL_TYPE;
  const { t } = useTranslation();

  return (
    <div className={styles["container"]}>
      <div className={styles["options"]}>
        {channel === "jek" || isMobile ? (
          <ToggleButton
            color="secondary"
            list={toggleList}
            size="small"
            value={toggleValue}
            variant="contained"
          />
        ) : null}
      </div>
      <div className={styles["main"]}>
        <div className={styles["inputs-wrapper"]}>
          <div className={styles["autocomplete-wrapper"]}>
            <AutoComplete
              error={destinationError}
              fullWidth
              parts={[
                {
                  StartIcon: <TripOriginSmall />,
                  label: destinationLabel,
                  list: destinationListNormalized,
                  historyList:
                    destination.query.length <= 1 ? destinationHistoryList : [],
                  historyTitle: t("homepage.visa.main_search.your_last_search"),
                  text: destination.selected?.faName || "",
                  onSelect: onSelectDestination,
                  onSelectHistory: onSelectDestinationHistory,
                  onChangeQuery: onChangeDestinationQuery,
                  query: destination.query,
                  NoResult: DestinationNoResult
                }
              ]}
            />
          </div>
        </div>
        <div className={styles["button-wrapper"]}>
          <Button
            aria-label="search"
            fullWidth
            loading={submitLoading}
            onClick={onSubmitClick}
            size={isMobile ? "large" : "x-large"}
          >
            {submitText}
          </Button>
        </div>
      </div>
    </div>
  );
};

export { SearchVisa };
