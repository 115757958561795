import { MouseEvent, useCallback, useEffect, useRef, useState } from "react";
import { PopoverPropertiesType } from "./popover.types";

export const usePopover = (properties: PopoverPropertiesType) => {
  const rootReference = useRef<HTMLDivElement>(null);
  const {
    "aria-describedby": ariaDescribedby,
    "aria-labelledby": ariaLabelledby,
    id,
    animate,
    open,
    onClose
  } = properties;
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
  const [animateOpen, setAnimateOpen] = useState<boolean>(false);

  useEffect(() => {
    const checkIfClickedOutside: EventListenerOrEventListenerObject = ({
      target
    }) => {
      if (
        open &&
        rootReference.current &&
        !rootReference.current.contains(target as Node)
      ) {
        onClose();
      }
    };

    if (document) {
      if (open) {
        document.addEventListener("click", checkIfClickedOutside);
        setPopoverOpen(true);
        setTimeout(() => {
          setAnimateOpen(true);
        }, 0);
      } else {
        document.removeEventListener("click", checkIfClickedOutside);
        setAnimateOpen(false);
        setTimeout(
          () => {
            setPopoverOpen(false);
          },
          animate === "normal" ? 0 : 75
        );
      }
    }

    return () => {
      if (document) {
        document.removeEventListener("click", checkIfClickedOutside);
      }
    };
  }, [animate, onClose, open]);

  const onCloseButtonClick = useCallback(
    (event: MouseEvent<HTMLSpanElement>) => {
      event.stopPropagation();
      onClose();
    },
    [onClose]
  );

  const stopPropagationOnModal = useCallback((event: MouseEvent) => {
    event.stopPropagation();
  }, []);

  return {
    rootProps: {
      "aria-labelledby": ariaLabelledby,
      "aria-describedby": ariaDescribedby,
      "aria-modal": true,
      onClick: stopPropagationOnModal,
      ref: rootReference,
      role: "dialog",
      tabIndex: 0,
      id
    },
    transitionProps: {
      timeout: animate === "normal" ? 0 : 75
    },
    closeButtonProps: {
      onClick: onCloseButtonClick
    },
    popoverOpen,
    animateOpen
  };
};
