import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { useSwitch } from "./switch.hook";
import styles from "./switch.module.scss";
import { SwitchPropertiesType } from "./switch.types";

const Switch: FC<SwitchPropertiesType> = (properties) => {
  const { inputProps } = useSwitch(properties);
  const { className, color, disabled, label } = properties;

  return (
    <label
      className={`${styles["container"]} ${
        disabled ? styles["disabled"] : ""
      } ${styles[color || "primary"]} ${className ?? ""}`}
    >
      <div className={styles["input-wrapper"]}>
        <input {...inputProps} className={styles["input"]} />
        <div className={styles["slider"]} />
        <div className={styles["knob"]} />
      </div>
      <span className={styles["label"]} hidden={!label}>
        {label}
      </span>
    </label>
  );
};

const SwitchMemoized = memo(Switch, deepCompare);
export { SwitchMemoized as Switch };
