import { RatingDefaults } from "../reserve-rate.types";

const ratingDefaults: RatingDefaults = [
  { minRate: 4.5, text: "عالی", color: "success" },
  { minRate: 4, text: "خیلی خوب", color: "success" },
  { minRate: 3.5, text: "خوب", color: "default" },
  { minRate: 3.4, text: "", color: "default" }
];
export const useGetColorAndTextRate = (rate: string) =>
  ratingDefaults.find(
    (item) =>
      Number(rate) >= item?.minRate && { text: item?.text, color: item?.color }
  );
