import { MaterialIcon } from "@website/icons";
import { Children, RefObject, useEffect, useRef, useState } from "react";

export type FilterCollapseType = {
  children: React.ReactNode;
  active?: boolean;
};

export const useFilterCollapse = ({ children }: FilterCollapseType) => {
  const [showAll, setShowAll] = useState(false);
  const containerReference: RefObject<HTMLDivElement> = useRef(null);
  const [containerHeight, setContainerHeight] = useState(0);
  const [allElementHeight, setAllElementHeight] = useState<number>();
  const numberOfChildren = Children.count(children);

  useEffect(() => {
    const firstChild = containerReference.current
      ?.firstElementChild as HTMLElement;
    if (firstChild) {
      const firstChildHeight = firstChild.offsetHeight;
      setContainerHeight(firstChildHeight * 5);
      setAllElementHeight(firstChildHeight * numberOfChildren);
    }
  }, [numberOfChildren]);

  return {
    children,
    containerHeight,
    allElementHeight,
    showAll,
    containerReference,
    buttonProps: {
      children: showAll ? "بستن" : "موارد بیشتر",
      EndIcon: (
        <MaterialIcon
          className="text-sm"
          name={showAll ? "arrow_drop_up" : "arrow_drop_down"}
        />
      ),
      color: "secondary" as const,
      type: "button" as const,
      onClick: () => setShowAll((previous) => !previous),
      variant: "text" as const
    }
  };
};
