import { pick } from "lodash";
import { ButtonPropertiesType } from "./button.types";

export const useButton = (properties: ButtonPropertiesType) => {
  const { disabled, loading, datatestid } = properties;

  return {
    buttonProps: {
      ...pick(properties, "aria-label", "type", "onClick", "id"),
      disabled: disabled || loading,
      role: "button",
      "data-testid": datatestid
    },
    linkProps: {
      href: "",
      ...pick(properties, "aria-label", "href", "rel", "target", "id"),
      prefetch: false,
      disabled: disabled || loading,
      role: "link",
      "data-testid": datatestid
    }
  };
};
