import { useTranslation } from "@website/locale";
import { useRouter } from "next/router";
import { useMemo } from "react";

export const useToggle = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const query = new URLSearchParams(
    router.query as Record<string, string>
  ).toString();
  const { pathname } = router;
  const channel = process.env.NEXT_PUBLIC_CHANNEL_TYPE;
  const toggleList = useMemo(() => {
    if (channel === "jek") {
      return [
        {
          text: t("homepage.services.domestic_flights"),
          value: `/flights${query ? `?${query}` : ""}`,
          role: "next-link" as const,
          href: `/flights${query ? `?${query}` : ""}`,
          replace: true
        },
        {
          text: t("homepage.services.inter_flights"),
          value: `/interflights${query ? `?${query}` : ""}`,
          role: "next-link" as const,
          href: `/interflights${query ? `?${query}` : ""}`,
          replace: true
        },
        {
          text: t("homepage.services.visa"),
          value: `/home-visa${query ? `?${query}` : ""}`,
          role: "next-link" as const,
          href: `/home-visa${query ? `?${query}` : ""}`,
          replace: true
        }
      ];
    }
    return [
      {
        text: t("homepage.services.domestic_flights"),
        value: `/flights${query ? `?${query}` : ""}`,
        role: "next-link" as const,
        href: `/flights${query ? `?${query}` : ""}`
      },
      {
        text: t("homepage.services.inter_flights"),
        value: `/inter-flights${query ? `?${query}` : ""}`,
        role: "next-link" as const,
        href: `/inter-flights${query ? `?${query}` : ""}`
      }
    ];
  }, [channel, t, query]);

  const toggleValue = `${pathname}${query ? `?${query}` : ""}`;

  return {
    toggleList,
    toggleValue
  };
};
