import { Button, Chips, ListItem, MiniPopover } from "@website/ui-old";
import { FC } from "react";
import { usePassengerCount } from "./passenger-count.hook";
import styles from "./passenger-count.module.scss";
import { PassengerCountPropertiesType } from "./passenger-count.types";

export const PassengerCount: FC<PassengerCountPropertiesType> = (
  properties
) => {
  const { list, chipsProps, popoverProps, submitButtonProps, submitText } =
    usePassengerCount(properties);

  return (
    <div className={styles["passenger-count"]}>
      <Chips {...chipsProps} />
      <MiniPopover {...popoverProps}>
        <div className={styles["mini-popover"]}>
          <div className={styles["main"]}>
            <div className={styles["list"]}>
              {list.map((item) => (
                <ListItem key={item.title} {...item} />
              ))}
            </div>
          </div>
          <div className={styles["footer"]}>
            <Button {...submitButtonProps}>{submitText}</Button>
          </div>
        </div>
      </MiniPopover>
    </div>
  );
};
