import { useViewPort } from "@website/hooks";
import { useCallback, useMemo, useState } from "react";
import {
  DatePickerType,
  DatepickerCalendarLocaleType,
  DatepickerShowDateType,
  InitialShowDatesType
} from "../datepicker.types";
import {
  addMonth,
  createShowDatesArray,
  getToday,
  subtractMonth
} from "../datepicker.utils";
import { useInititalShowDates } from "./initialize-show-dates.hooks";

export const INITIAL_SHOW_DATES_NUMBER = 2;

export const useShowDate = (
  calendarLocale: DatepickerCalendarLocaleType,
  scrollToTop: () => void,
  initialShowDates?: InitialShowDatesType,
  type?: DatePickerType
) => {
  const { isDesktop } = useViewPort();

  const [showDates, setShowDates] = useState<DatepickerShowDateType>(
    createShowDatesArray(calendarLocale, INITIAL_SHOW_DATES_NUMBER)
  );

  useInititalShowDates({
    calendarLocale,
    setShowDates,
    showDates,
    initialShowDates,
    INITIAL_SHOW_DATES_NUMBER,
    type
  });

  const increaseShowDatesNumber = useCallback(() => {
    setShowDates(createShowDatesArray(calendarLocale, showDates.length + 1));
  }, [calendarLocale, showDates]);

  const today = getToday(calendarLocale);

  const nextMonthDisabled = useMemo(
    () =>
      (Number(showDates.at(-1)?.year) - today.year) * 12 +
        (Number(showDates.at(-1)?.month) - today.month) >=
      12,
    [showDates, today]
  );

  const previousMonthDisabled = useMemo(
    () =>
      (showDates[0].year - today.year) * 12 +
        (showDates[0].month - today.month) <=
      0,
    [showDates, today]
  );

  const onNextMonthClick = useCallback(() => {
    if (!nextMonthDisabled) {
      const newShowDates = showDates.map((item) => {
        const newDate = addMonth(item.year, item.month, 1);
        return { year: newDate.year, month: newDate.month };
      });
      setShowDates(newShowDates);
    }
  }, [showDates, nextMonthDisabled]);

  const onPreviousMonthClick = useCallback(() => {
    if (!previousMonthDisabled) {
      const newShowDates = showDates.map((item) => {
        const newDate = subtractMonth(item.year, item.month, 1);
        return { year: newDate.year, month: newDate.month };
      });
      setShowDates(newShowDates);
    }
  }, [showDates, previousMonthDisabled]);

  const onShowTodayClick = useCallback(() => {
    if (isDesktop) {
      setShowDates(
        createShowDatesArray(calendarLocale, INITIAL_SHOW_DATES_NUMBER)
      );
    } else {
      scrollToTop();
    }
  }, [scrollToTop, calendarLocale, isDesktop]);

  return {
    onNextMonthClick,
    onPreviousMonthClick,
    onShowTodayClick,
    showDates,
    increaseShowDatesNumber,
    nextMonthDisabled,
    previousMonthDisabled,
    setShowDates
  };
};
