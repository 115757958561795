import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { FC } from "react";
import { PropertiesType } from "./types";

export const TrainHint: FC<PropertiesType> = (properties: PropertiesType) => {
  const { t } = useTranslation();
  const { onHandleForiegnPeople, style } = properties;

  return (
    <section
      className={`bg-on-surface-disable-info w-full items-center justify-center rounded-lg pl-2 md:pl-0 ${style}`}
    >
      <p className="text-body-2 text-on-surface-high-emphasis p-4 pl-1 text-justify">
        {t("homepage.train.foriegn_people")}
      </p>
      <p className="text-on-surface-high-emphasis cursor-pointer p-2 text-2xl">
        <MaterialIcon name="close" onClick={onHandleForiegnPeople} />
      </p>
    </section>
  );
};
