import { memo, SVGProps } from "react";

const SvgSquacircle = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    height="1em"
    viewBox="0 0 72 72"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <path
      clip-rule="evenodd"
      d="M18.5822 2.05713L20.048 1.82568C30.5386 0.169272 41.2241 0.169272 51.7147 1.82568L53.1806 2.05713C61.746 3.40957 68.4642 10.1277 69.8166 18.6931L70.048 20.159C71.7045 30.6496 71.7045 41.3351 70.048 51.8257L69.8166 53.2915C68.4642 61.857 61.746 68.5751 53.1806 69.9276L51.7147 70.159C41.2241 71.8154 30.5386 71.8154 20.048 70.159L18.5822 69.9276C10.0167 68.5751 3.29861 61.857 1.94617 53.2915L1.71472 51.8257C0.0583105 41.3351 0.0583105 30.6496 1.71472 20.159L1.94617 18.6931C3.29861 10.1277 10.0167 3.40957 18.5822 2.05713Z"
      fill-rule="evenodd"
    />
  </svg>
);

const Memo = memo(SvgSquacircle);
export default Memo;
