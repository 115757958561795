import { memo, SVGProps } from "react";

const SvgFlight = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    height="1em"
    viewBox="0 0 32 32"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <path d="M25.6 28.8c.4-.4.533-1.067.533-1.6l-3.467-14.4 6.4-6.4c.933-.933.933-2.533 0-3.467s-2.533-.933-3.467 0l-6.4 6.4L4.799 6c-.533-.133-1.2 0-1.6.533-.8.8-.533 2.133.4 2.667l10.267 5.333-6.4 6.4-3.733-.533c-.267 0-.533 0-.667.267l-.667.667c-.4.4-.267 1.067.133 1.333l4.267 2.4 2.4 4.267c.267.533.933.533 1.333.133l.667-.667c.133-.133.267-.4.267-.667l-.533-3.733 6.4-6.4 5.333 10.267c.667 1.067 2 1.333 2.933.533z" />
  </svg>
);

const Memo = memo(SvgFlight);
export default Memo;
