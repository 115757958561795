import { FC, memo, useMemo } from "react";
import { Loading } from "..";
import { useButton } from "./button.hook";
import styles from "./button.module.scss";
import { ButtonPropertiesType } from "./button.types";

const Button: FC<ButtonPropertiesType> = (properties) => {
  const {
    children,
    className,
    color,
    disabled,
    EndIcon,
    fullWidth,
    loading,
    loadingWithText,
    role,
    size,
    StartIcon,
    variant,
    text,
    equalDimension,
    divButtonType
  } = properties;
  const { buttonProps, linkProps, divButtonProps } = useButton(properties);

  const classNames = `${styles["button"]} ${styles[variant ?? "contained"]} ${
    styles[color ?? "primary"]
  } ${disabled ? styles["disabled"] : ""} ${styles[size ?? "medium"]} ${
    fullWidth ? styles["full-width"] : ""
  } ${equalDimension ? styles["equal-dimension"] : ""} ${className ?? ""}`;

  const elements = useMemo(
    () =>
      loading ? (
        children && loadingWithText ? (
          <Loading color="primary" size="xs">
            <span className="mx-1">{children}</span>
          </Loading>
        ) : (
          <Loading color="white" />
        )
      ) : (
        <>
          {StartIcon ? (
            <span
              className={`${styles["icon"]} ${
                size === "x-small"
                  ? styles["icon-large"]
                  : children
                    ? styles["icon-small"]
                    : styles["icon-medium"]
              }`}
            >
              {StartIcon}
            </span>
          ) : null}
          {text ? <div className={styles["text"]}>{text}</div> : null}
          {children ? <span className="mx-1">{children}</span> : null}
          {EndIcon ? (
            <span
              className={`${styles["icon"]} ${
                size === "x-small"
                  ? styles["icon-large"]
                  : children
                    ? styles["icon-small"]
                    : styles["icon-medium"]
              }`}
            >
              {EndIcon}
            </span>
          ) : null}
        </>
      ),
    [EndIcon, StartIcon, children, loading, loadingWithText, size, text]
  );

  if (role === "link") {
    return (
      <a {...linkProps} className={classNames}>
        {elements}
      </a>
    );
  }
  if (divButtonType) {
    return (
      <div {...divButtonProps} className={classNames}>
        {elements}
      </div>
    );
  }
  return (
    <button {...buttonProps} className={classNames}>
      {elements}
    </button>
  );
};

const ButtonMemoized = memo(Button);
export { ButtonMemoized as Button };
