import { useTranslation } from "@website/locale";
import { FC, memo } from "react";
import { Button } from "../../..";
import { useCta } from "./cta.hook";
import styles from "./cta.module.scss";
import { CtaPropertiesType } from "./cta.types";

const Cta: FC<CtaPropertiesType> = (properties) => {
  const { t } = useTranslation();

  const { acceptButtonProperties, rejectButtonProperties } = useCta(properties);

  return (
    <div className={styles["container"]}>
      <Button {...rejectButtonProperties} className={styles["reject-button"]}>
        {t("action.reset")}
      </Button>
      <Button {...acceptButtonProperties} className={styles["accept-button"]}>
        {t("action.ok")}
      </Button>
    </div>
  );
};

const CtaMemoized = memo(Cta);
export { CtaMemoized as Cta };
