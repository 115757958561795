import { FC, memo } from "react";
import styles from "./skeleton.module.scss";
import { SkeletonPropertiesType } from "./skeleton.types";

const Skeleton: FC<SkeletonPropertiesType> = (properties) => {
  const { shape = "rectangle", height } = properties;

  return (
    <div
      className={`${styles["container"]} ${styles[shape]}`}
      style={{
        height: height ? `${height}px` : "100%",
        width: shape === "circle" || shape === "square" ? `${height}px` : "100%"
      }}
    />
  );
};

const SkeletonMemoized = memo(Skeleton);
export { SkeletonMemoized as Skeleton };
