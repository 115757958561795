import {
  CalendarDate,
  getLocalTimeZone,
  GregorianCalendar,
  IslamicTabularCalendar,
  PersianCalendar,
  toCalendar,
  today
} from "@internationalized/date";
import { DayType, gregorianToShamsi, MonthType } from "@website/utils";

export const getToday = (calendarType: string) => {
  const localDate = today(getLocalTimeZone());
  const gregorianDate = new CalendarDate(
    new GregorianCalendar(),
    localDate.year,
    localDate.month,
    localDate.day
  );
  const date =
    calendarType === "persian"
      ? // @internationalize/date has issue with
        // convert from gregorian to persian.
        // this code fix that issue.
        // update it later.
        new CalendarDate(
          new PersianCalendar(),
          ...gregorianToShamsi(
            gregorianDate.year,
            gregorianDate.month as MonthType,
            gregorianDate.day as DayType
          )
        )
      : calendarType === "hijri"
        ? toCalendar(gregorianDate, new IslamicTabularCalendar())
        : gregorianDate;

  return {
    year: date.year,
    month: date.month,
    day: date.day
  };
};

export const getDaysInMonth = (
  calendarType: string,
  year: number,
  month: number
) => {
  let date;
  let calendar;
  if (calendarType === "persian") {
    date = new CalendarDate(new PersianCalendar(), year, month, 1);
    calendar = new PersianCalendar();
  } else if (calendarType === "hijri") {
    date = new CalendarDate(new IslamicTabularCalendar(), year, month, 1);
    calendar = new IslamicTabularCalendar();
  } else {
    date = new CalendarDate(new GregorianCalendar(), year, month, 1);
    calendar = new GregorianCalendar();
  }

  const daysInMonth = calendar.getDaysInMonth(date);

  return daysInMonth;
};
