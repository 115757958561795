import { useTranslation } from "@website/locale";
import { useCallback } from "react";

export const useFooter = () => {
  const { t } = useTranslation();
  const channel = process.env.NEXT_PUBLIC_CHANNEL_TYPE;
  const baseUrl = process.env.NEXT_PUBLIC_BASE_URL_B2C;

  const onClickEnamad = useCallback(() => {
    if (window) {
      window.open(
        "https://trustseal.enamad.ir/?id=83827&Code=RBoh3Kq5s0Z8xE4tbcG",
        "Popup",
        "toolbar=no, scrollbars=no, location=no, statusbar=no, menubar=no, resizable=0, width=450, height=630, top=30"
      );
    }
  }, []);

  const onClickSamandehi = useCallback(() => {
    if (window) {
      window.open(
        "https://logo.samandehi.ir/Verify.aspx?id=128793&p=rfthuiwkmcsijyoepfvlxlao",
        "Popup",
        "toolbar=no, scrollbars=no, location=no, statusbar=no, menubar=no, resizable=0, width=450, height=630, top=30"
      );
    }
  }, []);

  const onClickEcunion = useCallback(() => {
    if (window) {
      window.open(
        `https://ecunion.ir/verify/snapptrip.com?token=44328795b1d0308e6e2b`,
        "Popup",
        "toolbar=no, scrollbars=no, location=no, statusbar=no, menubar=no, resizable=0, width=450, height=630, top=30"
      );
    }
  }, []);

  const onClickFarasa = useCallback(() => {
    if (window) {
      window.open(
        "https://farasa.cao.ir/sysworkflow/fa/modern/3810212626028ab03488017019616799/6464336316028ab04e3c618028352200.php",
        "Popup",
        "toolbar=no, scrollbars=no, location=no, statusbar=no, menubar=no, resizable=0, width=450, height=630, top=30"
      );
    }
  }, []);

  return {
    certificates: [
      {
        // referrerPolicy: "origin",
        id: "RBoh3Kq5s0Z8xE4tbcGh",
        onClick: onClickEnamad,
        alt: "logo-enamad",
        src: "https://trustseal.enamad.ir/logo.aspx?id=83827&Code=RBoh3Kq5s0Z8xE4tbcGh",
        width: 150,
        height: 150
      },
      {
        // referrerPolicy: "origin",
        id: "jxlznbqeoeukjzpesizprgvj",
        onClick: onClickSamandehi,
        alt: "logo-samandehi",
        src: "https://logo.samandehi.ir/logo.aspx?id=128793&p=nbpdodrfaqgwyndtbsiyqfti",
        width: 150,
        height: 150
      },
      {
        title: "ecunion logo",
        alt: "ecunion logo",
        id: "44328795b1d0308e6e2b",
        src: "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQwIiBoZWlnaHQ9IjM2MCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KCTxwYXRoIGQ9Im0xMjAgMjQzbDk0LTU0IDAtMTA5IC05NCA1NCAwIDEwOSAwIDB6IiBmaWxsPSIjODA4Mjg1Ii8+Cgk8cGF0aCBkPSJtMTIwIDI1NGwtMTAzLTYwIDAtMTE5IDEwMy02MCAxMDMgNjAgMCAxMTkgLTEwMyA2MHoiIHN0eWxlPSJmaWxsOm5vbmU7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS13aWR0aDo1O3N0cm9rZTojMDBhZWVmIi8+Cgk8cGF0aCBkPSJtMjE0IDgwbC05NC01NCAtOTQgNTQgOTQgNTQgOTQtNTR6IiBmaWxsPSIjMDBhZWVmIi8+Cgk8cGF0aCBkPSJtMjYgODBsMCAxMDkgOTQgNTQgMC0xMDkgLTk0LTU0IDAgMHoiIGZpbGw9IiM1ODU5NWIiLz4KCTxwYXRoIGQ9Im0xMjAgMTU3bDQ3LTI3IDAtMjMgLTQ3LTI3IC00NyAyNyAwIDU0IDQ3IDI3IDQ3LTI3IiBzdHlsZT0iZmlsbDpub25lO3N0cm9rZS1saW5lY2FwOnJvdW5kO3N0cm9rZS1saW5lam9pbjpyb3VuZDtzdHJva2Utd2lkdGg6MTU7c3Ryb2tlOiNmZmYiLz4KCTx0ZXh0IHg9IjE1IiB5PSIzMDAiIGZvbnQtc2l6ZT0iMjVweCIgZm9udC1mYW1pbHk9IidCIFlla2FuJyIgc3R5bGU9ImZpbGw6IzI5Mjk1Mjtmb250LXdlaWdodDpib2xkIj7Yudi22Ygg2KfYqtit2KfYr9uM2Ycg2qnYtNmI2LHbjDwvdGV4dD4KCTx0ZXh0IHg9IjgiIHk9IjM0MyIgZm9udC1zaXplPSIyNXB4IiBmb250LWZhbWlseT0iJ0IgWWVrYW4nIiBzdHlsZT0iZmlsbDojMjkyOTUyO2ZvbnQtd2VpZ2h0OmJvbGQiPtqp2LPYqCDZiCDaqdin2LHZh9in24wg2YXYrNin2LLbjDwvdGV4dD4KPC9zdmc+",
        width: 240,
        height: 360,
        onClick: onClickEcunion
      },
      {
        title: "farasa logo",
        alt: "farasa logo",
        id: "6464336316028ab04e3c618028352200",
        src: "https://farasa.cao.ir/PluginLib/JDSApp/img/pax",
        width: 942,
        height: 934,
        onClick: onClickFarasa
      }
    ],
    services: [
      {
        title: t("footer.services.hotel_domestic"),
        url: `${baseUrl}/`
      },
      {
        title: t("footer.services.flight_domestic"),
        url: `${baseUrl}/flights`
      },
      {
        title: t("footer.services.flight_international"),
        url: `${baseUrl}/${
          channel === "jek" ? "interflights" : "inter-flights"
        }`
      },
      {
        title: t("footer.services.bus"),
        url: `${baseUrl}/bus`
      }
    ],

    moreInfo:
      channel === "snapp-ir"
        ? [
            {
              title: t("footer.more_info.about_us"),
              url: `${baseUrl}/about`
            },
            {
              title: t("footer.more_info.contact_us"),
              url: `${baseUrl}/contact`
            },
            {
              title: t("footer.more_info.faq"),
              url: `${baseUrl}/faq`
            }
          ]
        : [
            {
              title: t("footer.more_info.about_us"),
              url: `${baseUrl}/about`
            },
            {
              title: t("footer.more_info.contact_us"),
              url: `${baseUrl}/contact`
            },
            {
              title: t("footer.more_info.terms"),
              url: `${baseUrl}/policy`
            },
            {
              title: t("footer.more_info.club"),
              url: `${baseUrl}/loyalty`
            },
            {
              title: t("footer.more_info.faq"),
              url: `${baseUrl}/faq`
            },
            {
              title: t("footer.more_info.careers"),
              url: baseUrl?.replace(/www|pwa|web/g, "careers")
            }
          ],
    links: [
      {
        title: t("footer.features.panel"),
        url: `${baseUrl}/blog/landingbuilder/b2b/`
      },
      {
        title: t("footer.features.agency"),
        url: `${baseUrl}/blog/landingbuilder/b2b/`
      },
      {
        title: t("footer.features.blog"),
        url: `${baseUrl}/blog`
      },
      {
        title: t("footer.features.info"),
        url: `${baseUrl}/app`
      },
      {
        title: t("footer.features.cancellation"),
        url: `${baseUrl}/policy`
      }
    ],
    linksBadgs: [
      {
        title: t("footer.links_badge.charter_ticket"),
        url: `${baseUrl}/charter`
      },
      {
        title: t("footer.links_badge.tahran_to_mashhad_ticket"),
        url: `${baseUrl}/flights/THR-tehran/MHD-mashhad`
      },
      {
        title: t("footer.links_badge.tehran_to_istanbul_ticket"),
        url: `${baseUrl}/${
          channel === "jek" ? "interflights" : "inter-flights"
        }/IKA_Tehran/IST_Istanbul`
      },
      {
        title: t("footer.links_badge.dariush_kish_hotel"),
        url: `${baseUrl}/%D8%B1%D8%B2%D8%B1%D9%88-%D9%87%D8%AA%D9%84/%DA%A9%DB%8C%D8%B4/%D9%87%D8%AA%D9%84-%D8%AF%D8%A7%D8%B1%DB%8C%D9%88%D8%B4`
      },
      {
        title: t("footer.links_badge.isfahan_hotel"),
        url: `${baseUrl}/%D8%B1%D8%B2%D8%B1%D9%88-%D9%87%D8%AA%D9%84/%D8%A7%D8%B5%D9%81%D9%87%D8%A7%D9%86`
      },
      {
        title: t("footer.links_badge.shiraz_hotel"),
        url: `${baseUrl}/%D8%B1%D8%B2%D8%B1%D9%88-%D9%87%D8%AA%D9%84/%D8%B4%DB%8C%D8%B1%D8%A7%D8%B2`
      },
      {
        title: t("footer.links_badge.spinas_palace_hotel"),
        url: `${baseUrl}/%D8%B1%D8%B2%D8%B1%D9%88-%D9%87%D8%AA%D9%84/%D8%AA%D9%87%D8%B1%D8%A7%D9%86/%D9%87%D8%AA%D9%84-%D8%A7%D8%B3%D9%BE%DB%8C%D9%86%D8%A7%D8%B3-%D9%BE%D8%A7%D9%84%D8%A7%D8%B3-%DA%A9%D9%84%DA%A9%D8%B3%DB%8C%D9%88%D9%86-%D9%84%D9%88%DA%A9%D8%B3-%DA%AF%D8%B1%D9%88%D9%87-%D9%87%D8%AA%D9%84-%D9%87%D8%A7%DB%8C-%D8%A7%D8%B3%D9%BE%DB%8C%D9%86%D8%A7%D8%B3`
      },
      {
        title: t("footer.links_badge.tehran_hotel"),
        url: `${baseUrl}/%D8%B1%D8%B2%D8%B1%D9%88-%D9%87%D8%AA%D9%84/%D8%AA%D9%87%D8%B1%D8%A7%D9%86`
      },
      {
        title: t("footer.links_badge.mashhad_hotel"),
        url: `${baseUrl}/%D8%B1%D8%B2%D8%B1%D9%88-%D9%87%D8%AA%D9%84/%D9%85%D8%B4%D9%87%D8%AF`
      },
      {
        title: t("footer.links_badge.kish_hotel"),
        url: `${baseUrl}/%D8%B1%D8%B2%D8%B1%D9%88-%D9%87%D8%AA%D9%84/%DA%A9%DB%8C%D8%B4`
      },
      {
        title: t("footer.links_badge.last_second_ticket"),
        url: `${baseUrl}/last-second`
      },
      {
        title: t("footer.links_badge.tehran_dobay_ticket"),
        url: `${baseUrl}/${
          channel === "jek" ? "interflights" : "inter-flights"
        }/IKA_Tehran/DXB_Dubai`
      }
    ]
  };
};
