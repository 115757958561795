import { AxiosResponse } from "axios";
import { Axios } from "../../utils";
import { BASE_URL_PROFILE } from "../urls";
import {
  AuthRequestOtpBodyType,
  AuthRequestOtpResponseType
} from "./auth-request-otp.types";

export const AuthRequestOtpUrl = `${BASE_URL_PROFILE}/auth/request-otp`;

export const AuthRequestOtpService = (
  body: AuthRequestOtpBodyType
): Promise<AuthRequestOtpResponseType> =>
  Axios.post(AuthRequestOtpUrl, body).then(
    ({ data }: AxiosResponse<AuthRequestOtpResponseType>) => data
  );
