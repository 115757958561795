import { useViewPort } from "@website/hooks";
import { MouseEvent, useCallback, useEffect, useRef, useState } from "react";
import { PopoverFullPropertiesType } from "./popover-full.types";

export const usePopoverFull = (properties: PopoverFullPropertiesType) => {
  const rootReference = useRef<HTMLDivElement>(null);
  const {
    "aria-describedby": ariaDescribedby,
    "aria-labelledby": ariaLabelledby,
    id,
    animate,
    open,
    onClose
  } = properties;
  const { isMobile, isTablet } = useViewPort();

  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
  const [animateOpen, setAnimateOpen] = useState<boolean>(false);

  useEffect(() => {
    if (isMobile || isTablet) {
      if (open) {
        document.body.classList.add("prevent-scroll");
      } else {
        document.body.classList.remove("prevent-scroll");
      }
    }

    return () => {
      document.body.classList.remove("prevent-scroll");
    };
  }, [open, isMobile, isTablet]);

  useEffect(() => {
    let timeoutId1: NodeJS.Timeout | null = null;
    let timeoutId2: NodeJS.Timeout | null = null;

    const checkIfClickedOutside: EventListenerOrEventListenerObject = ({
      target
    }) => {
      if (
        open &&
        rootReference.current &&
        !rootReference.current.contains(target as Node)
      ) {
        onClose && onClose();
      }
    };

    if (document) {
      if (open) {
        document.addEventListener("click", checkIfClickedOutside);
        setPopoverOpen(true);
        timeoutId1 = setTimeout(() => {
          setAnimateOpen(true);
        }, 0);
      } else {
        document.removeEventListener("click", checkIfClickedOutside);
        setAnimateOpen(false);
        if (timeoutId1) {
          clearTimeout(timeoutId1);
        }
        timeoutId2 = setTimeout(
          () => {
            setPopoverOpen(false);
          },
          animate === "normal" ? 0 : 75
        );
      }
    }

    return () => {
      if (document) {
        document.removeEventListener("click", checkIfClickedOutside);
      }
      if (timeoutId1) {
        clearTimeout(timeoutId1);
      }
      if (timeoutId2) {
        clearTimeout(timeoutId2);
      }
    };
  }, [animate, onClose, open]);

  const stopPropagationOnModal = useCallback((event: MouseEvent) => {
    event.stopPropagation();
  }, []);

  return {
    rootProps: {
      "aria-labelledby": ariaLabelledby,
      "aria-describedby": ariaDescribedby,
      "aria-modal": true,
      onClick: stopPropagationOnModal,
      ref: rootReference,
      role: "dialog",
      tabIndex: 0,
      id
    },
    transitionProps: {
      timeout: animate === "normal" ? 0 : 75
    },
    popoverOpen,
    animateOpen
  };
};
