import { AutoCompletePropertiesType } from "../auto-complete.types";

export const useSwap = (properties: AutoCompletePropertiesType) => {
  const { onSwap } = properties;

  return {
    swapButtonProperties: {
      onClick: onSwap
    }
  };
};
