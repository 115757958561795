import { useTranslation } from "@website/locale";
import { useCallback, useState } from "react";
import { AuthB2BVerifyOtpService } from "../../services/auth-b2b-verify-otp";
import { AuthRequestOtpService } from "../../services/auth-request-otp";
import { B2BOptPropertiesTypes } from "./b2b-otp.types";

export const useB2BOtp = (properties: B2BOptPropertiesTypes) => {
  const { userData, onEndAuth, setStep, setUserData } = properties;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingSendAgain, setIsLoadingSendAgain] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [otp, setOtp] = useState<string>("");
  const [showSendAgain, setShowSendAgain] = useState<boolean>(false);
  const { t } = useTranslation();

  const onChangeOtp = useCallback((value: string) => {
    setOtp(value);
  }, []);

  const onSubmitOtp = useCallback(async () => {
    const { otpId, otpLength, contractId } = userData;
    if (otp.length === otpLength) {
      setIsLoading(true);
      setError("");
      if (otpId) {
        AuthB2BVerifyOtpService({
          otp,
          otpId,
          contractId
        })
          .then((data) => {
            const { accessToken, hasPassword, refreshToken, userExists } = data;
            if (accessToken) {
              setUserData({
                ...userData,
                accessToken,
                hasPassword,
                refreshToken,
                userExists
              });
              onEndAuth?.(accessToken, refreshToken);
            }
          })
          .catch((error) => {
            setError(
              error?.response?.data?.message || t("login.general_error_message")
            );
          })
          .finally(() => setIsLoading(false));
      } else {
        setError(t("login.enter_code"));
      }
    }
  }, [userData, otp, setUserData, onEndAuth, t]);

  const onTimerEnd = useCallback(() => {
    setShowSendAgain(true);
  }, []);

  const onClickSendAgain = useCallback(async () => {
    const { phoneNumber } = userData;
    setIsLoadingSendAgain(true);
    setError("");
    AuthRequestOtpService({ phoneNumber })
      .then((data) => {
        setUserData({ ...userData, ...data });
        setShowSendAgain(false);
      })
      .catch((error) => {
        setError(
          error?.response?.data?.message || t("login.general_error_message")
        );
      })
      .finally(() => setIsLoadingSendAgain(false));
  }, [setUserData, t, userData]);

  const onBackClick = useCallback(() => {
    const { forgotPassword } = userData;
    if (forgotPassword) {
      setStep("password");
    } else {
      setStep("phone-number");
    }
  }, [setStep, userData]);

  return {
    isLoading,
    otp,
    onChangeOtp,
    onSubmitOtp,
    error,
    onBackClick,
    onTimerEnd,
    showSendAgain,
    onClickSendAgain,
    isLoadingSendAgain
    // onPasswordClick,
  };
};
