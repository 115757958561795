import { useLocalStorage } from "@website/hooks";
import { History } from "@website/icons";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  INTER_FLIGHT_ROUTE_HISTORY_LOCAL_STORAGE_KEY,
  INTER_FLIGHT_SEARCH_HISTORY_LOCAL_STORAGE_KEY
} from "../search-inter-flights.constant";
import {
  createOriginDestinationHistorySubtitle,
  createOriginDestinationHistoryTitle
} from "../utils";

export const useHistory = (
  onChangeOrigin: (item: any) => void,
  onChangeDestination: (item: any) => void
) => {
  const [history, setHistory] = useState<
    Array<{ origin: any; destination: any }>
  >([]);
  const { addToLocalStorage, getFromLocalStorage } = useLocalStorage();

  useEffect(() => {
    const historyString = getFromLocalStorage(
      INTER_FLIGHT_ROUTE_HISTORY_LOCAL_STORAGE_KEY
    );
    const history = historyString ? JSON.parse(historyString) : [];
    setHistory(history);
  }, [getFromLocalStorage]);

  const addToRouteHistory = useCallback(
    (origin, destination) => {
      const historyString = getFromLocalStorage(
        INTER_FLIGHT_ROUTE_HISTORY_LOCAL_STORAGE_KEY
      );
      const history = historyString ? JSON.parse(historyString) : [];

      let newHistory = history.filter(
        (item) =>
          item.origin.iataCode !== origin.iataCode &&
          item.destination.iataCode !== destination.iataCode
      );

      const newHistoryItem = {
        origin,
        destination
      };
      newHistory = [newHistoryItem, ...newHistory].slice(0, 2);

      addToLocalStorage(
        INTER_FLIGHT_ROUTE_HISTORY_LOCAL_STORAGE_KEY,
        JSON.stringify(newHistory)
      );
      setHistory(newHistory);
    },
    [addToLocalStorage, getFromLocalStorage]
  );

  const addToSearchHistory = useCallback(
    (
      origin: any,
      destination: any,
      startDateTime: any,
      endDateTime: any,
      calendarLocale: string,
      saleType: any,
      passengerNumber: any,
      flightClass: any
    ) => {
      const historyString = getFromLocalStorage(
        INTER_FLIGHT_SEARCH_HISTORY_LOCAL_STORAGE_KEY
      );
      const history = historyString ? JSON.parse(historyString) : [];

      let newHistory = history.filter(
        (item) =>
          !(
            item.origin.iataCode === origin.iataCode &&
            item.destination.iataCode === destination.iataCode &&
            item.startDateTime === startDateTime &&
            (item.endDateTime && endDateTime
              ? item.endDateTime === endDateTime
              : true)
          )
      );

      const newHistoryItem = {
        origin,
        destination,
        startDateTime,
        endDateTime,
        calendarLocale,
        saleType,
        passengerNumber,
        flightClass
      };
      newHistory = [newHistoryItem, ...newHistory].slice(0, 4);

      addToLocalStorage(
        INTER_FLIGHT_SEARCH_HISTORY_LOCAL_STORAGE_KEY,
        JSON.stringify(newHistory)
      );
    },
    [addToLocalStorage, getFromLocalStorage]
  );

  const addToHistory = useCallback(
    (
      origin: any,
      destination: any,
      startDateTime: any,
      endDateTime: any,
      calendarLocale: string,
      saleType: any,
      passengerNumber: any,
      flightClass: any
    ) => {
      addToRouteHistory(origin, destination);
      addToSearchHistory(
        origin,
        destination,
        startDateTime,
        endDateTime,
        calendarLocale,
        saleType,
        passengerNumber,
        flightClass
      );
    },
    [addToRouteHistory, addToSearchHistory]
  );

  const onSelectOriginRouteHistory = useCallback(
    (id: string) => {
      if (history) {
        let historyItem = null;
        for (const element of history) {
          const checkKey = `${element.origin.iataCode}-${element.origin.cityName}`;
          if (`${id}` === checkKey) {
            historyItem = element;
            break;
          }
        }
        if (historyItem) {
          onChangeOrigin(historyItem.origin);
        }
      }
    },
    [history, onChangeOrigin]
  );

  const onSelectDestinationRouteHistory = useCallback(
    (id: string) => {
      if (history) {
        let historyItem = null;
        for (const element of history) {
          const checkKey = `${element.destination.iataCode}-${element.destination.cityName}`;
          if (`${id}` === checkKey) {
            historyItem = element;
            break;
          }
        }

        if (historyItem) {
          onChangeDestination(historyItem.destination);
        }
      }
    },
    [history, onChangeDestination]
  );

  const originRouteHistoryList = useMemo(
    () =>
      history.map((item) => ({
        id: `${item?.origin?.iataCode}-${item?.origin?.cityName}`,
        title: createOriginDestinationHistoryTitle(item.origin),
        subtitle: createOriginDestinationHistorySubtitle(item.origin),
        StartIcon: <History />
      })),
    [history]
  );

  const destinationRouteHistoryList = useMemo(
    () =>
      history.map((item) => ({
        id: `${item?.destination?.iataCode}-${item?.destination?.cityName}`,
        title: createOriginDestinationHistoryTitle(item.destination),
        subtitle: createOriginDestinationHistorySubtitle(item.destination),
        StartIcon: <History />
      })),
    [history]
  );

  return {
    destinationRouteHistoryList,
    originRouteHistoryList,
    onSelectOriginRouteHistory,
    onSelectDestinationRouteHistory,
    addToHistory
  };
};
