import { useTranslation } from "@website/locale";
import { numberConvert } from "@website/utils";
import { FC, memo } from "react";
import { stringOfNumbers } from "../../datepicker.utils";
import { useTable } from "./table.hook";
import styles from "./table.module.scss";
import { TablePropertiesType } from "./table.types";

const Table: FC<TablePropertiesType> = (properties) => {
  const { calendarLocale, year, month, type, view } = properties;
  const { t, locale, direction } = useTranslation();
  const { days } = useTable(properties);
  const isFlat = type === "flat";

  return (
    <div
      className={`${styles["container"]} ${
        isFlat ? styles["container-flat"] : ""
      } ${styles[direction || "ltr"]}`}
    >
      <div
        className={`${
          isFlat && view !== "mobile"
            ? styles["week-names-container-desktop-flat"]
            : !isFlat && view !== "mobile"
              ? `${styles["week-names-container-desktop"]} ${styles["week-names-container-desktop__default"]}`
              : styles["week-names-container-desktop"]
        } `}
      >
        {[1, 2, 3, 4, 5, 6, 7].map((week) => (
          <div className={styles["week-name"]} key={week}>
            <div className={isFlat ? styles["aspect-ratio-content"] : ""}>
              {t<string>(
                `calendar.${calendarLocale}.weekNamesAbbr.${stringOfNumbers[week]}`
              )}
            </div>
          </div>
        ))}
      </div>
      <div
        className={`${styles["month-name"]} ${
          view === "mobile" ? "" : styles["month-name__default"]
        }`}
      >
        {t<string>(
          `calendar.${calendarLocale}.monthNames.${stringOfNumbers[month]}`
        )}{" "}
        {numberConvert(year, {
          numberOnly: true,
          locale
        })}
      </div>
      {[0, 1, 2, 3, 4, 5].map((row, rowIndex) => {
        if (
          days[rowIndex * 7]?.value !== -1 || // first cell of row
          days[rowIndex * 7 + 6]?.value !== -1 // last cell of row
        ) {
          return (
            <div
              className={`${isFlat ? styles["row-flat"] : styles["row"]} ${
                !isFlat && view === "mobile"
                  ? ""
                  : !isFlat && view !== "mobile"
                    ? styles["row__default"]
                    : ""
              }`}
              key={row}
            >
              {[0, 1, 2, 3, 4, 5, 6].map((cell, cellIndex) => {
                const index = rowIndex * 7 + cellIndex;
                const cellText =
                  days[index]?.value === -1 ? "" : days[index]?.value;
                const cellContent = days[index]?.content;
                const state = days[index]?.state;
                const isToday = days[index]?.isToday;

                return (
                  <div
                    className={`${styles["cell"]} ${
                      isFlat
                        ? styles["cell-flat"]
                        : view === "mobile"
                          ? styles["cell-popover__mobile"]
                          : styles["cell-popover"]
                    } ${isToday ? styles["today"] : ""} ${
                      state ? styles[state] : ""
                    }`}
                    key={cell}
                    onClick={days[index]?.onClick}
                  >
                    <div
                      className={`${styles["cell-inside"]} ${
                        isFlat
                          ? styles["cell-inside-flat"]
                          : view === "mobile"
                            ? styles["cell-inside-popover__mobile"]
                            : styles["cell-inside-popover__default"]
                      }`}
                    >
                      {numberConvert(cellText, {
                        numberOnly: true,
                        locale
                      })}
                      {cellContent ? (
                        <span className={styles["cell-inside-data"]}>
                          {cellContent}
                        </span>
                      ) : null}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

const TableMemoized = memo(Table);
export { TableMemoized as Table };
