import { FC } from "react";
import styles from "./features.module.scss";
import { FeaturesPropertiesType } from "./features.types";

export const Features: FC<FeaturesPropertiesType> = (properties) => {
  const { list, title } = properties;

  if (list && list.length > 0) {
    return (
      <section className={styles["features"]}>
        <article className={styles["container"]}>
          <header className={styles["title-wrapper"]}>
            <h1 className={styles["title"]}>{title}</h1>
          </header>
          {/* <section className={styles["list"]}>
            {list.map((item, index) => (
              <FeaturesItem
                Icon={item.Icon}
                description={item.description}
                key={item.title}
                title={item.title}
              />
            ))}
          </section> */}
        </article>
      </section>
    );
  }

  return null;
};
