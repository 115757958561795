import { DatepickerSelectedDateType } from "@website/ui-old";
import { useCallback, useEffect, useState } from "react";

type TourErrorsType = {
  originError: string;
  destinationError: string;
  dateError: string;
};

export const useValidation = ({
  originItem,
  destinationItem,
  date
}: {
  originItem: any;
  destinationItem: any;
  date: DatepickerSelectedDateType;
}) => {
  const [errors, setErorrs] = useState<TourErrorsType>({
    originError: "",
    destinationError: "",
    dateError: ""
  });

  useEffect(() => {
    setErorrs((previousError) => ({
      ...previousError,
      originError: ""
    }));
  }, [originItem]);

  useEffect(() => {
    setErorrs((previousError) => ({
      ...previousError,
      destinationError: ""
    }));
  }, [destinationItem]);

  useEffect(() => {
    setErorrs((previousError) => ({
      ...previousError,
      dateError: ""
    }));
  }, [date]);

  const submitValidationCheck = useCallback(() => {
    let hasError = false;
    if (!originItem) {
      setErorrs((previousError) => ({
        ...previousError,
        originError: "لطفا مبدأ را انتخاب نمایید"
      }));
      hasError = true;
    }

    if (!destinationItem) {
      setErorrs((previousError) => ({
        ...previousError,
        destinationError: "لطفا مقصد را انتخاب نمایید"
      }));
      hasError = true;
    }

    if (originItem?.iata_code === destinationItem?.iata_code) {
      setErorrs((previousError) => ({
        ...previousError,
        destinationError: "مبدأ و مقصد نمی توانند یکسان باشد"
      }));
      hasError = true;
    }

    if (!date || !date?.[0] || !date?.[1]) {
      setErorrs((previousError) => ({
        ...previousError,
        dateError: "لطفا تاریخ ورود و خروج خود را انتخاب نمایید"
      }));
      hasError = true;
    }

    if (hasError) {
      return false;
    }
    return true;
  }, [originItem, destinationItem, date]);

  return {
    errors,
    submitValidationCheck
  };
};
