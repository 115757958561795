import { GetImagePropertiesTypes } from "./get-image.types";

export const getImage = ({
  width,
  height,
  src,
  baseUrl
}: GetImagePropertiesTypes) =>
  src
    ? src?.startsWith("http")
      ? src
      : width && height
        ? `${process.env.NEXT_PUBLIC_BASE_URL_IMAGES}/images/${width}x${height}${src}`
        : baseUrl
          ? `${baseUrl}/${src}`
          : `${process.env.NEXT_PUBLIC_BASE_URL_IMAGES}/images${src}`
    : "";
