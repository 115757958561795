import { Close } from "@website/icons";
import { deepCompare } from "@website/utils";
import { FC, memo, PropsWithChildren } from "react";
import { Transition } from "react-transition-group";
import { useModalOld } from "./modal-old.hook";
import styles from "./modal-old.module.scss";
import { ModalOldPropertiesType } from "./modal-old.types";

const ModalOld: FC<PropsWithChildren<ModalOldPropertiesType>> = (
  properties
) => {
  const { animate, children, title, onClose, showCloseButton, mobileHeight } =
    properties;
  const {
    modalOpen,
    animateOpen,
    modalContainerProps,
    transitionProps,
    modalProps,
    containerHeight
  } = useModalOld(properties);

  if (modalOpen) {
    return (
      <Transition {...transitionProps} in={animateOpen}>
        {(state) => (
          <div
            className={`${styles["container"]} ${
              state === "entering" || state === "entered"
                ? styles["container-in"]
                : styles["container-out"]
            }`}
            {...modalContainerProps}
            style={{ height: `${containerHeight}px` }}
          >
            <div
              {...modalProps}
              className={`${styles["modal"]} ${
                styles[mobileHeight || "full"]
              } ${
                animate === "bottom"
                  ? `${styles["bottom"]} ${
                      state === "entered"
                        ? styles["modal-in"]
                        : styles["modal-out"]
                    }`
                  : ""
              }`}
              tabIndex={0}
            >
              {showCloseButton || title ? (
                <div className={styles["header"]}>
                  {title ? <h6 className={styles["title"]}>{title}</h6> : ""}
                  {showCloseButton ? (
                    <Close className={styles["close"]} onClick={onClose} />
                  ) : (
                    ""
                  )}
                </div>
              ) : null}
              {children}
            </div>
          </div>
        )}
      </Transition>
    );
  }
  return null;
};

const ModalOldMemoized = memo(ModalOld, deepCompare);
export { ModalOldMemoized as ModalOld };
