import { useTranslation } from "@website/locale";
import { MiniChips, MiniChipsColorType } from "@website/ui";
import { deepCompare, numberConvert } from "@website/utils";
import { useRouter } from "next/router";
import { memo } from "react";
import { ReserveRateHeaderPropertiesTypes } from "./reserve-rate-header.types";
import { useGetColorAndTextRate } from "./reserve-rate.hooks";

const limitRateCount = 10_000_000;
const Component = ({
  properties
}: {
  properties: ReserveRateHeaderPropertiesTypes;
}) => {
  const { rate, rateCount, size = "large" } = properties;
  const router = useRouter();
  const rateInfo = useGetColorAndTextRate(rate);
  const { t } = useTranslation();

  return (
    <div
      className={`flex flex-row items-center gap-4 ${
        size === "large" ? "xl:min-w-[175px] xl:justify-between" : ""
      }`}
    >
      <div className="text-headline-3 md:text-headline-2">
        {Number(rate) > 0 &&
          Number(rate) <= 5 &&
          numberConvert(rate, { locale: router.locale })}
      </div>
      <div className="flex flex-col items-start gap-2">
        {Number(rate) > 3.5 && (
          <MiniChips
            color={rateInfo?.color as MiniChipsColorType}
            size="medium"
            text={rateInfo?.text ?? ""}
            variant="accent"
          />
        )}
        <div className="text-caption md:text-body-2">
          {t("rate.rate_count", {
            count:
              Number(rateCount) < limitRateCount
                ? numberConvert(rateCount, { locale: router.locale })
                : numberConvert(limitRateCount, { locale: router.locale })
          })}
        </div>
      </div>
    </div>
  );
};
export const ReserveRateHeader = memo(Component, deepCompare);
