import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { useCollapsibleTextItem } from "./collapsible-text.hook";
import styles from "./collapsible-text.module.scss";
import { CollapsibleTextPropertiesType } from "./collapsible-text.types";

const CollapsibleText: FC<CollapsibleTextPropertiesType> = (properties) => {
  const {
    regionProperties,
    descriptionProperties,
    collapsed,
    buttonProperties,
    collapsible
  } = useCollapsibleTextItem(properties);
  const {
    className,
    buttonClassName,
    direction,
    title,
    moreText,
    lessText,
    titleStyle,
    moreTextIcon,
    lessTextIcon,
    withMedia
  } = properties;

  return (
    <div
      className={`${styles["item"]} ${withMedia ? styles["with-media"] : ""}`}
    >
      {title && <h2 className={`${titleStyle ?? styles["title"]}`}>{title}</h2>}
      <div {...regionProperties} className={styles["content"]} dir={direction}>
        <div
          {...descriptionProperties}
          className={`${styles["description"]} ${className}`}
        />
      </div>
      {collapsible && (
        <button
          {...buttonProperties}
          className={`${styles["button"]} ${buttonClassName}`}
        >
          {collapsed ? (
            <>
              <span className={styles["button__txt"]}>
                {moreText || "نمایش بیشتر"}
              </span>
              <span>{moreTextIcon}</span>
            </>
          ) : (
            <>
              <span className={styles["button__txt"]}>
                {lessText || "نمایش کمتر"}
              </span>
              <span>{lessTextIcon}</span>
            </>
          )}
        </button>
      )}
    </div>
  );
};

const CollapsibleTextMemoized = memo(CollapsibleText, deepCompare);
export { CollapsibleTextMemoized as CollapsibleText };
