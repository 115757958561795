import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { Radio } from "../radio";
import { SizeType } from "../types";
import { useRadioGroup } from "./radio-group.hook";
import { RadioGroupPropertiesType } from "./radio-group.types";

const RadioGroup: FC<RadioGroupPropertiesType> = (properties) => {
  const { rootProps, radioProps } = useRadioGroup(properties);
  const { className, items, size, title, vertical } = properties;

  return (
    <div
      {...rootProps}
      className={`flex ${vertical ? "flex-col" : "flex-row gap-5"} ${
        className ?? ""
      }`}
    >
      {title ? (
        <p
          className={`${
            size === SizeType.lg
              ? "text-xl"
              : size === SizeType.sm
                ? "text-base"
                : "text-lg"
          }`}
        >
          {title}
        </p>
      ) : null}
      {items.map((item, index) => (
        <Radio key={item.label as string} {...radioProps[index]} />
      ))}
    </div>
  );
};

const RadioGroupMemoized = memo(RadioGroup, deepCompare);
export { RadioGroupMemoized as RadioGroup };
