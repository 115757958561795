import { useLocalStorage } from "@website/hooks";
import { History } from "@website/icons";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  VISA_DESTINATION_HISTORY_LOCAL_STORAGE_KEY,
  VISA_SEARCH_HISTORY_LOCAL_STORAGE_KEY
} from "../search-visa.constant";

export const useHistory = (onChangeDestination: (item: any) => void) => {
  const [history, setHistory] = useState<Array<any>>([]);
  const { addToLocalStorage, getFromLocalStorage } = useLocalStorage();

  useEffect(() => {
    const historyString = getFromLocalStorage(
      VISA_DESTINATION_HISTORY_LOCAL_STORAGE_KEY
    );
    const history = historyString ? JSON.parse(historyString) : [];
    setHistory(history);
  }, [getFromLocalStorage]);

  const addToDestinationHistory = useCallback(
    (destination) => {
      const historyString = getFromLocalStorage(
        VISA_DESTINATION_HISTORY_LOCAL_STORAGE_KEY
      );
      const history = historyString ? JSON.parse(historyString) : [];

      let newHistory = history.filter((item) => item.name !== destination.name);

      newHistory = [destination, ...newHistory].slice(0, 2);

      addToLocalStorage(
        VISA_DESTINATION_HISTORY_LOCAL_STORAGE_KEY,
        JSON.stringify(newHistory)
      );
      setHistory(newHistory);
    },
    [addToLocalStorage, getFromLocalStorage]
  );

  const addToSearchHistory = useCallback(
    (destination: any) => {
      const historyString = getFromLocalStorage(
        VISA_SEARCH_HISTORY_LOCAL_STORAGE_KEY
      );
      const history = historyString ? JSON.parse(historyString) : [];

      let newHistory = history.filter(
        (item) => !(item.destination.name === destination.name)
      );
      const newHistoryItem = {
        destination
      };
      newHistory = [newHistoryItem, ...newHistory].slice(0, 4);

      addToLocalStorage(
        VISA_SEARCH_HISTORY_LOCAL_STORAGE_KEY,
        JSON.stringify(newHistory)
      );
    },
    [addToLocalStorage, getFromLocalStorage]
  );

  const addToHistory = useCallback(
    (destination: any) => {
      addToDestinationHistory(destination);
      addToSearchHistory(destination);
    },
    [addToDestinationHistory, addToSearchHistory]
  );

  const onSelectDestinationHistory = useCallback(
    (name: string) => {
      if (history) {
        let historyItem = null;
        for (const element of history) {
          const checkKey = `${element.name}`;
          if (`${name}` === checkKey) {
            historyItem = element;
            break;
          }
        }

        if (historyItem) {
          onChangeDestination(historyItem);
        }
      }
    },
    [history, onChangeDestination]
  );

  const destinationHistoryList = useMemo(
    () =>
      history.map((destination) => ({
        id: `${destination?.name}`,
        title: destination?.faName,
        StartIcon: <History />
      })),
    [history]
  );
  return {
    destinationHistoryList,
    onSelectDestinationHistory,
    addToHistory
  };
};
