import { useTranslation } from "@website/locale";
import { useCallback, useMemo, useState } from "react";

const SalonType = {
  normal: "NORMAL",
  female: "FEMALE",
  male: "MALE"
};
export const useSalonType = () => {
  const { t } = useTranslation();
  const [salonType, setSalonType] = useState<string>(SalonType.normal);
  const salonTypeList = useMemo(
    () => [
      {
        title: t("homepage.train.main_search.normal"),
        value: SalonType.normal
      },
      {
        title: t("homepage.train.main_search.female"),
        value: SalonType.female
      },
      { title: t("homepage.train.main_search.male"), value: SalonType.male }
    ],
    [t]
  );

  const onChangeSalonType = useCallback((value: string) => {
    setSalonType(value);
  }, []);

  return { salonType, salonTypeList, onChangeSalonType };
};
