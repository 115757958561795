import { memo, SVGProps } from "react";

const SvgFlightRounded = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    height="1em"
    viewBox="0 0 25 25"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <circle
      cx={12.547}
      cy={12.472}
      r={10}
      transform="rotate(.172 12.547 12.472)"
    />
    <path d="M12.52 21.472a9 9 0 1 0 .054-18 9 9 0 0 0-.054 18Z" />
    <path
      d="M17.673 7.378a1.045 1.045 0 0 0-1.493.01L14.4 9.233a1.016 1.016 0 0 1-1.054.258l-4.66-1.558a.243.243 0 0 0-.248.058L7.404 9.02l3.856 2.39c.334.207.392.67.119.953l-1.672 1.731a.243.243 0 0 1-.215.07l-1.215-.202a.243.243 0 0 0-.211.067l-.942.936 2.04.722c.07.025.125.08.149.15l.71 2.045.942-.937a.243.243 0 0 0 .068-.21l-.196-1.217a.243.243 0 0 1 .072-.214l1.742-1.661a.617.617 0 0 1 .951.124l2.367 3.87 1.034-1.027a.242.242 0 0 0 .06-.248l-1.53-4.67a1.016 1.016 0 0 1 .265-1.051l1.855-1.77a1.045 1.045 0 0 0 .02-1.492Z"
      fill="#fff"
    />
  </svg>
);

const Memo = memo(SvgFlightRounded);
export default Memo;
