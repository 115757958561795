import { Checkbox } from "@website/ui";
import { toString } from "lodash";
import { useRouter } from "next/router";
import { Fragment, useCallback, useEffect, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FilterCollapse } from "../collapse";
import { CheckboxTypes } from "../filter.types";
import styles from "../index.module.scss";

interface CheckboxFilterProperties extends CheckboxTypes {
  name: string;
}

export const CheckboxFilter = (properties: CheckboxFilterProperties) => {
  const {
    name,
    title,
    collapsible,
    defaultValue,
    type,
    options = []
  } = properties;
  const router = useRouter();
  const { control, setValue } = useFormContext();

  const parsedInitialValues = useMemo(() => {
    const query = router.query[name];

    const stringifyQuery = Array.isArray(query) ? query.join(",") : query;

    const data = stringifyQuery || defaultValue;

    return Object.fromEntries(data?.split(",").map((q) => [q, true]) ?? []);
  }, [defaultValue, name, router.query]);

  useEffect(() => {
    setValue(name, { type, value: parsedInitialValues ?? {} });
  }, [name, setValue, parsedInitialValues, type]);

  const checkedByQuery = useCallback(
    (optionName: string) => {
      if (!router.query[name]) return false;

      return toString(router.query[name]).split(",").includes(optionName);
    },
    [name, router.query]
  );

  return (
    <Fragment>
      <span className={styles["filter-header"]}>{title}</span>
      <FilterCollapse active={collapsible}>
        {options.map((option) => (
          <div
            className={styles["filter-checkbox-container"]}
            key={name + option.name}
          >
            <Controller
              control={control}
              name={`${name}.value.${option.name}`}
              render={({ field: { onChange, value, ...field } }) => (
                <Checkbox
                  checked={checkedByQuery(option.name) ?? value}
                  className={styles["filter-checkbox"]}
                  color="secondary"
                  label={option.label}
                  onChange={onChange}
                  {...field}
                />
              )}
            />
            {option.icon}
          </div>
        ))}
      </FilterCollapse>
    </Fragment>
  );
};
