import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { LazyImage } from "../lazy-image";
import styles from "./avatar.module.scss";
import { AvatarPropertiesType } from "./avatar.types";

const Avatar: FC<AvatarPropertiesType> = (properties) => {
  const {
    variant = "rounded",
    color = "default",
    size = "medium",
    type = "text",
    Icon,
    text,
    src,
    alt,
    className
  } = properties;

  return (
    <div
      className={`${styles["avatar"]} ${styles[variant]} ${styles[color]} ${
        styles[size]
      } ${styles[type]} ${className ?? ""} `}
    >
      {type === "text" ? (
        <span>{text || ""}</span>
      ) : type === "icon" && Icon ? (
        Icon
      ) : type === "image" && src ? (
        <LazyImage alt={alt} src={src} />
      ) : null}
    </div>
  );
};

const AvatarMemoized = memo(Avatar, deepCompare);
export { AvatarMemoized as Avatar };
