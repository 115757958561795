import { useCallback, useState } from "react";
import {
  DatepickerCalendarLocaleType,
  DatepickerPropertiesType
} from "../datepicker.types";
import { convertDate } from "../datepicker.utils";

export const useCalendarLocale = (properties: DatepickerPropertiesType) => {
  const { value, onChange, initialCalendarLocale } = properties;

  const [calendarLocale, setCalendarLocale] =
    useState<DatepickerCalendarLocaleType>(initialCalendarLocale || "persian");

  const changeCalendarLocaleToGregorian = useCallback(() => {
    const convertedDate = value.map((item) => convertDate(item, "gregorian"));
    onChange(convertedDate, "gregorian");
    setCalendarLocale("gregorian");
  }, [onChange, value]);

  const changeCalendarLocaleToPersian = useCallback(() => {
    const convertedDate = value.map((item) => convertDate(item, "persian"));
    onChange(convertedDate, "persian");
    setCalendarLocale("persian");
  }, [onChange, value]);

  const changeCalendarLocaleToHijri = useCallback(() => {
    const convertedDate = value.map((item) => convertDate(item, "hijri"));
    onChange(convertedDate, "hijri");
    setCalendarLocale("hijri");
  }, [onChange, value]);

  return {
    calendarLocale,
    changeCalendarLocaleToGregorian,
    changeCalendarLocaleToPersian,
    changeCalendarLocaleToHijri
  };
};
