import { useAddRemoveQuery } from "@website/hooks";
import { useRouter } from "next/router";
import { useCallback } from "react";
import { PopoverFullAnimateType, PopoverFullPositionType } from "../..";

export const usePopover = (queryKey?: string, onFocus?: () => void) => {
  const router = useRouter();
  const key = queryKey || "SEARCH_DATEPICKER";
  const { [key]: isKey } = router.query;
  const { addQuery, removeQuery } = useAddRemoveQuery(key);

  const onClosePopover = useCallback(() => {
    removeQuery();
  }, [removeQuery]);

  const onOpenPopover = useCallback(() => {
    addQuery("open");
    onFocus?.();
  }, [addQuery, onFocus]);

  return {
    open: Boolean(isKey),
    onOpenPopover,
    onClosePopover,
    popoverProperties: {
      animate: "bottom" as PopoverFullAnimateType,
      open: Boolean(isKey),
      onClose: onClosePopover,
      position: "end" as PopoverFullPositionType,
      gapHorizontal: "-24px",
      gapVertical: "-16px"
    }
  };
};
