import { MaterialIcon, MaterialNameType } from "@website/icons";
import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { AlertPropertiesType } from "./alert.types";
import { alertConfig, iconStyleConfig, iconTypeConfig } from "./config";

const Alert: FC<AlertPropertiesType> = (properties) => {
  const {
    className,
    title,
    severity = "default",
    type = "contained",
    //TODO: remove size from alert also snackbar
    size = "one-line",
    Action,
    isCloseable = true,
    startIcon,
    showIcon = true,
    onClose
  } = properties;

  return (
    <div
      className={`flex max-h-[92px] w-full gap-1 rounded-lg pl-2 pr-4 ${
        alertConfig[type].style
      } ${alertConfig[type].severity[severity]} ${className ?? ""} ${size}`}
      role="alert"
    >
      <div
        className={`flex flex-1 items-center justify-start ${
          showIcon ? "gap-2" : ""
        } py-4`}
      >
        {showIcon && (
          <div
            className={`flex items-center justify-center text-2xl leading-6 ${
              type === "contained"
                ? iconStyleConfig["white"]
                : iconStyleConfig[severity]
            }`}
          >
            {startIcon || (
              <MaterialIcon
                name={iconTypeConfig[severity] as MaterialNameType}
                variant="outlined"
              />
            )}
          </div>
        )}

        <div className="text-body-2 line-clamp-3">{title}</div>
      </div>
      <div className="flex items-center justify-end gap-2 p-2">
        {Action}
        {isCloseable && (
          <button onClick={onClose} tabIndex={0}>
            <MaterialIcon className="text-2xl leading-6" name="close" />
          </button>
        )}
      </div>
    </div>
  );
};

const AlertMemoized = memo(Alert, deepCompare);
export { AlertMemoized as Alert };
