import UAParser from "ua-parser-js";
import { TDeviceInfo } from "./device-info.types";

let _deviceInformation: TDeviceInfo;

export function getDeviceInfo(): TDeviceInfo {
  if (_deviceInformation) return _deviceInformation;

  const parser = new UAParser();
  _deviceInformation = parser.getResult();

  if (!_deviceInformation.device.type) {
    _deviceInformation.device.type = "desktop";
  }

  return _deviceInformation;
}
