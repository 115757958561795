import { AxiosResponse } from "axios";
import { AxiosNoAuth } from "../../../utils";
import { ListingParametersType, ListingResponseType } from "./listing.types";

export const ListingUrl = `${process.env.NEXT_PUBLIC_BASE_URL_FP}/bus-listing-go/v3/endpoints`;

export const ListingService = (
  parameters: ListingParametersType
): Promise<ListingResponseType> =>
  AxiosNoAuth.get(ListingUrl, {
    params: parameters
  }).then(({ data }: AxiosResponse<ListingResponseType>) => data);
