import { useTranslation } from "@website/locale";
import { useCallback, useState } from "react";

export const usePassengerCount = () => {
  const { t } = useTranslation();
  const [temporaryPassengerCount, setTemporaryPassengerCount] = useState<{
    adult: number;
    child: number;
    room: number;
  }>({
    adult: 1,
    child: 0,
    room: 1
  });
  const [passengerCount, setPassengerCount] = useState(temporaryPassengerCount);

  const { adult, child, room } = passengerCount;

  const roomSpace = 15;
  const total = adult + child;
  const remain = roomSpace * room - total;
  const remainNotAdult = Math.min(remain, adult * 12 - child);

  const adultMax = adult + remain;
  const adultMin = Math.max(Math.ceil(child / 12), 1);
  const childMax = child + remainNotAdult;
  const childMin = 0;
  const roomMax = 15;
  const roomMin = 1;

  const onSubmitPassengerCount = useCallback(() => {
    setPassengerCount((state) => {
      setTemporaryPassengerCount(state);
      return state;
    });
  }, []);

  const onChangeAdult = useCallback((value: number) => {
    setPassengerCount((state) => ({
      ...state,
      adult: value
    }));
  }, []);

  const onChangeChild = useCallback((value: number) => {
    setPassengerCount((state) => ({
      ...state,
      child: value
    }));
  }, []);

  const onChangeRoom = useCallback((value: number) => {
    setPassengerCount((state) => ({
      ...state,
      room: value
    }));
  }, []);

  const onClosePassengerButton = useCallback(() => {
    setTemporaryPassengerCount((state) => {
      setPassengerCount(state);
      return state;
    });
  }, []);

  const onClickBackDropHandler = useCallback(() => {
    setPassengerCount((state) => {
      setTemporaryPassengerCount(state);
      return state;
    });
  }, []);

  const list = [
    {
      increaseDisabled: adult >= adultMax,
      decreaseDisabled: adult <= adultMin,
      title: t("homepage.hotel.main_search.number_of_adult"),
      subtitle: t("homepage.hotel.main_search.greather_than_n_years", {
        age: 12
      }),
      value: adult,
      onChange: onChangeAdult
    },
    {
      increaseDisabled: child >= childMax,
      decreaseDisabled: child <= childMin,
      title: t("homepage.hotel.main_search.number_of_child"),
      subtitle: t("homepage.hotel.main_search.between_m_and_n_years", {
        age_one: 2,
        age_two: 12
      }),
      value: child,
      onChange: onChangeChild
    },
    {
      increaseDisabled: room >= roomMax,
      decreaseDisabled: room <= roomMin,
      title: t("homepage.hotel.main_search.number_of_room"),
      value: room,
      onChange: onChangeRoom
    }
  ];

  return {
    passengerCountTitle: t(
      "homepage.hotel.main_search.number_of_passenger_and_room"
    ),
    passengerCountResult: t(
      "homepage.hotel.main_search.x_passenger_and_y_room",
      {
        passenger_count: passengerCount.adult + passengerCount.child,
        room_count: passengerCount.room
      }
    ),
    passengerCountList: list,
    passengerCount,
    onSubmitPassengerCount,
    onClosePassengerButton,
    onClickBackDropHandler
  };
};
