import { useAddRemoveQuery } from "@website/hooks";
import { useTranslation } from "@website/locale";
import { useRouter } from "next/router";
import { useCallback } from "react";

export const useTrackModal = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const key = "PURCHASE_HOTEL_MOBILE";
  const { [key]: isKey } = router.query;
  const { addQuery, removeQuery } = useAddRemoveQuery(key);

  const onTrackModalOpen = useCallback(() => {
    addQuery("open");
  }, [addQuery]);

  const onTrackModalClose = useCallback(() => {
    removeQuery();
  }, [removeQuery]);

  return {
    trackModalIsOpen: Boolean(isKey),
    onTrackModalOpen,
    onTrackModalClose,
    trackModalProps: {
      title: t("header.track_modal.track_order"),
      open: Boolean(isKey),
      onClose: onTrackModalClose,
      showCloseButton: true,
      mobileHeight: "auto" as const,
      animate: "bottom" as const
    }
  };
};
