import { useCallback, useEffect, useRef } from "react";
import { RollPropertiesType } from "./roll.types";

export const useRoll = (properties: RollPropertiesType) => {
  const { value, onChange } = properties;
  const reference = useRef<HTMLDivElement>(null);
  const timeoutId = useRef<NodeJS.Timeout | null>(null);
  const isTouching = useRef<boolean>(false);

  useEffect(() => {
    const currentReference = reference?.current;
    function goToIndex() {
      if (currentReference && !isTouching.current) {
        const scrollTop = currentReference.scrollTop || 0;
        const index = Math.round(scrollTop / 48);
        currentReference.scrollTo(0, index * 48);
        onChange(index);
      }
    }

    function onScroll() {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
      timeoutId.current = setTimeout(goToIndex, 100);
    }

    function onTouchStart() {
      isTouching.current = true;
    }

    function onTouchEnd() {
      isTouching.current = false;
      goToIndex();
    }

    if (currentReference) {
      currentReference.addEventListener("scroll", onScroll);
      currentReference.addEventListener("touchstart", onTouchStart);
      currentReference.addEventListener("touchend", onTouchEnd);
    }

    return () => {
      if (currentReference) {
        currentReference.removeEventListener("scroll", onScroll);
        currentReference.removeEventListener("touchstart", onTouchStart);
        currentReference.removeEventListener("touchend", onTouchEnd);
      }
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, [onChange]);

  useEffect(() => {
    if (reference?.current) {
      reference.current.scrollTo(0, value * 48);
    }
  }, [value]);

  const onUpButtonClick = useCallback(() => {
    if (reference?.current) {
      reference.current.scrollTo(0, (value - 1) * 48);
    }
  }, [value]);

  const onDownButtonClick = useCallback(() => {
    if (reference?.current) {
      reference.current.scrollTo(0, (value + 1) * 48);
    }
  }, [value]);

  return {
    upButtonProperties: {
      onClick: onUpButtonClick
    },
    downButtonProperties: {
      onClick: onDownButtonClick
    },
    scrollProperties: {
      ref: reference
    }
  };
};
