import { MaterialIcon } from "@website/icons";
import { Button, Loading, TextField } from "@website/ui-old";
import { FC } from "react";
import { Header } from "..";
import styles from "./phone-number-card.module.scss";
import { PhoneNumberCardPropertiesType } from "./phone-number-card.types";
import { usePhoneNumberCard } from "./use-phone-number-card";

export const PhoneNumberCard: FC<PhoneNumberCardPropertiesType> = (
  properties
) => {
  const { onClose } = properties;
  const {
    phoneNumber,
    onChangePhoneNumber,
    onSubmitPhoneNumber,
    isLoading,
    error
  } = usePhoneNumberCard(properties);

  return (
    <div className={styles["container"]}>
      <Header onClose={onClose} title="ورود یا ثبت نام" />
      <div className={styles["title"]}>لطفا شماره موبایل خود را وارد کنید.</div>
      <div className={styles["input"]}>
        <TextField
          StartIcon={<MaterialIcon name="phone_iphone" />}
          error={Boolean(error)}
          fullWidth
          helperText={error}
          onChange={onChangePhoneNumber}
          placeholder="شماره موبایل"
          value={phoneNumber}
        />
      </div>
      <div className={styles["info"]}>
        <span className={styles["info-icon"]}>
          <MaterialIcon name="info" variant="outlined" />
        </span>
        ورود و استفاده از اسنپ‌تریپ به معنای پذیرش
        <a
          className={styles["info-rules"]}
          href={`${process.env.NEXT_PUBLIC_BASE_URL_B2C}/policy`}
        >
          قوانین و مقررات
        </a>
        آن می باشد.
      </div>
      <div className={styles["footer"]}>
        <Button
          color="secondary"
          fullWidth
          onClick={isLoading ? undefined : onSubmitPhoneNumber}
          size="large"
          variant="contained"
        >
          {isLoading ? <Loading color="white" size="lg" /> : "تایید و ادامه"}
        </Button>
      </div>
    </div>
  );
};
