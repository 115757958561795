import { useViewPort } from "@website/hooks";
import { SwapHorizental } from "@website/icons";
import { FC, memo } from "react";
import {
  ListItem,
  Loading,
  PopoverFull,
  SearchField,
  SizeType,
  TextField,
  TopAppBar
} from "..";
import styles from "./auto-complete.module.scss";
import { AutoCompletePropertiesType } from "./auto-complete.types";
import { useEnd, useStart, useSwap } from "./hooks";

const AutoComplete: FC<AutoCompletePropertiesType> = (properties) => {
  const { className, parts, error, fullWidth, onSwap } = properties;
  const { isMobile } = useViewPort();
  const {
    startTextFieldProperties,
    startItemsProperties,
    startPopoverProperties,
    startPopoverSearchFieldProperties,
    startHistoryItemsProperties,
    startTopAppBarProperties
  } = useStart(properties);
  const {
    endTextFieldProperties,
    endItemsProperties,
    endPopoverProperties,
    endPopoverSearchFieldProperties,
    endHistoryItemsProperties,
    endTopAppBarProperties
  } = useEnd(properties);
  const { swapButtonProperties } = useSwap(properties);

  return (
    <div
      className={`${styles["auto-complete"]} ${
        fullWidth ? styles["full-width"] : ""
      } ${className ?? ""}`}
    >
      <div className={styles["box"]}>
        <div className={styles["inputs-container"]}>
          <div className={styles["start"]}>
            <TextField
              {...startTextFieldProperties}
              className={styles["input"]}
              fullWidth
              noBorder={
                parts[1] ? (isMobile ? ["bottom"] : ["end"]) : undefined
              }
            />
          </div>
          {parts[1] ? (
            <>
              <div className={styles["middle"]} />
              {onSwap ? (
                <button
                  {...swapButtonProperties}
                  aria-label="swap selected items"
                  className={styles["swap-button"]}
                >
                  <SwapHorizental className={styles["swap-button-icon"]} />
                </button>
              ) : null}
              <div className={styles["end"]}>
                <TextField
                  {...endTextFieldProperties}
                  className={styles["input"]}
                  fullWidth
                  noBorder={isMobile ? ["top"] : ["start"]}
                />
              </div>
            </>
          ) : null}
        </div>
        {error ? <div className={styles["error"]}>{error}</div> : null}
      </div>
      <PopoverFull {...startPopoverProperties}>
        <div className={styles["popover"]}>
          {isMobile ? <TopAppBar {...startTopAppBarProperties} /> : null}
          <div className={styles["popover-header"]}>
            <SearchField
              {...startPopoverSearchFieldProperties}
              color={isMobile ? "white" : "transparent"}
            />
          </div>
          <div className={styles["popover-list"]}>
            <div className={styles["popover-list-divider"]} />
            {parts[0]?.loading ? (
              <div className={styles["loading"]}>
                <Loading size={SizeType.lg} />
                <div>در حال جستجوی نتیجه مورد نظر شما</div>
              </div>
            ) : null}
            {parts[0]?.NoResult ?? null}
            {startHistoryItemsProperties &&
            startHistoryItemsProperties?.length > 0 ? (
              <>
                <div className={styles["popover-history-title"]}>
                  {parts[0]?.historyTitle}
                </div>
                {startHistoryItemsProperties.map((item) => (
                  <ListItem key={item.id} {...item} />
                ))}
                <div className={styles["popover-history-bottom"]} />
              </>
            ) : null}
            {startItemsProperties && startItemsProperties?.length > 0 ? (
              <>
                {parts[0]?.listTitle ? (
                  <div className={styles["popover-list-title"]}>
                    {parts[0]?.listTitle}
                  </div>
                ) : null}

                {startItemsProperties.map((item) => (
                  <ListItem key={item.id} {...item} />
                ))}
              </>
            ) : null}
          </div>
        </div>
      </PopoverFull>
      {parts[1] ? (
        <PopoverFull {...endPopoverProperties}>
          <div className={styles["popover"]}>
            {isMobile ? <TopAppBar {...endTopAppBarProperties} /> : null}
            <div className={styles["popover-header"]}>
              <SearchField
                {...endPopoverSearchFieldProperties}
                color={isMobile ? "white" : "transparent"}
              />
            </div>
            <div className={styles["popover-list"]}>
              <div className={styles["popover-list-divider"]} />
              {parts[1]?.loading ? (
                <div className={styles["loading"]}>
                  <Loading size={SizeType.lg} />
                  <div>در حال جستجوی نتیجه مورد نظر شما</div>
                </div>
              ) : null}
              {parts[1]?.NoResult ?? null}
              {endHistoryItemsProperties &&
              endHistoryItemsProperties?.length > 0 ? (
                <>
                  <div className={styles["popover-history-title"]}>
                    {parts[1]?.historyTitle}
                  </div>
                  {endHistoryItemsProperties.map((item) => (
                    <ListItem key={item.id} {...item} />
                  ))}
                  <div className={styles["popover-history-bottom"]} />
                </>
              ) : null}
              {endItemsProperties && endItemsProperties?.length > 0 ? (
                <>
                  {parts[1]?.listTitle ? (
                    <div className={styles["popover-list-title"]}>
                      {parts[1]?.listTitle}
                    </div>
                  ) : null}
                  {endItemsProperties.map((item) => (
                    <ListItem key={item.id} {...item} />
                  ))}
                </>
              ) : null}
            </div>
          </div>
        </PopoverFull>
      ) : null}
    </div>
  );
};

const AutoCompleteMemoized = memo(AutoComplete);
export { AutoCompleteMemoized as AutoComplete };
