import { useAddRemoveQuery } from "@website/hooks";
import { ArrowDropDown } from "@website/icons";
import { useTranslation } from "@website/locale";
import { CounterButton } from "@website/ui-old";
import { pick } from "lodash";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import {
  PassengerRoomCountTourItemsType,
  PassengerRoomCountTourPropertiesType
} from "./passenger-room-count-tour.types";

export const usePassengerRoomCountTour = (
  properties: PassengerRoomCountTourPropertiesType
) => {
  const {
    onSubmit,
    list,
    title,
    result,
    onChange,
    onClosePassengerButton,
    onClickBackDropHandler,
    onClick
  } = properties;
  const { t, locale } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const router = useRouter();
  const [listItems, setListItems] = useState<
    Array<PassengerRoomCountTourItemsType> | []
  >([]);
  useEffect(() => {
    setListItems(list);
  }, [list]);

  const key = "SEARCH_INT_HOTEL_PASSENGER_COUNT";
  const { addQuery, removeQuery } = useAddRemoveQuery(key);

  const onClickChips = useCallback(() => {
    addQuery("open");
    onClick?.();
  }, [addQuery, onClick]);

  const onClosePopover = useCallback(() => {
    removeQuery();
  }, [removeQuery]);

  const onClickSubmit = useCallback(() => {
    onSubmit(onClosePopover);
  }, [onClosePopover, onSubmit]);

  const onCloseButton = useCallback(() => {
    onClosePopover();
    onClosePassengerButton && onClosePassengerButton();
  }, [onClosePassengerButton, onClosePopover]);

  const onClickBackDrop = useCallback(() => {
    onClosePopover();
    onClickBackDropHandler && onClickBackDropHandler();
  }, [onClickBackDropHandler, onClosePopover]);

  useEffect(() => {
    const { [key]: q } = router.query;
    if (q) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [router]);

  return {
    submitButtonProps: {
      onClick: onClickSubmit,
      color: "secondary" as const,
      size: "large" as const,
      fullWidth: true
    },
    chipsProps: {
      variant: "outline" as const,
      EndIcon: <ArrowDropDown />,
      text: result,
      selected: true,
      color: "secondary" as const,
      onClick: onClickChips
    },
    popoverProps: {
      onClose: onClosePopover,
      open,
      title: title,
      onCloseButton,
      onClickBackDrop
    },
    list: listItems.map((items, itemsIndex) => ({
      ...items,
      children: items.children.map((item, itemIndex) => ({
        title: item.title,
        subtitle: item.subtitle,
        role: "default",
        EndAction: (
          <CounterButton
            aria-label={item.title}
            color="secondary"
            // eslint-disable-next-line react/jsx-no-bind
            onChange={(value: number) => onChange(value, itemsIndex, itemIndex)}
            variant="outline"
            {...pick(item, "decreaseDisabled", "increaseDisabled")}
            locale={locale}
            value={item?.value?.length}
          />
        )
      }))
    })),
    submitText: t("action.ok"),
    onChange
  };
};
