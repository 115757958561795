import { useQuery } from "react-query";
import {
  ListingService,
  ListingUrl
} from "../../../../../services/pwa/listing";

export const useListingService = (query: string) => {
  const {
    isLoading,
    data
    // error
  } = useQuery([ListingUrl, query], () => ListingService({ query }), {
    enabled: query.length > 2
  });

  return { isLoading, data: data?.endpoints };
};
