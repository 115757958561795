import { useDebounce } from "@website/hooks";
import { FlightRounded } from "@website/icons";
import { useTranslation } from "@website/locale";
import { useCallback, useMemo, useState } from "react";
import { NoResult } from "../../../..";
import { useAirportsService, useSearchSuggestionsService } from "../services";

export const useDestination = () => {
  const { t } = useTranslation();
  const [destinationQuery, setDestinationQuery] = useState<string>("");
  const [selectedDestination, setSelectedDestination] = useState<string>("");
  const [selectedDestinationItem, setSelectedDestinationItem] = useState(null);
  const query = useDebounce(destinationQuery, 250);

  const { suggestions } = useSearchSuggestionsService();
  const { isLoading, data } = useAirportsService(query);

  const onChangeDestinationQuery = useCallback((value: string) => {
    setDestinationQuery(value);
  }, []);

  const destinationList = useMemo(
    () => (destinationQuery.length >= 2 ? data || [] : suggestions || []),
    [data, suggestions, destinationQuery]
  );

  const destinationListNormalized = useMemo(
    () =>
      destinationList.map((item) => ({
        id: item.iataCode,
        title: item.city,
        subtitle: item.name,
        StartIcon: <FlightRounded />,
        Meta: (
          <div className="text-on-surface-high-emphasis text-caption">
            {item.iataCode}
          </div>
        )
      })),
    [destinationList]
  );

  const onSelectDestination = useCallback(
    (id: string) => {
      setSelectedDestination(id);
      const destinationItem = destinationList.find(
        (destination: any) => `${destination.iataCode}` === `${id}`
      );
      setDestinationQuery("");
      setSelectedDestinationItem(destinationItem);
    },
    [destinationList]
  );

  const onChangeDestination = useCallback((item: any) => {
    setSelectedDestination(item?.iataCode || "");
    setDestinationQuery("");
    setSelectedDestinationItem(item);
  }, []);

  return {
    selectedDestinationItem,
    selectedDestinationText: selectedDestinationItem?.city || "",
    destinationQuery,
    selectedDestination,
    onChangeDestination,
    onSelectDestination,
    onChangeDestinationQuery,
    destinationList,
    destinationLoading: isLoading,
    destinationListNormalized,
    DestinationNoResult:
      destinationQuery?.length >= 2 &&
      !isLoading &&
      data &&
      data.length === 0 ? (
        <NoResult query={destinationQuery} />
      ) : undefined,
    destinationLabel: t("homepage.flights.main_search.destination")
  };
};
