import { useViewPort } from "@website/hooks";
import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { CollapsibleText } from "@website/ui";
import { deepCompare, numberConvert } from "@website/utils";
import { useRouter } from "next/router";
import { memo } from "react";
import { ReserveFacilitiesPropertiesTypes } from "./reserve-facilities.types";

const Component = ({
  hotelType,
  facilities
}: ReserveFacilitiesPropertiesTypes) => {
  const router = useRouter();
  const { isMobile } = useViewPort();
  const { t } = useTranslation();

  const Elements = facilities?.map((facility) => (
    <div className="flex flex-row items-center gap-4 py-2" key={facility.title}>
      <div>
        <MaterialIcon name="check_box" variant="outlined" />
      </div>
      <span className="text-black opacity-70">
        {numberConvert(facility.title, { locale: router.locale })}
      </span>
    </div>
  ));
  if (!facilities?.length) return null;
  return (
    <div
      className="flex md:max-w-[720px] md:flex-row md:flex-wrap xl:max-w-[1200px]"
      id="hotel_facilities"
    >
      <CollapsibleText
        ariaLabel="facilities"
        buttonClassName="border-on-surface-high-emphasis text-button h-[40px] w-full rounded-[2rem] border py-2 md:w-[154px] flex justify-center !text-black mt-2"
        className="text-subtitle-2 mt-2 grid gap-x-5 md:grid-cols-2 xl:grid-cols-3"
        content={Elements}
        itemsToShow={isMobile ? 5 : 15}
        lessText={t("global.close")}
        moreText={t("hotel.view_all_facilities")}
        title={t("hotel.hotel_facilities", { hotelType })}
        titleStyle="text-subtitle-1 md:text-headline-6"
      />
    </div>
  );
};
export const ReserveFacilities = memo(Component, deepCompare);
