import { MaterialIcon } from "@website/icons";
import { Accordion } from "@website/ui-old";
import { FC } from "react";
import styles from "./faq.module.scss";
import { FaqPropertiesType } from "./faq.types";

export const Faq: FC<FaqPropertiesType> = (properties) => {
  const { title, list } = properties;

  if (list && list.length > 0) {
    return (
      <article className={styles["container"]}>
        <div className={styles["title-wrapper"]}>
          <div className={styles["icon"]}>
            <MaterialIcon name="help_outline" />
          </div>
          <h2 className={styles["title"]}>{title}</h2>
        </div>
        <div className={styles["list"]}>
          {list.map((item, index) => (
            <Accordion
              EndIcon={<MaterialIcon name="keyboard_arrow_down" />}
              EndIconExpanded={<MaterialIcon name="keyboard_arrow_up" />}
              content={item.answer}
              initialExpanded={index <= 3}
              key={item.question}
              title={item.question}
            />
          ))}
        </div>
      </article>
    );
  }

  return null;
};
