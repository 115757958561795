import { useViewPort } from "@website/hooks";
import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { numberConvert } from "@website/utils";
import { FC, memo, useCallback } from "react";
import { BottomSheet, Button, Menu, Sheet, TextField } from "..";
import { Roll } from "./components";
import styles from "./datepicker-split.module.scss";
import { DatepickerSplitPropertiesType } from "./datepicker-split.types";
import { useBottomSheet, useDate, useList, useSheet } from "./hooks";

const DatepickerSplit: FC<DatepickerSplitPropertiesType> = (properties) => {
  const { label, fullWidth, error, id, helperText } = properties;

  const { selectedDateObject, onDayChange, onMonthChange, onYearChange } =
    useDate(properties);
  const {
    onCloseDayPicker,
    isOpenDayPicker,
    onDayChangePicker,
    onCloseMonthPicker,
    isOpenMonthPicker,
    onMonthChangePicker,
    onCloseYearPicker,
    isOpenYearPicker,
    onYearChangePicker,
    onOpenYearPicker,
    onOpenMonthPicker,
    onOpenDayPicker
  } = useSheet(onDayChange, onMonthChange, onYearChange);
  const {
    onOpenRollPicker,
    isOpenRollPicker,
    onDayChangeRoll,
    onCloseRollPicker,
    onMonthChangeRoll,
    onYearChangeRoll
  } = useBottomSheet(onDayChange, onMonthChange, onYearChange);
  const { isDesktop } = useViewPort();
  const { locale } = useTranslation();
  const { yearsList, monthsList, daysList } = useList(
    properties,
    selectedDateObject
  );

  const onCloseSheet = useCallback(() => {
    if (isOpenDayPicker) {
      onCloseDayPicker();
    }
    if (isOpenMonthPicker) {
      onCloseMonthPicker();
    }
    if (isOpenYearPicker) {
      onCloseYearPicker();
    }
  }, [
    isOpenDayPicker,
    isOpenMonthPicker,
    isOpenYearPicker,
    onCloseDayPicker,
    onCloseMonthPicker,
    onCloseYearPicker
  ]);

  return (
    <div
      className={`${styles["container"]} ${
        fullWidth ? styles["full-width"] : ""
      }`}
      id={id}
    >
      <div className={styles["fields"]}>
        <TextField
          EndIcon={<MaterialIcon name="arrow_drop_down" />}
          error={error}
          noBorder={["end"]}
          onClick={isDesktop ? onOpenDayPicker : onOpenRollPicker}
          placeholder="روز"
          readOnly
          value={numberConvert(selectedDateObject.day, {
            numberOnly: true,
            locale
          })}
        />
        <div className={styles["divider"]} />
        <TextField
          EndIcon={<MaterialIcon name="arrow_drop_down" />}
          error={error}
          noBorder={["end", "start"]}
          onClick={isDesktop ? onOpenMonthPicker : onOpenRollPicker}
          placeholder="ماه"
          readOnly
          value={numberConvert(selectedDateObject.month, {
            numberOnly: true,
            locale
          })}
        />
        <div className={styles["divider"]} />
        <TextField
          EndIcon={<MaterialIcon name="arrow_drop_down" />}
          error={error}
          noBorder={["start"]}
          onClick={isDesktop ? onOpenYearPicker : onOpenRollPicker}
          placeholder="سال"
          readOnly
          value={numberConvert(selectedDateObject.year, {
            numberOnly: true,
            locale
          })}
        />
      </div>
      {label ? (
        <label className={`${styles["label"]} ${error ? styles["error"] : ""}`}>
          {label}
        </label>
      ) : null}
      {helperText ? (
        <div
          className={`${styles["helper-text"]} ${
            error ? styles["helper-text-error"] : ""
          }`}
        >
          {helperText}
        </div>
      ) : null}
      {isDesktop ? (
        <>
          {(isOpenDayPicker || isOpenMonthPicker || isOpenYearPicker) && (
            <div
              className={styles["transparent-backdrop"]}
              onClick={onCloseSheet}
            />
          )}
          <Sheet
            fillContainerWidth
            onClose={onCloseDayPicker}
            open={isOpenDayPicker}
            xGap="8px"
            xPosition="end"
            yPosition="end"
          >
            <div className={styles["menu"]}>
              <Menu
                list={daysList}
                onChange={onDayChangePicker}
                value={`${selectedDateObject.day}`}
              />
            </div>
          </Sheet>
          <Sheet
            fillContainerWidth
            onClose={onCloseMonthPicker}
            open={isOpenMonthPicker}
            xGap="8px"
            xPosition="end"
            yPosition="end"
          >
            <div className={styles["menu"]}>
              <Menu
                list={monthsList}
                onChange={onMonthChangePicker}
                value={`${selectedDateObject.month}`}
              />
            </div>
          </Sheet>
          <Sheet
            fillContainerWidth
            onClose={onCloseYearPicker}
            open={isOpenYearPicker}
            xGap="8px"
            xPosition="end"
            yPosition="end"
          >
            <div className={styles["menu"]}>
              <Menu
                list={yearsList}
                onChange={onYearChangePicker}
                value={`${selectedDateObject.year}`}
              />
            </div>
          </Sheet>
        </>
      ) : (
        <BottomSheet onClose={onCloseRollPicker} open={isOpenRollPicker}>
          <div className={styles["bottom-sheet"]}>
            <div className={styles["header"]}>
              <div className={styles["title"]}>{label}</div>
              <Button
                EndIcon={<MaterialIcon name="close" />}
                color="neutral"
                equalDimension
                onClick={onCloseRollPicker}
                variant="text"
              />
            </div>
            <div className={styles["main"]}>
              <Roll
                list={daysList}
                onChange={onDayChangeRoll}
                value={`${selectedDateObject.day}`}
              />
              <Roll
                list={monthsList}
                onChange={onMonthChangeRoll}
                value={`${selectedDateObject.month}`}
              />
              <Roll
                list={yearsList}
                onChange={onYearChangeRoll}
                value={`${selectedDateObject.year}`}
              />
            </div>
            <div className={styles["footer"]}>
              <Button color="secondary" fullWidth onClick={onCloseRollPicker}>
                تایید
              </Button>
            </div>
          </div>
        </BottomSheet>
      )}
    </div>
  );
};

const DatepickerSplitMemoized = memo(DatepickerSplit);
export { DatepickerSplitMemoized as DatepickerSplit };
