import { TabButtonPropertiesType } from "./tab-button.types";

export const useTabButton = (properties: TabButtonPropertiesType) => {
  const {
    index,
    disabled,
    description,
    onClick,
    selected,
    href,
    title,
    datatestid
  } = properties;

  return {
    linkProperties: {
      href: href || "",
      onClick,
      "data-testid": `tab-button-${datatestid}`
    },
    buttonProperties: {
      "aria-label": `${title || ""} ${description || ""}`,
      "aria-controls": `tab-${index}`,
      "aria-selected": Boolean(selected),
      id: `tab-button-${index}`,
      role: "tab",
      onClick,
      disabled,
      "data-testid": `tab-button-${datatestid}`
    }
  };
};
