import { numberConvert } from "@website/utils";
import { FC, memo } from "react";
import { useRoll } from "./roll.hook";
import styles from "./roll.module.scss";
import { RollPropertiesType } from "./roll.types";

const Roll: FC<RollPropertiesType> = (properties) => {
  const { list } = properties;
  const { scrollProperties, selectedIndex } = useRoll(properties);

  return (
    <div className={styles["roll"]}>
      <div className={styles["line-top"]} />
      <div className={styles["line-bottom"]} />
      <div className={styles["roll-scroll"]} {...scrollProperties}>
        <div className={styles["roll-item"]} />
        {list.map((item, index) => (
          <div
            className={`${styles["roll-item"]} ${
              index === selectedIndex ? styles["active"] : ""
            }`}
          >
            {numberConvert(item.title, {
              numberOnly: false,
              locale: "fa"
            })}
          </div>
        ))}
        <div className={styles["roll-item"]} />
      </div>
    </div>
  );
};

const RollMemoized = memo(Roll);
export { RollMemoized as Roll };
