import { MouseEvent, useCallback, useEffect } from "react";
import { ModalPropertiesType } from "./modal.types";

export const useModal = (properties: ModalPropertiesType) => {
  const {
    "aria-describedby": ariaDescribedby,
    "aria-labelledby": ariaLabelledby,
    id,
    open,
    onClose,
    fullWidth,
    fullScreen
  } = properties;

  useEffect(() => {
    const checkIfPressEsc = (event: KeyboardEvent) => {
      if (open && event.key === "Escape") {
        onClose?.();
        event.stopPropagation();
      }
    };
    document.addEventListener("keydown", checkIfPressEsc);
    return () => {
      document.removeEventListener("keydown", checkIfPressEsc);
    };
  }, [onClose, open]);

  const stopPropagationOnModal = useCallback((event: MouseEvent) => {
    event.stopPropagation();
  }, []);

  return {
    modalProps: {
      role: "dialog",
      "data-full-width": fullWidth,
      "aria-labelledby": ariaLabelledby,
      "aria-describedby": ariaDescribedby,
      "aria-modal": true,
      onClick: stopPropagationOnModal,
      id
    },
    transitionProps: {
      timeout: fullScreen ? 0 : 200
    },
    modalContainerProps: {
      "data-testid": "modal-container",
      onClick: onClose
    }
  };
};
