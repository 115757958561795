import { Button } from "@website/ui-old";
import { useRouter } from "next/router";
import { FC, useCallback } from "react";
import { Header } from "..";
import approvedImage from "../../../../assets/images/approved.png";
import styles from "./password-change-successful-card.module.scss";

export const PasswordChangeSuccessfulCard: FC = () => {
  const queryString = "login_step";
  const router = useRouter();
  const onClose = useCallback(() => {
    router?.push(
      {
        pathname: router?.pathname,
        query: { ...router?.query, [queryString]: "password" }
      },
      undefined,
      { shallow: true, scroll: false }
    );
  }, [router]);

  return (
    <div className={styles["container"]}>
      <Header onClose={onClose} />
      <div className={styles["image-container"]}>
        <img
          alt="تیک سبز"
          height={approvedImage.height}
          src={approvedImage.src}
          width={approvedImage.width}
        />
      </div>
      <div className={styles["info"]}>رمز عبور شما با موفقیت تغییر کرد</div>
      <div className={styles["footer"]}>
        <Button
          color="secondary"
          fullWidth
          onClick={onClose}
          size="large"
          variant="contained"
        >
          ورود به اسنپ‌تریپ!
        </Button>
      </div>
    </div>
  );
};
