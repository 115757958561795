import { ChangeEvent, useCallback, useState } from "react";

export const usePhoneNumber = () => {
  const [phoneNumber, setPhoneNumber] = useState<string>("");

  const onPhoneNumberChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setPhoneNumber(event.target.value);
    },
    []
  );

  const onPhoneNumberClear = useCallback(() => {
    setPhoneNumber("");
  }, []);

  return {
    phoneNumber,
    onPhoneNumberChange,
    onPhoneNumberClear
  };
};
