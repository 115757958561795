import { useAddRemoveQuery } from "@website/hooks";
import { useRouter } from "next/router";
import { FC, memo, useCallback } from "react";
import styles from "./purchase-card.module.scss";
import { PurchaseCardPropertiesType } from "./purchase-card.types";

const PurchaseCard: FC<PurchaseCardPropertiesType> = (properties) => {
  const { list } = properties;
  const router = useRouter();
  const key = "PURCHASE";
  const { removeQuery } = useAddRemoveQuery(key);
  const onChangeRoute = useCallback(
    (url: string) => {
      removeQuery().finally(() => router.push(url));
    },
    [removeQuery, router]
  );
  return (
    <div className={styles["container"]}>
      {list
        ? list.map((item) =>
            item.type === "link" ? (
              <div
                className={styles["item"]}
                key={item.title}
                // eslint-disable-next-line react/jsx-no-bind
                onClick={() => {
                  onChangeRoute(item.url || "");
                }}
              >
                <span className={styles["item-icon"]}>{item.icon}</span>
                <div className={styles["item-title"]}>{item.title}</div>
              </div>
            ) : item.type === "button" ? (
              <div
                className={styles["item"]}
                key={item.title}
                onClick={item.onClick}
                role="button"
              >
                <span className={styles["item-icon"]}>{item.icon}</span>
                <div className={styles["item-title"]}>{item.title}</div>
              </div>
            ) : null
          )
        : null}
    </div>
  );
};

const PurchaseCardMemoized = memo(PurchaseCard);
export { PurchaseCardMemoized as PurchaseCard };
