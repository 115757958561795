import { memo, SVGProps } from "react";

const SvgAdFill = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    height="1em"
    viewBox="0 0 16 16"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <g clipPath="url(#Ad-fill_svg__a)">
      <path d="M10.153 7.454c-.448 0-.674.397-.674.892 0 .529.26.863.668.863a.577.577 0 0 0 .565-.468.966.966 0 0 0 .02-.221v-.411a.93.93 0 0 0-.013-.177c-.055-.263-.268-.478-.566-.478Z" />
      <path d="M12.8 2H3.2A1.2 1.2 0 0 0 2 3.2v9.6A1.2 1.2 0 0 0 3.2 14h9.6a1.2 1.2 0 0 0 1.2-1.2V3.2A1.2 1.2 0 0 0 12.8 2ZM7.159 9.917l-.333-1.111H5.582l-.305 1.11H4.265l1.32-4.325h1.28l1.347 4.326H7.159Zm3.707 0-.041-.46h-.014c-.198.352-.578.53-.983.53-.741 0-1.34-.636-1.34-1.612-.005-1.055.657-1.67 1.405-1.67.388 0 .686.138.826.355h.013v-1.7h.977v3.619c0 .356.012.728.025.938h-.868Z" />
      <path d="M6.194 6.325h-.013c-.064.254-.129.582-.198.834l-.259.918h.957l-.269-.919c-.075-.256-.151-.58-.218-.833Z" />
    </g>
    <defs>
      <clipPath id="Ad-fill_svg__a">
        <path d="M0 0h16v16H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(SvgAdFill);
export default Memo;
