import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import styles from "./digital-text-field-cell.module.scss";
import { DigitalTextFieldCellPropertiesType } from "./digital-text-field.types";

const DigitalTextFieldCell: FC<DigitalTextFieldCellPropertiesType> = (
  properties
) => {
  const { error, text, variant, focus, onClick } = properties;

  return (
    <div
      className={`${styles["digital-text-field-cell"]} ${
        error ? styles["error"] : ""
      } ${focus ? styles["focus"] : ""} ${text ? styles["fill"] : ""} ${
        styles[variant || "box"]
      }`}
      onClick={onClick}
    >
      {text}
    </div>
  );
};

const DigitalTextFieldCellMemoized = memo(DigitalTextFieldCell, deepCompare);
export { DigitalTextFieldCellMemoized as DigitalTextFieldCell };
