import { Close } from "@website/icons";
import { deepCompare } from "@website/utils";
import { FC, memo, PropsWithChildren } from "react";
import { createPortal } from "react-dom";
import { Transition } from "react-transition-group";
import { Button } from "..";
import { useDialog } from "./dialog.hook";
import styles from "./dialog.module.scss";
import { DialogPropertiesType } from "./dialog.types";

const Dialog: FC<PropsWithChildren<DialogPropertiesType>> = (properties) => {
  const {
    animate = "bottom",
    children,
    title,
    onClose,
    StartIcon
  } = properties;
  const {
    modalOpen,
    dialogContainerProperties,
    transitionProperties,
    dialogProperties
  } = useDialog(properties);

  if (modalOpen) {
    return createPortal(
      <Transition {...transitionProperties}>
        {(state) => (
          <div
            {...dialogContainerProperties}
            className={`${styles["dialog-container"]} ${
              styles[`animate-${animate}`]
            } ${styles[state]}`}
          >
            <div
              {...dialogProperties}
              className={styles["dialog"]}
              tabIndex={0}
            >
              {title || onClose || StartIcon ? (
                <div className={styles["header"]}>
                  {StartIcon}
                  {title && <div className={styles["title"]}>{title}</div>}
                  {onClose && (
                    <Button
                      EndIcon={<Close />}
                      color="neutral"
                      equalDimension
                      onClick={onClose}
                      size="small"
                      variant="text"
                    />
                  )}
                </div>
              ) : null}
              {children}
            </div>
          </div>
        )}
      </Transition>,
      document.body
    );
  }
  return null;
};

const DialogMemoized = memo(Dialog, deepCompare);
export { DialogMemoized as Dialog };
