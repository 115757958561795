import { memo, SVGProps } from "react";

const SvgPassport = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <path d="M7.97323 2.99984C6.13323 2.99984 4.64323 4.49317 4.64323 6.33317C4.64323 8.17317 6.13323 9.6665 7.97323 9.6665C9.81656 9.6665 11.3099 8.17317 11.3099 6.33317C11.3099 4.49317 9.81656 2.99984 7.97323 2.99984ZM10.2832 4.99984H9.2999C9.19323 4.58317 9.0399 4.18317 8.8399 3.81317C9.45323 4.02317 9.96323 4.44984 10.2832 4.99984ZM7.97656 3.67984C8.25323 4.07984 8.4699 4.52317 8.61323 4.99984H7.3399C7.48323 4.52317 7.6999 4.07984 7.97656 3.67984ZM5.39656 6.99984C5.34323 6.7865 5.3099 6.56317 5.3099 6.33317C5.3099 6.10317 5.34323 5.87984 5.39656 5.6665H6.52323C6.49656 5.8865 6.47656 6.1065 6.47656 6.33317C6.47656 6.55984 6.49656 6.77984 6.52323 6.99984H5.39656ZM5.6699 7.6665H6.65323C6.7599 8.08317 6.91323 8.48317 7.11323 8.85317C6.4999 8.64317 5.9899 8.21984 5.6699 7.6665ZM6.65323 4.99984H5.6699C5.9899 4.4465 6.4999 4.02317 7.11323 3.81317C6.91323 4.18317 6.7599 4.58317 6.65323 4.99984ZM7.97656 8.9865C7.6999 8.5865 7.48323 8.14317 7.3399 7.6665H8.61323C8.4699 8.14317 8.25323 8.5865 7.97656 8.9865ZM8.75656 6.99984H7.19656C7.16656 6.77984 7.14323 6.55984 7.14323 6.33317C7.14323 6.1065 7.16656 5.88317 7.19656 5.6665H8.75656C8.78656 5.88317 8.8099 6.1065 8.8099 6.33317C8.8099 6.55984 8.78656 6.77984 8.75656 6.99984ZM8.8399 8.85317C9.0399 8.48317 9.19323 8.08317 9.2999 7.6665H10.2832C9.96323 8.2165 9.45323 8.64317 8.8399 8.85317ZM9.4299 6.99984C9.45656 6.77984 9.47656 6.55984 9.47656 6.33317C9.47656 6.1065 9.45656 5.8865 9.4299 5.6665H10.5566C10.6099 5.87984 10.6432 6.10317 10.6432 6.33317C10.6432 6.56317 10.6099 6.7865 10.5566 6.99984H9.4299Z" />
    <path
      clip-rule="evenodd"
      d="M12.6908 1.99984H3.3099L3.3099 13.9998H12.6908L12.6908 1.99984ZM3.3099 0.666504C2.57352 0.666504 1.97656 1.26346 1.97656 1.99984V13.9998C1.97656 14.7362 2.57352 15.3332 3.3099 15.3332H12.6908C13.4272 15.3332 14.0242 14.7362 14.0242 13.9998V1.99984C14.0242 1.26346 13.4272 0.666504 12.6908 0.666504H3.3099Z"
      fill-rule="evenodd"
      fillOpacity="0.87"
    />
    <path d="M4.17643 12.9677V10.6975H5.04348C5.21017 10.6975 5.35217 10.7304 5.4695 10.7962C5.58682 10.8612 5.67624 10.9517 5.73777 11.0678C5.80001 11.1831 5.83113 11.3161 5.83113 11.4668C5.83113 11.6176 5.79965 11.7506 5.73669 11.8659C5.67374 11.9812 5.58253 12.0709 5.46306 12.1352C5.3443 12.1995 5.20051 12.2317 5.03168 12.2317H4.47904V11.847H4.95656C5.04599 11.847 5.11967 11.8311 5.17762 11.7994C5.23628 11.7668 5.27992 11.7221 5.30853 11.6652C5.33787 11.6076 5.35253 11.5415 5.35253 11.4668C5.35253 11.3914 5.33787 11.3257 5.30853 11.2695C5.27992 11.2126 5.23628 11.1686 5.17762 11.1376C5.11896 11.1058 5.04456 11.0899 4.95442 11.0899H4.64108V12.9677H4.17643Z" />
    <path d="M6.2655 12.9677H5.76759L6.52626 10.6975H7.12504L7.88263 12.9677H7.38472L6.83423 11.2163H6.81706L6.2655 12.9677ZM6.23438 12.0754H7.41048V12.45H6.23438V12.0754Z" />
    <path d="M9.29673 11.3504C9.28815 11.261 9.2513 11.1915 9.1862 11.142C9.1211 11.0925 9.03275 11.0678 8.92115 11.0678C8.84532 11.0678 8.78129 11.0789 8.72907 11.101C8.67685 11.1225 8.63678 11.1524 8.60888 11.1908C8.5817 11.2292 8.56811 11.2728 8.56811 11.3216C8.56668 11.3623 8.5749 11.3977 8.59279 11.428C8.61139 11.4583 8.63679 11.4846 8.66898 11.5067C8.70117 11.5282 8.73837 11.547 8.78058 11.5633C8.82279 11.5788 8.86786 11.5921 8.91579 11.6032L9.11323 11.6519C9.2091 11.6741 9.29709 11.7037 9.37721 11.7406C9.45733 11.7776 9.52673 11.823 9.58539 11.877C9.64405 11.9309 9.68948 11.9945 9.72167 12.0676C9.75458 12.1408 9.77139 12.2246 9.77211 12.3192C9.77139 12.4582 9.73705 12.5786 9.66909 12.6806C9.60184 12.7818 9.50455 12.8605 9.37721 12.9167C9.25059 12.9721 9.09785 12.9998 8.91901 12.9998C8.74159 12.9998 8.58707 12.9718 8.45543 12.9156C8.32452 12.8594 8.22222 12.7763 8.14853 12.6662C8.07556 12.5553 8.03729 12.4183 8.03371 12.2549H8.48333C8.48834 12.3311 8.50945 12.3946 8.54665 12.4456C8.58456 12.4959 8.635 12.5339 8.69795 12.5598C8.76162 12.5849 8.83352 12.5975 8.91364 12.5975C8.99233 12.5975 9.06065 12.5856 9.1186 12.562C9.17726 12.5383 9.22269 12.5055 9.25488 12.4633C9.28707 12.4212 9.30317 12.3728 9.30317 12.3181C9.30317 12.2671 9.2885 12.2243 9.25917 12.1895C9.23056 12.1548 9.18835 12.1253 9.13255 12.1009C9.07746 12.0765 9.00986 12.0543 8.92974 12.0344L8.69044 11.9723C8.50515 11.9257 8.35886 11.8529 8.25155 11.7539C8.14424 11.6549 8.09094 11.5215 8.09166 11.3538C8.09094 11.2163 8.12636 11.0962 8.19789 10.9935C8.27015 10.8908 8.36923 10.8106 8.49514 10.753C8.62105 10.6953 8.76412 10.6665 8.92437 10.6665C9.08748 10.6665 9.22984 10.6953 9.35146 10.753C9.47379 10.8106 9.56894 10.8908 9.6369 10.9935C9.70486 11.0962 9.73991 11.2152 9.74206 11.3504H9.29673Z" />
    <path d="M11.2844 11.3504C11.2758 11.261 11.239 11.1915 11.1739 11.142C11.1088 11.0925 11.0204 11.0678 10.9088 11.0678C10.833 11.0678 10.769 11.0789 10.7167 11.101C10.6645 11.1225 10.6244 11.1524 10.5965 11.1908C10.5694 11.2292 10.5558 11.2728 10.5558 11.3216C10.5543 11.3623 10.5626 11.3977 10.5804 11.428C10.599 11.4583 10.6244 11.4846 10.6566 11.5067C10.6888 11.5282 10.726 11.547 10.7682 11.5633C10.8104 11.5788 10.8555 11.5921 10.9034 11.6032L11.1009 11.6519C11.1968 11.6741 11.2847 11.7037 11.3649 11.7406C11.445 11.7776 11.5144 11.823 11.573 11.877C11.6317 11.9309 11.6771 11.9945 11.7093 12.0676C11.7422 12.1408 11.7591 12.2246 11.7598 12.3192C11.7591 12.4582 11.7247 12.5786 11.6567 12.6806C11.5895 12.7818 11.4922 12.8605 11.3649 12.9167C11.2382 12.9721 11.0855 12.9998 10.9067 12.9998C10.7292 12.9998 10.5747 12.9718 10.4431 12.9156C10.3122 12.8594 10.2099 12.7763 10.1362 12.6662C10.0632 12.5553 10.0249 12.4183 10.0214 12.2549H10.471C10.476 12.3311 10.4971 12.3946 10.5343 12.4456C10.5722 12.4959 10.6227 12.5339 10.6856 12.5598C10.7493 12.5849 10.8212 12.5975 10.9013 12.5975C10.98 12.5975 11.0483 12.5856 11.1063 12.562C11.1649 12.5383 11.2103 12.5055 11.2425 12.4633C11.2747 12.4212 11.2908 12.3728 11.2908 12.3181C11.2908 12.2671 11.2762 12.2243 11.2468 12.1895C11.2182 12.1548 11.176 12.1253 11.1202 12.1009C11.0651 12.0765 10.9975 12.0543 10.9174 12.0344L10.6781 11.9723C10.4928 11.9257 10.3465 11.8529 10.2392 11.7539C10.1319 11.6549 10.0786 11.5215 10.0793 11.3538C10.0786 11.2163 10.114 11.0962 10.1856 10.9935C10.2578 10.8908 10.3569 10.8106 10.4828 10.753C10.6087 10.6953 10.7518 10.6665 10.912 10.6665C11.0751 10.6665 11.2175 10.6953 11.3391 10.753C11.4614 10.8106 11.5566 10.8908 11.6246 10.9935C11.6925 11.0962 11.7276 11.2152 11.7297 11.3504H11.2844Z" />
  </svg>
);

const Memo = memo(SvgPassport);
export default Memo;
