import { useViewPort } from "@website/hooks";
import { toString } from "lodash";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";
import { InitializeShowDatesType } from "../datepicker.types";
import { createShowDatesArray, getShowDatesInitial } from "../datepicker.utils";

export const useInititalShowDates = (properties: InitializeShowDatesType) => {
  const {
    calendarLocale,
    setShowDates,
    showDates,
    initialShowDates,
    INITIAL_SHOW_DATES_NUMBER,
    type
  } = properties;

  const router = useRouter();
  const { isDesktop } = useViewPort();
  const [routerIsReady, setRouterIsReady] = useState(true);
  const [lastCalendarLocale, setLastCalendarLocale] = useState(calendarLocale);

  const shouldSyncDatePickerWithParameters = isDesktop || type === "flat";

  const calculateInititalShowDates = useMemo(
    () =>
      getShowDatesInitial(
        toString(router.query?.date_from),
        INITIAL_SHOW_DATES_NUMBER,
        calendarLocale
      ),
    [INITIAL_SHOW_DATES_NUMBER, calendarLocale, router.query?.date_from]
  );

  useEffect(() => {
    if (router.isReady && routerIsReady && shouldSyncDatePickerWithParameters) {
      setRouterIsReady(false);
      setShowDates(initialShowDates ?? calculateInititalShowDates);
    }
  }, [
    calculateInititalShowDates,
    initialShowDates,
    shouldSyncDatePickerWithParameters,
    router.isReady,
    routerIsReady,
    setShowDates
  ]);

  useEffect(() => {
    if (calendarLocale !== lastCalendarLocale) {
      setShowDates(createShowDatesArray(calendarLocale, showDates.length));
    }
    setLastCalendarLocale(calendarLocale);
  }, [calendarLocale, lastCalendarLocale, setShowDates, showDates.length]);

  useEffect(() => {
    if (initialShowDates && shouldSyncDatePickerWithParameters) {
      setShowDates(initialShowDates);
    }
  }, [initialShowDates, shouldSyncDatePickerWithParameters, setShowDates]);

  useEffect(() => {
    if (!shouldSyncDatePickerWithParameters) {
      setShowDates(createShowDatesArray(calendarLocale, showDates.length));
    }
  }, [
    calendarLocale,
    shouldSyncDatePickerWithParameters,
    setShowDates,
    showDates.length
  ]);
};
