import {
  CalendarDate,
  GregorianCalendar,
  IslamicTabularCalendar,
  PersianCalendar,
  getDayOfWeek,
  getLocalTimeZone,
  today
} from "@internationalized/date";
import { DayType, MonthType, gregorianToShamsi } from "@website/utils";
import { MouseEvent, useCallback, useEffect, useState } from "react";
import {
  DatepickerDateType,
  DatepickerDayItemType,
  DatepickerDayStateType,
  PriceDateType
} from "../../datepicker.types";
import { TablePropertiesType } from "./table.types";

export const useTable = (properties: TablePropertiesType) => {
  const {
    calendarLocale,
    calendarData,
    year,
    month,
    mode,
    value,
    onChange,
    disabledDateMode = "past",
    isEndFocused = false,
    isStartFocused = false,
    onFocusEnd,
    onBlurInputs,
    holidays,
    prices
  } = properties;

  const [holidaysList, setHolidaysList] = useState<Array<string>>([]);
  const [priceList, setPriceList] = useState<Array<PriceDateType>>([]);

  useEffect(() => {
    setHolidaysList(
      holidays?.map((date) => {
        const d = gregorianToShamsi(
          Number(date?.slice(0, 4)),
          Number(date?.slice(5, 7)) as MonthType,
          Number(date?.slice(8)) as DayType
        );
        return `${d[0]}${d[1] > 9 ? d[1] : `0${d[1]}`}${
          d[2] > 9 ? d[2] : `0${d[2]}`
        }`;
      }) || []
    );
  }, [holidays]);

  useEffect(() => {
    if (prices) {
      setPriceList(
        prices.map((item: any) => {
          const d = gregorianToShamsi(
            Number(item.Date?.slice(0, 4)),
            Number(item.Date?.slice(5, 7)) as MonthType,
            Number(item.Date?.slice(8)) as DayType
          );
          return {
            ...item,
            Date: `${d[0]}${d[1] > 9 ? d[1] : `0${d[1]}`}${
              d[2] > 9 ? d[2] : `0${d[2]}`
            }`
          };
        }) || []
      );
    }
  }, [prices]);

  const onDateClick = useCallback(
    (date: DatepickerDateType) => {
      const D = new Date(date?.dateTime).getTime();
      const V0 = new Date(value[0]?.dateTime).getTime();
      const V1 = new Date(value[1]?.dateTime).getTime();

      switch (mode) {
        case "single": {
          onChange([date], calendarLocale);
          break;
        }
        case "multiple": {
          const selectedIndex = value.findIndex(
            (item) => item.dateTime === date.dateTime
          );
          if (selectedIndex === -1) {
            onChange([...value, date], calendarLocale);
          } else {
            onChange(
              value.filter((_, index) => index !== selectedIndex),
              calendarLocale
            );
          }
          break;
        }
        case "range":
        case "start-end": {
          if (isStartFocused) {
            if (V0) {
              if (V1) {
                if (D < V1) {
                  onChange([date, value[1]], calendarLocale);
                } else if (D === V1) {
                  onChange([date], calendarLocale);
                } else {
                  onChange([date], calendarLocale);
                }
              } else if (D > V0) {
                onChange([value[0], date], calendarLocale);
              } else if (D === V0) {
                onChange([date], calendarLocale);
              } else {
                onChange([date], calendarLocale);
              }
            } else {
              onChange([date], calendarLocale);
            }
            onFocusEnd && onFocusEnd();
          } else if (isEndFocused) {
            if (V1) {
              if (V0) {
                if (D > V0) {
                  onChange([value[0], date], calendarLocale);
                } else if (D === V0) {
                  onChange([date], calendarLocale);
                } else {
                  onChange([date], calendarLocale);
                }
              } else {
                onChange([date], calendarLocale);
              }
            } else if (V0) {
              if (D > V0) {
                onChange([value[0], date], calendarLocale);
              } else if (D === V0) {
                onChange([date], calendarLocale);
              } else {
                onChange([date], calendarLocale);
              }
            } else {
              onChange([date, value[1]], calendarLocale);
            }
            onBlurInputs && onBlurInputs();
          } else if (V0) {
            if (V1) {
              onChange([date], calendarLocale);
            } else if (D > V0) {
              onChange([value[0], date], calendarLocale);
            } else {
              onChange([date], calendarLocale);
            }
          } else {
            onChange([date], calendarLocale);
          }
          break;
        }
        case "same-start-end": {
          if (isStartFocused) {
            if (V0) {
              if (V1) {
                if (D < V1) {
                  onChange([date, value[1]], calendarLocale);
                } else if (D === V1) {
                  onChange([date, date], calendarLocale);
                } else {
                  onChange([date], calendarLocale);
                }
              } else if (D > V0) {
                onChange([value[0], date], calendarLocale);
              } else if (D === V0) {
                onChange([date, date], calendarLocale);
              } else {
                onChange([date], calendarLocale);
              }
            } else {
              onChange([date], calendarLocale);
            }
            onFocusEnd && onFocusEnd();
          } else if (isEndFocused) {
            if (V1) {
              if (V0) {
                if (D > V0) {
                  onChange([value[0], date], calendarLocale);
                } else if (D === V0) {
                  onChange([date, date], calendarLocale);
                } else {
                  onChange([date], calendarLocale);
                }
              } else {
                onChange([date], calendarLocale);
              }
            } else if (V0) {
              if (D > V0) {
                onChange([value[0], date], calendarLocale);
              } else if (D === V0) {
                onChange([date, date], calendarLocale);
              } else {
                onChange([date], calendarLocale);
              }
            } else {
              onChange([date, value[1]], calendarLocale);
            }
            onBlurInputs && onBlurInputs();
          } else if (V0) {
            if (V1) {
              onChange([date], calendarLocale);
            } else if (D > V0) {
              onChange([value[0], date], calendarLocale);
            } else if (D === V0) {
              onChange([date, date], calendarLocale);
            } else {
              onChange([date], calendarLocale);
            }
          } else {
            onChange([date], calendarLocale);
          }
          break;
        }
        // No default
      }
    },
    [
      value,
      mode,
      onChange,
      calendarLocale,
      isStartFocused,
      isEndFocused,
      onFocusEnd,
      onBlurInputs
    ]
  );

  const todayDate = today(getLocalTimeZone());
  const todayString = todayDate.toString();
  const todayTimestamp = new Date(todayString).getTime();

  const days: Array<DatepickerDayItemType> = [];

  // show date calendar in locale
  let calendar;
  let dayOfWeek;
  let daysInMonth;
  if (calendarLocale === "persian") {
    calendar = new CalendarDate(new PersianCalendar(), year, month, 1);
    daysInMonth = new PersianCalendar().getDaysInMonth(calendar);
    dayOfWeek = getDayOfWeek(calendar, "fa-IR");
  } else if (calendarLocale === "hijri") {
    calendar = new CalendarDate(new IslamicTabularCalendar(), year, month, 1);
    daysInMonth = new IslamicTabularCalendar().getDaysInMonth(calendar);
    dayOfWeek = getDayOfWeek(calendar, "ar-SA");
  } else {
    calendar = new CalendarDate(new GregorianCalendar(), year, month, 1);
    daysInMonth = new GregorianCalendar().getDaysInMonth(calendar);
    dayOfWeek = getDayOfWeek(calendar, "en-US");
    // start of week is sunday.
    // change it to moday.
    dayOfWeek -= 1;
    if (dayOfWeek === -1) {
      dayOfWeek = 6;
    }
  }

  // selected dates timestamp
  const selectedDateTimestamp = value.map((item: DatepickerDateType) =>
    new Date(item?.dateTime).getTime()
  );

  for (let index = 0; index < dayOfWeek; index++) {
    days.push({
      value: -1,
      state: "disabled",
      isToday: false,
      onClick: undefined,
      content: undefined
    });
  }
  for (let index = 1; index <= daysInMonth; index++) {
    let state: DatepickerDayStateType = "normal";
    let content: number | string = "";
    let type: string = "";

    if (
      calendarLocale === "persian" &&
      holidaysList &&
      holidaysList.length > 0 &&
      (dayOfWeek + index) % 7 !== 0
    ) {
      const d = Number(
        `${year}${month > 9 ? month : `0${month}`}${
          index > 9 ? index : `0${index}`
        }`
      );
      for (const date of holidaysList) {
        if (Number(date) === d) {
          state = "holiday";
          break;
        }
        if (Number(date) > d) break;
      }
    }

    if (
      calendarLocale === "persian" &&
      Array.isArray(priceList) &&
      priceList.length > 0
    ) {
      const d = Number(
        `${year}${month > 9 ? month : `0${month}`}${
          index > 9 ? index : `0${index}`
        }`
      );
      // TODO fix 'for in for' structure
      for (const item of priceList) {
        if (Number(item.Date) === d) {
          content = item.Price;
          type = item.Type;
          break;
        }
      }
    }

    if ((dayOfWeek + index) % 7 === 0) {
      state = "weekend";
    }
    const newDate = calendar.add({ days: index - 1 });
    const newDateString = newDate.toString();
    const newDateTimestamp = new Date(newDateString).getTime();

    let extraData;
    if (calendarData) {
      extraData = calendarData[newDateString];
    }

    if (
      disabledDateMode === "all" &&
      (extraData?.disabled === undefined || extraData?.disabled === true)
    ) {
      // disabled
      state = "disabled";
    } else if (
      disabledDateMode === "past" &&
      newDateTimestamp < todayTimestamp
    ) {
      // disabled
      state = "disabled";
    } else if (extraData?.disabled === true) {
      // disabled
      state = "disabled";
    } else if (
      mode === "single" &&
      newDateTimestamp === selectedDateTimestamp[0]
    ) {
      // single
      state = "selected";
    } else if (
      mode === "multiple" &&
      selectedDateTimestamp.includes(newDateTimestamp)
    ) {
      // mutiple
      state = "multi-selected";
    } else if (
      mode === "range" &&
      newDateTimestamp === selectedDateTimestamp[0]
    ) {
      // range -> start
      state = "start";
    } else if (
      mode === "range" &&
      newDateTimestamp > selectedDateTimestamp[0] &&
      newDateTimestamp < selectedDateTimestamp[1]
    ) {
      // range -> in
      state = "in-range";
    } else if (
      mode === "range" &&
      newDateTimestamp === selectedDateTimestamp[1]
    ) {
      // range -> end
      state = "end";
    } else if (
      mode === "start-end" &&
      newDateTimestamp === selectedDateTimestamp[0]
    ) {
      // start-end -> start
      state = "start";
    } else if (
      mode === "start-end" &&
      newDateTimestamp > selectedDateTimestamp[0] &&
      newDateTimestamp < selectedDateTimestamp[1]
    ) {
      // start-end -> middle
      state = "middle";
    } else if (
      mode === "start-end" &&
      newDateTimestamp === selectedDateTimestamp[1]
    ) {
      // start-end -> end
      state = "end";
    } else if (extraData?.state === "green") {
      state = "special-best";
    } else if (extraData?.state === "red") {
      state = "special-worst";
    } else if (
      mode === "same-start-end" &&
      newDateTimestamp === selectedDateTimestamp[0] &&
      newDateTimestamp === selectedDateTimestamp[1]
    ) {
      // start=end -> selected
      state = "selected";
    } else if (
      mode === "same-start-end" &&
      newDateTimestamp === selectedDateTimestamp[0]
    ) {
      // start-end -> start
      state = "start";
    } else if (
      mode === "same-start-end" &&
      newDateTimestamp > selectedDateTimestamp[0] &&
      newDateTimestamp < selectedDateTimestamp[1]
    ) {
      // start-end -> middle
      state = "middle";
    } else if (
      mode === "same-start-end" &&
      newDateTimestamp === selectedDateTimestamp[1]
    ) {
      // start-end -> end
      state = "end";
    }

    let onClick: undefined | ((event: MouseEvent<HTMLDivElement>) => void);
    if (state !== "disabled") {
      onClick = () =>
        onDateClick({
          year: newDate.year,
          month: newDate.month,
          day: newDate.day,
          dateTime: newDateString
        });
    }

    days.push({
      value: index,
      state,
      isToday: newDateTimestamp === todayTimestamp,
      onClick,
      content: extraData?.content || content,
      type
    });
  }
  for (let index = days.length; index < 42; index++) {
    days.push({
      value: -1,
      state: "disabled",
      isToday: false,
      onClick: undefined,
      content: undefined
    });
  }

  return {
    days
  };
};
