// noinspection JSUnusedGlobalSymbols
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "@website/locale";
import { get, mapValues, toString } from "lodash";
import { FC, PropsWithChildren, useEffect } from "react";
import { useForm as useFormController } from "react-hook-form";
import {
  addMethod,
  array,
  boolean,
  date,
  defaultLocale,
  lazy,
  mixed,
  number,
  object,
  ref,
  setLocale,
  string
} from "yup";
import { UseFormType } from "./form.types";
import {
  isAlphabet,
  isArabic,
  isEnglish,
  isPersian,
  isValidFileSize,
  isValidFileType,
  isValidMobileNumber,
  isValidPassportExpireDate,
  isValidPassword,
  isValidateNationalCode,
  isValidatePassportCode
} from "./modules";

declare module "yup" {
  interface StringSchema {
    persian(...parameters: Parameters<typeof isPersian>): this;
    arabic(...parameters: Parameters<typeof isArabic>): this;
    english(...parameters: Parameters<typeof isEnglish>): this;
    alphabet(...parameters: Parameters<typeof isEnglish>): this;
    password(message?: string): this;
    mobile(text?: string): this;
    passportExpireDate(message?: string, toDate?: string): this;
    nationalCode(text?: string): this;
    passportCode(
      message?: string,
      ...parameters: Parameters<typeof isValidatePassportCode>
    ): this;
  }

  interface DateSchema {
    timestamp(): this;
  }

  interface MixedSchema {
    fileSize(
      message?: string,
      ...parameters: Parameters<typeof isValidFileSize>
    ): this;

    fileType(
      message?: string,
      ...parameters: Parameters<typeof isValidFileType>
    ): this;
  }
}

const customValidations = {
  string: {
    persian: isPersian,
    arabic: isArabic,
    english: isEnglish,
    alphabet: isAlphabet,
    password: isValidPassword,
    mobile: isValidMobileNumber,
    passportExpireDate: isValidPassportExpireDate,
    nationalCode: isValidateNationalCode,
    passportCode: isValidatePassportCode
  },
  mixed: {
    fileSize: isValidFileSize,
    fileType: isValidFileType
  }
};

const methodsMap = {
  array,
  boolean,
  date,
  lazy,
  mixed,
  number,
  object,
  ref,
  string
};

export const YupProvider: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation();
  setLocale(
    mapValues(defaultLocale, (group, groupKey) =>
      mapValues(group, (_, stringKey) => (parameters: { path: string }) => {
        const key = parameters.path.replaceAll(/\[\d+]/g, "");
        return t<string>(`validations.${groupKey}.${stringKey}`, {
          ...parameters,
          path: t<string>(`fields.${key}`) ?? key
        });
      })
    ) as typeof defaultLocale
  );

  useEffect(() => {
    window.addEventListener(
      "focus",
      (event) => {
        if (
          ["INPUT", "TEXTAREA"].includes(
            toString(get(event?.target, "nodeName"))
          )
        )
          document.body.classList.add("keyboard-opened");
      },
      true
    );
    window.addEventListener(
      "blur",
      () => document.body.classList.remove("keyboard-opened"),
      true
    );
  }, []);

  for (const [groupKey, group] of Object.entries(customValidations)) {
    for (const [name, method] of Object.entries(group)) {
      addMethod(
        get(methodsMap, groupKey) as Parameters<typeof addMethod>[0],
        name,
        function (message, ...arguments_) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          return this.test(name, function (value, parameters) {
            return (
              method(value, ...(arguments_ as [])) ||
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              this.createError({
                params: {
                  path: parameters?.path,
                  ...(method === "fileType"
                    ? {
                        types: arguments_[0]?.map((type: string) =>
                          type.split("/").pop()
                        )
                      }
                    : {}),
                  ...(method === "fileSize" ? { size: arguments_[0] } : {})
                },
                message:
                  message ||
                  t<string>(`validations.${groupKey}.${name}`, {
                    ...parameters,
                    path: t<string>(
                      `fields.${parameters?.path.replaceAll(/\[\d+]/g, "")}`
                    )
                  })
              })
            );
          });
        }
      );
    }
  }
  return children;
};

export const useForm: UseFormType = ({ validations, ...properties }) => {
  const form = useFormController({
    ...properties,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    resolver: yupResolver(validations)
  });
  const handleSubmit: typeof form.handleSubmit = (onValid, onInvalid) =>
    form.handleSubmit(onValid, (errors, event) => {
      onInvalid?.(errors, event);
      setTimeout(() => {
        document
          .querySelector("[data-error=true]")
          ?.scrollIntoView({ behavior: "smooth", block: "center" });
      }, 0);
    });
  return {
    ...form,
    handleSubmit
  };
};

export {
  array,
  boolean,
  date,
  lazy,
  mixed,
  number,
  object,
  ref,
  string
} from "yup";

export { Controller, useFieldArray } from "react-hook-form";
