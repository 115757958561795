import { memo, SVGProps } from "react";

const SvgChevronLeft = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    fill="#040A1F"
    fillOpacity={0.87}
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <path d="M15.41 7.41 14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59Z" />
  </svg>
);

const Memo = memo(SvgChevronLeft);
export default Memo;
