import { MaterialIcon } from "@website/icons";
import { deepCompare } from "@website/utils";
import { FC, PropsWithChildren, memo } from "react";
import { Transition } from "../transition";
import { useModal } from "./modal.hook";
import styles from "./modal.module.scss";
import { ModalPropertiesType } from "./modal.types";

const Modal: FC<PropsWithChildren<ModalPropertiesType>> = (properties) => {
  const {
    open,
    fullScreen,
    animate,
    children,
    title,
    onClose,
    showCloseButton,
    mobileHeight,
    className,
    closeIcon = "close",
    headerStyle,
    dialogClassName,
    iconClassName
  } = properties;
  const { modalContainerProps, transitionProps, modalProps } =
    useModal(properties);

  if (!open) return null;

  return (
    <Transition {...transitionProps} in={open}>
      {(state) => (
        <div
          className={`${styles["container"]} ${className} ${
            state === "entering" || state === "entered"
              ? styles["container-in"]
              : styles["container-out"]
          } ${fullScreen ? "!size-full rounded-none p-0 md:box-content" : ""}`}
          {...modalContainerProps}
        >
          <div
            {...modalProps}
            className={`${styles["modal"]} ${className} ${dialogClassName} ${
              styles[mobileHeight || "full"]
            } ${
              animate === "bottom"
                ? `${styles["bottom"]} ${
                    state === "entered"
                      ? styles["modal-in"]
                      : styles["modal-out"]
                  }`
                : ""
            } ${
              fullScreen ? "!size-full rounded-none p-0 md:box-content" : ""
            }`}
            tabIndex={0}
          >
            {showCloseButton || title ? (
              <div className={`${headerStyle} ${styles["header"]}`}>
                {title ? <h6 className={styles["title"]}>{title}</h6> : ""}
                {showCloseButton ? (
                  <MaterialIcon
                    className={`${styles["close"]} ${iconClassName || ""}`}
                    name={closeIcon}
                    onClick={onClose}
                  />
                ) : (
                  ""
                )}
              </div>
            ) : null}
            {children}
          </div>
        </div>
      )}
    </Transition>
  );
};

const ModalMemoized = memo(Modal, deepCompare);
export { ModalMemoized as Modal };
