import { useViewPort } from "@website/hooks";
import { ArrowDropDown } from "@website/icons";
import { useCallback, useMemo, useState } from "react";
// import { pick } from "lodash";
import { DropdownPropertiesType } from "./dropdown.types";

export const useDropdown = (properties: DropdownPropertiesType) => {
  const {
    size = "default",
    helperText,
    label,
    StartIcon,
    onStartIconClick,
    onChange,
    fullWidth,
    disabled,
    list,
    value,
    name,
    placeholder,
    error,
    divider
  } = properties;
  const [open, setOpen] = useState<boolean>(false);
  const { isMobile } = useViewPort();

  const onClosePopover = useCallback(() => {
    setOpen(false);
  }, []);

  // const onFocusTextField = useCallback(() => {
  //   setOpen(true);
  // }, []);

  // const onBlurTextField = useCallback(() => {
  //   setOpen(false);
  // }, []);

  const onClickTextField = useCallback(() => {
    setOpen(true);
  }, []);

  const onChangeTextField = useCallback(() => {
    // no need to do anything
  }, []);

  const onChangeMenu = useCallback(
    (value: string) => {
      onChange(value);
      setOpen(false);
    },
    [onChange]
  );

  const selectedItem = useMemo(
    () => list.find((item) => item.value === value),
    [list, value]
  );

  return {
    rootProps: {
      // ...pick(props, "aria-label", "onClick", "type"),
      // disabled: props.disabled || props.loading,
    },
    textfieldProps: {
      // onFocus: onFocusTextField,
      // onBlur: onBlurTextField,
      onClick: onClickTextField,
      onChange: onChangeTextField,
      size,
      helperText,
      label,
      EndIcon: <ArrowDropDown />,
      StartIcon,
      onStartIconClick,
      fullWidth,
      disabled,
      value: selectedItem?.title || "",
      name,
      placeholder,
      error
    },
    popoverProps: {
      animate: "bottom" as const,
      open,
      title: isMobile ? label : undefined,
      onClose: onClosePopover,
      size,
      hideCloseButton: true,
      fullWidth: true
    },
    menuProps: {
      onChange: onChangeMenu,
      list,
      value,
      divider,
      size
    }
  };
};
