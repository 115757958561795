import { pick } from "lodash";
import { RadioPropertiesType } from "./radio.types";

export const useRadio = (properties: RadioPropertiesType) => ({
  inputProps: {
    ...pick(
      properties,
      "autoFocus",
      "disabled",
      "checked",
      "name",
      "onChange",
      "readOnly",
      "value"
    ),
    type: "radio"
  }
});
