import { FocusEvent, MouseEvent, useCallback, useRef } from "react";
import { TextFieldPropertiesType } from "./text-field.types";

export const useTextField = (properties: TextFieldPropertiesType) => {
  const {
    disabled,
    onChange,
    onClick,
    placeholder,
    value,
    name,
    readOnly,
    type,
    onFocus,
    onBlur,
    onStartIconClick,
    onEndIconClick
  } = properties;
  // const [hasFocus, setHasFocus] = useState<boolean>(false);

  const inputReference = useRef<HTMLInputElement>(null);

  const onRootClick = useCallback(() => {
    inputReference.current?.focus();
    onClick?.();
  }, [onClick]);

  const onInputFocus = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      if (onFocus) {
        onFocus(event);
      }
      // setHasFocus(true);
    },
    [onFocus]
  );

  const onInputBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      if (onBlur) {
        onBlur(event);
      }
      // setHasFocus(false);
    },
    [onBlur]
  );

  const onStartIconClickHandler = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      if (onStartIconClick) {
        event.stopPropagation();
        onStartIconClick(event);
      }
    },
    [onStartIconClick]
  );

  const onEndIconClickHandler = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      if (onEndIconClick) {
        event.stopPropagation();
        onEndIconClick(event);
      }
    },
    [onEndIconClick]
  );

  return {
    rootProperties: {
      onClick: onRootClick
      // ...pick(props, "aria-label", "onClick", "type"),
      // disabled: props.disabled || props.loading,
    },
    inputProperties: {
      ref: inputReference,
      disabled,
      onChange,
      placeholder,
      name,
      value,
      readOnly,
      type,
      onFocus: onInputFocus,
      onBlur: onInputBlur
    },
    startIconProperties: {
      onClick: onStartIconClickHandler
    },
    endIconProperties: {
      onClick: onEndIconClickHandler
    }
  };
};
