import { observable } from "@legendapp/state";
import { persistObservable } from "@legendapp/state/persist";
import { ObservablePersistLocalStorage } from "@legendapp/state/persist-plugins/local-storage";

export const authStore = observable({
  accessToken: "",
  refreshToken: "",
  jekToken: ""
});

persistObservable(authStore, {
  local: "auth_store",
  pluginLocal: ObservablePersistLocalStorage
});
