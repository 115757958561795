import { FC, memo } from "react";
import { useTextField } from "./text-field.hook";
import styles from "./text-field.module.scss";
import { TextFieldPropertiesType } from "./text-field.types";

const TextField: FC<TextFieldPropertiesType> = (properties) => {
  const {
    className,
    disabled,
    error,
    EndIcon,
    fullWidth,
    helperText,
    label,
    prefix,
    size = "default",
    StartIcon,
    suffix,
    inputMode = "text",
    noBorder,
    staticDirection,
    id,
    textCenter
  } = properties;
  const {
    rootProperties,
    inputProperties,
    startIconProperties,
    endIconProperties
  } = useTextField(properties);

  return (
    <div
      className={`${styles["container"]} ${error ? styles["error"] : ""} ${
        disabled ? styles["disabled"] : ""
      } ${styles[size]} ${fullWidth ? styles["fullWidth"] : ""} ${noBorder
        ?.map((item) => `${styles[`no-border-${item}`]}`)
        .join(" ")} ${className ?? ""}`}
      {...rootProperties}
      id={id}
    >
      <div className={styles["box"]}>
        {StartIcon ? (
          <div {...startIconProperties} className={styles["start-icon"]}>
            {StartIcon}
          </div>
        ) : null}
        {prefix ? <div className={styles["prefix"]}>{prefix}</div> : null}
        <input
          {...inputProperties}
          className={`${styles["input"]} ${label ? styles["has-label"] : ""} ${
            staticDirection ? styles[staticDirection] : ""
          } ${textCenter && "text-center"}`}
          inputMode={inputMode}
        />
        {suffix ? <div className={styles["suffix"]}>{suffix}</div> : null}
        {EndIcon ? (
          <div {...endIconProperties} className={styles["end-icon"]}>
            {EndIcon}
          </div>
        ) : null}
        {label ? (
          <label
            className={`${styles["label"]} ${
              prefix || StartIcon ? styles["active"] : ""
            }`}
          >
            {label}
          </label>
        ) : null}
      </div>
      {helperText ? (
        <div className={styles["helper-text"]}>{helperText}</div>
      ) : null}
    </div>
  );
};

const TextFieldMemoized = memo(TextField);
export { TextFieldMemoized as TextField };
