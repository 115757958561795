import { useEffect } from "react";
import { DrawerPropertiesType } from "./drawer.types";
export const useDrawer = (properties: DrawerPropertiesType) => {
  const { open } = properties;

  useEffect(() => {
    if (open) {
      document.body.classList.add("prevent-scroll");
    } else {
      document.body.classList.remove("prevent-scroll");
    }

    return () => {
      document.body.classList.remove("prevent-scroll");
    };
  }, [open]);

  return {
    transitionProperties: {
      in: open,
      timeout: 75
    }
  };
};
