import { useMemo } from "react";

const RANGE_PLACEHOLDER = 0;
const PAGE_NUMBERS = 5;

const range = (start: number, end: number) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, index) => index + start);
};

export const usePaginationRange = (count: number, page: number) => {
  const items = useMemo(() => {
    if (PAGE_NUMBERS >= count) {
      return range(1, count);
    }

    const leftSiblingIndex = Math.max(page, 1);
    const rightSiblingIndex = Math.min(page, count);

    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < count - 2;

    const firstPageIndex = 1;
    const lastPageIndex = count;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftRange = range(1, PAGE_NUMBERS);
      return [...leftRange, RANGE_PLACEHOLDER, count];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightRange = range(count - PAGE_NUMBERS + 1, count);
      return [firstPageIndex, RANGE_PLACEHOLDER, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range(leftSiblingIndex - 1, rightSiblingIndex + 1);
      return [
        firstPageIndex,
        RANGE_PLACEHOLDER,
        ...middleRange,
        RANGE_PLACEHOLDER,
        lastPageIndex
      ];
    }

    return [];
  }, [count, page]);

  return items;
};
