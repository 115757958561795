import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { ChangeEvent, MouseEvent, useCallback, useState } from "react";
import { Button } from "../..";
import { AutoCompletePropertiesType } from "../auto-complete.types";

export const useEnd = (properties: AutoCompletePropertiesType) => {
  const { parts, size } = properties;

  const [isOpenEndPopover, setIsOpenEndPopover] = useState<boolean>(false);
  const { t } = useTranslation();

  const onCloseEndPopover = useCallback(() => {
    setIsOpenEndPopover(false);
  }, []);

  const onClickEndTextField = useCallback(() => {
    setIsOpenEndPopover(true);
  }, []);

  const onChangeEndPopoverTextField = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      parts[1]?.onChangeQuery(event.target.value);
    },
    [parts]
  );

  const onClickEndItem = useCallback(
    (item: any) => {
      // parts[1]?.onChangeQuery(item.title);
      parts[1]?.onSelect(item.id);
      setIsOpenEndPopover(false);
    },
    [parts]
  );

  const onEmptyEndSearchField = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      parts[1]?.onChangeQuery("");
      // parts[1]?.onSelect("");
    },
    [parts]
  );

  const onClickHistoryItem = useCallback(
    (item: any) => {
      parts[1]?.onSelectHistory && parts[1]?.onSelectHistory(item.id);
      setIsOpenEndPopover(false);
    },
    [parts]
  );

  return {
    endTextFieldProperties: {
      size,
      label: parts[1]?.text ? parts[1]?.label : "",
      value: parts[1]?.text,
      readOnly: true,
      placeholder: parts[1]?.label,
      onClick: onClickEndTextField,
      StartIcon: parts[1]?.StartIcon ?? (
        <MaterialIcon name="location_on" variant="fill" />
      )
    },
    endPopoverProperties: {
      animate: "bottom" as const,
      open: isOpenEndPopover,
      onClose: onCloseEndPopover,
      position: "end" as const
    },
    endTopAppBarProperties: {
      StartAction: (
        <Button
          EndIcon={<MaterialIcon name="arrow_forward" variant="outlined" />}
          color="neutral"
          equalDimension
          onClick={onCloseEndPopover}
          variant="text"
        />
      ),
      title: `جستجوی ${parts[1]?.label}`
    },
    endPopoverSearchFieldProperties: {
      autoFocus: true,
      placeholder: t("search.search_placeholder", { label: parts[1]?.label }),
      fullWidth: true,
      onChange: onChangeEndPopoverTextField,
      value: parts[1]?.query,
      StartIcon: parts[1]?.StartIcon ?? (
        <MaterialIcon name="location_on" variant="outlined" />
      ),
      EndIcon: parts[1]?.query ? (
        <MaterialIcon name="clear" variant="outlined" />
      ) : (
        <MaterialIcon name="search" variant="outlined" />
      ),
      onEndIconClick: parts[1]?.query ? onEmptyEndSearchField : undefined
    },
    endItemsProperties:
      parts[1]?.list.map((item: any) => ({
        ...item,
        role: "button",
        size: "large",
        onClick: () => onClickEndItem(item)
      })) || [],
    endHistoryItemsProperties:
      parts[1]?.historyList?.map((item: any) => ({
        ...item,
        role: "button",
        size: "large",
        onClick: () => onClickHistoryItem(item)
      })) || []
  };
};
