import { MaterialIcon } from "@website/icons";
import { deepCompare, numberConvert } from "@website/utils";
import { FC, memo } from "react";
import { useRoll } from "./roll.hook";
import styles from "./roll.module.scss";
import { RollPropertiesType } from "./roll.types";

const Roll: FC<RollPropertiesType> = (properties) => {
  const { list, value } = properties;
  const { upButtonProperties, downButtonProperties, scrollProperties } =
    useRoll(properties);

  return (
    <div className={styles["roll"]}>
      <div className={styles["line-top"]} />
      <div className={styles["line-bottom"]} />
      <div className={styles["roll-button"]} {...upButtonProperties}>
        <MaterialIcon name="keyboard_arrow_up" />
      </div>
      <div className={styles["roll-scroll"]} {...scrollProperties}>
        <div className={styles["roll-item"]} />
        {list.map((item, index) => (
          <div
            className={`${styles["roll-item"]} ${
              index === value ? styles["active"] : ""
            }`}
          >
            {numberConvert(item, {
              numberOnly: false,
              locale: "fa"
            })}
          </div>
        ))}
        <div className={styles["roll-item"]} />
      </div>
      <div className={styles["roll-button"]} {...downButtonProperties}>
        <MaterialIcon name="keyboard_arrow_down" />
      </div>
    </div>
  );
};

const RollMemoized = memo(Roll, deepCompare);
export { RollMemoized as Roll };
