import { MaterialIcon } from "@website/icons";
import { deepCompare, numberConvert } from "@website/utils";
import { FC, memo } from "react";
import { SearchSummaryPropertiesType } from "./types";

const SearchSummary: FC<SearchSummaryPropertiesType> = (properties) => {
  const { dateFrom, dateTo, detail, title, tripType, onClick } = properties;

  return (
    <button
      className="bg-dim-background border-outline-thin text-on-surface-high-emphasis text-subtitle-2 flex w-max max-w-[1200px] items-center gap-2 rounded-full border px-4 max-md:w-full md:gap-0 md:px-0 md:pl-[2px]"
      onClick={onClick}
      type="button"
    >
      <div className="flex w-full min-w-0 shrink max-md:flex-col">
        <h1 className="md:border-l-outline-thin max-md:text-body-2 truncate text-right max-md:mt-1.5 md:my-2 md:border-l md:px-4">
          {title}
        </h1>
        <div className="max-md:text-on-surface-medium-emphasis max-md:text-overline flex items-center whitespace-pre max-md:mb-1.5 max-md:mt-1 max-md:font-medium max-md:leading-3">
          {tripType && (
            <div className="flex items-center md:hidden">
              <div>{tripType}</div>
              <div className="whitespace-pre"> / </div>
            </div>
          )}

          <div className="md:border-l-outline-thin my-2 max-md:my-0 md:border-l md:px-4">
            {dateFrom} {dateTo && <span>- {dateTo}</span>}
          </div>
          {Array.isArray(detail) && detail.length > 0 && (
            <>
              <div className="whitespace-pre md:hidden">/ </div>
              <div className="my-2 truncate max-md:my-0 md:px-4">
                <span className="md:hidden">
                  {numberConvert(detail.join(" / "), { locale: "fa" })}
                </span>
                <span className="hidden md:block">
                  {numberConvert(detail.join("، "), { locale: "fa" })}
                </span>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="md:bg-primary text-on-surface-medium-emphasis md:text-on-color-high-emphasis flex size-6 shrink-0 items-center justify-center rounded-full text-2xl md:size-9">
        <MaterialIcon name="search" />
      </div>
    </button>
  );
};

export default memo(SearchSummary, deepCompare);
