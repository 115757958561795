import { useIsVisible } from "@website/hooks";
import { omit } from "lodash";
import { FC, memo, RefObject, useRef } from "react";
import { LazyImagePropertiesType } from "./lazy-image.types";

const LazyImage: FC<LazyImagePropertiesType> = (properties) => {
  const { src, fallback, loading = "lazy" } = properties;
  const { reference, inView } = useIsVisible({});
  const showImage = useRef<boolean>(false);

  if (inView) {
    showImage.current = true;
  }

  if (reference.current && "loading" in reference.current) {
    showImage.current = true;
  }

  return (
    <img
      {...omit(properties, "loading", "fallback", "src")}
      alt={properties?.alt}
      loading={loading}
      ref={reference as RefObject<HTMLImageElement>}
      src={showImage.current ? src : fallback}
    />
  );
};

const LazyImageMemoized = memo(LazyImage);
export { LazyImageMemoized as LazyImage };
