import { useAddRemoveQuery } from "@website/hooks";
import { useRouter } from "next/router";
import { useCallback } from "react";

export const useSupportMenu = () => {
  const router = useRouter();
  const key = "SUPPORT_MENU";
  const { [key]: isKey } = router.query;
  const { addQuery, removeQuery } = useAddRemoveQuery(key);

  const onSupportMenuOpen = useCallback(() => {
    addQuery("open");
  }, [addQuery]);

  const onSupportMenuClose = useCallback(() => {
    removeQuery();
  }, [removeQuery]);

  return {
    onSupportMenuOpen,
    onSupportMenuClose,
    supportMenuIsOpen: Boolean(isKey)
  };
};
