import { numberConvert } from "@website/utils";
import { useEffect, useRef } from "react";
import { CountDownTimerPropertiesType } from "./count-down-timer.types";

export const useCountDownTimer = (properties: CountDownTimerPropertiesType) => {
  const { seconds, onTimerEnd } = properties;
  const remainingSeconds = useRef<number>(seconds);
  const containerReference = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    let intervalId = -1;

    const makeTimeText = () => {
      const minutes = Math.floor(remainingSeconds.current / 60);
      const seconds = remainingSeconds.current - minutes * 60;
      return numberConvert(
        `${minutes.toString().padStart(2, "0")}:${seconds
          .toString()
          .padStart(2, "0")}`,
        {
          numberOnly: false,
          locale: "fa"
        }
      );
    };

    if (typeof window !== "undefined") {
      if (remainingSeconds.current > 0 && containerReference.current) {
        containerReference.current.innerHTML = makeTimeText();
      }

      intervalId = window.setInterval(() => {
        if (remainingSeconds.current > 0) {
          if (containerReference.current) {
            remainingSeconds.current -= 1;
            containerReference.current.innerHTML = makeTimeText();
          }
        } else {
          clearInterval(intervalId);
          onTimerEnd?.();
        }
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [onTimerEnd, seconds]);

  return {
    containerReference
  };
};
