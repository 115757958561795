import { AxiosResponse } from "axios";
import { AxiosNoAuth } from "../../../../utils";
import { SearchSuggestionsTourType } from "./search-suggestions.types";

export const SearchSuggestionsUrl = `${process.env.NEXT_PUBLIC_BASE_URL_TOUR_API}/listing/cities/suggestion?t=`;

export const SearchSuggestionsService = (
  target
): Promise<Array<SearchSuggestionsTourType>> =>
  AxiosNoAuth.get(target?.queryKey).then(
    ({ data }: AxiosResponse<Array<SearchSuggestionsTourType>>) => data
  );
