import { memo, SVGProps } from "react";

const SvgSwapHorizental = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    height="1em"
    viewBox="0 0 32 32"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <path d="M20 10.667c0 .736-.597 1.333-1.333 1.333l-11.448-.001 3.057 3.059a1.332 1.332 0 1 1-1.885 1.885L3.058 11.61l-.097-.109a.031.031 0 0 1-.009-.011l-.023-.029a1.075 1.075 0 0 1-.067-.099l-.028-.049a1.23 1.23 0 0 1-.041-.081l-.031-.069a.67.67 0 0 1-.028-.079l-.019-.061a1.226 1.226 0 0 1-.033-.156l-.009-.092-.004-.069-.001-.037.001.027v.011l.001.017-.001-.028-.001-.003v-.049c0-.031.003-.063.005-.093.003-.027.005-.053.009-.079l.012-.071a.848.848 0 0 1 .04-.148l.029-.08.029-.069a1.53 1.53 0 0 1 .041-.081l.028-.049a1.34 1.34 0 0 1 .067-.1l.032-.04-.004.007.004-.004.017-.021.012-.016.068-.073L8.39 4.394a1.332 1.332 0 1 1 1.885 1.885L7.218 9.336l11.448.001c.736 0 1.333.597 1.333 1.333zm8 10.665-.004.109-.011.091-.012.069c-.005.029-.013.059-.02.087-.005.021-.012.041-.019.061-.008.027-.019.052-.028.079l-.031.069a1.53 1.53 0 0 1-.041.081l-.028.048a1.144 1.144 0 0 1-.067.099l-.023.029c-.003.004-.005.008-.009.011l-.097.109-5.333 5.333a1.332 1.332 0 1 1-1.885-1.885l3.057-3.059L12 22.666A1.333 1.333 0 0 1 12 20l11.448-.001-3.057-3.056a1.332 1.332 0 1 1 1.885-1.885l5.333 5.333.092.103.037.047a.968.968 0 0 1 .067.1l.028.049c.015.027.028.053.041.081l.029.069c.011.027.02.053.029.08l.019.061c.008.029.015.057.021.087l.012.069a.952.952 0 0 1 .009.079c.003.032.004.063.005.093v.023z" />
  </svg>
);

const Memo = memo(SvgSwapHorizental);
export default Memo;
