import {
  CalendarDate,
  GregorianCalendar,
  IslamicTabularCalendar,
  PersianCalendar
} from "@internationalized/date";
import { dateFormat } from "@website/utils";
import { toNumber } from "lodash";

export const getToday = (calendarType: string) => {
  const today = new Date();
  const calendars: Record<string, string> = {
    persian: "fa",
    hijri: "ar",
    gregorian: "en"
  };
  return {
    year: toNumber(
      dateFormat(today, {
        locale: calendars[calendarType],
        template: "yyyy",
        numberConvert: false
      })
    ),
    month: toNumber(
      dateFormat(today, {
        locale: calendars[calendarType],
        template: "MM",
        numberConvert: false
      })
    ),
    day: toNumber(
      dateFormat(today, {
        locale: calendars[calendarType],
        template: "dd",
        numberConvert: false
      })
    )
  };
};

export const getDaysInMonth = (
  calendarType: string,
  year: number,
  month: number
) => {
  let date;
  let calendar;
  if (calendarType === "persian") {
    date = new CalendarDate(new PersianCalendar(), year, month, 1);
    calendar = new PersianCalendar();
  } else if (calendarType === "hijri") {
    date = new CalendarDate(new IslamicTabularCalendar(), year, month, 1);
    calendar = new IslamicTabularCalendar();
  } else {
    date = new CalendarDate(new GregorianCalendar(), year, month, 1);
    calendar = new GregorianCalendar();
  }

  return calendar.getDaysInMonth(date);
};
