import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { Button } from "..";
import { useCounterButton } from "./counter-button.hook";
import styles from "./counter-button.module.scss";
import { CounterButtonPropertiesType } from "./counter-button.types";

const CounterButton: FC<CounterButtonPropertiesType> = (properties) => {
  const {
    className,
    variant,
    decreaseDisabled,
    increaseDisabled,
    helperText,
    error,
    size = "medium"
  } = properties;
  const {
    value,
    rootProps,
    decreaseButtonProps,
    increaseButtonProps,
    valueProps
  } = useCounterButton(properties);

  return (
    <>
      <div
        {...rootProps}
        className={`${styles["counter-button"]} ${
          styles[variant ?? "contained"]
        } ${className ?? ""}`}
      >
        <Button
          {...increaseButtonProps}
          role="button"
          size={size}
          type="button"
        />
        <div {...valueProps} className={styles["value"]}>
          {value}
        </div>
        <Button
          {...decreaseButtonProps}
          role="button"
          size={size}
          type="button"
        />
      </div>
      {helperText ? (
        <div
          className={`${styles["helper-text"]} ${
            decreaseDisabled && increaseDisabled && styles.disabled
          } ${error && styles.error}`}
        >
          {helperText}
        </div>
      ) : null}
    </>
  );
};

const CounterButtonMemoized = memo(CounterButton, deepCompare);
export { CounterButtonMemoized as CounterButton };
