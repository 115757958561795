export const flight_international_visa = {
  no_result: {
    title: "No Result",
    description_first_part: "We did not find any results for",
    description_second_part: "",
    search_another_word: "Search Another Word.",
    search_count: "searched more than '{count} items'",
    but: "but"
  },
  services: {
    hotels: "Hotels",
    inter_hotels: "International Hotels",
    flights: "Flights",
    domestic_hotels: "Dom. Hotels",
    domestic_flights: "Dom. Flights",
    inter_flights: "Int. Flights",
    bus: "Bus",
    bus_domestic: "Bus Domestic",
    bus_international: "Bus International",
    stays: "Stays",
    tour: "Tour",
    train: "Train",
    visa: "Visa"
  },
  sale_type: {
    title: "Flight Type"
  },
  night_count: {
    title: "Night Count"
  },
  b2: {
    title: "Special offer",
    close: "Close",
    see_more: "See more"
  },
  special_contents: {
    show_all: "Show All",
    show_content: "Show Content"
  },
  bus: {
    main_search: {
      departure_date: "Departure Date",
      origin: "Origin",
      destination: "Destination",
      number_of_person: "Number of Person",
      number_of_passenger: "Number of passenger",
      x_passenger: "{passenger_count} passenger"
    },
    features: {
      title:
        "SnappTrip! Booking hotels, residences and villas, buying plane tickets, trains, buses and all kinds of tours",
      easy_shop: {
        title: "Easy and fast shopping",
        description:
          "The easiest process of searching, buying and refunding plane tickets"
      },
      best_price: {
        title: "Best price guarantee",
        description: "The lowest price for buying a plane ticket"
      },
      support: {
        title: "24 hour support",
        description: "Customer support service 7 days a week and holidays"
      },
      safe_choice: {
        title: "Safe choice",
        description: "Buy system and charter plane tickets safely"
      }
    },
    special_contents: {
      title: "Special Contents"
    },
    faq: {
      title: "Frequently asked questions"
    },
    inbound_links: {
      title: "Plan your next Trip"
    }
  },
  bus_international: {
    main_search: {
      departure_date: "Departure Date",
      origin: "Origin",
      destination: "Destination",
      number_of_person: "Number of Person",
      number_of_passenger: "Number of passenger",
      x_passenger: "{passenger_count} passenger"
    },
    features: {
      title:
        "SnappTrip! Booking hotels, residences and villas, buying plane tickets, trains, buses and all kinds of tours",
      easy_shop: {
        title: "Easy and fast shopping",
        description:
          "The easiest process of searching, buying and refunding plane tickets"
      },
      best_price: {
        title: "Best price guarantee",
        description: "The lowest price for buying a plane ticket"
      },
      support: {
        title: "24 hour support",
        description: "Customer support service 7 days a week and holidays"
      },
      safe_choice: {
        title: "Safe choice",
        description: "Buy system and charter plane tickets safely"
      }
    },
    special_contents: {
      title: "Special Contents"
    },
    faq: {
      title: "Frequently asked questions"
    },
    inbound_links: {
      title: "Plan your next Trip"
    }
  },
  flights: {
    main_search: {
      departure_date: "Departure Date",
      return_date: "Return Date",
      origin: "Origin",
      destination: "Destination",
      one_way: "One Way",
      round_trip: "Round Trip",
      number_of_adult: "Number of Adult",
      number_of_child: "Number of Child",
      number_of_infant: "Number of Infant",
      greather_than_n_years: "{age} years and above",
      between_m_and_n_years: "{age_one} to {age_two} years",
      number_of_passenger: "Number of passenger",
      x_passenger: "{passenger_count} passenger"
    },
    features: {
      title:
        "SnappTrip! Booking hotels, residences and villas, buying plane tickets, trains, buses and all kinds of tours",
      easy_shop: {
        title: "Easy and fast shopping",
        description:
          "The easiest process of searching, buying and refunding plane tickets"
      },
      best_price: {
        title: "Best price guarantee",
        description: "The lowest price for buying a plane ticket"
      },
      support: {
        title: "24 hour support",
        description: "Customer support service 7 days a week and holidays"
      },
      safe_choice: {
        title: "Safe choice",
        description: "Buy system and charter plane tickets safely"
      }
    },
    special_contents: {
      title: "Special Contents"
    },
    faq: {
      title: "Frequently asked questions"
    },
    popular_routes: {
      title: "Popular routes",
      subtitle:
        "To see the offers of each city, please select your destination",
      origin: "Origin",
      destination: "Destination",
      price_starts_from: "Price starts from",
      toman: "Toman"
    },
    airlines: {
      title: "Airlines"
    },
    inbound_links: {
      title: "Plan your next Trip"
    }
  },
  visa: {
    main_search: {
      check_in: "Check In",
      check_out: "Check Out",
      destination: "Destination",
      number_of_person: "Number of Person",
      number_of_passenger: "Number of passenger",
      x_passenger: "{passenger_count} passenger",
      destination_label: "Destination Label",
      your_last_search: "Your Last Search"
    },
    features: {
      title:
        "SnappTrip! Booking hotels, residences and villas, buying plane tickets, trains, buses and all kinds of tours",
      easy_shop: {
        title: "Easy and fast shopping",
        description:
          "The easiest process of searching, buying and refunding plane tickets"
      },
      best_price: {
        title: "Best price guarantee",
        description: "The lowest price for buying a plane ticket"
      },
      support: {
        title: "24 hour support",
        description: "Customer support service 7 days a week and holidays"
      },
      safe_choice: {
        title: "Safe choice",
        description: "Buy system and charter plane tickets safely"
      }
    },
    faq: {
      title: "Frequently asked questions"
    },
    inbound_links: {
      title: "Plan your next Trip"
    }
  },
  hotel: {
    main_search: {
      check_in: "Check In",
      check_out: "Check Out",
      destination: "Destination City/Hotel",
      number_of_adult: "Number of adult",
      number_of_child: "Number of child",
      number_of_room: "Number of room",
      greather_than_n_years: "{age} years and above",
      between_m_and_n_years: "{age_one} to {age_two} years",
      number_of_passenger_and_room: "Number of passanger and room",
      x_passenger_and_y_room: "{passenger_count} passenger, {room_count} room"
    },
    suggestions: {
      title: "Suggested hotels",
      subtitle:
        "To see the offers of each city, please select your destination",
      zero: "Zero",
      one: "One",
      two: "Two",
      three: "Three",
      four: "Four",
      five: "Five",
      count_stars: "{star} star",
      price_per_night: "Price per night",
      toman: "Toman"
    },
    popular_cities: {
      title: "Most popular cities in Iran"
    },
    features: {
      title:
        "SnappTrip! Booking hotels, residences and villas, buying plane tickets, trains, buses and all kinds of tours",
      easy_shop: {
        title: "Easy and fast shopping",
        description:
          "The easiest process of searching, buying and refunding plane tickets"
      },
      best_price: {
        title: "Best price guarantee",
        description: "The lowest price for buying a plane ticket"
      },
      support: {
        title: "24 hour support",
        description: "Customer support service 7 days a week and holidays"
      },
      safe_choice: {
        title: "Safe choice",
        description: "Buy system and charter plane tickets safely"
      }
    },
    special_contents: {
      title: "Special Contents"
    },
    faq: {
      title: "Frequently asked questions"
    },
    inbound_links: {
      title: "Plan your next Trip"
    }
  },
  inter_flights: {
    main_search: {
      departure_date: "Departure Date",
      return_date: "Return Date",
      origin: "Origin",
      destination: "Destination",
      one_way: "One Way",
      round_trip: "Round Trip",
      number_of_adult: "Number of Adult",
      number_of_child: "Number of Child",
      number_of_infant: "Number of Infant",
      greather_than_n_years: "{age} years and above",
      between_m_and_n_years: "{age_one} to {age_two} years",
      number_of_passenger: "Number of passenger",
      x_passenger: "{passenger_count} passenger",
      economy: "Economy",
      business: "Business",
      first_class: "First Class"
    },
    features: {
      title:
        "SnappTrip! Booking hotels, residences and villas, buying plane tickets, trains, buses and all kinds of tours",
      easy_shop: {
        title: "Easy and fast shopping",
        description:
          "The easiest process of searching, buying and refunding plane tickets"
      },
      best_price: {
        title: "Best price guarantee",
        description: "The lowest price for buying a plane ticket"
      },
      support: {
        title: "24 hour support",
        description: "Customer support service 7 days a week and holidays"
      },
      safe_choice: {
        title: "Safe choice",
        description: "Buy system and charter plane tickets safely"
      }
    },
    special_contents: {
      title: "Special Contents"
    },
    faq: {
      title: "Frequently asked questions"
    },
    inbound_links: {
      title: "Plan your next Trip"
    }
  },
  tour: {
    main_search: {
      check_in: "Check In",
      check_out: "Check Out",
      origin: "Origin",
      destination: "Destination",
      number_of_person: "Number of Person",
      number_of_passenger: "Number of passenger",
      x_passenger: "{passenger_count} passenger"
    },
    night_count: {
      label: "Night Count",
      night: "night"
    },
    features: {
      title:
        "SnappTrip! Booking hotels, residences and villas, buying plane tickets, trains, buses and all kinds of tours",
      easy_shop: {
        title: "Easy and fast shopping",
        description:
          "The easiest process of searching, buying and refunding plane tickets"
      },
      best_price: {
        title: "Best price guarantee",
        description: "The lowest price for buying a plane ticket"
      },
      support: {
        title: "24 hour support",
        description: "Customer support service 7 days a week and holidays"
      },
      safe_choice: {
        title: "Safe choice",
        description: "Buy system and charter plane tickets safely"
      }
    },
    faq: {
      title: "Frequently asked questions"
    },
    inbound_links: {
      title: "Plan your next Trip"
    }
  },
  train: {
    main_search: {},
    features: {
      title:
        "SnappTrip! Booking hotels, residences and villas, buying plane tickets, trains, buses and all kinds of tours",
      easy_shop: {
        title: "Easy and fast shopping",
        description:
          "The easiest process of searching, buying and refunding plane tickets"
      },
      best_price: {
        title: "Best price guarantee",
        description: "The lowest price for buying a plane ticket"
      },
      support: {
        title: "24 hour support",
        description: "Customer support service 7 days a week and holidays"
      },
      safe_choice: {
        title: "Safe choice",
        description: "Buy system and charter plane tickets safely"
      }
    },
    special_contents: {
      title: "Special Contents"
    },
    faq: {
      title: "Frequently asked questions"
    },
    popular_routes: {
      title: "Popular routes",
      subtitle:
        "To see the offers of each city, please select your destination",
      origin: "Origin",
      destination: "Destination",
      price_starts_from: "Price starts from",
      toman: "Toman"
    },
    inbound_links: {
      title: "Plan your next Trip"
    }
  },
  villa: {
    main_search: {
      check_in: "Check In",
      check_out: "Check Out",
      destination: "Destination",
      number_of_person: "Number of Person",
      number_of_passenger: "Number of passenger",
      x_passenger: "{passenger_count} passenger"
    },
    features: {
      title:
        "SnappTrip! Booking hotels, residences and villas, buying plane tickets, trains, buses and all kinds of tours",
      easy_shop: {
        title: "Easy and fast shopping",
        description:
          "The easiest process of searching, buying and refunding plane tickets"
      },
      best_price: {
        title: "Best price guarantee",
        description: "The lowest price for buying a plane ticket"
      },
      support: {
        title: "24 hour support",
        description: "Customer support service 7 days a week and holidays"
      },
      safe_choice: {
        title: "Safe choice",
        description: "Buy system and charter plane tickets safely"
      }
    },
    faq: {
      title: "Frequently asked questions"
    },
    inbound_links: {
      title: "Plan your next Trip"
    }
  }
};
