export const SchemaOrganization = () => (
  <script
    dangerouslySetInnerHTML={{
      __html: JSON.stringify({
        "@context": "https://schema.org/",
        "@type": "Organization",
        url: process.env.NEXT_PUBLIC_BASE_URL_B2C,
        logo: {
          "@type": "ImageObject",
          url: "/favicon.ico",
          height: "78",
          width: "137"
        },
        name: "اسنپ تریپ",
        sameAs: [
          "https://www.instagram.com/snapptrip",
          "https://twitter.com/snapptrip",
          "https://www.linkedin.com/company/snapptrip-com"
        ]
      })
    }}
    type="application/ld+json"
  />
);
