import { FC, memo } from "react";
import { Loading } from "..";
import { useInfoChips } from "./info-chips.hook";
import styles from "./info-chips.module.scss";
import { InfoChipsPropertiesType } from "./info-chips.types";

const InfoChips: FC<InfoChipsPropertiesType> = (properties) => {
  const {
    children,
    className,
    color = "primary",
    EndIcon,
    loading,
    size = "small",
    StartIcon,
    variant = "contained",
    text
  } = properties;
  const { infoChipsProps } = useInfoChips(properties);
  const classNames = `${styles["info-chips"]} ${styles[variant]} ${
    styles[color]
  } ${styles[size]} ${styles[`icon-${size}`]} ${className ?? ""}`;

  const elements = loading ? (
    <Loading color="white" size="sm" />
  ) : (
    <>
      {StartIcon ? <span className={styles["icon"]}>{StartIcon}</span> : null}
      {text ? <span className={styles["text"]}>{text}</span> : null}
      {children ? <span className={styles["children"]}>{children}</span> : null}
      {EndIcon ? <span className={styles["icon"]}>{EndIcon}</span> : null}
    </>
  );

  return (
    <div {...infoChipsProps} className={classNames}>
      {elements}
    </div>
  );
};

const InfoChipsMemoized = memo(InfoChips);
export { InfoChipsMemoized as InfoChips };
