import { useLocalStorage } from "@website/hooks";
import { History } from "@website/icons";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  HOTEL_DESTINATION_HISTORY_LOCAL_STORAGE_KEY,
  HOTEL_SEARCH_HISTORY_LOCAL_STORAGE_KEY
} from "../search-hotel.constant";

export const useHistory = (onChangeDestination: (item: any) => void) => {
  const [destinationHistory, setDestinationHistory] = useState([]);
  const { addToLocalStorage, getFromLocalStorage } = useLocalStorage();

  useEffect(() => {
    const historyString = getFromLocalStorage(
      HOTEL_DESTINATION_HISTORY_LOCAL_STORAGE_KEY
    );
    const history = historyString ? JSON.parse(historyString) : [];
    setDestinationHistory(history);
  }, [getFromLocalStorage]);

  const addToDestinationHistory = useCallback(
    (destination) => {
      const historyString = getFromLocalStorage(
        HOTEL_DESTINATION_HISTORY_LOCAL_STORAGE_KEY
      );
      const history = historyString ? JSON.parse(historyString) : [];

      let newHistory = history.filter((item) => item.id !== destination.id);

      newHistory = [destination, ...newHistory].slice(0, 2);

      addToLocalStorage(
        HOTEL_DESTINATION_HISTORY_LOCAL_STORAGE_KEY,
        JSON.stringify(newHistory)
      );
      setDestinationHistory(newHistory);
    },
    [addToLocalStorage, getFromLocalStorage]
  );

  const addToSearchHistory = useCallback(
    (
      destination: any,
      startDateTime: any,
      endDateTime: any,
      calendarLocale: string
      // passengerNumber: any
    ) => {
      const historyString = getFromLocalStorage(
        HOTEL_SEARCH_HISTORY_LOCAL_STORAGE_KEY
      );
      const history = historyString ? JSON.parse(historyString) : [];

      let newHistory = history.filter(
        (item) =>
          !(
            item.destination.id === destination.id &&
            item.startDateTime === startDateTime &&
            item.endDateTime === endDateTime
          )
      );

      const newHistoryItem = {
        destination,
        startDateTime,
        endDateTime,
        calendarLocale
        // passengerNumber
      };
      newHistory = [newHistoryItem, ...newHistory].slice(0, 4);

      addToLocalStorage(
        HOTEL_SEARCH_HISTORY_LOCAL_STORAGE_KEY,
        JSON.stringify(newHistory)
      );
    },
    [addToLocalStorage, getFromLocalStorage]
  );

  const addToHistory = useCallback(
    (
      destination: any,
      startDateTime: any,
      endDateTime: any,
      calendarLocale: string
      // passengerNumber: any
    ) => {
      addToDestinationHistory(destination);
      addToSearchHistory(
        destination,
        startDateTime,
        endDateTime,
        calendarLocale
        // passengerNumber
      );
    },
    [addToDestinationHistory, addToSearchHistory]
  );

  const onSelectDestinationHistory = useCallback(
    (id: string) => {
      if (destinationHistory) {
        let historyItem = null;
        for (const destination of destinationHistory) {
          const checkKey = `${destination.id}`;
          if (`${id}` === checkKey) {
            historyItem = destination;
            break;
          }
        }

        if (historyItem) {
          onChangeDestination(historyItem);
        }
      }
    },
    [destinationHistory, onChangeDestination]
  );

  const destinationHistoryList = useMemo(
    () =>
      destinationHistory.map((destination) => ({
        id: `${destination?.id}`,
        title: `${destination?.fa_title}`,
        subtitle:
          "accommodation_type_title" in destination
            ? destination.fa_city_title
            : destination.fa_state_title,
        StartIcon: <History />
      })),
    [destinationHistory]
  );

  return {
    destinationHistoryList,
    onSelectDestinationHistory,
    addToHistory
  };
};
