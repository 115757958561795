// import { useQuery } from "react-query";
// import {
//   SearchSuggestionsService,
//   SearchSuggestionsUrl
// } from "../../../../../services/cdn/bus/search-suggestions";
import { BUS_SUGGESTIONS } from "../search-bus.constant";

export const useSearchSuggestionsService = () => {
  // const {
  //   isLoading,
  //   data
  //   // error
  // } = useQuery(SearchSuggestionsUrl, SearchSuggestionsService);
  const isLoading = false;
  const data = BUS_SUGGESTIONS;

  return { suggestions: data, suggestionsLoading: isLoading };
};
