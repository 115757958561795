import { useViewPort } from "@website/hooks";
import { RefObject, useEffect, useState } from "react";
import { MiniPopoverPropertiesType } from "../mini-popover.types";

export const usePosition = (
  properties: MiniPopoverPropertiesType,
  popoverReference: RefObject<HTMLDivElement>
) => {
  const { defaultHorizontalPosition } = properties;
  const { isMobile } = useViewPort();

  const [horizontalPosition, setHorizontalPosition] = useState<"start" | "end">(
    defaultHorizontalPosition || "start"
  );

  // position
  useEffect(() => {
    if (!isMobile && popoverReference.current) {
      let documentDirection = "ltr";
      if (document.documentElement) {
        documentDirection = document.documentElement.dir || "ltr";
      }
      const rect = popoverReference.current.getBoundingClientRect();
      if (documentDirection === "rtl") {
        if (horizontalPosition === "start") {
          if (rect.left < 0) {
            setHorizontalPosition("end");
          }
        } else if (rect.right > window.innerWidth) {
          setHorizontalPosition("start");
        }
      } else if (horizontalPosition === "start") {
        if (rect.right > window.innerWidth) {
          setHorizontalPosition("end");
        }
      } else if (rect.left < 0) {
        setHorizontalPosition("start");
      }
    }
  }, [horizontalPosition, isMobile, popoverReference]);

  return {
    horizontalPosition
  };
};
