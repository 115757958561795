import { FC, memo } from "react";
import styles from "./count-down-timer.module.scss";
import { CountDownTimerPropertiesType } from "./count-down-timer.types";
import { useCountDownTimer } from "./use-count-down-timer";

const CountDownTimer: FC<CountDownTimerPropertiesType> = (properties) => {
  const { containerReference } = useCountDownTimer(properties);

  return <span className={styles["container"]} ref={containerReference} />;
};

const CountDownTimerMemoized = memo(CountDownTimer);
export { CountDownTimerMemoized as CountDownTimer };
