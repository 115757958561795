import { memo, SVGProps } from "react";

const SvgTripOriginSmall = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <g clipPath="url(#trip-origin-small_svg__a)">
      <path
        clipRule="evenodd"
        d="M12 5c-3.864 0-7 3.136-7 7s3.136 7 7 7 7-3.136 7-7-3.136-7-7-7Zm0 9.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="trip-origin-small_svg__a">
        <path d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(SvgTripOriginSmall);
export default Memo;
