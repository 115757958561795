import { UUIDType } from "./uuid.types";

export const uuid: UUIDType = () => {
  try {
    let d = Date.now();
    if (
      typeof performance !== "undefined" &&
      typeof performance.now === "function"
    ) {
      d += performance.now();
    }
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replaceAll(/[xy]/g, (c) => {
      // eslint-disable-next-line no-bitwise
      const r = Math.trunc((d + Math.random() * 16) % 16);
      d = Math.floor(d / 16);
      // eslint-disable-next-line no-bitwise
      return (c === "x" ? r : (r & 0b0011) | 0b1000).toString(16);
    });
  } catch {
    return "";
  }
};
