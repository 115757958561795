import { useTranslation } from "@website/locale";
import { Button } from "@website/ui";
import { deepCompare } from "@website/utils";
import { useRouter } from "next/router";
import { memo, useCallback } from "react";
import { ReserveComment } from "../reserve-comment";
import { ReserveCommentPopup } from "./reserve-comment-popup";
import { ReserveCommentsPropertiesLayoutTypes } from "./reserve-comments-layout.types";

const Component = (properties: ReserveCommentsPropertiesLayoutTypes) => {
  const router = useRouter();
  const { comments, lastReviewId, onLoadMore, isLoading, rate } = properties;
  const { t } = useTranslation();

  const modal_key = "modal";
  const open = router.query[modal_key] === "comments";

  const toggleModal = useCallback(
    (statue: boolean) =>
      statue
        ? router.push({
            pathname: router.pathname,
            query: { ...router.query, [modal_key]: "comments" }
          })
        : router.back(),
    [router]
  );

  if (comments?.length === 0) return null;

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col items-center md:flex-row md:justify-between md:gap-4">
        <div>
          <h2 className="text-subtitle-1 md:text-headline-6">
            {t("comments.passengers_comments")}
          </h2>
          <div className="text-caption text-on-surface-medium-emphasis pb-4 pt-1 md:pb-0 md:pt-2">
            {t("comments.description")}
          </div>
        </div>
        {/*<Button*/}
        {/*  color="secondary"*/}
        {/*  size="small"*/}
        {/*  variant="outline"*/}
        {/*>*/}
        {/*  ثبت دیدگاه*/}
        {/*</Button>*/}
      </div>
      {comments
        ?.slice(0, 5)
        .map((comment) => <ReserveComment key={comment?.id} {...comment} />)}
      {(comments?.length ?? 0) >= 5 && (
        <Button
          color="neutral"
          onClick={toggleModal.bind(null, true)}
          size="medium"
          variant="round-outline"
        >
          {t("comments.view_more_comments")}
        </Button>
      )}
      <ReserveCommentPopup
        comments={comments}
        isLoading={isLoading}
        lastReviewId={lastReviewId}
        onClose={toggleModal.bind(null, false)}
        onLoadMore={onLoadMore}
        open={open}
        rate={rate}
      />
    </div>
  );
};
export const ReserveCommentsLayout = memo(Component, deepCompare);
