import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { useMiniChips } from "./mini-chips.hook";
import styles from "./mini-chips.module.scss";
import { MiniChipsPropertiesType } from "./mini-chips.types";

const MiniChips: FC<MiniChipsPropertiesType> = (properties) => {
  const {
    color,
    EndIcon,
    StartIcon,
    selected,
    size,
    variant,
    text,
    className,
    textClassName,
    children,
    datatestid
  } = properties;
  const { endIconProps, rootProps } = useMiniChips(properties);

  return (
    <div
      {...rootProps}
      className={`${styles["mini-chips"]} ${
        styles[variant || "round-accent"]
      } ${styles[size || "medium"]} ${styles[color || "default"]} ${
        selected ? styles["selected"] : ""
      } ${className ?? ""}`}
      data-testid={datatestid}
    >
      {StartIcon ? (
        <div className={styles["start-icon"]}>{StartIcon}</div>
      ) : null}
      <div className={textClassName ?? styles["text"]}>{text || children}</div>
      {EndIcon ? (
        <div {...endIconProps} className={styles["end-icon"]}>
          {EndIcon}
        </div>
      ) : null}
    </div>
  );
};

const MiniChipsMemoized = memo(MiniChips, deepCompare);
export { MiniChipsMemoized as MiniChips };
