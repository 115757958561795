import { useViewPort } from "@website/hooks";
import { pick } from "lodash";
import { MouseEvent, RefObject, useCallback, useEffect, useState } from "react";
import { MiniPopoverPropertiesType } from "../mini-popover.types";

export const useShow = (
  properties: MiniPopoverPropertiesType,
  popoverReference: RefObject<HTMLDivElement>
) => {
  const { id, animate, open, onClose, onCloseButton, onClickBackDrop } =
    properties;
  const { isMobile, isTablet } = useViewPort();

  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
  const [animateOpen, setAnimateOpen] = useState<boolean>(false);

  useEffect(() => {
    if (isMobile || isTablet) {
      if (open) {
        document.body.classList.add("prevent-scroll");
      } else {
        document.body.classList.remove("prevent-scroll");
      }
    }

    return () => {
      document.body.classList.remove("prevent-scroll");
    };
  }, [open, isMobile, isTablet]);

  useEffect(() => {
    let timeoutId1: NodeJS.Timeout | null = null;
    let timeoutId2: NodeJS.Timeout | null = null;

    const checkIfClickedOutside: EventListenerOrEventListenerObject = ({
      target
    }) => {
      if (
        open &&
        popoverReference.current &&
        !popoverReference.current.contains(target as Node)
      ) {
        onClose();
      }
    };
    if (document) {
      if (open) {
        document.addEventListener("click", checkIfClickedOutside);
        setPopoverOpen(true);
        timeoutId1 = setTimeout(() => {
          setAnimateOpen(true);
        }, 0);
      } else {
        document.removeEventListener("click", checkIfClickedOutside);
        setAnimateOpen(false);
        if (timeoutId1) {
          clearTimeout(timeoutId1);
        }
        timeoutId2 = setTimeout(
          () => {
            setPopoverOpen(false);
          },
          animate === "normal" ? 0 : 150
        );
      }
    }
    return () => {
      if (document) {
        document.removeEventListener("click", checkIfClickedOutside);
      }
      if (timeoutId1) {
        clearTimeout(timeoutId1);
      }
      if (timeoutId2) {
        clearTimeout(timeoutId2);
      }
    };
  }, [animate, onClose, open, popoverReference]);

  const onCloseButtonClick = useCallback(
    (event: MouseEvent<SVGSVGElement>) => {
      event.stopPropagation();
      onClose();
      onCloseButton && onCloseButton();
    },
    [onClose, onCloseButton]
  );

  const onBackdropClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      onClose();
      onClickBackDrop && onClickBackDrop();
    },
    [onClickBackDrop, onClose]
  );

  const stopPropagationOnModal = useCallback((event: MouseEvent) => {
    event.stopPropagation();
  }, []);

  return {
    popoverProperties: {
      ...pick(properties, "aria-labelledby", "aria-describedby"),
      "aria-modal": true,
      onClick: stopPropagationOnModal,
      role: "dialog",
      tabIndex: 0,
      id
    },
    transitionProps: {
      timeout: animate === "normal" ? 0 : 75
    },
    closeButtonProps: {
      onClick: onCloseButtonClick,
      "data-testid": "popover-close-button"
    },
    backdrop: {
      onClick: onBackdropClick
    },
    popoverOpen,
    animateOpen
  };
};
