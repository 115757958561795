import { Button } from "@website/ui";
import { FC } from "react";
import styles from "./index.module.scss";
import { FilterCollapseType, useFilterCollapse } from "./useCollapse";

export const FilterCollapse: FC<FilterCollapseType> = (properties) => {
  const { active = false } = properties;
  const {
    children,
    containerHeight,
    allElementHeight,
    showAll,
    containerReference,
    buttonProps
  } = useFilterCollapse(properties);

  return active ? (
    <>
      <div
        className={`${styles["container"]}`}
        ref={containerReference}
        style={{ maxHeight: showAll ? allElementHeight : containerHeight }}
      >
        {children}
      </div>
      <Button {...buttonProps} />
    </>
  ) : (
    children
  );
};
