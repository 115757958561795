import { useDebounce } from "@website/hooks";
import { Airport, Origin } from "@website/icons";
import { useTranslation } from "@website/locale";
import { useCallback, useMemo, useState } from "react";
import { NoResult } from "../../../..";
import styles from "../search-inter-flights.module.scss";
import {
  useAirportsCitiesService,
  useSearchSuggestionsService
} from "../services";
import {
  createOriginDestinationInputText,
  createOriginDestinationItemSubtitle
} from "../utils";

export const useOrigin = () => {
  const { t } = useTranslation();
  const [originQuery, setOriginQuery] = useState<string>("");
  const [selectedOriginItem, setSelectedOriginItem] = useState(null);
  const query = useDebounce(originQuery, 250);

  const {
    suggestions
    // suggestionsLoading
  } = useSearchSuggestionsService();
  const { isLoading, data } = useAirportsCitiesService(query);

  const onChangeOriginQuery = useCallback((value: string) => {
    setOriginQuery(value);
  }, []);

  const originList = useMemo(
    () => (originQuery.length >= 3 ? data || [] : suggestions || []),
    [data, suggestions, originQuery]
  );

  const originListNormalized = useMemo(() => {
    const list = [];
    for (const city of originList) {
      if (city.includeAllAirports) {
        list.push({
          id: `${city.iataCode}-${city.cityName}`,
          title: `${city.cityFaName}، ${city.countryFaName}`,
          subtitle: createOriginDestinationItemSubtitle(city),
          StartIcon: (
            <div className={styles["origin-icon"]}>
              <Origin />
            </div>
          ),
          indent: 0
        });
      }
      if (
        (city.airports?.length > 1 && !city?.isSuggested) ||
        city.includeAllAirports === false
      ) {
        for (const airport of city.airports) {
          list.push({
            id: `${airport.iataCode}-${airport.name}-${city.cityName}`,
            title: airport.faName || `فرودگاه  ${airport.name}`,
            subtitle: `${city.cityFaName} - ${airport.iataCode}`,
            indent: city.includeAllAirports ? 1 : 0,
            StartIcon: <Airport />
          });
        }
      }
    }
    return list;
  }, [originList]);

  const selectedOriginText = useMemo(
    () => createOriginDestinationInputText(selectedOriginItem),
    [selectedOriginItem]
  );

  const onSelectOrigin = useCallback(
    (id: string) => {
      let originItem;
      originItem = originList.find(
        (origin: any) => `${origin.iataCode}-${origin.cityName}` === `${id}`
      );
      if (!originItem) {
        for (const city of originList) {
          for (const airport of city.airports) {
            if (
              `${airport.iataCode}-${airport.name}-${city.cityName}` === `${id}`
            ) {
              originItem = {
                airportName: airport.name,
                airportFaName: airport.faName,
                airportIataCode: airport.iataCode,
                ...city
              };
            }
          }
        }
      }
      setOriginQuery("");
      setSelectedOriginItem(originItem);
    },
    [originList]
  );

  const onChangeOrigin = useCallback((item: any) => {
    setOriginQuery("");
    setSelectedOriginItem(item);
  }, []);

  return {
    selectedOriginItem,
    // selectedOriginText: selectedOriginItem?.isCity
    //   ? "تمام فرودگاه های " + selectedOriginItem.cityName
    //   : selectedOriginItem?.cityName
    //   ? selectedOriginItem.cityName + "," + selectedOriginItem.countryName
    //   : "",
    selectedOriginText,
    originQuery,
    onChangeOrigin,
    onSelectOrigin,
    onChangeOriginQuery,
    originList,
    originLoading: isLoading,
    originListNormalized,
    OriginNoResult:
      originQuery?.length >= 2 && !isLoading && data && data.length === 0 ? (
        <NoResult count="14,000" query={originQuery} />
      ) : undefined,
    originLabel: t("homepage.inter_flights.main_search.origin")
  };
};
