import { FC, memo } from "react";
import styles from "./badge.module.scss";
import { BadgePropertiesType } from "./badge.types";

const Badge: FC<BadgePropertiesType> = (properties) => {
  const { content, color = "primary" } = properties;

  return (
    <span
      className={`${styles["container"]} ${styles[color]} ${
        content ? styles["fill"] : ""
      }`}
    >
      {content}
    </span>
  );
};

const BadgeMemoized = memo(Badge);
export { BadgeMemoized as Badge };
