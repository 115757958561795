import { ChangeEvent, useCallback, useState } from "react";

export const useCode = () => {
  const [code, setCode] = useState<string>("");

  const onCodeChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setCode(event.target.value);
  }, []);

  const onCodeClear = useCallback(() => {
    setCode("");
  }, []);

  return {
    code,
    onCodeChange,
    onCodeClear
  };
};
