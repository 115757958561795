import { MiniChipsPropertiesType } from "./mini-chips.types";

export const useMiniChips = (properties: MiniChipsPropertiesType) => {
  const { onClick } = properties;

  return {
    rootProps: {
      ...(onClick && { role: "button", onClick, tabIndex: 0 })
    },
    endIconProps: {}
  };
};
