export enum INTERNATIONAL_HOTEL_ERROR {
  INTERNAL = "INTERNAL",
  INVALID_REQUEST = "INVALID_REQUEST",
  EXPIRED_SEARCH = "EXPIRED_SEARCH",
  ALREADY_BOOKED = "ALREADY_BOOKED",
  GET_POLICIES_NOT_CALLED = "GET_POLICIES_NOT_CALLED",
  INVALID_TITLE = "INVALID_TITLE",
  INVALID_HOTELIAN_CODE = "INVALID_HOTELIAN_CODE",
  RESOURCE_NOT_FOUND = "RESOURCE_NOT_FOUND",
  ROOM_SOLD_OUT = "ROOM_SOLD_OUT",
  INVALID_PHONE_NUMBER = "INVALID_PHONE_NUMBER",
  INVALID_CHECKIN_DATE = "INVALID_CHECKIN_DATE",
  INVALID_CHECKOUT_DATE = "INVALID_CHECKOUT_DATE",
  ROOMS_NO_EXCEEDED = "ROOMS_NO_EXCEEDED",
  DISCOUNT_CODE_EXPIRED = "DISCOUNT_CODE_EXPIRED",
  DISCOUNT_CODE_NOT_FOUND = "DISCOUNT_CODE_NOT_FOUND",
  DISCOUNT_CODE_MIN_STAY_NOT_REACHED = "DISCOUNT_CODE_MIN_STAY_NOT_REACHED",
  DISCOUNT_CODE_USED = "DISCOUNT_CODE_USED",
  DISCOUNT_CODE_MIN_PRICE_NOT_REACHED = "DISCOUNT_CODE_MIN_PRICE_NOT_REACHED",
  DISCOUNT_CODE_MOST_PRICE_NOT_REACHED = "DISCOUNT_CODE_MOST_PRICE_NOT_REACHED",
  DISCOUNT_CODE_NOT_ALLOWED = "DISCOUNT_CODE_NOT_ALLOWED",
  DISCOUNT_CODE_NOT_MATCHED_CONDITION = "DISCOUNT_CODE_NOT_MATCHED_CONDITION",
  DISCOUNT_ALREADY_USED = "DISCOUNT_ALREADY_USED",
  NO_HOTEL_FOUND = "NO_HOTEL_FOUND"
}
