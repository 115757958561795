import { memo, SVGProps } from "react";

const SvgClose = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    height="1em"
    viewBox="0 0 32 32"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <path d="m16 14.115 8.391-8.391a1.332 1.332 0 1 1 1.885 1.885L17.885 16l8.391 8.391a1.332 1.332 0 1 1-1.885 1.885L16 17.885l-8.391 8.391a1.332 1.332 0 1 1-1.885-1.885L14.115 16 5.724 7.609a1.332 1.332 0 1 1 1.885-1.885L16 14.115z" />
  </svg>
);

const Memo = memo(SvgClose);
export default Memo;
