import { useTranslation } from "@website/locale";
import { AutoComplete, Button, Datepicker } from "@website/ui-old";
import { FC, useCallback, useState } from "react";
import { CoupeType, PassengerCount, SaleType, SalonType } from "../../..";
import { TrainHint } from "../../../train-hint";
import { useCoupeType } from "./hooks/use-coupe-type";
import { useDate } from "./hooks/use-date";
import { useDestination } from "./hooks/use-destination";
import { useHistory } from "./hooks/use-history";
import { useOrigin } from "./hooks/use-origin";
import { usePassengerCount } from "./hooks/use-passenger-count";
import { useSaleType } from "./hooks/use-sale-type";
import { useSalonType } from "./hooks/use-salon-type";
import { useSubmit } from "./hooks/use-submit";
import { useValidation } from "./hooks/use-validation";
import styles from "./search-train.module.scss";
import { SearchTrainPropertiesType } from "./search-train.types";

const SearchTrain: FC<SearchTrainPropertiesType> = (properties) => {
  const {
    selectedOriginItem,
    selectedOriginText,
    originQuery,
    onChangeOrigin,
    onChangeOriginQuery,
    onSelectOrigin,
    originLoading,
    originListNormalized,
    originLabel,
    OriginNoResult
  } = useOrigin();
  const { saleType, saleTypeList, onChangeSaleType, onChangeDatepickerMode } =
    useSaleType();
  const { coupeType, coupeTypeList, onChangeCoupeType } = useCoupeType();
  const { salonType, salonTypeList, onChangeSalonType } = useSalonType();
  const { date, calendarLocale, onChangeDate, fromTitle, toTitle } = useDate();

  const {
    onSubmitPassengerCount,
    passengerCount,
    passengerCountList,
    passengerCountResult,
    passengerCountTitle,
    onClosePassengerButton,
    onClickBackDropHandler
  } = usePassengerCount();
  const {
    selectedDestinationItem,
    selectedDestinationText,
    destinationQuery,
    onChangeDestination,
    onChangeDestinationQuery,
    onSelectDestination,
    destinationLoading,
    destinationListNormalized,
    destinationLabel,
    DestinationNoResult
  } = useDestination();
  const { routeHistoryList, addToHistory, onSelectRouteHistory } = useHistory(
    onChangeOrigin,
    onChangeDestination
  );
  const { originError, destinationError, dateError, submitValidationCheck } =
    useValidation({
      originItem: selectedOriginItem,
      destinationItem: selectedDestinationItem,
      saleType,
      date
    });
  const {
    onSubmitClick,
    // submitLoading,
    submitText
  } = useSubmit(
    selectedOriginItem,
    selectedDestinationItem,
    saleType,
    date,
    calendarLocale,
    passengerCount,
    salonType,
    coupeType,
    addToHistory,
    submitValidationCheck,
    properties
  );
  const { t } = useTranslation();

  const onSwap = useCallback(() => {
    onChangeOrigin(selectedDestinationItem);
    onChangeDestination(selectedOriginItem);
  }, [
    onChangeOrigin,
    selectedDestinationItem,
    onChangeDestination,
    selectedOriginItem
  ]);
  const [foriegnPeople, setForiegnPeople] = useState(true);

  const onHandleForiegnPeople = useCallback(() => {
    setForiegnPeople(false);
  }, []);

  return (
    <div className={styles["container"]}>
      {foriegnPeople && (
        <TrainHint
          onHandleForiegnPeople={onHandleForiegnPeople}
          style="flex md:hidden"
        />
      )}
      <div className={styles["options"]}>
        <div className={styles["filters"]}>
          <SaleType
            list={saleTypeList}
            name="train-sale-type"
            onChange={onChangeSaleType}
            title={t("homepage.train.sale_type.title")}
            value={saleType}
          />
          <PassengerCount
            list={passengerCountList}
            onClickBackDropHandler={onClickBackDropHandler}
            onClosePassengerButton={onClosePassengerButton}
            onSubmit={onSubmitPassengerCount}
            result={passengerCountResult}
            title={passengerCountTitle}
          />
          <CoupeType
            list={coupeTypeList}
            name="train-coupe-type"
            onChange={onChangeCoupeType}
            value={coupeType}
          />
          <SalonType
            list={salonTypeList}
            name="train-salon-type"
            onChange={onChangeSalonType}
            value={salonType}
          />
        </div>
      </div>
      <div className={styles["main"]}>
        <div className={styles["inputs-wrapper"]}>
          <div className={styles["autocomplete-wrapper"]}>
            <AutoComplete
              error={originError || destinationError}
              fullWidth
              onSwap={onSwap}
              parts={[
                {
                  label: originLabel,
                  list: originListNormalized,
                  historyList: originQuery.length <= 1 ? routeHistoryList : [],
                  historyTitle: t(
                    "homepage.train.main_search.your_last_searches"
                  ),
                  text: selectedOriginText,
                  onSelect: onSelectOrigin,
                  onSelectHistory: onSelectRouteHistory,
                  onChangeQuery: onChangeOriginQuery,
                  query: originQuery,
                  loading: originLoading,
                  NoResult: OriginNoResult
                },
                {
                  label: destinationLabel,
                  list: destinationListNormalized,
                  historyList:
                    destinationQuery.length <= 1 ? routeHistoryList : [],
                  historyTitle: t(
                    "homepage.train.main_search.your_last_searches"
                  ),
                  text: selectedDestinationText,
                  onSelect: onSelectDestination,
                  onSelectHistory: onSelectRouteHistory,
                  onChangeQuery: onChangeDestinationQuery,
                  query: destinationQuery,
                  loading: destinationLoading,
                  NoResult: DestinationNoResult
                }
              ]}
            />
          </div>
          <div className={styles["datepicker-wrapper"]}>
            <Datepicker
              changeableMode
              error={dateError.length > 0}
              fromTitle={fromTitle}
              fullWidth
              helperText={dateError}
              mode={saleType === "one-way" ? "single" : "same-start-end"}
              onChange={onChangeDate}
              secondaryMode="same-start-end"
              setMode={onChangeDatepickerMode}
              toTitle={toTitle}
              value={date}
            />
          </div>
        </div>
        <div className={styles["button-wrapper"]}>
          <Button
            aria-label="search"
            fullWidth
            // loading={submitLoading}
            onClick={onSubmitClick}
            size="x-large"
          >
            {submitText}
          </Button>
        </div>
      </div>
    </div>
  );
};

export { SearchTrain };
