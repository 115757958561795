import {
  CalendarDate,
  GregorianCalendar,
  PersianCalendar,
  parseDate
} from "@internationalized/date";
import { DayType, MonthType, gregorianToShamsi } from "@website/utils";
import { useCallback, useEffect, useState } from "react";
import {
  DatepickerSplitPropertiesType,
  DatepickerSplitSelectedDateObjectType
} from "../datepicker-split.types";
import { getDaysInMonth } from "../datepicker-split.utils";

export const useDate = (properties: DatepickerSplitPropertiesType) => {
  const { onChange, value, calendarLocale = "persian" } = properties;
  const [selectedDateObject, setSelectedDateObject] =
    useState<DatepickerSplitSelectedDateObjectType>({
      year: null,
      month: null,
      day: null
    });

  useEffect(() => {
    // initial value
    if (value) {
      const newDate = parseDate(value);
      let newDateConverted: CalendarDate;

      if (calendarLocale === "persian") {
        // @internationalize/date has issue with
        // convert from gregorian to persian.
        // this code fix that issue.
        // update it later.
        const temporaryDateConverted = gregorianToShamsi(
          newDate.year,
          newDate.month as MonthType,
          newDate.day as DayType
        );
        newDateConverted = new CalendarDate(
          new PersianCalendar(),
          temporaryDateConverted[0],
          temporaryDateConverted[1],
          temporaryDateConverted[2]
        );
      } else {
        newDateConverted = newDate;
      }

      setSelectedDateObject({
        year: `${newDateConverted.year}`,
        month: `${newDateConverted.month}`,
        day: `${newDateConverted.day}`
      });
    }
  }, [calendarLocale, selectedDateObject.year, value]);

  const onDayChange = useCallback(
    (day: string) => {
      const { year, month } = selectedDateObject;

      setSelectedDateObject({
        ...selectedDateObject,
        day
      });

      if (year && month) {
        const calendar =
          calendarLocale === "persian"
            ? new CalendarDate(
                new PersianCalendar(),
                Number(year),
                Number(month),
                Number(day)
              )
            : new CalendarDate(
                new GregorianCalendar(),
                Number(year),
                Number(month),
                Number(day)
              );
        onChange(calendar.toString());
      }
    },
    [calendarLocale, onChange, selectedDateObject]
  );

  const onMonthChange = useCallback(
    (month: string) => {
      const { year, day } = selectedDateObject;

      let emptyDay: boolean = false;
      if (day) {
        const daysInMonth: number = getDaysInMonth(
          calendarLocale,
          Number(year) || 1,
          Number(month)
        );
        emptyDay = Number(day) > daysInMonth;
      }
      setSelectedDateObject({
        ...selectedDateObject,
        month,
        ...(emptyDay ? { day: null } : {})
      });

      // if all fileds are available, call on-change
      if (emptyDay) {
        onChange("");
      } else if (year && day) {
        const calendar =
          calendarLocale === "persian"
            ? new CalendarDate(
                new PersianCalendar(),
                Number(year),
                Number(month),
                Number(day)
              )
            : new CalendarDate(
                new GregorianCalendar(),
                Number(year),
                Number(month),
                Number(day)
              );
        onChange(calendar.toString());
      }
    },
    [selectedDateObject, calendarLocale, onChange]
  );

  const onYearChange = useCallback(
    (year: string) => {
      const { month, day } = selectedDateObject;

      let emptyDay: boolean = false;
      if (day && month) {
        const daysInMonth: number = getDaysInMonth(
          calendarLocale,
          Number(year),
          Number(month)
        );
        emptyDay = Number(day) > daysInMonth;
      }
      setSelectedDateObject({
        ...selectedDateObject,
        year,
        ...(emptyDay ? { day: null } : {})
      });

      // if all fileds are available, call on-change
      if (emptyDay) {
        onChange("");
      } else if (day && month) {
        const calendar =
          calendarLocale === "persian"
            ? new CalendarDate(
                new PersianCalendar(),
                Number(year),
                Number(month),
                Number(day)
              )
            : new CalendarDate(
                new GregorianCalendar(),
                Number(year),
                Number(month),
                Number(day)
              );
        onChange(calendar.toString());
      }
    },
    [calendarLocale, onChange, selectedDateObject]
  );

  return {
    selectedDateObject,
    onDayChange,
    onMonthChange,
    onYearChange
  };
};
