import { pick } from "lodash";
import { useCallback, useEffect, useRef } from "react";
import { TooltipPropertiesType } from "./tooltip.types";

export const useTooltip = (properties: TooltipPropertiesType) => {
  const { title } = properties;
  const contentReference = useRef<HTMLDivElement>(null);

  const escFunction = useCallback((event: KeyboardEvent) => {
    if (event.key === "Escape" && contentReference.current) {
      contentReference.current.blur();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction);

    return () => {
      document.removeEventListener("keydown", escFunction);
    };
  }, [escFunction]);

  return {
    tooltipProperties: {
      ref: contentReference,
      "aria-label": title,
      tabIndex: 1
    },
    titleProperties: {
      ...pick(properties, "id"),
      role: "tooltip"
    }
  };
};
