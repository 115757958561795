import { SVGProps, memo } from "react";

const SvgLodgingPin = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    // fill="none"
    height="1em"
    viewBox="0 0 25 25"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <circle
      cx={12.439}
      cy={12.469}
      r={10}
      transform="rotate(.172 12.439 12.469)"
    />
    <path d="M12.412 21.469a9 9 0 1 0 .054-18 9 9 0 0 0-.054 18Z" />
    <path
      clipRule="evenodd"
      d="M15.907 9.787a2.016 2.016 0 0 1 2.01 2.023l-.004 1.42c0 .013-.003.024-.006.036-.003.01-.006.02-.007.03l-.008 2.65a.256.256 0 0 1-.257.256l-.834-.003a.256.256 0 0 1-.255-.256l.005-1.805-8.14-.024a.225.225 0 0 1-.062-.012c-.015-.004-.03-.008-.046-.01l-.005 1.686a.256.256 0 0 1-.257.255l-.834-.002a.256.256 0 0 1-.256-.257l.02-6.784c.001-.142.116-.256.258-.255l.834.002c.141 0 .256.116.255.257l-.008 2.66c0 .127.104.231.232.232l3.797.011a.233.233 0 0 0 .233-.232l.005-1.544c0-.19.155-.343.344-.343l2.986.01Zm-4.87.307a1.229 1.229 0 1 1-2.458-.008 1.229 1.229 0 0 1 2.458.008Z"
      fill="#fff"
      fillRule="evenodd"
    />
  </svg>
);

const Memo = memo(SvgLodgingPin);
export default Memo;
