import { useIsVisible } from "@website/hooks";
import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { deepCompare, numberConvert } from "@website/utils";
import { FC, memo } from "react";
import { Button } from "../button";
import { useStepper } from "./stepper.hook";
import styles from "./stepper.module.scss";
import { StepperProperties } from "./stepper.types";

const Stepper: FC<StepperProperties> = (properties) => {
  const {
    className,
    completed,
    "aria-label": ariaLabel,
    previousButton
  } = properties;
  const { t, locale } = useTranslation();
  const { stepperProperties, onGoBack } = useStepper(properties);
  const { inView, reference } = useIsVisible({});

  return (
    <>
      <div ref={reference as React.RefObject<HTMLImageElement>} />
      <div
        className={`${styles["stepper-container"]} ${
          inView ? "" : styles["stepper-container__has-shadow"]
        } ${className}`}
      >
        <div
          aria-label={ariaLabel}
          className={`${styles["stepper-container__stepper"]}`}
          role="tablist"
        >
          {previousButton && (
            <Button
              StartIcon={
                <MaterialIcon
                  className={
                    styles["stepper-container__stepper__back-button-icon"]
                  }
                  name="arrow_forward"
                  variant="outlined"
                />
              }
              className={styles["stepper-container__stepper__back-button"]}
              color="neutral"
              onClick={onGoBack}
              variant="text"
            >
              {t("global.back")}
            </Button>
          )}
          <div className={styles["stepper-container__stepper__steps"]}>
            {stepperProperties.steps?.map((step, index: number) => (
              <div
                aria-label={step.label}
                className={`${
                  styles["stepper-container__stepper__steps__step"]
                } ${
                  stepperProperties.active === index
                    ? styles["stepper-container__stepper__steps__step__active"]
                    : ""
                } ${
                  index < stepperProperties.active || completed
                    ? styles[
                        "stepper-container__stepper__steps__step__completed"
                      ]
                    : ""
                }`}
                key={`${step.label}`}
                role="tab"
              >
                <div
                  className={
                    styles[
                      "stepper-container__stepper__steps__step__label-wrapper"
                    ]
                  }
                >
                  <div
                    className={
                      styles[
                        "stepper-container__stepper__steps__step__label-wrapper__count"
                      ]
                    }
                  >
                    {numberConvert(index + 1, {
                      numberOnly: true,
                      locale
                    })}
                  </div>
                  <div
                    className={
                      styles[
                        "stepper-container__stepper__steps__step__label-wrapper__label"
                      ]
                    }
                  >
                    {step.label}
                  </div>
                </div>
                <div
                  className={
                    styles["stepper-container__stepper__steps__step__line"]
                  }
                />
              </div>
            ))}
          </div>
          {previousButton && (
            <div className={styles["stepper-container__stepper__offset-el"]} />
          )}
        </div>
      </div>
    </>
  );
};

const StepperMemoized = memo(Stepper, deepCompare);
export { StepperMemoized as Stepper };
