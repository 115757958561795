import { pick } from "lodash";
import { MouseEvent, RefObject, useCallback, useEffect, useState } from "react";
import { MiniPopoverPropertiesType } from "../mini-popover.types";

export const useShow = (
  properties: MiniPopoverPropertiesType,
  popoverReference: RefObject<HTMLDivElement>
) => {
  const { id, animate, open, onClose } = properties;

  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
  const [animateOpen, setAnimateOpen] = useState<boolean>(false);

  useEffect(() => {
    const checkIfClickedOutside: EventListenerOrEventListenerObject = ({
      target
    }) => {
      if (
        open &&
        popoverReference.current &&
        !popoverReference.current.contains(target as Node)
      ) {
        onClose();
      }
    };

    if (document) {
      if (open) {
        document.addEventListener("click", checkIfClickedOutside);
        setPopoverOpen(true);
        setTimeout(() => {
          setAnimateOpen(true);
        }, 0);
      } else {
        document.removeEventListener("click", checkIfClickedOutside);
        setAnimateOpen(false);
        setTimeout(
          () => {
            setPopoverOpen(false);
          },
          animate === "normal" ? 0 : 150
        );
      }
    }

    return () => {
      if (document) {
        document.removeEventListener("click", checkIfClickedOutside);
      }
    };
  }, [animate, onClose, open, popoverReference]);

  const onCloseButtonClick = useCallback(
    (event: MouseEvent<HTMLSpanElement>) => {
      event.stopPropagation();
      onClose();
    },
    [onClose]
  );

  const stopPropagationOnModal = useCallback((event: MouseEvent) => {
    event.stopPropagation();
  }, []);

  return {
    popoverProperties: {
      ...pick(properties, "aria-labelledby", "aria-describedby"),
      "aria-modal": true,
      onClick: stopPropagationOnModal,
      role: "dialog",
      tabIndex: 0,
      id
    },
    transitionProps: {
      timeout: animate === "normal" ? 0 : 75
    },
    closeButtonProps: {
      onClick: onCloseButtonClick
    },
    popoverOpen,
    animateOpen
  };
};
