import { omit } from "lodash";
import { LinkPropertiesType } from "./link.types";

export const useLink = (properties: LinkPropertiesType) => {
  const { disabled, href } = properties;

  return {
    rootProperties: {
      ...omit(properties, "children", "className", "color", "disabled", "href"),
      role: "link",
      ...(disabled ? { "aria-disabled": true } : { href, tabIndex: 0 })
    }
  };
};
