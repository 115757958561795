import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { useCollapsibleTextItem } from "./collapsible-text.hook";
import styles from "./collapsible-text.module.scss";
import { CollapsibleTextPropertiesType } from "./collapsible-text.types";

const CollapsibleText: FC<CollapsibleTextPropertiesType> = (properties) => {
  const { regionProperties, expanded, buttonProperties, collapsible } =
    useCollapsibleTextItem(properties);
  const { content, title, moreText, lessText } = properties;

  return (
    <div className={styles["item"]}>
      <h2 className={styles["title"]}>{title}</h2>
      <div
        {...regionProperties}
        className={`${styles["content"]} ${
          collapsible ? styles["collapsible"] : ""
        } ${expanded ? styles["expanded"] : ""}`}
      >
        {content}
      </div>

      {collapsible ? (
        <button {...buttonProperties} className={styles["button"]}>
          {expanded ? (
            <span>{lessText || "نمایش کمتر"}</span>
          ) : (
            <span>{moreText || "نمایش بیشتر"}</span>
          )}
        </button>
      ) : null}
    </div>
  );
};

const CollapsibleTextMemoized = memo(CollapsibleText, deepCompare);
export { CollapsibleTextMemoized as CollapsibleText };
