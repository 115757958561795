declare global {
  // noinspection JSUnusedGlobalSymbols
  interface Window {
    webengage: {
      user: {
        setAttribute: (name: string, value: unknown) => void;
        login: (data: unknown) => void;
      };
      track: (eventName: string, properties?: Record<string, unknown>) => void;
    };
    dataLayer: Array<{ event: string & unknown; ecommerce: object } | object>;
  }
}

export type AddEventToWebengageType = (
  event: WEBENGAGE_EVENTS,
  data: Record<string, unknown>
) => void;

export type WebengageLoginType = (guestUserPhone?: string | number) => void;

export type AddEventToDataLayerType = (
  event: DATA_LAYER_EVENTS,
  dataLayerData: Record<string, unknown>
) => void;

export enum WEBENGAGE_EVENTS {
  HOME_PAGE_LAND = "IF_homePageLanded",

  CITY_VISIT = "hotel-cityVisit",
  HOTEL_VISIT = "hotel-hotelVisit",
  CATEGORY_VISIT = "category-page-hotel",
  PAY_BANNER_IMPRESSION = "pay_banner_impression",
  HOTEL_RESERVATION = "hotel-reservation",
  HOTEL_TO_PAY = "hotel-toPay",
  IH_cityVisit = "IH-cityVisit",
  IH_hotelVisit = "IH-hotelVisit",
  IH_reservation = "IH_reservation",
  IH_confirmInfo = "IH-confirmInfo",
  IH_checkOutComplete = "IH-checkOutComplete",
  IH_Fulfilled = "IH-Fulfilled",

  TOUR_ST_SEARCH = "Tour_ST_Search",
  TOUR_ST_SELECT_SOLUTION = "Tour_ST_Select_Solution",
  TOUR_ST_HOTEL_SELECTED = "Tour_ST_Hotel_Selected",
  TOUR_ST_HOTEL_DETAILS = "Tour_ST_Hotel_Details",
  TOUR_ST_HOTEL_DETAILS_LOCATION = "Tour_ST_Hotel_Details_Location",
  TOUR_ST_HOTEL_DETAILS_RATE_REVIEW = "Tour_ST_Hotel_Details_Rate&Review",
  TOUR_ST_HOTEL_DETAILS_FACILITIES = "Tour_ST_Hotel_Details_Facilities",
  TOUR_ST_SUGGESTION_CLICKED = "Tour_ST_Suggestion_clicked",
  TOUR_ST_SUGGESTED = "Tour_ST_Suggested",
  FLIGHT_INTL_PRICE_TABLE_LOADED = "IF_priceTableLoaded",
  FLIGHT_INTL_SEARCH = "IF_search",
  FLIGHT_INTL_SELECT_SOLUTION = "IF_selectSolution",
  FLIGHT_INTL_EMPTY_SEARCH = "IF_empty_search",
  FLIGHT_INTL_PRICE_CALENDAR = "IF_priceCalendarLoaded",
  FLIGHT_INTL_VISA_SEARCH = "IF_visaSearch",
  FLIGHT_INTL_VISA_SUBMIT = "IF_visaSubmitted"
}

export enum USER_TRACKING_EVENTS {
  EXPERIMENT_VIEWD = "experiment_viewed",
  CITY_PAGE_VISIT = "city_page_visit",
  CATEGORY_PAGE_VISIT = "category_page_visit",
  HOTEL_PAGE_VISIT = "hotel_page_visit",
  ROOM_SELECTED = "room_selected",
  HOTEL_BOOKING_FREE_TRANSFER = "free_transfer_form_filled",
  SNAPPPAY_TERMS_VIEWED = "snapppay_terms_viewed",
  SNAPPPAY_TERMS_DECLINED = "snapppay_terms_declined",
  SNAPPPAY_TERMS_ACCEPTED = "snapppay_terms_accepted",
  SNAPPPAY_SELECTED = "snapppay_selected",

  TOUR_ST_HOTEL_DETAILS_LOCATION = "Tour_ST_Hotel_Details_Location",
  TOUR_ST_HOTEL_DETAILS_RATE_REVIEW = "Tour_ST_Hotel_Details_Rate&Review",
  TOUR_ST_HOTEL_DETAILS_FACILITIES = "Tour_ST_Hotel_Details_Facilities",
  CHANGE_ROOM_FLIGHT_PAGE_VISITED = "change_room_flight_page_visited",
  PASSENGERS_PAGE_VISITED = "passengers_page_visited",
  INFO_CONFIRMED = "info_confirmed",
  TOUR_HOTEL_SELECT_CLICKED = "Tour_Hotel_Select_Clicked",

  HOME_PAGE_VISITED = "home_page_visited",
  // user tracking v2 events
  GENERAL_SEARCH = "search_initiated",
  PAGE_VISIT_CITY = "city_page_visited",
  PAGE_VISIT_PROPERTY = "property_page_visited",
  DRAFT_INFO = "passengers_info_drafted",
  CONFIRM_INFO = "passengers_info_confirmed",
  PAGE_VISIT_AVAILABILITY_WAITING = "waiting_page_visited",
  VISIT_PRE_PAYMENT = "checkout_page_visited",
  REDEEM_DISCOUNT = "discount_redeemed",
  PAYMENT_ON = "payment_created",
  PAGE_VISIT_AFTER_PAYMENT = "thankyou_page_visited",
  ROOM_RESERVE_CARD_VIEWED = "room_card_viewed",
  ROOM_RESERVE_BUTTON_CLICKED = "room_reserve_button_clicked",
  ROOM_RESERVE_DETAIL_VIEWED = "room_detail_viewed",
  ROOM_RESERVE_NON_AVAILABLE_BUTTON_CLICKED = "room_reserve_non_available_button_clicked",
  PAGE_VIEWED = "page_viewed",
  USER_SESSION_STARTED = "user_session_started",
  HOTEL_CARD_VIEWED = "hotel_card_viewed",
  FLIGHTS_INTL_SEARCH_RESULTS_PAGE_VISITED = "search_results_page_visited",
  FLIGHTS_INTL_SOLUTION_DETAIL_CLICKED = "solution_detail_clicked",
  FLIGHTS_INTL_SOLUTION_SELECTED = "solution_selected",
  FLIGHTS_INTL_PASSENGER_INFO_VISITED = "passengers_info_page_visited",
  REGION_PAGE_VISITED = "region_page_visited",
  RESERVE_BUTTON_CLICKED = "reserve_button_clicked",
  HOTEL_RESERVE_BUTTON_CLICKED = "hotel_reserve_button_clicked",
  ROOM_PASSENGERS_INTERACTED = "room_passengers_interacted",
  SEARCH_BOX_INTERACTED = "search_box_interacted",
  // Profile
  WALLET_CLICKED = "wallet_clicked",
  TRANSACTION_LIST_CLICKED = "transaction_list_clicked",
  CHARGE_WALLET_CLICKED = "charge_wallet_clicked",
  CHARGE_WALLET_REDIRECTED_TO_IPG = "charge_wallet_redirected_to_ipg",
  CHARGE_WALLET_RESULT = "charge_wallet_result",
  WALLET_WITHDRAW_CLICKED = "wallet_withdraw_clicked",
  WALLET_WITHDRAW_RULES_ACCEPTED = "wallet_withdraw_rules_accepted",
  WALLET_WITHDRAW_CARD_SELECTED = "wallet_withdraw_card_selected",
  WALLET_WITHDRAW_AMOUNT_AND_CARD_SELECTED = "wallet_withdraw_amount_and_card_selected",
  WALLET_WITHDRAW_REQUEST_CONFIRMED = "wallet_withdraw_request_confirmed"
}

export enum DATA_LAYER_EVENTS {
  VIEW_ITEM_LIST = "view_item_list",
  SELECT_ITEM = "select_item",
  ADD_TO_CART = "add_to_cart",
  BEGIN_CHECKOUT = "begin_checkout",
  ADD_PAYMENT_INFO = "add_payment_info",
  PURCHASE = "purchase",

  FLIGHT_INTL_SEARCH_INITIATED = "intlFlightSearchInitiated",
  FLIGHT_INT_SEARCH_LANDED = "intlFlightSearchLanded",
  FLIGHT_INT_SELECT_SOLUTION = "intlFlightSelectedSolution",
  FLIGHT_INTL_VIEW_ITEM = "intflight_view_item",
  FLIGHT_INTL_BOOK_CLICK = "intflight_begin_checkout",

  SUBMIT_CIP = "CipRequest",
  TELESALES_BUTTON_CLICK = "coldcallclick",
  TELESALES_SUBMIT = "'coldcallrequest'"
}
