import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { RangePropertiesType } from "./range.types";

export const useRange = (properties: RangePropertiesType) => {
  const { value, min, max, step, onChange } = properties;
  const isSingle = typeof value === "number";

  const rangeReference = useRef<HTMLDivElement>(null);

  const [minValue, setMinValue] = useState(isSingle ? 0 : value[0]);
  const [maxValue, setMaxValue] = useState(isSingle ? value : value[1]);

  const stepItems = useMemo(() => {
    if (!step) {
      return [];
    }
    const items = Array.from({ length: Math.round(max / step) + 1 }).map(
      (_, index) => ({
        out: index * step < minValue || index * step > maxValue
      })
    );

    return items;
  }, [step, max, minValue, maxValue]);

  const onMinChange = useCallback(
    (event: any) => {
      const value = Number(event.target.value);
      if (maxValue > value) {
        setMinValue(value);
        onChange(isSingle ? maxValue : [value, maxValue]);
      }
    },
    [isSingle, maxValue, onChange]
  );

  const onMaxChange = useCallback(
    (event: any) => {
      const value = Number(event.target.value);
      if (isSingle) {
        setMaxValue(value);
        onChange(value);
      } else if (minValue < value) {
        setMaxValue(value);
        onChange([minValue, value]);
      }
    },
    [isSingle, minValue, onChange]
  );

  const getPercent = useCallback(
    (value: any) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  useEffect(() => {
    const minPercent = getPercent(minValue);
    const maxPercent = getPercent(maxValue);

    if (rangeReference.current) {
      rangeReference.current.style.width = `${maxPercent - minPercent}%`;
      const { direction } = getComputedStyle(rangeReference.current);
      if (direction === "rtl") {
        rangeReference.current.style.right = `${minPercent}%`;
      } else {
        rangeReference.current.style.left = `${minPercent}%`;
      }
    }
  }, [minValue, maxValue, getPercent]);

  // useEffect(() => {
  //   onChange(isSingle ? maxValue : [minValue, maxValue]);
  // }, [minValue, maxValue, onChange]);

  return {
    isSingle,
    minValue,
    maxValue,
    stepItems,
    minInputProperties: {
      max,
      min,
      step,
      type: "range",
      value: minValue,
      onChange: onMinChange
    },
    maxInputProperties: {
      max,
      min,
      step,
      type: "range",
      value: maxValue,
      onChange: onMaxChange
    },
    rangeProperties: {
      ref: rangeReference
    }
  };
};
