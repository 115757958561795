import { useCallback, useMemo, useRef } from "react";
import { TabBarPropertiesType } from "./tab-bar.types";

export const useTabBar = (properties: TabBarPropertiesType) => {
  const { orientation } = properties;
  const buttonsContainerReference = useRef<HTMLDivElement>(null);

  const onNextScrollClick = useCallback(() => {
    if (buttonsContainerReference.current) {
      if (orientation === "vertical") {
        buttonsContainerReference.current?.scrollTo(
          0,
          Number(buttonsContainerReference.current?.scrollTop) + 100
        );
      } else {
        buttonsContainerReference.current?.scrollTo(
          Number(buttonsContainerReference.current?.scrollLeft) + 100,
          0
        );
      }
    }
  }, [orientation]);

  const onPreviousScrollClick = useCallback(() => {
    if (buttonsContainerReference.current) {
      if (orientation === "vertical") {
        buttonsContainerReference.current?.scrollTo(
          0,
          Number(buttonsContainerReference.current?.scrollTop) - 100
        );
      } else {
        buttonsContainerReference.current?.scrollTo(
          Number(buttonsContainerReference.current?.scrollLeft) - 100,
          0
        );
      }
    }
  }, [orientation]);

  const scrollButtonShow = useMemo(
    () =>
      orientation === "vertical"
        ? Number(buttonsContainerReference.current?.scrollHeight) >=
          Number(buttonsContainerReference.current?.offsetHeight) + 100
        : Number(buttonsContainerReference.current?.scrollWidth) >=
          Number(buttonsContainerReference.current?.offsetWidth) + 100,
    [orientation]
  );

  return {
    buttonsContainerProps: {
      ref: buttonsContainerReference
    },
    nextScrollProps: {
      onClick: onNextScrollClick,
      hidden: !scrollButtonShow
    },
    previousScrollProps: {
      onClick: onPreviousScrollClick,
      hidden: !scrollButtonShow
    }
  };
};
