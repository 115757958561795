import { useViewPort } from "@website/hooks";
import { throttle } from "lodash";
import { MouseEvent, useCallback, useEffect, useState } from "react";
import { ModalOldPropertiesType } from "./modal-old.types";

export const useModalOld = (properties: ModalOldPropertiesType) => {
  const {
    "aria-describedby": ariaDescribedby,
    "aria-labelledby": ariaLabelledby,
    id,
    open,
    onClose
  } = properties;

  const [containerHeight, setContainerHeight] = useState<number>(0);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [animateOpen, setAnimateOpen] = useState<boolean>(false);
  const { isMobile, isTablet } = useViewPort();

  useEffect(() => {
    const resizeListener = () => {
      if (typeof window !== "undefined") {
        setContainerHeight(window.visualViewport?.height || window.innerHeight);
      }
    };
    const resizeListenerThrottled = throttle(resizeListener);

    resizeListener();

    window.addEventListener("resize", resizeListenerThrottled);
    return () => {
      window.removeEventListener("resize", resizeListenerThrottled);
    };
  }, []);

  useEffect(() => {
    if (isMobile || isTablet) {
      if (open) {
        document.body.classList.add("prevent-scroll");
      } else {
        document.body.classList.remove("prevent-scroll");
      }
    }

    return () => {
      document.body.classList.remove("prevent-scroll");
    };
  }, [open, isMobile, isTablet]);

  useEffect(() => {
    const checkIfPressEsc = (event: KeyboardEvent) => {
      if (open && event.key === "Escape" && onClose) {
        onClose();
      }
    };

    if (document) {
      if (open) {
        document.addEventListener("keydown", checkIfPressEsc);
        setModalOpen(true);
        setTimeout(() => {
          setAnimateOpen(true);
        }, 50);
      } else {
        document.removeEventListener("keydown", checkIfPressEsc);
        setAnimateOpen(false);
        setTimeout(() => {
          setModalOpen(false);
        }, 200);
      }
    }

    return () => {
      if (document) {
        document.removeEventListener("keydown", checkIfPressEsc);
      }
    };
  }, [onClose, open]);

  const stopPropagationOnModal = useCallback((event: MouseEvent) => {
    event.stopPropagation();
  }, []);

  return {
    containerHeight,
    modalProps: {
      role: "dialog",
      "aria-labelledby": ariaLabelledby,
      "aria-describedby": ariaDescribedby,
      "aria-modal": true,
      onClick: stopPropagationOnModal,
      id
    },
    transitionProps: {
      timeout: 200
    },
    modalContainerProps: {
      "data-testid": "modal-container",
      onClick: onClose
    },
    modalOpen,
    animateOpen
  };
};
