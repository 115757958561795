import { useLocalStorage } from "@website/hooks";
import { useCallback, useEffect, useState } from "react";
import { TOUR_SEARCH_HISTORY_LOCAL_STORAGE_KEY } from "../search-tour.constant";

export const useHistory = (
  onChangeOrigin: (item: any) => void,
  onChangeDestination: (item: any) => void
) => {
  const [history, setHistory] = useState<Array<any>>([]);
  const { addToLocalStorage, getFromLocalStorage } = useLocalStorage();

  useEffect(() => {
    const historyString = getFromLocalStorage(
      TOUR_SEARCH_HISTORY_LOCAL_STORAGE_KEY
    );
    const history = historyString ? JSON.parse(historyString) : [];
    setHistory(history);
  }, [getFromLocalStorage]);

  const addToSearchHistory = useCallback(
    (
      origin: any,
      destination: any,
      startDateTime: string,
      endDateTime: string,
      passengers: string,
      passengerTotalCount: number,
      calendarLocale: string
    ) => {
      const historyString = getFromLocalStorage(
        TOUR_SEARCH_HISTORY_LOCAL_STORAGE_KEY
      );
      const history = historyString ? JSON.parse(historyString) : [];

      let newHistory = history.filter(
        (item) =>
          !(
            item.origin.iataCode === origin.iataCode &&
            item.destination.iataCode === destination.iataCode &&
            item.startDateTime === startDateTime &&
            item.endDateTime === endDateTime
          )
      );
      const newHistoryItem = {
        origin,
        destination,
        startDateTime,
        endDateTime,
        passengers,
        passengerTotalCount,
        calendarLocale
      };
      newHistory = [newHistoryItem, ...newHistory].slice(0, 4);

      addToLocalStorage(
        TOUR_SEARCH_HISTORY_LOCAL_STORAGE_KEY,
        JSON.stringify(newHistory)
      );
    },
    [addToLocalStorage, getFromLocalStorage]
  );

  const addToHistory = useCallback(
    (
      origin: any,
      destination: any,
      startDateTime: string,
      endDateTime: string,
      passengers: string,
      passengerTotalCount: number,
      calendarLocale: string
    ) => {
      addToSearchHistory(
        origin,
        destination,
        startDateTime,
        endDateTime,
        passengers,
        passengerTotalCount,
        calendarLocale
      );
    },
    [addToSearchHistory]
  );

  const onSelectDestinationHistory = useCallback(
    (id: string) => {
      if (history) {
        let historyItem = null;
        for (const element of history) {
          const checkKey = `${element.id}`;
          if (`${id}` === checkKey) {
            historyItem = element;
            break;
          }
        }

        if (historyItem) {
          onChangeDestination(historyItem);
        }
      }
    },
    [history, onChangeDestination]
  );

  return {
    onSelectDestinationHistory,
    addToHistory
  };
};
