import { parseDateTime } from "@internationalized/date";
import { useTranslation } from "@website/locale";
import { DatepickerSelectedDateType } from "@website/ui-old";
import { useCallback } from "react";

export const useSubmit = (
  destinationItem: any,
  date: DatepickerSelectedDateType,
  calendarLocale: string,
  passengerNumber: number,
  addToHistory: (
    destination: any,
    startDateTime: any,
    endDateTime: any,
    calendarLocale: string,
    passengerNumber: any
  ) => void,
  submitValidationCheck: () => boolean
) => {
  const { t } = useTranslation();
  const channel = process.env.NEXT_PUBLIC_CHANNEL_TYPE;

  const onSubmitClick = useCallback(() => {
    if (submitValidationCheck()) {
      let startDateTime = "";
      let endDateTime = "";
      let startDateObject = null;
      let endDateObject = null;
      let startDate = null;
      let endDate = null;
      if (date && date[0] && date[1]) {
        startDateTime = date[0].dateTime;
        endDateTime = date[1].dateTime;

        startDateObject = parseDateTime(startDateTime);
        endDateObject = parseDateTime(endDateTime);

        startDate = `${startDateObject.year}-${startDateObject.month
          .toString()
          .padStart(2, "0")}-${startDateObject.day
          .toString()
          .padStart(2, "0")}`;
        endDate = `${endDateObject.year}-${endDateObject.month
          .toString()
          .padStart(2, "0")}-${endDateObject.day.toString().padStart(2, "0")}`;

        if (destinationItem && startDate && endDate) {
          addToHistory(
            destinationItem,
            startDateTime,
            endDateTime,
            calendarLocale,
            passengerNumber
          );
          const parameters = {
            room_capacity: `${passengerNumber}`,
            date_from: startDate || "",
            date_to: endDate,
            source: "searchBox"
          };
          window?.webengage?.track("villa-search-clicked", {
            ...parameters,
            channel,
            source: "searchBox"
          });
          window?.dataLayer?.push({
            event: "villa-search",
            ecommerce: {
              items: [parameters]
            }
          });

          window.location.href = `${
            process.env.NEXT_PUBLIC_BASE_URL_B2C
          }/villa/${destinationItem.entity?.toLowerCase()}/${
            destinationItem.slug
          }/?${new URLSearchParams(parameters).toString()}`;
        }
      }
    }
  }, [
    submitValidationCheck,
    date,
    destinationItem,
    addToHistory,
    calendarLocale,
    passengerNumber,
    channel
  ]);

  return {
    onSubmitClick,
    submitText: t("action.search")
  };
};
