import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { LazyImage } from "../lazy-image";
import { useChips } from "./chips.hook";
import styles from "./chips.module.scss";
import { ChipsPropertiesType } from "./chips.types";

const Component: FC<ChipsPropertiesType> = (properties) => {
  const {
    disabled,
    EndIcon,
    StartIcon,
    selected,
    variant,
    color,
    text,
    avatar,
    alt,
    className,
    transparent
  } = properties;
  const { endIconProperties, rootProperties } = useChips(properties);

  return (
    <div
      {...rootProperties}
      className={`${styles["chips"]} ${styles[variant || "contained"]} ${
        styles[color || "primary"]
      } ${disabled ? styles["disabled"] : ""} ${
        selected ? styles["selected"] : ""
      } ${className}`}
      style={
        color?.startsWith("#")
          ? {
              color: transparent ? color : "white",
              backgroundColor: transparent ? `${color}20` : color
            }
          : {}
      }
    >
      {avatar ? (
        <LazyImage alt={alt} className={styles["image"]} src={avatar} />
      ) : StartIcon ? (
        <div className={styles["start-icon"]}>{StartIcon}</div>
      ) : null}
      <div className={styles["text"]}>{text}</div>
      {EndIcon ? (
        <div {...endIconProperties} className={styles["end-icon"]}>
          {EndIcon}
        </div>
      ) : null}
      <div className={styles["overlay"]} />
    </div>
  );
};

export const Chips = memo(Component, deepCompare);
