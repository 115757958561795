import { useTranslation } from "@website/locale";
import { useCallback, useMemo, useState } from "react";

const SalonType = {
  oneWay: "one-way",
  rountTrip: "round-trip"
};

export const useSaleType = () => {
  const { t } = useTranslation();
  const [saleType, setSaleType] = useState<string>(SalonType.oneWay);

  const saleTypeList = useMemo(
    () => [
      {
        title: t("homepage.train.main_search.one_way"),
        value: SalonType.oneWay
      },
      {
        title: t("homepage.train.main_search.round_trip"),
        value: SalonType.rountTrip
      }
    ],
    [t]
  );

  const onChangeSaleType = useCallback((value: string) => {
    setSaleType(value);
  }, []);

  const onChangeDatepickerMode = useCallback((value: string) => {
    if (value === "single") {
      setSaleType(SalonType.oneWay);
    } else if (value === "same-start-end") {
      setSaleType(SalonType.rountTrip);
    }
  }, []);

  return { saleType, saleTypeList, onChangeSaleType, onChangeDatepickerMode };
};
