import { memo, SVGProps } from "react";

const SvgBus = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    height="1em"
    viewBox="0 0 32 32"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <path d="M23.5 4C24.879 4 26 5.123 26 6.5v4.551c1.14.232 2 1.243 2 2.449v1c0 .827-.673 1.5-1.5 1.5l-.501-.001L26 23.5a2.503 2.503 0 0 1-1.999 2.449L24 26.5c0 .827-.673 1.5-1.5 1.5h-2c-.827 0-1.5-.673-1.5-1.5v-.501h-6v.501c0 .772-.587 1.409-1.337 1.491L11.5 28h-2c-.827 0-1.5-.673-1.5-1.5l-.001-.551A2.504 2.504 0 0 1 6 23.5v-7.501L5.5 16a1.503 1.503 0 0 1-1.491-1.337L4 14.5v-1c0-1.207.86-2.217 2-2.449V6.5C6 5.123 7.121 4 8.5 4h15zm-13 16c-.827 0-1.5.673-1.5 1.5s.673 1.5 1.5 1.5 1.5-.673 1.5-1.5-.673-1.5-1.5-1.5zm11 0c-.827 0-1.5.673-1.5 1.5s.673 1.5 1.5 1.5 1.5-.673 1.5-1.5-.673-1.5-1.5-1.5zm1-10h-13c-.827 0-1.5.673-1.5 1.5v5c0 .827.673 1.5 1.5 1.5h13c.827 0 1.5-.673 1.5-1.5v-5c0-.827-.673-1.5-1.5-1.5zm-1-4h-11C9.673 6 9 6.673 9 7.5S9.673 9 10.5 9h11c.827 0 1.5-.673 1.5-1.5S22.327 6 21.5 6z" />
  </svg>
);

const Memo = memo(SvgBus);
export default Memo;
