import { useCallback, useEffect, useRef } from "react";
import { RollPropertiesType } from "./roll.types";

export const useRoll = (properties: RollPropertiesType) => {
  const { value, onChange } = properties;
  const rollReference = useRef<HTMLDivElement>(null);
  const timeoutId = useRef(0);
  const isTouching = useRef(false);

  useEffect(() => {
    const reference = rollReference;
    const goToIndex = () => {
      if (reference.current && !isTouching.current) {
        const scrollTop = reference.current.scrollTop || 0;
        const index = Math.round(scrollTop / 48);
        reference.current.scrollTo(0, index * 48);
        onChange(index);
      }
    };

    const onScroll = () => {
      clearTimeout(timeoutId.current);
      timeoutId.current = Number(setTimeout(goToIndex, 100));
    };

    const onTouchStart = () => {
      isTouching.current = true;
    };

    const onTouchEnd = () => {
      isTouching.current = false;
      goToIndex();
    };

    if (reference.current) {
      reference.current.addEventListener("scroll", onScroll);
      reference.current.addEventListener("touchstart", onTouchStart);
      reference.current.addEventListener("touchend", onTouchEnd);
    }

    return () => {
      if (reference.current) {
        reference.current.removeEventListener("scroll", onScroll);
        reference.current.removeEventListener("touchstart", onTouchStart);
        reference.current.removeEventListener("touchend", onTouchEnd);
      }
    };
  }, [onChange]);

  useEffect(() => {
    if (rollReference?.current) {
      rollReference.current.scrollTo(0, value * 48);
    }
  }, [value]);

  const onUpButtonClick = useCallback(() => {
    if (rollReference?.current) {
      rollReference.current.scrollTo(0, (value - 1) * 48);
    }
  }, [value]);

  const onDownButtonClick = useCallback(() => {
    if (rollReference?.current) {
      rollReference.current.scrollTo(0, (value + 1) * 48);
    }
  }, [value]);

  return {
    upButtonProperties: {
      onClick: onUpButtonClick
    },
    downButtonProperties: {
      onClick: onDownButtonClick
    },
    scrollProperties: {
      ref: rollReference
    }
  };
};
