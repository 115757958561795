import { useTranslation } from "@website/locale";
import { useCallback, useMemo, useState } from "react";

export const useFlightClass = () => {
  const { t } = useTranslation();
  const [flightClass, setFlightClass] = useState<string>("ECONOMY");

  const flightClassList = useMemo(
    () => [
      {
        title: t("homepage.inter_flights.main_search.economy"),
        value: "ECONOMY"
      },
      {
        title: t("homepage.inter_flights.main_search.business"),
        value: "BUSINESS"
      },
      {
        title: t("homepage.inter_flights.main_search.first_class"),
        value: "FIRST_CLASS"
      }
    ],
    [t]
  );

  const onChangeFlightClass = useCallback((value: string) => {
    setFlightClass(value);
  }, []);

  return {
    flightClass,
    flightClassList,
    onChangeFlightClass
  };
};
