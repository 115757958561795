import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { Menu, MiniPopover, TextField } from "..";
import { SmallTextField } from "../small-text-field";
import { useDropdown } from "./dropdown.hook";
import styles from "./dropdown.module.scss";
import { DropdownPropertiesType } from "./dropdown.types";

const Dropdown: FC<DropdownPropertiesType> = (properties) => {
  const {
    className,
    disabled,
    error,
    fullWidth,
    small = false,
    longListStyle,
    readOnly = false,
    id
  } = properties;
  const { textfieldProps, popoverProps, menuProps } = useDropdown(properties);

  return (
    <div
      className={`${styles["dropdown"]} ${error ? styles["error"] : ""} ${
        disabled ? styles["disabled"] : ""
      } ${fullWidth ? styles["fullWidth"] : ""} ${className ?? ""}`}
      id={id}
    >
      {small ? (
        <SmallTextField
          className={`${styles["small-text-field"]}`}
          {...textfieldProps}
          readOnly={readOnly}
        />
      ) : (
        <TextField
          className={`${styles["text-field"]}`}
          {...textfieldProps}
          readOnly={readOnly}
        />
      )}
      <MiniPopover {...popoverProps}>
        <div className={styles["popover"]}>
          <Menu {...menuProps} longListStyle={longListStyle} />
        </div>
      </MiniPopover>
    </div>
  );
};

const DropdownMemoized = memo(Dropdown, deepCompare);
export { DropdownMemoized as Dropdown };
