import { useDebounce } from "@website/hooks";
import { Origin } from "@website/icons";
import { useTranslation } from "@website/locale";
import { useCallback, useMemo, useState } from "react";
import { NoResult } from "../../../..";
import { fixPersianWords } from "../../common/utils";
import { useStationsService } from "../services";

export const useOrigin = () => {
  const { t } = useTranslation();
  const [originQuery, setOriginQuery] = useState<string>("");
  const [selectedOrigin, setSelectedOrigin] = useState<string>("");
  const [selectedOriginItem, setSelectedOriginItem] = useState(null);
  const query = useDebounce(originQuery, 250);

  const suggestions = useMemo(
    () => [
      {
        nameFa: "تهران",
        provinceName: "تهران",
        code: 1
      },
      {
        nameFa: "مشهد",
        provinceName: "خراسان رضوی",
        code: 191
      },
      {
        nameFa: "تبریز",
        provinceName: "آذربایجان شرقی",
        code: 55
      },
      {
        nameFa: "یزد",
        provinceName: "یزد",
        code: 219
      },
      {
        nameFa: "بندر عباس",
        provinceName: "هرمزگان",
        code: 37
      }
    ],
    []
  );
  const { isLoading, data } = useStationsService();

  const onChangeOriginQuery = useCallback((value: string) => {
    setOriginQuery(value);
  }, []);

  const originList = useMemo(
    () =>
      originQuery.length >= 2
        ? data?.filter(
            (item) =>
              fixPersianWords(item?.nameFa).includes(query) ||
              item.code.toString().toLowerCase().includes(query)
          ) || []
        : suggestions || [],
    [originQuery.length, data, suggestions, query]
  );
  const originListNormalized = useMemo(
    () =>
      originList.map((item) => ({
        id: item.code,
        title: item.nameFa,
        subtitle: item?.provinceName || item.countryName,
        StartIcon: <Origin />
      })),
    [originList]
  );

  const onSelectOrigin = useCallback(
    (id: string) => {
      setSelectedOrigin(id);
      const originItem = originList.find(
        (origin: any) => `${origin.code}` === `${id}`
      );
      setOriginQuery("");
      setSelectedOriginItem(originItem);
    },
    [originList]
  );

  const onChangeOrigin = useCallback((item: any) => {
    setSelectedOrigin(item?.code || "");
    setOriginQuery("");
    setSelectedOriginItem(item);
  }, []);

  return {
    selectedOriginItem,
    selectedOriginText: selectedOriginItem?.nameFa || "",
    originQuery,
    selectedOrigin,
    onChangeOrigin,
    onSelectOrigin,
    onChangeOriginQuery,
    originList,
    originLoading: isLoading,
    originListNormalized,
    OriginNoResult:
      originQuery?.length >= 2 &&
      !isLoading &&
      originList &&
      originList.length === 0 ? (
        <NoResult query={originQuery} />
      ) : undefined,
    originLabel: t("homepage.train.main_search.origin")
  };
};
