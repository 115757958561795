import { FC, memo } from "react";
import { useMenuItem } from "./menu-item.hook";
import styles from "./menu-item.module.scss";
import { MenuItemPropertiesType } from "./menu-item.types";

const MenuItem: FC<MenuItemPropertiesType> = (properties) => {
  const {
    title,
    description,
    StartIcon,
    EndIcon,
    selected,
    disabled,
    size = "default"
  } = properties;
  const { onClickItem } = useMenuItem(properties);

  return (
    <div
      className={`${styles["container"]} ${
        selected ? styles["selected"] : ""
      } ${disabled ? styles["disabled"] : ""} ${styles[size]}`}
      onClick={onClickItem}
    >
      {StartIcon ? <div className={styles["start"]}>{StartIcon}</div> : null}
      <div className={styles["middle"]}>
        <div className={styles["title"]}>{title}</div>
        {description ? (
          <div className={styles["description"]}>{description}</div>
        ) : null}
      </div>
      {EndIcon ? <div className={styles["end"]}>{EndIcon}</div> : null}
    </div>
  );
};

const MenuItemMemoized = memo(MenuItem);
export { MenuItemMemoized as MenuItem };
