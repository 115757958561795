import { parseDateTime } from "@internationalized/date";
// import { USER_TRACKING_EVENTS, addEventToTracking } from "@website/tracking";
import { addEventToTracking, USER_TRACKING_EVENTS } from "@website/tracking";
import { DatepickerSelectedDateType } from "@website/ui-old";
import { diffDateDays } from "@website/utils";
import { useRouter } from "next/router";
import { useCallback } from "react";

export const useSubmit = (
  destinationItem: any,
  date: DatepickerSelectedDateType,
  calendarLocale: string,
  // passengerNumber: { adult: number; child: number; room: number },
  addToHistory: (
    destination: any,
    startDateTime: any,
    endDateTime: any,
    calendarLocale: string
    // passengerNumber: any
  ) => void,
  submitValidationCheck: () => boolean
) => {
  const router = useRouter();
  const channel = process.env.NEXT_PUBLIC_CHANNEL_TYPE;

  const onSubmitClick = useCallback(() => {
    if (submitValidationCheck()) {
      let startDateTime = "";
      let endDateTime = "";
      let startDateObject = null;
      let endDateObject = null;
      let startDate = null;
      let endDate = null;
      if (date && date[0] && date[1]) {
        startDateTime = date[0].dateTime;
        endDateTime = date[1].dateTime;

        startDateObject = parseDateTime(startDateTime);
        endDateObject = parseDateTime(endDateTime);

        startDate = `${startDateObject.year}-${startDateObject.month
          .toString()
          .padStart(2, "0")}-${startDateObject.day
          .toString()
          .padStart(2, "0")}`;
        endDate = `${endDateObject.year}-${endDateObject.month
          .toString()
          .padStart(2, "0")}-${endDateObject.day.toString().padStart(2, "0")}`;

        if (destinationItem && startDate && endDate) {
          addToHistory(
            destinationItem,
            startDateTime,
            endDateTime,
            calendarLocale
            // passengerNumber
          );

          const isCity = !("accommodation_type_title" in destinationItem);

          const today = new Date();
          const searchWindow = diffDateDays(today, new Date(startDateObject));
          if (isCity) {
            const parameters = {
              ...router?.query,
              date_from: startDate,
              date_to: endDate,
              city_name: destinationItem?.fa_title,
              // adults: `${passengerNumber?.adult}`,
              // children: `${passengerNumber?.child}`,
              // no_rooms: `${passengerNumber?.room}`,
              source: "searchBox"
            };
            window?.webengage?.track("hotel-search-clicked", {
              ...parameters,
              channel,
              source: "searchBox",
              searchWindow
            });
            window?.dataLayer?.push({
              event: "hotel-search",
              ecommerce: {
                items: [parameters]
              }
            });
            window.location.href = `${
              process.env.NEXT_PUBLIC_BASE_URL_B2C
            }/رزرو-هتل/${destinationItem.fa_url}?${new URLSearchParams(
              parameters
            ).toString()}`;
          } else {
            const parameters = {
              ...router?.query,
              date_from: startDate,
              date_to: endDate,
              // adults: `${passengerNumber?.adult}`,
              // children: `${passengerNumber?.child}`,
              // no_rooms: `${passengerNumber?.room}`,
              source: "searchBox"
            };
            window?.webengage?.track("hotel-search-clicked", {
              ...parameters,
              channel,
              source: "searchBox",
              searchWindow
            });
            window?.dataLayer?.push({
              event: "hotel-search",
              ecommerce: {
                items: [parameters]
              }
            });

            window.location.href = `${
              process.env.NEXT_PUBLIC_BASE_URL_B2C
            }/رزرو-هتل/${destinationItem.fa_url}?${new URLSearchParams(
              parameters
            ).toString()}`;
          }
          addEventToTracking(USER_TRACKING_EVENTS.GENERAL_SEARCH, {
            date_from: startDate,
            date_to: endDate,
            city: destinationItem?.fa_title,
            city_id: destinationItem?.id,
            product: "dom-hotel",
            search_source: "search-box"
          });
        }
      }
    }
  }, [
    submitValidationCheck,
    date,
    destinationItem,
    addToHistory,
    calendarLocale,
    channel,
    router?.query
  ]);

  return {
    onSubmitClick
  };
};
