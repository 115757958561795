import { useViewPort } from "@website/hooks";
import { useTranslation } from "@website/locale";
import { AutoComplete, Button, Datepicker } from "@website/ui-old";
import { FC } from "react";
import { PassengerRoomCountTour } from "../../../passenger-room-count-tour";
import {
  useDate,
  useDestination,
  useOrigin,
  useSubmit,
  useValidation
} from "./hooks";
import { useHistory } from "./hooks/use-history";
import { usePassengerRoomCountTour } from "./hooks/use-passenger-room-count-tour";
import {
  onceTrackRoomPassengersInteracted,
  onceTrackSearchBoxInteracted
} from "./search-tour-tracking";
import { SearchTourPropertiesType } from "./search-tour.types";

const SearchTour: FC<SearchTourPropertiesType> = () => {
  const {
    onSubmitPassengerCount,
    passengerCount,
    passengerCountList,
    passengerCountTitle,
    passengerCountResult,
    onChange,
    onDeletePassengerCountList,
    onAddPassengerCountList,
    onChangeChildAge,
    totalPassenger,
    onClickBackDropHandler,
    onClosePassengerButton
  } = usePassengerRoomCountTour();
  const {
    selectedOriginItem,
    selectedOriginText,
    originQuery,
    onChangeOriginQuery,
    onSelectOrigin,
    originLoading,
    originListNormalized,
    originLabel,
    OriginNoResult,
    onChangeOrigin
  } = useOrigin();
  const {
    selectedDestinationItem,
    selectedDestinationText,
    destinationQuery,
    onChangeDestinationQuery,
    onSelectDestination,
    destinationLoading,
    destinationListNormalized,
    destinationLabel,
    DestinationNoResult,
    onChangeDestination
  } = useDestination();
  const { date, calendarLocale, onChangeDate } = useDate();

  const { addToHistory } = useHistory(onChangeDestination, onChangeOrigin);

  const { errors, submitValidationCheck } = useValidation({
    originItem: selectedOriginItem,
    destinationItem: selectedDestinationItem,
    date
  });
  const { onSubmitClick, submitText } = useSubmit(
    selectedOriginItem,
    selectedDestinationItem,
    date,
    passengerCount,
    calendarLocale,
    submitValidationCheck,
    addToHistory
  );
  const { isMobile } = useViewPort();
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col items-start gap-6 xl:flex-row xl:items-center">
        <div className="flex h-8 flex-1 flex-row gap-2 overflow-x-auto md:overflow-visible lg:flex-none xl:h-[38px]">
          <PassengerRoomCountTour
            list={passengerCountList}
            onAdd={onAddPassengerCountList}
            onChange={onChange}
            onChangeChildAge={onChangeChildAge}
            onClick={() => {
              onceTrackRoomPassengersInteracted();
              onceTrackSearchBoxInteracted();
            }}
            onClickBackDropHandler={onClickBackDropHandler}
            onClosePassengerButton={onClosePassengerButton}
            onDelete={onDeletePassengerCountList}
            onSubmit={onSubmitPassengerCount}
            result={passengerCountResult}
            title={passengerCountTitle}
            totalPassenger={totalPassenger}
          />
        </div>
      </div>
      <div className="flex flex-col gap-6 xl:flex-row xl:gap-4">
        <div className="flex flex-1 flex-col gap-4 lg:flex-row">
          <div className="flex-1">
            <AutoComplete
              error={errors?.originError || errors?.destinationError}
              fullWidth
              parts={[
                {
                  label: originLabel,
                  list: originListNormalized,
                  text: selectedOriginText,
                  onSelect: onSelectOrigin,
                  onChangeQuery: onChangeOriginQuery,
                  query: originQuery,
                  loading: originLoading,
                  NoResult: OriginNoResult,
                  onFocus: onceTrackSearchBoxInteracted
                },
                {
                  label: destinationLabel,
                  list: destinationListNormalized,
                  text: selectedDestinationText,
                  onSelect: onSelectDestination,
                  onChangeQuery: onChangeDestinationQuery,
                  query: destinationQuery,
                  loading: destinationLoading,
                  NoResult: DestinationNoResult,
                  onFocus: onceTrackSearchBoxInteracted
                }
              ]}
            />
          </div>
          <div className="flex-1 lg:w-[312px] lg:flex-none">
            <Datepicker
              error={Boolean(errors?.dateError)}
              fromTitle={t("homepage.tour.main_search.departure_date")}
              fullWidth
              helperText={errors?.dateError}
              mode="start-end"
              onChange={onChangeDate}
              onFocus={onceTrackSearchBoxInteracted}
              toTitle={t("homepage.tour.main_search.return_date")}
              value={date}
            />
          </div>
        </div>
        <div className="w-full xl:w-24">
          <Button
            aria-label="search"
            fullWidth
            id="tour_page"
            // loading={submitLoading}
            onClick={onSubmitClick}
            size={isMobile ? "large" : "x-large"}
          >
            {submitText}
          </Button>
        </div>
      </div>
    </div>
  );
};

export { SearchTour };
