import { createContext, FC, useCallback, useContext, useState } from "react";
import { SettingsContextType, SettingsProviderType } from "./settings.types";

const SettingsContext = createContext<SettingsContextType>({
  theme: "light",
  changeTheme: () => null
});
// const light =
//   process.env["NEXT_PUBLIC_CHANNEL_TYPE"] === "snapp-ir"
//     ? ["light", "snapp-ir-light"]
//     : ["light"];
// const dark =
//   process.env["NEXT_PUBLIC_CHANNEL_TYPE"] === "snapp-ir"
//     ? ["dark", "snapp-ir-dark"]
//     : ["dark"];
export const SettingsProvider: FC<SettingsProviderType> = (properties) => {
  const { children } = properties;
  const [settings] = useState<any>(null);
  // const { addToLocalStorage, getFromLocalStorage } = useLocalStorage();

  // useEffect(() => {
  //   const settingsRaw = getFromLocalStorage("settings");
  //   const settingsObject = settingsRaw ? JSON.parse(settingsRaw) : null;
  //   setSettings(settingsObject);

  //   const theme = settingsObject?.theme || "light";
  //   if (theme === "dark") {
  //     for (const className of dark) {
  //       if (className) {
  //         document.documentElement.classList.add(className);
  //       }
  //     }
  //     light && document.documentElement.classList.remove(light);
  //   } else {
  //     light && document.documentElement.classList.add(light);
  //     document.documentElement.classList.remove("dark");
  //   }
  // }, [dark, light]);

  const changeTheme = useCallback((theme: string) => {
    // if (localStorage) {
    //   const settingsRaw = getFromLocalStorage("settings");
    //   const settingsObject = settingsRaw ? JSON.parse(settingsRaw) : null;
    //   const settingsObjectNew = { ...settingsObject, theme };
    //   const settingsRawNew = JSON.stringify(settingsObjectNew);
    //   addToLocalStorage("settings", settingsRawNew);
    //   if (theme === "dark") {
    //     for (const className of dark) {
    //       if (className) {
    //         document.documentElement.classList.add(className);
    //       }
    //     }
    //     light && document.documentElement.classList.remove(light);
    //   } else {
    //     light && document.documentElement.classList.add(light);
    //     document.documentElement.classList.remove("dark");
    //     document.documentElement.classList.remove("snapp-ir-dark");
    //   }
    // }
  }, []);

  const value = {
    theme: settings?.theme || "light",
    changeTheme
  };

  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettings = () => useContext(SettingsContext);
