import { parseDateTime } from "@internationalized/date";
import { useTranslation } from "@website/locale";
import { DatepickerSelectedDateType } from "@website/ui-old";
import { useRouter } from "next/router";
import { useCallback } from "react";
import { SubmitSearchTrainPropertiesType } from "../search-train.types";

export const useSubmit = (
  originItem: any,
  destinationItem: any,
  saleType: string,
  date: DatepickerSelectedDateType,
  calendarLocale: string,
  passengerNumber: { adult: number; child: number; infant: number },
  salonType: string,
  coupeType: string,
  addToHistory: (
    origin: any,
    destination: any,
    startDate: any,
    endDate: any,
    calendarLocale: string,
    saleType: any,
    passengerNumber: any,
    salonType: any,
    coupeType: any
  ) => void,
  submitValidationCheck: () => boolean,
  properties: SubmitSearchTrainPropertiesType
) => {
  const { t } = useTranslation();
  const channel = process.env.NEXT_PUBLIC_CHANNEL_TYPE;
  const router = useRouter();

  const onSubmitClick = useCallback(() => {
    if (submitValidationCheck()) {
      let startDateTime = "";
      let endDateTime = "";
      let startDateObject = null;
      let endDateObject = null;
      let startDate = null;
      let endDate = null;
      if (saleType === "round-trip" && date && date[0] && date[1]) {
        startDateTime = date[0].dateTime;
        endDateTime = date[1].dateTime;

        startDateObject = parseDateTime(startDateTime);
        endDateObject = parseDateTime(endDateTime);

        startDate = `${startDateObject.year}-${startDateObject.month
          .toString()
          .padStart(2, "0")}-${startDateObject.day
          .toString()
          .padStart(2, "0")}`;
        endDate = `${endDateObject.year}-${endDateObject.month
          .toString()
          .padStart(2, "0")}-${endDateObject.day.toString().padStart(2, "0")}`;
      } else if (saleType === "one-way" && date && date[0]) {
        startDateTime = date[0].dateTime;

        startDateObject = parseDateTime(startDateTime);

        startDate = `${startDateObject.year}-${startDateObject.month
          .toString()
          .padStart(2, "0")}-${startDateObject.day
          .toString()
          .padStart(2, "0")}`;
      }

      if (
        originItem &&
        destinationItem &&
        ((startDate && saleType === "one-way") ||
          (endDate && saleType === "round-trip"))
      ) {
        addToHistory(
          originItem,
          destinationItem,
          startDateTime,
          endDateTime,
          calendarLocale,
          saleType,
          passengerNumber,
          salonType,
          coupeType
        );

        if (channel === "jek") {
          const parameters = {
            ...router?.query,
            adults: `${passengerNumber.adult}`,
            children: `${passengerNumber.child}`,
            infants: `${passengerNumber.infant}`,
            departDate: startDate || "",
            ...(endDate && { returnDate: endDate }),
            origin: originItem?.code,
            destination: destinationItem?.code || destinationItem.code,
            origin_city: originItem.nameFa,
            destination_city: destinationItem.nameFa,
            isExclusive: coupeType === "exclusive-coupe" ? "true" : "false",
            ticketType: salonType,
            source: "searchBox"
          };

          window?.webengage?.track("train-search", {
            ...parameters,
            channel,
            source: "searchBox"
          });

          window?.dataLayer?.push({
            event: "train-search",
            ecommerce: {
              items: [parameters]
            }
          });
          const urlParameters = new URLSearchParams(parameters);
          window.location.href = `${
            process.env.NEXT_PUBLIC_BASE_URL_B2C
          }/train/result-outbound?${urlParameters.toString()}`;
        }
      }
    }
  }, [
    submitValidationCheck,
    saleType,
    date,
    originItem,
    destinationItem,
    addToHistory,
    calendarLocale,
    passengerNumber,
    salonType,
    coupeType,
    channel,
    router?.query
  ]);

  return {
    onSubmitClick,
    submitText: t("action.search")
  };
};
