import { AxiosResponse } from "axios";
import { AxiosNoAuth } from "../../../utils";
import {
  AirportsCitiesParametersType,
  AirportsCitiesResponseType
} from "./airports-cities.types";

export const AirportsCitiesUrl = `${process.env.NEXT_PUBLIC_BASE_URL_IFT}/api/statics/v2/airports-cities`;

export const AirportsCitiesService = (
  parameters: AirportsCitiesParametersType
): Promise<AirportsCitiesResponseType> =>
  AxiosNoAuth.get(AirportsCitiesUrl, {
    params: parameters
  }).then(({ data }: AxiosResponse<AirportsCitiesResponseType>) => data);
