import { omit } from "lodash";
import { RefObject, useEffect, useRef, useState } from "react";
import { UseIsVisibleOptionsType, UseIsVisibleType } from "./is-visible.types";

export const isVisible = (
  element?: Element | null,
  options?: UseIsVisibleOptionsType
) => {
  try {
    const observer = new IntersectionObserver(
      ([entry]) => options?.onChange?.(entry.isIntersecting),
      omit(options, "onChange") ?? {}
    );
    element && observer?.observe(element);
    return {
      unobserve: () => {
        element && observer?.unobserve(element);
      }
    };
  } catch {
    return {
      unobserve: () => undefined
    };
  }
};

export const useIsVisible: UseIsVisibleType = (options) => {
  const reference = useRef<HTMLDivElement>();
  const _options = useRef(options);
  const [inView, setInView] = useState(false);

  useEffect(() => {
    const { unobserve } = isVisible(reference.current, {
      ..._options.current,
      onChange: (inView: boolean) => {
        setInView(inView);
        _options.current?.onChange?.(inView);
      }
    });
    return unobserve;
  }, []);

  return {
    reference: reference as RefObject<HTMLDivElement>,
    inView
  };
};
