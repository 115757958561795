import { memo, SVGProps } from "react";

const SvgTrain = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    height="1em"
    viewBox="0 0 32 32"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <path d="M16 4c-5.333 0-10.667.667-10.667 5.333V22A4.672 4.672 0 0 0 10 26.667l-1.764 1.764A.805.805 0 0 0 8 29c0 .184.149.333.333.333h2.088c.353 0 .693-.14.943-.391l2.276-2.276h5.027l2.276 2.276c.251.251.589.391.943.391h1.781A.333.333 0 0 0 24 29a.804.804 0 0 0-.236-.569L22 26.667A4.672 4.672 0 0 0 26.667 22V9.333C26.667 4.666 21.894 4 16 4zm-6 20c-1.107 0-2-.893-2-2s.893-2 2-2 2 .893 2 2-.893 2-2 2zM8 13.333v-2.667c0-.736.597-1.333 1.333-1.333h4c.736 0 1.333.597 1.333 1.333v2.667c0 .736-.597 1.333-1.333 1.333h-4A1.333 1.333 0 0 1 8 13.333zM22 24c-1.107 0-2-.893-2-2s.893-2 2-2 2 .893 2 2-.893 2-2 2zm-4.667-10.667v-2.667c0-.736.597-1.333 1.333-1.333h4c.736 0 1.333.597 1.333 1.333v2.667c0 .736-.597 1.333-1.333 1.333h-4a1.333 1.333 0 0 1-1.333-1.333z" />
  </svg>
);

const Memo = memo(SvgTrain);
export default Memo;
