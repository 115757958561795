import { AxiosResponse } from "axios";
import { Axios } from "../../utils";
import { BASE_URL_PROFILE } from "../urls";
import { SendOtpBodyType, SendOtpResponseType } from "./send-otp.types";

export const SendOtpUrl = `${BASE_URL_PROFILE}/auth/inquiry`;

export const SendOtpService = (
  body: SendOtpBodyType
): Promise<SendOtpResponseType> =>
  Axios.post(SendOtpUrl, body).then(
    ({ data }: AxiosResponse<SendOtpResponseType>) => data
  );
