import { useMemo } from "react";
import { InboundLinksPropertiesType } from "./inbound-links.types";
export const useInboundLinks = (properties: InboundLinksPropertiesType) => {
  const { list } = properties;

  const masonryList = useMemo(() => {
    const result = [[], [], []];

    if (list) {
      for (const [index, element] of list.entries()) {
        const columnIndex = index % 3;
        result[columnIndex].push(element);
      }
    }

    return result;
  }, [list]);

  return {
    masonryList
  };
};
