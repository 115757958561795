import { parseDateTime } from "@internationalized/date";
import { useTranslation } from "@website/locale";
import { USER_TRACKING_EVENTS, addEventToTracking } from "@website/tracking";
import { DatepickerSelectedDateType } from "@website/ui-old";
import { useCallback } from "react";

export const useSubmit = (
  originItem: any,
  destinationItem: any,
  saleType: string,
  date: DatepickerSelectedDateType,
  calendarLocale: string,
  passengerNumber: { adult: number; child: number; infant: number },
  flightClass: string,
  addToHistory: (
    origin: any,
    destination: any,
    startDate: any,
    endDate: any,
    calendarLocale: string,
    saleType: any,
    passengerNumber: any,
    flightClass: any
  ) => void,
  submitValidationCheck: () => boolean,
  setSubmitLoading: any
) => {
  const { t } = useTranslation();
  const channel = process.env.NEXT_PUBLIC_CHANNEL_TYPE;

  const onSubmitClick = useCallback(() => {
    if (submitValidationCheck()) {
      setSubmitLoading(true);
      let startDateTime = "";
      let endDateTime = "";
      let startDateObject = null;
      let endDateObject = null;
      let startDate = null;
      let endDate = null;
      if (saleType === "round-trip" && date && date[0] && date[1]) {
        startDateTime = date[0].dateTime;
        endDateTime = date[1].dateTime;

        startDateObject = parseDateTime(startDateTime);
        endDateObject = parseDateTime(endDateTime);

        startDate = `${startDateObject.year}-${startDateObject.month
          .toString()
          .padStart(2, "0")}-${startDateObject.day
          .toString()
          .padStart(2, "0")}`;
        endDate = `${endDateObject.year}-${endDateObject.month
          .toString()
          .padStart(2, "0")}-${endDateObject.day.toString().padStart(2, "0")}`;
      } else if (saleType === "one-way" && date && date[0]) {
        startDateTime = date[0].dateTime;

        startDateObject = parseDateTime(startDateTime);

        startDate = `${startDateObject.year}-${startDateObject.month
          .toString()
          .padStart(2, "0")}-${startDateObject.day
          .toString()
          .padStart(2, "0")}`;
      }

      if (
        originItem &&
        destinationItem &&
        ((startDate && saleType === "one-way") ||
          (endDate && saleType === "round-trip"))
      ) {
        addToHistory(
          originItem,
          destinationItem,
          startDateTime,
          endDateTime,
          calendarLocale,
          saleType,
          passengerNumber,
          flightClass
        );
        const diffJek = {
          destination_is_city: destinationItem?.airportIataCode
            ? "false"
            : "true",
          origin_is_city: originItem?.airportIataCode ? "false" : "true"
        };
        const diffWeb = {
          destinationIsCity: destinationItem?.airportIataCode
            ? "false"
            : "true",
          originIsCity: originItem?.airportIataCode ? "false" : "true"
        };

        const parameters = {
          adultCount: `${passengerNumber.adult}`,
          childCount: `${passengerNumber.child}`,
          infantCount: `${passengerNumber.infant}`,
          cabinType: flightClass,
          dateType:
            calendarLocale === "persian"
              ? "jalali"
              : calendarLocale === "gregorian"
                ? "miladi"
                : calendarLocale,
          departureDate: startDate || "",
          tripType: saleType === "round-trip" ? "roundtrip" : "oneway",
          originCityName: originItem.cityName || originItem.cityName,
          destinationCityName:
            destinationItem.cityFaName || destinationItem.cityName,
          ...(endDate && { returnDate: endDate }),
          source: "searchBox",
          channel,
          originCode: originItem?.airportIataCode || originItem.iataCode,
          destinationCode:
            destinationItem?.airportIataCode || destinationItem.iataCode,
          originCity: originItem.cityName,
          destinationCity: destinationItem.cityName,
          ...(channel === "jek" ? diffJek : diffWeb)
        };
        window?.webengage?.track("IF_search_clicked", {
          ...parameters,
          channel,
          source: "searchBox"
        });
        window?.dataLayer?.push({
          event: "IF-search",
          ecommerce: {
            items: [parameters]
          }
        });

        const searchWindow = () => {
          try {
            return Math.round(
              Math.abs(
                new Date(startDate || "").getTime() / 86_400_000 -
                  Date.now() / 86_400_000
              )
            );
          } catch {
            return 0;
          }
        };
        addEventToTracking(USER_TRACKING_EVENTS.GENERAL_SEARCH, {
          product: "int-flight",
          departure_date: startDate ? new Date(startDate)?.toISOString() : "",
          return_departure_date: endDate
            ? new Date(endDate)?.toISOString()
            : "",
          cabin_type: flightClass,
          origin_city: originItem?.cityName,
          destination_city: destinationItem?.cityName,
          origin_is_city: !originItem?.airportIataCode,
          destination_is_city: !destinationItem?.airportIataCode,
          origin_airport: originItem?.airportIataCode
            ? originItem?.airportName
            : "All Airports",
          destination_airport: destinationItem?.airportIataCode
            ? destinationItem?.airportName
            : "All Airports",
          search_calendar_type: calendarLocale,
          trip_type: saleType,
          number_of_passengers:
            Number(passengerNumber?.adult) ||
            0 + Number(passengerNumber?.child) ||
            0 + Number(passengerNumber?.infant) ||
            0,
          route: `${originItem?.cityName - destinationItem?.cityName}`,
          search_source: "search-box",
          search_window: searchWindow()
        });

        const urlParameters = new URLSearchParams(parameters);

        window.location.href = `${
          process.env.NEXT_PUBLIC_BASE_URL_FLIGHT_INTERNATIONAL_RESULT
        }/api/listing/v1/redirect/search?${urlParameters.toString()}`;
        setSubmitLoading(false);
      }
    }
  }, [
    submitValidationCheck,
    channel,
    saleType,
    date,
    originItem,
    destinationItem,
    setSubmitLoading,
    addToHistory,
    calendarLocale,
    passengerNumber,
    flightClass
  ]);

  return { onSubmitClick, submitText: t("action.search") };
};
