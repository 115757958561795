import { useCallback, useState } from "react";

export const usePopover = () => {
  const [open, setOpen] = useState<boolean>(false);

  const onClosePopover = useCallback(() => {
    setOpen(false);
  }, []);

  const onOpenPopover = useCallback(() => {
    setOpen(true);
  }, []);

  return {
    open,
    onOpenPopover,
    onClosePopover,
    popoverProperties: {
      animate: "bottom" as const,
      open,
      onClose: onClosePopover,
      position: "end" as const,
      gapHorizontal: "-24px",
      gapVertical: "-16px"
    }
  };
};
