import { MaterialIcon } from "@website/icons";
import { MiniChips, MiniChipsColorType } from "@website/ui";
import { deepCompare, numberConvert } from "@website/utils";
import { useRouter } from "next/router";
import { memo, useCallback } from "react";
import { ReserveCommentRatingPropertiesType } from "./reserve-comment-rating.types";

const Component = (properties: ReserveCommentRatingPropertiesType) => {
  const router = useRouter();
  const { rate, size = "medium" } = properties;
  const color = useCallback((rate: number) => {
    switch (true) {
      case rate < 2: {
        return "error";
      }
      case rate >= 2 && rate < 3: {
        return "inverse-warning";
      }
      case rate >= 3 && rate < 4: {
        return "default";
      }
      default: {
        return "success";
      }
    }
  }, []);

  return (
    <MiniChips
      StartIcon={<MaterialIcon className="text-base" name="stars" />}
      color={color(rate) as MiniChipsColorType}
      size={size}
      text={numberConvert(rate, { locale: router.locale })}
      variant="accent"
    />
  );
};

export const ReserveCommentRating = memo(Component, deepCompare);
