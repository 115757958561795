import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { TabBar } from "..";
import { useTab } from "./tab.hook";
import styles from "./tabs.module.scss";
import { TabsPropertiesType } from "./tabs.types";

const Tabs: FC<TabsPropertiesType> = (properties) => {
  const {
    className,
    orientation = "horizontal",
    behavior = "button",
    tabs,
    panel
  } = properties;

  const { selectedTab, tabBarProperties } = useTab(properties);

  return (
    <div
      className={`${styles["tabs"]} ${styles[orientation]} ${className ?? ""}`}
    >
      <TabBar {...tabBarProperties} />
      <div className={styles["panels-container"]}>
        {behavior === "link" || behavior === "custom" ? (
          <div className="" tabIndex={0}>
            {panel}
          </div>
        ) : (
          tabs.map((item, index) => (
            <div
              aria-labelledby={`tab-button-${index}`}
              className=""
              hidden={selectedTab !== index}
              id={`tab-${index}`}
              key={item.title}
              role="tabpanel"
              tabIndex={0}
            >
              {item.panel}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

const TabsMemoized = memo(Tabs, deepCompare);
export { TabsMemoized as Tabs };
