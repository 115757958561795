import { memo, SVGProps } from "react";

const SvgBusRounded = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    height="1em"
    viewBox="0 0 25 25"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <circle cx="12.0361" cy="12.0361" r="10" />
    <path d="M12.0361 21.0361C17.0067 21.0361 21.0361 17.0066 21.0361 12.0361C21.0361 7.06551 17.0067 3.03607 12.0361 3.03607C7.06557 3.03607 3.03613 7.06551 3.03613 12.0361C3.03613 17.0066 7.06557 21.0361 12.0361 21.0361Z" />
    <path
      d="M6.98486 15.0565C6.98486 15.5891 7.23486 16.0625 7.60986 16.3583V17.4235C7.60986 17.7785 7.85986 18.0152 8.23486 18.0152H8.85986C9.23486 18.0152 9.48486 17.7785 9.48486 17.4235V16.8317H14.4849V17.4235C14.4849 17.7785 14.7349 18.0152 15.1099 18.0152H15.7349C16.1099 18.0152 16.3599 17.7785 16.3599 17.4235V16.3583C16.7349 16.0624 16.9849 15.5891 16.9849 15.0565V9.07842C16.9849 7.00729 14.7349 7.0152 11.9849 7.0152C9.23486 7.0152 6.98486 7.00729 6.98486 9.07842V15.0565ZM9.17231 15.6482C8.67231 15.6482 8.23475 15.234 8.23475 14.7606C8.23475 14.2872 8.67231 13.8729 9.17231 13.8729C9.67231 13.8729 10.1099 14.2872 10.1099 14.7606C10.1099 15.2341 9.67231 15.6482 9.17231 15.6482ZM14.7974 15.6482C14.2974 15.6482 13.8599 15.234 13.8599 14.7606C13.8599 14.2872 14.2974 13.8729 14.7974 13.8729C15.2974 13.8729 15.735 14.2872 15.735 14.7606C15.7349 15.2341 15.2974 15.6482 14.7974 15.6482ZM14.4617 11.6119H9.50801C8.80487 11.6119 8.23486 11.0723 8.23486 10.4065V9.85864C8.23486 9.19292 8.80487 8.65325 9.50801 8.65325H14.4617C15.1649 8.65325 15.7349 9.19292 15.7349 9.85864V10.4065C15.7349 11.0723 15.1649 11.6119 14.4617 11.6119Z"
      fill="white"
    />
  </svg>
);

const Memo = memo(SvgBusRounded);
export default Memo;
