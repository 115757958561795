import { useCallback, useEffect, useRef, useState } from "react";
import { SnackbarItemPropertiesType } from "./snackbar-item.types";

export const useSnackbarItem = (properties: SnackbarItemPropertiesType) => {
  const { dequeue, severity, title, id, size, Action, duration } = properties;
  const [show, setShow] = useState(false);
  const timeout = useRef<unknown>(null);

  useEffect(() => {
    setShow(true);

    timeout.current = setTimeout(
      () => {
        setShow(false);
        setTimeout(() => {
          dequeue(id);
        }, 150);
      },
      duration === "short" ? 3000 : 8000
    );

    return () => {
      clearTimeout(timeout.current as number);
    };
  }, [dequeue, duration, id]);

  const onClose = useCallback(() => {
    clearTimeout(timeout.current as number);
    setShow(false);
    setTimeout(() => {
      dequeue(id);
    }, 150);
  }, [dequeue, id]);

  return {
    alertProps: {
      onClose,
      severity,
      title,
      size,
      Action
    },
    show
  };
};
