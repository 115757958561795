import { MaterialIcon } from "@website/icons";
import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { useAlert } from "./alert.hook";
import styles from "./alert.module.scss";
import { AlertPropertiesType } from "./alert.types";

const Alert: FC<AlertPropertiesType> = (properties) => {
  const {
    className,
    title,
    severity = "default",
    size = "one-line",
    Action
  } = properties;
  const { rootProps, closeProps } = useAlert(properties);

  return (
    <div
      {...rootProps}
      className={`${styles["container"]} ${styles[size]} ${className ?? ""}`}
    >
      <div className={styles["content"]}>
        <div className={`${styles["icon"]} ${styles[severity]}`}>
          <MaterialIcon
            name={
              severity === "default"
                ? "info"
                : severity === "warning"
                  ? "warning_amber"
                  : severity === "error"
                    ? "error_outline"
                    : severity === "success"
                      ? "check_circle"
                      : "info"
            }
            variant="outlined"
          />
        </div>
        <div className={styles["title"]}>{title}</div>
      </div>
      <div className={styles["actions-container"]}>
        {Action}
        <div className={styles["close-button"]} {...closeProps}>
          <MaterialIcon name="close" />
        </div>
      </div>
    </div>
  );
};

const AlertMemoized = memo(Alert, deepCompare);
export { AlertMemoized as Alert };
