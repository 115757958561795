import { memo, SVGProps } from "react";

const SvgTelegram = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    height="1em"
    viewBox="0 0 32 32"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <path d="M16 2C8.3 2 2 8.3 2 16s6.3 14 14 14 14-6.3 14-14S23.7 2 16 2zm6.86 8.54-2.38 11.48c-.14.84-.7.98-1.26.56l-3.64-2.8c-.84.84-1.68 1.54-1.82 1.82-.28.14-.42.42-.7.42-.42 0-.42-.28-.56-.56l-1.26-4.2L7.46 16c-.84-.28-.84-.84.14-1.26l14.28-5.46c.56-.14 1.26.14.98 1.26zM19.5 11.8l-7.98 5.04 1.26 4.2.28-2.8 6.86-6.16c.42-.42.14-.42-.42-.28z" />
  </svg>
);

const Memo = memo(SvgTelegram);
export default Memo;
