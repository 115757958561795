import axios from "axios";
import { useState } from "react";

const Axios = axios.create({
  timeout: 50_000,
  headers: { "content-type": "application/json" }
});

export const useHotelTrackAPI = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const mutate = async ({
    code,
    phoneNumber
  }: {
    code: string;
    phoneNumber: string;
  }) => {
    try {
      setIsLoading(true);
      const baseUrl = process.env.NEXT_PUBLIC_BASE_URL_B2C;
      const url = `${baseUrl}/service2/hotelbooking/trackHotelBook/${code}/${phoneNumber}`;
      await Axios.get(url);
      setIsLoading(false);
      if (window) {
        const parameters = {
          trackCode: code,
          mobile: phoneNumber
        };
        const urlParameters = new URLSearchParams(parameters);
        window.open(`${baseUrl}/tracking?${urlParameters.toString()}`, "_self");
      }
    } catch {
      setIsLoading(false);
      setIsError(true);
    }
  };

  return {
    isLoadingHotelTrack: isLoading,
    isErrorHotelTrack: isError,
    mutateHotelTrack: mutate
  };
};

export const useFlightDomesticTrackAPI = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const mutate = async ({
    code,
    phoneNumber
  }: {
    code: string;
    phoneNumber: string;
  }) => {
    try {
      setIsLoading(true);
      const baseUrlPinTaPin = process.env.NEXT_PUBLIC_BASE_URL_B2C;
      const url = `${baseUrlPinTaPin}/flights/api/v3/invoices/${code}?mobile=${phoneNumber}`;
      await Axios.get(url);
      setIsLoading(false);
      if (window) {
        window.open(`${baseUrlPinTaPin}/flights/invoice/${code}`, "_self");
      }
    } catch {
      setIsLoading(false);
      setIsError(true);
    }
  };

  return {
    isLoadingFlightDomesticTrack: isLoading,
    isErrorFlightDomesticTrack: isError,
    mutateFlightDomesticTrack: mutate
  };
};

export const useFlightIntlTrackAPI = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const mutate = async ({
    code,
    phoneNumber
  }: {
    code: string;
    phoneNumber: string;
  }) => {
    try {
      setIsLoading(true);
      const baseUrlIFT =
        process.env.NEXT_PUBLIC_BASE_URL_IFT || "https://ift.snapptrip.com";
      const baseUrlPinTaPin = process.env.NEXT_PUBLIC_BASE_URL_B2C;
      const url = `${baseUrlIFT}/api/booking/v2/orders/tracking`;
      await Axios.post(url, {
        cellPhone: phoneNumber,
        trackingCode: code
      });
      setIsLoading(false);
      if (window) {
        const parameters = {
          trackingCode: code,
          mobile: phoneNumber
        };
        const urlParameters = new URLSearchParams(parameters);
        window.open(
          `${baseUrlPinTaPin}/inter-flights/tracking?${urlParameters.toString()}`,
          "_self"
        );
      }
    } catch {
      setIsLoading(false);
      setIsError(true);
    }
  };

  // const { isLoading, isError, mutate } = useMutation(
  //   async ({ code, phoneNumber }: { code: string; phoneNumber: string }) => {
  //     try {
  //       const baseUrlIFT = "https://ift.snapptrip.com";
  //       const baseUrlPinTaPin = process.env.NEXT_PUBLIC_BASE_URL_B2C;
  //       const url = baseUrlIFT + "/api/booking/v2/orders/tracking";
  //       await Axios.post(url, {
  //         cellPhone: phoneNumber,
  //         trackingCode: code
  //       });
  //       if (window) {
  //         const parameters = {
  //           trackingCode: code,
  //           mobile: phoneNumber
  //         };
  //         const urlParameters = new URLSearchParams(parameters);
  //         window.open(
  //           `${baseUrlPinTaPin}/inter-flights/tracking?${urlParameters.toString()}`,
  //           "_self"
  //         );
  //       }
  //     } catch (error: any) {
  //       throw new Error(error);
  //     }
  //   }
  // );

  return {
    isLoadingFlightIntlTrack: isLoading,
    isErrorFlightIntlTrack: isError,
    mutateFlightIntlTrack: mutate
  };
};

export const useBusTrackAPI = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const mutate = async ({
    code,
    phoneNumber
  }: {
    code: string;
    phoneNumber: string;
  }) => {
    try {
      setIsLoading(true);
      const baseUrlBus = process.env.NEXT_PUBLIC_BASE_URL_BUS_TRACKING;
      const url = `${baseUrlBus}/bus/api/booking/v1/tickets-tracking`;
      await Axios.post(url, {
        cellPhone: phoneNumber,
        trackingCode: code
      });
      setIsLoading(false);
      if (window) {
        window.open(`${baseUrlBus}/bus/tracking/${code}`, "_self");
      }
    } catch {
      setIsLoading(false);
      setIsError(true);
    }
  };

  // const { isLoading, isError, mutate } = useMutation(
  //   async ({ code, phoneNumber }: { code: string; phoneNumber: string }) => {
  //     try {
  //       const baseUrlBus = process.env.NEXT_PUBLIC_BASE_URL_B2C;
  //       const url = baseUrlBus + "/bus/api/booking/v1/tickets-tracking";
  //       await Axios.post(url, {
  //         cellPhone: phoneNumber,
  //         trackingCode: code
  //       });
  //       if (window) {
  //         window.open(`${baseUrlBus}/bus/tracking/${code}`, "_self");
  //       }
  //     } catch (error: any) {
  //       throw new Error(error);
  //     }
  //   }
  // );

  return {
    isLoadingBusTrack: isLoading,
    isErrorBusTrack: isError,
    mutateBusTrack: mutate
  };
};
