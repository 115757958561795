import { memo, SVGProps } from "react";

const SvgInstagram = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    height="1em"
    viewBox="0 0 32 32"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <path d="M20.368 2.667h-8.736a8.974 8.974 0 0 0-8.965 8.965v8.736a8.974 8.974 0 0 0 8.965 8.965h8.736a8.974 8.974 0 0 0 8.965-8.965v-8.736a8.974 8.974 0 0 0-8.965-8.965zm2.912 7.546a1.492 1.492 0 1 1-.001-2.985 1.492 1.492 0 0 1 .001 2.985zM21.593 16a5.6 5.6 0 1 1-11.2 0 5.6 5.6 0 0 1 11.2 0z" />
  </svg>
);

const Memo = memo(SvgInstagram);
export default Memo;
