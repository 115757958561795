export const ReserveRateEnum = {
  clean_rate: "وضعیت نظافت",
  covid19_rate: "رعایت پروتکل های بهداشتی",
  facility_rate: "امکانات اقامتگاه",
  food_quality_rate: "کیفیت غذا و پذیرایی",
  location_rate: "موقعیت مکانی و دسترسی",
  staff_rate: "خوشرویی و رفتار کارکنان",
  value_for_money_rate: "کیفیت اقامتگاه نسبت به قیمت"
};
export type ReserveRateTypes =
  | "clean_rate"
  | "covid19_rate"
  | "facility_rate"
  | "food_quality_rate"
  | "location_rate"
  | "staff_rate"
  | "value_for_money_rate";

export type ReserveRatePropertiesTypes = {
  rate: string;
  rateCount: number;
  offerCount: number;
  rateDetail: Record<ReserveRateTypes, string>;
  showTitle?: boolean;
  size?: "medium" | "large";
  hasBackground?: boolean;
  hotelType: string;
  pageTitle?: string;
  pageDescription?: string;
};

export type RatingText = string;

export type RatingColor =
  | "error"
  | "warning"
  | "secondary"
  | "default"
  | "success";

export type RatingDefaults = Array<{
  minRate: number;
  text: RatingText;
  color: RatingColor;
}>;
