import { saveLogin } from "@website/hooks";
import { authStore } from "@website/store";
import { ModalOldAnimateType, ModalOldMobileHeightType } from "@website/ui-old";
import { isSSR } from "@website/utils";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";

const validQueryStrings = new Set([
  "phone-number",
  "otp",
  "password",
  "forgot-password",
  "password-change-successful",
  "add-password"
]);

export const useLoginModal = () => {
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const queryString = "login_step";
  const router = useRouter();
  const queryValue = router?.query?.[queryString];

  useEffect(() => {
    const accessToken = authStore.accessToken.get();
    if (accessToken) {
      setIsLogin(true);
    }
  }, []);

  const onLoginModalOpen = useCallback(() => {
    router?.push(
      {
        pathname: router?.pathname,
        query: { ...router?.query, [queryString]: "phone-number" }
      },
      undefined,
      { shallow: true, scroll: false }
    );
  }, [router]);

  const onLoginModalClose = useCallback(() => {
    const { [queryString]: _, ...remainQuery } = router.query;
    router?.push(
      {
        pathname: router?.pathname,
        query: remainQuery
      },
      undefined,
      { shallow: true, scroll: false }
    );
  }, [router]);

  const onLoginModalEnd = useCallback(
    (accessToken?: string, refreshToken?: string) => {
      if (accessToken) {
        saveLogin({ accessToken, refreshToken });
        setIsLogin(!isSSR());
      }
      onLoginModalClose();
    },
    [onLoginModalClose]
  );

  return {
    loginModalIsOpen: Boolean(
      typeof queryValue === "string" && validQueryStrings.has(queryValue)
    ),
    onLoginModalOpen,
    onLoginModalClose,
    onLoginModalEnd,
    loginModalProps: {
      open: Boolean(
        typeof queryValue === "string" && validQueryStrings.has(queryValue)
      ),
      onClose: onLoginModalClose,
      mobileHeight: "auto" as ModalOldMobileHeightType,
      animate: "bottom" as ModalOldAnimateType
    },
    isLogin
  };
};
