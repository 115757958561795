import {
  AnyCalendarDate,
  CalendarDate,
  GregorianCalendar,
  IslamicTabularCalendar,
  PersianCalendar,
  parseDate,
  toCalendar
} from "@internationalized/date";
import { DatepickerDateType } from "@website/ui-old";
import { DayType, MonthType, gregorianToShamsi } from "@website/utils";

export const convertDate = (date: DatepickerDateType, toLocale: string) => {
  const newDate = parseDate(date.dateTime);
  let newDateConverted: AnyCalendarDate;

  switch (toLocale) {
    case "persian": {
      // @internationalize/date has issue with
      // convert from gregorian to persian.
      // this code fix that issue.
      // update it later.
      const temporaryDateConverted = gregorianToShamsi(
        newDate.year,
        newDate.month as MonthType,
        newDate.day as DayType
      );
      newDateConverted = new CalendarDate(
        new PersianCalendar(),
        temporaryDateConverted[0],
        temporaryDateConverted[1],
        temporaryDateConverted[2]
      );
      break;
    }
    case "hijri": {
      newDateConverted = toCalendar(newDate, new IslamicTabularCalendar());
      break;
    }
    default: {
      newDateConverted = toCalendar(newDate, new GregorianCalendar());
    }
  }

  return {
    dateTime: date.dateTime,
    year: newDateConverted.year,
    month: newDateConverted.month,
    day: newDateConverted.day
  };
};
