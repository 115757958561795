import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { FC, memo } from "react";
import { Actions, Input, MonthActions } from "..";
import { Button, TopAppBar, stringOfNumbers } from "../../..";
import styles from "./header.module.scss";
import { HeaderPropertiesType } from "./header.types";

const Header: FC<HeaderPropertiesType> = (properties) => {
  const {
    calendarLocale = "gregorian",
    showDates,
    inputProperties,
    scrolled,
    onClose,
    showToday,
    changeCalendarLocale,
    showPreviousMonth,
    showNextMonth,
    nextMonthDisabled,
    previousMonthDisabled,
    type,
    view
  } = properties;

  const { t, direction } = useTranslation();

  return (
    <div
      className={`${styles["header"]} ${styles[direction || "ltr"]} ${
        scrolled ? styles["scrolled"] : ""
      }`}
    >
      {!scrolled && (
        <div className={styles["top-app-bar"]}>
          <TopAppBar
            StartAction={
              <Button
                EndIcon={
                  <MaterialIcon name="arrow_forward" variant="outlined" />
                }
                color="neutral"
                equalDimension
                onClick={onClose}
                variant="text"
              />
            }
            title={t("calendar.selectDate")}
          />
        </div>
      )}
      <div className={styles["part-one"]}>
        {!scrolled && (
          <Actions
            calendarLocale={calendarLocale}
            changeCalendarLocale={changeCalendarLocale}
            showToday={showToday}
          />
        )}
        <div className={styles["input-container"]}>
          <Input {...inputProperties} disabled fullWidth />
        </div>
      </div>
      <MonthActions
        calendarLocale={calendarLocale}
        nextMonthDisabled={nextMonthDisabled}
        previousMonthDisabled={previousMonthDisabled}
        showDates={showDates}
        showNextMonth={showNextMonth}
        showPreviousMonth={showPreviousMonth}
        type={type}
      />
      <div
        className={`${styles["week-names-container-mobile"]} ${
          view === "mobile"
            ? ""
            : styles["week-names-container-mobile__default"]
        }`}
      >
        {[1, 2, 3, 4, 5, 6, 7].map((item) => (
          <div className={styles["week-name"]} key={item}>
            {t<string>(
              `calendar.${calendarLocale}.weekNamesAbbr.${stringOfNumbers[item]}`
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const HeaderMemoized = memo(Header);
export { HeaderMemoized as Header };
