import { FC, memo, useCallback } from "react";
import {
  NewPasswordCard,
  OtpCard,
  PasswordCard,
  PasswordChangeSuccessfulCard,
  PhoneNumberCard
} from "./components";
import styles from "./login.module.scss";
import { LoginPropertiesType, LoginStepType } from "./login.types";
import { useLogin } from "./use-login";

const Login: FC<LoginPropertiesType> = (properties) => {
  const { onEndAuth, onClose } = properties;
  const { step, setStep, userData, setUserData } = useLogin();

  const setStepHandler = useCallback(
    (step: LoginStepType) => {
      setStep(step);
    },
    [setStep]
  );

  return (
    <div className={styles["container"]}>
      {step === "phone-number" && (
        <PhoneNumberCard
          onClose={onClose}
          setStep={setStepHandler}
          setUserData={setUserData}
          userData={userData}
        />
      )}
      {step === "otp" && (
        <OtpCard
          onClose={onClose}
          onEndAuth={onEndAuth}
          setStep={setStepHandler}
          setUserData={setUserData}
          userData={userData}
        />
      )}
      {step === "password" && (
        <PasswordCard
          onClose={onClose}
          onEndAuth={onEndAuth}
          setStep={setStepHandler}
          setUserData={setUserData}
          userData={userData}
        />
      )}
      {step === "forgot-password" && (
        <NewPasswordCard
          onEndAuth={onEndAuth}
          setStep={setStepHandler}
          userData={userData}
        />
      )}
      {step === "password-change-successful" && (
        <PasswordChangeSuccessfulCard />
      )}
    </div>
  );
};

const LoginMemoized = memo(Login);
export { LoginMemoized as Login };
