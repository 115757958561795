import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { DigitalTextFieldCell } from "./digital-text-field-cell";
import { useDigitalTextField } from "./digital-text-field.hook";
import styles from "./digital-text-field.module.scss";
import { DigitalTextFieldPropertiesType } from "./digital-text-field.types";

const DigitalTextField: FC<DigitalTextFieldPropertiesType> = (properties) => {
  const { className, error, helperText } = properties;
  const { inputProps, cellsProps } = useDigitalTextField(properties);
  return (
    <div
      className={`${styles["digital-text-field"]} ${
        error ? styles["error"] : ""
      } ${className ?? ""}`}
    >
      <input className={styles["digital-text-field-input"]} {...inputProps} />
      <div className={styles["digital-text-field-box"]}>
        {cellsProps?.map((item, index) => (
          <DigitalTextFieldCell {...item} key={`DigitalTextField-${index++}`} />
        ))}
      </div>
      {helperText ? (
        <div className={styles["digital-text-field-helper-text"]}>
          {helperText}
        </div>
      ) : null}
    </div>
  );
};

const DigitalTextFieldMemoized = memo(DigitalTextField, deepCompare);
export { DigitalTextFieldMemoized as DigitalTextField };
