import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { useLimitTextItem } from "./limit-text.hook";
import styles from "./limit-text.module.scss";
import { LimitTextPropertiesType } from "./limit-text.types";

const LimitText: FC<LimitTextPropertiesType> = (properties) => {
  const {
    regionProperties,
    expanded,
    buttonProperties,
    limitedContent,
    contentIsLimited
  } = useLimitTextItem(properties);
  const { content, title, moreText, lessText } = properties;

  return (
    <div className={styles["item"]}>
      <h2 className={styles["title"]}>{title}</h2>
      <div {...regionProperties} className={styles["text"]}>
        {expanded
          ? content
          : `${limitedContent}${contentIsLimited ? "..." : ""}`}
      </div>

      {contentIsLimited ? (
        <button {...buttonProperties} className={styles["button"]}>
          {expanded ? (
            <span>{lessText || "نمایش کمتر"}</span>
          ) : (
            <span>{moreText || "نمایش بیشتر"}</span>
          )}
        </button>
      ) : null}
    </div>
  );
};

const LimitTextMemoized = memo(LimitText, deepCompare);
export { LimitTextMemoized as LimitText };
