import { flight_international } from "./flight_international/fa";
import { flight_international_visa } from "./flight_international_visa/fa";
import { home_ui } from "./home_ui/fa";
import { homepage } from "./homepage/fa";
import { hotel_international } from "./hotel_international/fa";
import { profile } from "./profile/fa";
import { ticket_ui } from "./ticket_ui/fa";

export const fa = {
  homepage,
  hotel_international,
  flight_international_visa,
  flight_international,
  profile,
  ticket_ui,
  services: {
    domestic_hotels: "هتل داخلی",
    intel_hotels: "هتل خارجی",
    domestic_flights: "پرواز داخلی",
    inter_flights: "پرواز خارجی",
    bus: "اتوبوس",
    stays: "ویلا",
    inter_hotels: "هتل خارجی",
    train: "قطار",
    tours: "تورهای مسافرتی"
  },
  header: {
    logo: "اسنپ تریپ",
    account: "حساب کاربری",
    download_app: "دانلود اپلیکیشن",
    track_order: "پیگیری خرید",
    normal_user: "بخش کاربران عادی",
    login: "ورود",
    sign_up: "ثبت نام",
    agencies: "آژانس ها و شرکت ها",
    travel_services: "خدمات مسافرتی",
    show_support_links: "نمایش لینک های پشتیبانی",
    faq: "سوالات متداول",
    call_support: "تماس با پشتیبانی",
    and: "و",
    track_modal: {
      description: "برای پیگیری خرید خود لطفا موارد زیر را وارد کنید.",
      select_service: "انتخاب سرویس",
      tracking_code: "کد پیگیری",
      phone_number: "شماره موبایل",
      track_order: "پیگیری خرید",
      no_response_was_found: "رزروی با این مشخصات پیدا نشد."
    }
  },
  header_jek: {
    purchase_modal: {
      title: "پیگیری خرید",
      tracking_code: "کد پیگیری",
      phone_number: "شماره موبایل",
      submit: "پیگیری خرید"
    }
  },
  footer: {
    title: "پشتیبانی ۲۴ ساعته و ۷ روز هفته",
    services: {
      title: "خدمات مسافرتی",
      hotel_domestic: "رزرو هتل",
      hotel_international: "رزرو هتل خارجی",
      flight_domestic: "خرید بلیط پرواز داخلی",
      flight_international: "خرید بلیط پرواز خارجی",
      bus: "خرید بلیط اتوبوس",
      train: "خرید بلیط قطار"
    },
    categories: {
      title: "دسته بندی ها",
      hotel: "هتل ها",
      tour: "تور های مسافرتی",
      flight: "بلیط هواپیما",
      train: "بلیط قطار",
      bus: "بلیط اتوبوس",
      travelogue: "سفرنامه"
    },
    more_info: {
      title: "اطلاعات بیشتر",
      about_us: "درباره‌ ما",
      contact_us: "تماس با ما",
      terms: "قوانین و مقررات",
      club: "باشگاه مشتریان اسنپ",
      faq: "سوالات متداول",
      careers: "فرصت های شغلی"
    },
    features: {
      title: "امکانات",
      panel: "فروش سازمانی",
      agency: "همکاری با آژانس",
      blog: "بلاگ اسنپ",
      app: "دانلود اپلیکیشن",
      discounts: "تخفیف ها",
      cancellation: "قوانین کنسلی",
      info: "اطلاعات"
    },
    contact_us: {
      title: "ارتباط با ما",
      address: "آدرس",
      address_value:
        "بلوار نلسون ماندلا، تقاطع آرش غربی، نبش کوچه ایرج، پلاک 115",
      zip_code: "کد پستی",
      phone_number: "تلفن"
    },
    social_media: {
      title: "شبکه های اجتماعی",
      description: "با ما در ارتباط باشید"
    },
    download_app: {
      title: "دانلود اپلیکیشن"
    },
    about: {
      title: "درباره ی اسنپ تریپ",
      description:
        "در اسنپ تریپ با چند کلیک می‌توانید پروازها و اقامتگاه‌های مختلف را بررسی کرده و با انتخاب مناسب‌ترین گزینه‌ها سفر خوشی را تجربه کنید."
    },
    copyright: {
      title:
        "از مطالب این وب‌سایت فقط می‌توانید برای مقاصد غیرتجاری و با ذکر منبع استفاده کنید.",
      company_name:
        " تمامی حقوق این وب‌سایت به شرکت آرا جهان نوین گستر اطلس تعلق دارد."
    },
    links_badge: {
      tehran_hotel: "هتل تهران",
      mashhad_hotel: "هتل مشهد",
      kish_hotel: "هتل کیش",
      isfahan_hotel: " هتل اصفهان",
      shiraz_hotel: "هتل شیراز",
      spinas_palace_hotel: "هتل اسپیناس پالاس",
      dariush_kish_hotel: "هتل داریوش کیش",
      charter_ticket: "بلیط چارتر",
      tahran_to_mashhad_ticket: "بلیط تهران مشهد",
      tehran_to_istanbul_ticket: "بلیط تهران استانبول",
      last_second_ticket: "بلیط لحظه آخری",
      tehran_dobay_ticket: "بلیط تهران دبی"
    }
  },
  calendar: {
    today: "امروز",
    startDate: "تاریخ شروع",
    endDate: "تاریخ پایان",
    selectDate: "انتخاب تاریخ",
    gregorian: {
      title: "میلادی",
      weekNames: {
        one: "دوشنبه",
        two: "سه‌شنبه",
        three: "چهارشنبه",
        four: "پنج‌شنبه",
        five: "جمعه",
        six: "شنبه",
        seven: "یک‌شنبه"
      },
      weekNamesAbbr: {
        one: "د",
        two: "س",
        three: "چ",
        four: "پ",
        five: "ج",
        six: "ش",
        seven: "ی"
      },
      monthNames: {
        one: "ژانویه",
        two: "فوریه",
        three: "مارس",
        four: "آوریل",
        five: "مه",
        six: "ژوئن",
        seven: "ژوئیه",
        eight: "اوت",
        nine: "سپتامبر",
        ten: "اکتبر",
        eleven: "نوامبر",
        twelve: "دسامبر"
      },
      monthNamesNative: {
        one: "January",
        two: "February",
        three: "March",
        four: "April",
        five: "May",
        six: "June",
        seven: "July",
        eight: "August",
        nine: "September",
        ten: "October",
        eleven: "November",
        twelve: "December"
      }
    },
    persian: {
      title: "پارسی",
      weekNames: {
        one: "شنبه",
        two: "یکشنبه",
        three: "دوشنبه",
        four: "سه‌شنبه",
        five: "چهارشنبه",
        six: "پنج‌شنبه",
        seven: "جمعه"
      },
      weekNamesAbbr: {
        one: "ش",
        two: "ی",
        three: "د",
        four: "س",
        five: "چ",
        six: "پ",
        seven: "ج"
      },
      monthNames: {
        one: "فروردین",
        two: "اردیبهشت",
        three: "خرداد",
        four: "تیر",
        five: "مرداد",
        six: "شهریور",
        seven: "مهر",
        eight: "آبان",
        nine: "آذر",
        ten: "دی",
        eleven: "بهمن",
        twelve: "اسفند"
      }
    }
  },
  action: {
    ok: "تایید",
    cancel: "انصراف",
    reset: "پاک کردن",
    search: "جستجو",
    changeTo: "تبدیل به"
  },

  hotel_domestic: {
    hotel_state: "هتل داخلی"
  },
  global: {
    snapp_trip: "اسنپ تریپ",
    hotel_images: "تصاویر هتل",
    hotel_title: "{title} {type}",
    hotel_policies: "قوانین {type}",
    facilities: "امکانات",
    rooms: "اتاق ها",
    room_calendar: "تقویم اتاق",
    policies: "قوانین",
    location: "موقعیت",
    rate_and_comments: "امتیاز و نظرات",
    comments: "نظرات",
    back: "بازگشت",
    try_again: "تلاش مجدد",
    back_to_page: "بازگشت به صفحه {page}",
    increase: "افزایش",
    decrease: "کاهش",
    please_wait: "لطفا منتظر بمانید",
    confirm: {
      title: "آیا مطمئن هستید؟",
      description: "",
      confirm: "تایید",
      cancel: "انصراف"
    },
    table: {
      empty_data: "رکوردی جهت نمایش وجود ندارد",
      item_selected: "{size} مورد انتخاب شده است"
    },
    orientation: {
      asc: "صعودی",
      desc: "نزولی"
    },
    pagination: {
      row: "{size} ردیف",
      range: "{from} تا {to} از {total_items}",
      view_previous_result: " مشاهده {count} نتیجه قبل",
      view_next_result: "مشاهده {count} نتیجه دیگر"
    },
    city_hotels: "هتل‌های {title}",
    city_villa: "اقامتگاه‌های {title}",
    hotel_reservation: "رزرو هتل",
    hotel_state: "هتل",
    passenger_count: "تعداد مسافر",
    passengers_and_rooms_count_label: "تعداد مسافر و اتاق",
    passengers_and_rooms_count: "{passengersCount} مسافر, {roomsCount} اتاق",
    children_count: "تعداد کودک",
    adults_count: "تعداد بزرگسال",
    rooms_count: "تعداد اتاق",
    adult_definition: "۱۲ سال به بالا",
    child_definition: "۲ تا ۱۲ سال",
    close: "بستن",
    view_more: "مشاهده بیشتر",
    more: "بیشتر",
    more_detail: "جزئیات بیشتر",
    less: "کمتر",
    night_count: "{count} شب",
    unavailable: "ناموجود",
    breakfast_included: "با صبحانه",
    extra_bed_included: "قابلیت تخت اضافه",
    booked_up: "ظرفیت تکمیل",
    similar_hotels: "هتل های مشابه",
    tooman_currency: "تومان",
    residence_count: " اقامتگاه {count}",
    about: "درباره",
    discount: "تخفیف",
    payment: "پرداخت",
    image_message: "تصویر پیام",
    full_capacity: "تکمیل",
    time_with_hour: "{time} ساعت",
    price_with_tooman_currency: "{price} تومان",
    filters: "فیلترها",
    filters_count: "فیلترها {count}",
    remove_filters_with_count: "حذف فیلترها ({count})",
    view_result_count: "مشاهده {count} نتیجه",
    from_date: "از تاریخ {date}",
    until_date: "تا تاریخ {date}",
    sort: "مرتب‌سازی",
    specifications: "مشخصات",
    calendar: "تقویم",
    start_price_of: "شروع قیمت از {price} تومان",
    city: "شهر",
    state: "استان",
    village: "روستا",
    area: "منطقه",
    country: "کشور"
  },
  booking: {
    request: "درخواست رزرو",
    booker_information: "اطلاعات رزرو کننده",
    supervisor_information: "اطلاعات سرپرست",
    notification_of_changes:
      "اطلاعات رزرو و اطلاع ‌رسانی از تمام تغییرات را به این شماره می‌فرستیم.",
    hotel_requisition_description:
      " ما درخواستتان را به هتل منتقل می کنیم. (انجام شدن درخواست به شرایط هتل بستگی دارد.)",
    hotel_requisition_placeholder:
      "مثال: نیاز به تجهیزات ویژه برای معلولین مانند ویلچر یا دستگیره های کمکی",
    hotel_requisition: "درخواست از هتل",
    capacity_check: "بررسی ظرفیت",
    passengers_info: "اطلاعات مسافران",
    search_other_residences: "جستجوی سایر اقامتگاه ها",
    availability_pending: {
      title: "در حال بررسی ظرفیت",
      description1: "همکاران ما در حال بررسی ظرفیت اتاق شما هستند.",
      description2:
        "در صورت موجود بودن ظرفیت میتوانند رزرو خود را پرداخت و نهایی کنید.",
      buttonText: { text: "لطفا منتظر بمانید", shortText: "لطفا منتظر بمانید" }
    },
    availability_confirmed: {
      title: "درخواست ظرفیت توسط هتل تایید شد",
      description1: "درخواست رزرو شما توسط هتل پذیرفته شد.",
      description2:
        "برای ادامه فرایند خرید خود تا لحظاتی دیگر به مرحله بعد منتقل خواهید شد.",
      description3: "در صورت عدم انتقال خودکار، دکمه بررسی و پرداخت را بزنید.",
      buttonText: {
        text: "در حال انتقال به صفحه بررسی و پرداخت",
        shortText: "در حال انتقال به صفحه بررسی و پرداخت"
      }
    },
    user_confirmed_info: {
      title: "درخواست ظرفیت توسط میزبان تایید شد",
      description1: "درخواست رزرو شما توسط میزبان پذیرفته شد.",
      description2:
        "برای ادامه فرایند خرید خود تا لحظاتی دیگر به مرحله بعد منتقل خواهید شد.",
      description3: "در صورت عدم انتقال خودکار، دکمه بررسی و پرداخت را بزنید.",
      buttonText: {
        text: "در حال انتقال به صفحه بررسی و پرداخت",
        shortText: "در حال انتقال به صفحه بررسی و پرداخت"
      }
    },
    availability_expired: {
      title: "عدم پاسخگویی اقامتگاه",
      description1: "متاسفانه اقامتگاه در زمان معین پاسخگو نبود.",
      description2: " همکاران ما در حال پیگیری ظرفیت اتاق هستند.",
      description3: "در صورت عدم تمایل میتوانید اقامتگاه دیگری را رزرو نمایید.",
      buttonText: {
        text: "جستجوی سایر اقامتگاه ها",
        shortText: "اقامتگاه پاسخگو نیست"
      }
    },
    availability_rejected: {
      title: "عدم ظرفیت",
      description1: "متاسفانه اتاق مورد نظر شما در تاریخ انتخابی ظرفیت ندارد.",
      description2: "میتوانید اقامتگاه دیگری را رزرو نمایید.",
      buttonText: {
        text: "جستجوی سایر اقامتگاه ها",
        shortText: "عدم ظرفیت"
      }
    }
  },
  fields: {
    firstName: "نام",
    lastName: "نام خانوادگی",
    phoneNumber: "شماره موبایل",
    email: "ایمیل",
    purchaserName: "نام رزرو کننده",
    purchaserFamily: "نام خانوادگی رزرو کننده",
    purchaserMobileNumber: "شماره موبایل رزرو کننده",
    purchaserEmail: "ایمیل رزرو کننده",
    supervisorName: "نام سرپرست",
    supervisorFamily: "نام خانوادگی سرپرست",
    supervisorEmail: "ایمیل سرپرست",
    supervisorMobileNumber: "شماره موبایل سرپرست",
    isPurchaserSupervisor: "سرپرست مسافران خودم هستم",
    requisition: "درخواست از هتل",
    passengerInfo: {
      mainPassengerName: "نام مسافر",
      mainPassengerFamily: "نام خانوادگی مسافر",
      mainPassengerNationality: "ملیت مسافر",
      mainPassengerNationalCode: "کدملی مسافر",
      mainPassengerMobileNumber: "شماره موبایل مسافر",
      extraBedCount: "تعداد تخت اضافه",
      childCount: "تعداد کودک",
      infantCount: "تعداد نوزاد"
    },
    otherPassengersInfo: {
      mainPassengerName: "نام مسافر",
      mainPassengerFamily: "نام خانوادگی مسافر"
    },
    hasFreeTransfer: "ترانسفر رایگان",
    freeTransferNumber: "شماره {type}",
    freeTransferDeparture: "شهر مبدا {type}",
    freeTransferMobileNumber: "شماره موبایل",
    freeTransferPassengerCount: "تعداد نفرات",
    buyer: {
      mobileNumber: "شماره موبایل رزرو کننده",
      firstName: "نام رزرو کننده",
      lastName: "نام خانوادگی رزرو کننده"
    },
    roomGuest: {
      passengers: {
        firstName: "نام مسافر",
        lastName: "نام خانوادگی مسافر",
        gender: "جنسیت مسافر",
        birthDate: "تاریخ تولد مسافر",
        expireDate: "تاریخ انقضای گذرنامه",
        passportCode: "شماره گذرنامه",
        documentType: "ملیت مسافر",
        documentId: "کدملی مسافر"
      }
    }
  },
  validations: {
    mixed: {
      default: "فرمت {path} صحیح نمی‌باشد",
      required: "{path} الزامی می‌باشد",
      defined: "{path} باید تعریف شده باشد",
      notNull: "{path} باید وارد شود",
      oneOf: "{path} باید یکی از مقادیر {values} باشد",
      notOneOf: "{path} نباید یکی از مقادیر {values} باشد",
      notType: "فرمت {path} باید {type} باشد",
      // custom
      fileType: "فرمت {path} باید {type} باشد",
      fileSize: "حجم {path} باید حداکثر {size} مگابایت باشد"
    },
    string: {
      length: "{path} باید {length} کاراکتر باشد",
      min: "{path} باید حداقل {min} کاراکتر باشد",
      max: "{path} باید حداکثر {max} کاراکتر باشد",
      matches: "فرمت {path} صحیح نمی‌باشد",
      email: "{path} باید یک ایمیل معتبر باشد",
      url: "{path} باید یک آدرس معتبر باشد",
      uuid: "{path} باید یک آیدی معتبر باشد",
      trim: "{path} نباید شامل فاصله‌ها باشد",
      lowercase: "{path} باید حروف کوچک باشد",
      uppercase: "{path} باید حروف بزرگ باشد",
      // custom
      persian: "{path} باید فارسی باشد",
      english: "{path} باید انگلیسی باشد",
      arabic: "{path} باید عربی باشد",
      alphabet: "{path} نباید شامل اعداد و کاراکترهای خاص باشد",
      password: "{path} باید شامل حروف بزرگ و کوچک و اعداد باشد",
      mobile: "فرمت {path} باید صحیح باشد",
      passportExpireDate: "گذرنامه مسافر باید حداقل ۶ ماه اعتبار داشته باشد.",
      nationalCode: "کدملی مسافر را به صورت صحیح وارد کنید"
    },
    number: {
      min: "{path} باید حداقل {min} رقم باشد",
      max: "{path} باید حداکثر {max} رقم باشد",
      lessThan: "{path} باید کمتر از {less} رقم باشد",
      moreThan: "{path} باید بیشتر از {more} رقم باشد",
      positive: "{path} باید بزرگتر از صفر باشد",
      negative: "{path} باید کوچکتر از صفر باشد",
      integer: "{path} باید عدد صحیح باشد"
    },
    date: {
      min: "{path} باید حداقل {min} باشد",
      max: "{path} باید حداکثر {max} باشد"
    },
    boolean: {
      isValue: "{path} باید {value} باشد"
    },
    object: {
      noUnknown: "{path} شامل فیلدهای نامشخص است"
    },
    array: {
      min: "{path} باید حداقل {min} تعداد باشد",
      max: "{path} باید حداکثر {max} تعداد باشد",
      length: "{path} باید {length} تعداد باشد"
    }
  },
  validations_types: {
    string: "متن",
    number: "عدد",
    date: "تاریخ",
    boolean: "صحیح/غلط",
    object: "آبجکت",
    array: "چندتایی"
  },
  search: {
    search_result: "نتایج جستجو",
    period_determination: "تعیین بازه اقامت",
    not_found_result: "نتیجه ای یافت نشد.",
    not_found_result_for_query: "نتیجه ای برای {query} پیدا نکردیم.",
    search_with_another_word: "با کلمه ی دیگری جستجو کنید.",
    rooms_and_passengers_determination: "تعیین تعداد مسافر و اتاق",
    period_and_passengers_determination: "تعیین بازه اقامت و مسافران",
    city_or_hotel_destination: "شهر یا هتل مقصد",
    city_or_villa_destination: "شهر یا اقامتگاه مقصد",
    recent_searches: "جستجو های اخیر",
    search_update: "بروزرسانی جستجو",
    search_update_message:
      "قیمت و ظرفیت هتل‌ها دائما در حال تغییر هستند. برای اطمینان از آخرین قیمت و وضعیت هتل‌ها٬ لطفا جستجو را بروز‌رسانی نمایید.",
    checkin_date: "تاریخ ورود",
    checkout_date: "تاریخ خروج",
    warning_message:
      "برای مشاهده قیمت دقیق اقامتگاه‌ها٬ تاریخ و تعداد مسافر را وارد کنید.",
    information_completion: "تکمیل اطلاعات",
    residence: "{count} اقامتگاه",
    searching: "در حال جستجو هستیم",
    search_placeholder: "جستجوی {label}",
    children_count_validation:
      "سقف تعداد کودک به ازای هر بزرگسال ۱۲ نفر می باشد",
    passengers_count_validation:
      "سقف مجموع تعداد مسافر برای هر اتاق ۱۵ نفر می باشد.",
    destination_validation: "لطفا مقصد خود را انتخاب نمایید.",
    date_validation: "لطفا تاریخ ورود و خروج خود را انتخاب نمایید.",
    between_m_and_n_years: "{age_one} تا {age_two} سال",
    valid_max_adult_count:
      "سقف تعداد بزرگسال برای هر اتاق {count} نفر می باشد.",
    valid_max_child_count: "سقف تعداد کودک برای هر اتاق {count} نفر می باشد.",
    child_age_is_mandatory: "سن کودک اجباری است",
    room: "اتاق",
    delete_title: "حذف",
    add_room: "افزودن اتاق",
    under_year: "تا {year} سال",
    year: "{year} سال",
    child_age: "سن کودک {num}",
    number_of_adult: "تعداد بزرگسال",
    number_of_child: "تعداد کودک",
    greather_than_n_years: "{age} سال به بالا",
    to_n_years: "تا {age} سال",
    number_of_passenger_and_room: "تعداد مسافر و اتاق"
  },
  gallery: {
    image_count: "{count} تصویر در گالری",
    hotel_images: "گالری تصاویر هتل",
    villa_images: "گالری تصاویر اقامتگاه",
    image_is_not_available: "تصویر در دسترس نیست!"
  },
  policies: {
    residence_policies: "قوانین {residence}",
    checkin_date: "تاریخ ورود",
    checkout_date: "تاریخ خروج",
    before_noon: "{time}  قبل از ظهر",
    after_noon: "{time}  بعد از ظهر",
    at_noon: "{time} ظهر"
  },
  categories: {
    residence_labels: "برچسب های این اقامتگاه"
  },
  comments: {
    positive_point: "نقاط قوت",
    negative_point: "نقاط ضعف",
    submit_comments: "ثبت دیدگاه",
    passengers_comments: "نظرات مسافران",
    view_more_comments: "مشاهده نظرات بیشتر",
    night_count: "({night} شب)",
    passengers_comments_for_residence:
      "نظرات و امتیاز {residence} از دید مسافران",
    description:
      "در صورت اقامت در این اقامتگاه نظر و امتیاز خود را ثبت کنید تا دیگران با اطمینان بیشتری اقامتگاه مناسب برای سفرشان را انتخاب کنند‌."
  },
  rate: {
    rate_count: "{count} نظر",
    rate_residence: "امتیاز {residence} از دید کاربران اسنپ‌تریپ",
    passengers_offered_count:
      "{count} مسافر اخیر این اقامتگاه را پیشنهاد داده اند."
  },
  recommendation: {
    view_all: "مشاهده همه",
    similar_hotels: "هتل های مشابه",
    description: "برای دیدن آفر های هر شهر لطفا مقصد خود را انتخاب کنید"
  },
  suggestion: {
    suggested_hotels: "هتل های پیشنهادی"
  },
  hotel: {
    view_all_facilities: "مشاهده همه امکانات",
    hotel_facilities: "امکانات {hotelType}",
    room: {
      change_data: "تغییر تاریخ",
      search_text: "پیشنهاد بر اساس جستجو و فیلترهای انتخابی شما",
      not_found_text: "اتاقی یافت نشد، لطفا تاریخ دیگری را جستجو کنید!",
      is_not_active: "هتل مورد نظر غیرفعال است!",
      available_rooms: "اتاق های دارای ظرفیت",
      not_available_rooms: "اتاق های تکمیل ظرفیت",
      suitable_rooms: "مناسب ترین اتاق ها برای شما",
      view_all_not_available_rooms: "مشاهده اتاق های تکمیل ظرفیت",
      select_package: "انتخاب بسته",
      reserve_room: "رزرو {count} اتاق",
      min_room_count: "برای رزرو این اتاق حداقل {count} شب را انتخاب کنید",
      min_room_count_twenty:
        "{hotelType} در تاریخ انتخابی شما، امکان پذیرش مسافر ندارد. لطفا تاریخ دیگری انتخاب کنید.",
      change_date: "تغییر تاریخ",
      price_for_night: "قیمت برای {count} شب",
      currency_for_night: "تومان برای {count} شب",
      available_calendar: "تقویم موجودی",
      check_availability: "بررسی وضعیت موجودی اتاق از روی تقویم",
      special_package: "بسته های ویژه",
      package_text:
        " با انتخاب هر یک از بسته‌های ویژه زیر تاریخ ورود و خروج‌تان را مشخص کنید.",
      non_refundable: "غیرقابل استرداد",
      free_cancellation: "کنسلی رایگان",
      cancellation_policies: "قوانین کنسلی",
      cancellation_time: "شرایط زمانی کنسلی",
      cancellation_cost: "هزینه کنسلی",
      cancellation_modal_btn: "متوجه شدم",
      first_room: "اتاق اول",
      second_room: "اتاق دوم",
      third_room: "اتاق سوم",
      fourth_room: "اتاق چهارم",
      fifth_room: "اتاق پنجم",
      sixth_room: "اتاق ششم",
      seventh_room: "اتاق هفتم",
      eighth_room: "اتاق هشتم",
      ninth_room: "اتاق نهم",
      tenth_room: "اتاق دهم"
    },
    map: {
      title: "موقیت های مکانی و دسترسی های {title}",
      text: "مراکز نزدیک به {type} را میتوانید از روی نقشه مشاهده کنید."
    }
  },
  hotel_card: {
    view_and_reserve: "مشاهده و رزرو",
    pictures: "تصاویر هتل",
    star_count: "{count} ستاره"
  },
  hotel_cities: {
    search_text: "در حال پیدا کردن بهترین هتل ها برای شما هستیم.",
    searching: "در حال جستجو ...",
    searching_with_city_title: "جستجوی هتل های {cityTitle} ...",
    change_destination: "تغییر مقصد",
    remove_filters: "حذف فیلترها",
    not_found_hotels_in_city:
      "متاسفانه هتل و اقامتگاه فعالی در شهر {cityTitle} یافت نشد. لطفا شهرهای دیگر را جستجو کنید.",
    not_found_filtered_data:
      "متاسفانه در حال حاضر هتل و اقامتگاهی متناسب با فیلتر های انتخابی شما وجود ندارد .",
    not_found_result: "نتیجه‌ای یافت نشد.",
    not_found_data: "متاسفانه اطلاعاتی یافت نشد.",
    back_to_main_page: "بازگشت به صفحه‌ی اصلی",
    error: "خطایی رخ داده است!",
    "hotel-domestic": {
      searching_in_city_title: "در حال جستجوی اقامتگاه های شهر {cityTitle} ...",
      residence_count_with_city_title: "{count} اقامتگاه در شهر {cityTitle}",
      residence_count_without_city_title: "{count} اقامتگاه در {cityTitle}"
    },
    "hotel-international": {
      searching_in_city_title: "در حال جستجوی اقامتگاه های شهر {cityTitle} ...",
      residence_count_with_city_title: "اقامتگاه های شهر {cityTitle}",
      residence_count_without_city_title: "اقامتگاه ها در {cityTitle}"
    },
    villa: {
      searching_in_city_title: "در حال جستجوی ویلا های شهر {cityTitle} ...",
      residence_count_with_city_title: "{count} اقامتگاه در شهر {cityTitle}",
      residence_count_without_city_title: "{count} اقامتگاه در {cityTitle}"
    },
    tour: {
      searching_in_city_title: "در حال جستجوی اقامتگاه های شهر {cityTitle} ...",
      residence_count_with_city_title: "{count} اقامتگاه در شهر {cityTitle}",
      residence_count_without_city_title: "{count} اقامتگاه در {cityTitle}"
    }
  },
  free_transfer: {
    description:
      "با انتخاب این سرویس، ترانسفر شما به هتل به صورت رایگان توسط هتل انجام می شود.",
    pick_up_location: "محل سوار شدن",
    airport: "فرودگاه",
    railway: "راه آهن",
    people_count: "{count} نفر",
    policy: "نکات ضروری:",
    content:
      "درخواست ترانسفر رایگان از {location} با شماره {type} {transferNumber} از شهر مبدا {departure} برای {count} نفر  را دارم. لطفا برای هماهنگی با شماره همراه {mobile} تماس بگیرید "
  },
  payment: {
    download_voucher: "دانلود ووچر و رسید رزرو",
    back_to_origin_page: "رفتن به صفحه اصلی",
    expiration_popup_title: "اتمام فرصت پرداخت",
    expiration_popup_text:
      "زمان پرداخت این رزرو به اتمام رسیده است. برای اطلاع از آخرین قیمت و وضعیت پروازها، لطفا دوباره جستجو کنید.",
    expiration_popup_button: "جستجوی مجدد",
    tracking_code: "کد پیگیری : {code}",
    error: "خطایی رخ داده است دوباره تلاش کنید!"
  },
  passengers: {
    passenger_warning:
      "برای هر اتاق صرفا وارد کردن اطلاعات سرپرست کافی است و نیاز به ثبت سایر مسافران نیست.",
    previous_passengers: {
      passenger_list: "لیست مسافران",
      search_placeholder: "جستجو نام، نام خانوادگی، کد ملی و شماره گذرنامه",
      name_family: "نام و نام خانوادگی",
      national_code: "کد ملی",
      passport_number: "شماره گذرنامه",
      select: "انتخاب",
      no_result: "نتیجه ای یافت نشد..."
    }
  },
  villa: {
    residence: "اقامتگاه",
    villa_and_residence: "ویلا و اقامتگاه",
    villa: "ویلا",
    apartment: "آپارتمان",
    cottage: "کلبه",
    guesthouse: "مهمان خانه",
    motel: "متل",
    hotel: "هتل",
    villa_garden: "باغ ویلا",
    hotel_apartment: "هتل آپارتمان",
    traditional_hotel: "هتل سنتی",
    residential_complex: "مجتمع مسکونی",
    suite: "سوییت",
    hostel: "خوابگاه",
    ecotourism: "اکوتوریسم",
    price_per_night: "قیمت از شبی",
    checkin_date: "تاریخ ورود",
    checkout_date: "تاریخ خروج",
    number_of_passengers: "تعداد مسافر",
    price_with_passangers_count: "قیمت {night} شب، {count} نفر ",
    extra_person: "{count} نفر اضافه",
    total_amount_payable: "جمع مبلغ قابل پرداخت",
    booking: {
      requisition_description:
        "ما درخواستتان را به میزبان منتقل می کنیم. (انجام شدن درخواست به شرایط اقامتگاه بستگی دارد.)",
      requisition_placeholder: "مثال: درخواست ضدعفونی کردن اقامتگاه",
      request_registration: "ثبت درخواست",
      host_confirmation: "تایید میزبان",
      user_confirmation: "تایید اطلاعات",
      payment_and_reservation: "پرداخت و رزرو",
      requisition: "در خواست از میزبان",
      main_passenger_information: "to do",
      disabled:
        "امکان رزرو اقامتگاه در تاریخ و تعداد مسافر مشخص شده، وجود ندارد"
    }
  },
  seo: {
    faq: "پاسخ به برخی از سوالات",
    planning_next_trip: "سفر بعدیت رو از الان برنامه ریزی کن"
  },
  tele_sale: {
    reserve_with_phone_button: "رزرو تلفنی",
    request_phone_reserve_title: "ثبت درخواست رزرو تلفنی",
    request_phone_reserve_body:
      "با کلیک روی دکمه زیر در اسرع وقت کارشناسان ما با شما تماس خواهند گرفت و شما را برای رزرو هتل راهنمایی خواهند کرد.",
    submit_request: "ثبت درخواست",
    request_sent: "درخواست شما با موفقیت ثبت شد.",
    request_already_sent_before:
      "درخواست شما قبلا ثبت شده است و به زودی کارشناسان ما با شما تماس خواهند گرفت."
  },
  last_second: {
    main_title: "هتل‌های لحظه آخری",
    main_title_city_page: "هتل‌های لحظه آخری {cityTitle}",
    main_title_hotel_page: "تخفیف لحظه آخری",
    until_end: "تا پایان",
    all_last_seconds_of: "همه لحظه آخری‌های {city}",
    only_left_number_of_rooms: "فقط {count} اتاق باقی مانده",
    tonight_price: "قیمت امشب",
    adults_count: "{adults} نفر"
  },
  home_ui,
  login: {
    general_error_message: "خطایی رخ داده، دوباره تلاش کنید.",
    enter_your_password: "رمز عبور را وارد کنید.",
    enter_code: "کد را وارد کنید",
    new_password: "رمز عبور جدید",
    accept_continue: "تایید و ادامه",
    please_enter_new_password: "لطفاً یک رمز عبور جدید وارد کنید.",
    entering_otp: "وارد کردن کد تایید",
    send_otp_to_number: "کد تایید را به شماره {number} فرستادیم",
    number_is_wrong: "شماره موبایل اشتباه است؟",
    edit: "ویرایش",
    dont_receive_verification_code: "کد تایید را دریافت نکردم ",
    send_again: "ارسال دوباره",
    send_again_verification_code: "ارسال کد تایید تا ",
    accept_and_enter_to_snapptrip: "تایید و ورود به اسنپ‌تریپ!",
    call_support: "تماس با پشتیبانی"
  }
};
