import { AxiosResponse } from "axios";
import { Axios } from "../../utils";
import { BASE_URL_PROFILE } from "../urls";
import {
  AuthVerifyOtpBodyType,
  AuthVerifyOtpResponseType
} from "./auth-verify-otp.types";

export const AuthVerifyOtpUrl = `${BASE_URL_PROFILE}/auth/verify-otp`;

export const AuthVerifyOtpService = (
  body: AuthVerifyOtpBodyType
): Promise<AuthVerifyOtpResponseType> =>
  Axios.post(AuthVerifyOtpUrl, body).then(
    ({ data }: AxiosResponse<AuthVerifyOtpResponseType>) => data
  );
