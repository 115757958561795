import Link from "next/link";
import { FC, memo } from "react";
import { Loading } from "..";
import { useButton } from "./button.hook";
import styles from "./button.module.scss";
import { ButtonPropertiesType } from "./button.types";

const Button: FC<ButtonPropertiesType> = (properties) => {
  const {
    children,
    className,
    color = "primary",
    disabled,
    EndIcon,
    equalDimension,
    fullWidth,
    loading,
    role,
    size = "medium",
    StartIcon,
    variant = "contained",
    text,
    replace,
    id,
    loadingColor = "white"
  } = properties;
  const { buttonProps, linkProps } = useButton(properties);
  const classNames = `${styles["button"]} ${styles[variant]} ${styles[color]} ${
    disabled ? styles["disabled"] : ""
  } ${styles[size]} ${fullWidth ? styles["full-width"] : ""} ${
    equalDimension ? styles["equal-dimension"] : ""
  } ${children || text ? styles["icon-small"] : styles["icon-medium"]} ${
    className ?? ""
  }`;

  const elements = loading ? (
    <Loading color={loadingColor} size="sm" />
  ) : (
    <>
      {StartIcon ? <span className={styles["icon"]}>{StartIcon}</span> : null}
      {text ? <span className={styles["text"]}>{text}</span> : null}
      {children ? <span className={styles["children"]}>{children}</span> : null}
      {EndIcon ? <span className={styles["icon"]}>{EndIcon}</span> : null}
    </>
  );

  return role === "link" ? (
    <a {...linkProps} className={classNames}>
      {elements}
    </a>
  ) : role === "next-link" ? (
    <Link
      {...linkProps}
      className={classNames}
      id={id}
      replace={(replace && replace) || false}
    >
      {elements}
    </Link>
  ) : (
    <button {...buttonProps} className={classNames}>
      {elements}
    </button>
  );
};

const ButtonMemoized = memo(Button);
export { ButtonMemoized as Button };
