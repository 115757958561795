import { useTranslation } from "@website/locale";
import { useCallback, useMemo, useState } from "react";

export const useSaleType = () => {
  const { t } = useTranslation();
  const [saleType, setSaleType] = useState<string>("one-way");

  const saleTypeList = useMemo(
    () => [
      { title: t("homepage.flights.main_search.one_way"), value: "one-way" },
      {
        title: t("homepage.flights.main_search.round_trip"),
        value: "round-trip"
      }
    ],
    [t]
  );

  const onChangeSaleType = useCallback((value: string) => {
    setSaleType(value);
  }, []);

  const onChangeDatepickerMode = useCallback((value: string) => {
    if (value === "single") {
      setSaleType("one-way");
    } else if (value === "same-start-end") {
      setSaleType("round-trip");
    }
  }, []);

  return { saleType, saleTypeList, onChangeSaleType, onChangeDatepickerMode };
};
