import { RadioButtonChecked, RadioButtonUnchecked } from "@website/icons";
import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { useRadio } from "./radio.hook";
import styles from "./radio.module.scss";
import { RadioPropertiesType } from "./radio.types";

const Radio: FC<RadioPropertiesType> = (properties) => {
  const { inputProps } = useRadio(properties);
  const { className, color, disabled, label, fullWidthLabel } = properties;

  return (
    <label
      className={`${styles["radio"]} ${disabled ? styles["disabled"] : ""} ${
        className ?? ""
      }`}
    >
      <input {...inputProps} className={styles["input"]} />
      <div className={styles["box"]}>
        <RadioButtonUnchecked className={styles["un-checked-icon"]} />
        <RadioButtonChecked
          className={`${styles["checked-icon"]} ${styles[color || "primary"]}`}
        />
        <div className={`${styles["ripple"]} ${styles[color || "primary"]}`} />
      </div>
      <div
        className={`${styles["label"]} ${
          fullWidthLabel && styles["full-width-label"]
        }`}
        hidden={!label}
      >
        {label}
      </div>
    </label>
  );
};

const RadioMemoized = memo(Radio, deepCompare);
export { RadioMemoized as Radio };
