import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { Modal } from "@website/ui";
import { deepCompare } from "@website/utils";
import { memo } from "react";
import { ReserveGalleryDetail } from "../../reserve-gallery-detail";
import { useReserveGalleryDetailContainer } from "./reserve-gallery-detail-container.hooks";
import { ReserveGalleryDetailContainerPropertiesTypes } from "./reserve-gallery-detail-container.types";

const Component = ({
  title,
  gallery,
  isModalOpen,
  onClose
}: ReserveGalleryDetailContainerPropertiesTypes) => {
  const { containerProps } = useReserveGalleryDetailContainer({
    templateNumber: 4,
    galleryLength: gallery?.length ?? 0
  });
  const { t } = useTranslation();

  return (
    <div className={isModalOpen ? "" : "hidden"}>
      <Modal fullScreen onClose={onClose} open={isModalOpen}>
        <div className="border-b-outline-thin flex h-[64px] w-full border-b px-2 md:px-6 xl:px-[75px] 2xl:px-[calc((100%-1200px)/2)]">
          <div className="flex h-full flex-row items-center justify-start gap-2 lg:max-w-[1200px]">
            <MaterialIcon
              className="text-on-surface-medium-emphasis cursor-pointer text-2xl"
              name="arrow_forward"
              onClick={onClose}
            />
            <span className="text-subtitle-1">
              {title || t("gallery.hotel_images")}
            </span>
          </div>
        </div>
        <div className="grid h-full grid-cols-2 gap-2 overflow-y-auto p-2 lg:px-[calc((100%-750px)/2)] lg:py-6">
          {containerProps?.map(({ index, rowCount }) => (
            <ReserveGalleryDetail
              gallery={gallery}
              index={index}
              key={`HotelReserveGalleryDetailContainer-${++index}`}
              rowCount={rowCount}
            />
          ))}
        </div>
      </Modal>
    </div>
  );
};
export const ReserveGalleryDetailContainer = memo(Component, deepCompare);
