import { useAddRemoveQuery } from "@website/hooks";
import {
  Arrowright,
  CloseRounded,
  Search,
  TripOriginSmall
} from "@website/icons";
import { useRouter } from "next/router";
import { ChangeEvent, MouseEvent, useCallback } from "react";
import { Button } from "../..";
import { AutoCompletePropertiesType } from "../auto-complete.types";

export const useStart = (properties: AutoCompletePropertiesType) => {
  const { parts } = properties;

  const router = useRouter();
  const key = "SEARCH_END";
  const { [key]: isKey } = router.query;
  const { addQuery, removeQuery } = useAddRemoveQuery(key);

  const onCloseStartPopover = useCallback(() => {
    removeQuery();
  }, [removeQuery]);

  const onClickStartTextField = useCallback(() => {
    addQuery("open");
    parts[0]?.onFocus?.();
  }, [addQuery, parts]);

  const onChangeStartPopoverTextField = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      parts[0]?.onChangeQuery && parts[0]?.onChangeQuery(event.target.value);
    },
    [parts]
  );

  const onClickStartItem = useCallback(
    (item: any) => {
      // parts[0]?.onChangeQuery && parts[0]?.onChangeQuery(item.title);
      parts[0]?.onSelect && parts[0]?.onSelect(item.id);
      removeQuery();
    },
    [parts, removeQuery]
  );

  const onEmptyStartSearchField = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      parts[0]?.onChangeQuery && parts[0]?.onChangeQuery("");
      // parts[0]?.onSelect && parts[0]?.onSelect("");
    },
    [parts]
  );

  const onClickHistoryItem = useCallback(
    (item: any) => {
      parts[0]?.onSelectHistory && parts[0]?.onSelectHistory(item.id);
      removeQuery();
    },
    [parts, removeQuery]
  );

  return {
    startTextFieldProperties: {
      disabled: parts[0]?.disabled,
      label: parts[0]?.text ? parts[0]?.label : "",
      value: parts[0]?.text,
      readOnly: true,
      placeholder: parts[0]?.label,
      onClick: onClickStartTextField,
      StartIcon: parts[0]?.StartIcon ?? <TripOriginSmall />
    },
    startPopoverProperties: {
      animate: "bottom" as const,
      open: Boolean(isKey),
      onClose: onCloseStartPopover,
      position: "start" as const
    },
    startTopAppBarProperties: {
      StartAction: (
        <Button
          EndIcon={<Arrowright />}
          color="neutral"
          equalDimension
          onClick={onCloseStartPopover}
          variant="text"
        />
      ),
      title: `جستجوی ${parts[0]?.label}`
    },
    startPopoverSearchFieldProperties: {
      autoFocus: true,
      placeholder: `جستجوی ${parts[0]?.label}`,
      fullWidth: true,
      onChange: onChangeStartPopoverTextField,
      value: parts[0]?.query,
      StartIcon: parts[0]?.StartIcon ?? <TripOriginSmall />,
      EndIcon: parts[0]?.query ? <CloseRounded /> : <Search />,
      onEndIconClick: parts[0]?.query ? onEmptyStartSearchField : undefined
    },
    startItemsProperties:
      parts[0]?.list?.map((item: any) => ({
        ...item,
        role: "button",
        size: "large",
        onClick: () => onClickStartItem(item)
      })) || [],
    startHistoryItemsProperties:
      parts[0]?.historyList?.map((item: any) => ({
        ...item,
        role: "button",
        size: "large",
        onClick: () => onClickHistoryItem(item)
      })) || []
  };
};
