import { useTranslation } from "@website/locale";
import { useCallback, useState } from "react";

export const usePassengerCount = () => {
  const { t } = useTranslation();
  const [temporaryPassengerCount, setTemporaryPassengerCount] = useState<{
    adult: number;
    child: number;
    infant: number;
  }>({
    adult: 1,
    child: 0,
    infant: 0
  });

  const [passengerCount, setPassengerCount] = useState(temporaryPassengerCount);

  const { adult, child, infant } = passengerCount;

  const total = adult + child + infant;
  const remain = 9 - total;
  const remainNotAdult = Math.min(remain, adult * 3 - child - infant);

  const adultMax = adult + remain;
  const adultMin = Math.max(Math.ceil((child + infant) / 3), infant, 1);
  const childMax = child + remainNotAdult;
  const childMin = 0;
  const infantMax = infant + Math.min(remainNotAdult, adult - infant);
  const infantMin = 0;

  const onSubmitPassengerCount = useCallback(() => {
    setPassengerCount((state) => {
      setTemporaryPassengerCount(state);
      return state;
    });
  }, []);

  const onChangeAdult = useCallback((value: number) => {
    setPassengerCount((state) => ({
      ...state,
      adult: value
    }));
  }, []);

  const onChangeChild = useCallback((value: number) => {
    setPassengerCount((state) => ({
      ...state,
      child: value
    }));
  }, []);

  const onChangeInfant = useCallback((value: number) => {
    setPassengerCount((state) => ({
      ...state,
      infant: value
    }));
  }, []);

  const onClosePassengerButton = useCallback(() => {
    setTemporaryPassengerCount((state) => {
      setPassengerCount(state);
      return state;
    });
  }, []);

  const onClickBackDropHandler = useCallback(() => {
    setPassengerCount((state) => {
      setTemporaryPassengerCount(state);
      return state;
    });
  }, []);

  const list = [
    {
      increaseDisabled: adult >= adultMax,
      decreaseDisabled: adult <= adultMin,
      title: t("homepage.flights.main_search.number_of_adult"),
      subtitle: t("homepage.flights.main_search.greather_than_n_years", {
        age: 12
      }),
      value: adult,
      onChange: onChangeAdult
    },
    {
      increaseDisabled: child >= childMax,
      decreaseDisabled: child <= childMin,
      title: t("homepage.flights.main_search.number_of_child"),
      subtitle: t("homepage.flights.main_search.between_m_and_n_years", {
        age_one: 2,
        age_two: 12
      }),
      value: child,
      onChange: onChangeChild
    },
    {
      increaseDisabled: infant >= infantMax,
      decreaseDisabled: infant <= infantMin,
      title: t("homepage.flights.main_search.number_of_infant"),
      subtitle: t("homepage.flights.main_search.between_m_and_n_years", {
        age_one: 0,
        age_two: 2
      }),
      value: infant,
      onChange: onChangeInfant
    }
  ];

  return {
    passengerCountTitle: t("homepage.flights.main_search.number_of_passenger"),
    passengerCountResult: t("homepage.flights.main_search.x_passenger", {
      passenger_count:
        passengerCount.adult + passengerCount.child + passengerCount.infant
    }),
    passengerCountList: list,
    passengerCount,
    onSubmitPassengerCount,
    onClickBackDropHandler,
    onClosePassengerButton
  };
};
