/* eslint-disable unicorn/prevent-abbreviations */
import { Range } from "@website/ui";
import { numberConvert } from "@website/utils";
import { isArray, toNumber } from "lodash";
import { useRouter } from "next/router";
import { Fragment, useEffect, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { TimeTypes } from "../filter.types";
import styles from "../index.module.scss";

interface TimeFilterProperties extends TimeTypes {}

export const TimeFilter = (properties: TimeFilterProperties) => {
  const {
    title,
    description,
    min = [0, 0],
    max,
    step,
    min_key,
    max_key,
    type
  } = properties;
  const router = useRouter();
  const { control, setValue } = useFormContext();

  const value = useWatch({ control });

  const bounds = useMemo(() => {
    const maybeMinSrc = router.query[min_key[0]];
    const maybeMinDest = router.query[min_key[1]];

    const maybeMaxSrc = router.query[max_key[0]];
    const maybeMaxDest = router.query[max_key[1]];

    const minAsNumberSrc = maybeMinSrc ? toNumber(maybeMinSrc) : undefined;
    const maxAsNumberSrc = maybeMaxSrc ? toNumber(maybeMaxSrc) : undefined;
    const minAsNumberDest = maybeMinDest ? toNumber(maybeMinDest) : undefined;
    const maxAsNumberDest = maybeMaxDest ? toNumber(maybeMaxDest) : undefined;

    return { minAsNumberSrc, maxAsNumberDest, maxAsNumberSrc, minAsNumberDest };
  }, [max_key, min_key, router.query]);

  useEffect(() => {
    setValue(min_key[0], {
      type,
      withDebounce: false,
      value: bounds.minAsNumberSrc
    });
    setValue(min_key[1], {
      type,
      withDebounce: false,
      value: bounds.minAsNumberDest
    });
    setValue(max_key[0], {
      type,
      withDebounce: false,
      value: bounds.maxAsNumberSrc
    });
    setValue(max_key[1], {
      type,
      withDebounce: false,
      value: bounds.maxAsNumberDest
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bounds, max_key, min_key, type]);

  return (
    <Fragment>
      <div className={styles["filter-range-title"]}>{title}</div>
      {description?.map((item, index) => (
        <div className="pb-4">
          <label
            className={styles["filter-range-description"]}
            htmlFor="range_total_price"
          >
            {item}
          </label>
          <div className={styles["filter-range-price"]}>
            <span>
              {`${numberConvert(
                String(value[min_key[index]]?.value || min[index]),
                {
                  locale: router.locale
                }
              )}:۰۰`}
            </span>
            <span className="text-extra-caption">
              {`${numberConvert(
                String(value[max_key[index]]?.value || max[index]),
                {
                  locale: router.locale
                }
              )}:۰۰`}
            </span>
          </div>
          <Range
            color="secondary"
            id="range_total_price"
            max={Number(max[index])}
            min={Number(min[index])}
            onChange={(event, eventType) => {
              if (eventType === "minOnChange") {
                setValue(min_key[index], {
                  type,
                  withDebounce: true,
                  value: isArray(event)
                    ? event[0] === Number(min[index])
                      ? undefined
                      : `${event[0]}`
                    : event
                });
              } else {
                setValue(max_key[index], {
                  type,
                  withDebounce: true,
                  value: isArray(event)
                    ? event[1] === Number(max[index])
                      ? undefined
                      : `${event[1]}`
                    : event
                });
              }
            }}
            step={step}
            value={[
              value[min_key[index]]?.value || min[index],
              value[max_key[index]]?.value || max[index]
            ]}
          />
        </div>
      ))}
    </Fragment>
  );
};
