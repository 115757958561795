import { useTranslation } from "@website/locale";
import { useRouter } from "next/router";
import { useMemo } from "react";

export const useToggle = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const query = new URLSearchParams(
    router.query as Record<string, string>
  ).toString();
  const { pathname } = router;
  const channel = process.env.NEXT_PUBLIC_CHANNEL_TYPE;

  const toggleList = useMemo(
    () => [
      {
        text: t("homepage.services.bus_domestic"),
        value: `/bus${query ? `?${query}` : ""}`,
        role: "next-link" as const,
        href: `/bus${query ? `?${query}` : ""}`,
        replace: channel === "jek"
      },
      {
        text: t("homepage.services.bus_international"),
        value: `/bus-international${query ? `?${query}` : ""}`,
        role: "next-link" as const,
        href: `/bus-international${query ? `?${query}` : ""}`,
        replace: channel === "jek"
      }
    ],
    [channel, query, t]
  );

  const toggleValue = `${pathname}${query ? `?${query}` : ""}`;

  return {
    toggleList,
    toggleValue
  };
};
