import { useViewPort } from "@website/hooks";
import { throttle } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";

export const useScroll = () => {
  // const scrollReference = useRef<HTMLDivElement>(null);
  const [scrollReference, setScrollReference] = useState<any>(null);
  const [scrolled, setScrolled] = useState<boolean>(false);
  const scrollPosition = useRef<number>(0);
  const { isDesktop } = useViewPort();

  useEffect(() => {
    const scrollListener = () => {
      if (scrollReference && scrollReference && scrollReference.scrollTop) {
        if (scrolled && scrollPosition.current > scrollReference.scrollTop) {
          setScrolled(false);
        } else if (
          !scrolled &&
          scrollPosition.current < scrollReference.scrollTop
        ) {
          setScrolled(true);
        }
        scrollPosition.current = scrollReference.scrollTop;
      }
    };

    const scrollListenerThrottle = throttle(scrollListener, 100);

    if (scrollReference && !isDesktop) {
      scrollReference.addEventListener("scroll", scrollListenerThrottle);
    }

    return () => {
      if (scrollReference && !isDesktop) {
        scrollReference.removeEventListener("scroll", scrollListenerThrottle);
      }
    };
  }, [scrollReference, scrolled, isDesktop]);

  const scrollToTop = useCallback(() => {
    if (scrollReference && !isDesktop) {
      scrollReference.scrollTo(0, 0);
    }
  }, [scrollReference, isDesktop]);

  return {
    scrollReference,
    setScrollReference,
    scrolled,
    setScrolled,
    scrollToTop
  };
};
