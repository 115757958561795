import { omit } from "lodash";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useState } from "react";
import { TabsPropertiesType } from "./tabs.types";

export const useTab = (properties: TabsPropertiesType) => {
  const {
    defaultTab,
    onChange,
    tabs,
    color,
    iconPosition,
    variant,
    size,
    behavior,
    orientation,
    queryKey
  } = properties;
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const router = useRouter();
  const tabIndex =
    ((queryKey &&
      router?.query?.[queryKey] &&
      router?.query?.[queryKey]) as string) || 0;

  useEffect(() => {
    const selected =
      tabs[Number(defaultTab)] === undefined ||
      tabs[Number(defaultTab)]?.disabled === true
        ? tabs.findIndex(({ disabled }) => !disabled)
        : defaultTab;

    setSelectedTab(selected || 0);
  }, [tabs, defaultTab]);

  useEffect(() => {
    if (router?.isReady && queryKey) {
      const tab = router?.query?.[queryKey] as string;
      if (Boolean(tab) === false) {
        router?.push(
          {
            pathname: router?.pathname,
            query: { ...router?.query, [queryKey]: tab || 0 }
          },
          undefined,
          { shallow: true, scroll: false }
        );
      }
    }
  }, [queryKey, router]);

  const onClickTabHandler = useCallback(
    (id: number) => {
      if (queryKey) {
        router?.push(
          {
            pathname: router?.pathname,
            query: { ...router?.query, [queryKey]: id }
          },
          undefined,
          { shallow: true, scroll: false }
        );
      }
    },
    [queryKey, router]
  );

  const tabBarTabs = useMemo(
    () =>
      tabs.map((item, index) => ({
        ...omit(item, "panel"),
        onClick: () => {
          setSelectedTab(index);
          queryKey && onClickTabHandler(index);
          onChange?.(index);
        }
      })),
    [tabs, queryKey, onClickTabHandler, onChange]
  );

  return {
    selectedTab,
    tabBarProperties: {
      tabs: tabBarTabs,
      value: queryKey ? Number(tabIndex) || 0 : selectedTab,
      color,
      iconPosition,
      variant,
      size,
      behavior,
      orientation
    }
  };
};
