import {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { SliderPropertiesType } from "./slider.types";

const SWIPE_PERCENTAGE = 15;

export const useSlider = (properties: SliderPropertiesType) => {
  const { list } = properties;

  const sliderList = useMemo(
    () =>
      list.map((item, index) => ({
        component: item,
        ref: createRef<HTMLDivElement>(),
        itemIndex: index,
        onDotClick: (index: any) => {
          const selectedSlider = sliderList[index].ref.current;
          if (
            !selectedSlider ||
            !containerReference.current ||
            !currentSlideReference.current ||
            disabled.current ||
            index === currentSlideIndex.current
          ) {
            return;
          }

          containerReference.current.style.height = `${currentSlideReference.current.clientHeight}px`;
          currentSlideReference.current.style.position = "";

          const isNext = index > currentSlideIndex.current;
          disabled.current = true;
          selectedSlider.style.left = isNext ? "-100%" : "100%";
          selectedSlider.style.display = "block";

          setTimeout(() => {
            if (currentSlideReference.current) {
              currentSlideReference.current.style.left = isNext
                ? "100%"
                : "-100%";
              selectedSlider.style.left = "0";
              pastSlideIndex.current = currentSlideIndex.current;
              currentSlideIndex.current = index;
              setActiveIndex(index);
            }
          }, 100);
        }
      })),
    [list]
  );

  const [activeIndex, setActiveIndex] = useState(0);
  const currentSlideIndex = useRef(0);
  const pastSlideIndex = useRef(-1);
  const direction = useRef("");

  const containerReference = useRef<HTMLDivElement>(null);
  const currentSlideReference = useRef<HTMLDivElement | null>(null);
  const nextSlideReference = useRef<HTMLDivElement | null>(null);
  const previousSlideReference = useRef<HTMLDivElement | null>(null);

  const disabled = useRef(false);
  const swipeIsActive = useRef(false);
  const hasSwiped = useRef(false);

  const currentSlidePosition = useRef({ first: 0, second: 0 });

  const getNextAvailableIndex = useCallback(
    (index: number) => (index < sliderList.length - 1 ? index + 1 : 0),
    [sliderList.length]
  );

  const getPreviousAvailableIndex = useCallback(
    (index: number) => (index > 0 ? index - 1 : sliderList.length - 1),
    [sliderList.length]
  );

  const onClick = useCallback((event: any) => {
    if (disabled.current) {
      event.preventDefault();
    }
  }, []);

  const transitionendHandler = useCallback(() => {
    if (
      pastSlideIndex.current !== -1 &&
      pastSlideIndex.current !== currentSlideIndex.current
    ) {
      const pastSlideElement = sliderList[pastSlideIndex.current].ref.current;
      pastSlideElement?.removeAttribute("style");
      pastSlideIndex.current = currentSlideIndex.current;
    } else {
      previousSlideReference.current?.removeAttribute("style");
      nextSlideReference.current?.removeAttribute("style");
    }
    disabled.current = false;
    containerReference.current?.removeAttribute("style");
    if (currentSlideReference.current) {
      currentSlideReference.current.style.position = "static";
    }
  }, [sliderList]);

  useEffect(() => {
    const previousIndex = getPreviousAvailableIndex(activeIndex);
    const nextIndex = getNextAvailableIndex(activeIndex);

    currentSlideReference.current = sliderList[activeIndex]?.ref?.current;
    previousSlideReference.current = sliderList[previousIndex]?.ref?.current;
    nextSlideReference.current = sliderList[nextIndex]?.ref?.current;

    const currentSlideElement = currentSlideReference.current;
    if (currentSlideElement && sliderList.length > 1) {
      currentSlideElement.addEventListener(
        "transitionend",
        transitionendHandler
      );
    }

    return () => {
      if (currentSlideElement && sliderList.length > 1) {
        currentSlideElement.removeEventListener(
          "transitionend",
          transitionendHandler
        );
      }
    };
  }, [
    activeIndex,
    getNextAvailableIndex,
    getPreviousAvailableIndex,
    sliderList,
    transitionendHandler
  ]);

  const pointerdownHandler = useCallback((event: any) => {
    event.preventDefault();
    if (disabled.current) {
      return;
    }
    swipeIsActive.current = true;
    currentSlidePosition.current.second = event.clientX;
  }, []);

  const pointermoveHandler = useCallback((event: any) => {
    event.preventDefault();
    if (
      !swipeIsActive.current ||
      !containerReference.current ||
      !currentSlideReference.current ||
      !nextSlideReference.current ||
      !previousSlideReference.current ||
      disabled.current
    ) {
      return;
    }

    hasSwiped.current = true;

    const firstPositions = currentSlidePosition.current.second - event.clientX;
    const secondPosition = event.clientX;
    const { offsetLeft } = currentSlideReference.current;
    const { offsetWidth } = currentSlideReference.current;

    const swipingToNext =
      direction.current === "rtl" ? offsetLeft > 0 : offsetLeft < 0;
    const nextSlideLeftOffset =
      direction.current === "rtl"
        ? offsetLeft - offsetWidth - firstPositions
        : offsetLeft + offsetWidth - firstPositions;
    const previousSlideLeftOffset =
      direction.current === "rtl"
        ? offsetLeft + offsetWidth - firstPositions
        : offsetLeft - offsetWidth - firstPositions;

    currentSlidePosition.current.first = firstPositions;
    currentSlidePosition.current.second = secondPosition;

    currentSlideReference.current.style.transition = "none";
    currentSlideReference.current.style.left = `${
      offsetLeft - firstPositions
    }px`;

    containerReference.current.style.height = `${currentSlideReference.current.clientHeight}px`;
    currentSlideReference.current.style.position = "";

    if (swipingToNext) {
      nextSlideReference.current.style.transition = "none";
      nextSlideReference.current.style.display = "block";
      nextSlideReference.current.style.left = `${nextSlideLeftOffset}px`;
    } else {
      previousSlideReference.current.style.transition = "none";
      previousSlideReference.current.style.display = "block";
      previousSlideReference.current.style.left = `${previousSlideLeftOffset}px`;
    }
  }, []);

  const previous = useCallback(() => {
    pastSlideIndex.current = currentSlideIndex.current;
    currentSlideIndex.current = getPreviousAvailableIndex(
      currentSlideIndex.current
    );
    setActiveIndex(currentSlideIndex.current);
  }, [getPreviousAvailableIndex]);

  const next = useCallback(() => {
    pastSlideIndex.current = currentSlideIndex.current;
    currentSlideIndex.current = getNextAvailableIndex(
      currentSlideIndex.current
    );
    setActiveIndex(currentSlideIndex.current);
  }, [getNextAvailableIndex]);

  const animateToPrevious = useCallback(() => {
    if (
      !containerReference.current ||
      !currentSlideReference.current ||
      !previousSlideReference.current ||
      disabled.current
    ) {
      return;
    }

    const previousSlideLeftOffset =
      direction.current === "rtl" ? "100%" : "-100%";
    const currentSlideLeftOffset =
      direction.current === "rtl" ? "-100%" : "100%";

    disabled.current = true;
    containerReference.current.style.height = `${currentSlideReference.current.clientHeight}px`;
    currentSlideReference.current.style.position = "";
    previousSlideReference.current.style.display = "block";
    previousSlideReference.current.style.left = previousSlideLeftOffset;

    setTimeout(() => {
      if (currentSlideReference.current && previousSlideReference.current) {
        currentSlideReference.current.style.left = currentSlideLeftOffset;
        previousSlideReference.current.style.left = "0";
        previous();
      }
    }, 100);
  }, [previous]);

  const pointerupHandler = useCallback(() => {
    swipeIsActive.current = false;

    if (!hasSwiped.current) {
      return;
    }

    if (
      !currentSlideReference.current ||
      !nextSlideReference.current ||
      !previousSlideReference.current
    ) {
      return;
    }

    const { offsetLeft } = currentSlideReference.current;
    const { offsetWidth } = currentSlideReference.current;

    const swipedPercent = Math.floor((offsetLeft * 100) / offsetWidth);
    const shouldSwipeToNext =
      direction.current === "rtl"
        ? swipedPercent > SWIPE_PERCENTAGE
        : swipedPercent < -SWIPE_PERCENTAGE;
    const shouldSwipeToPrevious =
      direction.current === "rtl"
        ? swipedPercent < -SWIPE_PERCENTAGE
        : swipedPercent > SWIPE_PERCENTAGE;

    disabled.current = true;
    currentSlideReference.current.style.transition = "";

    if (shouldSwipeToNext) {
      previousSlideReference.current.removeAttribute("style");
      currentSlideReference.current.style.left =
        direction.current === "rtl" ? "100%" : "-100%";
      nextSlideReference.current.style.transition = "";
      nextSlideReference.current.style.left = "0";
      next();
    } else if (shouldSwipeToPrevious) {
      nextSlideReference.current.removeAttribute("style");
      currentSlideReference.current.style.left =
        direction.current === "rtl" ? "-100%" : "100%";
      previousSlideReference.current.style.transition = "";
      previousSlideReference.current.style.left = "0";
      previous();
    } else {
      currentSlideReference.current.style.left = "0";
      if (
        (direction.current === "rtl" && offsetLeft > 0) ||
        (direction.current === "ltr" && offsetLeft < 0)
      ) {
        nextSlideReference.current.style.left =
          direction.current === "rtl" ? "-100%" : "100%";
        nextSlideReference.current.style.transition = "";
      } else {
        previousSlideReference.current.style.left =
          direction.current === "rtl" ? "100%" : "-100%";
        previousSlideReference.current.style.transition = "";
      }
    }

    hasSwiped.current = false;
  }, [next, previous]);

  const animateToNext = useCallback(() => {
    if (
      !containerReference.current ||
      !currentSlideReference.current ||
      !nextSlideReference.current ||
      disabled.current
    ) {
      return;
    }

    const nextSlideLeftOffset = direction.current === "rtl" ? "-100%" : "100%";
    const currentSlideLeftOffset =
      direction.current === "rtl" ? "100%" : "-100%";

    disabled.current = true;
    containerReference.current.style.height = `${currentSlideReference.current.clientHeight}px`;
    currentSlideReference.current.style.position = "";
    nextSlideReference.current.style.display = "block";
    nextSlideReference.current.style.left = nextSlideLeftOffset;

    setTimeout(() => {
      if (currentSlideReference.current && nextSlideReference.current) {
        currentSlideReference.current.style.left = currentSlideLeftOffset;
        nextSlideReference.current.style.left = "0";
        next();
      }
    }, 100);
  }, [next]);

  const onKeydown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "ArrowLeft") {
        animateToNext();
      } else if (event.key === "ArrowRight") {
        animateToPrevious();
      }
    },
    [animateToNext, animateToPrevious]
  );

  useEffect(() => {
    const containerElement = containerReference?.current;
    if (sliderList.length > 1 && containerElement) {
      containerElement.addEventListener("keydown", onKeydown);
      containerElement.addEventListener("pointerdown", pointerdownHandler);
      containerElement.addEventListener("pointerup", pointerupHandler);
      containerElement.addEventListener("pointerleave", pointerupHandler);
      containerElement.addEventListener("pointermove", pointermoveHandler);
      direction.current = getComputedStyle(containerElement).direction;
    }

    const currentSlideElement = sliderList?.[0]?.ref?.current;
    if (currentSlideElement) {
      currentSlideElement.style.position = "initial";
      currentSlideElement.style.display = "block";
    }

    return () => {
      if (sliderList.length > 1 && containerElement) {
        containerElement.removeEventListener("keydown", onKeydown);
        containerElement.removeEventListener("pointerdown", pointerdownHandler);
        containerElement.removeEventListener("pointerup", pointerupHandler);
        containerElement.removeEventListener("pointerleave", pointerupHandler);
        containerElement.removeEventListener("pointermove", pointermoveHandler);
      }
    };
  }, [
    onKeydown,
    pointerdownHandler,
    pointermoveHandler,
    pointerupHandler,
    sliderList
  ]);

  return {
    containerProperties: {
      ref: containerReference,
      "aria-label": "slider",
      tabIndex: 0,
      onClick: onClick
    },
    previousButtonProperties: {
      color: "neutral" as const,
      onClick: animateToPrevious,
      variant: "round" as const,
      "aria-label": "previous slide",
      equalDimension: true
    },
    nextButtonProperties: {
      color: "neutral" as const,
      onClick: animateToNext,
      variant: "round" as const,
      "aria-label": "previous slide",
      equalDimension: true
    },
    activeIndex,
    sliderList
  };
};
