import { deepCompare } from "@website/utils";
import { FC, Fragment, memo } from "react";
import { useSwitch } from "./switch.hook";
import styles from "./switch.module.scss";
import { SwitchPropertiesType } from "./switch.types";

const Switch: FC<SwitchPropertiesType> = (properties) => {
  const { inputProps } = useSwitch(properties);
  const { className, color, disabled, label, error, helperText } = properties;

  return (
    <Fragment>
      <span>
        <label
          className={`${styles["container"]} ${
            disabled ? styles["disabled"] : ""
          } ${styles[color || "primary"]} ${className ?? ""} ${
            error ? styles["error"] : ""
          }`}
          data-error={error}
          data-testid="switch-wrapper"
        >
          <span className={styles["input-wrapper"]}>
            <input {...inputProps} className={styles["input"]} />
            <span className={styles["slider"]} />
            <span className={styles["knob"]} />
          </span>
          <span className={styles["label"]} hidden={!label}>
            {label}
          </span>
        </label>
      </span>
      {helperText ? (
        <span className={styles["helper-text"]}>{helperText}</span>
      ) : null}
    </Fragment>
  );
};

const SwitchMemoized = memo(Switch, deepCompare);
export { SwitchMemoized as Switch };
