import { deepCompare } from "@website/utils";
import { FC, memo, PropsWithChildren } from "react";
import { useModal } from "./modal.hook";
import styles from "./modal.module.scss";
import { ModalPropertiesType } from "./modal.types";

const Modal: FC<PropsWithChildren<ModalPropertiesType>> = (properties) => {
  const {
    animate = "bottom",
    curve = true,
    children,
    fullHeight,
    datatestid
  } = properties;
  const { sheetProperties, animateState } = useModal(properties);
  if (
    animateState === "initial" ||
    animateState === "entered" ||
    animateState === "entering" ||
    animateState === "exiting"
  ) {
    return (
      <div
        className={`${styles["container"]} ${styles[animate]} ${styles[animateState]}`}
        data-testid={datatestid || "modal-container"}
      >
        <div className={styles["overlay"]} />
        <div
          {...sheetProperties}
          className={`${styles["sheet"]} ${
            fullHeight ? styles["full-height"] : ""
          } ${styles[curve ? "curve" : ""]}`}
        >
          {children}
        </div>
      </div>
    );
  }
  return null;
};

const ModalMemoized = memo(Modal, deepCompare);
export { ModalMemoized as Modal };
