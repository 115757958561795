import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { ChangeEvent, MouseEvent, useCallback, useState } from "react";
import { Button } from "../..";
import { AutoCompletePropertiesType } from "../auto-complete.types";

export const useStart = (properties: AutoCompletePropertiesType) => {
  const { parts, size } = properties;

  const [isOpenStartPopover, setIsOpenStartPopover] = useState<boolean>(false);
  const { t } = useTranslation();

  const onCloseStartPopover = useCallback(() => {
    setIsOpenStartPopover(false);
  }, []);

  const onClickStartTextField = useCallback(() => {
    setIsOpenStartPopover(true);
  }, []);

  const onChangeStartPopoverTextField = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      parts[0]?.onChangeQuery && parts[0]?.onChangeQuery(event.target.value);
    },
    [parts]
  );

  const onClickStartItem = useCallback(
    (item: any) => {
      // parts[0]?.onChangeQuery && parts[0]?.onChangeQuery(item.title);
      parts[0]?.onSelect && parts[0]?.onSelect(item.id);
      setIsOpenStartPopover(false);
    },
    [parts]
  );

  const onEmptyStartSearchField = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      parts[0]?.onChangeQuery && parts[0]?.onChangeQuery("");
      // parts[0]?.onSelect && parts[0]?.onSelect("");
    },
    [parts]
  );

  const onClickHistoryItem = useCallback(
    (item: any) => {
      parts[0]?.onSelectHistory && parts[0]?.onSelectHistory(item.id);
      setIsOpenStartPopover(false);
    },
    [parts]
  );

  return {
    startTextFieldProperties: {
      size,
      label: parts[0]?.text ? parts[0]?.label : "",
      value: parts[0]?.text,
      readOnly: true,
      placeholder: parts[0]?.label,
      onClick: onClickStartTextField,
      StartIcon: parts[0]?.StartIcon ?? (
        <MaterialIcon name="location_on" variant="fill" />
      )
    },
    startPopoverProperties: {
      animate: "bottom" as const,
      open: isOpenStartPopover,
      onClose: onCloseStartPopover,
      position: "start" as const
    },
    startTopAppBarProperties: {
      StartAction: (
        <Button
          EndIcon={<MaterialIcon name="arrow_forward" variant="outlined" />}
          color="neutral"
          equalDimension
          onClick={onCloseStartPopover}
          variant="text"
        />
      ),
      title: `جستجوی ${parts[0]?.label}`
    },
    startPopoverSearchFieldProperties: {
      autoFocus: true,
      placeholder: t("search.search_placeholder", { label: parts[0]?.label }),
      fullWidth: true,
      onChange: onChangeStartPopoverTextField,
      value: parts[0]?.query,
      StartIcon: parts[0]?.StartIcon ?? (
        <MaterialIcon name="location_on" variant="outlined" />
      ),
      EndIcon: parts[0]?.query ? (
        <MaterialIcon name="clear" variant="outlined" />
      ) : (
        <MaterialIcon name="search" variant="outlined" />
      ),
      onEndIconClick: parts[0]?.query ? onEmptyStartSearchField : undefined
    },
    startItemsProperties:
      parts[0]?.list.map((item: any) => ({
        ...item,
        role: "button",
        size: "large",
        onClick: () => onClickStartItem(item)
      })) || [],
    startHistoryItemsProperties:
      parts[0]?.historyList?.map((item: any) => ({
        ...item,
        role: "button",
        size: "large",
        onClick: () => onClickHistoryItem(item)
      })) || []
  };
};
