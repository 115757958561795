import { ChangeEvent, useCallback, useRef, useState } from "react";
import {
  DigitalTextFieldCellPropertiesType,
  DigitalTextFieldPropertiesType
} from "./digital-text-field.types";

export const useDigitalTextField = (
  properties: DigitalTextFieldPropertiesType
) => {
  const inputReference = useRef<HTMLInputElement>(null);
  const [hasFocus, setHasFocus] = useState<boolean>(false);
  const { onChange, error, value, variant, num } = properties;

  const onChangeInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value_ = event.target.value;
      if (value_.length <= num && !Number.isNaN(Number(value_))) {
        onChange(value_);
      }
    },
    [num, onChange]
  );

  const onClickCell = useCallback(() => {
    if (inputReference?.current?.focus) {
      inputReference.current.focus();
    }
  }, []);

  const onFocusInput = useCallback(() => {
    setHasFocus(true);
  }, []);

  const onBlurInput = useCallback(() => {
    setHasFocus(false);
  }, []);

  const cellsProperties: Array<DigitalTextFieldCellPropertiesType> = [];
  for (let index = 0; index < num; index++) {
    cellsProperties.push({
      error: error || false,
      text: value[index] || "",
      variant,
      onClick: onClickCell,
      focus:
        hasFocus &&
        (value.length === index || (value.length === num && index === num - 1))
    });
  }

  return {
    cellsProps: cellsProperties,
    inputProps: {
      ref: inputReference,
      onFocus: onFocusInput,
      onBlur: onBlurInput,
      type: "text",
      onChange: onChangeInput,
      value
    }
  };
};
