import { useDebounce } from "@website/hooks";
import { Origin } from "@website/icons";
import { useTranslation } from "@website/locale";
import { useCallback, useMemo, useState } from "react";
import { NoResult } from "../../../..";
import { fixPersianWords } from "../../common/utils";
import { useStationsService } from "../services";

export const useDestination = () => {
  const { t } = useTranslation();
  const [destinationQuery, setDestinationQuery] = useState<string>("");
  const [selectedDestination, setSelectedDestination] = useState<string>("");
  const [selectedDestinationItem, setSelectedDestinationItem] = useState(null);
  const query = useDebounce(destinationQuery, 250);

  const suggestions = useMemo(
    () => [
      {
        nameFa: "تهران",
        provinceName: "تهران",
        code: 1
      },
      {
        nameFa: "مشهد",
        provinceName: "خراسان رضوی",
        code: 191
      },
      {
        nameFa: "تبریز",
        provinceName: "آذربایجان شرقی",
        code: 55
      },
      {
        nameFa: "یزد",
        provinceName: "یزد",
        code: 219
      },
      {
        nameFa: "بندر عباس",
        provinceName: "هرمزگان",
        code: 37
      }
    ],
    []
  );
  const { isLoading, data } = useStationsService();

  const onChangeDestinationQuery = useCallback((value: string) => {
    setDestinationQuery(value);
  }, []);

  const destinationList = useMemo(
    () =>
      destinationQuery.length >= 2
        ? data?.filter(
            (item) =>
              fixPersianWords(item?.nameFa).includes(query) ||
              item.code.toString().toLowerCase().includes(query)
          ) || []
        : suggestions || [],
    [destinationQuery.length, data, suggestions, query]
  );

  const destinationListNormalized = useMemo(
    () =>
      destinationList.map((item) => ({
        id: item.code,
        title: item.nameFa,
        subtitle: item?.provinceName || item.countryName,
        StartIcon: <Origin />
      })),
    [destinationList]
  );

  const onSelectDestination = useCallback(
    (id: string) => {
      setSelectedDestination(id);
      const destinationItem = destinationList.find(
        (destination: any) => `${destination.code}` === `${id}`
      );
      setDestinationQuery("");
      setSelectedDestinationItem(destinationItem);
    },
    [destinationList]
  );

  const onChangeDestination = useCallback((item: any) => {
    setSelectedDestination(item?.code || "");
    setDestinationQuery("");
    setSelectedDestinationItem(item);
  }, []);

  return {
    selectedDestinationItem,
    selectedDestinationText: selectedDestinationItem?.nameFa || "",
    destinationQuery,
    selectedDestination,
    onChangeDestination,
    onSelectDestination,
    onChangeDestinationQuery,
    destinationList,
    destinationLoading: isLoading,
    destinationListNormalized,
    DestinationNoResult:
      destinationQuery?.length >= 2 &&
      !isLoading &&
      destinationList &&
      destinationList.length === 0 ? (
        <NoResult query={destinationQuery} />
      ) : undefined,
    destinationLabel: t("homepage.train.main_search.destination")
  };
};
