import axios from "axios";

/**
 * =======
 *  Axios
 * =======
 */
const Axios = axios.create({
  timeout: 50_000,
  headers: { "content-type": "application/json" }
});

/**
 * ===============
 *  Axios No Auth
 * ===============
 */
const AxiosNoAuth = axios.create({
  timeout: 50_000,
  headers: { "content-type": "application/json" }
});

const AxiosLastSecondWithChannel = axios.create({
  timeout: 50_000,
  headers: { "content-type": "application/json" }
});

const AxiosXAccesToken = (xAccessToken: string) =>
  axios.create({
    timeout: 50_000,
    headers: {
      "content-type": "application/json",
      "x-access-token": xAccessToken
    }
  });

export { Axios, AxiosLastSecondWithChannel, AxiosNoAuth, AxiosXAccesToken };
