import { AxiosResponse } from "axios";
import { AxiosXAccesToken } from "../../../../utils";
import {
  ChangeTokenBodyType,
  ChangeTokenResponseType
} from "./change-token.types";

export const ChangeTokenUrl = `${process.env.NEXT_PUBLIC_BASE_URL_FLIGHT}/flights/api/b2b/v1/auth`;
const XAccessToken = process.env.NEXT_PUBLIC_X_ACCESS_TOKEN_JEK;
export const ChangeTokenService = (
  body: ChangeTokenBodyType
): Promise<ChangeTokenResponseType> =>
  AxiosXAccesToken(XAccessToken)
    .post(ChangeTokenUrl, body)
    .then(({ data }: AxiosResponse<ChangeTokenResponseType>) => data);
