import { useLocalStorage } from "@website/hooks";
import { History } from "@website/icons";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  TRAIN_ROUTE_HISTORY_LOCAL_STORAGE_KEY,
  TRAIN_SEARCH_HISTORY_LOCAL_STORAGE_KEY
} from "../search-train.constant";

export const useHistory = (
  onChangeOrigin: (item: any) => void,
  onChangeDestination: (item: any) => void
) => {
  const [history, setHistory] = useState<
    Array<{ origin: any; destination: any }>
  >([]);
  const { addToLocalStorage, getFromLocalStorage } = useLocalStorage();

  useEffect(() => {
    const historyString = getFromLocalStorage(
      TRAIN_ROUTE_HISTORY_LOCAL_STORAGE_KEY
    );
    const history = historyString ? JSON.parse(historyString) : [];
    setHistory(history);
  }, [getFromLocalStorage]);

  const addToRouteHistory = useCallback(
    (origin, destination) => {
      const historyString = getFromLocalStorage(
        TRAIN_ROUTE_HISTORY_LOCAL_STORAGE_KEY
      );
      const history = historyString ? JSON.parse(historyString) : [];

      let newHistory = history.filter(
        (item) =>
          item.origin.code !== origin.code &&
          item.destination.code !== destination.code
      );

      const newHistoryItem = {
        origin,
        destination
      };
      newHistory = [newHistoryItem, ...newHistory].slice(0, 2);

      addToLocalStorage(
        TRAIN_ROUTE_HISTORY_LOCAL_STORAGE_KEY,
        JSON.stringify(newHistory)
      );
      setHistory(newHistory);
    },
    [addToLocalStorage, getFromLocalStorage]
  );

  const addToSearchHistory = useCallback(
    (
      origin: any,
      destination: any,
      startDateTime: any,
      endDateTime: any,
      calendarLocale: string,
      saleType: any,
      passengerNumber: any,
      salonType: any,
      coupeType: any
    ) => {
      const historyString = getFromLocalStorage(
        TRAIN_SEARCH_HISTORY_LOCAL_STORAGE_KEY
      );
      const history = historyString ? JSON.parse(historyString) : [];

      let newHistory = history.filter(
        (item) =>
          !(
            item.origin.code === origin.code &&
            item.destination.code === destination.code &&
            item.startDateTime === startDateTime &&
            (item.endDateTime && endDateTime
              ? item.endDateTime === endDateTime
              : true)
          )
      );

      const newHistoryItem = {
        origin,
        destination,
        startDateTime,
        endDateTime,
        calendarLocale,
        saleType,
        passengerNumber,
        salonType,
        coupeType
      };
      newHistory = [newHistoryItem, ...newHistory].slice(0, 4);

      addToLocalStorage(
        TRAIN_SEARCH_HISTORY_LOCAL_STORAGE_KEY,
        JSON.stringify(newHistory)
      );
    },
    [addToLocalStorage, getFromLocalStorage]
  );

  const addToHistory = useCallback(
    (
      origin: any,
      destination: any,
      startDateTime: any,
      endDateTime: any,
      calendarLocale: string,
      saleType: any,
      passengerNumber: any,
      salonType: any,
      coupeType: any
    ) => {
      addToRouteHistory(origin, destination);
      addToSearchHistory(
        origin,
        destination,
        startDateTime,
        endDateTime,
        calendarLocale,
        saleType,
        passengerNumber,
        salonType,
        coupeType
      );
    },
    [addToRouteHistory, addToSearchHistory]
  );

  const onSelectRouteHistory = useCallback(
    (id: string) => {
      if (history) {
        let historyItem = null;
        for (const element of history) {
          const checkKey = `${element.origin.code}-${element.destination.code}`;
          if (`${id}` === checkKey) {
            historyItem = element;
            break;
          }
        }

        if (historyItem) {
          onChangeOrigin(historyItem.origin);
          onChangeDestination(historyItem.destination);
        }
      }
    },
    [history, onChangeDestination, onChangeOrigin]
  );

  const routeHistoryList = useMemo(
    () =>
      history.map((item) => ({
        id: `${item?.origin?.code}-${item?.destination?.code}`,
        title: `${item?.origin?.nameFa} - ${item?.destination?.nameFa}`,
        StartIcon: <History />
      })),
    [history]
  );

  return {
    routeHistoryList,
    onSelectRouteHistory,
    addToHistory
  };
};
