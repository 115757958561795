import { useTranslation } from "@website/locale";
import { FC } from "react";
import SearchNoResult from "../../public/images/search-no-result.png";
import styles from "./no-result.module.scss";
import { NoResultPropertiesType } from "./no-result.types";

export const NoResult: FC<NoResultPropertiesType> = (properties) => {
  const { query, count } = properties;
  const { t } = useTranslation();

  return (
    <div className={styles["container"]}>
      <img alt="no result" src={SearchNoResult.src} />
      <div className={styles["title"]}>{t("homepage.no_result.title")}</div>
      <div className={styles["description"]}>
        {count && t("homepage.no_result.search_count", { count })}
        {count && t("homepage.no_result.but")}
        {t("homepage.no_result.description_first_part")}
        <span className={styles["search_word"]}>{`"${query}"`}</span>

        {t("homepage.no_result.description_second_part")}
      </div>
      <div className={styles["description"]}>
        {t("homepage.no_result.search_another_word")}
      </div>
    </div>
  );
};
