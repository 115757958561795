import { memo, SVGProps } from "react";

const SvgInfo = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    height="1em"
    viewBox="0 0 32 32"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <path d="M16 2.667c7.352 0 13.333 5.981 13.333 13.333S23.352 29.333 16 29.333 2.667 23.352 2.667 16 8.648 2.667 16 2.667zm0 2.666C10.119 5.333 5.333 10.125 5.333 16S10.118 26.667 16 26.667 26.667 21.875 26.667 16 21.882 5.333 16 5.333zm-1.328 10.656c0-.731.592-1.323 1.323-1.323h.023c.731 0 1.323.592 1.323 1.323v4.791l-.001.552a1.34 1.34 0 0 1-1.339 1.335 1.329 1.329 0 0 1-1.328-1.328V15.989zm-.339-4.96c0-.92.74-1.667 1.667-1.667.92 0 1.667.74 1.667 1.667 0 .92-.74 1.667-1.667 1.667-.92 0-1.667-.74-1.667-1.667z" />
  </svg>
);

const Memo = memo(SvgInfo);
export default Memo;
