import Link from "next/link";
import { FC, memo } from "react";
import { useRouteButton } from "./route-button.hook";
import styles from "./route-button.module.scss";
import { RouteButtonPropertiesType } from "./route-button.types";

const RouteButton: FC<RouteButtonPropertiesType> = (properties) => {
  const { Icon, title, selected, size = "large" } = properties;

  const { rootProperties } = useRouteButton(properties);
  const channel = process.env.NEXT_PUBLIC_CHANNEL_TYPE;

  if (channel === "jek") {
    return (
      <Link
        {...rootProperties}
        className={`${styles["container"]} ${
          selected ? styles["selected"] : ""
        } ${styles[size]}`}
        replace
      >
        {Icon ? <div className={styles["icon"]}>{Icon}</div> : null}
        <div className={styles["title"]}>{title}</div>
      </Link>
    );
  }
  return (
    <Link
      {...rootProperties}
      className={`${styles["container"]} ${
        selected ? styles["selected"] : ""
      } ${styles[size]}`}
    >
      {Icon ? <div className={styles["icon"]}>{Icon}</div> : null}
      <div className={styles["title"]}>{title}</div>
    </Link>
  );
};

const RouteButtonMemoized = memo(RouteButton);
export { RouteButtonMemoized as RouteButton };
