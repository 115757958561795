import { toString, trimStart } from "lodash";
import { numberConvert } from "../number-convert";
import { NumberSeparatorType } from "./number-separator.types";

export const numberSeparator: NumberSeparatorType = (text, options) => {
  const value = trimStart(toString(text), "0 ") || "0";
  return numberConvert(
    value.slice(0, value.includes(".") ? value.indexOf(".") : value.length),
    {
      numberOnly: true,
      locale: "en"
    }
  ).replaceAll(/\B(?=(\d{3})+(?!\d))/g, options?.separator || ",");
};
