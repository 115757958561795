export type EnvironmentChannelType =
  | "ap"
  | "jek"
  | "web"
  | "web-ir"
  | "business"
  | "ads"
  | "telesale"
  | "snapp-ir"
  | "android"
  | "ios"
  | "pwa"
  | "website"
  | "b2b";

export enum Nationality {
  Iranian = "iranian",
  Foreign = "foreign"
}
