import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { PasswordFieldPropertiesType } from "./password-field.types";
import { checkPasswordConditionErrors } from "./password-field.utils";

export const usePasswordField = (properties: PasswordFieldPropertiesType) => {
  const { initialValue = "", onChange } = properties;
  const [conditionErrors, setConditionErrors] = useState<
    Record<string, boolean>
  >({
    useEnglishCharacter: true,
    useNumberCharacter: true,
    useSpecialCharacter: true,
    reachMinimumCharactersLength: true
  });
  const [password, setPassword] = useState<string>(initialValue);
  const [visibility, setVisibility] = useState<boolean>(false);

  useEffect(() => {
    const errors = checkPasswordConditionErrors(initialValue);
    setConditionErrors(errors);
  }, [initialValue]);

  const onChangePassword = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      const errors = checkPasswordConditionErrors(value);
      setConditionErrors(errors);
      setPassword(value);
      onChange?.(value);
      if (Object.values(errors).every((value) => value === false)) {
        onChange?.(value);
      }
    },
    [onChange]
  );

  const toggleVisibility = useCallback(() => {
    setVisibility((value) => !value);
  }, []);

  return {
    password,
    onChangePassword,
    conditionErrors,
    toggleVisibility,
    visibility
  };
};
