import { FC, memo } from "react";
import { useLoading } from "./loading.hook";
import styles from "./loading.module.scss";
import { LoadingPropertiesType } from "./loading.types";

const Loading: FC<LoadingPropertiesType> = (properties) => {
  const { rootProps } = useLoading();
  const { color = "primary", size = "lg" } = properties;

  return (
    <div
      {...rootProps}
      className={`${styles["container"]} ${styles[color]} ${styles[size]}`}
    >
      <div className={`${styles["dot"]} ${styles["odd"]}`} />
      <div className={`${styles["dot"]} ${styles["even"]}`} />
      <div className={`${styles["dot"]} ${styles["odd"]}`} />
    </div>
  );
};

const LoadingMemoized = memo(Loading);
export { LoadingMemoized as Loading };
