import { AxiosResponse } from "axios";
import { Axios } from "../../utils";
import { BASE_URL_PROFILE } from "../urls";
import {
  AuthByPasswordBodyType,
  AuthByPasswordResponseType
} from "./auth-by-password.types";

export const AuthByPasswordUrl = `${BASE_URL_PROFILE}/auth/password`;

export const AuthByPasswordService = (
  body: AuthByPasswordBodyType
): Promise<AuthByPasswordResponseType> =>
  Axios.post(AuthByPasswordUrl, body).then(
    ({ data }: AxiosResponse<AuthByPasswordResponseType>) => data
  );
