import { MouseEvent, useCallback, useEffect, useRef, useState } from "react";
import { SheetPropertiesType } from "./sheet.types";

export const useSheet = (properties: SheetPropertiesType) => {
  const rootReference = useRef<HTMLDivElement>(null);
  const {
    "aria-describedby": ariaDescribedby,
    "aria-labelledby": ariaLabelledby,
    id,
    animate,
    open,
    onClose
  } = properties;
  const [animateState, setAnimateState] = useState<string>("exited");

  useEffect(() => {
    const checkIfClickedOutside: EventListenerOrEventListenerObject = ({
      target
    }) => {
      if (
        open &&
        rootReference.current &&
        !rootReference.current.contains(target as Node)
      ) {
        onClose();
      }
    };

    if (document) {
      const duration = animate === "normal" ? 0 : 150;
      if (open) {
        document.addEventListener("click", checkIfClickedOutside);
        setAnimateState("initial");
        setTimeout(() => {
          setAnimateState("entering");
        }, 0);
        setTimeout(() => {
          setAnimateState("entered");
        }, duration);
      } else {
        document.removeEventListener("click", checkIfClickedOutside);
        setAnimateState("exiting");
        setTimeout(() => {
          setAnimateState("exited");
        }, duration);
      }
    }

    return () => {
      if (document) {
        document.removeEventListener("click", checkIfClickedOutside);
      }
    };
  }, [animate, onClose, open]);

  const stopPropagationOnModal = useCallback((event: MouseEvent) => {
    event.stopPropagation();
  }, []);

  return {
    rootProperties: {
      "aria-labelledby": ariaLabelledby,
      "aria-describedby": ariaDescribedby,
      "aria-modal": true,
      onClick: stopPropagationOnModal,
      ref: rootReference,
      role: "dialog",
      tabIndex: 0,
      id
    },
    animateState
  };
};
