import { compare } from "fast-json-patch";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { PartialUpdateProfileService } from "../../services/partial-update-profile";
import { AccountInfoCardPropertiesType } from "./account-info-card.types";

export const useAccountInfoCard = (
  properties: AccountInfoCardPropertiesType
) => {
  const { onEndAuth, userData, setStep } = properties;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fullName, setFullName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [fullNameError, setFullNameError] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");
  const [submitError, setSubmitError] = useState<string>("");

  const passwordConditionErrors = useMemo(() => {
    const errors = {
      useEnglishCharacter: true,
      useNumberCharacter: true,
      useSpecialCharacter: true,
      reachMinimumCharactersLength: true
    };

    const regexpEnglish = /[A-Za-z]+/g;
    if (regexpEnglish.test(password)) {
      errors.useEnglishCharacter = false;
    }

    const regexpNumber = /\d+/g;
    if (regexpNumber.test(password)) {
      errors.useNumberCharacter = false;
    }

    const regexpSpecial = /[!"#$%&'()*+,./:;<=>?@[\\\]^_`{|}~-]/g;
    if (regexpSpecial.test(password)) {
      errors.useSpecialCharacter = false;
    }

    if (password.length >= 8) {
      errors.reachMinimumCharactersLength = false;
    }

    return errors;
  }, [password]);

  const toggleShowPassword = useCallback(() => {
    setShowPassword((value) => !value);
  }, []);

  const onChangeFullName = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setFullName(value);
    },
    []
  );

  const onChangePassword = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setPassword(value);
    },
    []
  );

  const onSubmitAccountInfo = useCallback(async () => {
    try {
      let hasError = false;
      if (fullName && fullName.length < 3) {
        setFullNameError("نام خود را وارد کنید.");
        hasError = true;
      } else {
        setFullNameError("");
      }

      if (
        passwordConditionErrors.reachMinimumCharactersLength ||
        passwordConditionErrors.useEnglishCharacter ||
        passwordConditionErrors.useNumberCharacter ||
        passwordConditionErrors.useSpecialCharacter
      ) {
        setPasswordError("رمز عبور پیچیده تری انتخاب کنید.");
        hasError = true;
      } else {
        setPasswordError("");
      }

      if (!hasError) {
        setIsLoading(true);
        setSubmitError("");
        const { accessToken = "" } = userData;

        const baseData = { name: "", password: "" };
        const updateData = { name: fullName, password };
        const patchData = compare(baseData, updateData);

        await PartialUpdateProfileService(patchData, accessToken);

        onEndAuth?.({ accessToken });
        setIsLoading(false);
      }
    } catch {
      setIsLoading(false);
      setSubmitError("مشکلی پیش آمده است.");
    }
  }, [
    fullName,
    passwordConditionErrors.reachMinimumCharactersLength,
    passwordConditionErrors.useEnglishCharacter,
    passwordConditionErrors.useNumberCharacter,
    passwordConditionErrors.useSpecialCharacter,
    userData,
    password,
    onEndAuth
  ]);

  const onLaterClick = useCallback(() => {
    const { accessToken } = userData;
    onEndAuth?.({ accessToken });
  }, [onEndAuth, userData]);

  const onBackClick = useCallback(() => {
    setStep("otp");
  }, [setStep]);

  const onCloseClick = useCallback(() => {
    const { accessToken } = userData;
    onEndAuth?.({ accessToken });
  }, [onEndAuth, userData]);

  return {
    passwordConditionErrors,
    isLoading,
    fullName,
    password,
    onBackClick,
    onCloseClick,
    onChangeFullName,
    onChangePassword,
    onSubmitAccountInfo,
    onLaterClick,
    showPassword,
    toggleShowPassword,
    fullNameError,
    passwordError,
    submitError
  };
};
