import { flight_international } from "./flight_international/en";
import { flight_international_visa } from "./flight_international_visa/en";
import { home_ui } from "./home_ui/en";
import { homepage } from "./homepage/en";
import { hotel_international } from "./hotel_international/en";
import { profile } from "./profile/en";
import { ticket_ui } from "./ticket_ui/en";

export const en = {
  homepage,
  hotel_international,
  flight_international_visa,
  flight_international,
  profile,
  ticket_ui,
  services: {
    domestic_hotels: "Dom. Hotels",
    domestic_flights: "Dom. Flights",
    inter_flights: "Int. Flights",
    bus: "Bus",
    stays: "Stays"
  },
  header: {
    logo: "Snapp Trip",
    account: "Account",
    download_app: "Download App",
    track_order: "Track Order",
    login: "Login",
    sign_up: "Sign Up",
    agencies: "Agencies and Companies",
    travel_services: "Travel Services",
    show_support_links: "Show Support Links",
    faq: "FAQ",
    call_support: "Call Support",
    and: "and",
    track_modal: {
      description: "To track your purchase, please enter the following.",
      select_service: "Select service",
      tracking_code: "Tracking code",
      phone_number: "Phone number",
      track_order: "Track order",
      no_response_was_found: "No reservation was found with this specification."
    }
  },
  header_jek: {
    purchase_modal: {
      title: "Track order",
      tracking_code: "Tracking code",
      phone_number: "Phone number",
      submit: "Track order"
    }
  },
  footer: {
    title: "24/7 Customer support",
    services: {
      title: "Services",
      hotel_domestic: "Iran Hotels",
      hotel_international: "International Hotels",
      flight_domestic: "Iran Flights",
      flight_international: "International Flights",
      bus: "Bus Ticket",
      train: "Train Ticket"
    },
    categories: {
      title: "Categories",
      hotel: "Hotels",
      tour: "Tours",
      flight: "Flights",
      train: "Trains",
      bus: "Bus",
      travelogue: "Journal"
    },
    more_info: {
      title: "More Info",
      contact_us: "Contact Us",
      terms: "Terms & Conditions",
      club: "Customers Club",
      faq: "FAQ",
      careers: "Careers",
      cooperation: "Partnership"
    },
    features: {
      title: "Features",
      panel: "Organization Panel",
      agency: "Agencies",
      blog: "SnappTrip Blog",
      app: "Application",
      discounts: "Discounts",
      cancellation: "Cancellation",
      info: "Information"
    },
    contact_us: {
      title: "Contact Us",
      address: "Address",
      address_value: "Nelson Mandela Blv. Iraj Alley, No. 0, Tehran",
      zip_code: "Postal Code",
      phone_number: "Phone"
    },
    social_media: {
      title: "Socials",
      description: "Keep contact with us"
    },
    download_app: {
      title: "Get the App"
    },
    about: {
      title: "About Snapp!Trip",
      description:
        "SnappTrip! is a platform to buy tickets and Reserve best qualitiy stays and hotels, with smallest prices, only with a few clicks and have a enjoyable trip."
    },
    copyright: {
      title:
        "All rights are reserved for Ara Jahan Novin Gostar Atlas. Share only with mention the Credit."
    }
  },
  calendar: {
    today: "Today",
    startDate: "Start Date",
    endDate: "End Date",
    selectDate: "Select Date",
    gregorian: {
      title: "Gregorian",
      weekNames: {
        one: "Monday",
        two: "Tuesday",
        three: "Wednesday",
        four: "Thursday",
        five: "Friday ",
        six: "Saturday",
        seven: "Sunday"
      },
      weekNamesAbbr: {
        one: "M",
        two: "T",
        three: "W",
        four: "T",
        five: "F",
        six: "S",
        seven: "S"
      },
      monthNames: {
        one: "January",
        two: "February",
        three: "March",
        four: "April",
        five: "May",
        six: "June",
        seven: "July",
        eight: "August",
        nine: "September",
        ten: "October",
        eleven: "November",
        twelve: "December"
      }
    },
    persian: {
      title: "Persian",
      weekNames: {
        one: "Saturday",
        two: "Sunday",
        three: "Monday",
        four: "Tuesday",
        five: "Wednesday",
        six: "Thursday",
        seven: "Friday"
      },
      weekNamesAbbr: {
        one: "S",
        two: "S",
        three: "M",
        four: "T",
        five: "W",
        six: "T",
        seven: "F"
      },
      monthNames: {
        one: "Farvardin",
        two: "Ordibehesht",
        three: "Khordad",
        four: "Tir",
        five: "Mordad",
        six: "Shahrivar",
        seven: "Mehr",
        eight: "Aban",
        nine: "Azar",
        ten: "Dey",
        eleven: "Bahman",
        twelve: "Esfand"
      }
    }
  },
  action: {
    ok: "OK",
    cancel: "Cancel",
    reset: "Reset",
    search: "Search",
    changeTo: "change to"
  },
  global: {
    increase: "increase",
    decrease: "decrease",
    image_message: "image message"
  },
  validations: {
    mixed: {
      default: "{path} is invalid",
      required: "{path} is a required field",
      defined: "{path} must be defined",
      notNull: "{path} cannot be null",
      oneOf: "{path} must be one of {values} values",
      notOneOf: "{path} must not be one of {values} values",
      notType: "{path} must be a {type} type",
      // custom
      fileType: "{path} format must be {type}",
      fileSize: "{path} size must {size} MB maximum"
    },
    string: {
      length: "{path} must be exactly {length} characters",
      min: "{path} must be at least {min} characters",
      max: "{path} must be at most {max} characters",
      matches: "{path} has an invalid format",
      email: "{path} must be a valid email",
      url: "{path} must be a valid URL",
      uuid: "{path} must be a valid UUID",
      trim: "{path} must be a trimmed string",
      lowercase: "{path} must be a lowercase string",
      uppercase: "{path} must be a upper case string",
      // custom
      persian: "{path} must be persian",
      english: "{path} must be english",
      arabic: "{path} must be arabic",
      alphabet: "{path} should not contain numbers and special characters",
      password: "{path} must contain upper and lower case letters and numbers",
      mobile: "{path} format is invalid"
    },
    number: {
      min: "{path} must be greater than or equal to {min}",
      max: "{path} must be less than or equal to {max}",
      lessThan: "{path} must be less than {less}",
      moreThan: "{path} must be greater than {more}",
      positive: "{path} must be a positive number",
      negative: "{path} must be a negative number",
      integer: "{path} must be an integer"
    },
    date: {
      min: "{path} field must be later than {min}",
      max: "{path} field must be at earlier than {max}"
    },
    boolean: {
      isValue: "{path} field must be {value}"
    },
    object: {
      noUnknown: "{path} field has unspecified keys"
    },
    array: {
      min: "{path} field must have at least {min} items",
      max: "{path} field must have less than or equal to {max} items",
      length: "{path} must have {length} items"
    }
  },
  tele_sale: {
    reserve_with_phone_button: "Telephone reservation"
  },
  home_ui
};
