import { useQuery } from "react-query";
import {
  ActiveRoutesQueryService,
  ActiveRoutesQueryUrl
} from "../../../../../services/snapp-tour/city/active-routes/active-routes-query";

export const useCitiesService = (query: string) => {
  const {
    isLoading,
    data
    // error
  } = useQuery(
    [ActiveRoutesQueryUrl, query],
    () => ActiveRoutesQueryService(query),
    {
      enabled: query.length > 1
    }
  );

  return { isLoading, data };
};
