import { useLocalStorage } from "@website/hooks";
import { History } from "@website/icons";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  VILLA_DESTINATION_HISTORY_LOCAL_STORAGE_KEY,
  VILLA_SEARCH_HISTORY_LOCAL_STORAGE_KEY
} from "../search-villa.constant";

export const useHistory = (onChangeDestination: (item: any) => void) => {
  const [history, setHistory] = useState<Array<any>>([]);
  const { addToLocalStorage, getFromLocalStorage } = useLocalStorage();

  useEffect(() => {
    const historyString = getFromLocalStorage(
      VILLA_DESTINATION_HISTORY_LOCAL_STORAGE_KEY
    );
    const history = historyString ? JSON.parse(historyString) : [];
    setHistory(history);
  }, [getFromLocalStorage]);

  const addToDestinationHistory = useCallback(
    (destination) => {
      const historyString = getFromLocalStorage(
        VILLA_DESTINATION_HISTORY_LOCAL_STORAGE_KEY
      );
      const history = historyString ? JSON.parse(historyString) : [];

      let newHistory = history.filter((item) => item.id !== destination.id);

      newHistory = [destination, ...newHistory].slice(0, 2);

      addToLocalStorage(
        VILLA_DESTINATION_HISTORY_LOCAL_STORAGE_KEY,
        JSON.stringify(newHistory)
      );
      setHistory(newHistory);
    },
    [addToLocalStorage, getFromLocalStorage]
  );

  const addToSearchHistory = useCallback(
    (
      destination: any,
      startDateTime: any,
      endDateTime: any,
      calendarLocale: string,
      passengerNumber: any
    ) => {
      const historyString = getFromLocalStorage(
        VILLA_SEARCH_HISTORY_LOCAL_STORAGE_KEY
      );
      const history = historyString ? JSON.parse(historyString) : [];

      let newHistory = history.filter(
        (item) =>
          !(
            item.destination.id === destination.id &&
            item.startDateTime === startDateTime &&
            item.endDateTime === endDateTime
          )
      );
      const newHistoryItem = {
        destination,
        startDateTime,
        endDateTime,
        calendarLocale,
        passengerNumber
      };
      newHistory = [newHistoryItem, ...newHistory].slice(0, 4);

      addToLocalStorage(
        VILLA_SEARCH_HISTORY_LOCAL_STORAGE_KEY,
        JSON.stringify(newHistory)
      );
    },
    [addToLocalStorage, getFromLocalStorage]
  );

  const addToHistory = useCallback(
    (
      destination: any,
      startDate: any,
      endDate: any,
      calendarLocale: string,
      passengerNumber: any
    ) => {
      addToDestinationHistory(destination);
      addToSearchHistory(
        destination,
        startDate,
        endDate,
        calendarLocale,
        passengerNumber
      );
    },
    [addToDestinationHistory, addToSearchHistory]
  );

  const onSelectDestinationHistory = useCallback(
    (id: string) => {
      if (history) {
        let historyItem = null;
        for (const element of history) {
          const checkKey = `${element.id}`;
          if (`${id}` === checkKey) {
            historyItem = element;
            break;
          }
        }

        if (historyItem) {
          onChangeDestination(historyItem);
        }
      }
    },
    [history, onChangeDestination]
  );

  const destinationHistoryList = useMemo(
    () =>
      history.map((destination) => ({
        id: `${destination.id}`,
        title: destination.title,
        StartIcon: <History />
      })),
    [history]
  );

  return {
    destinationHistoryList,
    onSelectDestinationHistory,
    addToHistory
  };
};
