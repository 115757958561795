import { omit } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { TabsPropertiesType } from "./tabs.types";

export const useTab = (properties: TabsPropertiesType) => {
  const {
    defaultTab,
    onChange,
    tabs,
    color,
    iconPosition,
    variant,
    size,
    behavior,
    orientation
  } = properties;
  const [selectedTab, setSelectedTab] = useState<number>(0);

  useEffect(() => {
    const selected =
      tabs[Number(defaultTab)] === undefined ||
      tabs[Number(defaultTab)]?.disabled === true
        ? tabs.findIndex(({ disabled }) => !disabled)
        : defaultTab;

    setSelectedTab(selected || 0);
  }, [tabs, defaultTab]);

  const tabBarTabs = useMemo(
    () =>
      tabs.map((item, index) => ({
        ...omit(item, "panel"),
        onClick: () => {
          setSelectedTab(index);
          onChange?.(index);
        }
      })),
    [onChange, tabs]
  );

  return {
    selectedTab,
    tabBarProperties: {
      tabs: tabBarTabs,
      value: selectedTab,
      color,
      iconPosition,
      variant,
      size,
      behavior,
      orientation
    }
  };
};
