import { AxiosResponse } from "axios";
import { AxiosNoAuth } from "../../../utils";
import { CountryResponseType } from "./types";

export const CountryUrl = `${process.env.NEXT_PUBLIC_BASE_URL_IFT}/api/visa/v1/country`;

export const CountryService = (): Promise<CountryResponseType> =>
  AxiosNoAuth.get(CountryUrl).then(
    ({ data }: AxiosResponse<CountryResponseType>) => data
  );
