import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { useTooltip } from "./tooltip.hook";
import styles from "./tooltip.module.scss";
import { TooltipPropertiesType } from "./tooltip.types";

const Tooltip: FC<TooltipPropertiesType> = (properties) => {
  const { title, children, placement, color, variant, className } = properties;
  const { titleProperties, tooltipProperties } = useTooltip(properties);

  return (
    <div className={className}>
      <div className={styles["tooltip"]} {...tooltipProperties}>
        {children}
        <span
          className={`${styles["title"]} ${styles[placement || "bottom"]} ${
            styles[color || "neutral"]
          } ${styles[variant || "wrap"]}`}
          {...titleProperties}
        >
          {title}
        </span>
      </div>
    </div>
  );
};

const TooltipMemoized = memo(Tooltip, deepCompare);
export { TooltipMemoized as Tooltip };
