import { memo, SVGProps } from "react";

const SvgVentureTrain = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <path
      clipRule="evenodd"
      d="M12 2c-4.21 0-8.42.526-8.42 4.21v10a3.688 3.688 0 0 0 3.683 3.685L5.87 21.288a.635.635 0 0 0-.186.449c0 .145.118.263.264.263h1.648c.279 0 .547-.11.744-.308l1.797-1.797h3.968l1.797 1.797c.198.197.466.308.745.308h1.406a.263.263 0 0 0 .263-.263.635.635 0 0 0-.186-.45l-1.393-1.392a3.688 3.688 0 0 0 3.684-3.684v-10c0-3.685-3.768-4.211-8.42-4.211ZM7.263 17.79a1.577 1.577 0 0 1-1.579-1.58c0-.873.706-1.578 1.58-1.578.873 0 1.578.705 1.578 1.579 0 .873-.705 1.578-1.579 1.578ZM5.684 9.367V7.263c0-.581.472-1.053 1.053-1.053h3.158c.581 0 1.053.472 1.053 1.053v2.105c0 .582-.472 1.053-1.053 1.053H6.737a1.053 1.053 0 0 1-1.053-1.053Zm11.053 8.421a1.577 1.577 0 0 1-1.579-1.579c0-.873.705-1.578 1.579-1.578s1.579.705 1.579 1.579c0 .873-.705 1.578-1.579 1.578Zm-3.684-8.42V7.262c0-.581.471-1.053 1.052-1.053h3.158c.582 0 1.053.472 1.053 1.053v2.105c0 .582-.471 1.053-1.053 1.053h-3.158a1.053 1.053 0 0 1-1.052-1.053Z"
      fillRule="evenodd"
    />
  </svg>
);

const Memo = memo(SvgVentureTrain);
export default Memo;
