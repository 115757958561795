import { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { UseViewPortType, ViewPortType } from "./viewport.types";

let defaultViewport: ViewPortType = "xs";

const map: Record<ViewPortType, number> = {
  xs: 0,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 1536
};
const getViewport = () =>
  (Object.entries(map)
    .reverse()
    .find((view) => window.innerWidth >= view[1])?.[0] || "xs") as ViewPortType;

export const useViewPort: UseViewPortType = () => {
  const [viewPort, setViewPort] = useState<ViewPortType>(defaultViewport);
  const eventCallback = useMemo(
    () =>
      debounce(() => {
        defaultViewport = getViewport();
        setViewPort(defaultViewport);
      }, 500),
    []
  );
  useEffect(() => {
    defaultViewport = getViewport();
    setViewPort(defaultViewport);
    window.addEventListener("resize", eventCallback);
    return () => window.removeEventListener("resize", eventCallback);
  }, [eventCallback]);
  return {
    viewPort,
    isMobile: ["xs", "sm"].includes(viewPort),
    isTablet: ["md"].includes(viewPort),
    isDesktop: ["lg", "xl", "2xl"].includes(viewPort)
  };
};
