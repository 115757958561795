import { range } from "lodash";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { ReserveDetailContainerPropertiesTypes } from "../reserve-gallery.types";

export const useReserveGalleryDetailContainer = ({
  galleryLength,
  templateNumber
}: ReserveDetailContainerPropertiesTypes) => {
  const router = useRouter();

  useEffect(() => {
    document
      .querySelector(`#gallery_image_${router.query.item}`)
      ?.scrollIntoView();
  }, [router.query.item]);
  const containerProperties =
    galleryLength > templateNumber
      ? range(0, galleryLength, templateNumber).map((index) => ({
          index: index,
          rowCount:
            index + templateNumber < galleryLength
              ? templateNumber
              : galleryLength % templateNumber
        }))
      : [{ index: 0, rowCount: galleryLength }];

  return { containerProps: containerProperties };
};
