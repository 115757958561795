import { useDebounce } from "@website/hooks";
import { BusRounded, Origin } from "@website/icons";
import { useTranslation } from "@website/locale";
import { useCallback, useMemo, useState } from "react";
import { NoResult } from "../../../..";
import {
  useListingService,
  useSearchOriginSuggestionsService
} from "../services";
import { makeShowText } from "../utils";

export const useOrigin = () => {
  const { t } = useTranslation();
  const [originQuery, setOriginQuery] = useState<string>("");
  const [selectedOriginItem, setSelectedOriginItem] = useState(null);
  const query = useDebounce(originQuery, 250);

  const { suggestions } = useSearchOriginSuggestionsService();
  const { isLoading, data } = useListingService(query);

  const onChangeOriginQuery = useCallback((value: string) => {
    setOriginQuery(value);
  }, []);

  const originList = useMemo(
    () => (originQuery.length > 2 ? data || [] : suggestions || []),
    [data, suggestions, originQuery]
  );

  const originListNormalized = useMemo(() => {
    const list = [];
    for (const item of originList) {
      list.push({
        id: `${item.code}`,
        title: `${item.name}, ${item.isAbroad ? "کشور" : "استان"} ${
          item.province
        }`,
        subtitle: `تمام پایانه ها`,
        StartIcon: <Origin />
      });

      if (item.childs) {
        for (const child of item.childs) {
          list.push({
            id: `${child.code}`,
            title: `پایانه ${child.name}`,
            subtitle: child.name,
            StartIcon: <BusRounded />,
            indent: 1
          });
        }
      }
    }
    return list;
  }, [originList]);

  const onSelectOrigin = useCallback(
    (id: string) => {
      let originItem = null;
      for (const item of originList) {
        if (`${item.code}` === `${id}`) {
          originItem = {
            ...item,
            level: 1
          };
          break;
        }

        if (item.childs) {
          for (const child of item.childs) {
            if (`${child.code}` === `${id}`) {
              originItem = {
                ...child,
                level: 2
              };
              break;
            }
          }

          if (originItem) {
            break;
          }
        }
      }
      setOriginQuery("");
      setSelectedOriginItem(originItem);
    },
    [originList]
  );

  const onChangeOrigin = useCallback((item: any) => {
    setOriginQuery("");
    setSelectedOriginItem(item);
  }, []);

  return {
    selectedOriginItem,
    selectedOriginText: makeShowText(selectedOriginItem),
    originQuery,
    onChangeOrigin,
    onSelectOrigin,
    onChangeOriginQuery,
    originList,
    originLoading: isLoading,
    originListNormalized,
    OriginNoResult:
      originQuery?.length > 2 && !isLoading && data && data.length === 0 ? (
        <NoResult query={originQuery} />
      ) : undefined,
    originLabel: t("homepage.bus.main_search.origin")
  };
};
