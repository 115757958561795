import { MaterialIcon } from "@website/icons";
import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { Button } from "../button";
import { Skeleton } from "../skeleton";
import { useCarousel } from "./carousel.hook";
import styles from "./carousel.module.scss";
import { CarouselPropertiesType } from "./carousel.types";

const Carousel: FC<CarouselPropertiesType> = (properties) => {
  const {
    carouselList,
    containerProperties,
    nextButtonProperties,
    previousButtonProperties
  } = useCarousel(properties);

  const { list, itemsToShow, noGap, className } = properties;

  return (
    <div className={styles["container"]}>
      <div
        {...containerProperties}
        className={`${className} ${styles["scrollable-wrapper"]}`}
      >
        <div
          className={`${styles["list"]} ${noGap ? "" : styles["gap"]}`}
          style={{
            minWidth: `calc(100% / ${itemsToShow} * ${list.length} + (${
              list.length / itemsToShow - 1
            }rem))`
          }}
        >
          {carouselList?.length
            ? carouselList.map(({ component, ref, itemIndex }) => (
                <div key={itemIndex} ref={ref}>
                  {component}
                </div>
              ))
            : Array.from({ length: itemsToShow }, (_, index) => (
                <div className={styles["carousel-item-skeleton"]} key={index}>
                  <Skeleton height={112} />
                </div>
              ))}
        </div>
      </div>
      {list.length > 3 ? (
        <div className={styles["buttons-wrapper"]}>
          <Button
            StartIcon={
              <MaterialIcon name="arrow_forward_ios" variant="outlined" />
            }
            className={styles["previous-button"]}
            size="small"
            {...previousButtonProperties}
          />
          <Button
            StartIcon={
              <MaterialIcon name="arrow_back_ios" variant="outlined" />
            }
            className={styles["next-button"]}
            size="small"
            {...nextButtonProperties}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

const CarouselMemoized = memo(Carousel, deepCompare);
export { CarouselMemoized as Carousel };
