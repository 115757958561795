import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { FALLBACK_IMAGE_MD, LazyImage, Modal, Slider } from "@website/ui";
import { deepCompare, getImage, numberConvert } from "@website/utils";
import { toNumber, toString } from "lodash";
import { useRouter } from "next/router";
import { Fragment, memo, useCallback, useMemo } from "react";
import { ReserveGallerySinglePropertiesTypes } from "./reserve-gallery-single.types";

const Component = (properties: ReserveGallerySinglePropertiesTypes) => {
  const { t } = useTranslation();
  const { gallery, baseUrl } = properties;
  const router = useRouter();

  const onClose = useCallback(() => router.back(), [router]);

  const startId = toNumber(router.query?.item) ?? 0;
  const isModalOpen = router.query?.modal === "gallery-details";

  const startIndex = useMemo(
    () => gallery.findIndex(({ id }) => toString(id) === toString(startId)),
    [gallery, startId]
  );

  const filteredGallery = useMemo(
    () => [...gallery.slice(startIndex), ...gallery.slice(0, startIndex)],
    [gallery, startIndex]
  );

  return (
    <Modal
      className="flex !h-full !w-full justify-center !rounded-none !bg-[#121212] p-0 md:box-content"
      onClose={onClose}
      open={isModalOpen}
    >
      <div className="hover:bg-on-surface-high-emphasis flex h-[64px] items-center justify-end gap-2 px-6 py-4 md:h-[68px]">
        <div className="flex cursor-pointer flex-row gap-1" onClick={onClose}>
          <MaterialIcon
            className="md:text-background text-xl text-gray-400"
            name="close"
          />
          <span className="text-background hidden md:block">
            {t("global.close")}
          </span>
        </div>
      </div>
      <div className="size-full px-4 py-6">
        <Slider
          className="h-full max-w-full"
          list={filteredGallery.map((img, index) => (
            <Fragment key={`${img?.media}_${img?.title}`}>
              <div className="text-body-1 bg-on-surface-disable relative top-1 z-[1] mx-auto w-fit rounded-lg p-1 text-center text-white">{`${numberConvert(
                index + 1,
                {
                  locale: router.locale
                }
              )}/${numberConvert(filteredGallery.length, {
                locale: router.locale
              })}`}</div>
              <div className="flex-col items-center justify-end">
                <LazyImage
                  alt={img?.title}
                  className="bg-outline-thin absolute left-1/2 top-1/2 flex size-auto max-h-full max-w-full -translate-x-1/2 -translate-y-1/2 align-middle"
                  fallback={FALLBACK_IMAGE_MD.src}
                  loading="lazy"
                  src={getImage({
                    src: img?.media,
                    baseUrl: baseUrl
                  })}
                  title={img?.title}
                />
                <div className="text-caption bg-on-surface-disable relative top-2 z-[1] mx-auto size-fit rounded-lg p-2 text-center text-white">
                  {numberConvert(img?.title, { locale: router.locale })}
                </div>
              </div>
            </Fragment>
          ))}
          navigateUsingArrows
        />
      </div>
    </Modal>
  );
};
export const ReserveGallerySingle = memo(Component, deepCompare);
