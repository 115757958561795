import { useCallback, useState } from "react";
import { LoginStepType, LoginUserDataType } from "./login.types";

export const useB2BLogin = () => {
  const [currentStep, setCurrentStep] = useState<string>("phone-number");

  const setStep = useCallback((step: LoginStepType) => {
    setCurrentStep(step);
  }, []);

  const [userData, setUserData] = useState<LoginUserDataType>({
    phoneNumber: "",
    userExists: false,
    hasPassword: false,
    accessToken: "",
    refreshToken: "",
    forgotPassword: false,
    message: "",
    otpLength: 0,
    otpValidUntil: 0,
    otpId: "",
    otp: "",
    contractId: ""
  });

  return {
    step: currentStep,
    setStep,
    userData,
    setUserData
  };
};
