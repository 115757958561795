import { MaterialIcon } from "@website/icons";
import { SearchField } from "@website/ui";
import { debounce } from "lodash";
import { useSearchParams } from "next/navigation";
import { Fragment, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { InputTypes } from "../filter.types";
import styles from "../index.module.scss";

interface InputFilterProperties extends InputTypes {}
export const Input = (properties: InputFilterProperties) => {
  const { title, search_key, type } = properties;
  const [searchValue, setSearchValue] = useState("");
  const { setValue } = useFormContext();
  const searchParameters = useSearchParams();
  const hotelName = searchParameters.get(search_key);

  const setFilter = debounce((text) => {
    setValue(search_key, {
      type,
      withDebounce: true,
      value: text
    });
  }, 600);

  const setQuery = (event_: string) => {
    setSearchValue(event_);
    event_.length === 0 &&
      setValue(search_key, {
        type,
        withDebounce: true,
        value: undefined
      });
    event_.length > 3 && setFilter(event_);
  };

  useEffect(() => {
    if (!hotelName) setSearchValue("");
  }, [hotelName]);

  return (
    <Fragment>
      <span className={styles["filter-header"]}>{title}</span>
      <SearchField
        EndIcon={<MaterialIcon className="text-2xl" name="search" />}
        disabled={false}
        fullWidth
        onChange={(event) => setQuery(event.target.value)}
        onEndIconClick={() => setFilter(searchValue)}
        onKeyDown={(event: any) => {
          if (event.key === "Enter") {
            event.preventDefault();
            setFilter(searchValue);
          }
        }}
        placeholder="نام هتل"
        size="dense"
        value={searchValue}
      />
    </Fragment>
  );
};
