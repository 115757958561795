import { memo, SVGProps } from "react";

const SvgPlus = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    height="1em"
    viewBox="0 0 32 32"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <path d="M17.333 14.667h8a1.333 1.333 0 0 1 0 2.666h-8v8a1.333 1.333 0 0 1-2.666 0v-8h-8a1.333 1.333 0 0 1 0-2.666h8v-8a1.333 1.333 0 0 1 2.666 0v8z" />
  </svg>
);

const Memo = memo(SvgPlus);
export default Memo;
