import { isSSR } from "@website/utils";
import { FC, createContext, useContext, useMemo } from "react";
import {
  LocalStorageContextType,
  LocalStorageProviderPropertiesType
} from "./local-storage.types";

export const LocalStorageContext = createContext<LocalStorageContextType>({
  addToLocalStorage: (key: string, value: string) => null,
  removeFromLocalStorage: (key: string) => null,
  getFromLocalStorage: (key: string) => ""
});

export const LocalStorageProvider: FC<LocalStorageProviderPropertiesType> = ({
  children,
  version,
  prefix
}) => {
  const value = useMemo(
    () => ({
      addToLocalStorage: (key: string, value: string) => {
        if (isSSR()) return;
        localStorage.setItem(`${prefix}-${version}-${key}`, value);
      },
      removeFromLocalStorage: (key: string) => {
        if (isSSR()) return;
        localStorage.removeItem(`${prefix}-${version}-${key}`);
      },
      getFromLocalStorage: (key: string) => {
        if (isSSR()) return null;
        return localStorage.getItem(`${prefix}-${version}-${key}`);
      }
    }),
    [version, prefix]
  );

  return (
    <LocalStorageContext.Provider value={value}>
      {children}
    </LocalStorageContext.Provider>
  );
};

export const useLocalStorage = () => {
  const context = useContext(LocalStorageContext);
  if (context === undefined) {
    throw new Error(
      "useLocalStorage must be used within a LocalStorageProvider"
    );
  }
  return context;
};
