import { useViewPort } from "@website/hooks";
import { throttle } from "lodash";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useState } from "react";
import { HeaderWrapperPropertiesType } from "./header-wrapper.types";

export const useHeaderWrapper = (properties: HeaderWrapperPropertiesType) => {
  const { showAuth, snappTripToken } = properties;
  const [scrolled, setScrolled] = useState<boolean>(false);
  const { isMobile } = useViewPort();
  const { pathname, replace } = useRouter();
  const search = useMemo(
    () =>
      typeof window === "object" &&
      new URLSearchParams(window?.location?.search),
    []
  );
  const ispwa = search && search.get("ispwa");

  useEffect(() => {
    const onScroll = () => {
      setScrolled(window.scrollY > 0);
    };
    const onScrollThrottle = throttle(onScroll, 100);

    if (typeof window !== "undefined" && isMobile) {
      window.addEventListener("scroll", onScrollThrottle);
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("scroll", onScrollThrottle);
      }
    };
  }, [isMobile]);

  const onBackButtonClick = useCallback(() => {
    const page_source = search.get("page_source");
    if (
      (pathname === "/tour" || pathname === "/villa" || pathname === "/") &&
      (!page_source || page_source !== "from_snapp_launcher")
    ) {
      replace(`/launcher${search ? `?${search}` : ""}`);
      return;
    }
    if (ispwa) {
      window.close();
      return;
    }
    window.location.assign("snpjekhomepage://");
  }, [ispwa, pathname, replace, search]);

  return {
    scrolled,
    headerProperties: {
      showAuth,
      minimalView: false,
      showBackButton: false
    },
    headerJekProperties: {
      onBackButtonClick,
      snappTripToken,
      showPurchaseMenu: true
    }
  };
};
