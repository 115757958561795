import { MaterialIcon } from "@website/icons";
import { TextField } from "@website/ui-old";
import { FC, memo } from "react";
import { usePasswordField } from "./password-field.hook";
import styles from "./password-field.module.scss";
import { PasswordFieldPropertiesType } from "./password-field.types";

const PasswordField: FC<PasswordFieldPropertiesType> = (properties) => {
  const { error, helperText, placeholder, hideConditions } = properties;
  const {
    password,
    onChangePassword,
    conditionErrors,
    toggleVisibility,
    visibility
  } = usePasswordField(properties);

  return (
    <div className={styles["container"]}>
      <TextField
        EndIcon={<MaterialIcon name="remove_red_eye" />}
        StartIcon={<MaterialIcon name="lock" variant="outlined" />}
        error={error}
        fullWidth
        helperText={helperText}
        onChange={onChangePassword}
        onEndIconClick={toggleVisibility}
        placeholder={placeholder}
        type={visibility ? "text" : "password"}
        value={password}
      />
      {hideConditions ? null : (
        <div className={styles["conditions"]}>
          <div
            className={`${styles["condition-item"]} ${
              conditionErrors.useEnglishCharacter ? "" : styles["checked"]
            }`}
          >
            {conditionErrors.useEnglishCharacter ? (
              <MaterialIcon name="remove" />
            ) : (
              <MaterialIcon name="check_circle" />
            )}
            استفاده از حروف انگلیسی
          </div>
          <div
            className={`${styles["condition-item"]} ${
              conditionErrors.useNumberCharacter ? "" : styles["checked"]
            }`}
          >
            {conditionErrors.useNumberCharacter ? (
              <MaterialIcon name="remove" />
            ) : (
              <MaterialIcon name="check_circle" />
            )}
            استفاده از عدد
          </div>
          <div
            className={`${styles["condition-item"]} ${
              conditionErrors.useSpecialCharacter ? "" : styles["checked"]
            }`}
          >
            {conditionErrors.useSpecialCharacter ? (
              <MaterialIcon name="remove" />
            ) : (
              <MaterialIcon name="check_circle" />
            )}
            استفاده از کاراکتر های خاص (@#$&*! و ...)
          </div>
          <div
            className={`${styles["condition-item"]} ${
              conditionErrors.reachMinimumCharactersLength
                ? ""
                : styles["checked"]
            }`}
          >
            {conditionErrors.reachMinimumCharactersLength ? (
              <MaterialIcon name="remove" />
            ) : (
              <MaterialIcon name="check_circle" />
            )}
            حداقل ۸ کاراکتر
          </div>
        </div>
      )}
    </div>
  );
};

const PasswordFieldMemoized = memo(PasswordField);
export { PasswordFieldMemoized as PasswordField };
