import { MaterialIcon } from "@website/icons";
import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { Button } from "../button";
import { useSlider } from "./slider.hook";
import styles from "./slider.module.scss";
import { SliderPropertiesType } from "./slider.types";

const Slider: FC<SliderPropertiesType> = (properties) => {
  const { className } = properties;
  const {
    sliderList,
    activeIndex,
    containerProperties,
    nextButtonProperties,
    previousButtonProperties,
    onDotClick
  } = useSlider(properties);

  return (
    <div
      {...containerProperties}
      className={`${className} ${styles["container"]}`}
    >
      {sliderList.map((item, index) => (
        <div
          aria-hidden={activeIndex !== index}
          aria-label={`slide ${index + 1} of ${sliderList.length}`}
          className={`${styles["item-wrapper"]}`}
          key={`Slider-${index++}`}
          ref={item.ref}
          role="group"
        >
          {item.component}
        </div>
      ))}

      {sliderList.length > 1 ? (
        <>
          <div className={styles["buttons-wrapper"]}>
            <Button
              StartIcon={
                <MaterialIcon name="arrow_forward_ios" variant="outlined" />
              }
              className={styles["previous-button"]}
              size="small"
              {...previousButtonProperties}
            />
            <Button
              StartIcon={
                <MaterialIcon name="arrow_back_ios" variant="outlined" />
              }
              className={styles["next-button"]}
              size="small"
              {...nextButtonProperties}
            />
          </div>
          <ul className={styles["dot-list"]}>
            {sliderList.map((_, index) => (
              <li className={styles["dot-wrapper"]} key={`Slider-${index++}`}>
                <button
                  aria-label={`Go to slide ${index + 1}`}
                  className={`${styles["dot"]} ${
                    index === activeIndex ? styles["active"] : ""
                  }`}
                  onClick={onDotClick(index)}
                />
              </li>
            ))}
          </ul>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

const SliderMemoized = memo(Slider, deepCompare);
export { SliderMemoized as Slider };
