import { memo } from "react";

const SvgFlightLandFa = () => (
  <svg
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4702_474925)">
      <path
        d="M14.334 12.6668H1.66732V14.0002H14.334V12.6668ZM3.10732 10.5668C2.57399 10.7068 2.02732 10.3935 1.88065 9.86016C1.74065 9.32683 2.05398 8.78016 2.58732 8.6335L6.12732 7.68683L7.96732 1.6735L9.25398 1.3335V6.8535L12.5673 5.96683L13.1873 4.42016L14.154 4.16016V7.60683L3.10732 10.5668Z"
        fill="#040A1F"
        fillOpacity="0.6"
      />
    </g>
    <defs>
      <clipPath id="clip0_4702_474925">
        <rect fill="white" height="16" width="16" />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(SvgFlightLandFa);
export default Memo;
