import { AxiosResponse } from "axios";
import { AxiosNoAuth } from "../../../../utils";
import { SearchSuggestionsResponseType } from "./search-suggestions.types";

export const SearchSuggestionsUrl = `${process.env.NEXT_PUBLIC_BASE_URL_CDN}/B2C/Uploads/international-flights/home-page/updated-search-suggestions.json`;

export const SearchSuggestionsService =
  (): Promise<SearchSuggestionsResponseType> =>
    AxiosNoAuth.get(SearchSuggestionsUrl).then(
      ({ data }: AxiosResponse<SearchSuggestionsResponseType>) => data
    );
