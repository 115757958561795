/* eslint-disable no-console */
import { decodedToken } from "@website/hooks";
import { Footer } from "@website/widget";
import { useRouter } from "next/router";
import { FC, memo, useCallback, useEffect, useState } from "react";
import { HeaderWrapper, Search } from "..";
import { growthbook } from "../../pages/_app.page";
import { useLayout } from "./layout.hook";
import styles from "./layout.module.scss";
import { LayoutPropertiesType } from "./layout.types";
import { useChangeTokenService } from "./services/use-change-token-service";

const Layout: FC<LayoutPropertiesType> = (properties) => {
  const { children } = properties;
  const { headerProperties, footerProperties } = useLayout();
  const router = useRouter();
  const channel = process.env.NEXT_PUBLIC_CHANNEL_TYPE;
  const snappToken =
    typeof window === "object"
      ? new URLSearchParams(window?.location?.search)?.get("token") || ""
      : "";
  const { isLoading, mutateAsync } = useChangeTokenService({
    token: snappToken,
    tokenType: "SNAPP_TOKEN"
  });
  const [snappTripToken, setSnappTripToken] = useState("");

  const setCookie = useCallback(async () => {
    if (!("cookieStore" in window)) return;

    const value = await (window.cookieStore as any).get("homepage-version");
    if (!value) {
      const daysToLive = 30;
      await (window.cookieStore as any).set({
        name: "homepage-version",
        value: "new",
        expires: Date.now() + daysToLive * 24 * 60 * 60 * 1000
      });
    }
  }, []);
  const addIdInGrowthbook = useCallback((token: string) => {
    try {
      const { customerKey } = decodedToken();

      growthbook.setAttributes({
        id: customerKey
      });
    } catch {
      console.log("The token is not valid");
    }
  }, []);

  useEffect(() => {
    if (channel === "jek") {
      mutateAsync()
        .then((response) => {
          setSnappTripToken(response?.token);
          if (response?.token) {
            addIdInGrowthbook(response?.token);
          }
          localStorage.setItem("snappTripToken", response?.token);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log("snapp trip token error:", error);
        });
    }
    const getSnapptripToken = localStorage.getItem("snappTripToken");
    if (getSnapptripToken) {
      addIdInGrowthbook(getSnapptripToken);
    }

    setCookie();
  }, [addIdInGrowthbook, channel, mutateAsync, setCookie]);

  return (
    <>
      <HeaderWrapper
        {...{
          ...headerProperties,
          snappTripToken,
          snappTripTokenLoading: isLoading
        }}
      />
      <section>
        {router.pathname === "/" ||
        router.pathname === "/international-hotel" ||
        router.pathname === "/flights" ||
        router.pathname ===
          (channel === "jek" ? "/interflights" : "/inter-flights") ||
        (channel === "jek" && router.pathname === "/home-visa") ||
        router.pathname === "/bus" ||
        router.pathname === "/bus-international" ||
        router.pathname === "/villa" ||
        router.pathname === "/tour" ||
        (channel === "jek" && router.pathname === "/train") ? (
          <Search snappTripToken={snappTripToken} />
        ) : null}
        {children}
      </section>
      {channel === "jek" || channel === "ads" ? (
        <div className={styles["bottom-gap-jek"]} />
      ) : (
        <Footer {...footerProperties} />
      )}
    </>
  );
};

const LayoutMemoized = memo(Layout);
export { LayoutMemoized as Layout };
