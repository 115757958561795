import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { Radio } from "../radio";
import { SizeType } from "../types";
import { useRadioGroup } from "./radio-group.hook";
import styles from "./radio-group.module.scss";
import { RadioGroupPropertiesType } from "./radio-group.types";

const RadioGroup: FC<RadioGroupPropertiesType> = (properties) => {
  const { rootProps, radioProps } = useRadioGroup(properties);
  const {
    className,
    itemClassName,
    items,
    size,
    title,
    vertical,
    titleStyle,
    error,
    helperText
  } = properties;

  return (
    <div
      {...rootProps}
      className={`flex items-center ${
        vertical ? "flex-col" : "flex-row gap-5"
      } ${className ?? ""} ${styles["radio-group"]} ${
        error ? styles["error"] : ""
      }`}
      data-error={error}
    >
      {title ? (
        <p
          className={`${styles["title"]} ${titleStyle} ${
            size === SizeType.lg
              ? "text-xl"
              : size === SizeType.sm
                ? "text-base"
                : "text-lg"
          }`}
        >
          {title}
        </p>
      ) : null}
      {items.map((item, index) => (
        <Radio
          key={String(item.label)}
          labelClassName={itemClassName ?? itemClassName}
          {...radioProps[index]}
        />
      ))}
      {helperText ? (
        <div className={styles["helper-text"]}>{helperText}</div>
      ) : null}
    </div>
  );
};

const RadioGroupMemoized = memo(RadioGroup, deepCompare);
export { RadioGroupMemoized as RadioGroup };
