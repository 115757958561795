import { pick } from "lodash";
import { ListItemPropertiesType } from "./list-item.types";

export const useListItem = (properties: ListItemPropertiesType) => {
  const { href, target, onClick, role } = properties;

  return {
    rootProps: {
      default: {
        ...pick(properties, "aria-label", "data-testid")
      },
      button: {
        ...pick(properties, "aria-label", "id", "data-testid"),
        role,
        onClick,
        tabIndex: 0
      },
      nextLink: {
        ...pick(properties, "aria-label", "data-testid"),
        href: href || "",
        target,
        tabIndex: 0
      },
      link: {
        ...pick(properties, "aria-label", "data-testid"),
        role,
        href,
        target,
        tabIndex: 0
      },
      label: {
        ...pick(properties, "aria-label", "data-testid")
      }
    }
  };
};
