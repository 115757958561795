import { useTranslation } from "@website/locale";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { PassengerRoomCountItemsType } from "../../../../passenger-room-count/passenger-room-count.types";

export type PassengersCountType = Array<PassengerCountType>;
export type PassengerCountType = {
  adult: Array<number>;
  child: Array<number | undefined>;
};
export const usePassengerRoomCountTour = () => {
  const { t } = useTranslation();
  const [temporaryPassengerCount, setTemporaryPassengerCount] =
    useState<PassengersCountType>([
      {
        adult: [1, 1],
        child: []
      }
    ]);
  const [passengerCount, setPassengerCount] = useState<PassengersCountType>([
    {
      adult: [1, 1],
      child: []
    }
  ]);

  const [totalPassenger, setTotalPassenger] = useState(2);

  const onChange = useCallback(
    (value: number, listIndex: number, childIndex: number) => {
      if (value > -1) {
        setPassengerCount((previous) => {
          const copyState = _.cloneDeep(previous);
          if (childIndex === 0) {
            copyState[listIndex].adult = Array.from({ length: value }).fill(
              1
            ) as Array<number>;
          } else if (value > copyState[listIndex].child.length) {
            copyState[listIndex].child[value - 1] = undefined;
          } else if (value > -1)
            copyState[listIndex].child = copyState[listIndex].child.splice(
              0,
              value
            );
          return copyState;
        });
      }
    },
    []
  );

  const [updatedList, setUpdatedList] = useState<
    Array<PassengerRoomCountItemsType>
  >([
    {
      id: "1",
      title: t("homepage.inter_hotel.main_search.room"),
      children: [
        {
          increaseDisabled: false,
          decreaseDisabled: false,
          title: t("homepage.inter_hotel.main_search.number_of_adult"),
          subtitle: t(
            "homepage.inter_hotel.main_search.greather_than_n_years",
            {
              age: 12
            }
          ),
          value: [1],
          onChange
        },
        {
          increaseDisabled: false,
          decreaseDisabled: false,
          title: t("homepage.inter_hotel.main_search.number_of_child"),
          subtitle: t("homepage.inter_hotel.main_search.to_n_years", {
            age: 12
          }),
          value: [],
          error: [],
          onChange
        }
      ]
    }
  ]);

  const [, setTemporaryUpdatedList] = useState<
    Array<PassengerRoomCountItemsType>
  >([
    {
      id: "1",
      title: t("homepage.inter_hotel.main_search.room"),
      children: [
        {
          increaseDisabled: false,
          decreaseDisabled: false,
          title: t("homepage.inter_hotel.main_search.number_of_adult"),
          subtitle: t(
            "homepage.inter_hotel.main_search.greather_than_n_years",
            {
              age: 12
            }
          ),
          value: [1],
          onChange
        },
        {
          increaseDisabled: false,
          decreaseDisabled: false,
          title: t("homepage.inter_hotel.main_search.number_of_child"),
          subtitle: t("homepage.inter_hotel.main_search.to_n_years", {
            age: 12
          }),
          value: [],
          error: [],
          onChange
        }
      ]
    }
  ]);

  const validation = useCallback(() => {
    const clear = [];
    setUpdatedList((previous) => {
      let copyPrevious = [...previous];
      copyPrevious = copyPrevious.map((items) => {
        items.children.map((item) => {
          if (item.value) {
            item.value.map((value, index) => {
              if (value === undefined || value === null) {
                clear.push(1);
                item.error[index] = 1;
              }
            });
          }
        });
        return items;
      });
      return copyPrevious;
    });
    return clear.length === 0;
  }, []);

  const onTemporaryListUpdate = useCallback(() => {
    const total = passengerCount?.reduce(
      (accumulator, current) =>
        accumulator + current?.adult?.length + current?.child?.length,
      0
    );
    setUpdatedList((previous) => {
      const copyUpdatedList = _.cloneDeep(previous);
      setTemporaryUpdatedList(
        copyUpdatedList.map((items, index) => {
          const ADULT = passengerCount[index]?.adult;
          const CHILD = passengerCount[index]?.child;
          return {
            ...items,
            children: items?.children?.map((item, index_) => ({
              ...item,
              decreaseDisabled:
                index_ === 0
                  ? ADULT?.length === 1 ||
                    Math.floor(CHILD?.length / ADULT?.length) === 3 ||
                    Math.floor(CHILD?.length / (ADULT?.length - 1)) > 3
                  : CHILD?.length === 0,
              increaseDisabled:
                index_ === 0
                  ? ADULT?.length + CHILD?.length > 5 || total > 8
                  : ADULT?.length + CHILD?.length > 5 ||
                    total > 8 ||
                    Math.floor(CHILD?.length / ADULT?.length) === 3,
              value: index_ === 0 ? ADULT : CHILD,
              ...(index_ === 1
                ? {
                    error:
                      item?.error?.map((value, index) =>
                        index < item?.error?.length - 1 ? value : undefined
                      ) || []
                  }
                : null)
            }))
          };
        })
      );
      return previous;
    });
  }, [passengerCount]);

  const onSubmitPassengerCount = useCallback(
    (onClosePopover: () => void) => {
      if (validation()) {
        setPassengerCount((previous) => {
          setTemporaryPassengerCount((state) =>
            previous.map((item, index) => {
              if (state[index]) {
                state[index]["adult"] = item?.adult;
                state[index]["child"] = item?.child;
              } else {
                state[index] = { adult: [], child: [] };
                state[index]["adult"] = item?.adult;
                state[index]["child"] = item?.child;
              }
              return state[index];
            })
          );
          return previous;
        });
        onTemporaryListUpdate();
        onClosePopover();
      }
    },
    [onTemporaryListUpdate, validation]
  );

  useEffect(() => {
    const total = passengerCount?.reduce(
      (accumulator, current) =>
        accumulator + current?.adult?.length + current?.child?.length,
      0
    );
    setTotalPassenger(total);
    setUpdatedList((previous) =>
      previous.map((items, index) => {
        const ADULT = passengerCount[index]?.adult;
        const CHILD = passengerCount[index]?.child;
        return {
          ...items,
          children: items?.children.map((item, index_) => ({
            ...item,
            decreaseDisabled:
              index_ === 0
                ? ADULT?.length === 1 ||
                  Math.floor(CHILD?.length / ADULT?.length) === 3 ||
                  Math.floor(CHILD?.length / (ADULT?.length - 1)) > 3
                : CHILD?.length === 0,
            increaseDisabled:
              index_ === 0
                ? ADULT?.length + CHILD?.length > 5 || total > 8
                : ADULT?.length + CHILD?.length > 5 ||
                  total > 8 ||
                  Math.floor(CHILD?.length / ADULT?.length) === 3,
            value: index_ === 0 ? ADULT : CHILD,
            ...(index_ === 1
              ? {
                  error:
                    item?.error.map((value, index) =>
                      index < item.error.length - 1 ? value : undefined
                    ) || []
                }
              : null)
          }))
        };
      })
    );
  }, [passengerCount]);

  const onDeletePassengerCountList = useCallback(
    (id: number) => {
      if (updatedList.length > 1) {
        setUpdatedList((previous) =>
          previous
            .filter((item) => Number(item.id) !== Number(id))
            .map((item, index) => {
              item.id = `${index + 1}`;
              return item;
            })
        );
      }
      setPassengerCount((previous) =>
        previous.filter((_, index) => index + 1 !== Number(id))
      );
    },
    [updatedList]
  );

  const onAddPassengerCountList = useCallback(() => {
    setUpdatedList((previous) => {
      const copyUpdatedList = [...previous];
      copyUpdatedList.push({
        id: `${previous.length + 1}`,
        title: t("homepage.inter_hotel.main_search.room"),
        children: [
          {
            increaseDisabled: false,
            decreaseDisabled: false,
            title: t("homepage.inter_hotel.main_search.number_of_adult"),
            subtitle: t(
              "homepage.inter_hotel.main_search.greather_than_n_years",
              {
                age: 12
              }
            ),
            value: totalPassenger < 8 ? [1, 1] : [1],
            onChange
          },
          {
            increaseDisabled: false,
            decreaseDisabled: false,
            title: t("homepage.inter_hotel.main_search.number_of_child"),
            subtitle: t(
              "homepage.inter_hotel.main_search.between_m_and_n_years",
              {
                age_one: 2,
                age_two: 12
              }
            ),
            value: [],
            error: [],
            onChange
          }
        ]
      });
      return copyUpdatedList;
    });
    setPassengerCount((previous) => {
      const copyPassengerCount = [...previous];
      copyPassengerCount.push({
        adult: totalPassenger < 8 ? [1, 1] : [1],
        child: []
      });
      return copyPassengerCount;
    });
  }, [t, totalPassenger, onChange]);

  const onChangeChildAge = useCallback(
    (value: string, listIndex: number, childIndex: number) => {
      setUpdatedList((previous) => {
        const copyPrevious = [...previous];
        copyPrevious[listIndex].children[1].value[childIndex] = Number(value);
        copyPrevious[listIndex].children[1].error[childIndex] = 0;
        return copyPrevious;
      });
      setPassengerCount((previous) => {
        const copyPassengerCount = [...previous];
        copyPassengerCount[listIndex].child[childIndex] = Number(value);
        return copyPassengerCount;
      });
    },
    []
  );

  const onClosePassengerButton = useCallback(() => {
    setTemporaryPassengerCount((state) => {
      setPassengerCount(state);
      return state;
    });

    const total = temporaryPassengerCount?.reduce(
      (accumulator, current) =>
        accumulator + current?.adult?.length + current?.child?.length,
      0
    );
    setTemporaryUpdatedList((previous) => {
      const copyTemporaryUpdatedList = _.cloneDeep(previous);
      setUpdatedList(
        copyTemporaryUpdatedList?.map((items, index) => {
          const ADULT = temporaryPassengerCount[index]?.adult;
          const CHILD = temporaryPassengerCount[index]?.child;
          return {
            ...items,
            children: items?.children.map((item, index_) => ({
              ...item,
              decreaseDisabled:
                index_ === 0
                  ? ADULT?.length === 1 ||
                    Math.floor(CHILD?.length / ADULT?.length) === 3 ||
                    Math.floor(CHILD?.length / (ADULT?.length - 1)) > 3
                  : CHILD?.length === 0,
              increaseDisabled:
                index_ === 0
                  ? ADULT?.length + CHILD?.length > 5 || total > 8
                  : ADULT?.length + CHILD?.length > 5 ||
                    total > 8 ||
                    Math.floor(CHILD?.length / ADULT?.length) === 3,
              value: index_ === 0 ? ADULT : CHILD,
              ...(index_ === 1
                ? {
                    error:
                      item?.error.map((value, index) =>
                        index < item.error.length - 1 ? value : undefined
                      ) || []
                  }
                : null)
            }))
          };
        })
      );
      return previous;
    });
  }, [temporaryPassengerCount]);

  const onClickBackDropHandler = useCallback(() => {
    setPassengerCount((state) => {
      setTemporaryPassengerCount(state);
      return state;
    });
    onTemporaryListUpdate();
  }, [onTemporaryListUpdate]);

  return {
    passengerCountTitle: t(
      "homepage.hotel.main_search.number_of_passenger_and_room"
    ),
    passengerCountResult: t(
      "homepage.hotel.main_search.x_passenger_and_y_room",
      {
        passenger_count: passengerCount?.reduce(
          (accumulator, current) =>
            accumulator + current?.adult?.length + current?.child?.length,
          0
        ),
        room_count: passengerCount?.length
      }
    ),
    passengerCountList: updatedList,
    passengerCount,
    onSubmitPassengerCount,
    onChange,
    onDeletePassengerCountList,
    onAddPassengerCountList,
    onChangeChildAge,
    totalPassenger,
    onClosePassengerButton,
    onClickBackDropHandler
  };
};
