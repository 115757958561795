import { USER_TRACKING_EVENTS, addEventToTracking } from "@website/tracking";

function onceInvoke<T extends (...arguments_: Array<any>) => any>(
  callback: T
): (...arguments_: Parameters<T>) => void {
  let invoked = false;

  return function (...arguments_: Parameters<T>): void {
    if (!invoked) {
      invoked = true;
      callback(...arguments_);
    }
  };
}

export const onceTrackSearchBoxInteracted = onceInvoke(() => {
  addEventToTracking(USER_TRACKING_EVENTS.SEARCH_BOX_INTERACTED, {
    product: "tour"
  });
});

export const onceTrackRoomPassengersInteracted = onceInvoke(() => {
  addEventToTracking(USER_TRACKING_EVENTS.ROOM_PASSENGERS_INTERACTED, {
    product: "tour"
  });
});
