import { useViewPort } from "@website/hooks";
import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { FC, memo } from "react";
import {
  ListItem,
  Loading,
  PopoverFull,
  SearchField,
  SizeType,
  TextField,
  TopAppBar
} from "..";
import styles from "./auto-complete.module.scss";
import { AutoCompletePropertiesType } from "./auto-complete.types";
import { useEnd, useStart, useSwap } from "./hooks";

const AutoComplete: FC<AutoCompletePropertiesType> = (properties) => {
  const { className, parts, error, fullWidth, onSwap } = properties;
  const { isMobile } = useViewPort();
  const {
    startTextFieldProperties,
    startItemsProperties,
    startPopoverProperties,
    startPopoverSearchFieldProperties,
    startHistoryItemsProperties,
    startTopAppBarProperties
  } = useStart(properties);
  const {
    endTextFieldProperties,
    endItemsProperties,
    endPopoverProperties,
    endPopoverSearchFieldProperties,
    endHistoryItemsProperties,
    endTopAppBarProperties
  } = useEnd(properties);
  const { swapButtonProperties } = useSwap(properties);
  const { t } = useTranslation();

  return (
    <div
      className={`${styles["auto-complete"]} ${
        fullWidth ? styles["full-width"] : ""
      } ${className ?? ""}`}
    >
      <div className={styles["box"]}>
        <div className={styles["inputs-container"]}>
          <div className={styles["start"]}>
            <TextField
              {...startTextFieldProperties}
              className={styles["input"]}
              fullWidth
            />
          </div>
          {parts[1] ? (
            <>
              <div className={styles["middle"]}>
                <div className={styles["middle-line"]} />
              </div>
              {onSwap && (
                <button
                  {...swapButtonProperties}
                  aria-label="swap selected items"
                  className={styles["swap-button"]}
                >
                  <MaterialIcon
                    className={styles["swap-button-icon"]}
                    name="swap_horiz"
                    variant="outlined"
                  />
                </button>
              )}
              <div className={styles["end"]}>
                <TextField
                  {...endTextFieldProperties}
                  className={styles["input"]}
                  fullWidth
                />
              </div>
            </>
          ) : null}
        </div>
        {error ? <div className={styles["error"]}>{error}</div> : null}
      </div>
      <PopoverFull {...startPopoverProperties}>
        <div className={styles["popover"]}>
          <TopAppBar {...startTopAppBarProperties} />
          <div className={styles["popover-header"]}>
            <SearchField
              {...startPopoverSearchFieldProperties}
              color={isMobile ? "white" : "transparent"}
            />
          </div>
          <div className={styles["popover-list"]}>
            <div className={styles["popover-list-divider"]} />
            {parts[0]?.loading ? (
              <div className={styles["loading"]}>
                <Loading size={SizeType.lg} />
                <div>{t("search.searching")}</div>
              </div>
            ) : null}
            {parts[0]?.NoResult ?? null}
            {!parts[0]?.query &&
            startHistoryItemsProperties &&
            startHistoryItemsProperties?.length > 0 ? (
              <>
                <div className={styles["popover-history-title"]}>
                  {parts[0]?.historyTitle}
                </div>
                {startHistoryItemsProperties.map((item) => (
                  <ListItem key={item.id} {...item} />
                ))}
                <div className={styles["popover-history-bottom"]} />
              </>
            ) : null}
            {startItemsProperties && startItemsProperties?.length > 0 ? (
              <>
                {parts[0]?.listTitle ? (
                  <div className={styles["popover-list-title"]}>
                    {parts[0]?.listTitle}
                  </div>
                ) : null}

                {startItemsProperties.map((item) => (
                  <ListItem key={item.id} {...item} />
                ))}
              </>
            ) : null}
          </div>
        </div>
      </PopoverFull>
      {parts[1] ? (
        <PopoverFull {...endPopoverProperties}>
          <div className={styles["popover"]}>
            <TopAppBar {...endTopAppBarProperties} />
            <div className={styles["popover-header"]}>
              <SearchField
                {...endPopoverSearchFieldProperties}
                color={isMobile ? "white" : "transparent"}
              />
            </div>
            <div className={styles["popover-list"]}>
              <div className={styles["popover-list-divider"]} />
              {parts[1]?.loading ? (
                <div className={styles["loading"]}>
                  <Loading size={SizeType.lg} />
                  <div>{t("search.searching")}</div>
                </div>
              ) : null}
              {parts[1]?.NoResult ?? null}
              {!parts[1]?.query &&
              endHistoryItemsProperties &&
              endHistoryItemsProperties?.length > 0 ? (
                <>
                  <div className={styles["popover-history-title"]}>
                    {parts[1]?.historyTitle}
                  </div>
                  {endHistoryItemsProperties.map((item) => (
                    <ListItem key={item.id} {...item} />
                  ))}
                  <div className={styles["popover-history-bottom"]} />
                </>
              ) : null}
              {endItemsProperties && endItemsProperties?.length > 0 ? (
                <>
                  {parts[1]?.listTitle ? (
                    <div className={styles["popover-list-title"]}>
                      {parts[1]?.listTitle}
                    </div>
                  ) : null}
                  {endItemsProperties.map((item) => (
                    <ListItem key={item.id} {...item} />
                  ))}
                </>
              ) : null}
            </div>
          </div>
        </PopoverFull>
      ) : null}
    </div>
  );
};

const AutoCompleteMemoized = memo(AutoComplete);
export { AutoCompleteMemoized as AutoComplete };
