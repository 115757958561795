import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { Button } from "../button";
import { useAccordionItem } from "./accordion.hook";
import styles from "./accordion.module.scss";
import { AccordionPropertiesType } from "./accordion.types";

const Accordion: FC<AccordionPropertiesType> = (properties) => {
  const {
    buttonText,
    buttonProperties,
    contentWrapperProperties,
    detailsProperties,
    summeryProperties
  } = useAccordionItem(properties);
  const { content, disabled, title, subtitle, variant, scroll, StartIcon } =
    properties;

  return (
    <details
      itemProp="mainEntity"
      itemScope
      itemType="https://schema.org/Question"
      {...detailsProperties}
      className={`${styles["details"]} ${styles[variant || "outline"]}`}
    >
      <summary
        {...summeryProperties}
        className={`${styles["summery"]} ${disabled ? styles["disabled"] : ""}`}
      >
        <h3 className={styles["title-wrapper"]} itemProp="name">
          {StartIcon ? <div className={styles["icon"]}>{StartIcon}</div> : null}
          {title}
        </h3>
        {subtitle ? <span className={styles["subtitle"]}>{subtitle}</span> : ""}
        <Button className={styles["button"]} {...buttonProperties}>
          {buttonText}
        </Button>
      </summary>
      <div
        itemProp="acceptedAnswer"
        itemScope
        itemType="https://schema.org/Answer"
        {...contentWrapperProperties}
        className={styles["content-wrapper"]}
      >
        <div
          className={`${styles["content"]} ${scroll ? styles[scroll] : ""}`}
          itemProp="text"
        >
          {content}
        </div>
      </div>
    </details>
  );
};

const AccordionMemoized = memo(Accordion, deepCompare);
export { AccordionMemoized as Accordion };
