import { useTranslation } from "@website/locale";
import { FC, memo } from "react";
import { MonthActions } from "..";
import { stringOfNumbers } from "../../datepicker.utils";
import styles from "./flat-header.module.scss";
import { HeaderPropertiesType } from "./flat-header.types";

const FlatHeader: FC<HeaderPropertiesType> = (properties) => {
  const {
    calendarLocale = "gregorian",
    showDates,
    scrolled,
    showPreviousMonth,
    showNextMonth,
    nextMonthDisabled,
    previousMonthDisabled,
    type,
    view
  } = properties;
  const { t, direction } = useTranslation();

  return (
    <div
      className={`${styles["header"]} ${styles[direction || "ltr"]} ${
        scrolled ? styles["scrolled"] : ""
      }`}
    >
      <MonthActions
        calendarLocale={calendarLocale}
        nextMonthDisabled={nextMonthDisabled}
        previousMonthDisabled={previousMonthDisabled}
        showDates={showDates}
        showNextMonth={showNextMonth}
        showPreviousMonth={showPreviousMonth}
        type={type}
        view={view}
      />
      <div
        className={`${styles["week-names-container-mobile"]} ${
          view === "mobile"
            ? ""
            : styles["week-names-container-mobile__default"]
        }`}
      >
        {[1, 2, 3, 4, 5, 6, 7].map((item) => (
          <div className={styles["week-name"]} key={item}>
            <div className={styles["aspect-ratio-content"]}>
              {t<string>(
                `calendar.${calendarLocale}.weekNamesAbbr.${stringOfNumbers[item]}`
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const HeaderMemoized = memo(FlatHeader);
export { HeaderMemoized as FlatHeader };
