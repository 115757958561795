import { MaterialIcon } from "@website/icons";
import { deepCompare } from "@website/utils";
import { FC, Fragment, memo } from "react";
import { Link } from "..";
import { useBreadcrumb } from "./breadcrumb.hook";
import styles from "./breadcrumb.module.scss";
import { BreadcrumbPropertiesType } from "./breadcrumb.types";

const Component: FC<BreadcrumbPropertiesType> = (properties) => {
  const { className, list, variant } = properties;
  const { rootProperties, itemsProperties } = useBreadcrumb(properties);

  return (
    <nav>
      <ol
        {...rootProperties}
        className={`${styles["breadcrumb"]} ${className ?? ""}`}
        itemScope
        itemType="http://schema.org/BreadcrumbList"
      >
        {variant === "collapsed" && list.length > 3 ? (
          <li
            itemProp="itemListElement"
            itemScope
            itemType="http://schema.org/ListItem"
          >
            <Link {...itemsProperties[0]}>{list[0].title}</Link>
            <div className={styles["divider"]}>/</div>
            <MaterialIcon
              className={styles["more-icon"]}
              name="more_horiz"
              variant="outlined"
            />
            <div className={styles["divider"]}>/</div>
            <Link {...itemsProperties[list.length - 2]}>
              {list.at(-2)?.title}
            </Link>
            <div className={styles["divider"]}>/</div>
            <Link {...itemsProperties[list.length - 1]}>
              {list.at(-1)?.title}
            </Link>
          </li>
        ) : (
          list.map((item, index) => (
            <Fragment key={item.title}>
              {index > 0 ? <li className={styles["divider"]}>/</li> : null}
              <li
                itemProp="itemListElement"
                itemScope
                itemType="http://schema.org/ListItem"
              >
                <Link
                  {...itemsProperties[index]}
                  data-testid={
                    itemsProperties[index].disabled
                      ? "breadcrumb_item_active"
                      : "breadcrumb_item"
                  }
                >
                  {item.title}
                </Link>
                <meta content={`${index + 1}`} itemProp="position" />
              </li>
            </Fragment>
          ))
        )}
      </ol>
    </nav>
  );
};

export const Breadcrumb = memo(Component, deepCompare);
