import { useBack } from "@website/hooks";
import { useEffect, useState } from "react";
import { StepperProperties } from "./stepper.types";

export const useStepper = (properties: StepperProperties) => {
  const { steps, activeStep } = properties;
  const { goBack } = useBack();
  const [active, setActive] = useState<number>(0);
  useEffect(() => {
    const currentStep =
      steps[Number(activeStep)] === undefined
        ? activeStep <= 0
          ? 0
          : steps.length
        : activeStep;

    setActive(currentStep || 0);
  }, [steps, activeStep]);

  return {
    onGoBack: goBack,
    stepperProperties: {
      steps,
      active
    }
  };
};
