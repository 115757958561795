import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { useCallback, useMemo, useState } from "react";

export const useService = () => {
  const channel = process.env.NEXT_PUBLIC_CHANNEL_TYPE;
  const [service, setService] = useState<string>("hotel");
  const { t } = useTranslation();

  const onServiceChange = useCallback((value: string) => {
    setService(value);
  }, []);

  const servicesList = useMemo(() => {
    const list = [
      {
        StartIcon: <MaterialIcon name="apartment" variant="outlined" />,
        title: t("services.domestic_hotels"),
        value: "hotel"
      }
    ];

    if (channel !== "snapp-ir") {
      list.push(
        {
          StartIcon: <MaterialIcon name="local_airport" variant="outlined" />,
          title: t("services.domestic_flights"),
          value: "flight-domestic"
        },
        {
          StartIcon: <MaterialIcon name="flight_takeoff" />,
          title: t("services.inter_flights"),
          value: "flight-intl"
        },
        {
          StartIcon: <MaterialIcon name="directions_bus_filled" />,
          title: t("services.bus"),
          value: "bus"
        }
      );
    }

    return list;
  }, [channel, t]);

  return {
    servicesList,
    service,
    onServiceChange
  };
};
