import { useViewPort } from "@website/hooks";
import { MaterialIcon } from "@website/icons";
import { FC, memo } from "react";
import { Transition } from "../transition";
import { usePopover } from "./popover.hook";
import styles from "./popover.module.scss";
import { PopoverPropertiesType } from "./popover.types";

const Popover: FC<PopoverPropertiesType> = (properties) => {
  const {
    animate = "bottom",
    children,
    hideCloseButton,
    title,
    gap,
    horizontalPosition = "start",
    className,
    headerClassName
  } = properties;
  const { isMobile } = useViewPort();
  const {
    rootProps,
    transitionProps,
    closeButtonProps,
    popoverOpen,
    animateOpen
  } = usePopover(properties);

  if (popoverOpen) {
    return (
      <Transition {...transitionProps} in={animateOpen}>
        {(state: string) => (
          <div
            {...rootProps}
            className={`${styles["popover"]} ${styles[animate]} ${styles[state]} ${styles[horizontalPosition]} ${className}`}
            style={{ marginTop: isMobile ? "0px" : gap || "0px" }}
          >
            <div className={`${styles["header"]} ${headerClassName}`}>
              {title ? <div className={styles["title"]}>{title}</div> : null}
              {hideCloseButton ? null : (
                <div className={styles["close-wrapper"]}>
                  <MaterialIcon
                    className={styles["close-button"]}
                    name="close"
                    {...closeButtonProps}
                  />
                </div>
              )}
            </div>
            {children}
          </div>
        )}
      </Transition>
    );
  }
  return null;
};

const PopoverMemoized = memo(Popover);
export { PopoverMemoized as Popover };
