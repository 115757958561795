import { memo, SVGProps } from "react";

const SvgChevronRight = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <g clipPath="url(#chevron_right_svg__a)">
      <path
        d="M10 6 8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6Z"
        fill="#040A1F"
        fillOpacity={0.87}
      />
    </g>
    <defs>
      <clipPath id="chevron_right_svg__a">
        <path d="M0 0h24v24H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(SvgChevronRight);
export default Memo;
