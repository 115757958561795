import { useQuery } from "react-query";
import {
  SearchSuggestionsService,
  SearchSuggestionsUrl
} from "../../../../../services/cdn/hotel/search-suggestions";

export const useSearchSuggestionsService = () => {
  const {
    isLoading,
    data
    // error
  } = useQuery(SearchSuggestionsUrl, SearchSuggestionsService);

  return { suggestions: data, suggestionsLoading: isLoading };
};
