import { Skeleton, Slider } from "@website/ui-old";
import { FC, memo } from "react";
import { BannerSlidePropertiesType } from "./banner-slide.types";
import { BannerSlideItem } from "./components";

const BannerSlide: FC<BannerSlidePropertiesType> = (properties) => {
  const { list, isLoading = false, className, imageStyle } = properties;

  return (
    <article>
      {isLoading ? (
        <Skeleton height={300} />
      ) : list?.length > 0 ? (
        <Slider
          list={list.map((item, index) => (
            <BannerSlideItem
              className={className}
              imageStyle={imageStyle}
              key={item?.imageId}
              {...{ ...item, id: index }}
            />
          ))}
        />
      ) : null}
    </article>
  );
};

const BannerSlideMemoized = memo(BannerSlide);
export { BannerSlideMemoized as BannerSlide };
