import { deepCompare } from "@website/utils";
import Link from "next/link";
import { forwardRef, ForwardRefExoticComponent, memo } from "react";
import { useLink } from "./link.hook";
import styles from "./link.module.scss";
import { LinkPropertiesType } from "./link.types";

const LinkComponent: ForwardRefExoticComponent<any> = forwardRef<
  HTMLAnchorElement,
  LinkPropertiesType
>((properties, reference) => {
  const { children, className, color, disabled, StartIcon } = properties;
  const { rootProperties } = useLink(properties);

  return (
    <Link
      {...rootProperties}
      className={`${styles["link"]} ${styles[color ?? "primary"]} ${
        disabled ? styles["disabled"] : ""
      } ${className ?? ""}`}
      prefetch={false}
      ref={reference}
    >
      {StartIcon ? (
        <div className={styles["start-icon"]}>{StartIcon}</div>
      ) : null}
      <span itemProp="name">{children}</span>
    </Link>
  );
});

const LinkMemoized = memo(LinkComponent, deepCompare);
export { LinkMemoized as Link };
