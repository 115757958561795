import { useIsVisible } from "@website/hooks";
import { omit } from "lodash";
import { Loader } from "next/dist/shared/lib/dynamic";
import dynamic from "next/dynamic";
import { useCallback, useState } from "react";

export const lazy = <T extends object>(loader: Loader<T>) =>
  function (properties: T) {
    const [inView, setInView] = useState(false);

    const onChange = useCallback(
      (inView: boolean) => inView && setInView(true),
      []
    );

    const { reference } = useIsVisible({ onChange });

    if (!inView) return <div ref={reference} />;

    const LazyComponent = dynamic(loader);

    return <LazyComponent {...(omit(properties, "loader") as T)} />;
  };
