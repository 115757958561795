import { deepCompare } from "@website/utils";
import { memo, useEffect, useRef } from "react";
import { ReserveRateProgressBarPropertiesType } from "./reserve-rate-progress-bar.types";

const Component = ({
  properties
}: {
  properties: ReserveRateProgressBarPropertiesType;
}) => {
  const { limit, progress } = properties;
  const reference = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const width = Math.floor(100 / limit) * progress;
    reference?.current?.style?.setProperty("--progressWidth", `${width}%`);
  }, [limit, progress]);

  return (
    <div
      className="bg-on-surface-disable after:bg-on-surface-high-emphasis relative size-[4px] w-full rounded-[2px] after:absolute after:h-full after:w-[var(--progressWidth)] after:transition-colors after:content-['']"
      ref={reference}
      style={{ transform: "translate(0, var(--progressWidth))" }}
    />
  );
};
export const ReserveProgressBar = memo(Component, deepCompare);
