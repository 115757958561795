import { isArray } from "lodash";
import { AddEventToDataLayerType } from "./tracking.types";

export const addEventToDataLayer: AddEventToDataLayerType = (
  event,
  dataLayerData
) => {
  window.dataLayer ||= [];
  const passedData =
    process.env.NEXT_PUBLIC_CHANNEL_TYPE === "b2b" &&
    isArray(dataLayerData?.items)
      ? dataLayerData?.items.map((item) => ({
          ...item,
          ...(item.item_brand && { item_brand: "b2b" })
        }))
      : dataLayerData;

  window.dataLayer.push({ event, ecommerce: passedData });
};

export const addEventToDataLayerGT3: AddEventToDataLayerType = (
  event,
  dataLayerData
) => {
  window.dataLayer ||= [];
  window.dataLayer.push({ event, ...dataLayerData });
};
