import { parseDateTime } from "@internationalized/date";
import { USER_TRACKING_EVENTS, addEventToTracking } from "@website/tracking";
import { DatepickerSelectedDateType } from "@website/ui-old";
import { useRouter } from "next/router";
import { useCallback } from "react";

type TemporaryPassengersCountType = Array<TemporaryPassengerCountType>;
type TemporaryPassengerCountType = {
  adult: Array<number>;
  child: Array<number | undefined>;
};

export const useSubmit = (
  destinationItem: any,
  date: DatepickerSelectedDateType,
  calendarLocale: string,
  passengerNumber: TemporaryPassengersCountType,
  addToHistory: (
    destination: any,
    startDateTime: any,
    endDateTime: any,
    calendarLocale: string,
    occupants: string,
    passengerCount: number
  ) => void,
  submitValidationCheck: () => boolean
) => {
  const router = useRouter();
  const channel = process.env.NEXT_PUBLIC_CHANNEL_TYPE;

  const addDataLayer = useCallback((event: string, parameters: any) => {
    window?.dataLayer?.push({
      event,
      ecommerce: {
        items: [parameters]
      }
    });
  }, []);

  const onSubmitClick = useCallback(() => {
    if (submitValidationCheck()) {
      let startDateTime = "";
      let endDateTime = "";
      let startDateObject = null;
      let endDateObject = null;
      let startDate = null;
      let endDate = null;
      if (date && date[0] && date[1]) {
        startDateTime = date[0].dateTime;
        endDateTime = date[1].dateTime;

        startDateObject = parseDateTime(startDateTime);
        endDateObject = parseDateTime(endDateTime);

        startDate = `${startDateObject.year}-${startDateObject.month
          .toString()
          .padStart(2, "0")}-${startDateObject.day
          .toString()
          .padStart(2, "0")}`;
        endDate = `${endDateObject.year}-${endDateObject.month
          .toString()
          .padStart(2, "0")}-${endDateObject.day.toString().padStart(2, "0")}`;

        if (destinationItem && startDate && endDate) {
          addEventToTracking(USER_TRACKING_EVENTS.GENERAL_SEARCH, {
            product: "int-hotel",
            city: destinationItem.city_name_fa,
            city_id: destinationItem.id,
            date_from: startDate,
            date_to: endDate,
            search_source: "search-box"
          });
          // occupants = adultsCount-childAge,childAge_adultsCount-childAge,
          // childAge;
          let occupants = "";
          passengerNumber?.map((item, index) => {
            occupants += `${item?.adult?.length || 1}${
              item?.child.length > 0 ? "-" : ""
            }${item?.child.join(",")}${
              index < passengerNumber.length - 1 ? "_" : ""
            }`;
          });
          const passengerCount = passengerNumber?.reduce(
            (accumulator, current) =>
              accumulator + current.adult.length + current.child.length,
            0
          );

          addToHistory(
            destinationItem,
            startDateTime,
            endDateTime,
            calendarLocale,
            occupants || "1",
            passengerCount || 1
          );
          const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL_B2C;
          // web
          if (destinationItem?.type === "city") {
            // city
            const parameters = {
              ...router?.query,
              date_from: startDate,
              date_to: endDate,
              occupants,
              source: "searchBox"
            };
            window?.webengage?.track("IH-search-clicked", {
              ...parameters,
              channel,
              source: "searchBox"
            });
            addDataLayer("IH-search", {
              ...parameters,
              city_slug: destinationItem.city_slug
            });
            const urlParameters = new URLSearchParams(parameters);
            window.location.href = `${BASE_URL}/international-hotel/${
              destinationItem.city_slug
            }?${urlParameters.toString()}`;
          } else {
            // hotel
            const parameters = {
              ...router?.query,
              date_from: startDate,
              date_to: endDate,
              occupants,
              source: "searchBox"
            };
            window?.webengage?.track("IH-search-clicked", {
              ...parameters,
              channel,
              source: "searchBox"
            });
            addDataLayer("IH-search", {
              ...parameters,
              city_slug: destinationItem?.city_slug,
              hotel_slug: destinationItem?.hotel_slug
            });
            const urlParameters = new URLSearchParams(parameters);
            window.location.href = `${BASE_URL}/international-hotel/${
              destinationItem.city_slug
            }/${destinationItem.hotel_slug}?${urlParameters.toString()}`;
          }
        }
      }
    }
  }, [
    submitValidationCheck,
    date,
    destinationItem,
    passengerNumber,
    addToHistory,
    calendarLocale,
    channel,
    router?.query,
    addDataLayer
  ]);

  return {
    onSubmitClick
  };
};
