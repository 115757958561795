import { pick } from "lodash";
import { memo } from "react";
import { Header } from "../header";
import { HeaderJek } from "../header-jek";
import { MainHeaderPropertiesType } from "./header-wrapper.types";

const MainHeader = (properties: MainHeaderPropertiesType) => {
  const {
    isJek = ["android", "ios", "jek"].includes(
      process.env.NEXT_PUBLIC_CHANNEL_TYPE
    )
  } = properties;
  if (isJek) {
    return (
      <HeaderJek
        {...pick(properties, [
          "title",
          "snappTripToken",
          "showBackButton",
          "showAuth",
          "onBackButtonClick",
          "onProfileCLick",
          "showShareButton",
          "showPurchaseMenu",
          "showSupportButton",
          "redirectUrl"
        ])}
      />
    );
  }
  return (
    <Header
      {...pick(properties, [
        "showBackButton",
        "showAuth",
        "minimalView",
        "showMenuButton",
        "showDropShadow",
        "showBottomBorder",
        "showTravelServices",
        "showAgencies",
        "showTrackPurchase",
        "showDownloadApp",
        "state"
      ])}
    />
  );
};
const MainHeaderMemoized = memo(MainHeader);
export { MainHeaderMemoized as HeaderWrapper };
