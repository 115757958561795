import { useTranslation } from "@website/locale";
import { deepCompare, numberConvert } from "@website/utils";
import { useRouter } from "next/router";
import { memo } from "react";
import checkCircleOutline from "./assets/check-circle-outline.svg";
import { ReserveRateFooterPropertiesTypes } from "./reserve-rate-footer.types";

const limitOfferCount = 1_000_000;
const Component = ({
  properties
}: {
  properties: ReserveRateFooterPropertiesTypes;
}) => {
  const { offerCount } = properties;
  const router = useRouter();
  const { t } = useTranslation();
  return (
    <div className="bg-transparent-success text-body-2 mt-4 flex min-w-[312px] flex-row items-center justify-start gap-2 rounded-[8px] p-4 md:max-w-[864px] lg:max-w-[1200px]">
      <img alt="checked" className="size-6" src={checkCircleOutline?.src} />
      <span>
        {t("rate.passengers_offered_count", {
          count:
            Number(offerCount) < 1_000_000
              ? numberConvert(offerCount, {
                  locale: router.locale
                })
              : numberConvert(limitOfferCount, { locale: router.locale })
        })}
      </span>
    </div>
  );
};
export const ReserveRateFooter = memo(Component, deepCompare);
