import { useViewPort } from "@website/hooks";
import { LazyImage } from "@website/ui-old";
import { useRouter } from "next/router";
import { FC, memo, useEffect, useState } from "react";
import { BannerMediumItemPropertiesType } from "../../banner-medium.types";
import styles from "./banner-medium-item.module.scss";
import fallback from "./fallback-b3.svg";

// TODO:
// - width and height are temporary (get them from api)

const BannerMediumItem: FC<BannerMediumItemPropertiesType> = (properties) => {
  const { id = 0, alt, desktopSrc, mobileSrc, linkTo, isSameTab } = properties;
  const { isDesktop } = useViewPort();
  const { pathname } = useRouter();
  const channel = process.env.NEXT_PUBLIC_CHANNEL_TYPE;
  const router = useRouter();

  const imageUrl = isDesktop ? desktopSrc : mobileSrc;
  const imageWidth = isDesktop ? 576 : 576;
  const imageHeight = isDesktop ? 288 : 288;
  const [ID, setID] = useState("");

  useEffect(() => {
    try {
      setID(
        `${
          pathname?.length === 1 ? "hotel" : pathname?.slice(1)
        }_${channel}_medium-banner_${id + 1}`
      );
    } catch {
      setID("medium-banner");
    }
  }, [channel, id, pathname]);

  if (linkTo) {
    const pathName = router.pathname;
    const routerQuery = router?.query;
    const snappTripToken = localStorage.getItem("snappTripToken");
    if (pathName === "/flights" && snappTripToken) {
      routerQuery.authToken = snappTripToken;
    }
    const jekLink = `${
      linkTo.includes("?")
        ? `${linkTo}&${new URLSearchParams(routerQuery as any)}`
        : `${linkTo}?${new URLSearchParams(routerQuery as any)}`
    }`;

    return (
      <a
        className={styles["image-wrapper"]}
        href={channel === "jek" ? jekLink : linkTo}
        rel="noreferrer"
        target={isSameTab ? "_self" : "_blank"}
      >
        <LazyImage
          alt={alt}
          className={styles["image"]}
          fallback={fallback.src}
          height={imageHeight}
          id={ID}
          loading="lazy"
          src={imageUrl as string}
          width={imageWidth}
        />
      </a>
    );
  }
  return (
    <div className={styles["image-wrapper"]}>
      <LazyImage
        alt={alt}
        className={styles["image"]}
        fallback={fallback.src}
        height={imageHeight}
        id={ID}
        loading="lazy"
        src={imageUrl as string}
        width={imageWidth}
      />
    </div>
  );
};

const BannerMediumItemMemoized = memo(BannerMediumItem);
export { BannerMediumItemMemoized as BannerMediumItem };
