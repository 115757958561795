import { omit } from "lodash";
import { Url } from "next/dist/shared/lib/router/router";
import { BaseSyntheticEvent, MouseEvent, useCallback } from "react";
import { LinkPropertiesType } from "./link.types";

export const useLink = (properties: LinkPropertiesType) => {
  const { disabled, href, onClick, itemScope } = properties;

  const handleClick = useCallback(
    (event: BaseSyntheticEvent) => {
      if (!disabled)
        return onClick?.(event as MouseEvent<HTMLAnchorElement, MouseEvent>);
      event.preventDefault();
    },
    [disabled, onClick]
  );

  return {
    rootProperties: {
      ...omit(properties, "children", "className", "color", "disabled"),
      onClick: handleClick,
      role: "link",
      href: (href ?? "") as Url,
      ...(disabled ? { "aria-disabled": true } : { tabIndex: 0 }),
      ...(itemScope && { itemScope: true })
    }
  };
};
