import { GrowthBook } from "@growthbook/growthbook-react";
import { userTrackingStore } from "@website/store";
import { toArray } from "lodash";

const growthbook = new GrowthBook({
  apiHost:
    process.env["NEXT_PUBLIC_CHANNEL_TYPE"] === "ads"
      ? "https://gpi.snapptrip.info"
      : "https://gpi.snapptrip.com",
  clientKey: "sdk-Z2itvy5yPKT5xu",
  enableDevMode: true,
  subscribeToChanges: true
  // trackingCallback: (experiment, result) => {
  //   // TODO: Use your real analytics tracking system
  //   // console.log("Viewed Experiment", {
  //   //   experimentId: experiment.key,
  //   //   variationId: result.key
  //   // });
  // }
});

growthbook.init({
  // Optional, enable streaming updates
  streaming: true
});

growthbook.setAttributes({
  id: userTrackingStore.aid.get()
});

export const getAllExperiments = () =>
  toArray(growthbook.getAllResults().values());

userTrackingStore.experiments.set(
  getAllExperiments() as unknown as Array<string>
);
