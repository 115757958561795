import { pick } from "lodash";
import { RadioGroupPropertiesType } from "./radio-group.types";

export const useRadioGroup = (properties: RadioGroupPropertiesType) => ({
  rootProps: {
    role: "radiogroup"
  },
  radioProps: properties.items.map((item) => ({
    ...item,
    ...pick(properties, "color", "name", "onChange", "size"),
    checked: properties.value
      ? properties.value === item.value && !item.disabled
      : undefined
  }))
});
