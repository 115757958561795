import { useCallback, useEffect, useRef, useState } from "react";
import { CollapsibleTextPropertiesType } from "./collapsible-text.types";

export const useCollapsibleTextItem = (
  properties: CollapsibleTextPropertiesType
) => {
  const { title, disabled } = properties;
  const [expanded, setExpanded] = useState<boolean>(false);
  const [collapsible, setCollapsible] = useState<boolean>(false);
  const regionReference = useRef<HTMLDivElement>(null);

  const onButtonClick = useCallback(() => {
    setExpanded((state) => {
      if (state) {
        regionReference.current?.removeAttribute("style");
        return false;
      }

      if (regionReference.current) {
        regionReference.current.style.height = `${regionReference.current?.scrollHeight}px`;
      }
      return true;
    });
  }, []);

  useEffect(() => {
    if (
      !disabled &&
      regionReference.current &&
      regionReference.current.clientHeight > 80
    ) {
      setCollapsible(true);
    } else {
      setCollapsible(false);
    }
  }, [disabled]);

  const buttonId = `button-${title?.replaceAll(" ", "")}`;
  const regionId = `region-${title?.replaceAll(" ", "")}`;

  return {
    regionProperties: {
      ref: regionReference,
      id: regionId,
      role: "region",
      "aria-labelledby": collapsible && buttonId ? buttonId : undefined // Set only if collapsible is true
    },
    buttonProperties: {
      "aria-controls": regionId,
      "aria-expanded": disabled ? true : expanded,
      "aria-disabled": disabled,
      disabled,
      id: buttonId,
      onClick: onButtonClick
    },
    expanded: disabled ? true : expanded,
    collapsible
  };
};
