import {
  Aparat,
  Blog,
  Instagram,
  Linkedin,
  Telegram,
  Twitter,
  Youtube
} from "@website/icons";
import { dateFormat } from "@website/utils";
import cafeBazaar from "../../assets/images/cafe-bazaar.svg";
import pwa from "../../assets/images/pwa.svg";

const www_web = process.env.NEXT_PUBLIC_BASE_URL_B2C;
const web =
  process.env["NEXT_PUBLIC_CHANNEL_TYPE"] === "ads" ? "" : "snapptrip.com";

const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL_B2C;

// export const support = {
//   phoneNumber: {
//     value: "+982175132000",
//     text: "021 75 13 2000"
//   }
// };
export const support = {
  phoneNumber: {
    value: "+982192000123",
    text: "021 92 000 123"
  }
};

export const contactUs = {
  zipCode: "1917754328",
  phoneNumberPrefix: "021",
  phoneNumbers: [
    // {
    //   value: "+982175132000",
    //   text: "75132000"
    // },
    {
      value: "+982196669066",
      text: "96669066"
    },
    {
      value: "+982192000123",
      text: "92000123"
    }
  ]
};

export const socialMediaLinks = [
  {
    icon: <Blog className="fill-on-surface-medium-emphasis" />,
    url: `${www_web}/blog`,
    ariaLabel: `${web}/blog`,
    rel: "noreferrer nofollow noopener"
  },
  {
    icon: <Instagram className="fill-on-surface-medium-emphasis" />,
    url: "https://www.instagram.com/snapptrip",
    ariaLabel: "instagram.com/snapptrip",
    rel: "noreferrer nofollow noopener"
  },
  {
    icon: <Telegram className="fill-on-surface-medium-emphasis" />,
    url: "https://t.me/snapptrip",
    ariaLabel: "t.me/snapptrip",
    rel: "noreferrer nofollow noopener"
  },
  {
    icon: <Aparat className="fill-on-surface-medium-emphasis" />,
    url: "https://www.aparat.com/SnappTrip",
    ariaLabel: "aparat.com/SnappTrip",
    rel: "noreferrer nofollow noopener"
  },
  {
    icon: <Twitter className="fill-on-surface-medium-emphasis" />,
    url: "https://twitter.com/snapptrip",
    ariaLabel: "twitter.com/snapptrip",
    rel: "noreferrer nofollow noopener"
  },
  {
    icon: <Linkedin className="fill-on-surface-medium-emphasis" />,
    url: "https://www.linkedin.com/company/snapptrip-com",
    ariaLabel: "linkedin.com/company/snapptrip-com",
    rel: "noreferrer nofollow noopener"
  }
];

export const snappSocialMediaLinks = [
  {
    icon: <Instagram className="fill-on-surface-medium-emphasis" />,
    url: "https://instagram.com/snapp_team",
    ariaLabel: "instagram.com/snapp_team",
    rel: "noreferrer nofollow noopener"
  },
  {
    icon: <Telegram className="fill-on-surface-medium-emphasis" />,
    url: "tg://resolve?domain=snappofficial",
    ariaLabel: "tg://resolve?domain=snappofficial",
    rel: "noreferrer nofollow noopener"
  },
  {
    icon: <Twitter className="fill-on-surface-medium-emphasis" />,
    url: "https://twitter.com/snapp_team",
    ariaLabel: "twitter.com/snapp_team",
    rel: "noreferrer nofollow noopener"
  },
  {
    icon: <Linkedin className="fill-on-surface-medium-emphasis" />,
    url: "https://www.linkedin.com/company/snapp.ir",
    ariaLabel: "linkedin.com/company/snapp.ir",
    rel: "noreferrer nofollow noopener"
  },
  {
    icon: <Youtube className="fill-on-surface-medium-emphasis" />,
    url: "https://www.youtube.com/c/Snapp_team",
    ariaLabel: "youtube.com/c/Snapp_team",
    rel: "noreferrer nofollow noopener"
  }
];

export const applications = [
  {
    image: pwa.src,
    url: "https://pwa.snapptrip.com/app?utm-source=pwa-jek",
    alt: "pwa version",
    width: pwa.width,
    height: pwa.height
  },
  {
    image: cafeBazaar.src,
    url: "https://redirect.appmetrica.yandex.com/serve/171456018746713582",
    alt: "cafe bazaar",
    width: cafeBazaar.width,
    height: cafeBazaar.height
  }
];

export const copyright = {
  sign: `Copyright ©${dateFormat(new Date(), {
    template: "yyyy",
    locale: "fa"
  })} ${BASE_URL?.replace(/https?:\/\//, "")}`
};
