import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { Button, MiniPopover, TextField } from "..";
import { Roll } from "./components";
import styles from "./datepicker-roll.module.scss";
import { DatepickerRollPropertiesType } from "./datepicker-roll.types";
import { usePopover, useSelectDate } from "./hooks";

const DatepickerRoll: FC<DatepickerRollPropertiesType> = (properties) => {
  const { error, fullWidth, helperText } = properties;

  const {
    selectedDate,
    setSelectedDate,
    rollYearProperties,
    rollMonthProperties,
    rollDayProperties,
    valueText
  } = useSelectDate(properties);

  const { onOpenPopover, popoverProperties, onAccept } = usePopover(
    properties,
    selectedDate,
    setSelectedDate
  );

  return (
    <div className={styles["datepicker-roll"]}>
      <TextField
        error={error}
        fullWidth={fullWidth}
        helperText={helperText}
        inputMode="none"
        onClick={onOpenPopover}
        readOnly
        value={valueText}
      />
      <MiniPopover {...popoverProperties}>
        <div className={styles["mini-popover"]}>
          <div className={styles["main"]}>
            <Roll {...rollYearProperties} />
            <Roll {...rollMonthProperties} />
            <Roll {...rollDayProperties} />
          </div>
          <div className={styles["footer"]}>
            <Button color="secondary" fullWidth onClick={onAccept}>
              تایید
            </Button>
          </div>
        </div>
      </MiniPopover>
    </div>
  );
};

const DatepickerRollMemoized = memo(DatepickerRoll, deepCompare);
export { DatepickerRollMemoized as DatepickerRoll };
