import { get, pick } from "lodash";
import { ButtonPropertiesType } from "./button.types";

export const useButton = (properties: ButtonPropertiesType) => {
  const { disabled, loading, itemProp, role } = properties;

  return {
    buttonProps: {
      ...pick(properties, "aria-label", "onClick", "type", "hidden"),
      disabled: disabled || loading,
      role: "button"
    },
    divButtonProps: {
      ...(pick(properties, "aria-label", "hidden") as {
        "aria-label": string;
        hidden: boolean;
      }),
      disabled: disabled || loading
    },
    linkProps: {
      itemProp,
      ...pick(properties, "aria-label", "rel", "target", "hidden"),
      prefetch: false,
      "aria-disabled": disabled || loading,
      href: get(properties, "href"),
      role
    }
  };
};
