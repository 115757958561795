import { AxiosResponse } from "axios";
import { AxiosNoAuth } from "../../../utils";
import { StationsItemType } from "./stations.types";

export const StationsUrl = `${process.env.NEXT_PUBLIC_BASE_URL_TRAIN}/statics/v1/stations`;

export const StationsService = (): Promise<Array<StationsItemType>> =>
  AxiosNoAuth.get(StationsUrl).then(
    ({ data }: AxiosResponse<Array<StationsItemType>>) => data
  );
