import { throttle } from "lodash";
import { useEffect, useRef, useState } from "react";

const SAFARI_MOBILE_BOTTOM_MENU_HEIGHT = 44;

export const useSheet = (popoverOpen: boolean) => {
  const sheetReference = useRef<HTMLDivElement>(null);
  const [bottom, setBottom] = useState<number>(0);

  useEffect(() => {
    const computeOffsetBottom = () => {
      if (sheetReference.current && typeof window !== "undefined") {
        const { bottom } = sheetReference.current.getBoundingClientRect();
        if (Math.floor(bottom) > window.innerHeight) {
          setBottom(SAFARI_MOBILE_BOTTOM_MENU_HEIGHT);
          // setBottom(Math.floor(bottom) - window.innerHeight);
        } else {
          setBottom(0);
        }
      }
    };

    const computeOffsetBottomThrottled = throttle(computeOffsetBottom, 200);

    if (typeof window !== "undefined") {
      if (popoverOpen) {
        computeOffsetBottom();
        window.addEventListener("scroll", computeOffsetBottomThrottled);
      } else {
        window.removeEventListener("scroll", computeOffsetBottomThrottled);
      }
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("scroll", computeOffsetBottomThrottled);
      }
    };
  }, [popoverOpen]);

  return {
    sheetReference,
    sheetBottom: bottom
  };
};
