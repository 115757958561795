import { FocusEvent, MouseEvent, useCallback, useRef } from "react";
import { SearchFieldPropertiesType } from "./search-field.types";

export const useSearchField = (properties: SearchFieldPropertiesType) => {
  const {
    autoFocus,
    "aria-label": ariaLabel,
    disabled,
    onChange,
    placeholder,
    value,
    onFocus,
    onBlur,
    onSubmit,
    onStartIconClick,
    onEndIconClick
  } = properties;
  const inputReference = useRef<HTMLInputElement>(null);

  const onInputFocus = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      if (onFocus) {
        onFocus(event);
      }
    },
    [onFocus]
  );

  const onInputBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      if (onBlur) {
        onBlur(event);
      }
    },
    [onBlur]
  );

  const onRootClick = useCallback(() => {
    inputReference.current?.focus();
  }, []);

  const onStartIconClickHandler = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      if (onStartIconClick) {
        event.stopPropagation();
        onStartIconClick(event);
      }
    },
    [onStartIconClick]
  );

  const onEndIconClickHandler = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      if (onEndIconClick) {
        event.stopPropagation();
        onEndIconClick(event);
      }
    },
    [onEndIconClick]
  );

  return {
    rootProps: {
      onClick: onRootClick
    },
    inputProps: {
      autoFocus,
      "aria-label": ariaLabel,
      ref: inputReference,
      disabled,
      onChange,
      placeholder,
      value,
      onFocus: onInputFocus,
      onBlur: onInputBlur
    },
    submitButtonProps: {
      tabIndex: -1, // remove tab focus
      onClick: onSubmit,
      disabled
    },
    startIconProperties: {
      onClick: onStartIconClickHandler
    },
    endIconProperties: {
      onClick: onEndIconClickHandler
    }
  };
};
