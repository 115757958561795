import { deepCompare } from "@website/utils";
import Link from "next/link";
import { FC, memo } from "react";
import { Loading } from "../loading";
import { useListItem } from "./list-item.hook";
import styles from "./list-item.module.scss";
import { ListItemPropertiesType } from "./list-item.types";

const ListItem: FC<ListItemPropertiesType> = (properties) => {
  const {
    className,
    EndAction,
    EndIcon,
    image,
    indent = 0,
    Meta,
    overline,
    role,
    size = "medium",
    subtitle,
    StartAction,
    StartIcon,
    title,
    isLoading = false
  } = properties;
  const { rootProps } = useListItem(properties);

  const indents = [];
  for (let index = 0; index < indent; index++) {
    indents.push(<div className={styles["indent"]} />);
  }

  const inside = (
    <>
      {indents}
      {image ? (
        <div className={styles["image"]}>
          <img alt="icon" src={image} />
        </div>
      ) : null}
      {StartAction ? (
        <div className={styles["start-action"]}>{StartAction}</div>
      ) : null}
      {StartIcon ? <div className={styles["icon"]}>{StartIcon}</div> : null}
      <div className={styles["content"]}>
        {overline ? <div className={styles["overline"]}>{overline}</div> : null}
        {title ? <div className={styles["title"]}>{title}</div> : null}
        {subtitle ? <div className={styles["subtitle"]}>{subtitle}</div> : null}
      </div>
      {Meta ? <div className={styles["meta"]}>{Meta}</div> : null}
      {EndIcon ? <div className={styles["icon"]}>{EndIcon}</div> : null}
      {EndAction ? (
        <div className={styles["end-action"]}>{EndAction}</div>
      ) : null}
    </>
  );

  if (role === "next-link") {
    return (
      <Link
        {...rootProps.nextLink}
        className={`${styles["list-item"]} ${styles[size]} ${className ?? ""}`}
      >
        {isLoading ? (
          <div className={styles["loading"]}>
            <Loading color="secondary" size="sm" />
          </div>
        ) : (
          inside
        )}
      </Link>
    );
  }
  if (role === "link") {
    return (
      <a
        {...rootProps.link}
        className={`${styles["list-item"]} ${styles[size]} ${className ?? ""}`}
      >
        {isLoading ? (
          <div className={styles["loading"]}>
            <Loading color="secondary" size="sm" />
          </div>
        ) : (
          inside
        )}
      </a>
    );
  }
  if (role === "button") {
    return (
      <button
        {...rootProps.button}
        className={`${styles["list-item"]} ${styles[size]} ${className ?? ""}`}
      >
        {isLoading ? (
          <div className={styles["loading"]}>
            <Loading color="secondary" size="sm" />
          </div>
        ) : (
          inside
        )}
      </button>
    );
  }
  if (role === "label") {
    return (
      <label
        {...rootProps.label}
        className={`${styles["list-item"]} ${styles[size]} ${className ?? ""}`}
      >
        {isLoading ? (
          <div className={styles["loading"]}>
            <Loading color="secondary" size="sm" />
          </div>
        ) : (
          inside
        )}
      </label>
    );
  }
  return (
    <div
      {...rootProps.default}
      className={`${styles["list-item"]} ${styles[size]} ${className ?? ""}`}
    >
      {isLoading ? (
        <div className={styles["loading"]}>
          <Loading color="secondary" size="sm" />
        </div>
      ) : (
        inside
      )}
    </div>
  );
};

const ListItemMemoized = memo(ListItem, deepCompare);
export { ListItemMemoized as ListItem };
