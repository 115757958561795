import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { deepCompare } from "@website/utils";
import { memo } from "react";
import { ReserveCommentPointsPropertiesType } from "./reserve-points.types";

const Component = ({
  properties
}: {
  properties: ReserveCommentPointsPropertiesType;
}) => {
  const { message, type } = properties;
  const { t } = useTranslation();
  return (
    <div className="text-caption xl:text-body-2 flex flex-col items-start gap-1 xl:flex-row xl:items-center xl:gap-6 xl:pr-4 xl:ltr:pl-4">
      <div className="flex w-[115px] flex-row items-center gap-1 xl:w-[20px] xl:justify-center">
        {type === "strengths" ? (
          <>
            <MaterialIcon
              className="text-ventures-snapp xl:text-headline-6"
              name="thumb_up"
              variant="outlined"
            />
            <span className="text-ventures-snapp xl:text-headline-6 text-overline xl:hidden">
              {t("comments.positive_point")}
            </span>
          </>
        ) : (
          <>
            <MaterialIcon
              className="text-error xl:text-headline-6"
              name="thumb_down"
              variant="outlined"
            />
            <span className="text-error xl:text-headline-6 text-overline xl:hidden">
              {t("comments.negative_point")}
            </span>
          </>
        )}
      </div>
      <div>{message}</div>
    </div>
  );
};

export const ReserveCommentPoints = memo(Component, deepCompare);
