import { useViewPort } from "@website/hooks";
import { BottomSheet, Sheet } from "@website/ui-old";
import { FC, memo } from "react";
import { PurchaseCard } from "./components";
import { PurchaseMenuPropertiesType } from "./purchase-menu.types";

const PurchaseMenu: FC<PurchaseMenuPropertiesType> = (properties) => {
  const { isMobile } = useViewPort();
  const { isOpen, onClose, list } = properties;

  if (isMobile) {
    return (
      <BottomSheet onClose={onClose} open={isOpen}>
        <PurchaseCard list={list} />
      </BottomSheet>
    );
  }

  return (
    <Sheet
      onClose={onClose}
      open={isOpen}
      xGap="16px"
      xPosition="end"
      yPosition="end"
    >
      <PurchaseCard list={list} />
    </Sheet>
  );
};

const PurchaseMenuMemoized = memo(PurchaseMenu);
export { PurchaseMenuMemoized as PurchaseMenu };
