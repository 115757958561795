import { isSSR, memoize, numberConvert, replaceOptions } from "@website/utils";
import { get, toString } from "lodash";
import { FC, createContext, useContext, useMemo } from "react";
import { ar } from "./ar";
import { en } from "./en";
import { fa } from "./fa";
import type {
  TranslationContextType,
  TranslationDirectionType,
  TranslationKey,
  TranslationProviderPropertiesType
} from "./index.types";

export const localesSchema = {
  ar,
  en,
  fa
};

export type Locale = keyof typeof localesSchema;

export const getValidatedLocale = memoize(
  (locale: Locale): Locale =>
    Object.keys(localesSchema).includes(locale) ? locale : "fa"
);

export const getIsRtl = memoize(
  (locale?: string) =>
    locale?.startsWith("fa") || locale?.startsWith("ar") || false
);

export const getDirection = memoize((locale?: string) =>
  getIsRtl(locale) ? "rtl" : "ltr"
);

export const TranslationContext = createContext<TranslationContextType>({
  t: <U,>(key: TranslationKey<U>) => key,
  locale: "fa",
  direction: "rtl" as const
});

export const TranslationProvider: FC<TranslationProviderPropertiesType> = ({
  children,
  locale
}) => {
  if (!isSSR()) {
    document.dir = getDirection(locale);
    document.documentElement.lang = locale || "fa";
  }

  const validatedLocale = getValidatedLocale(locale as Locale);
  const direction = getDirection(validatedLocale);

  const value = useMemo(
    () => ({
      t: <U,>(
        key: TranslationKey<U>,
        options: unknown,
        allowEmpty?: boolean
      ) => {
        const result = numberConvert(
          replaceOptions(
            toString(get(localesSchema[validatedLocale], toString(key))),
            options as never
          ),
          {
            locale: validatedLocale,
            numberOnly: false
          }
        );
        if (
          !result &&
          !allowEmpty &&
          process.env.NEXT_PUBLIC_BUILD_ENV !== "production" &&
          isSSR() &&
          !isSSR()
        ) {
          // eslint-disable-next-line no-alert
          alert(`translation missing for: ${key}`);
        }
        if (process.env.NEXT_PUBLIC_CHANNEL_TYPE === "snapp-ir")
          return result
            .replaceAll(
              new RegExp("اسنپ تریپ|اسنپ‌تریپ|اسنپتریپ", "gi"),
              "اسنپ"
            )
            .replaceAll(new RegExp("snapp trip|snapptrip", "gi"), "Snapp");
        return result;
      },
      locale: validatedLocale,
      direction: direction as TranslationDirectionType
    }),
    [direction, validatedLocale]
  );

  return (
    <TranslationContext.Provider value={value}>
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslation = () => {
  const context = useContext(TranslationContext);
  if (context === undefined) {
    throw new Error("useTranslation must be used within a TranslationProvider");
  }
  return context;
};
