import { useDebounce } from "@website/hooks";
import { BusRounded, Origin } from "@website/icons";
import { useTranslation } from "@website/locale";
import { useCallback, useMemo, useState } from "react";
import { NoResult } from "../../../..";
import { useListingService, useSearchSuggestionsService } from "../services";
import { makeShowText } from "../utils";

export const useDestination = () => {
  const { t } = useTranslation();
  const [destinationQuery, setDestinationQuery] = useState<string>("");
  const [selectedDestinationItem, setSelectedDestinationItem] = useState(null);
  const query = useDebounce(destinationQuery, 250);

  const { suggestions } = useSearchSuggestionsService();
  const { isLoading, data } = useListingService(query);

  const onChangeDestinationQuery = useCallback((value: string) => {
    setDestinationQuery(value);
  }, []);

  const destinationList = useMemo(
    () => (destinationQuery.length > 2 ? data || [] : suggestions || []),
    [data, suggestions, destinationQuery]
  );

  const destinationListNormalized = useMemo(() => {
    const list = [];
    for (const item of destinationList) {
      list.push({
        id: `${item.code}`,
        title: `${item.name}, استان ${item.province}`,
        subtitle: `تمام پایانه ها`,
        StartIcon: <Origin />
      });

      if (item.childs) {
        for (const child of item.childs) {
          list.push({
            id: `${child.code}`,
            title: `پایانه ${child.name}`,
            subtitle: child.name,
            StartIcon: <BusRounded />,
            indent: 1
          });
        }
      }
    }
    return list;
  }, [destinationList]);

  const onSelectDestination = useCallback(
    (id: string) => {
      let destinationItem = null;
      for (const item of destinationList) {
        if (`${item.code}` === `${id}`) {
          destinationItem = {
            ...item,
            level: 1
          };
          break;
        }

        if (item.childs) {
          for (const child of item.childs) {
            if (`${child.code}` === `${id}`) {
              destinationItem = {
                ...child,
                level: 2
              };
              break;
            }
          }

          if (destinationItem) {
            break;
          }
        }
      }
      setDestinationQuery("");
      setSelectedDestinationItem(destinationItem);
    },
    [destinationList]
  );

  const onChangeDestination = useCallback((item: any) => {
    setDestinationQuery("");
    setSelectedDestinationItem(item);
  }, []);

  return {
    selectedDestinationItem,
    selectedDestinationText: makeShowText(selectedDestinationItem),
    destinationQuery,
    onChangeDestination,
    onSelectDestination,
    onChangeDestinationQuery,
    destinationList,
    destinationLoading: isLoading,
    destinationListNormalized,
    DestinationNoResult:
      destinationQuery?.length > 2 &&
      !isLoading &&
      data &&
      data.length === 0 ? (
        <NoResult query={destinationQuery} />
      ) : undefined,
    destinationLabel: t("homepage.bus.main_search.destination")
  };
};
