import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import { LoginStepType, LoginUserDataType } from "./login.types";

export const useLogin = () => {
  const router = useRouter();
  const queryString = "login_step";

  const setStep = useCallback(
    (step: LoginStepType) => {
      router &&
        router?.replace(
          {
            pathname: router?.pathname,
            query: { ...router?.query, [queryString]: step }
          },
          undefined,
          { shallow: true, scroll: false }
        );
    },
    [router]
  );

  const [userData, setUserData] = useState<LoginUserDataType>({
    phoneNumber: "",
    userExists: false,
    hasPassword: false,
    accessToken: "",
    refreshToken: "",
    forgotPassword: false,
    message: "",
    otpLength: 0,
    otpValidUntil: 0,
    otpId: "",
    otp: ""
  });

  useEffect(() => {
    if (!userData?.phoneNumber) setStep("phone-number");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.phoneNumber]);

  return {
    step: (router?.query?.[queryString] as LoginStepType) || "phone-number",
    setStep,
    userData,
    setUserData
  };
};
