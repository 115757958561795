import { MaterialIcon } from "@website/icons";
import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { PositionType } from "../types";
import styles from "./drawer.module.scss";
import { DrawerPropertiesType } from "./drawer.types";

const Drawer: FC<DrawerPropertiesType> = (properties) => {
  const { isOpen, fullWidth, title, children, footer, position, onClose } =
    properties;
  return (
    <>
      <div className={styles["root"]} hidden={!isOpen} onClick={onClose} />
      <div
        className={`${styles["menu"]} ${
          position === PositionType.top
            ? styles["top"]
            : position === PositionType.right
              ? styles["right"]
              : position === PositionType.bottom
                ? styles["bottom"]
                : position === PositionType.left
                  ? styles["left"]
                  : ""
        } ${isOpen ? styles["open"] : styles["close"]} ${
          fullWidth ? styles["full-width"] : ""
        } `}
      >
        <div>
          <div className={styles["header"]}>
            {title ? <div className={styles["title"]}>{title}</div> : null}
            <MaterialIcon
              className={styles["close-button"]}
              name="close"
              onClick={onClose}
            />
          </div>
          <div>{children}</div>
        </div>
        {footer}
      </div>
    </>
  );
};

const DrawerMemoized = memo(Drawer, deepCompare);
export { DrawerMemoized as Drawer };
