import { AxiosResponse } from "axios";
import { AxiosNoAuth } from "../../../../utils";
import {
  ActiveRoutesQueryItemType,
  ActiveRoutesQueryResponseType
} from "./active-routes.types";

export const ActiveRoutesQueryUrl = `${process.env.NEXT_PUBLIC_BASE_URL_TOUR_API}/listing/v2/cities/`;

export const ActiveRoutesQueryService = (
  query: string
): Promise<ActiveRoutesQueryResponseType> =>
  AxiosNoAuth.get(`${ActiveRoutesQueryUrl}${query}`, {}).then(
    ({ data }: AxiosResponse<Array<ActiveRoutesQueryItemType>>) => data
  );
