import { memo, SVGProps } from "react";

const SvgAngleLeftRegular = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    height="1em"
    viewBox="0 0 32 32"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <path d="M20.412 24.584c.782.78.782 2.048 0 2.828a1.998 1.998 0 0 1-2.828 0l-10-9.996a1.998 1.998 0 0 1 0-2.828l10-10a1.998 1.998 0 0 1 2.828 0 1.998 1.998 0 0 1 0 2.828l-8.586 8.586 8.586 8.582z" />
  </svg>
);

const Memo = memo(SvgAngleLeftRegular);
export default Memo;
