import { getDiffInMonth } from "@website/utils";

const enAlphabet = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
const faAlphabet = "ابپتثجچحخدذرزژسشصضطظعغفقکگلمنوهیَُِ‌آاً";
const arAlphabet = `${faAlphabet}ًٌٍَُِّْٰٔءك‌ةۀأإيـئؤ،`;

export const isPersian = (text?: string, ignorePattern = /\s/g): boolean =>
  // eslint-disable-next-line no-misleading-character-class
  new RegExp(`^[${faAlphabet}]+$`).test(
    (text ?? "").replace(ignorePattern, "")
  );

export const isArabic = (text?: string, ignorePattern = /\s/g): boolean =>
  // eslint-disable-next-line no-misleading-character-class
  new RegExp(`^[${arAlphabet}]+$`).test(
    (text ?? "").replace(ignorePattern, "")
  );

export const isEnglish = (text?: string, ignorePattern = /\s/g): boolean =>
  new RegExp(`^[${enAlphabet}]+$`).test(
    (text ?? "").replace(ignorePattern, "")
  );

export const isAlphabet = (text?: string, ignorePattern = /\s/g): boolean =>
  isPersian(text, ignorePattern) ||
  isArabic(text, ignorePattern) ||
  isEnglish(text, ignorePattern);

export const isValidPassword = (text?: string): boolean =>
  new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{8,}$/).test(text ?? "");

export const isValidMobileNumber = (text?: string): boolean =>
  !text || new RegExp(/^09\d{9}$/).test(text ?? "");

export const isValidPassportExpireDate = (text: string, toDate: string) => {
  const diff = getDiffInMonth(new Date(toDate), new Date(text));
  if (diff < 6) {
    return false;
  }
  return true;
};

export const isValidateNationalCode = (code: string) => {
  const L = code.length;
  if (L < 8 || Number.parseInt(code, 10) === 0) return false;
  code = `0000${code}`.slice(L + 4 - 10);
  if (Number.parseInt(code.slice(3, 9), 10) === 0) return false;
  const c = Number.parseInt(code.slice(9, 10), 10);
  let s = 0;
  for (let index = 0; index < 9; index++)
    s += Number.parseInt(code.slice(index, index + 1), 10) * (10 - index);
  s %= 11;
  return (s < 2 && c === s) || (s >= 2 && c === 11 - s);
};

export const isValidatePassportCode = (
  value: string,
  passportCountry?: string
) => {
  if (passportCountry && passportCountry === "IR") {
    return /^[A-Za-z]\d{8}$/.test(value);
  }
  return /^[\dA-Za-z]*$/.test(value);
};
