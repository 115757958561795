import { AxiosResponse } from "axios";
import { Axios } from "../../utils";
import { BASE_URL_PROFILE } from "../urls";
import {
  ResetPasswordRequestOtpBodyType,
  ResetPasswordRequestOtpResponseType
} from "./reset-password-request-otp.types";

export const ResetpasswordRequestOtpUrl = `${BASE_URL_PROFILE}/reset-password/request-otp`;

export const ResetPasswordRequestOtpService = (
  body: ResetPasswordRequestOtpBodyType
): Promise<ResetPasswordRequestOtpResponseType> =>
  Axios.post(ResetpasswordRequestOtpUrl, body).then(
    ({ data }: AxiosResponse<ResetPasswordRequestOtpResponseType>) => data
  );
