import { useIsVisible } from "@website/hooks";
import { deepCompare } from "@website/utils";
import { memo } from "react";
import { FabMenuItemsTypes, FabMenuPropertiesTypes } from "./fab-bar.type";
import { useTopBar } from "./fab-menu.hook";
import styles from "./fab-menu.module.scss";

const Component = (properties: FabMenuPropertiesTypes) => {
  const { items } = properties;
  const { scrolledItem, handleClick } = useTopBar(properties);
  const { reference, inView } = useIsVisible({});

  return (
    <>
      <div className={styles["fake-element"]} ref={reference} />
      <div className={`${styles["layout"]} ${inView ? "hidden" : "block"}`}>
        <div className={styles["container"]}>
          {items?.map((item: FabMenuItemsTypes) => (
            <div
              className={`${styles["container__item"]} ${
                scrolledItem === item?.title
                  ? styles["container__item__clicked"]
                  : styles["container__item__not-clicked"]
              }`}
              key={item.selector}
              onClick={handleClick(item)}
            >
              {item.title}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export const FabMenu = memo(Component, deepCompare);
