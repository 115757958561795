import { FC, memo } from "react";
import { Loading } from "..";
import { useFab } from "./fab.hook";
import styles from "./fab.module.scss";
import { FabPropertiesType } from "./fab.types";

const Fab: FC<FabPropertiesType> = (properties) => {
  const { size, className, color, variant, text, Icon, loading } = properties;
  const { rootProps } = useFab(properties);

  return (
    <button
      {...rootProps}
      className={`${styles["button"]} ${styles[variant || "contained"]} ${
        styles[color || "primary"]
      } ${styles[size || "large"]} ${className ?? ""}`}
    >
      {loading ? (
        <Loading color="white" />
      ) : (
        <>
          <span className={styles["icon"]}>{Icon}</span>
          {size === "extended" && text ? (
            <div className="mx-2">{text}</div>
          ) : (
            ""
          )}
        </>
      )}
    </button>
  );
};

const FabMemoized = memo(Fab);
export { FabMemoized as Fab };
