import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { Button } from "..";
import { useCounterButton } from "./counter-button.hook";
import styles from "./counter-button.module.scss";
import { CounterButtonPropertiesType } from "./counter-button.types";

const CounterButton: FC<CounterButtonPropertiesType> = (properties) => {
  const { className, variant } = properties;
  const {
    value,
    rootProps,
    decreaseButtonProps,
    increaseButtonProps,
    valueProps
  } = useCounterButton(properties);

  return (
    <div
      {...rootProps}
      className={`${styles["counter-button"]} ${
        styles[variant ?? "contained"]
      } ${className ?? ""}`}
    >
      <Button
        {...increaseButtonProps}
        datatestid="increase-button"
        role="button"
        size="medium"
      />
      <div
        {...valueProps}
        className={styles["value"]}
        data-testid="counter-button-value"
      >
        {value}
      </div>
      <Button
        datatestid="decrease-button"
        {...decreaseButtonProps}
        role="button"
        size="medium"
      />
    </div>
  );
};

const CounterButtonMemoized = memo(CounterButton, deepCompare);
export { CounterButtonMemoized as CounterButton };
