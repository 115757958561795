import { useTranslation } from "@website/locale";
import { useCallback, useState } from "react";
import { AuthByPasswordService } from "../../services/auth-by-password";
import { AuthRequestOtpService } from "../../services/auth-request-otp";
import { ResetPasswordRequestOtpService } from "../../services/reset-password-request-otp";
import { isPhoneNumberValid } from "../../utils";
import { PasswordCardPropertiesType } from "./password-card.types";

export const usePasswordCard = (properties: PasswordCardPropertiesType) => {
  const { userData, setStep, setUserData, onEndAuth } = properties;
  const [isLoadingPassword, setIsLoadingPassword] = useState<boolean>(false);
  const [isLoadingOtp, setIsLoadingOtp] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const { t } = useTranslation();

  const onChangePassword = useCallback((value: string) => {
    setPassword(value);
  }, []);

  const onSubmitPassword = useCallback(async () => {
    if (password) {
      setIsLoadingPassword(true);
      setError("");

      AuthByPasswordService({
        phoneNumber: userData?.phoneNumber,
        password
      })
        .then((data) => {
          const { accessToken, refreshToken } = data;
          if (accessToken) {
            setUserData({ ...userData, ...data });
            onEndAuth?.(accessToken, refreshToken);
          }
        })
        .catch((error) => {
          setError(error?.response?.data?.message);
        })
        .finally(() => setIsLoadingPassword(false));
    } else {
      setError("رمز عبور خود را وارد کنید.");
    }
  }, [onEndAuth, password, setUserData, userData]);

  const onOtpClick = useCallback(async () => {
    const { phoneNumber } = userData;
    if (isPhoneNumberValid(phoneNumber)) {
      setIsLoadingOtp(true);
      setError("");
      AuthRequestOtpService({ phoneNumber })
        .then((data) => {
          setStep("otp");
          setUserData({ ...userData, ...data });
        })
        .catch((error) => {
          setError(
            error?.response?.data?.message || t("login.general_error_message")
          );
        })
        .finally(() => setIsLoadingOtp(false));
    }
  }, [setStep, setUserData, t, userData]);

  const onClickForgotPassword = useCallback(async () => {
    const { phoneNumber } = userData;
    if (isPhoneNumberValid(phoneNumber)) {
      setIsLoadingOtp(true);
      setError("");
      ResetPasswordRequestOtpService({
        phoneNumber
      })
        .then((data) => {
          setUserData({ ...userData, ...data, forgotPassword: true });
          setStep("otp");
        })
        .catch((error) => {
          setError(
            error?.response?.data?.message || t("login.general_error_message")
          );
        })
        .finally(() => setIsLoadingOtp(false));
    }
  }, [setStep, setUserData, t, userData]);

  const onBackClick = useCallback(() => {
    setStep("phone-number");
  }, [setStep]);

  return {
    isLoadingPassword,
    isLoadingOtp,
    password,
    onChangePassword,
    onSubmitPassword,
    onOtpClick,
    error,
    onClickForgotPassword,
    onBackClick
  };
};
