import { memo, SVGProps } from "react";

const SvgArrowDropDown = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <g clipPath="url(#arrow-drop-down_svg__a)">
      <path d="m7 10 5 5 5-5H7Z" />
    </g>
    <defs>
      <clipPath id="arrow-drop-down_svg__a">
        <path d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(SvgArrowDropDown);
export default Memo;
