import { MaterialIcon } from "@website/icons";
import { numberConvert, numberSeparator } from "@website/utils";
import { KeenSliderInstance } from "keen-slider";
import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";
import { FC, useCallback, useMemo, useRef } from "react";
import LOGO from "./assets/logo.svg";
import styles from "./banner.module.scss";
import { BannerPropertiesType } from "./banner.types";

export const Banner: FC<BannerPropertiesType> = ({
  link,
  icon,
  title,
  items
}) => {
  const animation = useMemo(
    () => ({
      duration: (items?.length || 1) * 4000
    }),
    [items]
  );
  const animatorReference = useRef(
    null as unknown as KeenSliderInstance<object, object>
  );
  const [sliderReference] = useKeenSlider({
    loop: true,
    rtl: true,
    drag: true,
    mode: "free",
    renderMode: "performance",
    slides: {
      spacing: 1,
      perView: "auto"
    },
    created: (slider) => {
      animatorReference.current = slider;
      slider.moveToIdx(items?.length ?? 1 - 1, true, animation);
    },
    updated: (slider) => {
      slider.moveToIdx(
        slider.track.details?.abs + (items?.length ?? 1 - 1),
        true,
        animation
      );
    },
    animationEnded: (slider) => {
      slider.moveToIdx(
        slider.track.details?.abs + (items?.length ?? 1 - 1),
        true,
        animation
      );
    }
  });
  const onMouseOver = useCallback(
    () => animatorReference.current?.animator.stop(),
    []
  );
  const onMouseOut = useCallback(
    () =>
      animatorReference.current?.moveToIdx(
        animatorReference.current?.track.details?.abs +
          (items?.length ?? 1 - 1),
        true,
        animation
      ),
    [animation, items?.length]
  );
  return (
    <div
      className={styles.wrapper}
      onMouseOut={onMouseOut}
      onMouseOver={onMouseOver}
    >
      {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
      <div className={`keen-slider ${styles.slider}`} ref={sliderReference}>
        {items?.map(({ currency, icon, link, price, title }) => (
          <a
            /* eslint-disable-next-line tailwindcss/no-custom-classname */
            className={`keen-slider__slide ${styles.item}`}
            href={link}
            rel="nofollow noreferrer"
            target="_blank"
          >
            <MaterialIcon className={styles.item_icon} name={icon} />
            <span className={styles.item_title}>{title}</span>
            <span className={styles.item_price}>
              {numberConvert(numberSeparator(price), { locale: "fa" })}{" "}
              {currency}
            </span>
          </a>
        ))}
      </div>
      <a
        className={`group ${styles.logo_wrapper}`}
        href={link}
        rel="nofollow noreferrer"
        target="_blank"
      >
        <MaterialIcon className={styles.page_icon} name={icon} />
        <span className={`group-hover:block ${styles.page_title}`}>
          {title}
        </span>
        <img alt="" className={styles.page_logo} src={LOGO.src} />
      </a>
    </div>
  );
};
