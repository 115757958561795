import { useViewPort } from "@website/hooks";
import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { Button } from "@website/ui-old";
import { FC, memo } from "react";
import { Transition } from "react-transition-group";
import { useBannerPopup } from "./banner-popup.hook";
import styles from "./banner-popup.module.scss";
import { BannerPopupPropertiesType } from "./banner-popup.types";

// TODO:
// - width and height are temporary (get them from api)

const BannerPopup: FC<BannerPopupPropertiesType> = (properties) => {
  const { item } = properties;
  const { visible, onClose } = useBannerPopup();
  const { isDesktop } = useViewPort();
  const { t } = useTranslation();

  const imageUrl = isDesktop ? item.desktopSrc : item.mobileSrc;
  const imageWidth = isDesktop ? 624 : 624;
  const imageHeight = isDesktop ? 388 : 388;

  if (imageUrl) {
    return (
      <Transition in={visible} timeout={500}>
        {(state: string) => (
          <>
            <div
              className={`${styles["container"]} ${
                state === "entered" ? styles[state] : ""
              }`}
            >
              <div className={styles["header"]}>
                <div className={styles["title-wrapper"]}>
                  <div className={styles["icon"]}>
                    <MaterialIcon name="campaign" />
                  </div>
                  <div className={styles["title"]}>
                    {t("homepage.b2.title")}
                  </div>
                </div>
                <Button
                  EndIcon={<MaterialIcon name="close" />}
                  color="neutral"
                  equalDimension
                  onClick={onClose}
                  size="small"
                  variant="text"
                />
              </div>
              <div className={styles["content"]}>
                <img
                  alt={item.alt}
                  className={styles["image"]}
                  height={imageHeight}
                  src={imageUrl}
                  width={imageWidth}
                />
              </div>
              {item?.linkTo ? (
                <div className={styles["footer"]}>
                  <Button
                    color="secondary"
                    onClick={onClose}
                    size="large"
                    variant="text"
                  >
                    {t("homepage.b2.close")}
                  </Button>
                  <Button
                    color="secondary"
                    href={item.linkTo}
                    role="link"
                    size="large"
                    target={item.isSameTab ? "_self" : "_blank"}
                  >
                    {t("homepage.b2.see_more")}
                  </Button>
                </div>
              ) : (
                ""
              )}
            </div>
            <div
              className={`${styles["overlay"]} ${
                state === "entered" ? styles[state] : ""
              }`}
            />
          </>
        )}
      </Transition>
    );
  }
  return null;
};

const BannerPopupMemoized = memo(BannerPopup);
export { BannerPopupMemoized as BannerPopup };
