import { useTranslation } from "@website/locale";
import { MiniChips } from "@website/ui";
import { dateFormat, deepCompare, numberConvert } from "@website/utils";
import { useRouter } from "next/router";
import { memo } from "react";
import { ReserveCommentAvatar } from "../reserve-comment-avavtar";
import { ReserveCommentRating } from "../reserve-comment-rating";
import bookingLogo from "./assets/booking.svg";
import { ReserveCommentHeaderPropertiesType } from "./reserve-comment-header.types";

const Component = ({
  properties
}: {
  properties: ReserveCommentHeaderPropertiesType;
}) => {
  const { userName, registeredDate, roomName, standNights, rate, source } =
    properties;
  const { t } = useTranslation();

  const router = useRouter();
  return (
    <div className="flex flex-row flex-wrap items-center justify-between gap-2 xl:mb-0 xl:grow-0 xl:items-start xl:gap-0">
      <div
        className={
          "block size-[40px] rounded-full border content-[''] xl:hidden"
        }
      >
        <ReserveCommentAvatar
          className="mx-auto ms-[7px] mt-[7px] size-[24px]"
          rate={rate}
        />
      </div>
      <div className="text-caption xl:text-subtitle-2 flex flex-auto flex-col gap-0">
        <div>{userName}</div>
        <div className="text-on-surface-medium-emphasis text-overline mt-1">
          <span>
            {dateFormat(registeredDate, {
              locale: "fa",
              template: "MMMM yyyy"
            })}
          </span>
          <span className="px-1">
            {t("comments.night_count", {
              night: numberConvert(standNights, {
                locale: router.locale
              })
            })}
          </span>
        </div>
      </div>
      <span className="col-span-1 grid-flow-col lg:order-2">
        <ReserveCommentRating rate={rate} />
      </span>
      <div className="mx-0 basis-full md:ml-2 lg:order-1 lg:basis-auto xl:mt-0 lg:ltr:mr-2">
        <MiniChips size="medium" text={roomName} variant="accent" />
      </div>
      {source === "booking.com" && (
        <MiniChips
          StartIcon={<img alt="booking" src={bookingLogo?.src} />}
          size="medium"
          text="booking ثبت شده در"
          variant="accent"
        />
      )}
    </div>
  );
};

export const ReserveCommentHeader = memo(Component, deepCompare);
