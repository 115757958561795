import { DatepickerSelectedDateType } from "@website/ui-old";
import { useCallback, useEffect, useState } from "react";

export const useValidation = ({
  destinationItem,
  date
}: {
  destinationItem: any;
  date: DatepickerSelectedDateType;
}) => {
  const [destinationError, setDestinationError] = useState<string>("");
  const [dateError, setDateError] = useState<string>("");

  useEffect(() => {
    setDestinationError("");
  }, [destinationItem]);

  useEffect(() => {
    setDateError("");
  }, [date]);

  const submitValidationCheck = useCallback(() => {
    let hasError = false;
    if (!destinationItem) {
      setDestinationError("لطفا مقصد خود را انتخاب نمایید");
      hasError = true;
    }

    if (!date || !date[0] || !date[1]) {
      setDateError("لطفا تاریخ ورود و خروج خود را انتخاب نمایید");
      hasError = true;
    }

    if (hasError) {
      return false;
    }
    return true;
  }, [date, destinationItem]);

  return { dateError, destinationError, submitValidationCheck };
};
