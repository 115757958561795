import styles from "./footer-row.module.scss";
import { FooterRowPropertiesType } from "./footer.types";

export const FooterRow: FooterRowPropertiesType = ({
  children,
  background
}) => (
  <div
    className={`${styles["footer-row"]} ${
      background ? styles[background] : ""
    }`}
  >
    <div className={styles["footer-row-container"]}>{children}</div>
  </div>
);
