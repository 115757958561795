import { useBack } from "@website/hooks";
import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { HeaderPropertiesType } from "../header.types";

export const useServices = (properties: HeaderPropertiesType) => {
  const { redirectUrl } = properties;
  const { t } = useTranslation();
  const { goBack } = useBack(redirectUrl);
  const router = useRouter();

  const services = useMemo(
    () => [
      {
        url: `${process.env.NEXT_PUBLIC_BASE_URL_B2C}/`,
        title: t("services.domestic_hotels"),
        icon: <MaterialIcon name="apartment" variant="outlined" />,
        selected: router.pathname === "/"
      },
      {
        url: `${process.env.NEXT_PUBLIC_BASE_URL_B2C}/international-hotel`,
        title: t("services.intel_hotels"),
        icon: <MaterialIcon name="location_city" variant="outlined" />,
        selected: router.pathname === "/international-hotel"
      },
      {
        url: `${process.env.NEXT_PUBLIC_BASE_URL_B2C}/flights`,
        title: t("services.domestic_flights"),
        icon: <MaterialIcon name="local_airport" variant="outlined" />,
        selected: router.pathname === "/flights"
      },
      {
        url: `${process.env.NEXT_PUBLIC_BASE_URL_B2C}/inter-flights`,
        title: t("services.inter_flights"),
        icon: <MaterialIcon name="flight_takeoff" />,
        selected: router.pathname === "/inter-flights"
      },
      {
        url: `${process.env.NEXT_PUBLIC_BASE_URL_B2C}/bus`,
        title: t("services.bus"),
        icon: <MaterialIcon name="directions_bus_filled" />,
        selected: router.pathname === "/bus"
      },
      {
        url: `${process.env.NEXT_PUBLIC_BASE_URL_B2C}/villa`,
        title: t("services.stays"),
        icon: <MaterialIcon name="house" />,
        selected: router.pathname === "/villa"
      },
      {
        url: `${process.env.NEXT_PUBLIC_BASE_URL_B2C}/tour`,
        title: t("services.tours"),
        icon: <MaterialIcon name="tour" />,
        selected: false
      }
      // {
      //   url: `${baseUrl}/train`,
      //   title: t("homepage.services.train"),
      //   icon: <Train />,
      //   selected: router.pathname === "/train"
      // }
    ],
    [router, t]
  );

  return {
    services,
    onGoBack: goBack
  };
};
