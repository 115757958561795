import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { numberConvert } from "@website/utils";
import { useCallback } from "react";
import { CounterButtonPropertiesType } from "./counter-button.types";

export const useCounterButton = (properties: CounterButtonPropertiesType) => {
  const {
    color,
    decreaseDisabled,
    increaseDisabled,
    onChange,
    value,
    variant,
    locale,
    "aria-label": ariaLabel
  } = properties;
  const { t } = useTranslation();

  const onDecreaseButtonClick = useCallback(() => {
    onChange && onChange(value - 1);
  }, [onChange, value]);

  const onIncreaseButtonClick = useCallback(() => {
    onChange && onChange(value + 1);
  }, [onChange, value]);

  return {
    value: numberConvert(value, { locale: locale || "en" }),
    rootProps: {},
    decreaseButtonProps: {
      "aria-label": ariaLabel
        ? `${t("global.decrease")} ${ariaLabel}`
        : "decrease button",
      color,
      disabled: decreaseDisabled,
      EndIcon: <MaterialIcon name="remove" />,
      onClick: onDecreaseButtonClick,
      equalDimension: true,
      variant
    },
    valueProps: {
      "aria-label": ariaLabel || undefined
    },
    increaseButtonProps: {
      "aria-label": ariaLabel
        ? `${t("global.increase")} ${ariaLabel}`
        : "increase button",
      color,
      disabled: increaseDisabled,
      EndIcon: <MaterialIcon name="add" />,
      onClick: onIncreaseButtonClick,
      equalDimension: true,
      variant
    }
  };
};
