import { useDebounce } from "@website/hooks";
import { Origin } from "@website/icons";
import { useTranslation } from "@website/locale";
import { useCallback, useEffect, useMemo, useState } from "react";
import { NoResult } from "../../../..";
import { useCitiesService } from "../services";
import { useSearchSuggestionsService } from "../services/use-tour-suggestions-service";

export const useDestination = () => {
  const { t } = useTranslation();
  const [destinationQuery, setDestinationQuery] = useState<string>("");
  const [selectedDestinationItem, setSelectedDestinationItem] = useState(null);
  const query = useDebounce(destinationQuery, 250);

  const { suggestions } = useSearchSuggestionsService("dest");
  const { isLoading, data } = useCitiesService(query);

  const onChangeDestinationQuery = useCallback((value: string) => {
    setDestinationQuery(value);
  }, []);

  const destinationList = useMemo(
    () =>
      query.length >= 2
        ? data?.map((item) => ({
            city: item?.title_fa,
            iata_code: item?.iata_code
          })) || []
        : suggestions || [],
    [suggestions, query, data]
  );

  useEffect(() => {
    setDestinationQuery("");
    setSelectedDestinationItem(null);
  }, []);

  const destinationListNormalized = useMemo(
    () =>
      destinationList.map((item) => ({
        id: item?.iata_code,
        title: item?.city || item?.title_fa,
        subtitle: item?.iata_code,
        StartIcon: <Origin />
      })),
    [destinationList]
  );

  const onSelectDestination = useCallback(
    (id: string) => {
      const destinationItem = destinationList?.find(
        (destination: any) => `${destination?.iata_code}` === `${id}`
      );
      setDestinationQuery("");
      setSelectedDestinationItem(destinationItem);
    },
    [destinationList]
  );

  const onChangeDestination = useCallback((item: any) => {
    setDestinationQuery("");
    setSelectedDestinationItem(item);
  }, []);

  return {
    selectedDestinationItem,
    selectedDestinationText:
      selectedDestinationItem?.city || selectedDestinationItem?.title_fa || "",
    destinationQuery,
    onChangeDestination,
    onSelectDestination,
    onChangeDestinationQuery,
    destinationList,
    destinationLoading: isLoading,
    destinationListNormalized,
    DestinationNoResult:
      destinationQuery?.length >= 2 &&
      !isLoading &&
      destinationList &&
      destinationList.length === 0 ? (
        <NoResult query={destinationQuery} />
      ) : undefined,
    destinationLabel: t("homepage.tour.main_search.destination")
  };
};
