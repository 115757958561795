import { memo, SVGProps } from "react";

const SvgArrowright = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    height="1em"
    viewBox="0 0 32 32"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <path d="M5.333 17.333a1.333 1.333 0 0 1 0-2.666h19.448L17.724 7.61a1.332 1.332 0 1 1 1.885-1.885l9.333 9.333a1.332 1.332 0 0 1 0 1.885l-9.333 9.333a1.332 1.332 0 1 1-1.885-1.885l7.057-7.057H5.333z" />
  </svg>
);

const Memo = memo(SvgArrowright);
export default Memo;
