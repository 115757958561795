import { memo, SVGProps } from "react";

const SvgVilla = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <path
      d="M0 0h24v24H0z"
      style={{
        fill: "none"
      }}
    />
    <path d="M19.611 9.42V4.201c0-.3-.243-.544-.544-.544h-2.175c-.3 0-.544.243-.544.544v2.283l-3.622-3.26a1.088 1.088 0 0 0-1.455 0L2.18 11.408a.544.544 0 0 0 .364.948h1.845v8.155c0 .3.243.544.544.544h4.349c.3 0 .544-.243.544-.544v-4.893c0-.601.487-1.087 1.087-1.087h2.175c.601 0 1.087.487 1.087 1.087v4.893c0 .3.243.544.544.544h4.349c.3 0 .544-.243.544-.544v-8.155h1.845a.544.544 0 0 0 .364-.948l-2.21-1.988zm-9.786.761c0-1.196.979-2.175 2.175-2.175s2.175.979 2.175 2.175h-4.35z" />
  </svg>
);

const Memo = memo(SvgVilla);
export default Memo;
