import { useQuery } from "react-query";
import {
  SearchTextServiceHotelian,
  SearchTextUrlHotelian
} from "../../../../../services/base/international-hotel/search-text";

export const useSearchDestinationServiceHotelian = (query: string) => {
  const { isLoading, data } = useQuery(
    [SearchTextUrlHotelian, query],
    () => (query ? SearchTextServiceHotelian(query) : null),
    {
      enabled: query.length > 1
    }
  );
  return { isLoading, data: query ? data?.data || [] : [] };
};
