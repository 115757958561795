import { useViewPort } from "@website/hooks";
import { LazyImage } from "@website/ui-old";
import { useRouter } from "next/router";
import { FC, memo, useEffect, useState } from "react";
import { BannerSlideItemPropertiesType } from "../../banner-slide.types";
import styles from "./banner-slide-item.module.scss";

// TODO:
// - width and height are temporary (get them from api)

const BannerSlideItem: FC<BannerSlideItemPropertiesType> = (properties) => {
  const {
    id = 0,
    alt,
    desktopSrc,
    mobileSrc,
    linkTo,
    isSameTab,
    className,
    imageStyle
  } = properties;
  const { isDesktop } = useViewPort();
  const { pathname } = useRouter();
  const channel = process.env.NEXT_PUBLIC_CHANNEL_TYPE;
  const router = useRouter();

  const imageUrl = isDesktop ? desktopSrc : mobileSrc;
  const imageWidth = isDesktop ? 1200 : 624;
  const imageHeight = isDesktop ? 360 : 388;
  const [ID, setID] = useState("BannerSlideItem");

  useEffect(() => {
    try {
      setID(
        `${
          pathname?.length === 1 ? "hotel" : pathname?.slice(1)
        }_${channel}_big-banner_${id + 1}`
      );
    } catch {
      setID("big-banner");
    }
  }, [channel, id, pathname]);

  if (linkTo) {
    const jekLink = `${linkTo}?${new URLSearchParams(router?.query as any)}`;
    return (
      <a
        className={`${className} ${styles["image-wrapper"]}`}
        href={channel === "jek" ? jekLink : linkTo}
        rel="noreferrer"
        target={isSameTab ? "_self" : "_blank"}
      >
        <LazyImage
          alt={alt}
          className={`${imageStyle} ${styles["image"]}`}
          height={imageHeight}
          id={ID}
          loading="lazy"
          src={imageUrl as string}
          width={imageWidth}
        />
      </a>
    );
  }

  return (
    <div className={`${className} ${styles["image-wrapper"]}`}>
      <LazyImage
        alt={alt}
        className={`${imageStyle} ${styles["image"]}`}
        height={imageHeight}
        id={ID}
        loading="lazy"
        src={imageUrl as string}
        width={imageWidth}
      />
    </div>
  );
};

const BannerSlideItemMemoized = memo(BannerSlideItem);
export { BannerSlideItemMemoized as BannerSlideItem };
