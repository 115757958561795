import { MoreHoriz } from "@website/icons";
import { deepCompare } from "@website/utils";
import { FC, Fragment, memo } from "react";
import { Link } from "..";
import { useBreadcrumb } from "./breadcrumb.hook";
import styles from "./breadcrumb.module.scss";
import { BreadcrumbPropertiesType } from "./breadcrumb.types";

const Breadcrumb: FC<BreadcrumbPropertiesType> = (properties) => {
  const { className, list, variant } = properties;
  const { rootProperties, itemsProperties } = useBreadcrumb(properties);

  return (
    <div
      {...rootProperties}
      className={`${styles["breadcrumb"]} ${className ?? ""}`}
    >
      {variant === "collapsed" && list.length > 3 ? (
        <>
          <Link {...itemsProperties[0]}>{list[0].title}</Link>
          <div className={styles["divider"]}>/</div>
          <MoreHoriz className={styles["more-icon"]} />
          <div className={styles["divider"]}>/</div>
          <Link {...itemsProperties[list.length - 2]}>
            {list.at(-2)?.title}
          </Link>
          <div className={styles["divider"]}>/</div>
          <Link {...itemsProperties[list.length - 1]}>
            {list.at(-1)?.title}
          </Link>
        </>
      ) : (
        list.map((item, index) => (
          <Fragment key={item.title}>
            {index > 0 ? <div className={styles["divider"]}>/</div> : null}
            <Link {...itemsProperties[index]}>{item.title}</Link>
          </Fragment>
        ))
      )}
    </div>
  );
};

const BreadcrumbMemoized = memo(Breadcrumb, deepCompare);
export { BreadcrumbMemoized as Breadcrumb };
