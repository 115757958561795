import { memo, SVGProps } from "react";

const SvgAirport = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <circle cx="12" cy="12" fill="white" r="10" />
    <path
      d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
      fill="#7AC2C4"
    />
    <path
      d="M17.1102 6.89003C16.6962 6.476 16.0229 6.48296 15.6175 6.90542L13.8423 8.75534C13.5711 9.0379 13.1618 9.1395 12.79 9.01644L8.1252 7.47268C8.0381 7.44388 7.94209 7.46661 7.87723 7.53146L6.84636 8.56243L10.7095 10.941C11.0442 11.1471 11.1033 11.6094 10.8311 11.893L9.16459 13.6295C9.10924 13.6872 9.029 13.714 8.95013 13.701L7.73438 13.5015C7.65718 13.4888 7.5787 13.5141 7.52335 13.5693L6.58398 14.5087L8.62702 15.2243C8.69668 15.2487 8.75154 15.3035 8.77594 15.3732L9.49153 17.4163L10.4309 16.4769C10.4862 16.4216 10.5114 16.3431 10.4988 16.2659L10.2992 15.0501C10.2863 14.9712 10.313 14.891 10.3707 14.8356L12.1072 13.1692C12.3907 12.8972 12.8531 12.9561 13.0592 13.2908L15.4378 17.154L16.4688 16.123C16.5336 16.0582 16.5565 15.9621 16.5276 15.875L14.9839 11.2104C14.8608 10.8385 14.9624 10.4291 15.245 10.158L17.0948 8.38285C17.5173 7.97734 17.5242 7.30407 17.1102 6.89003Z"
      fill="white"
    />
  </svg>
);

const Memo = memo(SvgAirport);
export default Memo;
