export const checkPasswordConditionErrors = (password: string) => {
  const errors = {
    useEnglishCharacter: true,
    useNumberCharacter: true,
    useSpecialCharacter: true,
    reachMinimumCharactersLength: true
  };

  const regexpEnglish = /[A-Za-z]+/g;
  if (regexpEnglish.test(password)) {
    errors.useEnglishCharacter = false;
  }

  const regexpNumber = /\d+/g;
  if (regexpNumber.test(password)) {
    errors.useNumberCharacter = false;
  }

  const regexpSpecial = /[!"#$%&'()*+,./:;<=>?@[\\\]^_`{|}~-]/g;
  if (regexpSpecial.test(password)) {
    errors.useSpecialCharacter = false;
  }

  if (password.length >= 8) {
    errors.reachMinimumCharactersLength = false;
  }

  return errors;
};
