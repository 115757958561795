/* eslint-disable react/jsx-no-bind */
import { MaterialIcon } from "@website/icons";
import {
  CarouselNavigationButtonPropertiesType,
  CarouselNavigationPropertiesType
} from "./carousel.types";

const Button = ({
  icon,
  onClick,
  disabled,
  classes
}: CarouselNavigationButtonPropertiesType) => (
  <button
    className={`bg-on-surface-high-emphasis absolute bottom-1/2 z-10 size-9 translate-y-1/2 transform-gpu rounded-full px-1.5 py-0.5 opacity-0 transition group-hover:opacity-100 ${classes} ${
      disabled ? "hidden" : ""
    }`}
    disabled={disabled}
    onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      onClick();
    }}
    type="button"
  >
    <MaterialIcon name={icon} />
  </button>
);

const CarouselNavigation = ({
  currentSlide,
  currentReference
}: CarouselNavigationPropertiesType) => (
  <div className="text-on-color-high-emphasis text-2xl hover:block max-lg:hidden">
    <Button
      classes="-right-4"
      disabled={currentSlide === 0}
      icon="chevron_right"
      onClick={() => currentReference?.prev()}
    />
    <Button
      classes="-left-4"
      disabled={currentSlide === currentReference?.track?.details?.maxIdx}
      icon="chevron_left"
      onClick={() => currentReference?.next()}
    />
  </div>
);

export default CarouselNavigation;
