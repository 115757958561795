import { deepCompare } from "@website/utils";
import Image from "next/image";
import { FC, memo } from "react";
import { RESULT_TYPES } from "./result.constants";
import { IResult } from "./result.types";

const Result: FC<IResult> = ({ status, title, subtitle, extra }) => {
  const result = RESULT_TYPES[status];

  return (
    <div className="my-10 flex flex-col items-center">
      <Image alt="no passenger" height={147} src={result?.image} width={188} />
      <div className="text-subtitle-1 text-on-surface-high-emphasis mb-2 mt-6">
        {title || result?.title}
      </div>
      {subtitle && (
        <div className="text-caption text-on-surface-medium-emphasis max-w-[400px] px-6 text-center">
          {subtitle}
        </div>
      )}
      {extra && (
        <div className="text-caption text-on-surface-medium-emphasis max-w-[400px] px-6 text-center">
          {extra}
        </div>
      )}
    </div>
  );
};

const ResultMemoized = memo(Result, deepCompare);
export { ResultMemoized as Result };
