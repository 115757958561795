import { Link } from "@website/ui-old";
import { FC } from "react";
import styles from "./inbound-link-content.module.scss";
import { InboundLinkContentPropertiesType } from "./inbound-links.types";

export const InboundLinkContent: FC<InboundLinkContentPropertiesType> = (
  properties
) => {
  const { list } = properties;

  return (
    <div className={styles["container"]}>
      {list &&
        list.map((item) => (
          <Link color="default" href={item.url} key={item.title}>
            {item.title}
          </Link>
        ))}
    </div>
  );
};
