import { useIsVisible, useViewPort } from "@website/hooks";
import { useTranslation } from "@website/locale";
import { FC, Fragment, memo, useEffect, useMemo } from "react";
import { Table } from "../table";
import styles from "./main.module.scss";
import { MainPropertiesType } from "./main.types";

const Main: FC<MainPropertiesType> = (properties) => {
  const {
    type,
    calendarLocale,
    value,
    onChange,
    showDates,
    increaseShowDatesNumber,
    scrollReference,
    setScrollReference,
    calendarData,
    mode,
    disableLimit,
    view
  } = properties;

  const { direction } = useTranslation();
  const { reference, inView } = useIsVisible({
    root: scrollReference,
    rootMargin: "50px"
  });
  const { isDesktop } = useViewPort();

  useEffect(() => {
    if (!isDesktop && inView && showDates.length < 12) {
      increaseShowDatesNumber();
    }
  }, [isDesktop, inView, showDates, increaseShowDatesNumber]);

  const calendarInfo = useMemo(
    () => ({
      findDisableDay: false,
      selectedDate: undefined
    }),
    []
  );

  return (
    <div
      className={`${styles["main"]} ${
        view === "mobile" ? styles["main__mobile"] : styles["main__default"]
      } ${styles[direction || "ltr"]}`}
    >
      <div
        className={`${styles["tables-container"]} ${
          view === "mobile" ? "" : styles["tables-container__default"]
        }`}
        ref={view === "mobile" ? setScrollReference : null}
      >
        {showDates.map((item, index) => (
          <Fragment key={`${item.year}_${item.month}`}>
            {index > 0 ? (
              <div
                className={`${styles["divider"]} ${
                  view === "mobile"
                    ? styles["divider__mobile"]
                    : styles["divider__default"]
                }`}
              />
            ) : null}

            <Table
              calendarData={calendarData}
              calendarInfo={calendarInfo}
              calendarLocale={calendarLocale}
              disableLimit={disableLimit}
              isLast={showDates.length === index + 1}
              mode={mode}
              month={item.month}
              onChange={onChange}
              type={type}
              value={value}
              view={view}
              year={item.year}
            />
          </Fragment>
        ))}
        {type === "popover" && (
          <div className={styles["scroll-more-area"]} ref={reference} />
        )}
      </div>
    </div>
  );
};

const MainMemoized = memo(Main);
export { MainMemoized as Main };
