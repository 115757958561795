import { useRouter } from "next/router";
import { useCallback } from "react";

export const useBack = (redirectUrl?: string) => {
  const router = useRouter();

  const goBack = useCallback(() => {
    if (redirectUrl) {
      return window.location.assign(redirectUrl);
    }
    if (window.history.length <= 1) {
      return window.location.replace(`${process.env.NEXT_PUBLIC_BASE_URL_B2C}`);
    }
    if (["/shopping/[shopId]/voucher"].includes(router.pathname)) {
      if (router.query.success === "true") {
        return window.history.go((window.history.length - 1) * -1);
      }
      if (window.history.length > 2) {
        return window.history.go(-2);
      }
    }
    return router.back();
  }, [redirectUrl, router]);

  return { goBack };
};
