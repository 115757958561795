import { FC, memo } from "react";
import { Badge } from "..";
import { useTabButton } from "./tab-button.hook";
import styles from "./tab-button.module.scss";
import { TabButtonPropertiesType } from "./tab-button.types";

const TabButton: FC<TabButtonPropertiesType> = (properties) => {
  const {
    badge,
    description,
    disabled,
    Wrapper,
    Icon,
    title,
    selected,
    variant = "contained",
    color = "primary",
    iconPosition = "top",
    behavior = "button",
    size = "content",
    defaultBorder = false,
    full = false
  } = properties;

  const { buttonProperties, linkProperties } = useTabButton(properties);

  const wrapperClassName = `${styles["tab-button"]} ${styles[variant]} ${
    styles[size]
  } ${iconPosition === "side" ? styles["side-icon"] : styles["top-icon"]} ${
    styles[color]
  } ${disabled ? styles["disabled"] : ""} ${
    selected ? styles["selected"] : ""
  } ${defaultBorder ? styles["border-default"] : ""} ${
    full ? styles["full-width"] : ""
  }`;

  const content = (
    <>
      {Icon ? <div className={styles["button-icon"]}>{Icon}</div> : null}
      <div className={styles["button-content"]}>
        <div className={styles["button-middle"]}>
          <div className={styles["button-title"]}>{title}</div>
          {badge ? <Badge color={color} content={badge} /> : null}
        </div>
        {description ? (
          <div className={styles["button-description"]}>{description}</div>
        ) : null}
      </div>
    </>
  );

  if (behavior === "link") {
    return (
      <a {...linkProperties} className={wrapperClassName}>
        {content}
      </a>
    );
  }
  if (behavior === "custom" && Wrapper) {
    return (
      <Wrapper {...linkProperties} className={wrapperClassName}>
        {content}
      </Wrapper>
    );
  }
  return (
    <button {...buttonProperties} className={wrapperClassName}>
      {content}
    </button>
  );
};

const TabButtonMemoized = memo(TabButton);
export { TabButtonMemoized as TabButton };
