import { memo, SVGProps } from "react";

const SvgVentureBus = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <path
      clipRule="evenodd"
      d="M18.25 2c1.15 0 2.083.935 2.083 2.083v3.792A2.087 2.087 0 0 1 22 9.917v.833c0 .69-.56 1.25-1.25 1.25l-.418-.001.001 6.251a2.087 2.087 0 0 1-1.665 2.041l-.001.459c0 .69-.561 1.25-1.25 1.25H15.75c-.69 0-1.25-.56-1.25-1.25v-.418h-5v.418c0 .643-.489 1.175-1.114 1.243L8.25 22H6.583c-.689 0-1.25-.56-1.25-1.25v-.459a2.087 2.087 0 0 1-1.666-2.041v-6.251L3.25 12a1.252 1.252 0 0 1-1.243-1.114L2 10.75v-.833c0-1.006.717-1.848 1.667-2.042V4.083C3.667 2.935 4.6 2 5.75 2h12.5ZM7.417 15.333c-.69 0-1.25.561-1.25 1.25 0 .69.56 1.25 1.25 1.25.689 0 1.25-.56 1.25-1.25 0-.689-.561-1.25-1.25-1.25Zm9.166 0c-.689 0-1.25.561-1.25 1.25 0 .69.561 1.25 1.25 1.25.69 0 1.25-.56 1.25-1.25 0-.689-.56-1.25-1.25-1.25ZM17.417 7H6.583c-.689 0-1.25.56-1.25 1.25v4.167c0 .689.561 1.25 1.25 1.25h10.834c.689 0 1.25-.561 1.25-1.25V8.25c0-.69-.561-1.25-1.25-1.25Zm-.834-3.333H7.417c-.69 0-1.25.56-1.25 1.25 0 .689.56 1.25 1.25 1.25h9.166c.69 0 1.25-.561 1.25-1.25 0-.69-.56-1.25-1.25-1.25Z"
      fillRule="evenodd"
    />
  </svg>
);

const Memo = memo(SvgVentureBus);
export default Memo;
