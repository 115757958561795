import { INTERNATIONAL_HOTEL_ERROR } from "./types";

export const hotel_international = {
  hotel_state: "هتل خارجی",
  booking: {
    labels: {
      name: "نام (انگلیسی مطابق گذرنامه)",
      family: "نام خانوادگی (انگلیسی مطابق گذرنامه)"
    },
    voucher_download: "دانلود ووچر انگلیسی",
    voucher_receipt: "دانلود رسید"
  },
  errors: {
    [INTERNATIONAL_HOTEL_ERROR.INTERNAL]: "خطایی رخ داده است!",
    [INTERNATIONAL_HOTEL_ERROR.INVALID_REQUEST]: "درخواست نامعتبر است!",
    [INTERNATIONAL_HOTEL_ERROR.EXPIRED_SEARCH]:
      "جستجو منقضی شده است لطفا دوباره جستجو کنید! ",
    [INTERNATIONAL_HOTEL_ERROR.ALREADY_BOOKED]: "قبلا رزرو شده است!",
    [INTERNATIONAL_HOTEL_ERROR.GET_POLICIES_NOT_CALLED]:
      "قوانین دریافت شده فراخوانی نشده اند!",
    [INTERNATIONAL_HOTEL_ERROR.INVALID_TITLE]: "عنوان نامعتبر است!",
    [INTERNATIONAL_HOTEL_ERROR.INVALID_HOTELIAN_CODE]:
      "کد هتلداری نامعتبر است!",
    [INTERNATIONAL_HOTEL_ERROR.RESOURCE_NOT_FOUND]: "منبع یافت نشد!",
    [INTERNATIONAL_HOTEL_ERROR.ROOM_SOLD_OUT]: "ظرفیت اتاق تمام شده است!",
    [INTERNATIONAL_HOTEL_ERROR.INVALID_PHONE_NUMBER]:
      "شماره همراه نامعتبر است!",
    [INTERNATIONAL_HOTEL_ERROR.INVALID_CHECKIN_DATE]: "تاریخ ورود نامعتبر است!",
    [INTERNATIONAL_HOTEL_ERROR.INVALID_CHECKOUT_DATE]:
      "تاریخ خروج نامعتبر است!",
    [INTERNATIONAL_HOTEL_ERROR.ROOMS_NO_EXCEEDED]:
      "تعداد اتاق ها بیش از حد مجاز است",
    [INTERNATIONAL_HOTEL_ERROR.DISCOUNT_CODE_EXPIRED]:
      "این کد تخفیف منقضی شده است!",
    [INTERNATIONAL_HOTEL_ERROR.DISCOUNT_CODE_NOT_FOUND]:
      "این کد تخفیف نامعتبر است!",
    [INTERNATIONAL_HOTEL_ERROR.DISCOUNT_CODE_MIN_STAY_NOT_REACHED]:
      "حداقل اقامت کد تخفیف به دست نیامده است!",
    [INTERNATIONAL_HOTEL_ERROR.DISCOUNT_CODE_USED]:
      "این کد تخفیف استفاده شده است! ",
    [INTERNATIONAL_HOTEL_ERROR.DISCOUNT_CODE_MOST_PRICE_NOT_REACHED]:
      "این کد تخفیف، برای خرید شما قابل استفاده نیست.",
    [INTERNATIONAL_HOTEL_ERROR.DISCOUNT_CODE_MIN_PRICE_NOT_REACHED]:
      "حداقل قیمت کد تخفیف به دست نیامده است!",
    [INTERNATIONAL_HOTEL_ERROR.DISCOUNT_CODE_NOT_ALLOWED]:
      "این کد تخفیف مجاز نیست!",
    [INTERNATIONAL_HOTEL_ERROR.DISCOUNT_CODE_NOT_MATCHED_CONDITION]:
      "!این کد تخفیف منطبق با شرایط نیست",
    [INTERNATIONAL_HOTEL_ERROR.DISCOUNT_ALREADY_USED]:
      "این کد تخفیف قبلا استفاده شده است!",
    [INTERNATIONAL_HOTEL_ERROR.NO_HOTEL_FOUND]: "هتلی یافت نشد!"
  }
};
