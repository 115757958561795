import { useQuery } from "react-query";
import {
  SearchSuggestionsService,
  SearchSuggestionsUrl
} from "../../../../../services/cdn/tour/search-suggestions/search-suggestions";

export const useSearchSuggestionsService = (target: "origin" | "dest") => {
  const {
    isLoading,
    data
    // error
  } = useQuery(SearchSuggestionsUrl + target, SearchSuggestionsService);

  return { suggestions: data, suggestionsLoading: isLoading };
};
