import { useViewPort } from "@website/hooks";
import { FC, memo } from "react";
import { Transition } from "../transition";
import { usePopoverFull } from "./popover-full.hook";
import styles from "./popover-full.module.scss";
import { PopoverFullPropertiesType } from "./popover-full.types";

const PopoverFull: FC<PopoverFullPropertiesType> = (properties) => {
  const {
    animate,
    children,
    title,
    position,
    StartAction,
    EndAction,
    gapHorizontal,
    gapVertical,
    className
  } = properties;
  const { rootProps, transitionProps, popoverOpen, animateOpen } =
    usePopoverFull(properties);
  const { isMobile } = useViewPort();

  if (popoverOpen) {
    return (
      <Transition {...transitionProps} in={animateOpen}>
        {(state) => (
          <div
            {...rootProps}
            className={`${styles["popover-full"]} ${className} ${
              styles[position || "start"]
            } ${
              animate === "normal"
                ? ""
                : `${styles["animate"]} ${
                    state === "entering" || state === "entered"
                      ? styles["animate-enter"]
                      : styles["animate-exit"]
                  }`
            } `}
            style={{
              marginBlockStart: isMobile ? "0px" : gapVertical || "0px",
              ...(position === "start"
                ? {
                    marginInlineStart: isMobile ? "0px" : gapHorizontal || "0px"
                  }
                : {
                    marginInlineEnd: isMobile ? "0px" : gapHorizontal || "0px"
                  })
            }}
          >
            {StartAction || title || EndAction ? (
              <div className={styles["header"]}>
                {StartAction}
                <div className={styles["title"]}>{title}</div>
                {EndAction}
              </div>
            ) : null}
            <div className={`${styles["main"]}`}>{children}</div>
          </div>
        )}
      </Transition>
    );
  }
  return null;
};

const PopoverFullMemoized = memo(PopoverFull);
export { PopoverFullMemoized as PopoverFull };
