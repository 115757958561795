import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { numberConvert } from "@website/utils";
import { useMemo } from "react";
import { stringOfNumbers } from "../../datepicker.utils";
import { InputPropertiesType } from "./input.types";

export const useInput = (properties: InputPropertiesType) => {
  const {
    fromTitle,
    toTitle,
    value,
    setMode,
    calendarLocale,
    onOpenPopover,
    disabled,
    size = "default"
  } = properties;
  const { t, locale } = useTranslation();

  const startTextFieldValue = useMemo(() => {
    if (value?.[0]) {
      const month = t<string>(
        `calendar.${calendarLocale}.monthNames.${
          stringOfNumbers[value[0].month]
        }`
      );
      const day = numberConvert(value[0].day, {
        numberOnly: true,
        locale
      });

      if (locale === "fa" || locale === "ar") {
        return `${day} ${month}`;
      }
      return `${month} ${day}`;
    }
    return "";
  }, [value, t, calendarLocale, locale]);

  const endTextFieldValue = useMemo(() => {
    if (value?.[1]) {
      const month = t<string>(
        `calendar.${calendarLocale}.monthNames.${
          stringOfNumbers[value?.[1].month]
        }`
      );
      const day = numberConvert(value?.[1].day, {
        numberOnly: true,
        locale
      });

      if (locale === "fa" || locale === "ar") {
        return `${day} ${month}`;
      }
      return `${month} ${day}`;
    }
    return "";
  }, [calendarLocale, locale, t, value]);

  return {
    rootProps: {
      tabIndex: 0
    },
    startTextFieldProperties: {
      "data-testid": "date_picker_start_input",
      label: value?.[0] ? fromTitle ?? t("calendar.startDate") : "",
      placeholder: value?.[0] ? "" : fromTitle ?? t("calendar.startDate"),
      StartIcon: <MaterialIcon name="date_range" variant="fill" />,
      readOnly: true,
      value: startTextFieldValue,
      onClick: onOpenPopover,
      fullWidth: true,
      disabled,
      size
    },
    endTextFieldProperties: {
      "data-testid": "date_picker_end_input",
      label: value?.[1] ? toTitle ?? t("calendar.endDate") : "",
      placeholder: value?.[1] ? "" : toTitle ?? t("calendar.endDate"),
      readOnly: true,
      value: endTextFieldValue,
      onClick: onOpenPopover,
      fullWidth: true,
      disabled,
      size
    },
    endEnableButtonProperties: {
      role: "button",
      onClick: setMode?.bind(null, "start-end")
    },
    endEnableButtonText: toTitle ?? t("calendar.endDate")
  };
};
