import { useViewPort } from "@website/hooks";
import { useTranslation } from "@website/locale";
import { deepCompare } from "@website/utils";
import { memo } from "react";

const Component = () => {
  const { isMobile } = useViewPort();
  const { t } = useTranslation();
  return (
    <div className="flex flex-row items-center justify-between xl:flex-col xl:items-start">
      <div className="text-subtitle-1 pb-1">
        {isMobile
          ? t("comments.passengers_comments")
          : t("comments.submit_comments")}
      </div>
      <div className="text-body-2 text-on-surface-medium-emphasis hidden pb-6 xl:block">
        {t("comments.description")}
      </div>
      {/*<Button*/}
      {/*  className={"xl:w-full"}*/}
      {/*  color="secondary"*/}
      {/*  size={isMobile ? "small" : "medium"}*/}
      {/*  variant="outline"*/}
      {/*>*/}
      {/*  ثبت دیدگاه*/}
      {/*</Button>*/}
    </div>
  );
};
export const ReserveCommentPopupSubmit = memo(Component, deepCompare);
