import { Chips, ListItem, MiniPopover } from "@website/ui-old";
import { FC } from "react";
import { useSaleType } from "./sale-type.hook";
import styles from "./sale-type.module.scss";
import { SaleTypePropertiesType } from "./sale-type.types";

export const SaleType: FC<SaleTypePropertiesType> = (properties) => {
  const { chipsProps, list, popoverProps } = useSaleType(properties);
  return (
    <div className={styles["container"]}>
      <Chips {...chipsProps} />
      <MiniPopover {...popoverProps}>
        <div className={styles["mini-popover"]}>
          <div className={styles["main"]}>
            {list.map((item) => (
              <ListItem key={item.title} {...item} />
            ))}
          </div>
        </div>
      </MiniPopover>
    </div>
  );
};
