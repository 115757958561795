import { memo, SVGProps } from "react";

const SvgAngleRightRegular = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    height="1em"
    viewBox="0 0 32 32"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <path d="M11.584 24.584a2 2 0 1 0 2.828 2.828l10-9.996a1.998 1.998 0 0 0 0-2.828l-10-10a1.998 1.998 0 0 0-2.828 0 1.998 1.998 0 0 0 0 2.828l8.586 8.586-8.586 8.582z" />
  </svg>
);

const Memo = memo(SvgAngleRightRegular);
export default Memo;
