import { debounce } from "lodash";
import { useEffect, useMemo, useRef } from "react";
import { RollPropertiesType } from "./roll.types";

export const useRoll = (properties: RollPropertiesType) => {
  const { value, onChange, list } = properties;
  const reference = useRef<HTMLDivElement>(null);
  const isTouching = useRef<boolean>(false); // Use useRef to preserve the value

  const selectedIndex = useMemo(
    () => list.findIndex((item) => item.value === value),
    [list, value]
  );

  useEffect(() => {
    function goToIndex() {
      if (reference.current && !isTouching.current) {
        const scrollTop = reference.current.scrollTop || 0;
        const index = Math.round(scrollTop / 48);
        reference.current.scrollTo(0, index * 48);
        onChange(list[index]?.value);
      }
    }

    const onScroll = debounce(goToIndex, 100);

    function onTouchStart() {
      isTouching.current = true;
    }

    function onTouchEnd() {
      isTouching.current = false;
      goToIndex();
    }

    const currentReference = reference.current;

    if (currentReference) {
      currentReference.addEventListener("scroll", onScroll);
      currentReference.addEventListener("touchstart", onTouchStart);
      currentReference.addEventListener("touchend", onTouchEnd);
    }

    return () => {
      if (currentReference) {
        currentReference.removeEventListener("scroll", onScroll);
        currentReference.removeEventListener("touchstart", onTouchStart);
        currentReference.removeEventListener("touchend", onTouchEnd);
      }
    };
  }, [onChange, list]);

  useEffect(() => {
    if (reference?.current) {
      reference.current.scrollTo(0, selectedIndex * 48);
    }
  }, [selectedIndex]);

  return {
    selectedIndex,
    scrollProperties: {
      ref: reference
    }
  };
};
