import { memo, SVGProps } from "react";

const SvgVentureFlightInt = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <path
      clipRule="evenodd"
      d="m13.123 9.629.464 2.007a6.29 6.29 0 0 0-2.218 2.724l-1.023-1.955L6.953 15.8l.278 1.943a.418.418 0 0 1-.124.37l-.364.364a.439.439 0 0 1-.69-.098l-1.26-2.27-2.27-1.26a.439.439 0 0 1-.099-.69l.364-.365a.418.418 0 0 1 .37-.123l1.944.278 3.393-3.394-5.38-2.813a.915.915 0 0 1 .63-1.703l7.527 1.74 3.393-3.394a1.307 1.307 0 0 1 1.851 0 1.307 1.307 0 0 1 0 1.851L13.123 9.63Zm9.332 7.168a5.31 5.31 0 0 0-5.304-5.304l-.23.005a5.31 5.31 0 0 0-5.075 5.3 5.31 5.31 0 0 0 5.305 5.304l.23-.005a5.311 5.311 0 0 0 5.074-5.3Zm-9.545-.157a4.25 4.25 0 0 1 4.465-4.08.219.219 0 0 0 .07.203c.032.028.054.051.069.068l.03.038c-.006.01-.046.08-.272.195l-.126.07c-.339.206-.686.551-.743.91a.538.538 0 0 0 .15.478.221.221 0 0 0 .156.065c.306 0 .543-.113.772-.223h.001l.14-.066c.188-.085.381-.153.634-.153.867 0 1.547.194 1.547.442 0 .091-.029.119-.04.13-.08.08-.31.095-.555.096l-.627-.008a.918.918 0 0 1-.29-.05l-.21-.093a1.296 1.296 0 0 0-.488-.075c-.365 0-1.042.066-1.483.507-.36.36-.322.79-.297 1.076l.008.096.004.09c0 .434.444.662.884.662.675 0 1.26.126 1.326.221 0 .248.073.417.132.552l.035.082a.634.634 0 0 1 .054.25c0 .1-.017.122-.061.179l-.041.056a.694.694 0 0 0-.12.428c0 .38.3.792.437.96l.062.072a.221.221 0 0 0 .22.066c.13-.033 1.27-.347 1.27-1.098 0-.235.074-.298.185-.395l.048-.043c.1-.09.21-.218.21-.446 0-.177.314-.634.606-.957a.221.221 0 0 0-.029-.322c-.171-.134-.646-.567-.805-.988.09.047.203.124.293.213.073.074.172.11.284.105.136-.008.292-.091.447-.208a4.25 4.25 0 0 1-4.111 5.296 4.233 4.233 0 0 1-3.095-1.344v-.469l.011-.118c.028-.147.105-.258.192-.383l.057-.084c.094-.141.182-.304.182-.52 0-.345-.254-.436-.459-.47l-.212-.027c-.113-.015-.214-.038-.277-.101-.06-.06-.12-.169-.184-.284l-.065-.117a1.705 1.705 0 0 0-.39-.484Z"
      fillRule="evenodd"
    />
  </svg>
);

const Memo = memo(SvgVentureFlightInt);
export default Memo;
