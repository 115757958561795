export const createOriginDestinationItemSubtitle = (city) => {
  if (city.airports?.length > 1) {
    return "همه فرودگاه ها";
  }
  return city.airports?.[0]?.faName
    ? `${city.airports?.[0]?.faName} - ${city.airports?.[0]?.iataCode} `
    : `فرودگاه  ${city.airports?.[0]?.iataCode} - ${city.airports?.[0]?.name} `;
};

export const createOriginDestinationInputText = (city) => {
  if (city) {
    return city?.airportName
      ? `${city?.cityFaName}، (${city?.airportIataCode}) ${
          city?.airportFaName || `فرودگاه ${city?.airportName}`
        }`
      : city?.cityFaName;
  }
};

export const createOriginDestinationHistorySubtitle = (city) => {
  if (city) {
    if (city?.airportName) {
      return `${city?.cityFaName} - ${city?.airportIataCode}`;
    }
    if (city.airports.length > 1) {
      return "همه فرودگاه ها";
    }
    return `${
      city?.airports[0]?.faName || `فرودگاه ${city?.airports[0]?.name}`
    } - ${city?.airports[0]?.iataCode}`;
  }
};

export const createOriginDestinationHistoryTitle = (city) => {
  if (city.airportName) {
    return city.airportFaName || `فرودگاه ${city.airportName}`;
  }
  return `${city.cityFaName}، ${city.countryFaName}`;
};
