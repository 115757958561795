import { SVGProps, memo } from "react";

const SvgTripCollection = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <path
      clipRule="evenodd"
      d="M19.341 13.387c.48 0 .877.363.932.829l.006.109v1.706a.94.94 0 0 1 .743.807l.007.112v.375c0 .279-.204.511-.471.555l-.092.007h-.188V20.7a.94.94 0 0 1-.748.918l-.001.207c0 .279-.204.511-.471.555l-.092.007h-.75a.564.564 0 0 1-.555-.471l-.007-.091-.001-.189h-2.25v.189c0 .279-.204.511-.47.555l-.092.007h-.75a.564.564 0 0 1-.555-.471l-.007-.091v-.207a.94.94 0 0 1-.743-.807l-.007-.111v-2.813h-.188a.564.564 0 0 1-.555-.471l-.007-.091v-.375a.94.94 0 0 1 .75-.919v-1.706c0-.48.362-.877.828-.931l.11-.007h5.624Zm-4.875 6a.563.563 0 1 0 .002 1.127.563.563 0 0 0-.002-1.127Zm4.125 0a.563.563 0 1 0 .002 1.127.563.563 0 0 0-.002-1.127ZM16.674 2.44a1.498 1.498 0 0 1 2.121 0 1.498 1.498 0 0 1 0 2.121l-3.889 3.89.773 3.988.058.316h-2.55a.199.199 0 0 1-.138-.057l-.05-.05-.017-.013-1.258-1.002-3.889 3.889.318 2.227a.48.48 0 0 1-.141.424l-.417.418a.503.503 0 0 1-.792-.114L5.36 15.875l-2.602-1.442a.503.503 0 0 1-.113-.792l.417-.417a.48.48 0 0 1 .424-.142l2.228.318 3.889-3.889-6.166-3.224a1.05 1.05 0 0 1 .721-1.952l8.627 1.994 3.889-3.889Zm2.292 13.197h-4.875a.563.563 0 0 0-.555.472l-.007.09v1.876c0 .279.204.511.471.555l.091.007h4.875c.28 0 .512-.204.556-.471l.007-.091V16.2a.563.563 0 0 0-.563-.563Zm-.375-1.5h-4.125a.563.563 0 0 0-.09 1.118l.09.007h4.125a.563.563 0 0 0 0-1.125Z"
      fillRule="evenodd"
    />
  </svg>
);

const Memo = memo(SvgTripCollection);
export default Memo;
