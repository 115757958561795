import { TabButtonPropertiesType } from "./tab-button.types";

export const useTabButton = (properties: TabButtonPropertiesType) => {
  const { index, disabled, description, onClick, selected, href, title } =
    properties;

  return {
    linkProperties: {
      href: href || "",
      onClick
    },
    buttonProperties: {
      "aria-label": `${title || ""} ${description || ""}`,
      "aria-controls": `tab-${index}`,
      "aria-selected": Boolean(selected),
      id: `tab-button-${index}`,
      role: "tab",
      onClick,
      disabled
    }
  };
};
