import { useAddRemoveQuery } from "@website/hooks";
import { Arrowright, CloseRounded, LocationOn, Search } from "@website/icons";
import { useRouter } from "next/router";
import { ChangeEvent, MouseEvent, useCallback } from "react";
import { Button } from "../..";
import { AutoCompletePropertiesType } from "../auto-complete.types";

export const useEnd = (properties: AutoCompletePropertiesType) => {
  const { parts } = properties;
  const router = useRouter();
  const key = "SEARCH_DESTINAION";
  const { [key]: isKey } = router.query;
  const { addQuery, removeQuery } = useAddRemoveQuery(key);

  const onCloseEndPopover = useCallback(() => {
    removeQuery();
  }, [removeQuery]);

  const onClickEndTextField = useCallback(() => {
    addQuery("open");
    parts[1]?.onFocus?.();
  }, [addQuery, parts]);

  const onChangeEndPopoverTextField = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      parts[1]?.onChangeQuery(event.target.value);
    },
    [parts]
  );

  const onClickEndItem = useCallback(
    (item: any) => {
      // parts[1]?.onChangeQuery(item.title);
      parts[1]?.onSelect(item.id);
      removeQuery();
    },
    [parts, removeQuery]
  );

  const onEmptyEndSearchField = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      parts[1]?.onChangeQuery("");
      // parts[1]?.onSelect("");
    },
    [parts]
  );

  const onClickHistoryItem = useCallback(
    (item: any) => {
      parts[1]?.onSelectHistory && parts[1]?.onSelectHistory(item.id);
      removeQuery();
    },
    [parts, removeQuery]
  );

  return {
    endTextFieldProperties: {
      disabled: parts[1]?.disabled,
      label: parts[1]?.text ? parts[1]?.label : "",
      value: parts[1]?.text,
      readOnly: true,
      placeholder: parts[1]?.label,
      onClick: onClickEndTextField,
      StartIcon: parts[1]?.StartIcon ?? <LocationOn />
    },
    endPopoverProperties: {
      animate: "bottom" as const,
      open: Boolean(isKey),
      onClose: onCloseEndPopover,
      position: "end" as const
    },
    endTopAppBarProperties: {
      StartAction: (
        <Button
          EndIcon={<Arrowright />}
          color="neutral"
          equalDimension
          onClick={onCloseEndPopover}
          variant="text"
        />
      ),
      title: `جستجوی ${parts[1]?.label}`
    },
    endPopoverSearchFieldProperties: {
      autoFocus: true,
      placeholder: `جستجوی ${parts[1]?.label}`,
      fullWidth: true,
      onChange: onChangeEndPopoverTextField,
      value: parts[1]?.query,
      StartIcon: parts[1]?.StartIcon ?? <LocationOn />,
      EndIcon: parts[1]?.query ? <CloseRounded /> : <Search />,
      onEndIconClick: parts[1]?.query ? onEmptyEndSearchField : undefined
    },
    endItemsProperties:
      parts[1]?.list?.map((item: any) => ({
        ...item,
        role: "button",
        size: "large",
        onClick: () => onClickEndItem(item)
      })) || [],
    endHistoryItemsProperties:
      parts[1]?.historyList?.map((item: any) => ({
        ...item,
        role: "button",
        size: "large",
        onClick: () => onClickHistoryItem(item)
      })) || []
  };
};
