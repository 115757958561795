import {
  CalendarDate,
  GregorianCalendar,
  PersianCalendar,
  getLocalTimeZone,
  today
} from "@internationalized/date";
import { DayType, MonthType, gregorianToShamsi } from "@website/utils";
import { DatepickerSplitCalendarLocaleType } from "./datepicker-split.types";

export const getToday = (calendarLocale: DatepickerSplitCalendarLocaleType) => {
  const localDate = today(getLocalTimeZone());

  const date =
    calendarLocale === "persian"
      ? // @internationalize/date has issue with
        // convert from gregorian to persian.
        // this code fix that issue.
        // update it later.
        new CalendarDate(
          new PersianCalendar(),
          ...gregorianToShamsi(
            localDate.year,
            localDate.month as MonthType,
            localDate.day as DayType
          )
        )
      : localDate;

  return {
    year: date.year,
    month: date.month,
    day: date.day
  };
};

export const getDaysInMonth = (
  calendarLocale: DatepickerSplitCalendarLocaleType,
  year: number,
  month: number
) => {
  if (calendarLocale === "persian") {
    const calendar = new CalendarDate(new PersianCalendar(), year, month, 1);
    return new PersianCalendar().getDaysInMonth(calendar);
  }

  const calendar = new CalendarDate(new GregorianCalendar(), year, month, 1);
  return new GregorianCalendar().getDaysInMonth(calendar);
};
