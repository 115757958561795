import { Switch } from "@website/ui";
import { useRouter } from "next/router";
import { useEffect, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { SwitchTypes } from "../filter.types";
import styles from "../index.module.scss";

interface SwitchProperties extends SwitchTypes {
  name: string;
}

export const SwitchFilter = ({
  name,
  title,
  description,
  type,
  defaultValue
}: SwitchProperties) => {
  const { control, setValue } = useFormContext();
  const router = useRouter();

  const parsedInitialValues = useMemo(
    () => Boolean(router.query[name] ?? defaultValue),
    [defaultValue, name, router.query]
  );

  useEffect(() => {
    setValue(name, { type, value: parsedInitialValues });
  }, [name, parsedInitialValues, setValue, type]);

  return (
    <div className={styles["switch-filter-container"]}>
      <div className="grid">
        <div id="has-availability">{title}</div>
        <span className={styles["has-availability"]}>{description}</span>
      </div>
      <Controller
        control={control}
        name={`${name}.value`}
        render={({ field: { value, onChange } }) => (
          <Switch
            aria-labelledby="has-availability"
            checked={value || false}
            color="secondary"
            onChange={onChange}
          />
        )}
      />
    </div>
  );
};
