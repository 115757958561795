import { FC, memo } from "react";
import { Alert } from "../../..";
import { useSnackbarItem } from "./snackbar-item.hook";
import styles from "./snackbar-item.module.scss";
import { SnackbarItemPropertiesType } from "./snackbar-item.types";

const SnackbarItem: FC<SnackbarItemPropertiesType> = (properties) => {
  const { index } = properties;
  const { alertProps, show } = useSnackbarItem(properties);

  return (
    <Alert
      {...alertProps}
      className={`${styles["container"]} ${show ? "" : styles["hide"]} ${
        index === 0 && styles["first"]
      } ${index === 1 && styles["second"]} ${index === 2 && styles["third"]}`}
    />
  );
};

const SnackbarItemMemoized = memo(SnackbarItem);
export { SnackbarItemMemoized as SnackbarItem };
