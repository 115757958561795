import { useViewPort } from "@website/hooks";
import { useTranslation } from "@website/locale";
import { FC, memo, useCallback } from "react";
import { PopoverFull } from "../popover-full";
import { Cta, Header, Input, Main } from "./components";
import { FlatHeader } from "./components/flat-header/flat-header";
import styles from "./datepicker.module.scss";
import { DatepickerPropertiesType } from "./datepicker.types";
import { useCalendarLocale, usePopover, useScroll, useShowDate } from "./hooks";

const Datepicker: FC<DatepickerPropertiesType> = (properties) => {
  const {
    type = "popover",
    error,
    fullWidth,
    helperText,
    mode,
    setMode,
    onChange,
    fromTitle,
    toTitle,
    value,
    changeableMode,
    calendarData,
    size,
    position,
    gapHorizontal,
    gapVertical,
    initialShowDates,
    disableLimit,
    view = "default"
  } = properties;

  const { direction } = useTranslation();

  const { onOpenPopover, onClosePopover, popoverProperties } = usePopover();
  const { scrollReference, setScrollReference, scrolled, scrollToTop } =
    useScroll();

  const {
    calendarLocale,
    changeCalendarLocaleToGregorian,
    changeCalendarLocaleToPersian
  } = useCalendarLocale(properties);
  const { isDesktop } = useViewPort();
  const {
    onNextMonthClick,
    onPreviousMonthClick,
    onShowTodayClick,
    showDates,
    increaseShowDatesNumber,
    nextMonthDisabled,
    previousMonthDisabled
  } = useShowDate(calendarLocale, scrollToTop, initialShowDates, type);

  const onReject = useCallback(() => {
    onChange([], calendarLocale);
  }, [calendarLocale, onChange]);

  return (
    <div className={`${styles["datepicker"]} ${styles[direction || "ltr"]}`}>
      {type === "flat" ? (
        <>
          <FlatHeader
            calendarLocale={calendarLocale}
            nextMonthDisabled={nextMonthDisabled}
            previousMonthDisabled={previousMonthDisabled}
            scrolled={scrolled}
            showDates={
              isDesktop && view !== "mobile" ? showDates : [showDates[0]]
            }
            showNextMonth={onNextMonthClick}
            showPreviousMonth={onPreviousMonthClick}
            type={type}
            view={view}
          />
          <Main
            calendarData={calendarData}
            calendarLocale={calendarLocale}
            disableLimit={disableLimit}
            increaseShowDatesNumber={increaseShowDatesNumber}
            mode={mode}
            onChange={onChange}
            scrollReference={scrollReference}
            setScrollReference={setScrollReference}
            showDates={
              isDesktop && view !== "mobile" ? showDates : [showDates[0]]
            }
            type={type}
            value={value}
            view={view}
          />
        </>
      ) : (
        <>
          <Input
            calendarLocale={calendarLocale}
            changeableMode={changeableMode}
            data-testid="date_picker_input"
            error={error}
            fromTitle={fromTitle}
            fullWidth={fullWidth}
            helperText={helperText}
            mode={mode}
            onOpenPopover={onOpenPopover}
            setMode={setMode}
            size={size}
            toTitle={toTitle}
            value={value}
          />
          <PopoverFull
            {...popoverProperties}
            gapHorizontal={
              isDesktop && view !== "mobile"
                ? gapHorizontal ?? popoverProperties.gapHorizontal
                : "0px"
            }
            gapVertical={
              isDesktop && view !== "mobile"
                ? gapVertical ?? popoverProperties.gapVertical
                : "0px"
            }
            position={position ?? popoverProperties.position}
          >
            <div className={styles["popover"]}>
              <Header
                calendarLocale={calendarLocale}
                changeCalendarLocale={
                  calendarLocale === "persian"
                    ? changeCalendarLocaleToGregorian
                    : changeCalendarLocaleToPersian
                }
                inputProperties={{
                  fromTitle,
                  toTitle,
                  mode,
                  setMode,
                  value,
                  calendarLocale: calendarLocale,
                  changeableMode
                }}
                nextMonthDisabled={nextMonthDisabled}
                onClose={onClosePopover}
                previousMonthDisabled={previousMonthDisabled}
                scrolled={scrolled}
                showDates={showDates}
                showNextMonth={onNextMonthClick}
                showPreviousMonth={onPreviousMonthClick}
                showToday={onShowTodayClick}
                type={type}
                view={view}
              />
              <Main
                calendarData={calendarData}
                calendarLocale={calendarLocale}
                disableLimit={disableLimit}
                increaseShowDatesNumber={increaseShowDatesNumber}
                mode={mode}
                onChange={onChange}
                scrollReference={scrollReference}
                setScrollReference={setScrollReference}
                showDates={showDates}
                type={type}
                value={value}
                view={view}
              />
              <Cta
                accept={onClosePopover}
                mode={mode}
                reject={onReject}
                value={value}
                view={view}
              />
            </div>
          </PopoverFull>
        </>
      )}
    </div>
  );
};

const DatepickerMemoized = memo(Datepicker);
export { DatepickerMemoized as Datepicker };
