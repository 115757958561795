import { pick } from "lodash";
import { SwitchPropertiesType } from "./switch.types";

export const useSwitch = (properties: SwitchPropertiesType) => ({
  inputProps: {
    ...pick(
      properties,
      "autoFocus",
      "disabled",
      "checked",
      "name",
      "onChange",
      "readOnly",
      "value",
      "data-testid"
    ),
    role: "switch",
    type: "checkbox"
  }
});
