import { deepCompare } from "@website/utils";
import { memo, useEffect, useMemo, useState } from "react";
import { TInfiniteList } from "./infinite-list.types";

const InfiniteList = <T,>({
  data = [],
  current,
  total,
  seeMoreButton,
  isFetching,
  renderItem,
  className = ""
}: TInfiniteList<T>) => {
  const [pages, setPages] = useState<Record<number, Array<T>>>({});

  useEffect(() => {
    if (!isFetching) {
      setPages((pages) => ({ ...pages, [current]: data }));
    }
  }, [data, current, isFetching]);

  const records = useMemo(() => Object.values(pages).flat(), [pages]);

  return (
    <div className={className}>
      {records.map((item, index) => renderItem(item, index))}
      {total > records.length && seeMoreButton}
    </div>
  );
};

const InfiniteListMemoized = memo(InfiniteList, deepCompare);
export { InfiniteListMemoized as InfiniteList };
