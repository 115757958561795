import { ChipsPropertiesType } from "./chips.types";

export const useChips = (properties: ChipsPropertiesType) => {
  const { disabled, onClick, onEndIconClick } = properties;

  return {
    rootProperties: {
      ...(onClick && { role: "button" }),
      ...(onClick && !disabled && { onClick, tabIndex: 0 }),
      // ...pick(properties, "aria-label", "onClick", "type"),
      disabled
    },
    endIconProperties: {
      ...(onEndIconClick && { role: "button" }),
      ...(onEndIconClick &&
        !disabled && { onClick: onEndIconClick, tabIndex: 0 })
    }
  };
};
