import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { deepCompare, numberConvert } from "@website/utils";
import { useRouter } from "next/router";
import { memo } from "react";
import { ReservePoliciesPropertiesTypes } from "./reserve-policies.types";
import { toStringTime } from "./reserve-policies.utils";

const Component = (properties: ReservePoliciesPropertiesTypes) => {
  const {
    checkinTime,
    checkoutTime,
    hotelType,
    rulesAllowed = [],
    rulesDisAllowed = []
  } = properties;
  const router = useRouter();
  const { t } = useTranslation();

  if (!checkinTime || !checkoutTime) return null;
  return (
    <div className="flex w-full flex-col gap-4" id="hotel_policies">
      <h2 className="text-subtitle-1 md:text-headline-6">
        {t("policies.residence_policies", { residence: hotelType })}
      </h2>
      <div className="flex flex-row gap-4">
        <div className="border-outline-thin flex w-[200px] flex-col gap-2 rounded-[8px] border p-2 md:flex-row">
          <div className="w-[24px] text-center">
            <MaterialIcon
              className="text-on-surface-medium-emphasis scale-150"
              name="login"
            />
          </div>
          <div className="flex flex-col gap-1 md:flex-auto">
            <div className="text-caption text-on-surface-medium-emphasis">
              {t("policies.checkin_date")}
            </div>
            <div className="text-body-1 text-on-surface-high-emphasis">
              {numberConvert(toStringTime(checkinTime), {
                locale: router.locale
              })}
            </div>
          </div>
        </div>
        <div className="border-outline-thin flex w-[200px] flex-col gap-2 rounded-[8px] border p-2 md:flex-row">
          <div className="w-[24px] text-center">
            <MaterialIcon
              className="text-on-surface-medium-emphasis scale-150"
              name="logout"
            />
          </div>
          <div className="flex flex-col gap-1 md:flex-auto">
            <div className="text-caption text-on-surface-medium-emphasis">
              {t("policies.checkout_date")}
            </div>
            <div className="text-body-1 text-on-surface-high-emphasis">
              {numberConvert(toStringTime(checkoutTime), {
                locale: router.locale
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2">
        {rulesAllowed?.length > 0 && (
          <div>
            {rulesAllowed.map((rule) => (
              <div
                className="flex items-center"
                key={`${rule?.title}_${rule?.id}`}
              >
                <MaterialIcon
                  className="text-success text-xl"
                  name="check_circle"
                  variant="outlined"
                />

                <p className="text-on-surface-high-emphasis text-body-2 mr-1">
                  {rule?.title}
                </p>
              </div>
            ))}
          </div>
        )}
        {rulesDisAllowed?.length > 0 && (
          <div>
            {rulesDisAllowed.map((rule) => (
              <div
                className="flex items-center"
                key={`${rule?.title}_${rule?.id}`}
              >
                <MaterialIcon
                  className="text-on-surface-medium-emphasis text-xl"
                  name="cancel"
                  variant="outlined"
                />
                <p className="text-on-surface-high-emphasis text-body-2 mr-1">
                  {rule?.title}
                </p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
export const ReservePolicies = memo(Component, deepCompare);
