import { FC, memo } from "react";
import styles from "./top-app-bar.module.scss";
import { TopAppBarPropertiesType } from "./top-app-bar.types";

const TopAppBar: FC<TopAppBarPropertiesType> = (properties) => {
  const { StartAction, title, EndAction } = properties;

  return (
    <div className={styles["container"]}>
      {StartAction}
      <div className={styles["title"]}>{title}</div>
      {EndAction}
    </div>
  );
};

const TopAppBarMemoized = memo(TopAppBar);
export { TopAppBarMemoized as TopAppBar };
