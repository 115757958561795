import { useCallback } from "react";
import { MenuItemPropertiesType } from "./menu-item.types";

export const useMenuItem = (properties: MenuItemPropertiesType) => {
  const { disabled, value, onClick } = properties;

  const onClickItem = useCallback(() => {
    if (onClick && !disabled) {
      onClick(value);
    }
  }, [value, disabled, onClick]);

  return {
    onClickItem
  };
};
