import { MaterialIcon } from "@website/icons";
import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { useRadio } from "./radio.hook";
import styles from "./radio.module.scss";
import { RadioPropertiesType } from "./radio.types";

const Radio: FC<RadioPropertiesType> = (properties) => {
  const { inputProps } = useRadio(properties);
  const { className, labelClassName, color, disabled, label } = properties;

  return (
    <label
      className={`${styles["radio"]} ${disabled ? styles["disabled"] : ""} ${
        className ?? ""
      }`}
    >
      <input {...inputProps} className={styles["input"]} />
      <div className={styles["box"]}>
        <MaterialIcon
          className={styles["un-checked-icon"]}
          name="radio_button_unchecked"
          variant="outlined"
        />
        <MaterialIcon
          className={`${styles["checked-icon"]} ${styles[color || "primary"]}`}
          name="radio_button_checked"
          variant="outlined"
        />
        <div className={`${styles["ripple"]} ${styles[color || "primary"]}`} />
      </div>
      <span className={`${styles["label"]} ${labelClassName}`} hidden={!label}>
        {label}
      </span>
    </label>
  );
};

const RadioMemoized = memo(Radio, deepCompare);
export { RadioMemoized as Radio };
