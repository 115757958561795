import {
  AnyCalendarDate,
  CalendarDate,
  GregorianCalendar,
  IslamicTabularCalendar,
  PersianCalendar,
  getLocalTimeZone,
  parseDate,
  toCalendar,
  today
} from "@internationalized/date";
import { DayType, MonthType, gregorianToShamsi } from "@website/utils";
import {
  DatepickerCalendarLocaleType,
  DatepickerDateType
} from "./datepicker.types";

export const stringOfNumbers = [
  "zero",
  "one",
  "two",
  "three",
  "four",
  "five",
  "six",
  "seven",
  "eight",
  "nine",
  "ten",
  "eleven",
  "twelve"
];

export const convertDate = (date: DatepickerDateType, toLocale: string) => {
  const newDate = parseDate(date.dateTime);
  let newDateConverted: AnyCalendarDate;

  switch (toLocale) {
    case "persian": {
      // @internationalize/date has issue with
      // convert from gregorian to persian.
      // this code fix that issue.
      // update it later.
      const temporaryDateConverted = gregorianToShamsi(
        newDate.year,
        newDate.month as MonthType,
        newDate.day as DayType
      );
      newDateConverted = new CalendarDate(
        new PersianCalendar(),
        temporaryDateConverted[0],
        temporaryDateConverted[1],
        temporaryDateConverted[2]
      );
      break;
    }
    case "hijri": {
      newDateConverted = toCalendar(newDate, new IslamicTabularCalendar());
      break;
    }
    default: {
      newDateConverted = toCalendar(newDate, new GregorianCalendar());
    }
  }

  return {
    dateTime: date.dateTime,
    year: newDateConverted.year,
    month: newDateConverted.month,
    day: newDateConverted.day
  };
};

export const getToday = (calendarLocale: DatepickerCalendarLocaleType) => {
  const localDate = today(getLocalTimeZone());

  const date =
    calendarLocale === "persian"
      ? // @internationalize/date has issue with
        // convert from gregorian to persian.
        // this code fix that issue.
        // update it later.
        new CalendarDate(
          new PersianCalendar(),
          ...gregorianToShamsi(
            localDate.year,
            localDate.month as MonthType,
            localDate.day as DayType
          )
        )
      : calendarLocale === "hijri"
        ? toCalendar(localDate, new IslamicTabularCalendar())
        : localDate;

  return {
    year: date.year,
    month: date.month,
    day: date.day
  };
};

export const addMonth = (year: number, month: number, number_: number) => {
  const extraYears = Math.floor((month - 1 + number_) / 12);
  const newYear = year + extraYears;
  const newMonth = ((month - 1 + number_) % 12) + 1;

  return { year: newYear, month: newMonth };
};

export const subtractMonth = (year: number, month: number, number_: number) => {
  const extraYears = Math.floor((month - 1 - number_) / 12);
  const newYear = year + extraYears;
  let newMonth = ((month - 1 - number_) % 12) + 1;
  if (newMonth <= 0) {
    newMonth += 12;
  }

  return { year: newYear, month: newMonth };
};

export const createShowDatesArray = (
  calendarLocale: DatepickerCalendarLocaleType,
  number_: number
) => {
  const showDates: Array<{ year: number; month: number }> = [];
  const today = getToday(calendarLocale);
  for (let index = 0; index < number_; index++) {
    const newDate = addMonth(today.year, today.month, index);
    showDates.push({
      year: newDate.year,
      month: newDate.month
    });
  }
  return showDates;
};
