import {
  keepPreviousData,
  QueryCache,
  QueryClient,
  QueryClientProvider
} from "@tanstack/react-query";
import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { FetcherProviderPropertiesType } from "./provider.types";

const queryCache = new QueryCache();

const queryClient = new QueryClient({
  queryCache,
  defaultOptions: {
    queries: {
      retry: Number.POSITIVE_INFINITY,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      placeholderData: keepPreviousData
    }
  }
});

const Component: FC<FetcherProviderPropertiesType> = ({ children }) => (
  <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
);

export const FetcherProvider = memo(Component, deepCompare);
