export const holidaysList = [
  "2024-01-25",
  "2024-02-08",
  "2024-02-11",
  "2024-02-25",
  "2024-03-19",
  "2024-03-20",
  "2024-03-21",
  "2024-03-22",
  "2024-03-23",
  "2024-03-31",
  "2024-04-01",
  "2024-04-01",
  "2024-04-10",
  "2024-04-11",
  "2024-05-04",
  "2024-06-03",
  "2024-06-04",
  "2024-06-17",
  "2024-06-25",
  "2024-07-15",
  "2024-07-16",
  "2024-08-25",
  "2024-09-02",
  "2024-09-04",
  "2024-09-12",
  "2024-09-21",
  "2024-12-05",
  "2025-01-14",
  "2025-01-28",
  "2025-02-10",
  "2025-02-14",
  "2025-03-19",
  "2025-03-20",
  "2025-03-21",
  "2025-03-22",
  "2025-03-22",
  "2025-03-23",
  "2025-03-24",
  "2025-03-31",
  "2025-04-01",
  "2025-04-01",
  "2025-04-02",
  "2025-04-24",
  "2025-06-04",
  "2025-06-05",
  "2025-06-07",
  "2025-06-15",
  "2025-07-05",
  "2025-07-06",
  "2025-08-15",
  "2025-08-23",
  "2025-08-24",
  "2025-09-01",
  "2025-09-10",
  "2025-11-25",
  "2026-01-03",
  "2026-01-17",
  "2026-02-04",
  "2026-02-11",
  "2026-03-11",
  "2026-03-20"
];
