import { observable } from "@legendapp/state";
import { persistObservable } from "@legendapp/state/persist";
import { ObservablePersistLocalStorage } from "@legendapp/state/persist-plugins/local-storage";

export const userTrackingStore = observable({
  sid: "",
  aid: "",
  sessionExpire: 0,
  queue: [] as Array<object>,
  experiments: [] as Array<string>,
  previousUrl: "",
  hotelPriceEngineRuleId: 0
});

persistObservable(userTrackingStore, {
  local: "user_tracking_store",
  pluginLocal: ObservablePersistLocalStorage
});
