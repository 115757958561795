import { parseDateTime } from "@internationalized/date";
import { useTranslation } from "@website/locale";
import { DatepickerSelectedDateType } from "@website/ui-old";
import { useRouter } from "next/router";
import { useCallback } from "react";
import { makeShowText } from "../utils";

export const useSubmit = (
  originItem: any,
  destinationItem: any,
  date: DatepickerSelectedDateType,
  calendarLocale: string,
  passengerNumber: number,
  addToHistory: (
    origin: any,
    destination: any,
    startDate: any,
    calendarLocale: string,
    passengerNumber: any
  ) => void,
  submitValidationCheck: () => boolean
) => {
  const { t } = useTranslation();
  const channel = process.env.NEXT_PUBLIC_CHANNEL_TYPE;
  const router = useRouter();

  const onSubmitClick = useCallback(() => {
    if (submitValidationCheck()) {
      let startDateTime = "";
      let startDateObject = null;
      let startDate = null;
      if (date && date[0]) {
        startDateTime = date[0].dateTime;
        startDateObject = parseDateTime(startDateTime);

        startDate = `${startDateObject.year}-${startDateObject.month
          .toString()
          .padStart(2, "0")}-${startDateObject.day
          .toString()
          .padStart(2, "0")}`;

        if (originItem && destinationItem && startDate) {
          addToHistory(
            originItem,
            destinationItem,
            startDateTime,
            calendarLocale,
            passengerNumber
          );

          if (channel === "jek") {
            const parameters = {
              ...router?.query,
              origin: originItem.code,
              originCity: makeShowText(originItem),
              dest: destinationItem.code,
              destCity: makeShowText(destinationItem),
              date: startDate || "",
              guests: `${passengerNumber}`,
              isAbroadOrigin: originItem.isAbroad,
              isAbroadDestination: destinationItem.isAbroad,
              abroad: "true",
              source: "searchBox"
            };
            window?.webengage?.track("Ibus-search-clicked", {
              ...parameters,
              channel,
              source: "searchBox"
            });
            window?.dataLayer?.push({
              event: "IB-search",
              ecommerce: {
                items: [
                  {
                    ...router?.query,
                    originCity: makeShowText(originItem),
                    destCity: makeShowText(destinationItem),
                    date: startDate || "",
                    guests: `${passengerNumber}`,
                    isAbroadOrigin: originItem.isAbroad,
                    isAbroadDestination: destinationItem.isAbroad,
                    abroad: "true",
                    source: "searchBox"
                  }
                ]
              }
            });

            window.location.href = `${
              process.env.NEXT_PUBLIC_BASE_URL_B2C
            }/bus/search?${new URLSearchParams(parameters).toString()}`;
          } else {
            const parameters = {
              date: startDate || "",
              guests: `${passengerNumber}`,
              source: "searchBox"
            };
            window?.webengage?.track("Ibus-search-clicked", {
              ...parameters,
              channel,
              source: "searchBox"
            });

            const urlParameters = new URLSearchParams(parameters);
            const originCityEn = originItem?.cityEn?.toLowerCase?.();
            const destinationCityEn = destinationItem?.cityEn?.toLowerCase?.();

            if (originCityEn && destinationCityEn) {
              window?.dataLayer?.push({
                event: "IB-search",
                ecommerce: {
                  items: [
                    {
                      origin: originCityEn,
                      dest: destinationCityEn,
                      date: startDate || "",
                      guests: `${passengerNumber}`,
                      source: "searchBox"
                    }
                  ]
                }
              });
              window.location.href = `${
                process.env.NEXT_PUBLIC_BASE_URL_B2C
              }/bus/${originItem.code}-${originCityEn}/${
                destinationItem.code
              }-${destinationCityEn}?${urlParameters.toString()}`;
            }
          }
        }
      }
    }
  }, [
    submitValidationCheck,
    date,
    originItem,
    destinationItem,
    addToHistory,
    calendarLocale,
    passengerNumber,
    channel,
    router?.query
  ]);

  return {
    onSubmitClick,
    // submitLoading,
    submitText: t("action.search")
  };
};
