import { numberConvert, numberSeparator } from "@website/utils";
import { pick } from "lodash";
import {
  ChangeEvent,
  ChangeEventHandler,
  FocusEvent,
  MouseEvent,
  useCallback,
  useRef
} from "react";
import { TextFieldPropertiesType } from "./text-field.types";

export const useTextField = (properties: TextFieldPropertiesType) => {
  const {
    onChange,
    onClick,
    type,
    inputMode,
    hasSeparator,
    onFocus,
    onBlur,
    onStartIconClick,
    onEndIconClick
  } = properties;
  const numberOnly = ["number", "tel"].includes(type ?? "text");

  const inputReference = useRef<HTMLInputElement & HTMLTextAreaElement>(null);

  const onRootClick = useCallback(() => {
    inputReference.current?.focus();
    onClick?.();
  }, [onClick]);

  const onInputFocus = useCallback(
    (event: FocusEvent<HTMLInputElement> & FocusEvent<HTMLTextAreaElement>) => {
      if (onFocus) {
        onFocus(event);
      }
      // setHasFocus(true);
    },
    [onFocus]
  );

  const onInputBlur = useCallback(
    (event: FocusEvent<HTMLInputElement> & FocusEvent<HTMLTextAreaElement>) => {
      if (onBlur) {
        onBlur(event);
      }
      // setHasFocus(false);
    },
    [onBlur]
  );

  const onStartIconClickHandler = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      if (onStartIconClick) {
        event.stopPropagation();
        onStartIconClick(event);
      }
    },
    [onStartIconClick]
  );

  const onEndIconClickHandler = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      if (onEndIconClick) {
        event.stopPropagation();
        onEndIconClick(event);
      }
    },
    [onEndIconClick]
  );

  const handleOnChange: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = useCallback(
    (event) => {
      event.target.value = numberConvert(
        hasSeparator ? numberSeparator(event.target.value) : event.target.value,
        { locale: "en", numberOnly }
      );
      onChange?.(
        event as ChangeEvent<HTMLInputElement> &
          ChangeEvent<HTMLTextAreaElement>
      );
    },
    [hasSeparator, numberOnly, onChange]
  );

  return {
    rootProperties: {
      onClick: onRootClick
      // ...pick(props, "aria-label", "onClick", "type"),
      // disabled: props.disabled || props.loading,
    },
    inputProperties: {
      ...pick(properties, [
        "disabled",
        "placeholder",
        "value",
        "defaultValue",
        "min",
        "max",
        "type",
        "readOnly",
        "data-testid",
        "name"
      ]),
      ref: inputReference,
      onChange: handleOnChange,
      inputMode: numberOnly ? ("numeric" as const) : inputMode,
      onFocus: onInputFocus,
      onBlur: onInputBlur
    },
    startIconProperties: {
      onClick: onStartIconClickHandler
    },
    endIconProperties: {
      onClick: onEndIconClickHandler
    }
  };
};
