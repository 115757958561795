import { FALLBACK_IMAGE_MD, LazyImage } from "@website/ui";
import { deepCompare, getImage } from "@website/utils";
import { useRouter } from "next/router";
import { memo, useCallback } from "react";
import { ReserveGalleryDetailPropertiesTypes } from "./reserve-gallery-detail.types";

const ImageElementComponent = ({
  img,
  className
}: {
  img: {
    id: number | string;
    media: string;
    title: string;
  };
  className: string;
}) => {
  const router = useRouter();
  const setIsModalOpen = useCallback(
    (status: boolean, item?: string | number) =>
      status
        ? router.push(
            {
              pathname: router.pathname,
              query: { ...router.query, modal: "gallery-details", item }
            },
            undefined,
            { shallow: true, scroll: false }
          )
        : router.back(),
    [router]
  );
  return (
    <div
      className={className}
      id={`gallery_image_${img.id}`}
      onClick={setIsModalOpen.bind(null, true, img?.id)}
    >
      <LazyImage
        alt={img?.title}
        className="bg-outline-thin size-full object-cover"
        fallback={FALLBACK_IMAGE_MD.src}
        loading="lazy"
        src={img?.media}
        title={img?.title}
      />
    </div>
  );
};

const ImageElement = memo(ImageElementComponent, deepCompare);

const Component = (properties: ReserveGalleryDetailPropertiesTypes) => {
  const { gallery, rowCount, index } = properties;

  if (rowCount === 1) {
    return gallery.slice(index).map((img) => (
      <ImageElement
        className="bg-outline-thin col-span-2 row-span-2 min-h-[364px]"
        img={{
          ...img,
          media: getImage({
            src: img?.media
          })
        }}
        key={img.id}
      />
    ));
  }

  if (rowCount === 2) {
    return gallery.slice(index).map((img) => (
      <ImageElement
        className="bg-outline-thin col-span-1 row-span-2 min-h-[364px]"
        img={{
          ...img,
          media: getImage({
            src: img?.media
          })
        }}
        key={img.id}
      />
    ));
  }

  if (rowCount === 3) {
    return (
      <>
        <ImageElement
          className="bg-outline-thin col-span-1 row-span-2 min-h-[364px]"
          img={{
            ...gallery?.[index],
            media: getImage({
              src: gallery?.[index]?.media
            })
          }}
        />
        {gallery.slice(index + 1).map((img) => (
          <ImageElement
            className="bg-outline-thin col-span-1 row-span-1 min-h-[206px]"
            img={{
              ...img,
              media: getImage({
                src: img?.media
              })
            }}
            key={img.id}
          />
        ))}
      </>
    );
  }

  return (
    <>
      <ImageElement
        className="bg-outline-thin col-span-1 row-span-2 min-h-[364px]"
        img={{
          ...gallery?.[index],
          media: getImage({
            src: gallery?.[index]?.media
          })
        }}
      />
      <ImageElement
        className="bg-outline-thin col-span-1 row-span-1 min-h-[206px]"
        img={{
          ...gallery?.[index + 1],
          media: getImage({
            src: gallery?.[index + 1]?.media
          })
        }}
      />
      <ImageElement
        className="bg-outline-thin col-span-1 row-span-1 min-h-[206px]"
        img={{
          ...gallery?.[index + 2],
          media: getImage({
            src: gallery?.[index + 2]?.media
          })
        }}
      />
      <ImageElement
        className="bg-outline-thin col-span-2 row-span-2 min-h-[364px]"
        img={{
          ...gallery?.[index + 3],
          media: getImage({
            src: gallery?.[index + 3]?.media
          })
        }}
      />
    </>
  );
};

export const ReserveGalleryDetail = memo(Component, deepCompare);
