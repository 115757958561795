import { useTranslation } from "@website/locale";
import { FC, memo, useCallback, useState } from "react";
import { PopoverFull } from "..";
import { Cta, Header, Input, Main } from "./components";
import styles from "./datepicker.module.scss";
import { DatepickerPropertiesType } from "./datepicker.types";
import { holidaysList } from "./holidays";
import { useCalendarLocale, usePopover, useScroll, useShowDate } from "./hooks";

const Datepicker: FC<DatepickerPropertiesType> = (properties) => {
  const [isStartFocused, setIsStartFocused] = useState(false);
  const [isEndFocused, setIsEndFocused] = useState(false);
  const {
    disabled,
    disabledDateMode,
    error,
    fullWidth,
    helperText,
    mode,
    setMode,
    onChange,
    fromTitle,
    toTitle,
    value,
    changeableMode,
    calendarData,
    secondaryMode,
    holidays,
    queryKey,
    prices,
    onFocus
  } = properties;

  const { direction } = useTranslation();

  const { onOpenPopover, onClosePopover, popoverProperties } = usePopover(
    queryKey,
    onFocus
  );
  const { scrollReference, setScrollReference, scrolled, scrollToTop } =
    useScroll();

  const {
    calendarLocale,
    changeCalendarLocaleToGregorian,
    changeCalendarLocaleToPersian
  } = useCalendarLocale(properties);

  const {
    onNextMonthClick,
    onPreviousMonthClick,
    onShowTodayClick,
    showDates,
    increaseShowDatesNumber,
    nextMonthDisabled,
    previousMonthDisabled
  } = useShowDate(calendarLocale, scrollToTop);

  const onAccept = useCallback(() => {
    onClosePopover();
  }, [onClosePopover]);

  const onReject = useCallback(() => {
    onChange([], calendarLocale);
  }, [calendarLocale, onChange]);

  const onFocusStart = useCallback(() => {
    setIsEndFocused(false);
    setIsStartFocused(true);
  }, []);

  const onFocusEnd = useCallback(() => {
    setIsStartFocused(false);
    setIsEndFocused(true);
  }, []);

  const onBlurInputs = useCallback(() => {
    setIsEndFocused(false);
    setIsStartFocused(false);
  }, []);

  return (
    <div className={`${styles["datepicker"]} ${styles[direction || "ltr"]}`}>
      <Input
        calendarLocale={calendarLocale}
        changeableMode={changeableMode}
        disabled={disabled}
        error={error}
        fromTitle={fromTitle}
        fullWidth={fullWidth}
        helperText={helperText}
        mode={mode}
        onFocusEnd={onFocusEnd}
        onFocusStart={onFocusStart}
        onOpenPopover={onOpenPopover}
        secondaryMode={secondaryMode}
        setMode={setMode}
        toTitle={toTitle}
        value={value}
      />
      <PopoverFull {...{ ...popoverProperties, onBlurInputs }}>
        <div className={styles["popover"]}>
          <Header
            calendarLocale={calendarLocale}
            changeCalendarLocale={
              calendarLocale === "persian"
                ? changeCalendarLocaleToGregorian
                : changeCalendarLocaleToPersian
            }
            inputProperties={{
              fromTitle,
              toTitle,
              mode,
              setMode,
              value,
              calendarLocale: calendarLocale,
              changeableMode,
              secondaryMode,
              onFocusStart,
              onFocusEnd
            }}
            nextMonthDisabled={nextMonthDisabled}
            onClose={onClosePopover}
            previousMonthDisabled={previousMonthDisabled}
            scrolled={scrolled}
            showDates={showDates}
            showNextMonth={onNextMonthClick}
            showPreviousMonth={onPreviousMonthClick}
            showToday={onShowTodayClick}
          />
          <Main
            calendarData={calendarData}
            calendarLocale={calendarLocale}
            disabledDateMode={disabledDateMode}
            holidays={holidays || holidaysList}
            increaseShowDatesNumber={increaseShowDatesNumber}
            isEndFocused={isEndFocused}
            isStartFocused={isStartFocused}
            mode={mode}
            onBlurInputs={onBlurInputs}
            onChange={onChange}
            onFocusEnd={onFocusEnd}
            prices={prices}
            scrollReference={scrollReference}
            setScrollReference={setScrollReference}
            showDates={showDates}
            value={value}
          />
          <div className="flex items-center justify-between">
            <div className="text-success flex items-center gap-1 px-6 py-4 text-xs">
              {prices && Array.isArray(prices) && prices.length > 0 && (
                <>
                  <div className="bg-transparent-success size-3 rounded-full" />
                  ارزران‌ترین قیمت
                </>
              )}
            </div>
            <Cta
              accept={onAccept}
              mode={mode}
              reject={onReject}
              value={value}
            />
          </div>{" "}
        </div>
      </PopoverFull>
    </div>
  );
};

const DatepickerMemoized = memo(Datepicker);
export { DatepickerMemoized as Datepicker };
