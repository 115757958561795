import { memo, SVGProps } from "react";

const SvgFlightTakeoffFa = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <g clipPath="url(#flight-takeoff-fa_svg__a)">
      <path
        className="fill-on-surface-medium-emphasis"
        d="M21.46 19h-19v2h19v-2ZM1.89 9.64c.21-.8 1.04-1.28 1.84-1.06L9.04 10l6.9-6.43 1.93.51-4.14 7.17 4.97 1.33 1.97-1.54 1.45.39-2.59 4.49-16.57-4.43a1.522 1.522 0 0 1-1.07-1.85Z"
      />
    </g>
    <defs>
      <clipPath id="flight-takeoff-fa_svg__a">
        <path className="fill-on-surface-medium-emphasis" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(SvgFlightTakeoffFa);
export default Memo;
