import { useCallback, useEffect, useState } from "react";

export const useBannerPopup = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setVisible(true);
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const onClose = useCallback(() => {
    setVisible(false);
  }, []);

  return {
    visible,
    onClose
  };
};
