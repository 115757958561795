import { deepCompare } from "@website/utils";
import { FC, Fragment, memo } from "react";
import { MenuItem } from "./menu-item";
// import { useMenu } from "./menu.hook";
import styles from "./menu.module.scss";
import { MenuPropertiesType } from "./menu.types";

const Menu: FC<MenuPropertiesType> = (properties) => {
  const { className, list, divider, value, onChange, size, name } = properties;

  return (
    <div className={`${styles["menu"]} ${className || ""}`} data-testid="muenu">
      {list
        ? list.map((item, index) => (
            <Fragment key={item.value}>
              {index === 0 ? null : (
                <div
                  className={`${styles["divider"]} ${
                    divider ? styles[divider] : ""
                  }`}
                />
              )}
              <MenuItem
                EndIcon={item.EndIcon}
                StartIcon={item.StartIcon}
                description={item.description}
                disabled={item.disabled}
                key={item.value}
                name={name}
                onClick={onChange}
                selected={item.value === value}
                size={size}
                title={item.title}
                value={item.value}
              />
            </Fragment>
          ))
        : null}
    </div>
  );
};

const MenuMemoized = memo(Menu, deepCompare);
export { MenuMemoized as Menu };
