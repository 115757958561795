import { useAddRemoveQuery } from "@website/hooks";
import { ArrowDropDown } from "@website/icons";
import { useTranslation } from "@website/locale";
import { CounterButton } from "@website/ui-old";
import { pick } from "lodash";
import { useRouter } from "next/router";
import { useCallback } from "react";
import { PassengerCountPropertiesType } from "./passenger-count.types";

export const usePassengerCount = (properties: PassengerCountPropertiesType) => {
  const {
    onSubmit,
    list,
    title,
    result,
    onClosePassengerButton,
    onClickBackDropHandler
  } = properties;
  const { t, locale } = useTranslation();
  const router = useRouter();
  const key = "SEARCH_PASSENGER_COUNT";
  const { [key]: isKey } = router.query;
  const { addQuery, removeQuery } = useAddRemoveQuery(key);

  const onClickChips = useCallback(() => {
    addQuery("open");
  }, [addQuery]);

  const onClosePopover = useCallback(() => {
    removeQuery();
  }, [removeQuery]);

  const onClickSubmit = useCallback(() => {
    onClosePopover();
    onSubmit();
  }, [onClosePopover, onSubmit]);

  const onCloseButton = useCallback(() => {
    onClosePopover();
    onClosePassengerButton && onClosePassengerButton();
  }, [onClosePassengerButton, onClosePopover]);

  const onClickBackDrop = useCallback(() => {
    onClosePopover();
    onClickBackDropHandler && onClickBackDropHandler();
  }, [onClickBackDropHandler, onClosePopover]);

  return {
    submitButtonProps: {
      onClick: onClickSubmit,
      color: "secondary" as const,
      size: "large" as const,
      fullWidth: true
    },
    chipsProps: {
      variant: "outline" as const,
      EndIcon: <ArrowDropDown />,
      text: result,
      selected: true,
      color: "secondary" as const,
      onClick: onClickChips
    },
    popoverProps: {
      onClose: onClosePopover,
      open: Boolean(isKey),
      title: title,
      onCloseButton,
      onClickBackDrop
    },
    list: list.map((item) => ({
      title: item.title,
      subtitle: item.subtitle,
      role: "default",
      EndAction: (
        <CounterButton
          aria-label={item.title}
          color="secondary"
          variant="outline"
          {...pick(item, "decreaseDisabled", "increaseDisabled", "onChange")}
          locale={locale}
          value={item.value}
        />
      )
    })),
    submitText: t("action.ok")
  };
};
