export const flight_international_visa = {
  landing: {
    page_title: "دریافت ویزای توریستی",
    select_header: "انتخاب کشور مقصد",
    submit: "جستجو",
    select_country: "انتخاب کشور مقصد",
    carousel: {
      header: "ویزای توریستی",
      detail: "برای دیدن جزییات قوانین لطفا کشور مقصد خود را انتخاب کنید.",
      price: "شروع قیمت از"
    }
  },
  succuss: {
    back: "بازگشت به صفحه اصلی",
    title: "درخواست شما با موفقیت ثبت شد.",
    description: "کارشناسان پشتیبانی به زودی با شما تماس خواهند گرفت."
  },
  country: {
    otp_modal: {
      title: "وارد کردن کد تایید",
      detail: "کد تایید را به شماره {phoneNumber} فرستادیم.",
      wrong_number: "شماره موبایل اشتباه است؟",
      edit: "ویرایش",
      resend: "ارسال دوباره کد تایید تا {time}",
      submit: "تایید و ثبت درخواست",
      resend_buttom: "ارسال دوباره",
      no_code: "کد تایید را دریافت نکردم",
      wrong_code: "کد وارد شده اشتباه است."
    },
    boxes: {
      validityDuration: "مدت زمان اعتبار پس از صدور",
      normalVisaDuration: "زمان پردازش ویزای عادی",
      urgentVisaDuration: "زمان پردازش ویزای فوری"
    },
    faq: {
      title: "سوالات متداول"
    },
    peoplePopover: {
      adult: "بزرگسال",
      adultSubtitle: "۱۸ سال به بالا",
      adultTitle: "تعداد بزرگسال",
      child: "کودک",
      childTitle: "کمتر از ۱۸ سال",
      childSubtitle: "تعداد کودک",
      peopleCount: "تعداد نفرات",
      submit: "تایید"
    },
    timeline: {
      assignment: {
        title: "ثبت درخواست",
        detail: "ثبت اطلاعات اولیه و درخواست تماس"
      },
      call: {
        title: "تماس کارشناس با شما",
        detail:
          "دقایقی پس از ثبت درخواست کارشناس پشتیبانی ویزا با شما تماس خواهد گرفت."
      },
      upload: {
        title: "ارسال مدارک به ایمیل",
        detail: "مدارک و اسناد خود را بارگذاری و ارسال کنید."
      },
      payment: {
        title: "پرداخت هزینه",
        detail: "هزینه ویزا به صورت ریالی و آنلاین دریافت می‌شود."
      },
      visa_process: {
        title: "پردازش ویزا",
        detail: "مدارک شما برای سفارت ارسال خواهد شد."
      },
      issue: {
        title: "صدور ویزا",
        detail: "ویزا صادر و برای شما ایمیل میگردد."
      }
    },
    visaTypePopover: {
      title: "نوع ویزا"
    }
  }
};
