import { dateFormat, isFilled } from "@website/utils";
import { toNumber } from "lodash";
import {
  ConvertDateType,
  DatepickerCalendarLocaleType,
  InitialShowDatesType
} from "./datepicker.types";

export const stringOfNumbers = [
  "zero",
  "one",
  "two",
  "three",
  "four",
  "five",
  "six",
  "seven",
  "eight",
  "nine",
  "ten",
  "eleven",
  "twelve"
];

export const convertDate = (date: ConvertDateType, toLocale: string) => {
  const calendars: Record<string, string> = {
    persian: "fa",
    hijri: "ar",
    gregorian: "en"
  };

  return {
    dateTime: date.dateTime,
    year: toNumber(
      dateFormat(date.dateTime, {
        locale: calendars[toLocale],
        template: "yyyy",
        numberConvert: false
      })
    ),
    month: toNumber(
      dateFormat(date.dateTime, {
        locale: calendars[toLocale],
        template: "MM",
        numberConvert: false
      })
    ),
    day: toNumber(
      dateFormat(date.dateTime, {
        locale: calendars[toLocale],
        template: "dd",
        numberConvert: false
      })
    )
  };
};

export const getToday = (calendarLocale: DatepickerCalendarLocaleType) => {
  const today = new Date();
  const calendars: Record<string, string> = {
    persian: "fa",
    hijri: "ar",
    gregorian: "en"
  };
  return {
    year: toNumber(
      dateFormat(today, {
        locale: calendars[calendarLocale],
        template: "yyyy",
        numberConvert: false
      })
    ),
    month: toNumber(
      dateFormat(today, {
        locale: calendars[calendarLocale],
        template: "MM",
        numberConvert: false
      })
    ),
    day: toNumber(
      dateFormat(today, {
        locale: calendars[calendarLocale],
        template: "dd",
        numberConvert: false
      })
    )
  };
};

export const addMonth = (year: number, month: number, number_: number) => {
  const extraYears = Math.floor((month - 1 + number_) / 12);
  const newYear = year + extraYears;
  const newMonth = ((month - 1 + number_) % 12) + 1;

  return { year: newYear, month: newMonth };
};

export const subtractMonth = (year: number, month: number, number_: number) => {
  const extraYears = Math.floor((month - 1 - number_) / 12);
  const newYear = year + extraYears;
  let newMonth = ((month - 1 - number_) % 12) + 1;
  if (newMonth <= 0) {
    newMonth += 12;
  }

  return { year: newYear, month: newMonth };
};

export const createShowDatesArray = (
  calendarLocale: DatepickerCalendarLocaleType,
  number_: number
) => {
  const showDates: Array<{ year: number; month: number }> = [];
  const today = getToday(calendarLocale);
  for (let index = 0; index < number_; index++) {
    const newDate = addMonth(today.year, today.month, index);
    showDates.push({
      year: newDate.year,
      month: newDate.month
    });
  }
  return showDates;
};

export const getShowDatesInitial = (
  date: string,
  INITIAL_SHOW_DATES_NUMBER: number,
  calendarLocale: DatepickerCalendarLocaleType
): InitialShowDatesType => {
  if (!isFilled(date))
    return createShowDatesArray(calendarLocale, INITIAL_SHOW_DATES_NUMBER);
  const dateFrom = convertDate({ dateTime: date }, calendarLocale);

  const finalInitialShows = [{ year: dateFrom.year, month: dateFrom.month }];

  for (let index = 0; index < INITIAL_SHOW_DATES_NUMBER - 1; index++) {
    const newDate = addMonth(
      finalInitialShows[finalInitialShows.length - 1 || 0].year,
      finalInitialShows[finalInitialShows.length - 1 || 0].month,
      1
    );

    finalInitialShows.push({
      year: newDate.year,
      month: newDate.month
    });
  }

  return finalInitialShows;
};
