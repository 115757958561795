import { useQuery } from "react-query";
import {
  ListingService,
  ListingUrl
} from "../../../../../services/pwa/listing";

export const useListingService = (query: string) => {
  const {
    isLoading,
    data
    // error
  } = useQuery([ListingUrl, query], () => ListingService({ query }), {
    enabled: query.length > 2
  });

  const dataDomestic =
    data?.endpoints?.length > 0
      ? data?.endpoints?.filter((item) => !item.isAbroad)
      : [];

  return { isLoading, data: dataDomestic };
};
