import {
  filter,
  isArray,
  isEmpty,
  isFunction,
  isPlainObject,
  map,
  mapValues,
  pickBy
} from "lodash";
import { isFilled } from "../is-filled";
import { DeepOmitType } from "./deep-omit.types";

export const deepOmit: DeepOmitType = (value, filterFunction) => {
  filterFunction = isFunction(filterFunction) ? filterFunction : isFilled;
  if (isArray(value)) {
    const newValue = filter(map(value, deepOmit), filterFunction);
    return (isEmpty(newValue) ? undefined : newValue) as typeof value;
  }
  if (isPlainObject(value)) {
    const newValue = pickBy(
      mapValues(value as object, deepOmit),
      filterFunction
    );
    return (isEmpty(newValue) ? undefined : newValue) as typeof value;
  }
  return filterFunction(value) ? value : undefined;
};
