import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { useRange } from "./range.hook";
import styles from "./range.module.scss";
import { RangePropertiesType } from "./range.types";

const Range: FC<RangePropertiesType> = (properties) => {
  const { startText, endText, color } = properties;
  const {
    stepItems,
    minInputProperties,
    maxInputProperties,
    rangeProperties,
    isSingle
  } = useRange(properties);

  return (
    <div>
      {startText || endText ? (
        <div className={styles["text"]}>
          <span data-testid="range-start-text">{startText}</span>
          <span data-testid="range-end-text">{endText}</span>
        </div>
      ) : (
        ""
      )}
      <div
        className={`${styles["range-wrapper"]} ${styles[color || "primary"]}`}
      >
        {!isSingle && (
          <input
            className={`${styles["input"]} ${styles["first"]}`}
            {...minInputProperties}
          />
        )}
        <input
          className={`${styles["input"]} ${styles["second"]}`}
          {...maxInputProperties}
        />
        {stepItems.length > 0 && (
          <div className={styles["steps-wrapper"]}>
            {stepItems.map(({ out }) => (
              <div
                className={`${styles["step"]} ${out ? styles["out"] : ""}`}
              />
            ))}
          </div>
        )}
        <div className={styles["track"]} />
        <div className={styles["range"]} {...rangeProperties} />
      </div>
    </div>
  );
};

const RangeMemoized = memo(Range, deepCompare);
export { RangeMemoized as Range };
