import { isVisible } from "@website/hooks";
import { get, toString } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { FabMenuItemsTypes, FabMenuPropertiesTypes } from "./fab-bar.type";

export const useTopBar = ({ items }: FabMenuPropertiesTypes) => {
  const [scrolledItem, setScrolledItem] = useState("");
  const [scrolledItems, setScrolledItems] = useState({});

  useEffect(() => {
    const list = items?.map(({ selector }) => {
      const element = document.querySelector<HTMLDivElement>(selector);
      const { unobserve } = isVisible(element, {
        onChange: (isIntersecting) =>
          setScrolledItems((state) => ({
            ...state,
            [selector]: isIntersecting
          }))
      });
      return { unobserve };
    });
    return () => void list?.map(({ unobserve }) => unobserve());
  }, [items]);

  useEffect(() => {
    setScrolledItem(
      toString(
        items?.find(({ selector }) => get(scrolledItems, selector) === true)
          ?.title
      )
    );
  }, [items, scrolledItems]);

  const handleClick = useCallback(
    (item: FabMenuItemsTypes) => () =>
      window?.scrollTo({
        top:
          (document.querySelector<HTMLDivElement>(item?.selector)
            ?.offsetTop as number) - 55,
        behavior: "smooth"
      }),
    []
  );

  return {
    scrolledItem,
    handleClick
  };
};
