import { useUserAgent } from "@website/hooks";
import { useRouter } from "next/router";
import { FC, memo, useCallback, useEffect, useState } from "react";
import { TrainHint } from "../train-hint";
import { RouteButton } from "./components/route-button";
import { useSearch } from "./search.hook";
import styles from "./search.module.scss";
import { SearchPropertiesType } from "./search.types";

const Search: FC<SearchPropertiesType> = (properties) => {
  const {
    routes,
    sourceProperties,
    imageProperties,
    panel,
    routeChanged,
    scrolled
  } = useSearch(properties);
  const { isIOS } = useUserAgent();
  const channel = process.env.NEXT_PUBLIC_CHANNEL_TYPE;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const router = useRouter();
  const [foriegnPeople, setForiegnPeople] = useState(true);

  useEffect(() => {
    const selected = routes?.findIndex((item) => item.selected === true);
    if (selected !== selectedIndex) {
      setSelectedIndex(selected);
    }
  }, [routes, selectedIndex]);

  useEffect(() => {
    setForiegnPeople(true);
  }, [router.pathname]);

  const onHandleForiegnPeople = useCallback(() => {
    setForiegnPeople(false);
  }, []);

  return (
    <article>
      {channel === "jek" && router.pathname === "/train" && foriegnPeople && (
        <TrainHint
          onHandleForiegnPeople={onHandleForiegnPeople}
          style="hidden md:flex"
        />
      )}
      <header className={styles["image-container"]}>
        <picture>
          <source
            {...sourceProperties}
            className={`${
              routeChanged ? styles["fade-out"] : styles["fade-in"]
            }`}
          />
          <img
            {...imageProperties}
            className={`${
              routeChanged ? styles["fade-out"] : styles["fade-in"]
            }`}
            fetchPriority="high"
            loading="eager"
          />
        </picture>
      </header>
      <section
        className={`${styles["tabs"]} ${
          channel === "jek" && isIOS ? "mt-4" : ""
        }`}
      >
        <header className={styles["tabs-header"]}>
          <nav
            className={`${styles["fix-layer"]} ${
              scrolled ? styles["scrolled"] : ""
            }`}
          >
            <nav className="flex">
              {routes.map((item) => (
                <RouteButton {...item} key={item.href} />
              ))}
            </nav>
            <div className={styles["float-bottom-container"]}>
              <div
                className={styles["float-bottom"]}
                style={{
                  width: `calc(100%/${routes?.length})`,
                  transform: `translateX(calc(-${selectedIndex}*100%))`
                }}
              />
            </div>
          </nav>
        </header>
        <div className={styles["tabs-panel"]}>{panel}</div>
      </section>
    </article>
  );
};

const SearchMemoized = memo(Search);
export { SearchMemoized as Search };
