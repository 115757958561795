import { MaterialIcon } from "@website/icons";
import { FC, memo } from "react";
import { TextField } from "../../..";
import { useInput } from "./input.hook";
import styles from "./input.module.scss";
import { InputPropertiesType } from "./input.types";

const Input: FC<InputPropertiesType> = (properties) => {
  const {
    mode,
    fullWidth,
    helperText,
    error,
    changeableMode,
    size = "default"
  } = properties;
  const {
    rootProps,
    startTextFieldProperties,
    endEnableButtonProperties,
    endTextFieldProperties,
    endEnableButtonText
  } = useInput(properties);

  return (
    <div
      {...rootProps}
      className={`${styles["input"]} ${fullWidth ? styles["full-width"] : ""} ${
        error ? styles["error"] : ""
      }`}
    >
      <div className={styles["inputs-container"]}>
        <div className={styles["text-field-container"]}>
          <TextField {...startTextFieldProperties} />
        </div>
        {mode === "single" ? (
          changeableMode ? (
            <>
              <div
                className={`${styles["middle"]} ${
                  size === "dense"
                    ? styles["middle__dense"]
                    : styles["middle__default"]
                }`}
              >
                <div className={styles["middle-line"]} />
              </div>
              <button
                className={styles["enable-second-mode-button"]}
                {...endEnableButtonProperties}
              >
                {endEnableButtonText}
                <MaterialIcon name="add" variant="outlined" />
              </button>
            </>
          ) : null
        ) : (
          <>
            <div className={styles["middle"]}>
              <div className={styles["middle-line"]} />
            </div>
            <div className={styles["text-field-container"]}>
              <TextField {...endTextFieldProperties} />
            </div>
          </>
        )}
      </div>
      {helperText ? (
        <div className={styles["helper-text"]}>{helperText}</div>
      ) : null}
    </div>
  );
};

const InputMemoized = memo(Input);
export { InputMemoized as Input };
