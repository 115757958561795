import { useQuery } from "react-query";
import {
  AirportsService,
  AirportsUrl
} from "../../../../../services/pwa/flights/airports";
// } from "../../../../../services/base/flights/airports";
// } from "../../../../../services/pwa/flights/b2b/airports";

export const useAirportsService = (query: string) => {
  const {
    isLoading,
    data
    // error
  } = useQuery([AirportsUrl, query], () => AirportsService({ query }), {
    enabled: query.length > 1
  });

  return { isLoading, data };
};
