import { memo, SVGProps } from "react";

const SvgHotel = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    height="1em"
    viewBox="0 0 32 32"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <path d="M14.667 2.667a2.666 2.666 0 0 1 2.667 2.667v4h8a2.666 2.666 0 0 1 2.667 2.667v13.333l.156.009c.663.076 1.177.64 1.177 1.324V28H2.667v-1.333c0-.736.597-1.333 1.333-1.333v-20a2.666 2.666 0 0 1 2.667-2.667h8zm-1.334 16c-.736 0-1.333.597-1.333 1.333v5.333h2.667V20c0-.736-.597-1.333-1.333-1.333zm5.334 0c-.736 0-1.333.597-1.333 1.333v5.333h2.667V20c0-.736-.597-1.333-1.333-1.333zM8 18.667c-.736 0-1.333.597-1.333 1.333v1.333a1.333 1.333 0 0 0 2.666 0V20c0-.736-.597-1.333-1.333-1.333zm16 0c-.736 0-1.333.597-1.333 1.333v1.333a1.333 1.333 0 0 0 2.666 0V20c0-.736-.597-1.333-1.333-1.333zM8 12c-.736 0-1.333.597-1.333 1.333v1.333a1.333 1.333 0 0 0 2.666 0v-1.333C9.333 12.597 8.736 12 8 12zm5.333 0c-.736 0-1.333.597-1.333 1.333v1.333a1.333 1.333 0 0 0 2.666 0v-1.333c0-.736-.597-1.333-1.333-1.333zm5.334 0c-.736 0-1.333.597-1.333 1.333v1.333a1.333 1.333 0 0 0 2.666 0v-1.333c0-.736-.597-1.333-1.333-1.333zM24 12c-.736 0-1.333.597-1.333 1.333v1.333a1.333 1.333 0 0 0 2.666 0v-1.333c0-.736-.597-1.333-1.333-1.333zM8 5.333c-.736 0-1.333.597-1.333 1.333v1.333a1.333 1.333 0 0 0 2.666 0V6.666c0-.736-.597-1.333-1.333-1.333zm5.333 0c-.736 0-1.333.597-1.333 1.333v1.333a1.333 1.333 0 0 0 2.666 0V6.666c0-.736-.597-1.333-1.333-1.333z" />
  </svg>
);

const Memo = memo(SvgHotel);
export default Memo;
