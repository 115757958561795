import { memo, SVGProps } from "react";

const SvgIndeterminateCheckBox = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <g clipPath="url(#indeterminate_check_box_svg__a)">
      <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2Zm-2 10H7v-2h10v2Z" />
    </g>
    <defs>
      <clipPath id="indeterminate_check_box_svg__a">
        <path d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(SvgIndeterminateCheckBox);
export default Memo;
