import { useCallback } from "react";
import {
  useBusTrackAPI,
  useFlightDomesticTrackAPI,
  useFlightIntlTrackAPI,
  useHotelTrackAPI
} from "./services";

export const useSubmit = ({
  service,
  code,
  phoneNumber,
  submitValidationCheck
}: {
  service: string;
  code: string;
  phoneNumber: string;
  submitValidationCheck: () => boolean;
}) => {
  const { isLoadingHotelTrack, isErrorHotelTrack, mutateHotelTrack } =
    useHotelTrackAPI();
  const {
    isLoadingFlightDomesticTrack,
    isErrorFlightDomesticTrack,
    mutateFlightDomesticTrack
  } = useFlightDomesticTrackAPI();
  const {
    isLoadingFlightIntlTrack,
    isErrorFlightIntlTrack,
    mutateFlightIntlTrack
  } = useFlightIntlTrackAPI();
  const { isLoadingBusTrack, isErrorBusTrack, mutateBusTrack } =
    useBusTrackAPI();

  const onSubmitClick = useCallback(() => {
    if (submitValidationCheck()) {
      switch (service) {
        case "hotel": {
          mutateHotelTrack({ code, phoneNumber });
          break;
        }
        case "flight-domestic": {
          mutateFlightDomesticTrack({ code, phoneNumber });
          break;
        }
        case "flight-intl": {
          mutateFlightIntlTrack({ code, phoneNumber });
          break;
        }
        case "bus": {
          mutateBusTrack({ code, phoneNumber });
          break;
        }
      }
    }
  }, [
    code,
    mutateBusTrack,
    mutateFlightDomesticTrack,
    mutateFlightIntlTrack,
    mutateHotelTrack,
    phoneNumber,
    service,
    submitValidationCheck
  ]);

  return {
    onSubmitClick,
    isLoading:
      isLoadingHotelTrack ||
      isLoadingFlightDomesticTrack ||
      isLoadingFlightIntlTrack ||
      isLoadingBusTrack,
    isError:
      isErrorHotelTrack ||
      isErrorFlightDomesticTrack ||
      isErrorFlightIntlTrack ||
      isErrorBusTrack
  };
};
