import { useEffect, useState } from "react";
import { UseUserAgent } from "./user-agent.types";

export const useUserAgent: UseUserAgent = () => {
  const [userAgent, setUserAgent] = useState("android");
  const [iphoneOSVersion, setIphoneOSVersion] = useState(0);

  useEffect(() => {
    try {
      setUserAgent(navigator?.userAgent);
      const indexOSVersionMatch =
        navigator?.userAgent?.match(/iPhone OS (\d+)/);
      if (/iphone|ipad|ipod/i.test(userAgent) && indexOSVersionMatch) {
        setIphoneOSVersion(Number.parseInt(indexOSVersionMatch[1], 10));
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("error:", error);
    }
  }, [userAgent]);

  return {
    isMobile: /android|webos|iphone|ipod|blackberry|iemobile|opera mini/i.test(
      userAgent
    ),
    isTablet: /ipad|tablet|kindle/i.test(userAgent),
    isDesktop: /windows|macintosh|linux/i.test(userAgent),
    isAndroid: /android/i.test(userAgent),
    isIOS: /iphone|ipad|ipod/i.test(userAgent),
    isWindows: /windows/i.test(userAgent),
    isMac: /macintosh|mac/i.test(userAgent),
    isLinux: /linux/i.test(userAgent),
    iOSVersion: iphoneOSVersion
  };
};
