import { useTranslation } from "@website/locale";
import { FC, memo } from "react";
import { Button } from "../../..";
import { useActions } from "./actions.hook";
import styles from "./actions.module.scss";
import { ActionsPropertiesType } from "./actions.types";

const Actions: FC<ActionsPropertiesType> = (properties) => {
  const { calendarLocale = "gregorian" } = properties;

  const { t } = useTranslation();

  const { showTodayButtonProperties, changeCalendarLocaleButtonProperties } =
    useActions(properties);

  return (
    <div className={styles["container"]}>
      <Button {...changeCalendarLocaleButtonProperties}>
        {t("action.changeTo")}{" "}
        {calendarLocale === "persian"
          ? t("calendar.gregorian.title")
          : t("calendar.persian.title")}
      </Button>
      <Button {...showTodayButtonProperties}>{t("calendar.today")}</Button>
    </div>
  );
};

const ActionsMemoized = memo(Actions);
export { ActionsMemoized as Actions };
