import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { Button, CarouselV2, InfoChips } from "@website/ui-old";
import type { NextPage } from "next";
import styles from "./special-contents.module.scss";
import { SpecialContentsPropertiesType } from "./special-contents.types";

const SpecialContents: NextPage<SpecialContentsPropertiesType> = (
  properties
) => {
  const { title, url, list } = properties;
  const { t } = useTranslation();

  return (
    <article className={styles["container"]}>
      <header className={styles["header"]}>
        <div className={styles["title-wrapper"]}>
          <div className={styles["title-icon"]}>
            <MaterialIcon name="auto_stories" variant="outlined" />
          </div>
          <h2 className={styles["title"]}>{title}</h2>
        </div>
        <Button
          color="secondary"
          href={url}
          role="link"
          target="_blank"
          variant="text"
        >
          {t("homepage.special_contents.show_all")}
        </Button>
      </header>
      <section className={styles["list"]}>
        <CarouselV2
          itemClass={styles["carousel-item"]}
          keyCarusel="SpecialContents"
          list={list.map((item) => (
            <a
              className={styles["item-wrapper"]}
              href={item.url}
              key={`${item.url}`}
              target="_blank"
            >
              <div className={styles["item"]}>
                <img
                  alt={item.alt || ""}
                  className={styles["item-image"]}
                  height={item.height || 144}
                  loading="lazy"
                  src={item.image}
                  width={item.width || 288}
                />
                <div className={styles["item-title"]}>{item.title}</div>
                <div className={styles["item-content"]}>{item.description}</div>
                <div className={styles["item-footer"]}>
                  <div className={styles["item-date"]}>{item.date}</div>
                  <InfoChips color="secondary" size="large" variant="text">
                    {t("homepage.special_contents.show_content")}
                  </InfoChips>
                </div>
              </div>
            </a>
          ))}
          mode="free"
          options={{
            slides: {
              perView: "auto",
              spacing: 16
            }
          }}
        />
      </section>
    </article>
  );
};

export { SpecialContents };
