import { memo, SVGProps } from "react";

const SvgFlightInternational = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    height="1em"
    viewBox="0 0 32 32"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <path d="m22.667 14.667-.347.008c-4.251.181-7.653 3.697-7.653 7.992 0 4.411 3.589 8 8 8l.347-.008c4.251-.181 7.653-3.697 7.653-7.992 0-4.411-3.589-8-8-8zm0 14.4A6.379 6.379 0 0 1 18 27.04v-.707l.016-.177c.041-.223.159-.391.289-.58l.085-.125c.143-.213.276-.459.276-.784 0-.52-.384-.657-.693-.709l-.32-.04c-.171-.023-.323-.056-.419-.152-.091-.091-.181-.255-.277-.429l-.099-.176a2.562 2.562 0 0 0-.589-.731c.128-3.419 2.949-6.163 6.397-6.163.113 0 .227.003.339.008a.33.33 0 0 0 .107.307c.048.043.08.077.103.103l.045.057c-.008.016-.069.12-.409.293l-.191.105c-.511.309-1.035.832-1.12 1.372a.812.812 0 0 0 .225.721.331.331 0 0 0 .236.097c.463 0 .82-.171 1.167-.336l.212-.1c.281-.125.572-.228.953-.228 1.308 0 2.333.293 2.333.667 0 .137-.043.179-.059.195-.123.121-.467.145-.839.147l-.945-.012a1.396 1.396 0 0 1-.437-.077l-.316-.139c-.161-.061-.375-.113-.737-.113-.549 0-1.571.099-2.236.764-.544.544-.487 1.195-.448 1.624l.012.144.005.135c0 .656.671 1 1.333 1 1.019 0 1.9.191 2 .333 0 .373.111.628.2.832l.052.123a.957.957 0 0 1 .081.379c0 .151-.025.183-.092.269l-.063.085c-.099.145-.179.324-.179.645 0 .573.452 1.193.659 1.447l.093.109a.335.335 0 0 0 .331.1c.196-.051 1.917-.524 1.917-1.656 0-.355.111-.451.277-.596l.073-.065c.151-.137.316-.329.316-.673 0-.267.475-.957.913-1.443a.33.33 0 0 0 .085-.253.34.34 0 0 0-.128-.235c-.259-.201-.975-.855-1.215-1.489.136.071.305.187.441.321.111.112.26.165.429.159.205-.012.44-.137.673-.315a6.409 6.409 0 0 1-6.2 7.989zm-8.184-10.868-1.885-3.605-4.691 4.692.384 2.687a.578.578 0 0 1-.171.512l-.504.503a.607.607 0 0 1-.955-.136l-1.74-3.139-3.139-1.74a.608.608 0 0 1-.136-.956l.504-.503a.58.58 0 0 1 .512-.171l2.687.384 4.692-4.692-7.44-3.889a1.266 1.266 0 0 1 .87-2.354l10.407 2.405 4.692-4.692c.708-.708 1.851-.708 2.559 0s.708 1.851 0 2.559l-4.692 4.692.979 4.235a10.396 10.396 0 0 0-2.933 3.209z" />
  </svg>
);

const Memo = memo(SvgFlightInternational);
export default Memo;
