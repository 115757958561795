import { omit } from "lodash";
import { BreadcrumbPropertiesType } from "./breadcrumb.types";

export const useBreadcrumb = (properties: BreadcrumbPropertiesType) => {
  const { list, color } = properties;

  return {
    rootProperties: {},
    itemsProperties: list.map((item, index) => ({
      ...omit(item, "title", "color", "ref"),
      disabled: index === list.length - 1,
      color,
      itemProp: "item"
    }))
  };
};
