import { useIsVisible, useViewPort } from "@website/hooks";
import { useTranslation } from "@website/locale";
import { FC, memo, useCallback, useEffect } from "react";
import { Table } from "../table";
import styles from "./main.module.scss";
import { MainPropertiesType } from "./main.types";

const Main: FC<MainPropertiesType> = (properties) => {
  const {
    calendarLocale,
    disabledDateMode,
    value,
    onChange,
    showDates,
    increaseShowDatesNumber,
    scrollReference,
    setScrollReference,
    calendarData,
    mode,
    isEndFocused,
    isStartFocused,
    onFocusEnd,
    onBlurInputs,
    holidays,
    prices
  } = properties;
  const { direction } = useTranslation();
  const { reference, inView } = useIsVisible({
    root: scrollReference,
    rootMargin: "50px"
  });
  const { isMobile } = useViewPort();

  useEffect(() => {
    if (isMobile && inView && showDates.length < 12) {
      increaseShowDatesNumber();
    }
  }, [isMobile, inView, showDates, increaseShowDatesNumber]);

  const setScrollReferenceCallback = useCallback(
    (reference_: HTMLDivElement | null) => {
      setScrollReference(reference_);
    },
    [setScrollReference]
  );
  return (
    <div className={`${styles["main"]} ${styles[direction || "ltr"]}`}>
      <div
        className={styles["tables-container"]}
        ref={setScrollReferenceCallback}
      >
        {showDates.map((item, index) => (
          <>
            {index > 0 ? <div className={styles["divider"]} /> : null}

            <Table
              calendarData={calendarData}
              calendarLocale={calendarLocale}
              disabledDateMode={disabledDateMode}
              holidays={holidays}
              isEndFocused={isEndFocused}
              isStartFocused={isStartFocused}
              mode={mode}
              month={item.month}
              onBlurInputs={onBlurInputs}
              onChange={onChange}
              onFocusEnd={onFocusEnd}
              prices={prices}
              value={value}
              year={item.year}
            />
          </>
        ))}
        <div
          className={styles["scroll-more-area"]}
          ref={reference as React.RefObject<HTMLDivElement>}
        />
      </div>
    </div>
  );
};

const MainMemoized = memo(Main);
export { MainMemoized as Main };
