import {
  CalendarDate,
  GregorianCalendar,
  IslamicTabularCalendar,
  PersianCalendar,
  getDayOfWeek,
  getLocalTimeZone,
  today
} from "@internationalized/date";
import { MouseEvent, useCallback } from "react";
import {
  DatepickerDateType,
  DatepickerDayItemType,
  DatepickerDayStateType
} from "../../datepicker.types";
import { convertDate } from "../../datepicker.utils";
import { SelectedDate, TablePropertiesType } from "./table.types";

function isFirstDateOlder(firstDate?: SelectedDate, secondDate?: SelectedDate) {
  if (!firstDate || !secondDate) return false;

  return (
    firstDate?.year < secondDate?.year ||
    (firstDate?.year === secondDate?.year &&
      (firstDate.month < secondDate.month ||
        (firstDate.month === secondDate.month &&
          (firstDate.day || 0) < (secondDate.day || 0))))
  );
}

function isSelectedDateOlder(
  selectedDate?: SelectedDate,
  secondDate?: SelectedDate
) {
  if (!selectedDate || !secondDate) return false;
  return (
    selectedDate.year < secondDate.year ||
    (selectedDate.year === secondDate.year &&
      selectedDate.month <= secondDate.month)
  );
}

function disableUnSelectableDays(
  days: Array<DatepickerDayItemType>,
  properties: TablePropertiesType,
  selectedDateTimestamp: Array<number>
) {
  const isOlder = isSelectedDateOlder(
    properties.calendarInfo.selectedDate,
    properties
  );

  // If it is older than the month selected by the user, we skip
  if (isOlder) {
    // Check "start" mode is active
    if (selectedDateTimestamp[0] && !selectedDateTimestamp[1]) {
      const findStartIndex = days?.findIndex((item) => item.state === "start");
      const startIndex = findStartIndex === -1 ? 0 : findStartIndex;

      if (startIndex > 0) {
        properties.calendarInfo.findDisableDay = false;
      }

      for (let index = startIndex; index < 42; index++) {
        const value_ = days[index];
        if (value_.state === "disabled" && value_.value !== -1) {
          properties.calendarInfo.findDisableDay = true;
        } else if (properties.calendarInfo.findDisableDay) {
          days[index] = {
            ...days[index],
            state: "disabled",
            onClick: undefined
          };
        }
      }
    }
    if (selectedDateTimestamp[0] && selectedDateTimestamp[1]) {
      properties.calendarInfo.findDisableDay = false;
    }
  }
}

function disableBeforeAndAfterLimit(
  days: Array<DatepickerDayItemType>,
  properties: TablePropertiesType
) {
  const { calendarLocale, disableLimit } = properties;

  for (let index = 0; index < 42; index++) {
    const limitAfter = convertDate(
      { dateTime: disableLimit?.disableAfterDate },
      calendarLocale
    );
    const disableBefore = convertDate(
      { dateTime: disableLimit?.disableBeforeDate },
      calendarLocale
    );

    const value_ = days[index];
    if (
      (isFirstDateOlder(limitAfter, value_.date) &&
        disableLimit?.disableAfterDate) ||
      (isFirstDateOlder(value_.date, disableBefore) &&
        disableLimit?.disableBeforeDate)
    ) {
      days[index] = {
        ...days[index],
        state: "disabled",
        onClick: undefined
      };
    }
  }
}

export const useTable = (properties: TablePropertiesType) => {
  const {
    calendarLocale,
    calendarData,
    year,
    month,
    mode,
    value,
    onChange,
    calendarInfo,
    type
  } = properties;

  const onDateClick = useCallback(
    (date: DatepickerDateType) => {
      calendarInfo.selectedDate = { year: date?.year, month: date?.month };

      switch (mode) {
        case "single": {
          onChange([date], calendarLocale);
          break;
        }
        case "multiple": {
          const selectedIndex = value.findIndex(
            (item) => item.dateTime === date.dateTime
          );
          if (selectedIndex === -1) {
            onChange([...value, date], calendarLocale);
          } else {
            onChange(
              value.filter((_, index) => index !== selectedIndex),
              calendarLocale
            );
          }
          break;
        }
        case "range":
        case "start-end": {
          if (value[1]) {
            // already has two selected date, reset it
            onChange([date], calendarLocale);
          } else if (value[0]) {
            // start is selected, but end is not
            if (
              new Date(date.dateTime).getTime() >
              new Date(value[0].dateTime).getTime()
            ) {
              onChange([value[0], date], calendarLocale);
            }
            //  When the start date is select twice
            else if (
              new Date(date.dateTime).getTime() ===
              new Date(value[0].dateTime).getTime()
            ) {
              onChange([], calendarLocale);
            } else {
              onChange([date], calendarLocale);
            }
          } else {
            // has no selected date
            onChange([date], calendarLocale);
          }
          break;
        }
        // No default
      }
    },
    [calendarInfo, mode, onChange, calendarLocale, value]
  );

  const disablePastDates = true;

  const todayDate = today(getLocalTimeZone());
  const todayString = todayDate.toString();
  const todayTimestamp = new Date(todayString).getTime();

  const days: Array<DatepickerDayItemType> = [];

  // show date calendar in locale
  let calendar;
  let dayOfWeek;
  let daysInMonth;
  if (calendarLocale === "persian") {
    calendar = new CalendarDate(new PersianCalendar(), year, month, 1);
    daysInMonth = new PersianCalendar().getDaysInMonth(calendar);
    dayOfWeek = getDayOfWeek(calendar, "fa-IR");
  } else if (calendarLocale === "hijri") {
    calendar = new CalendarDate(new IslamicTabularCalendar(), year, month, 1);
    daysInMonth = new IslamicTabularCalendar().getDaysInMonth(calendar);
    dayOfWeek = getDayOfWeek(calendar, "ar-SA");
  } else {
    calendar = new CalendarDate(new GregorianCalendar(), year, month, 1);
    daysInMonth = new GregorianCalendar().getDaysInMonth(calendar);
    dayOfWeek = getDayOfWeek(calendar, "en-US");
    // start of week is sunday.
    // change it to moday.
    dayOfWeek -= 1;
    if (dayOfWeek === -1) {
      dayOfWeek = 6;
    }
  }

  // selected dates timestamp
  const selectedDateTimestamp = value.map((item: DatepickerDateType) =>
    new Date(item.dateTime).getTime()
  );

  for (let index = 0; index < dayOfWeek; index++) {
    days.push({
      value: -1,
      state: "disabled",
      isToday: false,
      onClick: undefined,
      content: undefined
    });
  }
  for (let index = 1; index <= daysInMonth; index++) {
    let state: DatepickerDayStateType = "normal";
    if ((dayOfWeek + index) % 7 === 0) {
      state = "weekend";
    }
    const newDate = calendar.add({ days: index - 1 });
    const newDateString = newDate.toString();
    const newDateTimestamp = new Date(newDateString).getTime();

    let extraData;
    if (calendarData) {
      extraData = calendarData[newDateString];
    }

    if (
      (disablePastDates && newDateTimestamp < todayTimestamp) ||
      extraData?.state === "disabled"
    ) {
      // disabled
      state = "disabled";
    } else if (
      mode === "single" &&
      newDateTimestamp === selectedDateTimestamp[0]
    ) {
      // single
      state = "selected";
    } else if (
      mode === "multiple" &&
      selectedDateTimestamp.includes(newDateTimestamp)
    ) {
      // mutiple
      state = "multi-selected";
    } else if (mode === "range") {
      // range
      if (newDateTimestamp === selectedDateTimestamp[0]) {
        state = "start";
      } else if (
        newDateTimestamp > selectedDateTimestamp[0] &&
        newDateTimestamp < selectedDateTimestamp[1]
      ) {
        state = "in-range";
      } else if (newDateTimestamp === selectedDateTimestamp[1]) {
        state = "end";
      }
    } else if (mode === "start-end") {
      // start-end
      if (newDateTimestamp === selectedDateTimestamp[0]) {
        state = "start";
      } else if (
        newDateTimestamp > selectedDateTimestamp[0] &&
        newDateTimestamp < selectedDateTimestamp[1]
      ) {
        state = "middle";
      } else if (newDateTimestamp === selectedDateTimestamp[1]) {
        state = "end";
      }
    } else if (extraData?.state === "green") {
      state = "special-best";
    } else if (extraData?.state === "red") {
      state = "special-worst";
    }

    let onClick: undefined | ((event: MouseEvent<HTMLDivElement>) => void);
    if (state !== "disabled") {
      onClick = () =>
        onDateClick({
          year: newDate.year,
          month: newDate.month,
          day: newDate.day,
          dateTime: newDateString
        });
    }

    days.push({
      value: index,
      state,
      isToday: newDateTimestamp === todayTimestamp,
      onClick,
      content: extraData?.content,
      date: newDate
    });
  }
  for (let index = days.length; index < 42; index++) {
    const newDate = calendar.add({ days: index - 1 });
    days.push({
      value: -1,
      state: "disabled",
      isToday: false,
      onClick: undefined,
      content: undefined,
      date: newDate
    });
  }

  disableBeforeAndAfterLimit(days, properties);

  if (type === "flat") {
    disableUnSelectableDays(days, properties, selectedDateTimestamp);
  }

  return {
    days
  };
};
