import { useViewPort } from "@website/hooks";
import { MaterialIcon } from "@website/icons";
import { AutoComplete, Button, Datepicker } from "@website/ui-old";
import { FC } from "react";
import { PassengerCount } from "../../..";
import {
  useDate,
  useDestination,
  useHistory,
  usePassengerCount,
  useSubmit,
  useValidation
} from "./hooks";
import styles from "./search-villa.module.scss";
import { SearchVillaPropertiesType } from "./search-villa.types";

const SearchVilla: FC<SearchVillaPropertiesType> = (properties) => {
  const {
    selectedDestinationItem,
    selectedDestinationText,
    destinationQuery,
    onChangeDestination,
    onChangeDestinationQuery,
    onSelectDestination,
    destinationLoading,
    destinationListNormalized,
    destinationLabel,
    DestinationNoResult
  } = useDestination();
  const { date, calendarLocale, onChangeDate, fromTitle, toTitle } = useDate();
  const {
    passengerCount,
    passengerCountList,
    passengerCountResult,
    passengerCountTitle,
    onSubmitPassengerCount,
    onClosePassengerButton,
    onClickBackDropHandler
  } = usePassengerCount();
  const { destinationHistoryList, addToHistory, onSelectDestinationHistory } =
    useHistory(onChangeDestination);
  const { destinationError, dateError, submitValidationCheck } = useValidation({
    destinationItem: selectedDestinationItem,
    date
  });
  const {
    onSubmitClick,
    // submitLoading,
    submitText
  } = useSubmit(
    selectedDestinationItem,
    date,
    calendarLocale,
    passengerCount,
    addToHistory,
    submitValidationCheck
  );
  const { isMobile } = useViewPort();

  return (
    <div className={styles["container"]}>
      <div className={styles["options"]}>
        <div className={styles["filters"]}>
          <PassengerCount
            list={passengerCountList}
            onClickBackDropHandler={onClickBackDropHandler}
            onClosePassengerButton={onClosePassengerButton}
            onSubmit={onSubmitPassengerCount}
            result={passengerCountResult}
            title={passengerCountTitle}
          />
        </div>
      </div>
      <div className={styles["main"]}>
        <div className={styles["inputs-wrapper"]}>
          <div className={styles["autocomplete-wrapper"]}>
            <AutoComplete
              error={destinationError}
              fullWidth
              parts={[
                {
                  StartIcon: <MaterialIcon name="place" />,
                  label: destinationLabel,
                  list: destinationListNormalized,
                  historyList:
                    destinationQuery.length <= 1 ? destinationHistoryList : [],
                  historyTitle: "آخرین جستجوهای شما",
                  text: selectedDestinationText,
                  onSelect: onSelectDestination,
                  onSelectHistory: onSelectDestinationHistory,
                  onChangeQuery: onChangeDestinationQuery,
                  query: destinationQuery,
                  loading: destinationLoading,
                  NoResult: DestinationNoResult
                }
              ]}
            />
          </div>
          <div className={styles["datepicker-wrapper"]}>
            <Datepicker
              error={dateError.length > 0}
              fromTitle={fromTitle}
              fullWidth
              helperText={dateError}
              mode="start-end"
              onChange={onChangeDate}
              toTitle={toTitle}
              value={date}
            />
          </div>
        </div>
        <div className={styles["button-wrapper"]}>
          <Button
            aria-label="search"
            fullWidth
            id="villa_page"
            // loading={submitLoading}
            onClick={onSubmitClick}
            size={isMobile ? "large" : "x-large"}
          >
            {submitText}
          </Button>
        </div>
      </div>
    </div>
  );
};

export { SearchVilla };
