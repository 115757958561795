import { captureException } from "@sentry/nextjs";
import { authStore, userTrackingStore } from "@website/store";
import { isSSR, replaceOptions } from "@website/utils";
import { isArray, isPlainObject, omit, toString } from "lodash";
import { FetcherType, RejectHandlerType } from "./fetcher.types";

const rejectHandler: RejectHandlerType = ({ reject, error, extra }) => {
  reject({ error, ...extra });
  // eslint-disable-next-line no-console
  isSSR() && console.error({ error, ...extra, date: new Date() });
  captureException(error, {
    extra,
    tags: {
      errorType: "apiCallError",
      ApiUrl: toString(extra.url),
      ApiStatus: toString(extra.status),
      appName: process.env.NEXT_PUBLIC_APP_NAME,
      appChannel: process.env.NEXT_PUBLIC_CHANNEL_TYPE,
      appVersion: process.env.NEXT_PUBLIC_IMAGE_VERSION,
      anonymousId: userTrackingStore.aid.get(),
      experiments: userTrackingStore.experiments.get()?.join?.(",")
    }
  });
};

export const fetcher: FetcherType = (configs) => {
  const url = String(
    configs.options ? replaceOptions(configs.url, configs.options) : configs.url
  );
  const channel = process.env.NEXT_PUBLIC_CHANNEL_TYPE;
  // const jekToken = authStore.jekToken.get();
  const accessToken = authStore.accessToken.get();
  const jekToken = authStore.jekToken.get();
  const trackingKey = userTrackingStore.sid.get();
  const experimentKeys = userTrackingStore.experiments.get()?.join(",");

  return new Promise((resolve, reject) => {
    const headers = {
      Accept: "application/json",
      "Accept-Language": "fa",
      "Content-Type": "application/json",
      ...(channel ? { channel } : {}),
      ...(accessToken || jekToken
        ? { Authorization: accessToken ? `Bearer ${accessToken}` : jekToken }
        : {}),
      ...(trackingKey ? { "User-Tracking-Key": toString(trackingKey) } : {}),
      ...(experimentKeys ? { "Experiment-Keys": experimentKeys } : {}),
      ...configs.headers
    };
    fetch(
      configs.params
        ? `${url}?${Object.entries(configs.params)
            .map(([key, value]) =>
              isArray(value)
                ? value.map((value) => `${key}=${value}`).join("&")
                : `${key}=${value}`
            )
            .join("&")}`
        : url,
      {
        cache: configs.cache ?? "default",
        method: configs.method ?? "GET",
        headers,
        body: JSON.stringify(configs.data)
      }
    )
      .then((response) =>
        response
          .json()
          .then((data) => ({
            ...omit(response, ["body", "bodyUsed"]),
            data
          }))
          .catch((error) => ({
            ...omit(response, ["body", "bodyUsed"]),
            data: response.ok ? {} : error
          }))
      )
      .then((response) => {
        if (
          (response.ok || configs.validateStatus?.(response.status)) &&
          (isPlainObject(response.data?.error)
            ? Object.keys(response.data?.error).length === 0
            : !response.data?.error) &&
          ![
            toString(response.data?.success),
            toString(response.data?.successful),
            toString(response.data?.is_successfull)
          ].includes("false")
        ) {
          return resolve(response);
        }
        rejectHandler({
          reject,
          error: `HTTP Error: ${response.status} (${
            response.data?.error ||
            response.data?.message ||
            response.statusText
          })`,
          extra: {
            ...omit(configs, "validateStatus"),
            headers,
            response: response.data,
            status: response.status
          }
        });
      })
      .catch((error) => {
        rejectHandler({
          reject,
          error,
          extra: { ...omit(configs, "validateStatus"), headers }
        });
      });
  });
};
