import { useDebounce } from "@website/hooks";
import { Origin } from "@website/icons";
import { useTranslation } from "@website/locale";
import { useCallback, useMemo, useState } from "react";
import { NoResult } from "../../../..";
import { useCitiesService } from "../services";
import { useSearchSuggestionsService } from "../services/use-tour-suggestions-service";

export const useOrigin = () => {
  const { t } = useTranslation();
  const [originQuery, setOriginQuery] = useState<string>("");
  const [selectedOriginItem, setSelectedOriginItem] = useState(null);
  const query = useDebounce(originQuery, 250);
  const { suggestions } = useSearchSuggestionsService("origin");
  const { isLoading, data } = useCitiesService(query);

  const onChangeOriginQuery = useCallback((value: string) => {
    setOriginQuery(value);
  }, []);

  const originList = useMemo(
    () =>
      query.length >= 2
        ? data?.map((item) => ({
            city: item?.title_fa,
            iata_code: item?.iata_code
          })) || []
        : suggestions || [],
    [suggestions, query, data]
  );

  const originListNormalized = useMemo(
    () =>
      originList.map((item) => ({
        id: item?.iata_code,
        title: item?.city || item?.title_fa,
        subtitle: item?.iata_code,
        StartIcon: <Origin />
      })),
    [originList]
  );

  const onSelectOrigin = useCallback(
    (id: string) => {
      const originItem = originList.find(
        (origin: any) => `${origin?.iata_code}` === `${id}`
      );
      setOriginQuery("");
      setSelectedOriginItem(originItem);
    },
    [originList]
  );

  const onChangeOrigin = useCallback((item: any) => {
    setOriginQuery("");
    setSelectedOriginItem(item);
  }, []);

  return {
    selectedOriginItem,
    selectedOriginText:
      selectedOriginItem?.city || selectedOriginItem?.title_fa || "",
    originQuery,
    onChangeOrigin,
    onSelectOrigin,
    onChangeOriginQuery,
    originList,
    originLoading: isLoading,
    originListNormalized,
    OriginNoResult:
      originQuery?.length >= 2 &&
      !isLoading &&
      originList &&
      originList.length === 0 ? (
        <NoResult query={originQuery} />
      ) : undefined,
    originLabel: t("homepage.tour.main_search.origin")
  };
};
