import { useAddRemoveQuery } from "@website/hooks";
import { ArrowDropDown } from "@website/icons";
import { useTranslation } from "@website/locale";
import { Radio } from "@website/ui-old";
import { useRouter } from "next/router";
import { useCallback, useMemo } from "react";
import { SalonTypePropertiesType } from "./salon-type.types";

export const useSalonType = (properties: SalonTypePropertiesType) => {
  const { list, name, onChange, value } = properties;
  const { t } = useTranslation();

  const router = useRouter();
  const key = "SEARCH_SALON_TYPE";
  const { [key]: isKey } = router.query;
  const { addQuery, removeQuery } = useAddRemoveQuery(key);

  const onClickChips = useCallback(() => {
    addQuery("open");
  }, [addQuery]);

  const onClosePopover = useCallback(() => {
    removeQuery();
  }, [removeQuery]);

  const chipsText = useMemo(
    () => list.find((item) => item.value === value)?.title || "",
    [list, value]
  );

  return {
    chipsProps: {
      color: "secondary" as const,
      EndIcon: <ArrowDropDown />,
      text: chipsText,
      onClick: onClickChips,
      variant: "outline" as const,
      selected: true
    },
    popoverProps: {
      onClose: onClosePopover,
      open: Boolean(isKey),
      title: t("homepage.train.salon_type.title")
    },
    list: list.map((item) => ({
      title: item.title,
      role: "button",
      size: "small" as const,
      StartAction: (
        <Radio
          checked={item.value === value}
          color="secondary"
          name={name || ""}
          value={item.value}
        />
      ),
      onClick: () => {
        onChange(item.value);
        onClosePopover();
      }
    }))
  };
};
