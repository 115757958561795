import { useBack } from "@website/hooks";
import { useState } from "react";

export const useSupportMenu = (redirectUrl?: string) => {
  const { goBack } = useBack(redirectUrl);
  const [supportMenuIsOpen, setSupportMenuIsOpen] = useState<boolean>(false);

  const onSupportMenuOpen = () => {
    setSupportMenuIsOpen(true);
  };

  const onSupportMenuClose = () => {
    setSupportMenuIsOpen(false);
  };

  return {
    onSupportMenuOpen,
    onSupportMenuClose,
    supportMenuIsOpen,
    onGoBack: goBack
  };
};
