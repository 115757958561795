import { useViewPort } from "@website/hooks";
import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { EnvironmentChannelType } from "@website/store";
import {
  AutoComplete,
  Button,
  Datepicker,
  ToggleButton
} from "@website/ui-old";
import { FC } from "react";
import { PassengerRoomCount } from "../../../passenger-room-count";
import {
  useDate,
  useDestination,
  useHistory,
  usePassengerCount,
  useSubmit,
  useToggle,
  useValidation
} from "./hooks";
import styles from "./search-international-hotel.module.scss";
import { SearchInterHotelPropertiesType } from "./search-international-hotel.types";

const SearchInterHotel: FC<SearchInterHotelPropertiesType> = () => {
  const channel = process.env[
    "NEXT_PUBLIC_CHANNEL_TYPE"
  ] as EnvironmentChannelType;

  const { isMobile } = useViewPort();
  const { toggleList, toggleValue } = useToggle();

  const { t } = useTranslation();
  const {
    onSubmitPassengerCount,
    passengerCount,
    passengerCountList,
    passengerCountTitle,
    passengerCountResult,
    onChange,
    onDeletePassengerCountList,
    onAddPassengerCountList,
    onChangeChildAge,
    onClickBackDropHandler,
    onClosePassengerButton
  } = usePassengerCount();
  const {
    selectedDestinationItem,
    selectedDestinationText,
    destinationQuery,
    onChangeDestination,
    onChangeDestinationQuery,
    onSelectDestination,
    destinationLoading,
    destinationListNormalized,
    DestinationNoResult
  } = useDestination();
  const { date, calendarLocale, onChangeDate } = useDate();
  const { destinationHistoryList, addToHistory, onSelectDestinationHistory } =
    useHistory(onChangeDestination);
  const { destinationError, dateError, submitValidationCheck } = useValidation({
    destinationItem: selectedDestinationItem,
    date
  });
  const { onSubmitClick } = useSubmit(
    selectedDestinationItem,
    date,
    calendarLocale,
    passengerCount,
    addToHistory,
    submitValidationCheck
  );

  return (
    <div className={styles["container"]}>
      <div className={styles["options"]}>
        {channel !== "ads" && channel !== "snapp-ir" && (
          <ToggleButton
            color="secondary"
            list={toggleList}
            size="small"
            value={toggleValue}
            variant="contained"
          />
        )}
        <div className={styles["filters"]}>
          <PassengerRoomCount
            list={passengerCountList}
            onAdd={onAddPassengerCountList}
            onChange={onChange}
            onChangeChildAge={onChangeChildAge}
            onClickBackDropHandler={onClickBackDropHandler}
            onClosePassengerButton={onClosePassengerButton}
            onDelete={onDeletePassengerCountList}
            onSubmit={onSubmitPassengerCount}
            result={passengerCountResult}
            title={passengerCountTitle}
          />
        </div>
      </div>
      <div className={styles["main"]}>
        <div className={styles["inputs-wrapper"]}>
          <div className={styles["autocomplete-wrapper"]}>
            <AutoComplete
              error={destinationError}
              fullWidth
              parts={[
                {
                  StartIcon: <MaterialIcon name="place" />,
                  label: t("homepage.hotel.main_search.destination"),
                  list: destinationListNormalized,
                  historyList:
                    destinationQuery.length <= 1 ? destinationHistoryList : [],
                  historyTitle: t(
                    "homepage.inter_hotel.main_search.youre_recent_search"
                  ),
                  text: selectedDestinationText,
                  onSelect: onSelectDestination,
                  onSelectHistory: onSelectDestinationHistory,
                  onChangeQuery: onChangeDestinationQuery,
                  query: destinationQuery,
                  loading: destinationLoading,
                  NoResult: DestinationNoResult
                }
              ]}
            />
          </div>
          <div className={styles["datepicker-wrapper"]}>
            <Datepicker
              error={dateError.length > 0}
              fromTitle={t("homepage.hotel.main_search.check_in")}
              fullWidth
              helperText={dateError}
              mode="start-end"
              onChange={onChangeDate}
              toTitle={t("homepage.hotel.main_search.check_out")}
              value={date}
            />
          </div>
        </div>
        <div className={styles["button-wrapper"]}>
          <Button
            aria-label="search"
            fullWidth
            onClick={onSubmitClick}
            size={isMobile ? "large" : "x-large"}
          >
            {t("action.search")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export { SearchInterHotel };
