export const makeShowText = (item) => {
  if (item) {
    const { name, province, level, isAbroad } = item;
    if (level === 1) {
      return name + (isAbroad ? `, ${province}` : "");
    }
    return `${province}, پایانه ${name}`;
  }
  return "";
};
