import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { Button, Dropdown, TextField } from "@website/ui";
import { FC } from "react";
import {
  useCode,
  usePhoneNumber,
  useService,
  useSubmit,
  useValidation
} from "./hooks";
import styles from "./track-modal.module.scss";

const TrackModal: FC = () => {
  const { servicesList, service, onServiceChange } = useService();
  const { code, onCodeChange, onCodeClear } = useCode();
  const { phoneNumber, onPhoneNumberChange, onPhoneNumberClear } =
    usePhoneNumber();
  const { codeError, phoneNumberError, submitValidationCheck } = useValidation({
    code,
    phoneNumber
  });
  const { onSubmitClick, isLoading, isError } = useSubmit({
    service,
    code,
    phoneNumber,
    submitValidationCheck
  });
  const { t } = useTranslation();

  return (
    <div className={styles["root"]}>
      <div className={styles["header"]}>
        {t("header.track_modal.description")}
      </div>
      <Dropdown
        StartIcon={<MaterialIcon name="apartment" variant="outlined" />}
        className={styles["dropdown"]}
        fullWidth
        label={t("header.track_modal.select_service")}
        list={servicesList}
        name="track"
        onChange={onServiceChange}
        value={service}
      />
      <TextField
        EndIcon={
          code.length > 0 ? (
            <MaterialIcon
              name="clear"
              onClick={onCodeClear}
              variant="outlined"
            />
          ) : undefined
        }
        StartIcon={
          <MaterialIcon
            className={`${styles["icons"]} ${
              code.length > 0 && codeError.length === 0
                ? styles["secondary"]
                : ""
            }`}
            name="confirmation_number"
            variant="outlined"
          />
        }
        error={codeError.length > 0}
        fullWidth
        helperText={codeError}
        label={t("header.track_modal.tracking_code")}
        onChange={onCodeChange}
        value={code}
      />
      <TextField
        EndIcon={
          phoneNumber.length > 0 ? (
            <MaterialIcon
              name="clear"
              onClick={onPhoneNumberClear}
              variant="outlined"
            />
          ) : undefined
        }
        StartIcon={
          <MaterialIcon
            className={`${styles["icons"]} ${
              phoneNumber.length > 0 && phoneNumberError.length === 0
                ? styles["secondary"]
                : ""
            }`}
            name="phone_iphone"
            variant="outlined"
          />
        }
        error={phoneNumberError.length > 0}
        fullWidth
        helperText={phoneNumberError}
        label={t("header.track_modal.phone_number")}
        onChange={onPhoneNumberChange}
        value={phoneNumber}
      />
      <Button
        className={styles["submitButton"]}
        color="secondary"
        loading={isLoading}
        onClick={onSubmitClick}
        size="large"
      >
        <span className="text-lg">{t("header.track_modal.track_order")}</span>
      </Button>
      {isError ? (
        <span className="text-error text-sm">
          {t("header.track_modal.no_response_was_found")}
        </span>
      ) : null}
    </div>
  );
};

export { TrackModal };
