import { deepCompare } from "@website/utils";
import { FC, memo, useMemo } from "react";
import { LazyImage } from "../lazy-image";
import { useListItem } from "./list-item.hook";
import styles from "./list-item.module.scss";
import { ListItemPropertiesType } from "./list-item.types";

const ListItem: FC<ListItemPropertiesType> = (properties) => {
  const {
    className,
    EndAction,
    EndIcon,
    image,
    Meta,
    overline,
    role,
    size = "medium",
    subtitle,
    StartAction,
    StartIcon,
    title,
    isCollapsed
  } = properties;
  const { rootProps } = useListItem(properties);

  const inside = (
    <>
      {image && (
        <div className="flex-[0_0_64px]">
          <LazyImage alt={typeof title === "string" ? title : ""} src={image} />
        </div>
      )}
      {StartAction && (
        <div className={styles["start-action"]}>{StartAction}</div>
      )}
      {StartIcon && <div className={styles["icon"]}>{StartIcon}</div>}
      <div
        className={`flex-1 whitespace-nowrap transition-opacity duration-300 ${
          isCollapsed ? "opacity-0" : "opacity-100 delay-200"
        }`}
      >
        {overline && <div className={styles["overline"]}>{overline}</div>}
        {title && <div className={styles["title"]}>{title}</div>}
        {subtitle && <div className={styles["subtitle"]}>{subtitle}</div>}
      </div>
      {Meta && <div className={styles["meta"]}>{Meta}</div>}
      {EndIcon && <div className={styles["icon"]}>{EndIcon}</div>}
      {EndAction && <div className={styles["end-action"]}>{EndAction}</div>}
    </>
  );

  const Tag = useMemo(
    () => (role === "link" ? "a" : role === "button" ? "button" : "div"),
    [role]
  );

  const roleProperties = useMemo(
    () =>
      role === "link"
        ? rootProps.link
        : role === "button"
          ? rootProps.button
          : rootProps.default,
    [role, rootProps]
  );

  return (
    <Tag
      {...roleProperties}
      className={`${styles["list-item"]} ${styles[size]} ${className ?? ""} ${
        isCollapsed && image ? "px-2" : "px-6"
      }`}
    >
      {inside}
    </Tag>
  );
};

const ListItemMemoized = memo(ListItem, deepCompare);
export { ListItemMemoized as ListItem };
