import { useCallback, useState } from "react";
import {
  DatepickerCalendarLocaleType,
  DatepickerPropertiesType
} from "../datepicker.types";
import { convertDate } from "../datepicker.utils";

export const useCalendarLocale = (properties: DatepickerPropertiesType) => {
  const { value, onChange, initialCalendarLocale } = properties;

  const [calendarLocale, setCalendarLocale] =
    useState<DatepickerCalendarLocaleType>(initialCalendarLocale || "persian");

  const onLocaleChange = useCallback(
    (calendarLocale: DatepickerCalendarLocaleType) => {
      const convertedDate = value.map((item) =>
        convertDate(item, calendarLocale)
      );
      onChange(convertedDate, calendarLocale);
      setCalendarLocale(calendarLocale);
    },
    [onChange, value]
  );

  return {
    calendarLocale,
    changeCalendarLocaleToGregorian: onLocaleChange.bind(null, "gregorian"),
    changeCalendarLocaleToPersian: onLocaleChange.bind(null, "persian"),
    changeCalendarLocaleToHijri: onLocaleChange.bind(null, "hijri")
  };
};
