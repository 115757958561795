import { FC, memo } from "react";
import styles from "./top-app-bar.module.scss";
import { TopAppBarPropertiesType } from "./top-app-bar.types";

const TopAppBar: FC<TopAppBarPropertiesType> = (properties) => {
  const { StartAction, title, EndLayout, fixed = false, style } = properties;

  return (
    <div
      className={`${styles["container"]} ${fixed ? styles["fix"] : ""}`}
      data-testid="top-app-bar"
      style={style}
    >
      {StartAction}
      <div className={styles["title"]} data-testid="top-app-bar-title">
        {title}
      </div>
      <div className={styles["end-layout"]}>{EndLayout}</div>
    </div>
  );
};

const TopAppBarMemoized = memo(TopAppBar);
export { TopAppBarMemoized as TopAppBar };
