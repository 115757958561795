import { captureMessage } from "@sentry/nextjs";
import { padEnd, padStart, toNumber, toString } from "lodash";
import { DateTime } from "luxon";
import { isFilled } from "../is-filled";
import { numberConvert } from "../number-convert";
import { fetchTimeDiff } from "./date-format.server";
import { DateFormatType, TransformDateType } from "./date-format.types";

const calendars: Record<string, string> = {
  en: "iso8601",
  fa: "persian",
  ar: "islamic"
};

export const dateTimeShift = process.env.JEST_WORKER_ID
  ? 0
  : await fetchTimeDiff();

const dateParser = (date: string) => {
  if (/^-?\d{10,13}$/.test(date) || date === "0") {
    return new Date(
      toNumber(padEnd(date, date.startsWith("-") ? 14 : 13, "0"))
    );
  }
  return new Date(
    date
      .replaceAll(
        /(\d{4})[/-](\d{1,2})[/-](\d{1,2})/g,
        (match, year, month, day) =>
          `${year}-${padStart(month, 2, "0")}-${padStart(day, 2, "0")}`
      )
      .replaceAll(
        /(\d{1,2}):(\d{1,2})(?::(\d{1,2}))?\b/g,
        (match, hour, minute, second) =>
          `${hour}:${minute.padStart(2, "0")}${
            second ? `:${second.padStart(2, "0")}` : ""
          }`
      )
  );
};

export const getValidCheckIn = () =>
  dateFormat(Date.now() + dateTimeShift, {
    locale: "en",
    template: "yyyy-MM-dd"
  });

export const getValidCheckOut = () =>
  dateFormat(Date.now() + dateTimeShift + 60 * 60 * 24 * 1000, {
    locale: "en",
    template: "yyyy-MM-dd"
  });

export const isInvalidCheckin = (
  date_from: unknown,
  date_to: unknown,
  isEmptyAllowed?: boolean
) =>
  (isEmptyAllowed === false || Boolean(date_from) || Boolean(date_to)) &&
  (!date_from ||
    !date_to ||
    !(daysBetweenDates(date_from, date_to) >= 1) ||
    !(daysBetweenDates(getValidCheckIn(), date_from) >= 0) ||
    !(daysBetweenDates(getValidCheckOut(), date_to) >= 0));

export const daysBetweenDates = (start: unknown, end: unknown) => {
  try {
    const differenceMs =
      new Date(end as string).getTime() - new Date(start as string).getTime();
    return Math.ceil(differenceMs / (1000 * 3600 * 24));
  } catch {
    captureMessage(`daysBetweenDates went wrong => start:${start} end:${end}`);
  }
  return 0;
};

export const dateFormat: DateFormatType = (date, options) => {
  const locale = options.locale ?? "en";
  const parsedDate = dateParser(toString(date));
  if (!isFilled(date) || !isFilled(parsedDate)) {
    return undefined;
  }
  if ([undefined, "timestamp"].includes(options?.template)) {
    return parsedDate.getTime();
  }
  return numberConvert(
    DateTime.fromJSDate(parsedDate)
      .reconfigure({ outputCalendar: calendars[locale] })
      .setLocale(locale)
      .setZone("Asia/Tehran")
      .toFormat(options?.template),
    {
      numberOnly: false,
      locale: options.numberConvert === false ? "en" : locale
    }
  );
};

export const getDiffInMonth = (dateFrom: Date, dateTo: Date) => {
  const monthsDiff = dateTo.getMonth() - dateFrom.getMonth();
  const yearsMonths = (dateTo.getFullYear() - dateFrom.getFullYear()) * 12;
  return monthsDiff + yearsMonths;
};

export const transformDate: TransformDateType = (
  date,
  { year = 0, month = 0, day = 0, hour = 0, minute = 0, second = 0 } = {}
): string => {
  date.setFullYear(date.getFullYear() + year);
  date.setMonth(date.getMonth() + month);
  date.setDate(date.getDate() + day);
  date.setHours(date.getHours() + hour);
  date.setMinutes(date.getMinutes() + minute);
  date.setSeconds(date.getSeconds() + second);

  return date.toISOString();
};
